import { Modal } from 'react-bootstrap';

interface SupplierInvoiceFileProps {
    invoiceId: number;
    heading: string;
    uploadedInvoice: File;
    showModal: boolean;
    hideModal(): void;
}

const SupplierInvoiceFilePopup = (props: SupplierInvoiceFileProps) => {

    const hideModal = () => {
        props.hideModal();
    }

    const url = props.uploadedInvoice.size > 0 ? window.URL.createObjectURL(props.uploadedInvoice) : "";

    return (
        <div>
            <Modal size="lg" show={props.showModal} onHide={hideModal}>
                <Modal.Header className="modal__header--colour">
                    <Modal.Title>{props.heading}</Modal.Title>
                    <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <div className={url === "" ? "hidden" : ""}>
                        <embed className="invoice__fileSize" type="application/pdf" src={url}></embed>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="popup-button--yes" onClick={hideModal}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SupplierInvoiceFilePopup;
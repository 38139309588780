import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import NoteModal from './NotesModal';
import authService from './api-authorization/AuthorizeService';

interface VariationListProps {
    canEdit: boolean;
    canUnapprove: boolean;
    jobId: number;
    changePage(pageData: PageChangeData): void;
    runReport(data: RunReportData): void;
}

interface PageChangeData {
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    detailId: number;
    detailName: string;
    approved: boolean;
    page: string;
}

interface Variation {
    itemIndex: number;
    id: number;
    variationNumber: number;
    jobId: number;
    name: string;
    reportText: string;
    approved: boolean;
    subTotal: number;
    subTotalFormatted: string;
    markUpPercentage: number;
    subTotalExGst: number;
    subTotalExGstFormatted: string;
    gst: number;
    gstFormatted: string;
    gstPercentage: number;
    total: number;
    totalFormatted: string;
    report: boolean;
    isDirty: boolean;
}

interface RunReportData {
    variationId: number;
    reportName: string;
}

interface EditNotes {
    title: string;
    heading: string;
    rowIndex: number;
    id: number;
    notes: string;
}

const VariationListData = (props: VariationListProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [reportText, setReportText] = React.useState({
        showEditReportText: false, editReportText: {
            title: "",
            heading: "",
            id: 0,
            rowIndex: 0,
            notes: ""
        }
    });
    const [showConfirmUnapproveModal, setShowConfirmUnapproveModal] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [totals, setTotals] = React.useState({ subTotalExGstFormatted: "$0.00", gstFormatted: "$0.00", totalFormatted: "$0.00" });
    const [reportId, setReportId] = React.useState(-1);
    const [variationIndex, setVariationIndex] = React.useState(-1);
    const [variations, setVariations] = React.useState<Variation[]>([]);

    React.useEffect(() => {
        getVariations();
    }, []);

    const getVariations = async () => {
        const token = await authService.getAccessToken();
        var jobId = props.jobId;
        axios.get('Variation/GetVariations?JobId=' + jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //add blank header row
            var variationDetails = res.data;
            var variationList = variationDetails.variations;
            //update item index
            for (var i = 0; i < variationList.length; i++) {
                variationList[i].itemIndex = i;
            }

            if (props.canEdit) {
                var blankRow = addBlankRow(variationList.length);
                variationList.push(blankRow);
            }

            setVariations(variationList);
            setTotals({ subTotalExGstFormatted: res.data.subTotalExGstFormatted, gstFormatted: res.data.gstFormatted, totalFormatted: res.data.totalFormatted });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var newRow: Variation = {
            itemIndex: nextItemIndex,
            id: 0,
            variationNumber: nextItemIndex + 1,
            jobId: props.jobId,
            name: "",
            reportText: "",
            approved: false,
            subTotal: 0,
            subTotalFormatted: "$0.00",
            markUpPercentage: 0,
            subTotalExGst: 0,
            subTotalExGstFormatted: "$0.00",
            gst: 0,
            gstFormatted: "$0.00",
            gstPercentage: 0.1,
            total: 0,
            totalFormatted: "$0.00",
            report: false,
            isDirty: false
        };
        return newRow;
    }

    //delete row
    const deleteItem = (e: any, index: number) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setVariationIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setVariationIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        var vars = [...variations];
        var variation = vars[variationIndex];

        if (variation.id === 0) {
            //not saved yet so just remove from list
            vars.splice(variationIndex, 1);
            setVariations(vars);
            setShowConfirmModal(false);
            setModalSaveDisabled(false);
            setVariationIndex(-1);

            toast.success("Variation has been deleted");
        } else {
            deleteVarition(variation.id);
        }
    }

    const deleteVarition  = async (variationId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var deleteDetail = {
            id: variationId,
            jobId: props.jobId,
            subId: user.sub
        };

        var vars = [...variations];

        axios.post('Variation/DeleteVariation', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("Variation has been deleted");

                //remove from list
                vars.splice(variationIndex, 1);
                setVariations(vars);
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setVariationIndex(-1);
                setTotals({ subTotalExGstFormatted: res.data.subTotalExGstFormatted, gstFormatted: res.data.gstFormatted, totalFormatted: res.data.totalFormatted });
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }
    //end delete row

    const hideConfirmUnapproveModal = () => {
        setShowConfirmUnapproveModal(false);
        setModalSaveDisabled(false);
        setVariationIndex(-1);
    }

    const saveConfirmUnapproveModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        unapproveVarition();
    }

    const unapproveVarition = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var vars = [...variations];
        var variation = vars[variationIndex];
        variation.approved = false;

        var unapproveDetail = {
            id: variation.id,
            jobId: props.jobId,
            subId: user.sub
        };

        axios.post('Variation/UnapproveVariation', unapproveDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("Variation has been unapproved");

                setVariations(vars);
                setShowConfirmUnapproveModal(false);
                setModalSaveDisabled(false);
                setVariationIndex(-1);

                calculateFinalTotals(variations);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }
    //end unapprove

    const calculateFinalTotals = (variations: Variation[]) => {
        var subTotalExGst = 0;
        var gst = 0;
        var total = 0;

        for (var i = 0; i < (variations.length - 1); i++) {
            if (variations[i].approved) {
                subTotalExGst += variations[i].subTotalExGst;
                gst += variations[i].gst;
                total += variations[i].total;
            }
        }

        var subTotalExGstFormatted = subTotalExGst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var gstFormatted = gst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var totalFormatted = total.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });

        setTotals({ subTotalExGstFormatted: subTotalExGstFormatted, gstFormatted: gstFormatted, totalFormatted: totalFormatted });
    }

    //grid item changed
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var vars = [...variations];
        var variation = vars[index];
        let exist: any = {};
        exist = variation;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        //if markup changed, then update all the totals
        if (e.target.name === "markUpPercentage") {
            let subTotalExGst = variation.subTotal + (variation.subTotal * (e.target.value / 100));
            let gst = subTotalExGst * variation.gstPercentage;
            let total = subTotalExGst + gst;

            variation.subTotalExGst = subTotalExGst;
            variation.subTotalExGstFormatted = subTotalExGst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
            variation.gst = gst;
            variation.gstFormatted = gst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
            variation.total = total;
            variation.totalFormatted = total.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }

        if (index === (vars.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(vars.length);
            vars.push(newRow);
        }

        setVariations(vars);
        setSaveDisabled(false);
        setModalSaveDisabled(false);
        setErrors({});
    }

    const handleCellCheckboxUnapproveChange = (index: number, e: any) => {
        //update item that has changed
        var vars = [...variations];
        var variation = vars[index];

        if (e.target.checked) {
            //approve variation
            let exist: any = {};
            exist = variation;
            exist[e.target.name] = e.target.checked;
            exist.isDirty = true;

            setVariations(vars);
            setSaveDisabled(false);
            setModalSaveDisabled(false);

            calculateFinalTotals(variations);
        } else {
            //unapprove variation
            setShowConfirmUnapproveModal(true);
            setModalSaveDisabled(false);
            setVariationIndex(index);
        }
    }

    //open details page
    const openDetails = (itemIndex: number, e: any) => {
        e.preventDefault();

        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        //if isDirty - ask if want to save changes
        //need to save all changed items when opening next level, so check all
        var changed = variations.filter(data => data.isDirty);
        if (!modalSaveDisabled && changed.length > 0) {
            saveVariation(itemIndex, "", -1);
        } else {
            var varDetail = variations[itemIndex];
            openDetailsPage(varDetail.id, varDetail.name, varDetail.approved);
        }
    }

    const openDetailsPage = (id: number, name: string, approved: boolean) => {
        var pageChange: PageChangeData = {
            variationId: id,
            variationName: name,
            headerId: 0,
            headerName: "",
            detailId: 0,
            detailName: "",
            approved: approved,
            page: "header"
        };

        props.changePage(pageChange);
    }
    //end open details page

    //email variation **NOT USED
    const email = (itemIndex: number, e: any) => {
        e.preventDefault();

        //validate and save before emailing
        //if isDirty - ask if want to save changes
        var variation = variations[itemIndex];
        if (variation.isDirty) {
            saveVariation(itemIndex, "email", -1);
        } else {
            emailVariation(variation);
        }
    }

    const emailVariation = async (variation: Variation) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var email = {
            JobId: props.jobId,
            VariationId: variation.id,
            Name: variation.name,
            subId: user.sub
        }

        axios.post("Variation/Email", email, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("Variation has been emailed Successfully");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end email variation

    const handleReportChange = (index: number, e: any) => {
        var rptId = e.target.value * 1;
        setReportId(rptId);

        //validate and save
        //if isDirty - ask if want to save changes
        var variation = variations[index];
        if (variation.isDirty) {
            saveVariation(index, "report", rptId);
        } else {
            runVariationReport(variation.id, rptId);
        }
    }

    const printVariationReport = (index: number, e: any) => {
        e.preventDefault();

        if (reportId > 0) {
            var variation = variations[index];
            runVariationReport(variation.id, reportId);
        } else {
            toast.error("Please select a variation report to run!");
        }
    }

    const runVariationReport = (variationId: number, reportId: number) => {
        switch (reportId) {
            case 1:
                var data: RunReportData = { variationId: variationId, reportName: "variationItemScope" };
                props.runReport(data);
                break;
            case 2:
                var data: RunReportData = { variationId: variationId, reportName: "variationTradeScope" };
                props.runReport(data);
                break;
            case 3:
                var data: RunReportData = { variationId: variationId, reportName: "variationTradeBreakdown" };
                props.runReport(data);
                break;
            default:
                break;
        }
    }

    //end run report

    //show text for report
    const showReportText = (itemIndex: number, e: any) => {
        e.preventDefault();
        var variation = variations[itemIndex];

        if (variation.id === 0 || variation.isDirty) {
            saveVariation(itemIndex, "reportText", -1);
        } else {
            openReportText(itemIndex);
        }

    }

    const openReportText = (itemIndex: number) => {
        var variation = variations[itemIndex];
        var rptText: EditNotes = {
            title: "Report Text",
            heading: "Enter text for Variation Scope Report",
            id: variation.id,
            rowIndex: itemIndex,
            notes: variation.reportText
        };

        setReportText({ editReportText: rptText, showEditReportText: true });
    }

    //edit report text modal
    const hideReportText = () => {
        var rptText = resetReportText();
        setReportText({ editReportText: rptText, showEditReportText: false });
    }

    const resetReportText = () => {
        var resetNotes: EditNotes = {
            title: "",
            heading: "",
            rowIndex: 0,
            id: 0,
            notes: ""
        }
        return resetNotes;
    }

    const updateReportText = (updatedNotes: EditNotes) => {
        var rptText = reportText.editReportText;
        rptText.notes = updatedNotes.notes;

        setReportText({ editReportText: rptText, showEditReportText: true });
    }

    const saveReportText = () => {
        saveVariationReportText();
    }

    const saveVariationReportText = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var vars = [...variations];
        var variation = vars[reportText.editReportText.rowIndex];
             
        var reportTextUpdate = {
            Id: variation.id,
            JobId: variation.jobId,
            ReportText: reportText.editReportText.notes,
            subId: user.sub
        };

        //save
        axios.post('Variation/SaveReportText', reportTextUpdate, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            toast.success("Variation Report Text Saved");
            variation.reportText = reportText.editReportText.notes;
            setVariations(vars);

            var rptText = resetReportText();
            setReportText({ editReportText: rptText, showEditReportText: false });
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end edit report text modal

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = variations;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length < 2) {
            errorString = "Please enter at least one Variation!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].name) {
                    newErrorString += " Name, ";
                    error = true;
                }

                if (newErrorString !== "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["variations"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        saveVariation(-1, "", -1);
    }

    const saveVariation = async (itemIndex: number, afterSave: string, reportId: number) => {
        if (validate()) {
            let vars = [...variations];
            //Only save items that have changed
            var changed = vars.filter(data => data.isDirty);
            if (changed.length === 0) {
                toast.info("No items have been changed!");
            } else {
                const token = await authService.getAccessToken();
                const user = await authService.getUser();

                var variationDetails = {
                    jobId: props.jobId,
                    variations: changed,
                    subId: user.sub
                };

                axios.post('Variation/Save', variationDetails, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        for (var i = 0; i < errors.length; i++) {
                            toast.error(errors[i].content);
                        };
                    } else {
                        toast.success("Variations Saved");
                        changed = res.data.variations;
                        //loop through the changed items and update the main list
                        for (var y = 0; y < changed.length; y++) {
                            vars[changed[y].itemIndex] = changed[y];
                        }
                        setVariations(vars);
                        setErrors({});

                        if (afterSave === "email") {
                            var variationemail = variations[itemIndex];
                            emailVariation(variationemail);
                        } else if (afterSave === "report") {
                            var variationreport = variations[itemIndex];
                            runVariationReport(variationreport.id, reportId);
                        } else if(afterSave === "reportText") {
                            openReportText(itemIndex);
                        }
                        else if (itemIndex >= 0) {
                            //open the details page
                            changed = res.data.variations;
                            //either get data from the state, or if item was saved then from res.data
                            var findRow = changed.filter(data => data.itemIndex === itemIndex);
                            var id = variations[itemIndex].id;
                            var name = variations[itemIndex].name;
                            var approved = variations[itemIndex].approved;
                            if (findRow.length > 0) {
                                id = findRow[0].id;
                                name = findRow[0].name;
                                approved = findRow[0].approved
                            }
                            openDetailsPage(id, name, approved);
                        }
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    let confirmPopup = <ConfirmModal heading="Delete Variation" text="Are you sure you want to delete this variation and all sub rows?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmUnapprovePopup = <ConfirmModal heading="Unapprove Variation" text="Are you sure you want to un-approve this variation?" hideConfirmModal={hideConfirmUnapproveModal} showConfirmModal={showConfirmUnapproveModal} noConfirmModal={hideConfirmUnapproveModal} yesConfirmModal={saveConfirmUnapproveModal} saveDisabled={modalSaveDisabled} />
    let editReportTextModal = <NoteModal showEditNotesModal={reportText.showEditReportText} editNotes={reportText.editReportText} maxLength={10000} hideNotes={hideReportText} updateNotes={updateReportText} saveNotes={saveReportText} />
    let lastRow = props.canEdit ? variations.length - 1 : variations.length;
    let maxNameLength = 300;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {confirmUnapprovePopup}
                {editReportTextModal}
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th>No</th>
                            <th>Name</th>
                            <th className="table--smallHeaderSize"></th>
                            <th className="table--smallHeaderSize">Rpt Text</th>
                            <th className="table--smallHeaderSize">Reports</th>
                            <th className={isSafari ? "table--smallHeaderSize" : "hidden"}>View</th>
                            <th className="table--smallHeaderSize">Approve</th>
                            <th className="textalignright table--smallHeaderSize">Sub Total</th>
                            <th className="textalignright table--smallHeaderSize">Mark-Up %</th>
                            <th className="textalignright table--smallHeaderSize">Sub Total ex Gst</th>
                            <th className="textalignright table--smallHeaderSize">Gst</th>
                            <th className="textalignright table--smallHeaderSize">Total</th>
                            <th className="table--smallHeaderSize"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {variations.map((variation, index) =>
                            <tr key={index}>
                                <td className="hidden">{variation.id}</td>
                                <td className="table__text--align">{variation.variationNumber}</td>
                                <td className="table__text--align">
                                    <input type="text" maxLength={maxNameLength} className={variation.approved || !props.canEdit ? "hidden" : "form-control tablerow__textmedium"} name="name" value={variation.name} onChange={(e) => handleCellChange(index, e)} />
                                    {variation.approved || !props.canEdit ? variation.name : ""}
                                </td>
                                <td className="table__text--align">
                                    <a className={index === lastRow ? "hidden" : ""} href="#" onClick={(e) => openDetails(variation.itemIndex, e)}>
                                        Open
                                    </a>
                                </td>
                                <td className="table__text--align">
                                    <a className={index === lastRow ? "hidden" : ""} href="#" onClick={(e) => showReportText(variation.itemIndex, e)}>
                                        <span className="fas fa-comment-alt edit--icon alignIconCenter"></span>
                                    </a>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="reportId" onChange={(e) => handleReportChange(index, e)}>
                                        <option defaultValue="-1" value="-1"></option>
                                        <option value={1}>Variation Scope</option>
                                        <option value={2}>Variation Trade Scope</option>
                                        <option value={3}>Variation Trade Breakdown</option>
                                    </select>
                                </td>
                                <td className={isSafari ? "table__text--align" : "hidden"}>
                                    <a href="#" onClick={(e) => printVariationReport(variation.itemIndex, e)}>
                                        <span className="fas fa-file-export edit--icon alignIconCenter"></span>
                                    </a>
                                </td>
                                <td className="table__text--align textaligncenter">
                                    <input type="checkbox" className={variation.id === 0 ? "hidden" : "input checkbox"} name="approved" checked={variation.approved} onChange={(e) => handleCellCheckboxUnapproveChange(index, e)} disabled={(variation.approved && !props.canUnapprove ) || !props.canEdit} />
                                </td>
                                <td className="table__text--align textalignright">{variation.subTotalFormatted}</td>
                                <td className="table__text--align textalignright">
                                    <input type="text" className={variation.approved || !props.canEdit ? "hidden" : "form-control textalignright tablerow__textsmall"} name="markUpPercentage" value={variation.markUpPercentage} onChange={(e) => handleCellChange(index, e)} />
                                    {variation.approved || !props.canEdit ? variation.markUpPercentage : ""}
                                </td>
                                <td className="table__text--align textalignright">{variation.subTotalExGstFormatted}</td>
                                <td className="table__text--align textalignright">{variation.gstFormatted}</td>
                                <td className="table__text--align textalignright">{variation.totalFormatted}</td>
                                <td className="table__text--align">
                                    <div className={index === lastRow || variation.approved || !props.canEdit ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(e, index)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["variations"] ?
                    (errors["variations"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div className="input-group-parent">
                <button className="defaultbutton" type="submit" disabled={!props.canEdit || saveDisabled}>Save</button>
            </div>
            <div className="input-group-parent">
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="subTotalExGstFormatted">
                    <span className="label">Sub Total (Approved)</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalExGstFormatted" name="subTotalExGstFormatted" value={totals.subTotalExGstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="gstFormatted">
                    <span className="label">GST (Approved)</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={totals.gstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label">Total (Approved)</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={totals.totalFormatted} disabled></input>
                </label>
            </div>
        </form>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default VariationListData;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import { toast } from 'react-toastify';

interface SignatureProps {
    heading: string;
    description: string;
    saveDisabled: boolean;
    showModal: boolean;
    hideModal(): void;
    save(fileUrl: string, name: string): void;
    updateSaveDisabled(): void;
}

const SignaturePopup = (props: SignatureProps) => {
    const sigPad = React.useRef() as React.MutableRefObject<SignaturePad>;
    const [signatureName, setSignatureName] = React.useState("");
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const clearSig = (e: any) => {
        e.preventDefault();

        clearSignature();
    }

    const clearSignature = () => {
        sigPad.current?.clear();
    }

    const handleNameChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }
        setSignatureName(e.target.value);
    }

    const hideModal = () => {
        props.hideModal();
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (!signatureName) {
            formIsValid = false;
            error["signatureName"] = "Name is required";
        }

        if (sigPad.current?.isEmpty()) {
            formIsValid = false;
            error["signature"] = "Signature is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            var fileToSave = sigPad.current?.getTrimmedCanvas().toDataURL('image/png') ?? "";

            props.save(fileToSave, signatureName);
        } else {
            toast.error("Fix the validation errors before Saving");
        }
        
    }

    return (
        <div>
            <Modal show={props.showModal} onHide={hideModal}>
                <Modal.Header className="modal__header--colour">
                    <Modal.Title>Signature</Modal.Title>
                    <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <h4>{props.heading}</h4>
                    <div className="marginBottom10">{props.description}</div>

                    <SignaturePad canvasProps={{ width: 330, height: 200, className: 'signature__border' }} ref={sigPad} />
                    <span className={errors["signature"] ? "label errors errors__leftmargin" : "hidden"}>{errors["signature"]}</span>

                    <label className="input-group" htmlFor="signatureName">
                        <span className="label">Name</span>
                        <input className='input' type='text' maxLength={200} id="signatureName" name="signatureName" value={signatureName} onChange={(e) => handleNameChange(e)} ></input>
                    </label>
                    <span className={errors["signatureName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["signatureName"]}</span>
                </Modal.Body>
                <Modal.Footer>
                    <button className="popup-button--no" onClick={clearSig}>Clear</button>
                    <button className="popup-button--yes" onClick={save} disabled={props.saveDisabled}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default SignaturePopup;
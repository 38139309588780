import PhotoImportDetailPopup from './PhotoImportDetail';

interface JobAssessmentPhotosProps {
    id: number;
    uploadedFiles: UploadedFile[];
    loadingPhotos: boolean;
    update(selectedFiles: UploadedFile[]): void;
    importPhotos(files: File[]): void;
    delete(deleteIndex: number): void;
    save(): void;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const JobAssessmentPhotosData = (props: JobAssessmentPhotosProps) => {

    const save = (e: any) => {
        e.preventDefault();

        props.save();
    }

    var photoImportDetail = <PhotoImportDetailPopup title={"Upload Report Photos"} showLoading={props.loadingPhotos} uploadedFiles={props.uploadedFiles} importPhotos={props.importPhotos} update={props.update} delete={props.delete} />

    return (
        <div className="assessment__photo--width">
            {photoImportDetail}

            <button className="defaultbutton marginTop10" type="button" onClick={save}>Finish</button>
        </div>
    )
}

export default JobAssessmentPhotosData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import ScheduleCalendarAddAppointmentData from './ScheduleCalendarAddAppointment';
import ScheduleCalendarAppointmentPopup from './ScheduleCalendarAppointment';

const months: monthItem[] = [{ id: 1, name: "January" }, { id: 2, name: "February" }, { id: 3, name: "March" }, { id: 4, name: "April" }, { id: 5, name: "May" }, { id: 6, name: "June" }, { id: 7, name: "July" }, { id: 8, name: "August" }, { id: 9, name: "September" }, { id: 10, name: "October" }, { id: 11, name: "November" }, { id: 12, name: "December" }];
const years: number[] = [2022, 2023, 2024, 2025, 2026, 2027, 2028];

interface monthItem {
    id: number;
    name: string;
}

interface CalendarRow {
    rowNumber: number;
    items: CalendarItem[];
}

interface CalendarItem {
    key: number;        //number for unique key for table
    month: number;
    day: number;
    today: boolean;
    appointments: CalendarAppointment[];
}

interface CalendarAppointment {
    id: number;
    startTimeFormatted: string;
    startDateTimeFormatted: string;
    endDateTimeFormatted: string;
    title: string;
    jobId: number;
    location: string;
    notes: string;
}



const HoverText = () => {
    return (
        <div>
            Hovering right meow!
            <span role="img" aria-label="cat">
                🐱
            </span>
        </div>
    );
};

const ScheduleCalendarData = () => {
    const [loading, setLoading] = React.useState(true);
    const [calendarLoading, setCalendarLoading] = React.useState(false);
    const [calendarRows, setCalendarRows] = React.useState<CalendarRow[]>([]);
    const [monthId, setMonthId] = React.useState(8);
    const [yearId, setYearId] = React.useState(2023);
    const [showAddAppointment, setShowAddAppointment] = React.useState(false);
    const [showAppointment, setShowAppointment] = React.useState(false);
    //const apptRef = React.useRef();

    //const eventHandlers = React.useMemo(
    //    () => ({
    //        mouseover() {
    //            if (apptRef) setShowAppointment(true);
    //        },
    //        mouseout() {
    //            if (apptRef) setShowAppointment(false);
    //        }
    //    }),
    //    []
    //);



    //const markerRef = useRef();

    //const eventHandlers = useMemo(
    //    () => ({
    //        mouseover() {
    //            if (markerRef) markerRef.current.openPopup();
    //        },
    //        mouseout() {
    //            if (markerRef) markerRef.current.closePopup();
    //        }
    //    }),
    //    []
    //);

    //return (
    //    <Marker
    //        ref={markerRef}
    //        position={position}
    //        icon={icon}
    //        eventHandlers={eventHandlers}
    //    >
    //        <Popup>Hello</Popup>
    //    </Marker>
    //);


    React.useEffect(() => {
        var date = new Date();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        getData(month, year);
    }, [])

    const getData = async (month: number, year: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        setCalendarLoading(true);

        axios.get('Calendar/GetAppointments?Month=' + month + '&Year=' + year + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setMonthId(month);
            setYearId(year);
            setCalendarRows(res.data);
            setLoading(false);
            setCalendarLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const goNextMonth = () => {
        var month = monthId;
        var year = yearId;
        if (month === 12) {
            month = 1;
            year += 1;
            setMonthId(month);
            setYearId(year);
        } else {
            month += 1;
            setMonthId(month);
        }
        getData(month, year);
    }

    const goPrevMont = () => {
        var month = monthId;
        var year = yearId;
        if (monthId === 1) {
            month = 12;
            year -= 1;
            setMonthId(month);
            setYearId(year);
        } else {
            month -= 1;
            setMonthId(month);
        }
        getData(month, year);
    }

    const handleMonthChange = (e: any) => {
        var month = e.target.value * 1;
        setMonthId(month);
        getData(month, yearId);
    }

    const handleYearChange = (e: any) => {
        var year = e.target.value * 1;
        setYearId(year);
        getData(monthId, year);
    }

    const addAppointment = () => {
        setShowAddAppointment(true);
    }

    const hideAddAppointment = () => {
        setShowAddAppointment(false);
    }

    const saveAddAppointment = () => {
        setShowAddAppointment(false);
        getData(monthId, yearId);
    }

    const showAppt = () => {
        setShowAppointment(true);
    }

    const hideAppointment = () => {
        setShowAppointment(false);
    }

    const addAppt = <ScheduleCalendarAddAppointmentData showModal={showAddAppointment} hideModal={hideAddAppointment} saveModal={saveAddAppointment} />
    //const viewAppt = <ScheduleCalendarAppointmentPopup showModal={showAppointment} hideModal={hideAppointment} notes="testing" />
    const rows = calendarRows.length;

    const renderDetails = (
        <div>
            <div className="static-modal">
                {addAppt}
                {/*{viewAppt}*/}
            </div>
            <div className="makeitflexspacebetween marginTop10">
                <div className="makeitflexcenter marginTop10 marginBottom10">
                    <i className="fa-solid fa-circle-left calendar--icon marginRight10 mobile-icon-green" onClick={(e) => goPrevMont()}></i>
                    <select className="select marginRight10" id="monthId" name="monthId" value={monthId} onChange={(e) => handleMonthChange(e)}  >
                        <option hidden defaultValue=""></option>
                        {months.map(month =>
                            <option key={month.id} value={month.id}>{month.name}</option>
                        )};
                    </select>
                    <select className="select marginRight10" id="yearId" name="yearId" value={yearId} onChange={(e) => handleYearChange(e)} >
                        <option hidden defaultValue="2023"></option>
                        {years.map(year =>
                            <option key={year} value={year}>{year}</option>
                        )};
                    </select>
                    <i className="fa-solid fa-circle-right calendar--icon mobile-icon-green" onClick={(e) => goNextMonth()}></i>
                </div>
                <button className="defaultbutton" type="button" onClick={addAppointment}>Add Appointment</button>
            </div>
            <div className={calendarLoading ? "hidden" : "overflowAuto"}>
                <table className="table--main calendar-table-height">
                    <thead>
                        <tr>
                            <th className="calendar-header--border">Mon</th>
                            <th className="calendar-header--border">Tue</th>
                            <th className="calendar-header--border">Wed</th>
                            <th className="calendar-header--border">Thu</th>
                            <th className="calendar-header--border">Fri</th>
                            <th className="calendar-header--border">Sat</th>
                            <th className="calendar-header--border">Sun</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calendarRows.map((row, index) =>
                            <tr key={row.rowNumber}>
                                {row.items.map((item, index) =>
                                    <td className={"calendar-td-width " + (rows === 4 ? "calendar-td-height-4" : rows === 5 ? "calendar-td-height-5" : rows === 6 ? "calendar-td-height-6" : "calendar-td-height-5")} key={index}>
                                        <div className="calendar-day-height">
                                            <span className={"calendar-day--pos " + (item.today ? "calendar-today" : "")}>{item.day === 0 ? "" : item.day}</span>
                                        </div>
                                        <div className="calendar-appointment-colours calendar-appt-scroll">
                                            {item.appointments.map((appt, index) =>
                                                <div key={appt.id} className="marginBottom5 calendar-appt-shape" onMouseEnter={() => setShowAppointment(true)} onMouseLeave={() => setShowAppointment(false)}>
                                                    <span className="block">{appt.startTimeFormatted} - {appt.title}</span>
                                                    <span><a href={"http://maps.google.com/?q=" + appt.location} target="_blank">{appt.location}</a></span>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={calendarLoading ? "" : "hidden"}>
                <p><em>Loading...</em></p>
            </div>
        </div>
    
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ScheduleCalendarData;

import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface NotesModalProps {
    showEditNotesModal: boolean;
    editNotes: EditNotes;
    maxLength: number;
    hideNotes(): void;
    updateNotes(editNotes: EditNotes): void;
    saveNotes(): void;
}

interface EditNotes {
    title: string;
    heading: string;
    rowIndex: number;
    id: number;
    notes: string;
}

const NoteModal = (props: NotesModalProps) => {
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const handleChange = (e: any) => {
        var editNotes: EditNotes = props.editNotes;
        editNotes.notes = e.target.value;

        setSaveDisabled(false);
        props.updateNotes(editNotes);
    }

    const hideEditNotesModal = () => {
        props.hideNotes();
    }

    const saveEditNotes = () => {
        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);

        props.saveNotes();
    }

    let title = props.editNotes.title == "" ? "Edit Notes" : props.editNotes.title;
    let heading = props.editNotes.heading == "" ? "Enter Notes" : props.editNotes.heading;

    return (
        <Modal size="lg" show={props.showEditNotesModal} onHide={hideEditNotesModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>{title}</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideEditNotesModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <span className="label">{heading}</span>
                <textarea className="input notesModalSize" rows={8} maxLength={props.maxLength} id="editNotes" name="notes" value={props.editNotes.notes} onChange={(e) => handleChange(e)}></textarea>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={saveEditNotes} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )
}
export default NoteModal;
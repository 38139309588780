import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobTabs from './JobTabs';
import VariationListData from './JobVariationList';
import VariationHeaderData from './JobVariationHeader';
import VariationDetailsData from './JobVariationDetails';
import VariationDetailsSplitData from './JobVariationDetailsSplit';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface GenericListItem {
    id: number;
    name: string;
}

interface RunReportData {
    variationId: number;
    reportName: string;
}

interface PageChangeData {
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    detailId: number;
    detailName: string;
    costCodeId: number;
    approved: boolean; 
    page: string;
}

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

const VariationData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [pagePermissions, setPagePermissions] = React.useState({ canEdit: false, canUnapprove: false });
    const [costCodeList, setCostCodeList] = React.useState<GenericListItem[]>([]);
    const [uomList, setUomList] = React.useState<GenericListItem[]>([]);
    const [pcpsList, setPcpsList] = React.useState<GenericListItem[]>([]);
    const [labMatList, setLabMatList] = React.useState<GenericListItem[]>([]);
    const [variationData, setVariationData] = React.useState<PageChangeData>({
        variationId: 0,
        variationName: "",
        headerId: 0,
        headerName: "",
        detailId: 0,
        detailName: "",
        costCodeId: -1,
        approved: false,
        page: "list"
    });

    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save an audit trail note of who has accessed job details
        var auditTrail = {
            typeId: 6,
            linkId: Id,
            subId: user.sub
        };

        axios.post('Shared/SaveAuditTrail', auditTrail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //not doing anything if the audit trail doesn't work
        });

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=ViewJobVariations', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get user permissions and dropdowns
                axios.get('Variation/GetScreenData?JobId=' + Id + '&SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        setPagePermissions({ canEdit: res.data.canEdit, canUnapprove: res.data.canUnapprove });
                        setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                        setCostCodeList(res.data.costCodeList);
                        setUomList(res.data.uomList);
                        setPcpsList(res.data.pcPsList);
                        setLabMatList(res.data.labMatList);
                        setPermissions(res.data.userJobPermissions);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const tabChanged = (url: string) => {
        //upper tab changed - details, estimate, costings etc
        navigate(url);
    }

    const pageChanged = (pageData: PageChangeData) => {
        //variation page changed - headers, details, split
        setVariationData(pageData);
    }

    const runReport = (data: RunReportData) => {
        var url = "/job/" + jobData.jobId + "/report/" + data.variationId + "/" + data.reportName;
        window.open(url, '_blank');
    }

    var tabContents;
    if (variationData.page === "list") {
        tabContents = <VariationListData canEdit={pagePermissions.canEdit} canUnapprove={pagePermissions.canUnapprove} jobId={jobData.jobId} changePage={pageChanged} runReport={runReport} />;
    }
    else if (variationData.page === "header") {
        tabContents = <VariationHeaderData canEdit={pagePermissions.canEdit} jobId={jobData.jobId} variationId={variationData.variationId} variationName={variationData.variationName} approved={variationData.approved} changePage={pageChanged} />
    }
    else if (variationData.page === "details") {
        tabContents = <VariationDetailsData canEdit={pagePermissions.canEdit} variationId={variationData.variationId} variationName={variationData.variationName} headerId={variationData.headerId} headerName={variationData.headerName} jobId={jobData.jobId} approved={variationData.approved} costCodeList={costCodeList} uomList={uomList} pcpsList={pcpsList} changePage={pageChanged} />
    }
    else if (variationData.page === "split") {
        tabContents = <VariationDetailsSplitData canEdit={pagePermissions.canEdit} variationId={variationData.variationId} variationName={variationData.variationName} headerId={variationData.headerId} headerName={variationData.headerName} detailId={variationData.detailId} detailName={variationData.detailName} costCodeId={variationData.costCodeId} jobId={jobData.jobId} approved={variationData.approved} costCodeList={costCodeList} uomList={uomList} labMatList={labMatList} changePage={pageChanged} />
    }
    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="variations" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />

    const renderDetails = (
        <div>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                {tabContents}
            </div>
        </div>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default VariationData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface UserProps {
    id: number;
    userDetails: UserDetails;
    statusOptions: GenericListItem[];
    update(userDetails: UserDetails): void;
    save(): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface UserDetails {
    id: number;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    password: string;
    confirmPassword: string;
    statusId: number;
}

const UserDetailData = (props: UserProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        var user = props.userDetails;
        let newUser: any = {};
        newUser = user;
        newUser[e.target.name] = e.target.value;

        setSaveDisabled(false);
        props.update(user);
    }

    const resetPassword = () => {
        resetUserPassword();
    }

    const resetUserPassword = async() => {
        const token = await authService.getAccessToken();

        var details = {
            "email": props.userDetails.email
        };

        axios.post('Account/ForgotPassword', details, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Reset Password Email sent to " + props.userDetails.email);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const validate = () => {
        let fields = props.userDetails;
        let error: any = {};
        let formIsValid = true;

        if (!fields.firstName) {
            formIsValid = false;
            error["firstName"] = "First Name is required";
        }
        if (!fields.lastName) {
            formIsValid = false;
            error["lastName"] = "Last Name is required";
        }
        if (!fields.email) {
            formIsValid = false;
            error["email"] = "Email is required";
        }
        if (props.userDetails.id == 0) {
            //check password - if existing user, separate form to change password
            if (!fields.password) {
                formIsValid = false;
                error["password"] = "Password is required";
            }
            if (!fields.confirmPassword) {
                formIsValid = false;
                error["confirmPassword"] = "Confirm Password is required";
            }
            if (fields.password && fields.confirmPassword) {
                //check password and confirm password match
                if (fields.password != fields.confirmPassword) {
                    formIsValid = false;
                    error["confirmPassword"] = "Password and Confirm Password must match";
                }
            }
        }
        if (fields.statusId < 0) {
            formIsValid = false;
            errors["statusId"] = "Status is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            props.save();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    return (
        <form name="user" onSubmit={save}>
            <div>
                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className="input" type="text" maxLength={200} id="email" name="email" value={props.userDetails.email} onChange={(e) => handleChange(e)} disabled={props.userDetails.id == 0 ? false : true}></input>
                </label>
                <span className={errors["email"] ? "label errors errors__leftmargin" : "hidden"}>{errors["email"]}</span>
                    
                <div className={props.userDetails.id == 0 ? "" : "hidden"}>
                    <label className="input-group" htmlFor="password">
                        <span className="label">Password</span>
                        <input className="input" type="password" id="password" name="password" value={props.userDetails.password} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["password"] ? "label errors errors__leftmargin" : "hidden"}>{errors["password"]}</span>
                </div>
                <div className={props.userDetails.id == 0 ? "" : "hidden"}>
                    <label className="input-group" htmlFor="confirmPassword">
                        <span className="label">Confirm Password</span>
                        <input className="input" type="password" id="confirmPassword" name="confirmPassword" value={props.userDetails.confirmPassword} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["confirmPassword"] ? "label errors errors__leftmargin" : "hidden"}>{errors["confirmPassword"]}</span>
                </div>

                <label className="input-group" htmlFor="firstName">
                    <span className="label">First Name</span>
                    <input className="input" type="text" maxLength={100} id="firstName" name="firstName" value={props.userDetails.firstName} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["firstName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["firstName"]}</span>
                   
                <label className="input-group" htmlFor="lastName">
                    <span className="label">Last Name</span>
                    <input className="input" type="text" maxLength={100} id="lastName" name="lastName" value={props.userDetails.lastName} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["lastName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["lastName"]}</span>
                   
                <label className="input-group" htmlFor="mobile">
                    <span className="label">Mobile</span>
                    <input className="input" type="text" maxLength={30} id="mobile" name="mobile" value={props.userDetails.mobile} onChange={(e) => handleChange(e)}></input>
                </label>
                    
                <label className="input-group" htmlFor="statusId">
                    <span className="label">Status</span>
                    <select className="select" id="statusId" name="statusId" value={props.userDetails.statusId} onChange={(e) => handleChange(e)} disabled={props.userDetails.id == 0 ? true : false}>
                        {props.statusOptions.map(status =>
                            <option key={status.id} value={status.id}>{status.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["statusId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["statusId"]}</span>

                <button className="defaultbutton-prev user__reset-position" type="button" onClick={resetPassword}>Reset Password</button>
                <button className="defaultbutton-next" type="submit" disabled={ saveDisabled }>Save</button>
            </div>
        </form>
    );
}

export default UserDetailData;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import GoogleAutoComplete from './GoogleAutoComplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface AddXeroContactPopupProps {
    jobId: number;
    showUseCustomer: boolean;
    show: boolean;
    hide(): void;
    save(id: string, name: string): void;
}

interface Contact {
    contactName: string;
    firstName: string;
    lastName: string;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    phone: string;
    email: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const AddXeroContactPopup = (props: AddXeroContactPopupProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [useCustomer, setUseCustomer] = React.useState(false);
    const [xeroContact, setXeroContact] = React.useState<Contact>({
        contactName: "",
        firstName: "",
        lastName: "",
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        postCode: "",
        country: "",
        phone: "",
        email: ""
    });
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    
    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setXeroContact(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        setSaveDisabled(false);
    }

    const handleCheckboxChange = (e: any) => {
        setUseCustomer(e.target.checked);
        if (e.target.checked) {
            getCustomerDetails();
        }
    }

    const getCustomerDetails = async () => {
        const token = await authService.getAccessToken();

        //GetCustomerDetails
        axios.get('Jobs/GetCustomerDetails?JobId=' + props.jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var contactName = res.data.companyName === "" ? res.data.customerName : res.data.companyName;

            setXeroContact(prevState => ({
                ...prevState,
                contactName: contactName,
                firstName: res.data.customerName,
                lastName: "",
                address: res.data.address,
                streetNumber: res.data.streetNumber,
                streetName: res.data.streetName,
                suburb: res.data.suburb,
                state: res.data.state,
                postCode: res.data.postCode,
                country: res.data.country,
                phone: res.data.phone,
                email: res.data.email
            }));
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        setXeroContact(prevState => ({
            ...prevState,
            address: addressDetails.fullAddress,
            streetNumber: addressDetails.street_number,
            streetName: addressDetails.route,
            suburb: addressDetails.locality,
            state: addressDetails.administrative_area_level_1,
            country: addressDetails.country,
            postCode: addressDetails.postal_code
        }));
    }

    const resetContact = () => {
        let contact: Contact = {
            contactName: "",
            firstName: "",
            lastName: "",
            address: "",
            streetNumber: "",
            streetName: "",
            suburb: "",
            state: "",
            postCode: "",
            country: "",
            phone: "",
            email: ""
        };
        return contact;
    }

    const validate = () => {
        let fields = xeroContact;
        let formIsValid = true;
        let error: any = {};

        if (!fields.contactName) {
            formIsValid = false;
            error["contactName"] = "Contact Name is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = () => {
        //validate all data before saving
        if (validate()) {
            saveAddXeroCustomer();
        } else {
            toast.error("Please fix the validation issues before saving");
        }

    }

    const saveAddXeroCustomer = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var newXeroContact = {
            contact: xeroContact,
            subId: user.sub
        };

        //save new contact
        axios.post('Xero/AddNewContact', newXeroContact, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });
                } else {
                    toast.success("Xero Contact Added Successfully");

                    var xeroNewContact = res.data.customer;
                    setXeroContact(resetContact());
                    setSaveDisabled(false);
                    setUseCustomer(false);
                    setErrors({});
                    props.save(xeroNewContact.id, xeroNewContact.name);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    const hideModal = () => {
        setErrors({});
        setXeroContact(resetContact());
        setSaveDisabled(false);
        setUseCustomer(false);
        props.hide();
    }

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Address"} placeholder={xeroContact.address} className="input-group" disabled={false} save={setAddress} />

    return (
        <Modal show={props.show} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add New Xero Contact</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <label className={props.showUseCustomer ? "input-group" : "hidden"} htmlFor="useCustomer">
                    <span className="label">Use Customer Details?</span>
                    <input className="checkbox" type="checkbox" id="useCustomer" name="useCustomer" checked={useCustomer} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="contactName">
                    <span className="label">Contact Name</span>
                    <input className='input' type='text' maxLength={255} id="contactName" name="contactName" value={xeroContact.contactName} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["contactName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["contactName"]}</span>

                <label className="input-group" htmlFor="firstName">
                    <span className="label">First Name</span>
                    <input className='input' type='text' maxLength={255} id="firstName" name="firstName" value={xeroContact.firstName} onChange={(e) => handleChange(e)}></input>
                </label>
                    
                <label className="input-group" htmlFor="lastName">
                    <span className="label">Last Name</span>
                    <input className='input' type='text' maxLength={255} id="lastName" name="lastName" value={xeroContact.lastName} onChange={(e) => handleChange(e)}></input>
                </label>

                {googleContents}

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className='input' type='text' maxLength={50} id="phone" name="phone" value={xeroContact.phone} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className='input' type='text' maxLength={255} id="email" name="email" value={xeroContact.email} onChange={(e) => handleChange(e)}></input>
                </label>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={hideModal}>Cancel</button>
                <button className="popup-button--yes" onClick={save} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )

}

export default AddXeroContactPopup;
import * as React from 'react';

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplierId: number;
    supplier: string;
    supplierContactId: number;
    supplierContact: string;
    certificateExpiryDate: string;
    validCertificate: boolean;
    certificateExpiryText: string;
    registeredForGst: boolean;
    dateRequired: string;
    dateRequiredFormatted: string;
    includeFullScope: boolean;
    instructions: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    statusId: number;
}

interface PurchaseOrderDetail {
    itemIndex: number;
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    costCodeIdOrig: number;     //need to put back original values if they click back button
    costCodeOrig: string;
    quantityOrig: number;
    quantityTypeOrig: string;
    unitOfMeasureOrig: number;
    unitCostOrig: number;
    unitCostOrigFormatted: string;
    totalOrig: number;
    totalOrigFormatted: string;
    exceedForecastReason: string;
    newItem: boolean;
}

type JobPurchaseOrder = {
    purchaseOrder: PurchaseOrder;
    purchaseOrderDetails: PurchaseOrderDetail[];
    isLoading: boolean;
    error?: string;
}

const initialPo: JobPurchaseOrder = {
    purchaseOrder: {
        id: 0,
        jobId: 0,
        supplierId: -1,
        supplier: "",
        supplierContactId: -1,
        supplierContact: "",
        certificateExpiryDate: "",
        certificateExpiryText: "",
        validCertificate: false,
        registeredForGst: false,
        dateRequired: "",
        dateRequiredFormatted: "",
        includeFullScope: false,
        instructions: "As per scope of works",
        subTotalFormatted: "",
        gstFormatted: "",
        totalFormatted: "",
        statusId: 1
    },
    purchaseOrderDetails: [{
        itemIndex: 0,
        id: 0,
        purchaseOrderId: 0,
        costCodeId: -1,
        costCode: "",
        budget: 0,
        budgetFormatted: "",
        forecast: 0,
        forecastFormatted: "",
        remaining: 0,
        remainingFormatted: "",
        budgetRemaining: 0,
        quantity: 0,
        quantityType: "",
        unitOfMeasure: -1,
        unitCost: 0,
        unitCostFormatted: "$0.00",
        total: 0,
        totalFormatted: "$0.00",
        costCodeIdOrig: -1,
        costCodeOrig: "",
        quantityOrig: 0,
        quantityTypeOrig: "",
        unitOfMeasureOrig: -1,
        unitCostOrig: 0,
        unitCostOrigFormatted: "$0.00",
        totalOrig: 0,
        totalOrigFormatted: "$0.00",
        exceedForecastReason: "",
        newItem: false
    }],
    isLoading: false,
    error: ""
}

type Action =
    | { type: 'getDetails' }
    | { type: 'updatePo', purchaseOrder: PurchaseOrder }
    | { type: 'updatePoDetails', purchaseOrderDetails: PurchaseOrderDetail[] }
    | { type: 'reset' }
    | { type: 'error', error: "" };

const purchaseOrderReducer = (jobPo: JobPurchaseOrder = initialPo, action: Action): JobPurchaseOrder => {
    switch (action.type) {
        case 'getDetails':
            return { ...jobPo, isLoading: true };
        case 'updatePo':
            return { ...jobPo, isLoading: false, purchaseOrder: action.purchaseOrder };
        case 'updatePoDetails':
            return { ...jobPo, isLoading: false, purchaseOrderDetails: action.purchaseOrderDetails };
        case 'reset':
            return initialPo;
        case 'error':
            return { ...jobPo, isLoading: false, error: action.error };
        default:
            return jobPo;
    }
}

export default purchaseOrderReducer; 

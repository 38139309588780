import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';

interface VariationDetailsSplitProps {
    canEdit: boolean;
    jobId: number;
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    detailId: number;
    detailName: string
    costCodeId: number;         //variation detail selected cost code - preset
    approved: boolean;
    costCodeList: GenericListItem[],
    uomList: GenericListItem[],
    labMatList: GenericListItem[],
    changePage(pageData: PageChangeData): void;
}

interface VariationDetailsSplit {
    itemIndex: number;
    id: number;
    detailId: number;
    scope: string;
    scopeOrder: number;
    costCodeId: number;
    costCode: string;
    labMatId: number;
    labMat: string;
    quantity: number;
    unitOfMeasureId: number;
    unitOfMeasure: string;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    isDirty: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface PageChangeData {
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    detailId: number;
    detailName: string;
    approved: boolean;
    page: string;
}

const VariationDetailsSplitData = (props: VariationDetailsSplitProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [variationDetailsSplit, setvariationDetailsSplit] = React.useState<VariationDetailsSplit[]>([]);
    const [totalFormatted, setTotalFormatted] = React.useState("$0.00");
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [variationIndex, setVariationIndex] = React.useState(-1);

    React.useEffect(() => {
        getVariationSplitDetails();
    }, [props.detailId]);

    const getVariationSplitDetails = async () => {
        const token = await authService.getAccessToken();
        var detailId = props.detailId;
        axios.get('Variation/GetVariationDetailSplit?DetailId=' + detailId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //add blank header row
            var detailSplit = res.data.variationDetailSplit;
            //update item index
            for (var i = 0; i < detailSplit.length; i++) {
                detailSplit[i].itemIndex = i;
            }
            if (!props.approved && props.canEdit) {
                var blankRow = addBlankRow(detailSplit.length);
                detailSplit.push(blankRow);
            }

            setvariationDetailsSplit(detailSplit);
            setTotalFormatted(res.data.totalFormatted);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var blankRow: VariationDetailsSplit = {
            itemIndex: nextItemIndex,
            id: 0,
            detailId: props.detailId,
            scope: "",
            scopeOrder: 0,
            costCodeId: props.costCodeId,
            costCode: "",
            labMatId: -1,
            labMat: "",
            quantity: 0,
            unitOfMeasureId: -1,
            unitOfMeasure: "",
            unitCost: 0,
            unitCostFormatted: "",
            total: 0,
            totalFormatted: "",
            isDirty: false
        }
        return blankRow;
    }

    //BACK Button
    const back = () => {
        //if isDirty - ask if want to save changes
        var changed = variationDetailsSplit.filter(data => data.isDirty);
        if (changed.length > 0) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);
            saveVariation(true);
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            variationId: props.variationId,
            variationName: props.variationName,
            headerId: props.headerId,
            headerName: props.headerName,
            detailId: props.detailId,
            detailName: props.detailName,
            approved: props.approved,
            page: "details"
        };

        //validate and save before opening
        props.changePage(pageChange);
    }
    //End BACK Button

    //Change Events
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var varSplits = [...variationDetailsSplit];
        var split = varSplits[index];
        let exist: any = {};
        exist = split;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (e.target.name === "labMatId" || e.target.name === "costCodeId" || e.target.name === "unitOfMeasureId") {
            var itemName = e.target.name.replace("Id", "");
            exist[itemName] = e.target.options[e.target.selectedIndex].text;
        }

        if (index === (varSplits.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(varSplits.length);
            varSplits.push(newRow);
        }

        setvariationDetailsSplit(varSplits);
        setSaveDisabled(false);
        setErrors({});
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var varSplits = [...variationDetailsSplit];
        var split = varSplits[index];
        split.isDirty = true;

        let value = e.target.value * 1;

        //calculate unit cost / total
        if (e.target.name === "total") {
            let total = value;
            split.total = value;
            split.totalFormatted = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });

            //set quantity to 1 if its 0 and total changed
            let qty = split.quantity <= 0 ? 1 : split.quantity;
            split.quantity = qty;

            let unitCost = value / qty;
            split.unitCost = Number(unitCost.toFixed(2));
            split.unitCostFormatted = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        } else if (e.target.name === "unitCost") {
            let unitCost = value;
            split.unitCost = unitCost;
            split.unitCostFormatted = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });

            //set quantity to 1 if its 0 and total changed
            let qty = split.quantity <= 0 ? 1 : split.quantity;
            split.quantity = qty;

            let total = value * qty;
            split.total = Number(total.toFixed(2));
            split.totalFormatted = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        } else {
            //quantity
            split.quantity = value;

            let total = value * split.unitCost;
            let totaldb = total.toFixed(2);
            split.total = parseFloat(totaldb);
            split.totalFormatted = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        }

        if (index === (varSplits.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(varSplits.length);
            varSplits.push(newRow);
        }

        var total = (calculateTotal(varSplits)).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        setvariationDetailsSplit(varSplits);
        setTotalFormatted(total);
        setSaveDisabled(false);
        setErrors({});
    }

    const calculateTotal = (varDetailSplit: VariationDetailsSplit[]) => {
        var total = 0;
        for (var i = 0; i < varDetailSplit.length - 1; i++) {
            total += parseFloat((varDetailSplit[i].quantity * varDetailSplit[i].unitCost).toFixed(2));
        }
        return total;
    }
    //End Change Events

    //delete row
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setVariationIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setVariationIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        var varSplits = [...variationDetailsSplit];
        var varSplit = varSplits[variationIndex];

        if (varSplit.id === 0) {
            deleteVariationDetailUpdateOrder(varSplits);
        } else {
            deleteVariationDetail(varSplits, varSplit.id);
        }
    }

    const deleteVariationDetailUpdateOrder = async (varDetailsSplit: VariationDetailsSplit[]) => {
        const token = await authService.getAccessToken();

        var total = (calculateTotal(variationDetailsSplit)).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        //remove from list
        var removedItem = varDetailsSplit.splice(variationIndex, 1);

        //loop through the details and update the order
        var hasBlankRow = !props.approved && props.canEdit;
        var length = hasBlankRow ? varDetailsSplit.length - 1 : varDetailsSplit.length;
        for (var i = 0; i < length; i++) {
            varDetailsSplit[i].itemIndex = (i);
            varDetailsSplit[i].scopeOrder = (i + 1);
        }

        //save new details order
        axios.post('Variation/DeleteVariationDetailSplitUpdateOrder', varDetailsSplit, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                varDetailsSplit.splice(variationIndex, 0, removedItem[0]);
                setvariationDetailsSplit(varDetailsSplit);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            } else {
                setvariationDetailsSplit(varDetailsSplit);
                setTotalFormatted(total);
                setShowConfirmModal(false);
                setVariationIndex(-1);

                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteVariationDetail = async (varDetailsSplit: VariationDetailsSplit[], varDetailSplitId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var deleteDetail = {
            variationId: props.variationId,
            id: varDetailSplitId,
            jobId: props.jobId,
            variationDetailsOrder: varDetailsSplit,
            subId: user.sub
        };

        //remove from list
        var removedItem = varDetailsSplit.splice(variationIndex, 1);

        //loop through the details and update the order
        var hasBlankRow = !props.approved && props.canEdit;
        var length = hasBlankRow ? varDetailsSplit.length - 1 : varDetailsSplit.length;
        for (var i = 0; i < length; i++) {
            varDetailsSplit[i].itemIndex = (i);
            varDetailsSplit[i].scopeOrder = (i + 1);
        }

        axios.post('Variation/DeleteVariationDetailSplit', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                varDetailsSplit.splice(variationIndex, 0, removedItem[0]);
                setvariationDetailsSplit(varDetailsSplit);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            } else {
                toast.success("Item has been deleted");

                var total = (calculateTotal(variationDetailsSplit)).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });

                setvariationDetailsSplit(varDetailsSplit);
                setTotalFormatted(total);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end delete row

    //save
    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = variationDetailsSplit;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length < 2) {
            errorString = "Please enter at least one Item!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].scope) {
                    newErrorString += " Scope, ";
                    error = true;
                }

                if (items[i].costCodeId < 0) {
                    newErrorString += " Cost Code, ";
                    error = true;
                }

                if (items[i].labMatId < 0) {
                    newErrorString += " Lab/Mat Split, ";
                    error = true;
                }

                if (!items[i].quantity || items[i].quantity === 0) {
                    newErrorString += " Quantity, ";
                    error = true;
                }

                if (!items[i].unitCost || items[i].unitCost === 0) {
                    newErrorString += " Unit Cost, ";
                    error = true;
                }

                if (newErrorString !== "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["split"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        saveVariation(false);
    }

    const saveVariation = (goBack: boolean) => {
        if (validate()) {
            var splits = [...variationDetailsSplit];

            //Only save items that have changed
            var changed = splits.filter(data => data.isDirty);
            if (changed.length === 0) {
                toast.info("No items have been changed!");
            } else {
                saveVariationDetailsSplit(goBack);
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveVariationDetailsSplit = async (goBackLevel: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //Only save items that have changed
        var changed = variationDetailsSplit.filter(data => data.isDirty);

        var varDetailSplit = {
            variationId: props.variationId,
            detailId: props.detailId,
            variationDetailSplit: changed,
            subId: user.sub
        };

        axios.post('Variation/SaveVariationDetailsSplit', varDetailSplit, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Variation Saved");
                if (goBackLevel) {
                    goBack();
                } else {
                    //reload the screen to get ids, totals etc
                    getVariationSplitDetails();
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end save

    //MOVE ROW
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var varDetailsSplit = [...variationDetailsSplit];
        var hasBlankRow = !props.approved && props.canEdit;

        var varDetailsLength = varDetailsSplit.length - 1;

        if ((hasBlankRow && oldIndex != varDetailsLength && newIndex != varDetailsLength) || !hasBlankRow) {
            //only move if not blank row
            varDetailsSplit.splice(newIndex, 0, varDetailsSplit.splice(oldIndex, 1)[0]);

            //loop through the headers and update the order
            var length = hasBlankRow ? varDetailsSplit.length - 1 : varDetailsSplit.length;
            for (var i = 0; i < length; i++) {
                varDetailsSplit[i].itemIndex = (i);
                varDetailsSplit[i].scopeOrder = (i + 1);
                varDetailsSplit[i].isDirty = true;
            }

            setvariationDetailsSplit(varDetailsSplit);
            saveVariation(false);
        }
    };
    //END MOVE ROW

    let confirmPopup = <ConfirmModal heading="Delete Variation Row" text="Are you sure you want to delete this variation detail row?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    let lastRow = props.canEdit ? variationDetailsSplit.length - 1 : variationDetailsSplit.length;

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {confirmBack}
            </div>
            <div className="estimate__backButton">
                <h3>{props.detailName}</h3>
                <button type="button" className="defaultbutton" onClick={back}>Back</button>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__extralarge tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th className="table--smallHeaderSize"></th>
                            <th>Scope</th>
                            <th className="table--smallHeaderSize">Cost Code</th>
                            <th className="table--smallHeaderSize">Lab/Mat</th>
                            <th className="table--smallHeaderSize">Qty</th>
                            <th className="table--smallHeaderSize">UOM</th>
                            <th className="textalignright table--smallHeaderSize">Unit Cost</th>
                            <th className="textalignright table--smallHeaderSize">Total</th>
                            <th className={props.approved || !props.canEdit ? "hidden" : "table--smallHeaderSize"}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {variationDetailsSplit.map((split, itemIndex) =>
                            <tr key={itemIndex} draggable="true" onDragStart={(e) => handleDragStart(e, itemIndex)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, itemIndex)}>
                                <td className="hidden">{split.id}</td>
                                <td className="table__text--align">{split.scopeOrder == 0 ? itemIndex + 1 : split.scopeOrder}</td>
                                <td className="table__text--align">
                                    <input type="text" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin-xlg"} name="scope" value={split.scope} onChange={(e) => handleCellChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? split.scope : ""}
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="costCodeId" value={split.costCodeId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={props.approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.costCodeList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="labMatId" value={split.labMatId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={props.approved || !props.canEdit ? true : false}>
                                        <option defaultValue="-1"></option>
                                        {props.labMatList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align estimate__tdWidth">
                                    <input type='number' min="0" step="any" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin--sm textalignright"} name="quantity" value={split.quantity} onChange={(e) => handleCellAmountChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? split.quantity : ""}
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="unitOfMeasureId" value={split.unitOfMeasureId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={props.approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.uomList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <input type='number' min="0" step="any" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin--md textalignright"} name="unitCost" value={split.unitCost} onChange={(e) => handleCellAmountChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? split.unitCostFormatted : ""}
                                </td>
                                <td className="table__text--align">
                                    <input type='number' min="0" step="any" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin--md textalignright"} name="total" value={split.total} onChange={(e) => handleCellAmountChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? split.totalFormatted : ""}
                                </td>
                                <td className={props.approved || !props.canEdit ? "hidden" : "table__text--align"}>
                                    <div className={itemIndex === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(itemIndex, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["split"] ?
                    (errors["split"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label textalignright">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={totalFormatted} disabled></input>
                </label>
            </div>

            <button className="defaultbutton hideMobile" type="submit" disabled={props.approved || !props.canEdit || saveDisabled}>Save</button>
        </form>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default VariationDetailsSplitData;
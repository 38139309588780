import * as React from 'react';
import { useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clientReducer from './ClientReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
import ClientDetail from './ClientDetails';
import ClientContact from './ClientContacts';
import authService from './api-authorization/AuthorizeService';

interface ClientDetails {
    id: number;
    xeroContactId: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    typeId: number;
    phone: string;
    termId: number;
    useOpus: boolean;
    maxMarginPercent: number;
    status: number;
    lockAccount: boolean;
}

interface ClientContacts {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

const ClientData = () => {
    const { id } = useParams();
    let navigate = useNavigate();
    const [types, setTypes] = React.useState<GenericListItem[]>([]);
    const [terms, setTerms] = React.useState<GenericListItem[]>([]);
    const [statusOptions, setStatusOptions] = React.useState<GenericListItem[]>([]);
    const [tab, setTab] = React.useState("Details");

    const initialClientDetails: ClientDetails = {
        id: 0,
        xeroContactId: "",
        name: "",
        addressManual: false,
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        registeredForGst: false,
        abn: "",
        typeId: -1,
        phone: "",
        termId: -1,
        useOpus: false,
        maxMarginPercent: 0,
        status: 1,
        lockAccount: false
    };

    const initialClientContacts: ClientContacts[] = [{
        id: 0,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    }];

    const [{
        clientDetails,
        clientContacts,
        isLoading,
    }, dispatch] = useReducer(clientReducer, { clientDetails: initialClientDetails, clientContacts: initialClientContacts, isLoading: false });

    React.useEffect(() => {
        let Id = Number(id);

        if (Id > 0) {
            getData(Id);
        }
    }, []);


    const getData = async (Id: number) => {
        dispatch({ type: "getDetails" });   //set loading to true

        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateEditClients', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get user roles and status
                axios.get('Clients/GetDropdowns', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setTypes(res.data.types);
                    setTerms(res.data.terms);
                    setStatusOptions(res.data.statusOptions);

                    axios.get('Clients/GetClientDetails?clientId=' + Id, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                    .then(res => {
                        if (res.data.isError) {
                            var errors = res.data.messages as any[];
                            for (var i = 0; i < errors.length; i++) {
                                toast.error(errors[i].content);
                            }
                        } else {
                            var client = res.data;
                            var contacts = client.contacts;
                            contacts.push(getBlankContact());

                            dispatch({ type: "updateDetails", clientDetails: client });
                            dispatch({ type: "updateContacts", clientContacts: contacts });
                        }
                    })
                    .catch(error => {
                        toast.error(error.message);
                    });
                    
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getBlankContact = () => {
        var contact: ClientContacts = {
            id: 0,
            firstName: "",
            lastName: "",
            phone: "",
            mobile: "",
            email: ""
        };
        return contact;
    }

    const updateDetails = (client: ClientDetails) => {
        dispatch({ type: "updateDetails", clientDetails: client });
    }

    const updateContacts = (contacts: ClientContacts[]) => {
        dispatch({ type: "updateContacts", clientContacts: contacts });
    }

    const validateAll = () => {
        let formIsValid = true;
        var details = clientDetails;
        if (!details.name || (details.registeredForGst === true && !details.abn) || details.typeId < 0 || details.termId < 0 || details.status < 0) {
            toast.error("Client Details must be completed!");
            formIsValid = false;
        }

        var contacts = clientContacts;
        if (contacts.length < 2) {
            toast.error("Contacts - At least one contact is required");
            formIsValid = false;
        } else {
            for (var i = 0; i < (contacts.length - 1); i++) {
                let contactIsValid = true;
                if (!contacts[i].firstName && (!contacts[i].phone || !contacts[i].mobile) && !contacts[i].email) {
                    contactIsValid = false;
                }
                if (contactIsValid === false) {
                    formIsValid = false;
                    toast.error("Contacts - First Name, Mobile and Email are required for every contact");
                }
            }
        }

        return formIsValid;
    }

    const save = (sendToXero: boolean) => {
        //validate all data before saving - ADD screen has its own validation so skip and just save
        if (validateAll()) {
            saveClient(sendToXero);
        } else {
            toast.error("Please fix the validation issues before saving");
        }

    }

    const saveClient = async (sendToXero: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var client = clientDetails;
        var contacts = clientContacts;
        contacts.pop();

        var saveClient = {
            client: client,
            contacts: contacts,
            subId: user.sub
        };

        axios.post('Clients/Save', saveClient, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                if (sendToXero) {
                    sendClientToXero();
                } else {
                    toast.success("Client Saved");
                    navigate("/clientsearch");
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const sendToXero = () => {
        //save first
        save(true);  
    }

    const sendClientToXero = async () => {
        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "client",
            id: clientDetails.id
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //if authorised

                var saveClient = {
                    client: clientDetails,
                    contacts: clientContacts,
                    subId: user.sub
                };

                axios.post('Clients/SendToXero', saveClient, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        toast.success("Client Saved and Sent To Xero");
                        navigate("/clientsearch");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        }); 
    }

    var tabContents;
    if (tab === "Details") {
        tabContents = <ClientDetail clientDetails={clientDetails} statusOptions={statusOptions} types={types} terms={terms} update={updateDetails} save={save} sendToXero={sendToXero} />
    }
    else if (tab === "Contacts") {
        tabContents = <ClientContact clientContacts={clientContacts} update={updateContacts} save={save} />
    }

    const renderDetails = (
        <div>
            <div className="tabsComponent" id="tab-container">
                <div className="tabsComponent__showDropdown">
                    <label className="input-group" htmlFor="userTab">
                        <span className="label">Section</span>
                        <select className="select" id="userTab" value={tab} onChange={(e) => setTab(e.target.value)} >
                            <option value="Details">Details</option>
                            <option value="Contacts">Contacts</option>
                        </select>
                    </label>
                </div>

                <div className="tabsComponent__tabs tabsComponent__hideTabs">
                    <input type="radio" name="tabs" id="tab1" className="tabsComponent__control" checked={tab === 'Details'}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => setTab('Details')}>Details</label>
                    <input type="radio" name="tabs" id="tab2" className="tabsComponent__control" checked={tab === 'Contacts'}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => setTab('Contacts')}>Contacts</label>
                </div>

                <div className="tabsComponent__content tabsComponent__content--selected">
                    <div>
                        {tabContents}
                    </div>
                </div>
            </div>
        </div>
    );
    
    let contents = isLoading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            <h1>Client Details</h1>
            {contents}
        </div>
    )

}

export default ClientData;
import { Modal } from 'react-bootstrap';
import PhotoImportDetailPopup from './PhotoImportDetail';

interface PhotoImportProps {
    title: string;
    saveDisabled: boolean;
    uploadedFiles: UploadedFile[];
    showModal: boolean;
    hideModal(): void;
    update(selectedFiles: UploadedFile[]): void;
    importPhotos(files: File[]): void;
    loadingPhotos: boolean;
    delete(deleteIndex: number): void;
    save(): void;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const PhotoImportPopup = (props: PhotoImportProps) => {
    const hideModal = () => {
        props.hideModal();
    }

    const save = (e: any) => {
        e.preventDefault();

        props.save();
    }

    const photoImportDetail = <PhotoImportDetailPopup title={props.title} showLoading={props.loadingPhotos} uploadedFiles={props.uploadedFiles} importPhotos={props.importPhotos} update={props.update} delete={props.delete} />
    var contents = props.loadingPhotos ? "Loading Photos..." : photoImportDetail;

    return (
        <div>
            <Modal size="lg" show={props.showModal} onHide={hideModal}>
                <Modal.Header className="modal__header--colour">
                    <Modal.Title>Upload Photo</Modal.Title>
                    <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                    <button className="popup-button--yes" onClick={save} disabled={props.saveDisabled || props.loadingPhotos}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PhotoImportPopup;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import PhotoImportSinglePopup from './PhotoImportSingle';
import authService from './api-authorization/AuthorizeService';

interface EstimateDetailsProps {
    canEdit: boolean;
    jobId: number;
    pageData: PageChangeData;
    costCodeList: GenericListItem[];
    uomList: GenericListItem[];
    pcpsList: GenericListItem[];
    suppliersList: GenericListItem[];
    changePage(pageData: PageChangeData): void;
}

interface EstimateDetails {
    itemIndex: number;
    id: number;
    headerId: number;
    scope: string;
    scopeOrder: number;
    costCodeId: number;
    costCode: string;
    supplierId: number;
    quantity: number;
    unitOfMeasureId: number;
    unitOfMeasure: string;
    pcPsId: number;
    pcPs: string;
    unitCost: number;
    unitCostFormatted: string;
    subTotal: number;
    subTotalFormatted: string;
    markUpPercentage: number;
    markUpPercentageFormatted: string;
    total: number;
    totalFormatted: string;
    hasPhoto: boolean;
    isDirty: boolean;
    isSplitData: boolean; //true if there is a lab/mat split on 3rd level
}

interface GenericListItem {
    id: number;
    name: string;
}

interface PageChangeData {
    estimateId: number;
    page: string;
    id: number;
    headerText: string;
    costCodeId: number;
    prevId: number; //for going back from split to details
    prevHeaderText: string;
}

interface AppFile {
    id: number;
    fileType: string;
    content: any;
}

const EstimateDetailsData = (props: EstimateDetailsProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [estimateDetails, setEstimateDetails] = React.useState<EstimateDetails[]>([]);
    const [estimateDetailTotal, setEstimateDetailTotal] = React.useState({ estimateDetailSubTotal: "$0.00", estimateDetailTotal: "$0.00" });
    const [estimateSubTotalExGstOrig, setEstimateSubTotalExGstOrig] = React.useState(0);
    const [estimateTotals, setEstimateTotals] = React.useState({ estimateSubTotalExGst: "$0.00", estimateGst: "$0.00", estimateTotal: "$0.00" });     //estimateSubTotalExGstOrig - orig totals exclude current detail totals so I can modify the total when the details change
    const [percentages, setPercentages] = React.useState({ gstPercentage: 0.1, globalMarkupPercentage: 0 });
    const [approved, setApproved] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [estimateIndex, setEstimateIndex] = React.useState(-1);
    const [showUploadPhotoModal, setShowUploadPhotoModal] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(new File([""], "filename"));
    const [uploadedPhoto, setUploadedPhoto] = React.useState<AppFile>({ id: 0, fileType: "", content: [] });
    const [openSaveDisabled, setOpenSaveDisabled] = React.useState(false);
    const [estimateDetailId, setEstimateDetailId] = React.useState(0);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        var headerId = props.pageData.id;

        axios.get('Estimate/GetEstimateDetails?JobId=' + props.jobId + '&HeaderId=' + headerId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //add blank header row
            var estDetails = res.data.estimateDetails;
            //update item index
            for (var i = 0; i < estDetails.length; i++) {
                estDetails[i].itemIndex = i;
                estDetails[i].unitCost = (estDetails[i].unitCost).toFixed(2);
                estDetails[i].subTotal = (estDetails[i].subTotal).toFixed(2);
                estDetails[i].markUpPercentage = (estDetails[i].markUpPercentage); //.toFixed(2);
                estDetails[i].total = (estDetails[i].total).toFixed(2);
            }
            if (!res.data.approved && props.canEdit) {
                var blankRow = addBlankRow(estDetails.length);
                estDetails.push(blankRow);
            }

            setEstimateDetails(estDetails);
            setEstimateDetailTotal({ estimateDetailSubTotal: res.data.estimateDetailSubTotal, estimateDetailTotal: res.data.estimateDetailTotal });
            setEstimateTotals({ estimateSubTotalExGst: res.data.estimateSubTotalExGst, estimateGst: res.data.estimateGst, estimateTotal: res.data.estimateTotal });
            setEstimateSubTotalExGstOrig(res.data.estimateSubTotalExGstOrig);
            setPercentages({ gstPercentage: res.data.gstPercentage, globalMarkupPercentage: res.data.globalMarkupPercentage });
            setApproved(res.data.approved);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var blankRow: EstimateDetails = {
            itemIndex: nextItemIndex,
            id: 0,
            headerId: props.pageData.id,
            scope: "", 
            scopeOrder: 0,
            costCodeId: -1,
            costCode: "",
            supplierId: -1,
            quantity: 0,
            unitOfMeasureId: -1,
            unitOfMeasure: "",
            pcPsId: -1,
            pcPs: "",
            unitCost: 0,
            unitCostFormatted: "",
            subTotal: 0,
            subTotalFormatted: "",
            markUpPercentage: 0,
            markUpPercentageFormatted: "",
            total: 0,
            totalFormatted: "",
            hasPhoto: false,
            isDirty: false,
            isSplitData: false
        }
        return blankRow;
    }

    //BACK Button
    const back = () => {
        //if isDirty - ask if want to save changes
        var changed = estimateDetails.filter(data => data.isDirty);
        if (changed.length > 0) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);
            saveEstimate(-1, true);
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            estimateId: props.pageData.estimateId,
            id: 0,
            page: "header",
            headerText: "",
            costCodeId: -1,
            prevId: 0,
            prevHeaderText: ""
        };

        //validate and save before opening
        props.changePage(pageChange);
    }
    //End BACK Button

    //change events
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var estDetails = [...estimateDetails];
        var detail = estDetails[index];
        let exist: any = {};
        exist = detail;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (e.target.name === "pcPsId" || e.target.name === "costCodeId" || e.target.name === "unitOfMeasureId") {
            var itemName = e.target.name.replace("Id", "");
            exist[itemName] = e.target.options[e.target.selectedIndex].text;
        }

        if (index === (estDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(estDetails.length);
            estDetails.push(newRow);
        }

        setEstimateDetails(estDetails);
        setSaveDisabled(false);
        setModalSaveDisabled(false);
        setOpenSaveDisabled(false);
        setErrors({});
    }

    const handleScopeKeyUp = (index: number, e: any) => {
        getShortcut(index, e);
    }
         
    const getShortcut = async (index: number, e: any) => {
        const token = await authService.getAccessToken();

        //check if chars < 15, if so check if a shortcut key has been entered and update scope
        var estDetails = [...estimateDetails];
        var detail = estDetails[index];

        if (detail.scope && detail.scope.length < 15) {
            //check if shortcut
            axios.get('Estimate/GetShortcutScope?Scope=' + detail.scope, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                detail.scope = res.data.scope;   //returns entered scope if not a shortcut
                if (res.data.costCodeId > 0) {
                    //only set the cost code if it brings one back
                    detail.costCodeId = res.data.costCodeId;
                    detail.costCode = res.data.costCode;
                }
                setEstimateDetails(estDetails);
                setSaveDisabled(false);
                setModalSaveDisabled(false);
                setOpenSaveDisabled(false);
            })
            .catch(error => {
                toast.error(error.message);
            });
        }
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var estDetails = [...estimateDetails];
        var detail = estDetails[index];

        var amountChanged = e.target.value === "" ? e.target.value : e.target.value * 1;    //if decimal point entered first * 1 causes it to lose the .

        let exist: any = {};
        exist = detail;
        exist[e.target.name] = amountChanged;
        exist.isDirty = true;

        //calculate unit cost / total
        if (e.target.name === "subTotal" && detail.quantity > 0) {
            let unitCost = amountChanged / detail.quantity;
            exist["unitCost"] = unitCost.toFixed(2);
            exist["unitCostFormatted"] = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            exist["subTotalFormatted"] = amountChanged.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            let markupPercent = detail.markUpPercentage / 100;
            let muPercent = detail.markUpPercentage * 1;
            exist["markUpPercentageFormatted"] = muPercent.toString() + "%";
            let total = ((amountChanged * markupPercent) + amountChanged);
            exist["total"] = total.toFixed(2);
            exist["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        } else if (e.target.name === "unitCost" && detail.quantity > 0) {
            exist["unitCostFormatted"] = amountChanged.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            let subTotal = (amountChanged * detail.quantity);
            exist["subTotal"] = subTotal.toFixed(2);
            exist["subTotalFormatted"] = subTotal.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            let markupPercent = detail.markUpPercentage / 100;
            let muPercent = detail.markUpPercentage * 1;
            exist["markUpPercentageFormatted"] = muPercent.toString() + "%";
            let total = (((amountChanged * detail.quantity) * markupPercent) + (amountChanged * detail.quantity));
            exist["total"] = total.toFixed(2);
            exist["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        } else if (e.target.name === "quantity" && detail.unitCost > 0) {
            //quantity
            let subTotal = (amountChanged * detail.unitCost);
            exist["subTotal"] = subTotal.toFixed(2);
            exist["subTotalFormatted"] = subTotal.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            let markupPercent = detail.markUpPercentage / 100;
            let muPercent = detail.markUpPercentage * 1;
            exist["markUpPercentageFormatted"] = muPercent.toString() + "%";
            let total = (((amountChanged * detail.unitCost) * markupPercent) + (amountChanged * detail.unitCost));
            exist["total"] = total.toFixed(2);
            exist["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        } else if (e.target.name === "markUpPercentage" && detail.unitCost > 0 && detail.quantity > 0) {
            let muPercent = amountChanged * 1;
            exist["markUpPercentageFormatted"] = muPercent.toString() + "%";
            let total = (((detail.quantity * detail.unitCost) * (amountChanged / 100)) + (detail.quantity * detail.unitCost));
            exist["total"] = total.toFixed(2);
            exist["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        }

        if (index === (estDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(estDetails.length);
            estDetails.push(newRow);
        }

        //calculate totals and format
        var estimateTotals = calculateTotal(estDetails);
        var estimateDetailSubTotal = (estimateTotals.subTotal).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateDetailTotal = (estimateTotals.total).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateSubTotalExGst = (estimateTotals.estimateSubTotalExGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateGst = (estimateTotals.estimateGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateTotal = (estimateTotals.estimateTotal).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        setEstimateDetails(estDetails);
        setEstimateDetailTotal({ estimateDetailSubTotal: estimateDetailSubTotal, estimateDetailTotal: estimateDetailTotal });
        setEstimateTotals({ estimateSubTotalExGst: estimateSubTotalExGst, estimateGst: estimateGst, estimateTotal: estimateTotal });
        setSaveDisabled(false);
        setModalSaveDisabled(false);
        setOpenSaveDisabled(false);
        setErrors({});
    }

    //capture shortcut keys
    const handleKeyUp = (itemIndex: number, e: any) => {
        if (e.ctrlKey && e.keyCode === 50) {
            e.preventDefault();
            //go down
            openDetails(itemIndex, e);
        } else if (e.ctrlKey && e.keyCode === 51) {
            e.preventDefault();
            //go up
            back();
        }
    }

    const calculateTotal = (estDetails: EstimateDetails[]) => {
        var subtotal = 0;
        var total = 0;
        for (var i = 0; i < estDetails.length - 1; i++) {
            var amount = parseFloat((estDetails[i].quantity * estDetails[i].unitCost).toFixed(2));
            subtotal += amount;
            total += parseFloat(((amount * (estDetails[i].markUpPercentage / 100)) + amount).toFixed(2));
        }

        var totalIncGlobalMarkup = total + (total * percentages.globalMarkupPercentage);

        var estimateSubTotalExGst = estimateSubTotalExGstOrig + totalIncGlobalMarkup;
        var estimateGst = estimateSubTotalExGst * percentages.gstPercentage;
        var estimateTotal = estimateSubTotalExGst + estimateGst;

        var totals = {
            subTotal: subtotal,
            total: total,
            estimateSubTotalExGst: estimateSubTotalExGst,
            estimateGst: estimateGst,
            estimateTotal: estimateTotal
        };

        return totals;
    }
    //end change events

    //open split page
    const openDetails = (itemIndex: number, e: any) => {
        e.preventDefault();

        if (openSaveDisabled) {
            return;
        }

        //if isDirty - ask if want to save changes
        var changed = estimateDetails.filter(data => data.isDirty);
        if (!openSaveDisabled && changed.length > 0) {
            setOpenSaveDisabled(true);
            saveEstimate(itemIndex, false);
        } else {
            var estDetail = estimateDetails[itemIndex];
            openDetailSplitPage(estDetail.id, estDetail.costCodeId, estDetail.scope);
        }
    }

    const openDetailSplitPage = (detailId: number, costCodeId: number, scope: string) => {
        var pageChange: PageChangeData = {
            estimateId: props.pageData.estimateId,
            id: detailId,
            page: "split",
            headerText: scope,
            costCodeId: costCodeId,
            prevId: props.pageData.id,
            prevHeaderText: props.pageData.headerText
        };

        props.changePage(pageChange);
    }
    //end open split page

    //upload photo 
    const uploadPhoto = (detailId: number, e: any) => {
        uploadPhotoItem(detailId, e);
    }

    const uploadPhotoItem = async (detailId: number, e: any) => {
        e.preventDefault();
        const token = await authService.getAccessToken();

        //check if there is already a photo and show it
        axios.get('Estimate/GetEstimateDetailPhoto?DetailId=' + detailId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setShowUploadPhotoModal(true);
            setModalSaveDisabled(false);
            setEstimateDetailId(detailId);
            setSelectedFile(new File([""], "filename"));
            setUploadedPhoto(res.data);
        })
        .catch(error => {
            toast.error(error.message);
        });
 
    }

    const uploadPhotoHide = () => {
        setShowUploadPhotoModal(false);
        setModalSaveDisabled(false);
        setEstimateDetailId(0);
        setSelectedFile(new File([""], "filename"));
    }

    const uploadPhotoUpdate = (file: File) => {
        setSelectedFile(file);
    }

    const uploadPhotoDelete = () => {
        deleteUploadedPhoto();
    }

    const deleteUploadedPhoto = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var photoDelete = {
            jobId: props.jobId,
            detailId: estimateDetailId,
            subId: user.sub
        };

        axios.post('Estimate/DeleteEstimateDetailPhoto', photoDelete, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var detaillines = [...estimateDetails];
                var detailline = detaillines.filter(x => x.id === estimateDetailId);
                if (detailline.length > 0) {
                    detailline[0].hasPhoto = false;
                }
                setEstimateDetails(detaillines);
                setShowUploadPhotoModal(false);
                setModalSaveDisabled(false);
                setEstimateDetailId(0)
                setSelectedFile(new File([""], "filename"));
                
                toast.success("Photo has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const uploadPhotoSave = () => {
        setModalSaveDisabled(false);
        saveUploadedPhoto();
    }

    const saveUploadedPhoto = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        const data = new FormData();
        data.append('jobId', props.jobId.toString());
        data.append('detailId', estimateDetailId.toString());
        data.append('photo', selectedFile);
        data.append('subId', user.sub);

        axios.post('Estimate/SaveEstimateDetailPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var detaillines = [...estimateDetails];
                var detailline = detaillines.filter(x => x.id === estimateDetailId);
                if (detailline.length > 0) {
                    detailline[0].hasPhoto = true;
                }
                setEstimateDetails(detaillines);
                setShowUploadPhotoModal(false);
                setModalSaveDisabled(false);
                setEstimateDetailId(0)
                setSelectedFile(new File([""], "filename"));
                
                toast.success("Photo has been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end upload photo

    //delete row
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();

        setEstimateIndex(index);

        //save the rest of the estimate
        var changed = estimateDetails.filter(data => data.isDirty);
        if (changed.length > 0) {
            //remove the row for deletion and then validate the rest
            //as shouldn't have to fix a row that you want to delete
            var estDetails = [...estimateDetails];

            //remove from list
            estDetails.splice(index, 1);

            //just validate changed items for delete
            var changedDetails = estDetails.filter(data => data.isDirty);

            //need to save estimate details before delete
            if (validate(changedDetails, false)) {
                saveEstimateDetails(-1, false, true, changedDetails);
            } else {
                toast.error("You need to fix errors on screen to save latest changes before deleting");
            }
        } else {
            //no unsaved items so can just delete
            readyToDelete();
        }
    }

    const readyToDelete = () => {
        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setSaveDisabled(false);
        setEstimateIndex(0);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        var estDetails = [...estimateDetails];
        var estimateDetail = estDetails[estimateIndex];

        //remove from list
        var removedItem = estDetails.splice(estimateIndex, 1);

        //loop through the headers and update the order
        var hasBlankRow = !approved && props.canEdit;
        var length = hasBlankRow ? estDetails.length - 1 : estDetails.length;
        for (var i = 0; i < estDetails.length; i++) {
            estDetails[i].itemIndex = (i);
            if (i < length) {
                //don't want to update the scope order for the blank row
                estDetails[i].scopeOrder = (i + 1);
            }
        }

        if (estimateDetail.id == 0) {
            deleteItemUpdateOrder(estDetails, removedItem);
        } else {
            deleteEstimateItem(estDetails, removedItem);
        }
    }
    //end delete row

    const deleteItemUpdateOrder = async (estDetails: EstimateDetails[], removedItem: EstimateDetails[]) => {
        const token = await authService.getAccessToken();

        //recalculate totals
        var estimateTotals = calculateTotal(estDetails);
        var estimateDetailSubTotal = (estimateTotals.subTotal).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateDetailTotal = (estimateTotals.total).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateSubTotalExGst = (estimateTotals.estimateSubTotalExGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateGst = (estimateTotals.estimateGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateTotal = (estimateTotals.estimateTotal).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        //save new details order
        axios.post('Estimate/DeleteEstimateDetailUpdateOrder', estDetails, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                estDetails.splice(estimateIndex, 0, removedItem[0]);

                setEstimateDetails(estDetails);
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(0);
            } else {
                setEstimateDetails(estDetails);
                setEstimateDetailTotal({ estimateDetailSubTotal: estimateDetailSubTotal, estimateDetailTotal: estimateDetailTotal });
                setEstimateTotals({ estimateSubTotalExGst: estimateSubTotalExGst, estimateGst: estimateGst, estimateTotal: estimateTotal });
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(0);

                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteEstimateItem = async (estDetails: EstimateDetails[], removedItem: EstimateDetails[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var deleteDetail = {
            estimateId: props.pageData.estimateId,
            id: removedItem[0].id,
            jobId: props.jobId,
            estimateDetailsOrder: estDetails,
            subId: user.sub
        };

        axios.post('Estimate/DeleteEstimateDetail', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                estDetails.splice(estimateIndex, 0, removedItem[0]);
                setEstimateDetails(estDetails);
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(0);
            } else {
                var estimateTotals = calculateTotal(estDetails);
                var estimateDetailSubTotal = (estimateTotals.subTotal).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateDetailTotal = (estimateTotals.total).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateSubTotalExGst = (estimateTotals.estimateSubTotalExGst).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateGst = (estimateTotals.estimateGst).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateTotal = (estimateTotals.estimateTotal).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });

                setEstimateDetails(estDetails);
                setEstimateDetailTotal({ estimateDetailSubTotal: estimateDetailSubTotal, estimateDetailTotal: estimateDetailTotal });
                setEstimateTotals({ estimateSubTotalExGst: estimateSubTotalExGst, estimateGst: estimateGst, estimateTotal: estimateTotal });
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(0);

                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const validate = (items: EstimateDetails[], hasBlankRow: boolean) => {
        let errorList: any = {};
        let formIsValid = true;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (estimateDetails.length < 2) {
            errorString = "Please enter at least one Item!";
            error = true;
        } else {
            var itemsLength = hasBlankRow ? items.length - 1 : items.length;
            for (var i = 0; i < itemsLength; i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].scope) {
                    newErrorString += " Scope, ";
                    error = true;
                }

                if (items[i].costCodeId < 0) {
                    newErrorString += " Cost Code, ";
                    error = true;
                }

                if (newErrorString != "") {
                    errorString += "\n"
                    errorString += "Row " + (items[i].itemIndex + 1) + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["details"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        setModalSaveDisabled(false);
        setOpenSaveDisabled(false);
        
        saveEstimate(-1, false);
    }

    const saveEstimate = (openIndex: number, goBack: boolean) => {
        if (validate(estimateDetails, true)) {
            //Only save items that have changed
            var changed = estimateDetails.filter(data => data.isDirty);
            if (changed.length === 0) {
                toast.info("No items have been changed!");
            } else {
                saveEstimateDetails(openIndex, goBack, false, changed);
            }
        } else {
            toast.error("Please check and resolve the errors at the bottom of the estimate table");
        }
    }

    const saveEstimateDetails = async (openIndex: number, goBackPage: boolean, goDelete: boolean, changedDetails: EstimateDetails[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var details = [...estimateDetails];

        var estDetails = {
            estimateId: props.pageData.estimateId,
            jobId: props.jobId,
            headerId: props.pageData.id,
            estimateDetails: changedDetails,
            subId: user.sub
        };

        axios.post('Estimate/SaveEstimateDetails', estDetails, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Estimate Saved");
                if (openIndex >= 0) {
                    //open the split page
                    changedDetails = res.data.estimateDetails;
                    var findRow = changedDetails.filter(data => data.itemIndex === openIndex);
                    var id = details[openIndex].id;
                    var costCodeId = details[openIndex].costCodeId;
                    var scope = details[openIndex].scope;
                    if (findRow.length > 0) {
                        id = findRow[0].id;
                        scope = findRow[0].scope;
                    }
                    openDetailSplitPage(id, costCodeId, scope);
                } else if (goBackPage) {
                    goBack();
                } else {
                    changedDetails = res.data.estimateDetails;
                    //loop through the changed items and update the main list
                    for (var i = 0; i < changedDetails.length; i++) {
                        details[changedDetails[i].itemIndex] = changedDetails[i];
                    }

                    setEstimateDetails(details);
                    setErrors({});

                    if (goDelete) {
                        readyToDelete();
                    }
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //MOVE ROW
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var estDetails = [...estimateDetails];
        var hasBlankRow = !approved && props.canEdit;
        var estDetailsLength = estDetails.length - 1;

        if ((hasBlankRow && oldIndex != estDetailsLength && newIndex != estDetailsLength) || !hasBlankRow) {
            //only move if not blank row
            estDetails.splice(newIndex, 0, estDetails.splice(oldIndex, 1)[0]);

            //loop through the items and update the order
            var length = hasBlankRow ? estDetails.length - 1 : estDetails.length;
            for (var i = 0; i < length; i++) {
                estDetails[i].itemIndex = (i);
                estDetails[i].scopeOrder = (i + 1);
                estDetails[i].isDirty = true;
            }

            setEstimateDetails(estDetails);
            saveEstimate(-1, false);
        }
    };
    //END MOVE ROW

    let confirmPopup = <ConfirmModal heading="Delete Estimate Row" text="Are you sure you want to delete this estimate detail row?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    var phototext = "Search and select a photo to upload.";
    let photoUpload = <PhotoImportSinglePopup heading="Upload Photo" text={phototext} fileType="Photo" selectedFile={selectedFile} uploadedPhoto={uploadedPhoto} showModal={showUploadPhotoModal} hideModal={uploadPhotoHide} update={uploadPhotoUpdate} delete={uploadPhotoDelete} save={uploadPhotoSave} saveDisabled={modalSaveDisabled} />
    let lastRow = (approved || !props.canEdit) ? estimateDetails.length : estimateDetails.length - 1;

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {confirmBack}
                {photoUpload}
            </div>
            <div className="estimate__backButton">
                <h3>{props.pageData.headerText}</h3>
                <button type="button" className="defaultbutton" onClick={back}>Back</button>
            </div>

            <div className='overflowAuto'>
                <table className="table--main table__extralarge tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th className="table--smallHeaderSize"></th>
                            <th>Scope</th>
                            <th className="table--smallHeaderSize"></th>
                            <th className="table--smallHeaderSize">Cost Code</th>
                            <th className="table--smallHeaderSize">Supplier</th>
                            <th className="table--smallHeaderSize">PC/PS</th>
                            <th className="table--smallHeaderSize">Qty</th>
                            <th className="table--smallHeaderSize">UOM</th>
                            <th className="table--smallHeaderSize textalignright">Unit Cost</th>
                            <th className="table--smallHeaderSize textalignright">Sub Total</th>
                            <th className="table--smallHeaderSize textalignright">Mark Up %</th>
                            <th className="table--smallHeaderSize textalignright">Total (ex gst)</th>
                            <th className="table--smallHeaderSize">Photo</th>
                            <th className={approved || !props.canEdit ? "hidden" : "table--smallHeaderSize"}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {estimateDetails.map((detail, itemIndex) =>
                            <tr key={itemIndex} draggable="true" onDragStart={(e) => handleDragStart(e, itemIndex)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, itemIndex)}>
                                <td className="hidden">{detail.id}</td>
                                <td className="table__text--align">{detail.scopeOrder == 0 ? itemIndex + 1 : detail.scopeOrder}</td>
                                <td className="table__text--align">
                                    <textarea rows={2} className={approved || !props.canEdit ? "hidden" : "form-control estimate__scopeWidth"} name="scope" value={detail.scope} onChange={(e) => handleCellChange(itemIndex, e)} onBlur={(e) => handleScopeKeyUp(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} />
                                    {approved || !props.canEdit ? detail.scope : ""}
                                </td>
                                <td className="table__text--align">
                                    <a className={itemIndex === lastRow ? "hidden" : ""} href="#" onClick={(e) => openDetails(detail.itemIndex, e)}>
                                        Open
                                    </a>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size estimate__maxSupplierWidth" name="costCodeId" value={detail.costCodeId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={approved || !props.canEdit ? true : false} onKeyUp={(e) => handleKeyUp(itemIndex, e)}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.costCodeList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size estimate__maxSupplierWidth" name="supplierId" value={detail.supplierId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={approved || !props.canEdit ? true : false} onKeyUp={(e) => handleKeyUp(itemIndex, e)}>
                                        <option defaultValue="-1"></option>
                                        {props.suppliersList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <select className="select estimate__maxPcPsWidth" name="pcPsId" value={detail.pcPsId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={approved || !props.canEdit ? true : false} onKeyUp={(e) => handleKeyUp(itemIndex, e)}>
                                        <option defaultValue="-1" value="-1"></option>
                                        {props.pcpsList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                        </select>
                                </td>
                                <td className="table__text--align">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="quantity" value={detail.quantity} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={detail.isSplitData} />
                                    {approved || !props.canEdit ? detail.quantity : ""}
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="unitOfMeasureId" value={detail.unitOfMeasureId} onChange={(e) => handleCellChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.uomList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align textalignright">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__tdWidth textalignright"} name="unitCost" value={detail.unitCost} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={detail.isSplitData} />
                                    {approved || !props.canEdit ? detail.unitCostFormatted : ""}
                                </td>
                                <td className="table__text--align textalignright">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__tdWidth textalignright"} name="subTotal" value={detail.subTotal} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={detail.isSplitData} />
                                    {approved || !props.canEdit ? detail.subTotalFormatted : ""}
                                </td>
                                <td className="table__text--align textalignright">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="markUpPercentage" value={detail.markUpPercentage} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={percentages.globalMarkupPercentage > 0} />
                                    {approved || !props.canEdit ? detail.markUpPercentageFormatted : ""}
                                </td>
                                <td className="table__text--align textalignright">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__tdWidth textalignright"} name="total" value={detail.total} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled />
                                    {approved || !props.canEdit ? detail.totalFormatted : ""}
                                </td>
                                <td className="table__text--align">
                                    <div className={itemIndex === lastRow || detail.id === 0 || approved || !props.canEdit? "hidden" : "delete--tablecell"}>
                                        <a className={detail.hasPhoto ? "file__photoColour" : ""} href="#" onClick={(e) => uploadPhoto(detail.id, e)}>
                                            <span className="fas fa-file-image edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                                <td className={approved || !props.canEdit ? "hidden" : "table__text--align"}>
                                    <div className={itemIndex === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(itemIndex, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                                
                        )}
                        </tbody>
                </table>
                {errors["details"] ?
                    (errors["details"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div>
                <div className="input-group-parent">
                    <label className="input-group estimate__width" htmlFor="subTotalExGstFormatted">
                        <span className="label">Estimate Sub Total ex GST</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalExGstFormatted" name="subTotalExGstFormatted" value={estimateTotals.estimateSubTotalExGst} disabled></input>
                    </label>
                    <label className="input-group" htmlFor="gstFormatted">
                        <span className="label">Estimate GST</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={estimateTotals.estimateGst} disabled></input>
                    </label>
                    <label className="input-group" htmlFor="totalFormatted">
                        <span className="label">Estimate Total</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={estimateTotals.estimateTotal} disabled></input>
                    </label>
                </div>
                <div className="input-group-parent">
                    <label className="input-group estimate__alignTotals" htmlFor="estimateSubTotal">
                        <span className="label textalignright">Sub Total</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="estimateSubTotal" name="estimateSubTotal" value={estimateDetailTotal.estimateDetailSubTotal} disabled></input>
                    </label>
                    <label className="input-group estimate__alignTotals" htmlFor="estimateTotal">
                        <span className="label textalignright">Sub Total Ex Gst</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="estimateTotal" name="estimateTotal" value={estimateDetailTotal.estimateDetailTotal} disabled></input>
                    </label>
                </div>
            </div>

            <button className="defaultbutton hideMobile" type="submit" disabled={approved || !props.canEdit || saveDisabled}>Save</button>
        </form>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default EstimateDetailsData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import AddXeroContactPopup from './JobInvoiceAddXeroCustomerPopup';
import * as DateHelpers from './DateHelpers';
import authService from './api-authorization/AuthorizeService';

interface JobInvoiceDetailProps {
    jobId: number;
    id: number;
    showAddress: boolean;
    changePage(pageData: PageChangeData): void;
}

interface PageChangeData {
    id: number;
    page: string;
}

interface Invoice {
    id: number;
    jobId: number;
    xeroInvoiceId: string;
    xeroInvoiceNumber: string;
    clientTermsDays: number;
    xeroCustomerId: string;
    xeroBrandingThemeId: string;
    customerName: string;
    typeId: number;
    progressClaimNumber: number;
    sentDate: string;
    paidDate: string;
    dueDate: string;
    dueDateActual: string;
    statusId: number;
    status: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    total: number;
    totalFormatted: string;
    invoiceDetails: InvoiceDetails[];
    isDirty: boolean;
}

interface InvoiceDetails {
    id: number;
    linkId: number;
    linkTypeId: number;         //Estimate = 1, Variation = 2, ExcessDeduction = 3, RoofReport = 4, LeakDetection = 5, Assessment = 6
    description: string;
    subTotal: number;
    gst: number;
    total: number;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface XeroListItem {
    id: string;
    name: string;
    totalFormatted: string;
}

interface XeroThemeItem {
    id: string;
    name: string;
}

interface VariationListItem {
    id: number;
    variationNumber: number;
    name: string;
    displayName: string;
    subTotal: number;
    gst: number;
    total: number;
    totalFormatted: string;
    totalUsed: number;
    totalRemaining: string;
    selected: boolean;
}

interface ProgressClaimItem {
    id: number;
    subTotal: number;
    gst: number;
    total: number;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    selected: boolean;
}

const JobInvoiceDetailData = (props: JobInvoiceDetailProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [pagePermissions, setPagePermission] = React.useState({ canEdit: false, canSend: false });
    const [invoice, setInvoice] = React.useState<Invoice>({
        id: 0,
        jobId: props.id,
        clientTermsDays: 14,
        xeroInvoiceId: "",
        xeroInvoiceNumber: "",
        xeroCustomerId: "",
        customerName: "",
        xeroBrandingThemeId: "",
        typeId: -1,
        progressClaimNumber: 0,
        sentDate: "",
        paidDate: "",
        dueDate: "",
        dueDateActual: "",
        statusId: 1,
        status: "Created",
        subTotal: 0,
        subTotalFormatted: "$0.00",
        gst: 0,
        gstFormatted: "$0.00",
        total: 0,
        totalFormatted: "$0.00",
        invoiceDetails: [],
        isDirty: false
    });
    const [xeroNameSearch, setXeroNameSearch] = React.useState("");
    const [xeroCustomers, setXeroCustomers] = React.useState<XeroListItem[]>([]);
    const [xeroInvoiceBrandingThemes, setXeroInvoiceBrandingThemes] = React.useState<XeroThemeItem[]>([]);
    const [invoiceTypes, setInvoiceTypes] = React.useState<GenericListItem[]>([]);
    const [approvedVariations, setApprovedVariations] = React.useState<VariationListItem[]>([]);
    const [unapprovedVariations, setUnapprovedVariations] = React.useState<VariationListItem[]>([]);
    const [progressClaimsXero, setProgressClaimsXero] = React.useState<ProgressClaimItem[]>([]);
    const [progressClaimsUnsent, setProgressClaimsUnsent] = React.useState<ProgressClaimItem[]>([]);
    const [nextProgressClaimNumber, setNextProgressClaimNumber] = React.useState(1);
    const [gstData, setGstData] = React.useState({ gstPercent: 0.1, gstCalculation: 11 });
    const [jobData, setJobData] = React.useState({ siteAddress: "", jobTypeId: 1 });
    const [estimateData, setEstimateData] = React.useState({ estimateId: 0, estimateSubTotal: 0, estimateGst: 0, estimateTotal: 0, estimateTotalFormatted: "$0.00", estimateTotalUsed: 0, estimateTotalRemaining: "$0.00" });
    const [estimateSelected, setEstimateSelected] = React.useState(false);
    const [customerRegisteredForGst, setCustomerRegisteredForGst] = React.useState(false);
    const [excessData, setExcessData] = React.useState({ excess: 0, excessFormatted: "$0.00", excessTermsDays: 7 });
    const [excessSelected, setExcessSelected] = React.useState(false);
    const [showAddXeroContactModal, setShowAddXeroContactModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [sendDisabled, setSendDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [reportSelected, setReportSelected] = React.useState({ roofReportSelected: false, leakDetectionSelected: false });
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = React.useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
    const [deleteIndex, setDeleteIndex] = React.useState(-1);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var Id = props.id;
        var JobId = props.jobId;

        axios.get('Jobs/GetInvoiceDetails?JobId=' + JobId + '&Id=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var invoiceData: Invoice = res.data.invoice;
            //add a blank row if can edit
            if (res.data.canEdit && invoiceData.statusId === 1) {
                var invDetails = invoiceData.invoiceDetails;
                var blankItem = getBlankDetailRow();
                invDetails.push(blankItem);
            }

            setPagePermission({ canEdit: res.data.canEdit, canSend: res.data.canSend });
            setInvoiceTypes(res.data.invoiceTypes);
            setXeroCustomers(res.data.xeroCustomers);
            setXeroInvoiceBrandingThemes(res.data.xeroInvoiceBrandingThemes);
            setApprovedVariations(res.data.approvedVariations);
            setUnapprovedVariations(res.data.unapprovedVariations);
            setProgressClaimsXero(res.data.progressClaimsXero);
            setProgressClaimsUnsent(res.data.progressClaimsUnsent);
            setNextProgressClaimNumber(res.data.nextProgressClaimNumber);
            setEstimateData({ estimateId: res.data.estimateId, estimateSubTotal: res.data.estimateSubTotal, estimateGst: res.data.estimateGst, estimateTotal: res.data.estimateTotal, estimateTotalFormatted: res.data.estimateTotalFormatted, estimateTotalUsed: res.data.estimateTotalUsed, estimateTotalRemaining: res.data.estimateTotalRemaining });
            setEstimateSelected(res.data.estimateSelected);
            setInvoice(invoiceData);
            setGstData({ gstPercent: res.data.gstPercent, gstCalculation: res.data.gstCalculation });
            setJobData({ siteAddress: res.data.siteAddress, jobTypeId: res.data.jobTypeId });
            setCustomerRegisteredForGst(res.data.customerRegisteredForGst);
            setExcessData({ excess: res.data.excess, excessFormatted: res.data.excessFormatted, excessTermsDays: 7 });
            setExcessSelected(res.data.excessSelected);
            setReportSelected({ roofReportSelected: res.data.roofSelected, leakDetectionSelected: res.data.leakSelected });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getBlankDetailRow = () => {
        var blankItem: InvoiceDetails = {
            id: 0,
            linkId: 0,
            linkTypeId: 0,
            description: "",
            subTotal: 0,
            gst: 0,
            total: 0
        };
        return blankItem;
    }

    const searchXeroCustomers = (e: any) => {
        e.preventDefault();
        searchXeroInvCustomers();
    }

    const searchXeroInvCustomers = async () => {
        if (xeroNameSearch) {
            const token = await authService.getAccessToken();
            const user = await authService.getUser();

            axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + xeroNameSearch, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var customers = res.data;
                var xeroCusts = [...xeroCustomers];
                for (var i = 0; i < customers.length; i++) {
                    var customer = customers[i];
                    xeroCusts.push(customer);
                }
                setXeroCustomers(xeroCusts);

                if (customers.length === 0) {
                    toast.info("There were no Xero customers that matched your search.  Note: Xero search is case sensitive!");
                } else {
                    toast.info("Xero customer results added to Xero Customers list");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        } else {
            let error: any = {};
            error["xeroNameSearch"] = "Enter name to search";
            setErrors(error);
        }
    }

    //add Xero Contact
    const addXeroCustomer = (e: any) => {
        e.preventDefault();
        setShowAddXeroContactModal(true);
    }

    const hideAddXeroModal = () => {
        setShowAddXeroContactModal(false);
    }

    const saveAddXeroModal = (id: string, name: string) => {
        var newContact: XeroListItem = {
            id: id,
            name: name,
            totalFormatted: "$0.00"
        };

        let customers = [...xeroCustomers];
        customers.push(newContact);
        setXeroCustomers(customers);
        setInvoice(prevState => ({ ...prevState, xeroCustomerId: newContact.id, customerName: newContact.name, isDirty: true }));
        setShowAddXeroContactModal(false);
    }
    //End add Xero Contact

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setInvoice(prevState => ({ ...prevState, [e.target.name]: e.target.value, isDirty: true }));
        setSendDisabled(false);
        setSaveDisabled(false);

        if (e.target.name === "xeroCustomerId") {
            //also save the customer name
            var xeroItem = xeroCustomers[e.target.selectedIndex - 1];
            if (xeroItem) {
                setInvoice(prevState => ({ ...prevState, customerName: xeroItem.name }));
            }
        } else if (e.target.name === "dueDate") {
            setInvoice(prevState => ({ ...prevState, dueDateActual: e.target.value }));
        }
    }

    //if typeid is changed and final invoice is selected then add any unticked items to the invoice
    const handleTypeChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setInvoice(prevState => ({ ...prevState, [e.target.name]: e.target.value, progressClaimNumber: 0, isDirty: true }));
        setSendDisabled(false);
        setSaveDisabled(false);

        //remove all existing items
        removeExistingItems();

        var brandName = "insurance";
        if (jobData.jobTypeId === 2) {
            brandName = "private";
        }

        var invDueDate = DateHelpers.addDaysToCurrentDate(invoice.clientTermsDays);
        var brandId = "";

        //set brand for insurance/private works
        var brand = xeroInvoiceBrandingThemes.find(b => b.name.toLowerCase().indexOf(brandName) > -1);
        if (brand) {
            brandId = brand.id;
        }

        var value = e.target.value * 1;
        if (value === 1) {
            //set due date as 7 days for excess
            invDueDate = DateHelpers.addDaysToCurrentDate(excessData.excessTermsDays);

            //set brand for excess
            var brand = xeroInvoiceBrandingThemes.find(b => b.name.toLowerCase().indexOf("excess") > -1);
            if (brand) {
                brandId = brand.id;
            }
            setInvoice(prevState => ({ ...prevState, dueDate: invDueDate, dueDateActual: invDueDate, xeroBrandingThemeId: brandId, isDirty: true }));
            setReportSelected({ roofReportSelected: false, leakDetectionSelected: false });

            //excess invoice
            createExcessInvoice();
        } else if (value === 2) {
            //progress invoice
            let invDetails: InvoiceDetails[] = [];
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
            setInvoice(prevState => ({ ...prevState, progressClaimNumber: nextProgressClaimNumber, dueDate: invDueDate, dueDateActual: invDueDate, xeroBrandingThemeId: brandId, isDirty: true, invoiceDetails: invDetails }));
            setReportSelected({ roofReportSelected: false, leakDetectionSelected: false });

            calculateTotals(invDetails);
        } else if (value === 3) {
            //final invoice
            addUntickedItems();
            setInvoice(prevState => ({ ...prevState, dueDate: invDueDate, dueDateActual: invDueDate, xeroBrandingThemeId: brandId, isDirty: true }));
            setReportSelected({ roofReportSelected: false, leakDetectionSelected: false });
        } else {
            setInvoice(prevState => ({ ...prevState, dueDate: invDueDate, dueDateActual: invDueDate, xeroBrandingThemeId: brandId, isDirty: true }));
            setReportSelected({ roofReportSelected: false, leakDetectionSelected: false });
            getReportFees(value, true);
        }      
    }

    const getReportFees = async (invoiceType: number, replaceDetails: boolean) => {
        const token = await authService.getAccessToken();

        axios.get('Jobs/GetInvoiceDetailsByInvoiceType?JobId=' + props.jobId + '&InvoiceType=' + invoiceType, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (replaceDetails) {
                //changing invoice type so replace invoice details
                let invDetails = res.data;
                var blankItem = getBlankDetailRow();
                invDetails.push(blankItem);

                setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails }));
                calculateTotals(invDetails);
            } else {
                //adding extra fees onto existing invoice details
                var invDetails = [...invoice.invoiceDetails];
                invDetails.pop();
                var finalDetails = invDetails.concat(res.data);
                var blankItem = getBlankDetailRow();
                finalDetails.push(blankItem);

                setInvoice(prevState => ({ ...prevState, invoiceDetails: finalDetails, isDirty: true }));
                calculateTotals(finalDetails);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const removeExistingItems = () => {
        var inv = invoice;
        var items = [...inv.invoiceDetails];
        var variations = [...approvedVariations];
        var progressClaims = [...progressClaimsXero];

        //work out which boxes to untick (there could be progress invoices so don't want to untick all
        for (var i = 0; i < items.length; i++) {
            if (items[i].linkTypeId === 1) {
                //estimate
                setEstimateSelected(false);
            } else if (items[i].linkTypeId === 2) {
                //variation
                var variation = variations.find(v => v.id === items[i].linkId);
                if (variation) {
                    variation.selected = false;
                }
            } else if (items[i].linkTypeId === 3) {
                //excess deduction
                setExcessSelected(false);
            } else if (items[i].linkTypeId === 4) {
                let leak = reportSelected.leakDetectionSelected;
                setReportSelected({ roofReportSelected: false, leakDetectionSelected: leak });
            } else if (items[i].linkTypeId === 5) {
                let roof = reportSelected.roofReportSelected;
                setReportSelected({ roofReportSelected: roof, leakDetectionSelected: false });
            } else if (items[i].linkTypeId === 7) {
                var progress = progressClaims.find(p => p.id === items[i].linkId);
                if (progress) {
                    progress.selected = false;
                }
            }
        }
        //remove all items
        setInvoice(prevState => ({ ...prevState, invoiceDetails: [], isDirty: true }));
        setApprovedVariations(variations);
        setProgressClaimsXero(progressClaims);
    }

    const createExcessInvoice = () => {
        //remove all invoice details
        var invDetails: InvoiceDetails[] = [];
        var excessItem = getExcessItem();
        invDetails.push(excessItem);
        var blankItem = getBlankDetailRow();
        invDetails.push(blankItem);
        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true })); 
        calculateTotals(invDetails);
    }

    const addUntickedItems = () => {
        var invDetails: InvoiceDetails[] = [];
        var estSel = estimateSelected;
        var excessSel = excessSelected;

        //add estimate
        if (!estSel) {
            var estimate = getEstimateItem(true);
            invDetails.push(estimate);
            estSel = true;
        }

        //add excess
        var excessAmt = excessData.excess * 1;
        if (!excessSelected && excessAmt > 0) {
            var excessItem = getExcessDeductionItem();
            invDetails.push(excessItem);
            excessSel = true;
        }

        //add any variations
        var variations = [...approvedVariations];
        for (var i = 0; i < variations.length; i++) {
            var variation = variations[i];
            var varItem = getVariationItem(variation, true);
            invDetails.push(varItem);
            variation.selected = true;
        }
        setApprovedVariations(variations);

        //add any progress claims
        var progressClaims = [...progressClaimsXero];
        for (var x = 0; x < progressClaims.length; x++) {
            var claim = progressClaims[x];
            if (!claim.selected) {
                var proItem = getProgressClaimItem(claim, x + 1);
                invDetails.push(proItem);
                claim.selected = true;
            }
        }
        setProgressClaimsXero(progressClaims);

        var blankItem = getBlankDetailRow();
        invDetails.push(blankItem);

        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true })); 
        setEstimateSelected(estSel);
        setExcessSelected(excessSel);
        calculateTotals(invDetails);
    }

    //used for Excess Invoice to customer
    const getExcessItem = () => {
        var excessAmt = excessData.excess * 1;
        var gstAmt = customerRegisteredForGst ? excessAmt * gstData.gstPercent : 0;
        var totalAmt = excessAmt + gstAmt;

        var invDetails: InvoiceDetails = {
            id: 0,
            linkId: 0,
            linkTypeId: 0,
            description: "Policy Excess",
            subTotal: parseFloat(excessAmt.toFixed(2)),
            gst: parseFloat(gstAmt.toFixed(2)),
            total: parseFloat(totalAmt.toFixed(2))
        };
        return invDetails;
    }

    //gets remaining estimate or full for final invoice
    const getEstimateItem = (useFullTotal: boolean) => {
        //add estimate  line item
        var subTotal = estimateData.estimateSubTotal;
        var gst = estimateData.estimateGst;
        var total = estimateData.estimateTotal;

        if (estimateData.estimateTotalUsed > 0 && !useFullTotal) {
            //calculate totals
            total = estimateData.estimateTotal - estimateData.estimateTotalUsed;
            gst = total / gstData.gstCalculation;
            subTotal = total - gst;
        }

        var desc = (invoice.typeId * 1) === 2 ? "Progress Claim " + nextProgressClaimNumber : "Completed works as per quotation";

        var invDetails: InvoiceDetails = {
            id: 0,
            linkId: estimateData.estimateId,
            linkTypeId: 1,
            description: desc,
            subTotal: parseFloat(subTotal.toFixed(2)),
            gst: parseFloat(gst.toFixed(2)),
            total: parseFloat(total.toFixed(2))
        };
        return invDetails;
    }

    const getExcessDeductionItem = () => {
        var excessAmt = excessData.excess * -1;

        var invDetails: InvoiceDetails = {
            id: 0,
            linkId: 0,
            linkTypeId: 3,
            description: "Deduction for policy excess collected",
            subTotal: parseFloat(excessAmt.toFixed(2)),
            gst: 0.00,
            total: parseFloat(excessAmt.toFixed(2))
        };
        return invDetails;
    }

    const getVariationItem = (variation: VariationListItem, addTotalVariation: boolean) => {
        var subTotal = variation.subTotal;
        var gst = variation.gst;
        var total = variation.total;

        //When we do the final invoice we need to include the full variation total, as we deduct any progress claims from the total
        if (!addTotalVariation) {
            if (variation.totalUsed > 0) {
                //calculate totals
                total = total - variation.totalUsed;
                gst = total / gstData.gstCalculation;
                subTotal = total - gst;
            }
        }

        var varItem: InvoiceDetails = {
            id: 0,
            linkId: variation.id,
            linkTypeId: 2,
            description: "Variation " + variation.variationNumber + " - " + variation.name,
            subTotal: parseFloat(subTotal.toFixed(2)),
            gst: parseFloat(gst.toFixed(2)),
            total: parseFloat(total.toFixed(2))
        };
        return varItem;
    }

    const getProgressClaimItem = (progressClaim: ProgressClaimItem, claimNumber: number) => {
        var subTotal = progressClaim.subTotal * -1;
        var gst = progressClaim.gst * -1;
        var total = progressClaim.total * -1;

        var proItem: InvoiceDetails = {
            id: 0,
            linkId: progressClaim.id,
            linkTypeId: 7,
            description: "Deduction for Progress Claim " + claimNumber,
            subTotal: parseFloat(subTotal.toFixed(2)),
            gst: parseFloat(gst.toFixed(2)),
            total: parseFloat(total.toFixed(2))
        };
        return proItem;
    }

    const handleEstimateCheckboxChange = (e: any) => {
        var invDetails = [...invoice.invoiceDetails];

        //check if final invoice
        var finalInv = (invoice.typeId * 1) === 3;

        //add or remove estimate from invoice line items
        if (e.target.checked) {
            invDetails.pop();
            var estDetails = getEstimateItem(finalInv);
            invDetails.push(estDetails);
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
        } else {
            //remove estimate line item
            var index = invDetails.findIndex(d => d.linkTypeId === 1 && d.linkId === estimateData.estimateId);
            if (index >= 0) {
                invDetails.splice(index, 1);
            }
        }

        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
        setEstimateSelected(e.target.checked);
        setSendDisabled(false);
        setSaveDisabled(false);

        calculateTotals(invDetails);
    }

    const handleExcessCheckboxChange = (e: any) => {
        var invDetails = [...invoice.invoiceDetails];

        //add or remove excess from invoice line items
        if (e.target.checked) {
            invDetails.pop();
            //add excess  line item
            var excDetails = getExcessDeductionItem();
            invDetails.push(excDetails);
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
        } else {
            //remove excess line item
            var index = invDetails.findIndex(d => d.linkTypeId === 3 && d.linkId === 0);
            if (index >= 0) {
                invDetails.splice(index, 1);
            }
        }
        
        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
        setExcessSelected(e.target.checked);
        setSendDisabled(false);
        setSaveDisabled(false);

        calculateTotals(invDetails);
    }

    const handleProgressClaimCheckboxChange = (index: number, e: any) => {
        var invDetails = [...invoice.invoiceDetails];
        var progressClaims = [...progressClaimsXero];
        var claim = progressClaims[index];
        claim.selected = e.target.checked;

        //add or remove selected claim from invoice line items
        if (e.target.checked) {
            invDetails.pop();
            //add progress claim line item
            var claimItem = getProgressClaimItem(claim, index + 1);
            invDetails.push(claimItem);
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
        } else {
            //remove variation line item
            var index = invDetails.findIndex(d => d.linkTypeId === 7 && d.linkId === claim.id);
            if (index >= 0) {
                invDetails.splice(index, 1);
            }
        }

        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
        setProgressClaimsXero(progressClaims);
        setSendDisabled(false);
        setSaveDisabled(false);

        calculateTotals(invDetails);
    }

    const handleVariationCheckboxChange = (index: number, e: any) => {
        var invDetails = [...invoice.invoiceDetails];
        var variations = [...approvedVariations];
        var variation = variations[index];
        variation.selected = e.target.checked;

        //add or remove selected variation from invoice line items
        if (e.target.checked) {
            invDetails.pop();
            //add variation line item
            var varItem = getVariationItem(variation, false);
            invDetails.push(varItem);
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
        } else {
            //remove variation line item
            var index = invDetails.findIndex(d => d.linkTypeId === 2 && d.linkId === variation.id);
            if (index >= 0) {
                invDetails.splice(index, 1);
            }
        }

        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
        setApprovedVariations(variations);
        setSendDisabled(false);
        setSaveDisabled(false);

        calculateTotals(invDetails);
    }

    const handleRoofReportCheckboxChange = (e: any) => {
        setReportSelected(prevState => ({ ...prevState, roofReportSelected: e.target.checked }));
        
        //get report fee and add to invoice
        if (e.target.checked) {
            getReportFees(6, false);
        } else {
            //remove leak fee from invoice
            let invDetails = [...invoice.invoiceDetails];
            var index = invDetails.findIndex(d => d.linkTypeId === 4 && d.linkId === 0);
            if (index >= 0) {
                invDetails.splice(index, 1);
            }
            setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
            calculateTotals(invDetails);
        }
    }

    const handleLeakReportCheckboxChange = (e: any) => {
        setReportSelected(prevState => ({ ...prevState, leakDetectionSelected: e.target.checked }));

        //get report fee and add to invoice
        if (e.target.checked) {
            getReportFees(7, false);
        } else {
            //remove leak fee from invoice
            let invDetails = [...invoice.invoiceDetails];
            var index = invDetails.findIndex(d => d.linkTypeId === 5 && d.linkId === 0);
            if (index >= 0) {
                invDetails.splice(index, 1);
            }
            setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
            calculateTotals(invDetails);
        }
    }

    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var invDetails = [...invoice.invoiceDetails];
        var detail = invDetails[index];
        let exist: any = {};
        exist = detail;
        exist[e.target.name] = e.target.value;

        if (index === (invoice.invoiceDetails.length - 1)) {
            //add new blank row
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
        }
        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
        setSendDisabled(false);
        setSaveDisabled(false);
        setErrors({});
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var invDetails = [...invoice.invoiceDetails];
        var detail = invDetails[index];
        let exist: any = {};
        exist = detail;
        var amount = e.target.value * 1;
        exist[e.target.name] = amount;

        //calculate line item totals
        if (e.target.name === "total") {
            var gst = (amount / gstData.gstCalculation);
            exist["gst"] = gst.toFixed(2);
            exist["subTotal"] = (amount - gst).toFixed(2);
        } else if (e.target.name === "subTotal") {
            var gst = (amount * gstData.gstPercent);
            exist["gst"] = gst.toFixed(2);
            exist["total"] = (amount + gst).toFixed(2);
        } else {
            //gst
            var subTotal = detail.subTotal * 1;
            exist["total"] = (amount + subTotal).toFixed(2);
        }
        
        if (index === (invoice.invoiceDetails.length - 1)) {
            //add new blank row
            var blankItem = getBlankDetailRow();
            invDetails.push(blankItem);
        }

        setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails, isDirty: true }));
        setSendDisabled(false);
        setSaveDisabled(false);
        setErrors({});

        calculateTotals(invDetails);
    }

    const calculateTotals = (invDetails: InvoiceDetails[]) => {
        var subTotal = 0;
        var gst = 0;
        var total = 0;

        for (var i = 0; i < invDetails.length; i++) {
            subTotal += parseFloat((invDetails[i].subTotal * 1).toFixed(2));
            gst += parseFloat((invDetails[i].gst * 1).toFixed(2));
            total += parseFloat((invDetails[i].total * 1).toFixed(2));
        }

        let subTotalFormatted = subTotal.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
        let gstFormatted = gst.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
        let totalFormatted = total.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });

        setInvoice(prevState => ({ ...prevState, subTotal: subTotal, subTotalFormatted: subTotalFormatted, gst: gst, gstFormatted: gstFormatted, total: total, totalFormatted: totalFormatted }));
    }

    //DELETE ITEM
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();
        setDeleteIndex(index);
        setShowDeleteConfirmModal(true);
        setModalSaveDisabled(false);
    }

    const hideDeleteConfirmModal = () => {
        setDeleteIndex(-1);
        setShowDeleteConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noDeleteConfirmModal = () => {
        setDeleteIndex(-1);
        setShowDeleteConfirmModal(false);
        setModalSaveDisabled(false);
    }

    const saveDeleteConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        var invDetails = [...invoice.invoiceDetails];
        var item = invDetails[deleteIndex];

        switch (item.linkTypeId) {
            case 1:
                //estimate
                setEstimateSelected(false);
                break;
            case 2:
                //variation
                var index = approvedVariations.findIndex(d => d.id === item.linkId);
                if (index >= 0) {
                    var updatedVariations = [...approvedVariations];
                    var variation = updatedVariations[index];
                    variation.selected = false;
                    setApprovedVariations(updatedVariations);
                }
                break;
            case 3:
                //excess deduction
                setExcessSelected(false);
                break;
            case 4:
                let leak = reportSelected.leakDetectionSelected;
                setReportSelected({ roofReportSelected: false, leakDetectionSelected: leak });
                break;
            case 5:
                let roof = reportSelected.roofReportSelected;
                setReportSelected({ roofReportSelected: roof, leakDetectionSelected: false });
                break;
        }

        if (item.id > 0) {
            deleteInvoiceItem();
        } else {
            //remove item from array
            invDetails.splice(deleteIndex, 1);
            setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails }));
            setSendDisabled(false);
            setSaveDisabled(false);
            setDeleteIndex(-1);
            setShowDeleteConfirmModal(false);
            setModalSaveDisabled(false);
            toast.success("Item has been removed");

            calculateTotals(invDetails);
        }
    }

    const deleteInvoiceItem = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var invDetails = [...invoice.invoiceDetails];
        var item = invDetails[deleteIndex];

        var deleteItem = {
            jobId: props.jobId,
            id: item.id,
            subId: user.sub
        };

        //remove item from database
        axios.post('Jobs/DeleteInvoiceItem', deleteItem, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //remove item from array
                invDetails.splice(deleteIndex, 1);
                setInvoice(prevState => ({ ...prevState, invoiceDetails: invDetails }));
                setSendDisabled(false);
                setSaveDisabled(false);
                setDeleteIndex(-1);
                setShowDeleteConfirmModal(false);
                setModalSaveDisabled(false);

                toast.success("Item has been deleted");

                calculateTotals(invDetails);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //END DELETE ITEM

    //BACK
    const back = () => {
        //if isDirty - ask if want to save changes
        if (invoice.isDirty) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);
            saveInvoice(true);
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            id: 0,
            page: "List"
        };

        props.changePage(pageChange);
    }
    //END BACK

    //CANCEL INVOICE
    const cancelInvoice = () => {
        setShowCancelConfirmModal(true);
        setModalSaveDisabled(false);
    }

    const hideCancelConfirmModal = () => {
        setShowCancelConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noCancelConfirmModal = () => {
        setShowCancelConfirmModal(false);
        setModalSaveDisabled(false);
    }

    const saveCancelConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        //set invoice to cancelled
        if (invoice.id === 0) {
            //invoice isn't saved
            setInvoice(prevState => ({ ...prevState, status: "Cancelled", statusId: 4 }));
            setShowCancelConfirmModal(false);
            setModalSaveDisabled(false);
        } else {
            deleteClientInvoice();
        }
    }

    const deleteClientInvoice = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var invoiceToCancel = {
            xeroInvoiceId: invoice.xeroInvoiceId,
            id: invoice.id,
            jobId: invoice.jobId,
            subId: user.sub
        };

        //cancel invoice
        axios.post('Xero/DeleteClientInvoice', invoiceToCancel, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                setShowCancelConfirmModal(false);
                setModalSaveDisabled(false);
            } else {
                toast.success("Invoice Cancelled");
                setInvoice(prevState => ({ ...prevState, status: "Cancelled", statusId: 4 }));
                setShowCancelConfirmModal(false);
                setModalSaveDisabled(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //END CANCEL INVOICE

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = invoice.invoiceDetails;
        var rowNumber = 0;

        //must have a customer and type selected
        if (!invoice.xeroCustomerId) {
            formIsValid = false;
            errorList["xeroCustomerId"] = "Xero Customer";
        }

        if (!invoice.xeroBrandingThemeId) {
            formIsValid = false;
            errorList["xeroBrandingThemeId"] = "Xero Branding Theme";
        }

        if (invoice.typeId < 1) {
            formIsValid = false;
            errorList["typeId"] = "Invoice Type";
        }

        if (!invoice.dueDate) {
            formIsValid = false;
            errorList["dueDate"] = "Due Date";
        }

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length < 1) {
            errorString = "Please select at least one Item!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].description) {
                    newErrorString += " Description, ";
                    error = true;
                }

                if (newErrorString !== "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["details"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        saveInvoice(false);
    }

    const saveInvoice = async (goBack: boolean) => {
        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        setSendDisabled(false);

        if (validate()) {
            const token = await authService.getAccessToken();
            const user = await authService.getUser();

            var inv = invoice;
            var invDetails = [...inv.invoiceDetails];
            invDetails.pop();  //remove blank row
            inv.invoiceDetails = invDetails;

            var saveInvoice = {
                invoice: inv,
                subId: user.sub
            };

            axios.post('Jobs/SaveJobInvoice', saveInvoice, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });
                } else {
                    toast.success("Invoice Saved");
                    if (goBack) {
                        var pageChange: PageChangeData = {
                            id: 0,
                            page: "List"
                        };
                        props.changePage(pageChange);
                    } else {
                        var invoiceResult = res.data;
                        var blankItem = getBlankDetailRow();
                        let invResultDetails = invoiceResult.invoiceDetails;
                        invResultDetails.push(blankItem);
                        setInvoice(invoiceResult);
                    }
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        } else {
            toast.error("Please fix errors on screen before saving");
        }
    }

    const sendInvoice = (e: any) => {
        e.preventDefault();

        if (sendDisabled) {
            return;
        }
        setSendDisabled(true);

        if (invoice.isDirty || invoice.id === 0) {
            toast.error("Please save the Invoice before sending to Xero!");
        } else {
            if (validate()) {
                sendInvoiceToXero();
            } else {
                toast.error("Please fix the validation issues before saving");
            }
        }
    }

    const sendInvoiceToXero = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var inv = invoice;
        var invDetails = [...inv.invoiceDetails];
        invDetails.pop();  //remove blank row
        inv.invoiceDetails = invDetails;

        var sendToXero = {
            invoice: inv,
            subId: user.sub
        };

        axios.post('Jobs/SendInvoiceToXero', sendToXero, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Invoice Saved and Sent to Xero");
                setInvoice(res.data);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getInvoicePdf = (e: any) => {
        e.preventDefault();
        getPdfInvoice();
    }

    const getPdfInvoice = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios('Xero/GetJobInvoiceAsPdf?XeroInvoiceId=' + invoice.xeroInvoiceId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'XeroInvoice' + invoice.xeroInvoiceNumber + '.pdf');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    let lastRow = (invoice.statusId > 1 || !pagePermissions.canEdit) ? invoice.invoiceDetails.length : invoice.invoiceDetails.length - 1;
    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmCancel = <ConfirmModal heading="Cancel Invoice" text="Are you sure you want to cancel this invoice?" hideConfirmModal={hideCancelConfirmModal} showConfirmModal={showCancelConfirmModal} noConfirmModal={noCancelConfirmModal} yesConfirmModal={saveCancelConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmDelete = <ConfirmModal heading="Delete Item" text="Are you sure you want to delete this item?" hideConfirmModal={hideDeleteConfirmModal} showConfirmModal={showDeleteConfirmModal} noConfirmModal={noDeleteConfirmModal} yesConfirmModal={saveDeleteConfirmModal} saveDisabled={modalSaveDisabled} />
    let addXeroContact = <AddXeroContactPopup jobId={props.jobId} showUseCustomer={true} show={showAddXeroContactModal} hide={hideAddXeroModal} save={saveAddXeroModal} />

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmBack}
                {confirmCancel}
                {confirmDelete}
                {addXeroContact}
            </div>
            <div className={props.showAddress ? "marginTop10 makeitflexspacebetween" : "backButton--right"}>
                <h3 className={props.showAddress ? "" : "hidden"}>{props.jobId} - {jobData.siteAddress}</h3>
                <button type="button" className="defaultbutton" onClick={back}>Back</button>
            </div>
            <div className="input-group-parent">
                <label className="input-group" htmlFor="typeId">
                    <span className="label">Type</span>
                    <select className="select" id="typeId" name="typeId" value={invoice.typeId} onChange={(e) => handleTypeChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}>
                        <option defaultValue="-1" value="-1"></option>
                        {invoiceTypes.map(invoiceType =>
                            <option key={invoiceType.id} value={invoiceType.id}>{invoiceType.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["typeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["typeId"]}</span>

                <label className="input-group" htmlFor="xeroBrandingThemeId">
                    <span className="label">Xero Branding Theme</span>
                    <select className="select" id="xeroBrandingThemeId" name="xeroBrandingThemeId" value={invoice.xeroBrandingThemeId} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit} >
                        <option defaultValue="" value=""></option>
                        {xeroInvoiceBrandingThemes.map(theme =>
                            <option key={theme.id} value={theme.id}>{theme.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["xeroBrandingThemeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroBrandingThemeId"]}</span>

                <div>
                    <label className="input-group" htmlFor="xeroNameSearch">
                        <span className="label">Xero Name Search</span>
                        <input className='input marginRight10' type='text' id="xeroNameSearch" name="xeroNameSearch" value={xeroNameSearch} onChange={(e) => setXeroNameSearch(e.target.value)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                        <button className="defaultbutton defaultbutton__xsmall supplier-invoice__search--position" type="button" onClick={searchXeroCustomers} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}>Search</button>
                    </label>
                    <span className={errors["xeroNameSearch"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroNameSearch"]}</span>
                </div>

                <label className="input-group" htmlFor="xeroCustomerId">
                    <span className="label">Xero Customer
                        <a className={(invoice.statusId === 1) ? "marginLeft10" : "hidden"} href="#" onClick={(e) => addXeroCustomer(e)}>
                            Add New
                        </a>
                    </span>
                    <select className="select" id="xeroCustomerId" name="xeroCustomerId" value={invoice.xeroCustomerId} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}>
                        <option defaultValue="" value=""></option>
                        {xeroCustomers.map(xeroCustomer =>
                            <option key={xeroCustomer.id} value={xeroCustomer.id}>{xeroCustomer.name + " " + xeroCustomer.totalFormatted}</option>
                        )};
                    </select>
                </label>
                <span className={errors["xeroCustomerId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroCustomerId"]}</span>

                <label className="input-group" htmlFor="dueDate">
                    <span className="label">Due Date</span>
                    <input type='date' id="dueDate" name="dueDate" className='input' value={invoice.dueDate} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                </label>
                <span className={errors["dueDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["dueDate"]}</span>
            </div>
            <div className="input-group-parent">
                <label className="input-group" htmlFor="xeroInvoiceNumber">
                    <span className="label">Xero Invoice Number</span>
                    <input type='text' id="xeroInvoiceNumber" name="xeroInvoiceNumber" className='input' value={invoice.xeroInvoiceNumber} disabled></input>
                    <span className={invoice.statusId === 2 || invoice.statusId === 3 ? "marginLeft10" : "hidden"}>
                        <a href="#" onClick={(e) => getInvoicePdf(e)}>
                            <span className="fas fa-file-image supplier-invoice--icon alignIconCenter file__photoColour"></span>
                        </a>
                    </span>
                </label>
                <label className="input-group" htmlFor="sentDate">
                    <span className="label">Sent Date</span>
                    <input type='text' id="sentDate" name="sentDate" className='input' value={invoice.sentDate} disabled></input>
                </label>

                <label className="input-group" htmlFor="paidDate">
                    <span className="label">Paid Date</span>
                    <input type='text' id="paidDate" name="paidDate" className='input' value={invoice.paidDate} disabled></input>
                </label>

                <label className="input-group" htmlFor="status">
                    <span className="label">Status</span>
                    <input type='text' id="status" name="status" className='input' value={invoice.status} disabled></input>
                </label>
            </div>
            <div className={ invoice.typeId == 2 || invoice.typeId == 3 ? "" : "hidden" }>
                <p>Select items to include in the Invoice:</p>
                <label className="input-group" htmlFor="estimateSelected">
                    <input className="checkbox  invoiceSelectionMargin" type="checkbox" id="estimateSelected" name="estimateSelected" checked={estimateSelected} onChange={(e) => handleEstimateCheckboxChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                    <span className="label labelXLarge">Estimate {estimateData.estimateTotalFormatted} - Remaining: { estimateData.estimateTotalRemaining }</span>
                </label>
                <div className={excessData.excess === 0 ? "hidden" : ""}>
                    <label className="input-group" htmlFor="excessSelected">
                        <input className="checkbox invoiceSelectionMargin" type="checkbox" id="excessSelected" name="excessSelected" checked={excessSelected} onChange={(e) => handleExcessCheckboxChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                        <span className="label">- Excess {excessData.excessFormatted}</span>
                    </label>
                </div>
                <div className={(invoice.typeId * 1) === 3 ? "input-group-parent" : "hidden"}>
                    <div className={progressClaimsXero.length === 0 ? "hidden" : ""}>
                        <p>Progress Claims (Sent to Xero)</p>
                        {progressClaimsXero.map((claim, index) =>
                            <div key={claim.id}>
                                <input className="checkbox invoiceSelectionMargin" type="checkbox" id={"claim" + claim.id} name="claim" checked={claim.selected} onChange={(e) => handleProgressClaimCheckboxChange(index, e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                                <div className="label">Progress Claim {index + 1}: {claim.totalFormatted}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={(invoice.typeId * 1) === 3 ? "input-group-parent" : "hidden"}>
                    <div className={progressClaimsUnsent.length === 0 ? "hidden" : ""}>
                        <p>Progress Claims (Not Sent)</p>
                        {progressClaimsUnsent.map(claim =>
                            <div key={claim.id}>
                                <div className="label">Progress Claim: {claim.totalFormatted}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="input-group-parent">
                    <div className={approvedVariations.length === 0 ? "hidden" : ""}>
                        <p>Approved Variations</p>
                        {approvedVariations.map((variation, index) =>
                            <div key={variation.id}>
                                <input className="checkbox invoiceSelectionMargin" type="checkbox" id={"variation" + variation.id} name="variation" checked={variation.selected} onChange={(e) => handleVariationCheckboxChange(index, e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                                <div className="label">{variation.displayName} - Remaining: { variation.totalRemaining }</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="input-group-parent">
                    <div className={unapprovedVariations.length === 0 ? "hidden" : ""}>
                        <p>Unapproved Variations</p>
                        {unapprovedVariations.map(variation =>
                            <div key={variation.id}>
                                <div className="label">{variation.displayName}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={invoice.typeId == 6 || invoice.typeId == 7 || invoice.typeId == 8 ? "" : "hidden"}>
                <p>Select other report fees to include in the invoice:</p>
                <div className={invoice.typeId == 6 ? "hidden" : ""}>
                    <label className="input-group" htmlFor="roofReportSelected">
                        <input className="checkbox invoiceSelectionMargin" type="checkbox" id="roofReportSelected" name="roofReportSelected" checked={reportSelected.roofReportSelected} onChange={(e) => handleRoofReportCheckboxChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                        <span className="label">Roof Report</span>
                    </label>
                </div>
                <div className={invoice.typeId == 7 ? "hidden" : ""}>
                    <label className="input-group" htmlFor="leakDetectionSelected">
                        <input className="checkbox invoiceSelectionMargin" type="checkbox" id="leakDetectionSelected" name="leakDetectionSelected" checked={reportSelected.leakDetectionSelected} onChange={(e) => handleLeakReportCheckboxChange(e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit}></input>
                        <span className="label">Leak Detection</span>
                    </label>
                </div>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__large tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th className="hidden">Link Id</th>
                            <th className="hidden">Link Type Id</th>
                            <th>Description</th>
                            <th className="textalignright tableHeader--maxwidth">Sub Total</th>
                            <th className="textalignright tableHeader--maxwidth">GST</th>
                            <th className="textalignright tableHeader--maxwidth">Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.invoiceDetails.map((detail, index) =>
                            <tr key={index}>
                                <td className="hidden">{detail.id}</td>
                                <td className="hidden">{detail.linkId}</td>
                                <td className="hidden">{detail.linkTypeId}</td>
                                <td className="table__text--align">
                                    <input type='text' className="form-control table__tdmin-xlg" maxLength={3000} name="description" value={detail.description} onChange={(e) => handleCellChange(index, e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit } />
                                </td>
                                <td className="table__text--align">
                                    <input type='number' className="form-control table__tdmin--sm textalignright" name="subTotal" value={detail.subTotal} onChange={(e) => handleCellAmountChange(index, e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <input type='number' className="form-control table__tdmin--sm textalignright" name="gst" value={detail.gst} onChange={(e) => handleCellAmountChange(index, e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <input type='number' className="form-control table__tdmin--sm textalignright" name="total" value={detail.total} onChange={(e) => handleCellAmountChange(index, e)} disabled={invoice.statusId > 1 || !pagePermissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <div className={invoice.statusId > 1 || !pagePermissions.canEdit || index === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(index, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["details"] ?
                    (errors["details"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>

            <div className="input-group-parent">
                <div>
                    <button className="defaultbutton defaultbutton__medium" type="submit" disabled={invoice.statusId > 1 || !pagePermissions.canEdit || saveDisabled}>Save</button>
                    <button className="defaultbutton defaultbutton__medium threebuttons__btn2--position" type="button" onClick={sendInvoice} disabled={invoice.statusId > 1 || !pagePermissions.canSend || sendDisabled}>Send To Xero</button>
                    <button className="defaultbutton defaultbutton__medium threebuttons__btn3--position" type="button" onClick={cancelInvoice} disabled={!pagePermissions.canEdit || invoice.statusId === 4}>Cancel</button>
                </div>
            </div>
            <div className="input-group-parent">
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="subTotalFormatted">
                    <span className="label">Sub Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalFormatted" name="subTotalFormatted" value={invoice.subTotalFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="gstFormatted">
                    <span className="label">GST</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={invoice.gstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={invoice.totalFormatted} disabled></input>
                </label>
            </div>

        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );

}

export default JobInvoiceDetailData;
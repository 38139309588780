import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import JobTabsMobile from './JobTabsMobile';
import JobPurchaseOrderListMobileData from './JobPurchaseOrderListMobile';
import JobPurchaseOrderDetailsMobileData from './JobPurchaseOrderMobileDetails';

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplier: string;
    insertedDateFormatted: string;
    totalFormatted: string;
    invoicedFormatted: string;
    remainingFormatted: string;
    status: string;
}


const JobPurchaseOrdersMobileData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState({ canViewEstimate: false, canViewCostings: false, showAssessment: false });
    const [purchaseOrders, setPurchaseOrders] = React.useState<PurchaseOrder[]>([]);
    const [page, setPage] = React.useState("list");
    const [poId, setPoId] = React.useState(0);
    const [howRequired, setHowRequired] = React.useState(false);
    const [howCompleted, setHowCompleted] = React.useState(false);

    window.scrollTo(0, 0);

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //get user roles and status
        axios.get('Jobs/GetPurchaseOrdersMobile?JobId=' + Id + "&SubId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                setPermissions({ canViewEstimate: res.data.canViewEstimate, canViewCostings: res.data.canViewCostings, showAssessment: res.data.showAssessment });
                setHowRequired(res.data.howRequired);
                setHowCompleted(res.data.howCompleted);
                setPurchaseOrders(res.data.purchaseOrders);
                setLoading(false);
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const open = (id: number) => {
        setPage("details");
        setPoId(id);
    }

    const addNew = () => {
        var url = "/job/" + jobData.jobId + "/purchaseorderdetailsm/";
        navigate(url);
    }

    const goBackConfirm = () => {
        setPage("list");
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    var jobTabsMobile = <JobTabsMobile id={jobData.jobId} tab="details" canViewEstimate={permissions.canViewEstimate} canViewCostings={permissions.canViewCostings} showAssessment={permissions.showAssessment} tabChanged={tabChanged} />
    var tabContents;
    if (page === "list") {
        tabContents = <JobPurchaseOrderListMobileData jobId={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} howRequired={howRequired} howCompleted={howCompleted} purchaseOrders={purchaseOrders} open={open} addNew={addNew} />;
    }
    else if (page === "details") {
        tabContents = <JobPurchaseOrderDetailsMobileData id={poId} jobId={jobData.jobId} goBack={goBackConfirm} />
    }

    const renderDetails = (
        <div>
            <div className="marginTop10 marginBottom70">
                {tabContents}
            </div>
            <div>
                {jobTabsMobile}
            </div>
        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobPurchaseOrdersMobileData;
import * as React from 'react';

interface SupplierInvoicePoSearchResultsProps {
    searchResults: SearchResult[];
    showNoPoSearch: boolean;
    invoiceTotal: number;
    updateResults(searchResults: SearchResult[]): void;
}

interface SearchResult {
    selected: boolean;
    jobId: number;
    isMakeSafe: boolean;
    siteAddress: string;
    supplier: string;
    supplierId: number;
    supplierRegisteredForGst: boolean;
    xeroContactId: string;
    xeroContactName: string;
    purchaseOrders: SearchResultPo[];
}

interface SearchResultPo {
    selected: boolean;
    purchaseOrderId: number;
    dueDate: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    totalApplied: number;
    total: number;
    totalFormatted: string;
    remainingSubTotal: number;
    remainingSubTotalFormatted: string;
    remainingGst: number;
    remainingGstFormatted: string;
    remainingTotal: number;
    remainingTotalFormatted: string;
}

const SupplierInvoicePoSearchResults = (props: SupplierInvoicePoSearchResultsProps) => {


    //JOB TABLE
    const handleCellCheckboxChange = (e: any, index: number) => {
        var results = [...props.searchResults];

        //set all results selected to false as can only select one at a time
        for (var i = 0; i < results.length; i++) {
            results[i].selected = false;
        }
        var result = results[index];

        let change: any = {};
        change = result;
        change[e.target.name] = e.target.checked;
        props.updateResults(results);
    }

    //PO TABLE
    const handlePOCellAmountChange = (e: any, parentIndex: number, index: number) => {
        var results = [...props.searchResults];
        var poList = results[parentIndex].purchaseOrders;
        var po = poList[index];

        let change: any = {};
        change = po;
        change[e.target.name] = e.target.value * 1;
        props.updateResults(results);
    }

    const handlePOCellCheckboxChange = (e: any, parentIndex: number, index: number) => {
        var results = [...props.searchResults];
        var poList = results[parentIndex].purchaseOrders;
        var po = poList[index];

        //set all results selected to false as can only select one at a time
        for (var i = 0; i < results.length; i++) {
            if (i != parentIndex) {
                //untick all items not under current job/supplier 
                for (var y = 0; y < results[i].purchaseOrders.length; y++) {
                    results[i].purchaseOrders[y].selected = false;
                    results[i].purchaseOrders[y].totalApplied = 0;
                }
            }
        }

        let change: any = {};
        change = po;
        change[e.target.name] = e.target.checked;
        if (e.target.checked) {
            //calculate total
            var invoiceTotal = props.invoiceTotal;
            //calculate amount applied
            var poApplied = poList.filter(t => t.totalApplied > 0);
            var appliedTotal = 0;
            for (var x = 0; x < poApplied.length; x++) {
                appliedTotal += poApplied[x].totalApplied;
            }

            var invRemaining = invoiceTotal - appliedTotal;
            var invoiceRemaining = Math.round((invRemaining + Number.EPSILON) * 100) / 100
            if (invoiceRemaining <= 0) {
                po.totalApplied = 0;
            } else if (invoiceRemaining > po.remainingTotal) {
                if (results[parentIndex].supplierRegisteredForGst) {
                    po.totalApplied = po.remainingTotal;
                } else {
                    po.totalApplied = po.remainingTotal;
                }
            } else {
                if (results[parentIndex].supplierRegisteredForGst) {
                    po.totalApplied = invoiceRemaining;
                } else {
                    po.totalApplied = invoiceRemaining;
                }
            }

        } else {
            //set total to 0
            po.totalApplied = 0;
        }
        props.updateResults(results);
    }

    return (
        <div className='overflowAuto'>
            <table className="table--main tableColours">
                <thead>
                    <tr>
                        <th></th>
                        <th>Job Id</th>
                        <th>Site Address</th>
                        <th>Supplier</th>
                    </tr>
                </thead>
                <tbody>
                    {props.searchResults.map((result, index) =>
                        <React.Fragment key={index}>
                            <tr key={index}>
                                <td>
                                    <input type="checkbox" className={props.showNoPoSearch ? "input checkbox" : "hidden"} name="selected" checked={result.selected} onChange={(e) => handleCellCheckboxChange(e, index)} />
                                </td>
                                <td>{result.jobId}</td>
                                <td>{result.siteAddress}</td>
                                <td>{result.supplier}</td>
                            </tr>
                            <tr className={props.showNoPoSearch ? "hidden" : ""}>
                                <td colSpan={4}>
                                    <table className="table--main subtable tableColours-header">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>PO #</th>
                                                <th>Total</th>
                                                <th>Remaining</th>
                                                <th>Applied</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {result.purchaseOrders.map((po, poindex) =>
                                                <tr key={poindex}>
                                                    <td>
                                                        <input type="checkbox" className="input checkbox" name="selected" checked={po.selected} onChange={(e) => handlePOCellCheckboxChange(e, index, poindex)} />
                                                    </td>
                                                    <td>{po.purchaseOrderId}</td>
                                                    <td>{po.totalFormatted}</td>
                                                    <td>{po.remainingTotalFormatted}</td>
                                                    <td>
                                                        <input type='number' min="0" step="any" className="form-control textalignright" name="totalApplied" value={po.totalApplied} onChange={(e) => handlePOCellAmountChange(e, index, poindex)} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default SupplierInvoicePoSearchResults;
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';

interface ShortcutList {
    id: number;
    shortcut: string;
    costCodeId: number;
    scope: string;
    isDirty: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

const AdminShortcutData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [costCodeList, setCostCodeList] = React.useState<GenericListItem[]>([]);
    const [shortcutList, setShortcutList] = React.useState<ShortcutList[]>([]);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [addDisabled, setAddDisabled] = React.useState(false);
    const [shortcutIndex, setShortcutIndex] = React.useState(-1);
    const [shortcutDelete, setShortcutDelete] = React.useState("");
    const [newShortcut, setNewShortcut] = React.useState({ shortcut: "", costCodeId: -1, scope: "" });
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=EditAdminLists', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get list of shortcuts and dropdowns
                axios.get('Admin/GetShortcutLists', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    var shortcuts = res.data.shortcuts;
                    var costcodes = res.data.costCodes;

                    setShortcutList(shortcuts);
                    setCostCodeList(costcodes);
                    setLoading(false);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getShortcutList = async () => {
        const token = await authService.getAccessToken();
        axios.get('Admin/GetShortcutList', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var shortcuts = res.data;
            setShortcutList(shortcuts);
            setLoading(false);
        });
    }

    const handleChange = (e: any) => {
        setNewShortcut(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        setAddDisabled(false);
    }

    //grid item changed
    const handleCellChange = (index: number) => (e: any) => {
        //update item that has changed
        var shortcuts = [...shortcutList];
        var shortcut = shortcuts[index];
        let exist: any = {};
        exist = shortcut;
        exist[e.target.name] = e.target.value;
        exist["isDirty"] = true;

        setShortcutList(shortcuts);
        setSaveDisabled(false);
    }

    //DELETE
    const deleteShortcut = (index: number) => (e: any) => {
        e.preventDefault();

        var shortcut = shortcutList[index];

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setShortcutIndex(index);
        setShortcutDelete(shortcut.shortcut);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setShortcutIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        saveDeleteShortcut();
    }

    const saveDeleteShortcut = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var shortcut = shortcutList[shortcutIndex];
        var deleteShortcut = {
            id: shortcut.id,
            subId: user.sub
        };

        axios.post('Admin/DeleteShortcut', deleteShortcut, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Shortcut " + shortcut.shortcut + " has been deleted");
                setShowConfirmModal(false);
                setShortcutIndex(-1);
                getShortcutList();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //ADD
    const validateAdd = () => {
        var formIsValid = true;
        let errors: any = {};

        if (!newShortcut.shortcut) {
            formIsValid = false;
            errors["shortcut"] = "Shortcut is required";
        }

        if (!newShortcut.scope || newShortcut.scope.length < 5) {
            formIsValid = false;
            errors["scope"] = "Scope > 5 characters are required";
        }

        setErrors(errors);
        return formIsValid;
    }

    const add = (e: any) => {
        e.preventDefault();

        if (validateAdd()) {
            if (addDisabled) {
                return;
            }
            setAddDisabled(true);

            saveAddShortcut();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveAddShortcut = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let addShortcut = {
            shortcut: newShortcut.shortcut,
            costCodeId: newShortcut.costCodeId,
            scope: newShortcut.scope,
            subId: user.sub
        };

        axios.post('Admin/AddShortcut', addShortcut, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setNewShortcut({ shortcut: "", costCodeId: -1, scope: "" });
                toast.success("Shortcut has been added");
                
                getShortcutList();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //SAVE
    const validate = () => {
        let formIsValid = true;
        let errors: any = {};
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        for (var i = 0; i < shortcutList.length; i++) {
            rowNumber += 1;
            var newErrorString = "";

            if (shortcutList[i].shortcut === "") {
                newErrorString += " Shortcut, ";
                error = true;
            }

            if (shortcutList[i].scope === "") {
                newErrorString += " Scope, ";
                error = true;
            }

            if (newErrorString != "") {
                errorString += "\n"
                errorString += "Row " + rowNumber + ": " + newErrorString;
                errorString = errorString.substring(0, errorString.length - 2);
            }
        }

        if (error) {
            formIsValid = false;
            errors["shortcuts"] = errorString;
        }

        setErrors(errors);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            var shortcuts = shortcutList.filter(c => c.isDirty);

            if (shortcuts.length == 0) {
                toast.info("No shortcuts have been changed!");
            } else {
                if (saveDisabled) {
                    return;
                }
                setSaveDisabled(true);

                saveShortcuts(shortcuts);
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveShortcuts = async (shortcuts: ShortcutList[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let saveShortcuts = {
            shortcuts: shortcuts,
            subId: user.sub
        };

        axios.post('Admin/SaveShortcuts', saveShortcuts, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Shortcuts have been saved");
                getShortcutList();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const confirmPopup = <ConfirmModal heading={"Delete Shortcut " + shortcutDelete} text="Are you sure you want to delete this shortcut?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />

    const renderDetails = (
        <form>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div>
                <label className="input-group" htmlFor="shortcut">
                    <span className="label">Shortcut</span>
                    <input className='input' type='text' maxLength={15} id="shortcut" name="shortcut" value={newShortcut.shortcut} onChange={handleChange}></input>
                </label>
                <span className={errors["shortcut"] ? "label errors errors__leftmargin" : "hidden"}>{errors["shortcut"]}</span>

                <label className="input-group" htmlFor="costCodeId">
                    <span className="label">Cost Code</span>
                    <select className="select" id="costCodeId" name="costCodeId" value={newShortcut.costCodeId} onChange={handleChange}>
                        <option hidden defaultValue="-1"></option>
                        {costCodeList.map(cc =>
                            <option key={cc.id} value={cc.id}>{cc.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="scope">
                    <span className="label">Scope</span>
                    <textarea className="input job__noteSize" id="scope" name="scope" value={newShortcut.scope} onChange={handleChange}></textarea>
                </label>
                <span className={errors["scope"] ? "label errors errors__leftmargin" : "hidden"}>{errors["scope"]}</span>

                <button className="defaultbutton-prev defaultbutton__small defaultbutton-label marginBottom10" onClick={add} type="button" disabled={ addDisabled }>Add</button>

            </div>
            <div className="overflowAuto">
                <table className="table--main tableColours">
                    <thead>
                        <tr>
                            <th className="table--smallHeaderSize"></th>
                            <th className="hidden">Id</th>
                            <th className="table--smallHeaderSize">Shortcut</th>
                            <th className="table--smallHeaderSize">Cost Code</th>
                            <th>Scope</th>
                            <th className="table--smallHeaderSize table__center-text--align">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shortcutList.map((shortcut, index) =>
                            <tr key={index}>
                                <td className="table__text--align">{index + 1}</td>
                                <td className="hidden">{shortcut.id}</td>
                                <td className="table__text--align">{shortcut.shortcut}</td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="costCodeId" value={shortcut.costCodeId} onChange={handleCellChange(index)}>
                                        <option defaultValue="-1"></option>
                                        {costCodeList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <input className="input estimateShortcut--item table__tdmin-xlg" type="text" name="scope" value={shortcut.scope} onChange={handleCellChange(index)}></input>
                                </td>
                                <td className="table__text--align">
                                    <div className="delete--tablecell">
                                        <a className="makeitred" href="#" onClick={deleteShortcut(index)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["shortcuts"] ?
                    (errors["shortcuts"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <button className="defaultbutton marginBottom10" type="button" onClick={save} >Save</button>
        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        <h1>Add/Edit Estimate Shortcuts</h1>
        {contents}
    </div>)

}

export default AdminShortcutData;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const AdminSettingsData = () => {
    let navigate = useNavigate();
    const [domesticRegNo, setDomesticRegNo] = React.useState("");
    const [commercialRegNo, setCommercialRegNo] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=EditAdminLists', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get settings
                getSettingsData();
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getSettingsData = async () => {
        const token = await authService.getAccessToken();
        axios.get('Admin/GetSettings', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setDomesticRegNo(res.data.domesticRegistrationNo);
            setCommercialRegNo(res.data.commercialRegistrationNo);
            setLoading(false);
            
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleDomesticChange = (e: any) => {
        clearError(e.target.name);

        setDomesticRegNo(e.target.value);
    }

    const handleCommercialChange = (e: any) => {
        clearError(e.target.name);

        setCommercialRegNo(e.target.value);
    }

    const clearError = (errorName: string) => {
        var error = errors;
        delete error[errorName];
        setErrors(error);
    }


    const validate = () => {
        let formIsValid = true;
        let errorList: any = {};

        if (!domesticRegNo || domesticRegNo === "") {
            formIsValid = false;
            errorList["domesticRegNo"] = "Domestic Building Reg No is Required!";
        }

        if (!commercialRegNo || commercialRegNo === "") {
            formIsValid = false;
            errorList["commercialRegNo"] = "Commercial Building Reg No is Required!";
        }

        setErrors(errorList);

        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            saveSettings();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveSettings = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var adminSettings = {
            domesticRegistrationNo: domesticRegNo,
            commercialRegistrationNo: commercialRegNo,
            subId: user.sub
        };

        axios.post('Admin/SaveSettings', adminSettings, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Settings have been saved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <form onSubmit={save}>
            <label className="input-group" htmlFor="domesticRegNo">
                <span className="label">Domestic Building Reg No</span>
                <input className='input' type='text' maxLength={20} id="domesticRegNo" name="domesticRegNo" value={domesticRegNo} onChange={(e) => handleDomesticChange(e)} ></input>
            </label>
            <span className={errors["domesticRegNo"] ? "label errors errors__leftmargin" : "hidden"}>{errors["domesticRegNo"]}</span>

            <label className="input-group" htmlFor="commercialRegNo">
                <span className="label">Commercial Building Reg No</span>
                <input className='input' type='text' maxLength={20} id="commercialRegNo" name="commercialRegNo" value={commercialRegNo} onChange={(e) => handleCommercialChange(e)} ></input>
            </label>
            <span className={errors["commercialRegNo"] ? "label errors errors__leftmargin" : "hidden"}>{errors["commercialRegNo"]}</span>

            <button className="defaultbutton marginLeftLabel" type='submit'>Save</button>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        <h1>Add/Edit Settings</h1>
        {contents}
    </div>)
}

export default AdminSettingsData;

import * as React from 'react';
import ConfirmModal from './ConfirmModal';
import { toast } from 'react-toastify';

interface JobPurchaseOrderAddConfirmMobileProps {
    purchaseOrder: PurchaseOrder;
    purchaseOrderDetails: PurchaseOrderDetail[];
    costCodeBudgetCheck: CostCodeBudgetCheck[];
    cancel(): void;
    back(): void;
    updatePo(purchaseOrder: PurchaseOrder): void;
    updateCostCodeBudget(costCodeBudgetCheck: CostCodeBudgetCheck[]): void;
    edit(poDetailIndex: number): void;
    delete(poDetailIndex: number): void;
    save(): void;
}

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplierId: number;
    supplier: string;
    supplierContactId: number;
    supplierContact: string;
    registeredForGst: boolean;
    dateRequired: string;
    dateRequiredFormatted: string;
    includeFullScope: boolean;
    instructions: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    statusId: number;
}

interface PurchaseOrderDetail {
    itemIndex: number;
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    costCodeIdOrig: number;     //need to put back original values if they click back button
    costCodeOrig: string;
    quantityOrig: number;
    quantityTypeOrig: string;
    unitOfMeasureOrig: number;
    unitCostOrig: number;
    unitCostOrigFormatted: string;
    totalOrig: number;
    totalOrigFormatted: string;
    exceedForecastReason: string;
    newItem: boolean;
}

//remaining => based on forcast, budgetRemaining => based on estimate
interface CostCodeBudgetCheck {
    costCodeId: number;
    remaining: number;              //after catering for any items added on this po
    origRemaining: number;          //from the database based on existing saved po
    budgetRemaining: number;        //after catering for any items added on this po
    origBudgetRemaining: number;    //from the database based on existing saved po
    currentItemTotal: number;       //used to cater for user changing cost codes when adding/editing an item
}

const JobPurchaseOrderAddConfirmMobileData = (props: JobPurchaseOrderAddConfirmMobileProps) => {
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [poIndex, setPoIndex] = React.useState(-1);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const cancel = (e: any) => {
        e.preventDefault();
        setShowCancelConfirmModal(true);
    }

    const cancelConfirm = () => {
        props.cancel();
        setShowConfirmModal(false);
        setShowCancelConfirmModal(false);
        setModalSaveDisabled(false);
        setPoIndex(-1);
    }

    const back = (e: any) => {
        e.preventDefault();
        props.back();
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            props.save();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
        
    }

    const validate = () => {
        let formIsValid = true;
        let error: any = {};

        //check to make sure there is at least one line item
        var poDetails = props.purchaseOrderDetails;
        if (poDetails.length === 0) {
            error["details"] = "You must have at least one line item!";
            formIsValid = false;
        }
        setErrors(error);
        return formIsValid;
    }

    //edit item
    const edit = (e: any, index: number) => {
        e.preventDefault();

        props.edit(index);
    }
    //end edit item

    //delete row
    const deleteDetail = (e: any, index: number) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setPoIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setShowCancelConfirmModal(false);
        setModalSaveDisabled(false);
        setPoIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        props.delete(poIndex);
        setShowConfirmModal(false);
        setPoIndex(-1);
    }
    //end delete row

    let headingText = props.purchaseOrder.id === 0 ? "Add Purchase Order" : "Edit Purchase Order";
    let confirmPopup = <ConfirmModal heading="Delete PO Row" text="Are you sure you want to delete this purchase order detail row?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmPopupCancel = <ConfirmModal heading="Cancel" text="Are you sure you want to cancel?" hideConfirmModal={hideConfirmModal} showConfirmModal={showCancelConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={cancelConfirm} saveDisabled={modalSaveDisabled} />

    return (
        <div className="mobileFont marginBottom50">
            <div className="static-modal">
                {confirmPopup}
                {confirmPopupCancel}
            </div>
            <div className="makeitflexspacebetween">
                <h4 className="mobile-margin">{headingText}</h4>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-red" href="#" onClick={(e) => cancel(e)}>
                        <span className="fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
            <div>
                <div className="mobileBackground">
                    <div>{props.purchaseOrder.supplier}</div>
                    <div>{props.purchaseOrder.supplierContact}</div>
                    <div>{props.purchaseOrder.dateRequiredFormatted}</div>
                </div>
                <div className="mobileAltBackground">
                    <span className="mobileSplitScreen makeitbold">Instructions</span>
                    <div>{props.purchaseOrder.instructions}</div>

                    <span className="label">Include Full Scope</span>
                    <input className="checkbox" type="checkbox" id="includeFullScope" name="includeFullScope" checked={props.purchaseOrder.includeFullScope} disabled></input>
                </div>

                <div className="marginTop10">
                    <table className="table--main table__small tableColours mobileTableFont">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Budget</th>
                                <th className="textalignright">Quantity</th>
                                <th className="textalignright">Unit</th>
                                <th className="textalignright">Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.purchaseOrderDetails.map((detail, index) =>
                                <tr className={index === props.purchaseOrderDetails.length - 1 ? "hidden" : ""} key={index}>
                                    <td className="table__text--align">
                                        <div className="delete--tablecell">
                                            <a className="" href="#" onClick={(e) => edit(e, index)}>
                                                <span className="fas fa-edit edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="table__text--align">{detail.costCode}<br></br>{detail.remainingFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.quantityType}</td>
                                    <td className="table__text--align textalignright">{detail.unitCostFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.totalFormatted}</td>
                                    <td className="table__text--align">
                                        <div className="delete--tablecell">
                                            <a className="makeitred" href="#" onClick={(e) => deleteDetail(e, index)}>
                                                <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <span className={errors["details"] ? "label errors" : "hidden"}>{errors["details"]}</span>
            </div>
            <div className="mobileFont defaultbutton__container--right">
                <div>
                    <label className="input-group labeltextalignright makeitbold marginBottom15" htmlFor="subTotalExGstFormatted">
                        <span className="label-mobile">Sub Total ex GST</span>
                        <span className="estimate-mobile-totals">{props.purchaseOrder.subTotalFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright marginBottom15" htmlFor="gstFormatted">
                        <span className="label-mobile ">GST</span>
                        <span className="estimate-mobile-totals">{props.purchaseOrder.gstFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright makeitbold marginBottom15" htmlFor="totalFormatted">
                        <span className="label-mobile">Total</span>
                        <span className="estimate-mobile-totals">{props.purchaseOrder.totalFormatted}</span>
                    </label>
                </div>
            </div>
            <div className="bottom-edit-nav makeitflexspacebetween">
                <div className="jobpo__backButton">
                    <a className="mobile-icon-grey" href="#" onClick={(e) => back(e)}>
                        <span className="fas fa-arrow-alt-circle-left mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => save(e)}>
                        <span className="fas fa-check-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default JobPurchaseOrderAddConfirmMobileData;
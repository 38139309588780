import * as React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const win: any = window;
const Stimulsoft = win.Stimulsoft;

const JobReportViewerData = () => {
    const { id, name, tableid } = useParams();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let jobId = Number(id);
        let tableId = Number(tableid);
        let reportName = name ?? "";

        if (reportName != "") {
            getData(jobId, tableId, reportName);
        }
    }, []);

    const getData = async (jobId: number, tableId: number, reportName: string) => {
        const token = await authService.getAccessToken();

        //get the data
        axios.get('Jobs/Get' + reportName + 'Data?jobId=' + jobId + "&tableId=" + tableId + "&reportName=" + reportName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setLoading(false);

                var viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

                //console.log('Creating a new report instance');
                var report = new Stimulsoft.Report.StiReport();
                //report.loadFile("reports/" + res.data.reportFileName);
                var reportFile = Stimulsoft.System.IO.File.getFile("/reportfiles/" + res.data.reportFileName);
                report.load(reportFile);

                var reportData = JSON.stringify(res.data.reportData);
                //Dataset created
                var dataSet = new Stimulsoft.System.Data.DataSet("data");
                dataSet.readJson(reportData)
                report.regData(dataSet.dataSetName, "", dataSet);
                report.dictionary.connect(false);
                report.dictionary.synchronize();

                //add relationships between data
                for (var i = 0; i < res.data.reportRelationships.length; i++) {
                    var dataRelation = new Stimulsoft.Report.Dictionary.StiDataRelation(res.data.reportRelationships[i].sourceName, res.data.reportRelationships[i].name, res.data.reportRelationships[i].alias, report.dictionary.dataSources.getByName(res.data.reportRelationships[i].parentTableName), report.dictionary.dataSources.getByName(res.data.reportRelationships[i].childTableName), [res.data.reportRelationships[i].parentFieldName], [res.data.reportRelationships[i].childFieldName]);
                    report.dictionary.relations.add(dataRelation);
                    report.dictionary.synchronize();
                }

                viewer.report = report;
                viewer.renderHtml('reportviewer');
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div id="reportviewer">
        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

}

export default JobReportViewerData;

import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import AddXeroContactPopup from './JobInvoiceAddXeroCustomerPopup';
import SupplierInvoiceFilePopup from './SupplierInvoiceFile';
import * as DateHelpers from './DateHelpers';
import authService from './api-authorization/AuthorizeService';

interface JobSupplierInvoiceDetailProps {
    JobId: number;
    id: number;
    changePage(pageData: PageChangeData): void;
}

interface PageChangeData {
    id: number;
    page: string;
}

interface SupplierInvoice {
    id: number;
    jobId: number;
    invoiceNumber: string;
    xeroInvoiceId: string;
    xeroContactId: string;
    xeroContactName: string;
    supplier: string;
    invoiceDate: string;
    sentDate: string;
    paidDate: string;
    dueDate: string;
    dueDateActual: string;
    statusId: number;
    status: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    total: number;
    totalFormatted: string;
    invoiceDetails: SupplierInvoiceDetails[];
    isDirty: boolean;
}

interface SupplierInvoiceDetails {
    id: number;
    description: string;
    quantity: number;
    subTotal: number;
    gst: number;
    total: number;
}

interface XeroListItem {
    id: string;
    name: string;
    totalFormatted: string;
}

const JobSupplierInvoiceDetailData = (props: JobSupplierInvoiceDetailProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [permissions, setPermissions] = React.useState({ canEdit: false, canSend: false });
    const [gstData, setGstData] = React.useState({ gstPercent: 0.1, gstCalculation: 11 });
    const [invoice, setInvoice] = React.useState<SupplierInvoice>({
        id: 0,
        jobId: props.id,
        invoiceNumber: "",
        xeroInvoiceId: "",
        xeroContactId: "",
        xeroContactName: "",
        supplier: "",
        invoiceDate: "",
        sentDate: "",
        paidDate: "",
        dueDate: "",
        dueDateActual: "",
        statusId: 1,
        status: "Created",
        subTotal: 0,
        subTotalFormatted: "$0.00",
        gst: 0,
        gstFormatted: "$0.00",
        total: 0,
        totalFormatted: "$0.00",
        invoiceDetails: [],
        isDirty: false
    });
    const [xeroContacts, setXeroContacts] = React.useState<XeroListItem[]>([]);
    const [xeroNameSearch, setXeroNameSearch] = React.useState("");
    const [showAddXeroContactModal, setShowAddXeroContactModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [sendDisabled, setSendDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [viewInvoice, setViewInvoice] = React.useState({ showInvoice: false, uploadedInvoice: new File([""], "filename") });
    const [deleteData, setDeleteData] = React.useState({ showDeleteConfirmModal: false, deleteIndexId: 0 });
    const [modalDeleteSaveDisabled, setModalDeleteSaveDisabled] = React.useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var Id = props.id;
        axios.get('Jobs/GetSupplierInvoice?Id=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var inv = res.data.invoice;
            //add a blank row if can edit
            if (res.data.canEdit && inv.statusId <= 2) {
                var invDetails = inv.invoiceDetails;
                var blankItem = getBlankDetailRow();
                invDetails.push(blankItem);
            }

            setPermissions({ canEdit: res.data.canEdit, canSend: res.data.canSend });
            setXeroContacts(res.data.xeroContacts);
            setGstData({ gstPercent: res.data.gstPercent, gstCalculation: res.data.gstCalculation });
            setInvoice(inv);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getBlankDetailRow = () => {
        var blankItem: SupplierInvoiceDetails = {
            id: 0,
            description: "",
            quantity: 1,
            subTotal: 0,
            gst: 0,
            total: 0
        };
        return blankItem;
    }

    const searchXeroCustomers = (e: any) => {
        e.preventDefault();

        if (xeroNameSearch) {
            searchCustomers();
        } else {
            let error: any = {};
            error["xeroNameSearch"] = "Enter name to search";
            setErrors(error);
        }
    }

    const searchCustomers = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + xeroNameSearch, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var customers = res.data;
            var contacts = [...xeroContacts];
            for (var i = 0; i < customers.length; i++) {
                var customer = customers[i];
                contacts.push(customer);
            }
            setXeroContacts(contacts);

            if (customers.length === 0) {
                toast.info("There were no Xero customers that matched your search.  Note: Xero search is case sensitive!");
            } else {
                toast.info("Xero customer results added to Xero Customers list");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //add Xero Contact
    const addXeroCustomer = (e: any) => {
        e.preventDefault();
        setShowAddXeroContactModal(true);
    }

    const hideAddXeroModal = () => {
        setShowAddXeroContactModal(false);
    }

    const saveAddXeroModal = (id: string, name: string) => {
        var newContact: XeroListItem = {
            id: id,
            name: name,
            totalFormatted: "$0.00"
        };

        let contacts = [...xeroContacts];
        contacts.push(newContact);
        setXeroContacts(contacts);
        setShowAddXeroContactModal(false);
    }
    //End add Xero Contact

    const handleXeroNameChange = (e: any) => {
        setXeroNameSearch(e.target.value);

        setSaveDisabled(false);
        setSendDisabled(false);
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        if (e.target.name === "xeroContactId") {
            var contact = xeroContacts.find(xc => xc.id == e.target.value);
            if (contact != null) {
                var contactNmae = contact.name;
                //inv.xeroContactName = contact.name;
                setInvoice(prevState => ({ ...prevState, [e.target.name]: e.target.value, isDirty: true, xeroContactName: contactNmae }));
            } else {
                setInvoice(prevState => ({ ...prevState, [e.target.name]: e.target.value, isDirty: true }));
            }
        } else {
            setInvoice(prevState => ({ ...prevState, [e.target.name]: e.target.value, isDirty: true }));
        }

        setSaveDisabled(false);
        setSendDisabled(false);
    }

    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var inv = invoice;
        var details = inv.invoiceDetails;
        var detail = details[index];
        let exist: any = {};
        exist = detail;
        exist[e.target.name] = e.target.value;
        inv.isDirty = true;

        if (index === (inv.invoiceDetails.length - 1)) {
            //add new blank row
            var blankItem = getBlankDetailRow();
            details.push(blankItem);
        }
        setInvoice(inv);
        setSaveDisabled(false);
        setSendDisabled(false);
        setErrors({});
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var inv = invoice;
        var details = inv.invoiceDetails;
        var detail = details[index];
        let exist: any = {};
        exist = detail;
        var amount = e.target.value * 1;
        exist[e.target.name] = amount;

        //calculate line item totals
        if (e.target.name === "total") {
            var gst = (amount / gstData.gstCalculation);
            exist["gst"] = gst.toFixed(2);
            exist["subTotal"] = (amount - gst).toFixed(2);
        } else if (e.target.name === "subTotal") {
            var gst = (amount * gstData.gstPercent);
            exist["gst"] = gst.toFixed(2);
            exist["total"] = (amount + gst).toFixed(2);
        } else {
            //gst
            var subTotal = detail.subTotal * 1;
            exist["total"] = (amount + subTotal).toFixed(2);
        }
        inv.isDirty = true;

        if (index === (inv.invoiceDetails.length - 1)) {
            //add new blank row
            var blankItem = getBlankDetailRow();
            details.push(blankItem);
        }

        setInvoice(inv);
        setSaveDisabled(false);
        setSendDisabled(false);
        setErrors({});

        calculateTotals();
    }

    const calculateTotals = () => {
        var subTotal = 0;
        var gst = 0;
        var total = 0;

        var details = invoice.invoiceDetails;

        for (var i = 0; i < details.length; i++) {
            subTotal += parseFloat((details[i].subTotal * 1).toFixed(2));
            gst += parseFloat((details[i].gst * 1).toFixed(2));
            total += parseFloat((details[i].total * 1).toFixed(2));
        }

        let subTotalFormatted = subTotal.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
        let gstFormatted = gst.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
        let totalFormatted = total.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });

        setInvoice(prevState => ({ ...prevState, subTotal: subTotal, subTotalFormatted: subTotalFormatted, gst: gst, gstFormatted: gstFormatted, total: total, totalFormatted: totalFormatted }));
    }

    //SHOW INVOICE FILE
    const showInvoice = (e: any) => {
        e.preventDefault();
        showSupplierInvoice();
    }

    const showSupplierInvoice = async() => {
        const token = await authService.getAccessToken();

        axios.get('Suppliers/GetSupplierImportInvoice?InvoiceId=' + invoice.id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            setViewInvoice({ showInvoice: true, uploadedInvoice: res.data });
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideShowInvoice = () => {
        var uplInvoice: File = new File([""], "filename");
        setViewInvoice({ showInvoice: false, uploadedInvoice: uplInvoice });
    }
    //END SHOW INVOICE FILE

    //DELETE ITEM
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();
        setDeleteData({ deleteIndexId: index, showDeleteConfirmModal: true });
        setModalDeleteSaveDisabled(false);
    }

    const hideDeleteConfirmModal = () => {
        setDeleteData({ deleteIndexId: -1, showDeleteConfirmModal: false });
        setModalDeleteSaveDisabled(false);
    }

    //no save
    const noDeleteConfirmModal = () => {
        setDeleteData({ deleteIndexId: -1, showDeleteConfirmModal: false });
        setModalDeleteSaveDisabled(false);
    }

    const saveDeleteConfirmModal = () => {
        if (modalDeleteSaveDisabled) {
            return;
        }
        setModalDeleteSaveDisabled(true);

        var inv = invoice;
        var details = inv.invoiceDetails;
        var item = details[deleteData.deleteIndexId];

        if (item.id > 0) {
            deleteSupplierInvoiceItem();
        } else {
            //remove item from array
            details.splice(deleteData.deleteIndexId, 1);
            setInvoice(inv);
            setDeleteData({ deleteIndexId: -1, showDeleteConfirmModal: false });
            setModalDeleteSaveDisabled(false);
            toast.success("Item has been removed");

            calculateTotals();
        }
    }

    const deleteSupplierInvoiceItem = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var inv = invoice;
        var details = inv.invoiceDetails;
        var item = details[deleteData.deleteIndexId];

        var deleteItem = {
            jobId: props.JobId,
            id: item.id,
            subId: user.sub
        };

        //remove item from database
        axios.post('Jobs/DeleteSupplierInvoiceItem', deleteItem, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //remove item from array
                details.splice(deleteData.deleteIndexId, 1);
                setDeleteData({ deleteIndexId: -1, showDeleteConfirmModal: false });
                setModalDeleteSaveDisabled(false);
                
                toast.success("Item has been deleted");

                calculateTotals();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //END DELETE ITEM

    //BACK
    const back = () => {
        //if isDirty - ask if want to save changes
        if (invoice.isDirty) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);

            saveInvoice(true);
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            id: 0,
            page: "List"
        };

        props.changePage(pageChange);
    }

    //END BACK

    //CANCEL INVOICE
    const cancelInvoice = () => {
        setShowCancelConfirmModal(true);
    }

    const hideCancelConfirmModal = () => {
        setShowCancelConfirmModal(false);
    }

    //no save
    const noCancelConfirmModal = () => {
        setShowCancelConfirmModal(false);
    }

    const saveCancelConfirmModal = () => {
        //set invoice to cancelled
        var inv = invoice;

        if (inv.id === 0) {
            //invoice isn't saved
            inv.status = "Cancelled";
            inv.statusId = 5;
            setInvoice(inv);
            setShowCancelConfirmModal(false);
        } else {
            deleteSupplierInvoice();
        }
    }

    const deleteSupplierInvoice = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var inv = invoice;

        var invoiceToCancel = {
            id: inv.id,
            jobId: inv.jobId,
            xeroInvoiceId: inv.xeroInvoiceId,
            subId: user.sub
        };

        //cancel invoice
        axios.post('Xero/DeleteSupplierInvoice', invoiceToCancel, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                setShowCancelConfirmModal(false);
            } else {
                toast.success("Invoice Cancelled");
                inv.status = "Cancelled";
                inv.statusId = 5;
                setInvoice(inv);
                setShowCancelConfirmModal(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //END CANCEL INVOICE

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = invoice.invoiceDetails;
        var rowNumber = 0;

        //must have a customer and type selected
        if (!invoice.xeroContactId) {
            formIsValid = false;
            errorList["xeroContactId"] = "Xero Customer";
        }

        if (!invoice.invoiceNumber) {
            formIsValid = false;
            errorList["invoiceNumber"] = "Invoice Number";
        }

        if (!invoice.invoiceDate) {
            formIsValid = false;
            errorList["invoiceDate"] = "Invoice Date";
        } else if (!DateHelpers.isValidDate(invoice.invoiceDate)) {
            formIsValid = false;
            errorList["invoiceDate"] = "Valid Invoice Date";
        }

        if (!invoice.dueDate) {
            formIsValid = false;
            errorList["dueDate"] = "Due Date";
        } else if (!DateHelpers.isValidDate(invoice.dueDate)) {
            formIsValid = false;
            errorList["dueDate"] = "Valid Due Date";
        }

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length < 2) {
            errorString = "Please enter at least one Item!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (items[i].subTotal === 0) {
                    newErrorString += " Sub Total > 0, ";
                    error = true;
                }

                if (items[i].total === 0) {
                    newErrorString += " Total > 0, ";
                    error = true;
                }

                if (newErrorString !== "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["details"] = errorString;
        }

        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        saveInvoice(false);
    }

    const saveInvoice = (goBack: boolean) => {
        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);

        if (validate()) {
            saveSupplierInvoice(goBack);
        } else {
            toast.error("Please fix errors on screen before saving");
        }
    }

    const saveSupplierInvoice = async (goBack: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var inv = invoice;
        inv.invoiceDetails.pop();  //remove blank row   

        var saveInvoice = {
            invoice: inv,
            subId: user.sub
        };

        axios.post('Jobs/SaveJobSupplierInvoice', saveInvoice, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                var blankItem = getBlankDetailRow();
                inv.invoiceDetails.push(blankItem);
                setInvoice(inv);
            } else {
                toast.success("Invoice Saved");
                if (goBack) {
                    var pageChange: PageChangeData = {
                        id: 0,
                        page: "List"
                    };
                    props.changePage(pageChange);
                } else {
                    var invoiceResult = res.data;
                    var blankItem = getBlankDetailRow();
                    invoiceResult.invoiceDetails.push(blankItem);
                    setInvoice(invoiceResult);
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const sendInvoice = (e: any) => {
        e.preventDefault();

        if (sendDisabled) {
            return;
        }
        setSendDisabled(true);

        if (validate()) {
            SendInvoiceToXero();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const SendInvoiceToXero = async() => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        var inv = invoice;
        inv.invoiceDetails.pop();  //remove blank row   

        //check if have authenticated with xero
        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "costings",
            id: inv.jobId,
            subId: user.sub
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //add invoice id to list so we can use the same method as the Supplier Invoice Import screen
                var invIds: number[] = [];
                invIds.push(inv.id);

                var invoicesToSend = {
                    subId: user.sub,
                    InvoiceIds: invIds
                };

                //send invoice to Xero
                axios.post('Xero/SendSupplierInvoicesToXero', invoicesToSend, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var blankItem = getBlankDetailRow();
                        inv.invoiceDetails.push(blankItem);
                        setInvoice(inv);

                        var errorList = res.data.messages as any[];
                        errorList.map(function (error: any) {
                            toast.error(error.content);
                        });

                    } else {
                        setInvoice(prevState => ({ ...prevState, status: "Sent", statusId: 3, isDirty: false }));
                        toast.success("Invoice sent to Xero");
                    }
                })
                .catch(error => {
                    var blankItem = getBlankDetailRow();
                    inv.invoiceDetails.push(blankItem);
                    setInvoice(inv);
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }


    let lastRow = (invoice.statusId > 2 || !permissions.canEdit) ? invoice.invoiceDetails.length : invoice.invoiceDetails.length - 1;

    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmCancel = <ConfirmModal heading="Cancel Invoice" text="Are you sure you want to cancel this invoice?" hideConfirmModal={hideCancelConfirmModal} showConfirmModal={showCancelConfirmModal} noConfirmModal={noCancelConfirmModal} yesConfirmModal={saveCancelConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmDelete = <ConfirmModal heading="Delete Item" text="Are you sure you want to delete this item?" hideConfirmModal={hideDeleteConfirmModal} showConfirmModal={deleteData.showDeleteConfirmModal} noConfirmModal={noDeleteConfirmModal} yesConfirmModal={saveDeleteConfirmModal} saveDisabled={modalDeleteSaveDisabled} />
    let addXeroContact = <AddXeroContactPopup jobId={props.JobId} showUseCustomer={false} show={showAddXeroContactModal} hide={hideAddXeroModal} save={saveAddXeroModal} />
    let invoiceFile = <SupplierInvoiceFilePopup invoiceId={invoice.id} heading="View Invoice" uploadedInvoice={viewInvoice.uploadedInvoice} showModal={viewInvoice.showInvoice} hideModal={hideShowInvoice} />

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmBack}
                {confirmCancel}
                {confirmDelete}
                {addXeroContact}
                {invoiceFile}
            </div>
            <div className="backButton--right">
                <button type="button" className="defaultbutton" onClick={back}>Back</button>
            </div>
            <div className="input-group-parent">
                <div>
                    <label className="input-group" htmlFor="xeroNameSearch">
                        <span className="label">Xero Name Search</span>
                        <input className='input marginRight10' type='text' id="xeroNameSearch" name="xeroNameSearch" value={xeroNameSearch} onChange={(e) => handleXeroNameChange(e)} disabled={invoice.statusId > 2 || !permissions.canEdit}></input>
                        <button className="defaultbutton defaultbutton__xsmall supplier-invoice__search--position" type="button" onClick={searchXeroCustomers} disabled={invoice.statusId > 2 || !permissions.canEdit}>Search</button>
                    </label>
                    <span className={errors["xeroNameSearch"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroNameSearch"]}</span>
                </div>

                <label className="input-group" htmlFor="xeroContactId">
                    <span className="label">Xero Contact
                        <a className={(invoice.statusId <= 2) ? "marginLeft10" : "hidden"} href="#" onClick={(e) => addXeroCustomer(e)}>
                            Add New
                        </a>
                    </span>
                    <select className="select" id="xeroContactId" name="xeroContactId" value={invoice.xeroContactId} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 2 || !permissions.canEdit}>
                        <option defaultValue="" value=""></option>
                        {xeroContacts.map(xeroContacts =>
                            <option key={xeroContacts.id} value={xeroContacts.id}>{xeroContacts.name + " " + xeroContacts.totalFormatted}</option>
                        )};
                    </select>
                </label>
                <span className={errors["xeroContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroContactId"]}</span>

                <label className="input-group" htmlFor="invoiceDate">
                    <span className="label">Invoice Date</span>
                    <input type='date' id="invoiceDate" name="invoiceDate" className='input' value={invoice.invoiceDate} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 2 || !permissions.canEdit}></input>
                </label>
                <span className={errors["invoiceDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["invoiceDate"]}</span>

                <label className="input-group" htmlFor="dueDate">
                    <span className="label">Due Date</span>
                    <input type='date' id="dueDate" name="dueDate" className='input' value={invoice.dueDate} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 2 || !permissions.canEdit}></input>
                </label>
                <span className={errors["dueDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["dueDate"]}</span>
            </div>
            <div className="input-group-parent">
                <label className="input-group" htmlFor="invoiceNumber">
                    <span className="label">Invoice Number</span>
                    <input type='text' id="invoiceNumber" name="invoiceNumber" className='input' value={invoice.invoiceNumber} onChange={(e) => handleChange(e)} disabled={invoice.statusId > 2 || !permissions.canEdit}></input>
                    <span className="marginLeft10">
                        <a href="#" onClick={(e) => showInvoice(e)}>
                            <span className="fas fa-file-image supplier-invoice--icon alignIconCenter file__photoColour"></span>
                        </a>
                    </span>
                </label>
                <span className={errors["invoiceNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["invoiceNumber"]}</span>

                <label className="input-group" htmlFor="sentDate">
                    <span className="label">Sent Date</span>
                    <input type='text' id="sentDate" name="sentDate" className='input' value={invoice.sentDate} disabled></input>
                </label>

                <label className="input-group" htmlFor="paidDate">
                    <span className="label">Paid Date</span>
                    <input type='text' id="paidDate" name="paidDate" className='input' value={invoice.paidDate} disabled></input>
                </label>

                <label className="input-group" htmlFor="status">
                    <span className="label">Status</span>
                    <input type='text' id="status" name="status" className='input' value={invoice.status} disabled></input>
                </label>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__large tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th>Description</th>
                            <th className="textalignright tableHeader--maxwidth">Sub Total</th>
                            <th className="textalignright tableHeader--maxwidth">GST</th>
                            <th className="textalignright tableHeader--maxwidth">Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.invoiceDetails.map((detail, index) =>
                            <tr key={index}>
                                <td className="hidden">{detail.id}</td>
                                <td className="table__text--align">
                                    <input type='text' className="form-control table__tdmin-xlg" maxLength={3000} name="description" value={detail.description} onChange={(e) => handleCellChange(index, e)} disabled={invoice.statusId > 2 || !permissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <input type='number' className="form-control table__tdmin--sm textalignright" name="subTotal" value={detail.subTotal} onChange={(e) => handleCellAmountChange(index, e)} disabled={invoice.statusId > 2 || !permissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <input type='number' className="form-control table__tdmin--sm textalignright" name="gst" value={detail.gst} onChange={(e) => handleCellAmountChange(index, e)} disabled={invoice.statusId > 2 || !permissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <input type='number' className="form-control table__tdmin--sm textalignright" name="total" value={detail.total} onChange={(e) => handleCellAmountChange(index, e)} disabled={invoice.statusId > 2 || !permissions.canEdit} />
                                </td>
                                <td className="table__text--align">
                                    <div className={invoice.statusId > 2 || !permissions.canEdit || index === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(index, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["details"] ?
                    (errors["details"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>

            <div className="input-group-parent">
                <div>
                    <button className="defaultbutton defaultbutton__medium" type="submit" disabled={invoice.statusId > 2 || !permissions.canEdit || saveDisabled}>Save</button>
                    <button className="defaultbutton defaultbutton__medium threebuttons__btn2--position" type="button" onClick={sendInvoice} disabled={invoice.statusId > 2 || !permissions.canSend || sendDisabled}>Send To Xero</button>
                    <button className="defaultbutton defaultbutton__medium threebuttons__btn3--position" type="button" onClick={cancelInvoice} disabled={invoice.statusId > 3 || !permissions.canEdit}>Cancel</button>
                </div>
            </div>
            <div className="input-group-parent">
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="subTotalFormatted">
                    <span className="label labeltextalignright">Sub Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalFormatted" name="subTotalFormatted" value={invoice.subTotalFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="gstFormatted">
                    <span className="label labeltextalignright">GST</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={invoice.gstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label labeltextalignright">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={invoice.totalFormatted} disabled></input>
                </label>
            </div>

        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;

}

export default JobSupplierInvoiceDetailData;
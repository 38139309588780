import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import JobTabsMobile from './JobTabsMobile';
import SignaturePad from 'react-signature-canvas';


const SignatureMobileData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState({ canViewEstimate: false, canViewCostings: false, showAssessment: false });
    const [reportId, setReportId] = React.useState(1);  //1 = Authority, 2 = Completion
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const sigPad = React.useRef() as React.MutableRefObject<SignaturePad>;
    const [signatureName, setSignatureName] = React.useState("");
    const [signatureTypeId, setSignatureTypeId] = React.useState(1);    //1 = No Signature, 2 = Sign Authority, 3 = Sign Completion

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetSignatureDataForMobile?JobId=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                setPermissions({ canViewEstimate: res.data.canViewEstimate, canViewCostings: res.data.canViewCostings, showAssessment: res.data.showAssessment });
                setReportId(res.data.reportId);
                setSignatureTypeId(res.data.signatureTypeId);
                setLoading(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const clearSig = (e: any) => {
        e.preventDefault();

        clearSignature();
    }

    const clearSignature = () => {
        sigPad.current?.clear();
    }

    const handleNameChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }
        setSignatureName(e.target.value);
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (!signatureName) {
            formIsValid = false;
            error["signatureName"] = "Name is required";
        }

        if (sigPad.current?.isEmpty()) {
            formIsValid = false;
            error["signature"] = "Signature is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            SaveSignature();
        } else {
            toast.error("Fix the validation errors before Saving");
        }

    }

    const SaveSignature = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var fileToSave = sigPad.current?.getTrimmedCanvas().toDataURL('image/png') ?? "";
        var typeId = signatureTypeId == 2 ? "10" : "8";

        //save signature
        const data = new FormData()
        data.append('jobId', jobData.jobId.toString());
        data.append('typeId', typeId);
        data.append('signature', fileToSave);
        data.append('signatureName', signatureName);
        data.append('subId', user.sub);

        //save photos
        axios.post('Jobs/SaveJobSignature', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                clearSignature();
                toast.success("Signature has been saved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    const printSignedDocument = () => {
        var docType = reportId === 1 ? "/authority" : "/completion";
        var url = "/job/" + jobData.jobId + "/report/0" + docType;
        window.open(url, '_blank');
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    var docType = signatureTypeId === 2 ? "Authority" : "Completion";
    var reportType = reportId === 1 ? "Authority" : "Completion";
    var jobTabsMobile = <JobTabsMobile id={jobData.jobId} tab="sign" canViewEstimate={permissions.canViewEstimate} canViewCostings={permissions.canViewCostings} showAssessment={permissions.showAssessment} tabChanged={tabChanged} />

    const renderDetails = (
        <div>
            <div className="mobileFont marginBottom70">
                <div className={signatureTypeId === 1 ? "hidden" : "mobileFont marginTop10"}>
                    <h4 className="mobile-margin">Sign {docType}</h4>
                    <div className={jobData.isMakeSafe ? "makeSafeJob" : jobData.isReportOnly ? "reportOnlyJob" : ""}>{jobData.siteAddress}</div>

                    <div className="makeitflexcenter">
                        <SignaturePad canvasProps={{ width: 330, height: 200, className: 'signature__border' }} ref={sigPad} />
                        <a className="mobile-icon" href="#" onClick={(e) => clearSig(e)}>
                            <span className="fas fa-redo mobileMenuSize alignIconCenter"></span>
                            <span>Clear</span>
                        </a>
                    </div>
                    <span className={errors["signature"] ? "label errors errors__leftmargin" : "hidden"}>{errors["signature"]}</span>

                    <label className="input-group" htmlFor="signatureName">
                        <span className="label">Name</span>
                        <input className='input mobile-input' type='text' maxLength={200} id="signatureName" name="signatureName" value={signatureName} onChange={(e) => handleNameChange(e)} ></input>
                    </label>
                    <span className={errors["signatureName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["signatureName"]}</span>

                    <button className="mobile-button mobile-button_green" type="button" onClick={save} disabled={signatureTypeId === 1}>Save</button>
                </div>
                <div className={signatureTypeId === 1 ? "mobileFont marginTop10" : "hidden"}>
                    <h4>Sign Document</h4>
                    <div className={jobData.isMakeSafe ? "makeSafeJob" : jobData.isReportOnly ? "reportOnlyJob" : ""}>{jobData.siteAddress}</div>

                    There are currently no documents awaiting a signature.
                </div>
                <button className="mobile-button mobile-button_grey" type="button" onClick={printSignedDocument}>View {reportType}</button>
            </div>
            <div>
                {jobTabsMobile}
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default SignatureMobileData;
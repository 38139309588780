import * as React from 'react';
import { toast } from 'react-toastify';

interface UserProps {
    id: number;
    defaultRoleId: number;
    userRoles: GenericListItem[];
    roles: GenericListItem[];           //all roles
    userManagers: GenericListItem[];
    managers: GenericListItem[];        //all active users
    updateUserRoles(userRoles: GenericListItem[]): void;
    updateUserManagers(userManagers: GenericListItem[]): void;
    updateDefaultRole(defaultRoleId: number): void;
    save(): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

const UserRoleData = (props: UserProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const handleDefaultRoleChange = (e: any) => {
        e.preventDefault();

        var newDefaultRole = e.target.value * 1;
        setSaveDisabled(false);

        props.updateDefaultRole(newDefaultRole);
    }

    const handleRoleChange = (e: any) =>   
    {
        e.preventDefault();
        if (e.target.value) {
            delete errors["roleId"];
            setErrors(errors);
        }

        var userRoles = props.userRoles;
        var newRole: GenericListItem = {
            id: parseInt(e.target.value),
            name: e.target.selectedOptions[0].label
        };
        userRoles.push(newRole);

        setSaveDisabled(false);
        props.updateUserRoles(userRoles);
    }

    //remove role from selected
    const removeItem = (e: any, id: number) => {
        e.preventDefault();

        var userRoles = props.userRoles;
        userRoles.splice(userRoles.findIndex(function (i) {
            return i.id == id;
        }), 1);

        setSaveDisabled(false);
        props.updateUserRoles(userRoles);
    }

    const handleManagerChange = (e: any) => {
        e.preventDefault();

        setSaveDisabled(false);

        var userManagers = props.userManagers;
        var newManager: GenericListItem = {
            id: parseInt(e.target.value),
            name: e.target.selectedOptions[0].label
        };
        userManagers.push(newManager);
        
        props.updateUserManagers(userManagers);
    }

    //remove manager from selected
    const removeManagerItem = (e: any, id: number) => {
        e.preventDefault();
        setSaveDisabled(false);

        var userManagers = props.userManagers;
        userManagers.splice(userManagers.findIndex(function (i) {
            return i.id == id;
        }), 1);

        props.updateUserManagers(userManagers);
    }

    const validate = () => {
        //let fields = this.state.userRoles;
        let errors: any = {};
        let formIsValid = true;

        if (props.userRoles.length < 1) {
            formIsValid = false;
            errors["roleId"] = "At least one role is required";
        }

        setErrors(errors);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            props.save();
        } else {
            toast.error("Please fix the validation issues before saving");
        }

    }

    //roles
    var availRoles = props.roles.filter(function (data) {
        var found = props.userRoles.find(r => r.id == data.id);
        if (!found) {
            return data;
        }
    });
    //managers
    var availManagers = props.managers.filter(function (data) {
        var found = props.userManagers.find(m => m.id == data.id);
        if (!found) {
            return data;
        }
    });

    return (
        <form name="user" onSubmit={save}>
            <div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="roleId">
                        <span className="label">Role</span>
                        <select className="select" id="roleId" name="roleId" onChange={(e) => handleRoleChange(e)} >
                            <option defaultValue="-1"></option>
                            {availRoles.map(role =>
                                <option key={role.id} value={role.id}>{role.name}</option>
                            )}
                        </select>
                    </label>
                    <span className={errors["roleId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["roleId"]}</span>
                            
                    <label className="input-group" htmlFor="role">
                        <span className="label"></span>
                        <div className="">
                            {props.userRoles.map(role =>
                                <div className="list-close--nowrap" key={role.id} >
                                    <span className="input">{role.name}</span>
                                    <a className="makeitred" href="#" onClick={(e) => removeItem(e, role.id)}>
                                        <span className="fas fa-times-circle alignDeleteIcon"></span>
                                    </a>
                                    <br />
                                </div>
                            )}
                        </div>
                    </label>

                    <div>Default Role (used for displaying jobs on the Jobs screen) Notes: </div>
                    <div className="marginLeft10">* Estimators view Registered, Tendering, Quoted and Awaiting Customer jobs</div>
                    <div className="marginLeft10">* Supervisors view In Progress, On Hold and Completed jobs</div>
                    <label className="input-group" htmlFor="defaultRoleId">
                        <span className="label">Default Role</span>
                        <select className="select" id="defaultRoleId" name="defaultRoleId" value={props.defaultRoleId} onChange={(e) => handleDefaultRoleChange(e)} >
                            <option defaultValue="-1" value={-1}></option>
                            <option key="1" value={1}>Estimator</option>
                            <option key="2" value={2}>Supervisor</option>
                        </select>
                    </label>
                </div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="managerId">
                        <span className="label">Manager</span>
                        <select className="select" id="managerId" name="managerId" onChange={(e) => handleManagerChange(e)} >
                            <option defaultValue="-1"></option>
                            {availManagers.map(mgr =>
                                <option key={mgr.id} value={mgr.id}>{mgr.name}</option>
                            )}
                        </select>
                    </label>

                    <label className="input-group" htmlFor="manager">
                        <span className="label"></span>
                        <div className="">
                            {props.userManagers.map(manager =>
                                <div className="list-close--nowrap" key={manager.id} >
                                    <span className="input">{manager.name}</span>
                                    <a className="makeitred" href="#" onClick={(e) => removeManagerItem(e, manager.id)}>
                                        <span className="fas fa-times-circle alignDeleteIcon"></span>
                                    </a>
                                    <br />
                                </div>
                            )}
                        </div>
                    </label>
                </div>
                <button className="defaultbutton-prev defaultbutton-label" type="submit" disabled={ saveDisabled }>Save</button>
            </div>
        </form>
    );

}

export default UserRoleData;
import * as React from 'react';
import { toast } from 'react-toastify';

interface JobAssessmentMobileSuuProps {
    canEdit: boolean;
    buildingAssessment: BuildingAssessment;
    occupancyTypes: GenericListItem[];
    buildingTypes: GenericListItem[];
    constructionTypes: GenericListItem[];
    roofTypes: GenericListItem[];
    buildingUse: GenericListItem[];
    yesNo: GenericListItem[];
    yesNoUnsure: GenericListItem[];
    update(buildingAssessment: BuildingAssessment): void;
    updateSave(): void; //save assessment on field exit
    save(): void;
}

interface BuildingAssessment {
    id: number;
    jobId: number;
    typeId: number;
    policyNumber: string;
    occupantName: string;
    occupantContactNumber: string;
    incidentConfirmed: number;
    clientInformedNextSteps: number;
    occupancyType: number;
    temporaryAccommodation: number;
    furnitureRemovalStorage: number;
    makeSafeRequired: number;
    emergencyServicesAttend: number;
    makeSafeDescription: string;
    workCompletedOthers: number;
    workCompletedOthersDescription: string;
    buildingType: number;
    outBuildings: string;
    buildingCondition: string;
    constructionType: number;
    roofType: number;
    buildingUse: number;
    buildingAge: string;
    propertyUninhabitable: number;
    uninhabitableDuringRepairs: number;
    uninhabitableDuringRepairsTime: string;
    damageDetails: string;
    insuredVersionOfEvents: string;
    causeOfDamage: string;
    causeOfDamageTime: string;
    roofReportRequested: number;
    roofGeneralCondition: string;
    roofStormDamage: string;
    guttersClean: string;
    ceilingInspection: number;
    guttersSufficient: string;
    additionalRoofFixtures: number;
    rainWaterBreach: string;
    maintenanceIssues: number;
    maintenanceIssuesDescription: string;
    maintenanceIssuesInsuredAware: number;
    maintenanceIssuesInsuredAwareTime: string;
    maintenanceIssuesAddressed: number;
    damageConsistentWithEvent: number;
    insuranceRelated: number;
    recommendAcceptance: number;
    additionalComments: string;
    numberOfStories: number;
    roofPitch: string;
    structureArea: string;
    observations: string;
    resolution: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

const JobAssessmentMobileSuuData = (props: JobAssessmentMobileSuuProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    window.scrollTo(0, 0);

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        var error = errors;
        if (e.target.value) {
            delete error[e.target.name];
            setErrors(error);
        }

        let ba = props.buildingAssessment;
        let change: any = ba;
        change[e.target.name] = e.target.value;

        props.update(change);
    }

    const handleOnBlur = (e: any) => {
        props.updateSave();
    }

    const validate = () => {
        let fields = props.buildingAssessment;
        let error: any = {};
        let formIsValid = true;

        if (!fields.policyNumber) {
            formIsValid = false;
            error["policyNumber"] = "Policy Number is required";
        }

        if (fields.incidentConfirmed < 0) {
            formIsValid = false;
            error["incidentConfirmed"] = "Incident Confirmed is required";
        }

        if (fields.clientInformedNextSteps < 0) {
            formIsValid = false;
            error["clientInformedNextSteps"] = "Client Informed of Next Steps is required";
        }

        if (fields.occupancyType < 0) {
            formIsValid = false;
            error["occupancyType"] = "Occupancy Type is required";
        }

        if (fields.temporaryAccommodation < 0) {
            formIsValid = false;
            error["temporaryAccommodation"] = "Temporary Accommodation is required";
        }

        if (fields.furnitureRemovalStorage < 0) {
            formIsValid = false;
            error["furnitureRemovalStorage"] = "Furniture Removal / Storage is required";
        }

        if (fields.makeSafeRequired < 0) {
            formIsValid = false;
            error["makeSafeRequired"] = "Make Safe required is required";
        }

        if (fields.emergencyServicesAttend < 0) {
            formIsValid = false;
            error["emergencyServicesAttend"] = "Did Emergency Service Attend is required";
        }

        if (fields.makeSafeRequired == 1 || fields.emergencyServicesAttend == 1) {
            if (!fields.makeSafeDescription) {
                formIsValid = false;
                error["makeSafeDescription"] = "Make Safe Brief Description is required";
            }
        } 

        if (fields.workCompletedOthers < 0) {
            formIsValid = false;
            error["workCompletedOthers"] = "Work done by others is required";
        }

        if (fields.workCompletedOthers == 1) {
            if (!fields.workCompletedOthersDescription) {
                formIsValid = false;
                error["workCompletedOthersDescription"] = "Work Completed Description is required";
            }
        }

        if (fields.buildingType < 0) {
            formIsValid = false;
            error["buildingType"] = "Building Type is required";
        }

        if (!fields.buildingCondition) {
            formIsValid = false;
            error["buildingCondition"] = "Building Condition is required";
        }

        if (fields.constructionType < 0) {
            formIsValid = false;
            error["constructionType"] = "Construction Type is required";
        }

        if (fields.roofType < 0) {
            formIsValid = false;
            error["roofType"] = "Roof Type is required";
        }
        
        if (fields.buildingUse < 0) {
            formIsValid = false;
            error["buildingUse"] = "Building Use is required";
        }

        if (!fields.buildingAge) {
            formIsValid = false;
            error["buildingAge"] = "Building Age is required";
        }

        if (fields.propertyUninhabitable < 0) {
            formIsValid = false;
            error["propertyUninhabitable"] = "Property Uninhabitable is required";
        }

        if (fields.uninhabitableDuringRepairs < 0) {
            formIsValid = false;
            error["uninhabitableDuringRepairs"] = "Property Uninhabitable During Repairs is required";
        }

        if (fields.uninhabitableDuringRepairs == 1) {
            if (!fields.uninhabitableDuringRepairsTime) {
                formIsValid = false;
                error["uninhabitableDuringRepairsTime"] = "Property Uninhabitable Duration is required";
            }
        }

        if (!fields.damageDetails) {
            formIsValid = false;
            error["damageDetails"] = "Full details of damage is required";
        }

        if (!fields.insuredVersionOfEvents) {
            formIsValid = false;
            error["insuredVersionOfEvents"] = "Insured Version of Events is required";
        }

        if (!fields.causeOfDamage) {
            formIsValid = false;
            error["causeOfDamage"] = "Cause of Damage is required";
        }

        if (!fields.causeOfDamageTime) {
            formIsValid = false;
            error["causeOfDamageTime"] = "Damage length of time is required";
        }

        if (fields.roofReportRequested < 0) {
            formIsValid = false;
            error["roofReportRequested"] = "Roof Report Requested is required";
        }

        if (fields.roofReportRequested == 1) {
            if (!fields.roofGeneralCondition) {
                formIsValid = false;
                error["roofGeneralCondition"] = "Roof General Condition is required";
            }

            if (!fields.roofStormDamage) {
                formIsValid = false;
                error["roofStormDamage"] = "Evidence of Storm Damage is required";
            }

            if (!fields.guttersClean) {
                formIsValid = false;
                error["guttersClean"] = "Valleys / Gutters Clean is required";
            }

            if (fields.ceilingInspection < 0) {
                formIsValid = false;
                error["ceilingInspection"] = "Ceiling Inspection Conducted is required";
            }

            if (!fields.guttersSufficient) {
                formIsValid = false;
                error["guttersSufficient"] = "Gutters/Downpipes Sufficient is required";
            }

            if (fields.additionalRoofFixtures < 0) {
                formIsValid = false;
                error["additionalRoofFixtures"] = "Additional Fixtures Installed is required";
            }

            if (!fields.rainWaterBreach) {
                formIsValid = false;
                error["rainWaterBreach"] = "Location of Rain Water breach is required";
            }
        }

        if (fields.maintenanceIssues < 0) {
            formIsValid = false;
            error["maintenanceIssues"] = "Any Maintenance Issues is required";
        }

        if (fields.maintenanceIssues == 1) {
            if (!fields.maintenanceIssuesDescription) {
                formIsValid = false;
                error["maintenanceIssuesDescription"] = "Description of Maintenance Issues is required";
            }

            if (fields.maintenanceIssuesInsuredAware < 0) {
                formIsValid = false;
                error["maintenanceIssuesInsuredAware"] = "Insured aware of Maintenance Issues is required";
            }

            if (fields.maintenanceIssuesInsuredAware == 1) {
                if (!fields.maintenanceIssuesInsuredAwareTime) {
                    formIsValid = false;
                    error["maintenanceIssuesInsuredAwareTime"] = "Insured aware of issues duration is required";
                }
            }

            if (fields.maintenanceIssuesAddressed < 0) {
                formIsValid = false;
                error["maintenanceIssuesAddressed"] = "Maintenance Issues addressed is required";
            }
        }

        if (fields.damageConsistentWithEvent < 0) {
            formIsValid = false;
            error["damageConsistentWithEvent"] = "Damage Consistant with event is required";
        }

        if (fields.insuranceRelated < 0) {
            formIsValid = false;
            error["insuranceRelated"] = "Insurance Related is required";
        }

        if (fields.recommendAcceptance < 0) {
            formIsValid = false;
            error["recommendAcceptance"] = "Recommend Acceptance is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const next = (e: any) => {
        e.preventDefault();

        //saves assessment details and goes to upload photos
        if (validate()) {
            props.save();
        } else {
            toast.error("Check the assessment and fix all errors before going Next")
        }
    }

    return (
        <div className="mobileFont">
            <label className="input-group" htmlFor="policyNumber">
                <span className="label-mobile mobile-input">Policy Number</span>
                <input className='input' type='text' maxLength={50} id="policyNumber" name="policyNumber" value={props.buildingAssessment.policyNumber} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>
            <span className={errors["policyNumber"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["policyNumber"]}</span>

            <label className="input-group" htmlFor="occupantName">
                <span className="label-mobile mobile-input">Occupant Name</span>
                <input className='input' type='text' maxLength={200} id="occupantName" name="occupantName" value={props.buildingAssessment.occupantName} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>

            <label className="input-group" htmlFor="occupantContactNumber">
                <span className="label-mobile mobile-input">Contact Number</span>
                <input className='input' type='text' maxLength={100} id="occupantContactNumber" name="occupantContactNumber" value={props.buildingAssessment.occupantContactNumber} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>

            <label className="input-group" htmlFor="incidentConfirmed">
                <span className="label-mobile mobile-input">Incident Confirmed </span>
                <select className="select" id="incidentConfirmed" name="incidentConfirmed" value={props.buildingAssessment.incidentConfirmed} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["incidentConfirmed"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["incidentConfirmed"]}</span>

            <label className="input-group" htmlFor="clientInformedNextSteps">
                <span className="label-mobile mobile-input">Client Informed of Next Steps </span>
                <select className="select" id="clientInformedNextSteps" name="clientInformedNextSteps" value={props.buildingAssessment.clientInformedNextSteps} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["clientInformedNextSteps"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["clientInformedNextSteps"]}</span>

            <label className="input-group" htmlFor="occupancyType">
                <span className="label-mobile mobile-input">Occupancy Type </span>
                <select className="select" id="occupancyType" name="occupancyType" value={props.buildingAssessment.occupancyType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.occupancyTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["occupancyType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["occupancyType"]}</span>

            <label className="input-group" htmlFor="temporaryAccommodation">
                <span className="label-mobile mobile-input">Temporary Accommodation</span>
                <select className="select" id="temporaryAccommodation" name="temporaryAccommodation" value={props.buildingAssessment.temporaryAccommodation} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["temporaryAccommodation"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["temporaryAccommodation"]}</span>

            <label className="input-group" htmlFor="furnitureRemovalStorage">
                <span className="label-mobile mobile-input">Furniture Removal / Storage </span>
                <select className="select" id="furnitureRemovalStorage" name="furnitureRemovalStorage" value={props.buildingAssessment.furnitureRemovalStorage} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["furnitureRemovalStorage"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["furnitureRemovalStorage"]}</span>

            <label className="input-group" htmlFor="makeSafeRequired">
                <span className="label-mobile mobile-input">Make Safe Required </span>
                <select className="select" id="makeSafeRequired" name="makeSafeRequired" value={props.buildingAssessment.makeSafeRequired} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["makeSafeRequired"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["makeSafeRequired"]}</span>

            <label className="input-group" htmlFor="emergencyServicesAttend">
                <span className="label-mobile mobile-input">Did Emergency Services Attend </span>
                <select className="select" id="emergencyServicesAttend" name="emergencyServicesAttend" value={props.buildingAssessment.emergencyServicesAttend} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["emergencyServicesAttend"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["emergencyServicesAttend"]}</span>

            <div className={props.buildingAssessment.makeSafeRequired == 1 || props.buildingAssessment.emergencyServicesAttend == 1 ? "" : "hidden"}>
                <label className="input-group" htmlFor="makeSafeDescription">
                    <span className="label-mobile mobile-input">Please provide a brief description</span>
                    <input className='input' type='text' maxLength={500} id="makeSafeDescription" name="makeSafeDescription" value={props.buildingAssessment.makeSafeDescription} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
                </label>
                <span className={errors["makeSafeDescription"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["makeSafeDescription"]}</span>
            </div>

            <label className="input-group" htmlFor="workCompletedOthers">
                <span className="label-mobile mobile-input">Any work done by others </span>
                <select className="select" id="workCompletedOthers" name="workCompletedOthers" value={props.buildingAssessment.workCompletedOthers} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["workCompletedOthers"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["workCompletedOthers"]}</span>

            <div className={props.buildingAssessment.workCompletedOthers == 1 ? "" : "hidden"}>
                <label className="input-group" htmlFor="workCompletedOthersDescription">
                    <span className="label-mobile mobile-input">Enter a brief description</span>
                    <input className='input' type='text' maxLength={500} id="workCompletedOthersDescription" name="workCompletedOthersDescription" value={props.buildingAssessment.workCompletedOthersDescription} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
                </label>
                <span className={errors["workCompletedOthersDescription"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["workCompletedOthersDescription"]}</span>
            </div>

            <label className="input-group" htmlFor="buildingType">
                <span className="label-mobile mobile-input">Building Type </span>
                <select className="select" id="buildingType" name="buildingType" value={props.buildingAssessment.buildingType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.buildingTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["buildingType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["buildingType"]}</span>

            <label className="input-group" htmlFor="outBuildings">
                <span className="label-mobile mobile-input">Any Outbuildings</span>
                <input className='input' type='text' maxLength={500} id="outBuildings" name="outBuildings" value={props.buildingAssessment.outBuildings} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>

            <label className="input-group" htmlFor="buildingCondition">
                <span className="label-mobile mobile-input">Overall Building Condition</span>
                <input className='input' type='text' maxLength={500} id="buildingCondition" name="buildingCondition" value={props.buildingAssessment.buildingCondition} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>
            <span className={errors["buildingCondition"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["buildingCondition"]}</span>

            <label className="input-group" htmlFor="constructionType">
                <span className="label-mobile mobile-input">Construction Type</span>
                <select className="select" id="constructionType" name="constructionType" value={props.buildingAssessment.constructionType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.constructionTypes.map(constructionType =>
                        <option key={constructionType.id} value={constructionType.id}>{constructionType.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["constructionType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["constructionType"]}</span>

            <label className="input-group" htmlFor="roofType">
                <span className="label-mobile mobile-input">Roof Type </span>
                <select className="select" id="roofType" name="roofType" value={props.buildingAssessment.roofType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.roofTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["roofType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["roofType"]}</span>

            <label className="input-group" htmlFor="buildingUse">
                <span className="label-mobile mobile-input">Building Use </span>
                <select className="select" id="buildingUse" name="buildingUse" value={props.buildingAssessment.buildingUse} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.buildingUse.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["buildingUse"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["buildingUse"]}</span>

            <label className="input-group" htmlFor="buildingAge">
                <span className="label-mobile mobile-input">Building Age (Approx. Years)</span>
                <input className='input' type='text' maxLength={20} id="buildingAge" name="buildingAge" value={props.buildingAssessment.buildingAge} onBlur={(e) => handleOnBlur(e)} onChange={(e) => handleChange(e)} disabled={!props.canEdit}></input>
            </label>
            <span className={errors["buildingAge"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["buildingAge"]}</span>
              
            <label className="input-group" htmlFor="propertyUninhabitable">
                <span className="label-mobile mobile-input">Is the property uninhabitable due to the damage sustained?</span>
                <select className="select" id="propertyUninhabitable" name="propertyUninhabitable" value={props.buildingAssessment.propertyUninhabitable} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["propertyUninhabitable"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["propertyUninhabitable"]}</span>

            <label className="input-group" htmlFor="uninhabitableDuringRepairs">
                <span className="label-mobile mobile-input">Will the property be uninhabitable during repairs?</span>
                <select className="select" id="uninhabitableDuringRepairs" name="uninhabitableDuringRepairs" value={props.buildingAssessment.uninhabitableDuringRepairs} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["uninhabitableDuringRepairs"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["uninhabitableDuringRepairs"]}</span>

            <div className={props.buildingAssessment.uninhabitableDuringRepairs == 1 ? "" : "hidden"}>
                <label className="input-group" htmlFor="uninhabitableDuringRepairsTime">
                    <span className="label-mobile mobile-input">For how long?</span>
                    <input className='input' type='text' maxLength={50} id="uninhabitableDuringRepairsTime" name="uninhabitableDuringRepairsTime" value={props.buildingAssessment.uninhabitableDuringRepairsTime} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
                </label>
                <span className={errors["uninhabitableDuringRepairsTime"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["uninhabitableDuringRepairsTime"]}</span>
            </div>

            <label className="input-group" htmlFor="damageDetails">
                <span className="label-mobile mobile-input">Full details of damage</span>
                <textarea className="input assessment__textSize" maxLength={500} id="damageDetails" name="damageDetails" value={props.buildingAssessment.damageDetails} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["damageDetails"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["damageDetails"]}</span>

            <label className="input-group" htmlFor="insuredVersionOfEvents">
                <span className="label-mobile mobile-input">Insured's version of events</span>
                <textarea className="input assessment__textSize" maxLength={500} id="insuredVersionOfEvents" name="insuredVersionOfEvents" value={props.buildingAssessment.insuredVersionOfEvents} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["insuredVersionOfEvents"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["insuredVersionOfEvents"]}</span>

            <label className="input-group" htmlFor="causeOfDamage">
                <span className="label-mobile mobile-input">What is the cause of damage in your opinion?</span>
                <textarea className="input assessment__textSize" id="causeOfDamage" name="causeOfDamage" value={props.buildingAssessment.causeOfDamage} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["causeOfDamage"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["causeOfDamage"]}</span>

            <label className="input-group" htmlFor="causeOfDamageTime">
                <span className="label-mobile mobile-input">How long does the damage appear to have been occurring?</span>
                <textarea className="input assessment__textSize" maxLength={200} id="causeOfDamageTime" name="causeOfDamageTime" value={props.buildingAssessment.causeOfDamageTime} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["causeOfDamageTime"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["causeOfDamageTime"]}</span>

            <label className="input-group" htmlFor="roofReportRequested">
                <span className="label-mobile mobile-input">Was a roof report requested?</span>
                <select className="select" id="roofReportRequested" name="roofReportRequested" value={props.buildingAssessment.roofReportRequested} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["roofReportRequested"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["roofReportRequested"]}</span>

            <div className={props.buildingAssessment.roofReportRequested == 1 ? "" : "hidden"}>
                <label className="input-group" htmlFor="roofGeneralCondition">
                    <span className="label-mobile mobile-input">What is the general condition of the roof?</span>
                    <textarea className="input assessment__textSize" maxLength={500} id="roofGeneralCondition" name="roofGeneralCondition" value={props.buildingAssessment.roofGeneralCondition} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                </label>
                <span className={errors["roofGeneralCondition"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["roofGeneralCondition"]}</span>

                <label className="input-group" htmlFor="roofStormDamage">
                    <span className="label-mobile mobile-input">Was there evidence of a storm created opening? - Please provide comments.</span>
                    <textarea className="input assessment__textSize" maxLength={500} id="roofStormDamage" name="roofStormDamage" value={props.buildingAssessment.roofStormDamage} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                </label>
                <span className={errors["roofStormDamage"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["roofStormDamage"]}</span>

                <label className="input-group" htmlFor="guttersClean">
                    <span className="label-mobile mobile-input">Were the valleys and gutters clean at the time of inspection? - Please provide comments.</span>
                    <textarea className="input assessment__textSize" maxLength={500} id="guttersClean" name="guttersClean" value={props.buildingAssessment.guttersClean} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                </label>
                <span className={errors["guttersClean"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["guttersClean"]}</span>

                <label className="input-group" htmlFor="ceilingInspection">
                    <span className="label-mobile mobile-input">Was an inspection inside the ceiling cavity conducted?</span>
                    <select className="select" id="ceilingInspection" name="ceilingInspection" value={props.buildingAssessment.ceilingInspection} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                        <option hidden defaultValue="-1"></option>
                        {props.yesNo.map(yesNoOption =>
                            <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                        )};
                </select>
                </label>
                <span className={errors["ceilingInspection"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["ceilingInspection"]}</span>

                <label className="input-group" htmlFor="guttersSufficient">
                    <span className="label-mobile mobile-input">Were the gutters/downpipes to the property sufficient? - Please provide comments.</span>
                    <textarea className="input assessment__textSize" maxLength={500} id="guttersSufficient" name="guttersSufficient" value={props.buildingAssessment.guttersSufficient} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                </label>
                <span className={errors["guttersSufficient"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["guttersSufficient"]}</span>

                <label className="input-group" htmlFor="additionalRoofFixtures">
                    <span className="label-mobile mobile-input">Are there any additional fixtures installed on the roof?</span>
                    <select className="select" id="additionalRoofFixtures" name="additionalRoofFixtures" value={props.buildingAssessment.additionalRoofFixtures} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                        <option hidden defaultValue="-1"></option>
                        {props.yesNo.map(yesNoOption =>
                            <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                        )};
                </select>
                </label>
                <span className={errors["additionalRoofFixtures"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["additionalRoofFixtures"]}</span>

                <label className="input-group" htmlFor="rainWaterBreach">
                    <span className="label-mobile mobile-input">Where did the rain water breach the ceiling cavity? Please include supporting photographic evidence.</span>
                    <textarea className="input assessment__textSize" maxLength={500} id="rainWaterBreach" name="rainWaterBreach" value={props.buildingAssessment.rainWaterBreach} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                </label>
                <span className={errors["rainWaterBreach"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["rainWaterBreach"]}</span>
            </div>

            <label className="input-group" htmlFor="maintenanceIssues">
                <span className="label-mobile mobile-input">Are there any maintenance issues present?</span>
                <select className="select" id="maintenanceIssues" name="maintenanceIssues" value={props.buildingAssessment.maintenanceIssues} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["maintenanceIssues"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssues"]}</span>

            <div className={props.buildingAssessment.maintenanceIssues == 1 ? "" : "hidden"}>
                <label className="input-group" htmlFor="maintenanceIssuesDescription">
                    <span className="label-mobile mobile-input">What are the maintenance issues?</span>
                    <textarea className="input assessment__textSize" maxLength={500} id="maintenanceIssuesDescription" name="maintenanceIssuesDescription" value={props.buildingAssessment.maintenanceIssuesDescription} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                </label>
                <span className={errors["maintenanceIssuesDescription"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssuesDescription"]}</span>
                

                <label className="input-group" htmlFor="maintenanceIssuesInsuredAware">
                    <span className="label-mobile mobile-input">Would the Insured have been reasonably aware of these maintenance issues?</span>
                    <select className="select" id="maintenanceIssuesInsuredAware" name="maintenanceIssuesInsuredAware" value={props.buildingAssessment.maintenanceIssuesInsuredAware} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                        <option hidden defaultValue="-1"></option>
                        {props.yesNoUnsure.map(yesNoUnsureOption =>
                            <option key={yesNoUnsureOption.id} value={yesNoUnsureOption.id}>{yesNoUnsureOption.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["maintenanceIssuesInsuredAware"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssuesInsuredAware"]}</span>

                <div className={props.buildingAssessment.maintenanceIssuesInsuredAware == 1 ? "" : "hidden"}>
                    <label className="input-group" htmlFor="maintenanceIssuesInsuredAwareTime">
                        <span className="label-mobile mobile-input">How long would the Insured have been aware of the issues?</span>
                        <textarea className="input assessment__textSize" maxLength={200} id="maintenanceIssuesInsuredAwareTime" name="maintenanceIssuesInsuredAwareTime" value={props.buildingAssessment.maintenanceIssuesInsuredAwareTime} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
                    </label>
                    <span className={errors["maintenanceIssuesInsuredAwareTime"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssuesInsuredAwareTime"]}</span>
                </div>

                <label className="input-group" htmlFor="maintenanceIssuesAddressed">
                    <span className="label-mobile mobile-input">Do these maintenance issues need to be addressed before repairs to insurable damage can commence?</span>
                    <select className="select" id="maintenanceIssuesAddressed" name="maintenanceIssuesAddressed" value={props.buildingAssessment.maintenanceIssuesAddressed} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                        <option hidden defaultValue="-1"></option>
                        {props.yesNo.map(yesNoOption =>
                            <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["maintenanceIssuesAddressed"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssuesAddressed"]}</span>
            </div>
            <label className="input-group" htmlFor="damageConsistentWithEvent">
                <span className="label-mobile mobile-input">Is the damage consistant with the stated event?</span>
                <select className="select" id="damageConsistentWithEvent" name="damageConsistentWithEvent" value={props.buildingAssessment.damageConsistentWithEvent} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["damageConsistentWithEvent"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["damageConsistentWithEvent"]}</span>

            <label className="input-group" htmlFor="insuranceRelated">
                <span className="label-mobile mobile-input">Does Skyline believe the damage is insurance related?</span>
                <select className="select" id="insuranceRelated" name="insuranceRelated" value={props.buildingAssessment.insuranceRelated} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["insuranceRelated"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["insuranceRelated"]}</span>

            <label className="input-group" htmlFor="recommendAcceptance">
                <span className="label-mobile mobile-input">Does Skyline recommend acceptance of this claim?</span>
                <select className="select" id="recommendAcceptance" name="recommendAcceptance" value={props.buildingAssessment.recommendAcceptance} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNo.map(yesNoOption =>
                        <option key={yesNoOption.id} value={yesNoOption.id}>{yesNoOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["recommendAcceptance"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["recommendAcceptance"]}</span>

            <label className="input-group" htmlFor="additionalComments">
                <span className="label-mobile mobile-input">Additional Comments</span>
                <textarea className="input assessment__textSize" maxLength={500} id="additionalComments" name="additionalComments" value={props.buildingAssessment.additionalComments} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <div className="jobpo__backButton">
                <a className="mobile-icon-green" href="#" onClick={(e) => next(e)}>
                    <span className="fas fa-arrow-alt-circle-right mobileMenuSize alignIconCenter"></span>
                </a>
            </div>
        </div>
    )

}

export default JobAssessmentMobileSuuData;
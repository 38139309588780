import * as React from 'react';
import { Modal } from 'react-bootstrap';
import * as DateHelpers from './DateHelpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface JobsConfirmStatusChangeProps {
    heading: string;
    jobId: number;
    jobStatusId: number;
    showModal: boolean;
    hideModal(): void;
    cancelModal(): void;
    saveModal(): void;
}

const JobsConfirmStatusChange = (props: JobsConfirmStatusChangeProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [statusDate, setStatusDate] = React.useState(DateHelpers.getCurrentDate());

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setStatusDate(e.target.value);
        setSaveDisabled(false);
    }

    const hideModal = () => {
        setErrors({});
        props.hideModal();
    }

    const cancelModal = () => {
        setErrors({});
        props.cancelModal();
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (!statusDate) {
            formIsValid = false;
            error["statusDate"] = "Date is required";
        } else if (!DateHelpers.isValidDate(statusDate)) {
            formIsValid = false;
            error["statusDate"] = "Valid Date is required";
        } else if (DateHelpers.isDateInFuture(statusDate)) {
            formIsValid = false;
            error["statusDate"] = "Date cannot be a future date";
        }

        setErrors(error);
        return formIsValid;
    }

    const saveModal = () => {
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);
            setErrors({});
            saveStatusChange();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveStatusChange = async () => {
        const user = await authService.getUser();

        //onhold = 10, inprogress = 5
        let jobStatusId = props.jobStatusId === 5 ? 10 : 5;

        let updateStatus = {
            jobId: props.jobId,
            statusId: jobStatusId,
            statusDate: statusDate,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/UpdateJobStatus', updateStatus, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Job Status Updated");
                setErrors({});
                setSaveDisabled(false);
                setStatusDate(DateHelpers.getCurrentDate());
                props.saveModal();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <Modal size="sm" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>{props.heading}</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Enter date that the status changed</p>
                </div>
                <label className="input-group" htmlFor="notes">
                    <span className="label">Status Change Date</span>
                    <input type='date' id="statusDate" name="statusDate" className='input' value={statusDate} onChange={(e) => handleChange(e)} ></input>
                </label>
                <span className={errors["statusDate"] ? "label errors" : "hidden"}>{errors["statusDate"]}</span>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={cancelModal}>Cancel</button>
                <button className="popup-button--yes" onClick={saveModal} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )

}

export default JobsConfirmStatusChange;
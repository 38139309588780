import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import purchaseOrderReducer from './JobPurchaseOrderReducer';
import JobPurchaseOrderAddMobileData from './JobPurchaseOrderMobileAdd';
import JobPurchaseOrderAddDetailsListMobileData from './JobPurchaseOrderMobileAddDetailsList';
import JobPurchaseOrderAddDetailsMobileData from './JobPurchaseOrderMobileAddDetails';
import JobPurchaseOrderAddConfirmMobileData from './JobPurchaseOrderMobileAddConfirm';

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplierId: number;
    supplier: string;
    supplierContactId: number;
    supplierContact: string;
    certificateExpiryDate: string;
    validCertificate: boolean;
    certificateExpiryText: string;
    registeredForGst: boolean;
    dateRequired: string;
    dateRequiredFormatted: string;
    includeFullScope: boolean;
    instructions: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    statusId: number;
}

interface PurchaseOrderDetail {
    itemIndex: number;
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    costCodeIdOrig: number;     //need to put back original values if they click back button
    costCodeOrig: string;
    quantityOrig: number;
    quantityTypeOrig: string;
    unitOfMeasureOrig: number;
    unitCostOrig: number;
    unitCostOrigFormatted: string;
    totalOrig: number;
    totalOrigFormatted: string;
    exceedForecastReason: string;
    newItem: boolean;
}

interface SupplierOption {
    id: number;
    name: string;
    registeredForGst: boolean;
    validCertificate: boolean;
    certificateExpiryText: string;
    certificateExpiryDate: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface ExceedForecast {
    costCodeId: number;
    exceedForecastReason: string;
}

interface CostCodeBudgetCheck {
    costCodeId: number;
    remaining: number;              //after catering for any items added on this po
    origRemaining: number;          //from the database based on existing saved po
    budgetRemaining: number;        //after catering for any items added on this po
    origBudgetRemaining: number;    //from the database based on existing saved po
    currentItemTotal: number;       //used to cater for user changing cost codes when adding/editing an item
}


const JobPurchaseOrdersAddMobileData = () => {
    let navigate = useNavigate();
    const { id, jobid } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobId, setJobId] = React.useState(0);
    const [gstValue, setGstValue] = React.useState(0.1);
    const [canExceedBudget, setCanExceedBudget] = React.useState(false);
    const [costCodeBudgetCheck, setCostCodeBudgetCheck] = React.useState<CostCodeBudgetCheck[]>([]);
    const [checkSupplierCertificate, setCheckSupplierCertificate] = React.useState(false);
    const [suppliers, setSuppliers] = React.useState<SupplierOption[]>([]);
    const [supplierContacts, setSupplierContacts] = React.useState<GenericListItem[]>([]);
    const [costCodeList, setCostCodeList] = React.useState<GenericListItem[]>([]);
    const [uomList, setUomList] = React.useState<GenericListItem[]>([]);
    const [page, setPage] = React.useState("po");
    const [editIndex, setEditIndex] = React.useState(-1);
    
    const initialPurchaseOrder: PurchaseOrder = {
        id: 0,
        jobId: 0,
        supplierId: -1,
        supplier: "",
        supplierContactId: -1,
        supplierContact: "",
        certificateExpiryDate: "",
        certificateExpiryText: "",
        validCertificate: false,
        registeredForGst: false,
        dateRequired: "",
        dateRequiredFormatted: "",
        includeFullScope: false,
        instructions: "As per scope of works",
        subTotalFormatted: "",
        gstFormatted: "",
        totalFormatted: "",
        statusId: 1
    };

    const initialPurchaseOrderDetail: PurchaseOrderDetail[] = [];

    const [{
        purchaseOrder,
        purchaseOrderDetails,
        isLoading,
    }, dispatch] = useReducer(purchaseOrderReducer, { purchaseOrder: initialPurchaseOrder, purchaseOrderDetails: initialPurchaseOrderDetail, isLoading: false });


    React.useEffect(() => {
        window.scrollTo(0, 0);

        let JobId = Number(jobid);
        let Id = id ? Number(id) : 0;
        getData(JobId, Id);
    }, []);

    const getData = async(JobId: number, Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetPurchaseOrder?SubId=' + user.sub + '&JobId=' + JobId + '&Id=' + Id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                let po = res.data.purchaseOrder
                let poDetails = po.purchaseOrderDetails;
                //set exceed po reason
                for (var i = 0; i < poDetails.length; i++) {
                    //set original values for when hitting back button to reset changes
                    poDetails[i].itemIndex = i + 1;
                    poDetails[i].costCodeIdOrig = poDetails[i].costCodeId;
                    poDetails[i].costCodeOrig = poDetails[i].costCode;
                    poDetails[i].quantityOrig = poDetails[i].quantity;
                    poDetails[i].quantityTypeOrig = poDetails[i].quantityType;
                    poDetails[i].unitOfMeasureOrig = poDetails[i].unitOfMeasure;
                    poDetails[i].unitCostOrig = poDetails[i].unitCost;
                    poDetails[i].unitCostOrigFormatted = poDetails[i].unitCostFormatted;
                    poDetails[i].totalOrig = poDetails[i].total;
                    poDetails[i].totalOrigFormatted = poDetails[i].totalFormatted;
                    poDetails[i].exceedForecastReason = "";
                    poDetails[i].newItem = false;
                }

                var blankDetail = addBlankDetailRow(poDetails.length);
                poDetails.push(blankDetail);

                dispatch({ type: "updatePo", purchaseOrder: po });
                dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });

                setJobId(JobId);
                setGstValue(res.data.gstValue);
                setCanExceedBudget(res.data.canExceedBudget);
                setCostCodeBudgetCheck(res.data.costCodeBudgetCheck);
                setSuppliers(res.data.suppliers);
                setCheckSupplierCertificate(res.data.checkSupplierCertificate);
                setCostCodeList(res.data.costCodeList);
                setUomList(res.data.uomList);
                setLoading(false);

                if (po.supplierId > 0) {
                    getSupplierContacts(po.supplierId);
                }
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const getSupplierContacts = (supplierId: number) => {
        getSupplierContactList(supplierId);
    }

    const getSupplierContactList = async(supplierId: number) => {
        const token = await authService.getAccessToken();

        if (supplierId < 1) {
            setSupplierContacts([]);
        } else {
            axios.get('Suppliers/GetSupplierContacts?SupplierId=' + supplierId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                setSupplierContacts(res.data);
            }).catch(error => {
                toast.error(error.message);
            });
        }
    }

    const goPo = () => {
        setPage("po");
    }

    const goDetail = () => {
        setEditIndex(purchaseOrderDetails.length - 1);
        setPage("podetail");
    }

    const edit = (poDetailIndex: number) => {
        let poDetails = purchaseOrderDetails;
        var poDetail = poDetails[poDetailIndex];
        poDetail.newItem = false;

        //update the budget
        var blankPoDetail = addBlankDetailRow(0);
        var ccBudget = calculateCostCodeBudget(blankPoDetail);

        //update remaining and budget remaining on po item
        var ccBudgetItem = ccBudget.find(cc => cc.costCodeId === poDetail.costCodeId);
        if (ccBudgetItem) {
            var remaining = (ccBudgetItem.remaining * 1) + (poDetail.total * 1);
            var budgetRemaining = (ccBudgetItem.budgetRemaining * 1) + (poDetail.total * 1);
            poDetail.remaining = remaining;
            poDetail.remainingFormatted = remaining.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
            poDetail.budgetRemaining = budgetRemaining;
        }

        setPage("podetail");
        setEditIndex(poDetailIndex);
        dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });
    }

    const addDetailToPo = () => {
        let poDetails = purchaseOrderDetails;
        var poDetail = poDetails[editIndex];

        //update the original values to the new values
        poDetail.costCodeIdOrig = poDetail.costCodeId;
        poDetail.costCodeOrig = poDetail.costCode;
        poDetail.quantityOrig = poDetail.quantity;
        poDetail.quantityTypeOrig = poDetail.quantityType;
        poDetail.unitOfMeasureOrig = poDetail.unitOfMeasure;
        poDetail.unitCostOrig = poDetail.unitCost;
        poDetail.unitCostOrigFormatted = poDetail.unitCostFormatted;
        poDetail.totalOrig = poDetail.total;
        poDetail.totalOrigFormatted = poDetail.totalFormatted;

        var newIndex = poDetails.length;
        if (poDetail.newItem) {
            //need a blank row if we are adding, blank row exists when editing
            var newPoDetail = addBlankDetailRow(newIndex);
            poDetails.push(newPoDetail);
        }

        dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });

        //reset current item total in budget check
        let ccBudgetCheck = costCodeBudgetCheck;
        var resetItemTotal = ccBudgetCheck.filter(cc => cc.currentItemTotal > 0);
        for (var i = 0; i < resetItemTotal.length; i++) {
            resetItemTotal[i].currentItemTotal = 0;
        }
        
        setEditIndex(newIndex);
        setCostCodeBudgetCheck(ccBudgetCheck);
        dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });

        calculateTotals();   //calculate saves the po
    }

    const updatePoTotals = () => {
        calculateTotals();
    }

    const goDetailList = () => {
        setPage("podetaillist");
    }

    const goConfirm = () => {
        setPage("poconfirm");
    }

    const save = () => {
        savePo();
    }

    const savePo = async() => {
        //add any costcode + reasons that have exceeded the forecast
        var exceedForecast: ExceedForecast[] = [];
        var poExceed = purchaseOrderDetails.filter(po => po.exceedForecastReason.length > 0);
        for (var i = 0; i < poExceed.length; i++) {
            var exceedReason: ExceedForecast = {
                costCodeId: poExceed[i].costCodeId,
                exceedForecastReason: poExceed[i].exceedForecastReason
            };
            exceedForecast.push(exceedReason);
        }

        let po: any = purchaseOrder;
        purchaseOrderDetails.pop();
        po.purchaseOrderDetails = purchaseOrderDetails;
        
        const user = await authService.getUser();
        var saveData = {
            purchaseOrder: po,
            exceedForecast: exceedForecast,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/SavePurchaseOrder', saveData, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Purchase Order Saved");
                cancel();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //delete
    const deletePoDetailRow = (poIndex: number) => {
        //var purchaseOrder = this.state.purchaseOrder;
        var poDetails = purchaseOrderDetails;
        var poDetail = poDetails[poIndex];

        if (poDetail.id == 0) {
            //not saved yet so just remove from list
            poDetails.splice(poIndex, 1);

            dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });
            calculateTotals();   //calculate saves the po

            //get blank po Detail row
            var blankPoDetail = addBlankDetailRow(0);
            calculateCostCodeBudget(blankPoDetail);
        } else {
            deletePODetail(poIndex);
        }
    }

    const deletePODetail = async(poIndex: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var poDetails = purchaseOrderDetails;
        var poDetail = poDetails[poIndex];

        var deleteDetail = {
            PurchaseOrderId: purchaseOrder.id,
            id: poDetail.id,
            jobId: purchaseOrder.jobId,
            subId: user.sub
        };

        axios.post('Jobs/DeletePurchaseOrderDetail', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Item has been deleted");
                //remove from list
                poDetails.splice(poIndex, 1);

                dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });
                calculateTotals();   //calculate saves the po
                //update cost code budget
                var blankPoDetail = addBlankDetailRow(0);
                calculateCostCodeBudget(blankPoDetail);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end delete

    const addBlankDetailRow = (itemIndex: number) => {
        var purchaseOrderDetail: PurchaseOrderDetail = {
            itemIndex: itemIndex,
            id: 0,
            purchaseOrderId: 0,
            costCodeId: -1,
            costCode: "",
            budget: 0,
            budgetFormatted: "",
            forecast: 0,
            forecastFormatted: "",
            remaining: 0,
            remainingFormatted: "",
            budgetRemaining: 0,
            quantity: 0,
            quantityType: "",
            unitOfMeasure: -1,
            unitCost: 0,
            unitCostFormatted: "$0.00",
            total: 0,
            totalFormatted: "$0.00",
            costCodeIdOrig: -1,
            costCodeOrig: "",
            quantityOrig: 0,
            quantityTypeOrig: "",
            unitOfMeasureOrig: -1,
            unitCostOrig: 0,
            unitCostOrigFormatted: "$0.00",
            totalOrig: 0,
            totalOrigFormatted: "$0.00",
            exceedForecastReason: "",
            newItem: true
        };
        return purchaseOrderDetail;
    }

    const calculateTotals = () => {
        //check if supplier is registered for gst
        let registeredForGst = purchaseOrder.registeredForGst;
        
        var subTotalExGst = 0;
        var gst = 0;
        var total = 0;

        for (var i = 0; i < purchaseOrderDetails.length; i++) {
            subTotalExGst += (purchaseOrderDetails[i].total * 1);
        }
        gst = registeredForGst ? (subTotalExGst * gstValue) : 0;
        total = subTotalExGst + gst;

        var subTotalExGstFormatted = subTotalExGst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var gstFormatted = gst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var totalFormatted = total.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });

        var po = purchaseOrder;
        po.subTotalFormatted = subTotalExGstFormatted;
        po.gstFormatted = gstFormatted;
        po.totalFormatted = totalFormatted;
        dispatch({ type: "updatePo", purchaseOrder: po });
    }

    const updatepo = (po: PurchaseOrder) => {
        dispatch({ type: "updatePo", purchaseOrder: po });
    }

    const updatedetail = (poDetail: PurchaseOrderDetail) => {
        calculateCostCodeBudget(poDetail);
        let poDetails = purchaseOrderDetails;
        let poDetailExist = poDetails[editIndex];
        poDetailExist = poDetail;
        dispatch({ type: "updatePoDetails", purchaseOrderDetails: poDetails });
    }

    const calculateCostCodeBudget = (purchaseOrderDetail: PurchaseOrderDetail) => {
        var ccBudgetCheck = costCodeBudgetCheck;

        //reset all existing budgets
        for (var i = 0; i < ccBudgetCheck.length; i++) {
            ccBudgetCheck[i].budgetRemaining = ccBudgetCheck[i].origBudgetRemaining;
            ccBudgetCheck[i].remaining = ccBudgetCheck[i].origRemaining;
        }

        //loop through all purchase order details and update the budgets
        //check purchaseOrderDetail above for changed amounts
        for (var x = 0; x < purchaseOrderDetails.length; x++) {
            var poDetail = purchaseOrderDetails[x];
            if (poDetail.itemIndex == purchaseOrderDetail.itemIndex) {
                //use updated po details line
                poDetail = purchaseOrderDetail;
            }

            if (poDetail.costCodeId > 0) {
                ccBudgetCheck = calculateUpdateCostCodeBudget(ccBudgetCheck, poDetail);
            }
        }

        //if the item is new then we still need to update the cost code budget
        if (purchaseOrderDetail.newItem && purchaseOrderDetail.costCodeId > 0) {
            ccBudgetCheck = calculateUpdateCostCodeBudget(ccBudgetCheck, purchaseOrderDetail);
        }

        setCostCodeBudgetCheck(ccBudgetCheck);

        return costCodeBudgetCheck;
    }

    const calculateUpdateCostCodeBudget = (costCodeBudgetCheck: CostCodeBudgetCheck[], poDetail: PurchaseOrderDetail) => {
        //find any existing budget items
        var costCodeBudget = costCodeBudgetCheck.find(b => b.costCodeId === poDetail.costCodeId);
        var total = poDetail.total * 1;
        if (costCodeBudget) {
            //update existing budget
            costCodeBudget.budgetRemaining -= total;
            costCodeBudget.remaining -= total;
        } else {
            //add new budget
            costCodeBudget = {
                costCodeId: poDetail.costCodeId,
                currentItemTotal: total,
                remaining: (poDetail.remaining - total),
                origRemaining: poDetail.remaining,
                budgetRemaining: (poDetail.budgetRemaining - total),
                origBudgetRemaining: poDetail.budgetRemaining
            };
            costCodeBudgetCheck.push(costCodeBudget);
        }

        return costCodeBudgetCheck;
    }

    const updateCostCodeBudget = (ccBudgetCheck: CostCodeBudgetCheck[]) => {
        setCostCodeBudgetCheck(ccBudgetCheck);
    }

    const cancel = () => {
        //go back to the purchase order list page
        var url = "/job/" + jobId + "/purchaseordersm";
        navigate(url);
    }

    var tabContents;
    if (page === "po") {
        tabContents = <JobPurchaseOrderAddMobileData purchaseOrder={purchaseOrder} suppliers={suppliers} supplierContacts={supplierContacts} checkSupplierCertificate={checkSupplierCertificate} getSupplierContacts={getSupplierContacts} update={updatepo} next={purchaseOrder.id === 0 ? goDetail : goDetailList} cancel={cancel} />
    }
    else if (page === "podetaillist") {
        tabContents = <JobPurchaseOrderAddDetailsListMobileData purchaseOrderDetails={purchaseOrderDetails} cancel={cancel} back={goPo} addNew={goDetail} edit={edit} delete={deletePoDetailRow} next={goConfirm} />
    }
    else if (page === "podetail") {
        tabContents = <JobPurchaseOrderAddDetailsMobileData jobId={jobId} purchaseOrderId={purchaseOrder.id} registeredForGst={purchaseOrder.registeredForGst} gstValue={gstValue} canExceedBudget={canExceedBudget} purchaseOrderDetail={purchaseOrderDetails[editIndex]} costCodeBudgetCheck={costCodeBudgetCheck} costCodeList={costCodeList} uomList={uomList} update={updatedetail} cancel={cancel} back={purchaseOrder.id === 0 ? goPo : goDetailList} addNew={addDetailToPo} next={purchaseOrder.id === 0 ? goConfirm : goDetailList} />
    } else if (page === "poconfirm") {
        tabContents = <JobPurchaseOrderAddConfirmMobileData purchaseOrder={purchaseOrder} purchaseOrderDetails={purchaseOrderDetails} costCodeBudgetCheck={costCodeBudgetCheck} save={save} updatePo={updatePoTotals} updateCostCodeBudget={updateCostCodeBudget} edit={edit} back={purchaseOrder.id === 0 ? goDetail : goDetailList} delete={deletePoDetailRow} cancel={cancel} />
    }

    let contents = loading
        ? <p><em>Loading...</em></p>
        : <div>{tabContents}</div>

    return (
        <div>
            {contents}
        </div>
    );

}
export default JobPurchaseOrdersAddMobileData;
import * as React from 'react';

type BuildingAssessment = {
    id: number;
    jobId: number;
    typeId: number;
    policyNumber: string;
    occupantName: string;
    occupantContactNumber: string;
    incidentConfirmed: number;
    clientInformedNextSteps: number;
    occupancyType: number;
    temporaryAccommodation: number;
    furnitureRemovalStorage: number;
    makeSafeRequired: number;
    emergencyServicesAttend: number;
    makeSafeDescription: string;
    workCompletedOthers: number;
    workCompletedOthersDescription: string;
    buildingType: number;
    outBuildings: string;
    buildingCondition: string;
    constructionType: number;
    roofType: number;
    buildingUse: number;
    buildingAge: string;
    propertyUninhabitable: number;
    uninhabitableDuringRepairs: number;
    uninhabitableDuringRepairsTime: string;
    damageDetails: string;
    insuredVersionOfEvents: string;
    causeOfDamage: string;
    causeOfDamageTime: string;
    roofReportRequested: number;
    roofGeneralCondition: string;
    roofStormDamage: string;
    guttersClean: string;
    ceilingInspection: number;
    guttersSufficient: string;
    additionalRoofFixtures: number;
    rainWaterBreach: string;
    maintenanceIssues: number;
    maintenanceIssuesDescription: string;
    maintenanceIssuesInsuredAware: number;
    maintenanceIssuesInsuredAwareTime: string;
    maintenanceIssuesAddressed: number;
    damageConsistentWithEvent: number;
    insuranceRelated: number;
    recommendAcceptance: number;
    additionalComments: string;
    numberOfStories: number;
    roofPitch: string;
    structureArea: string;
    observations: string;
    resolution: string;
}

type Assessment = {
    buildingAssessment: BuildingAssessment;
    isLoading: boolean;
    error?: string;
}

const initialAssessment: Assessment = {
    buildingAssessment: {
        id: 0,
        jobId: 0,
        typeId: 1,
        policyNumber: "",
        occupantName: "",
        occupantContactNumber: "",
        incidentConfirmed: -1,
        clientInformedNextSteps: -1,
        occupancyType: -1,
        temporaryAccommodation: -1,
        furnitureRemovalStorage: -1,
        makeSafeRequired: -1,
        emergencyServicesAttend: -1,
        makeSafeDescription: "",
        workCompletedOthers: -1,
        workCompletedOthersDescription: "",
        buildingType: -1,
        outBuildings: "",
        buildingCondition: "",
        constructionType: -1,
        roofType: -1,
        buildingUse: -1,
        buildingAge: "",
        propertyUninhabitable: -1,
        uninhabitableDuringRepairs: -1,
        uninhabitableDuringRepairsTime: "",
        damageDetails: "",
        insuredVersionOfEvents: "",
        causeOfDamage: "",
        causeOfDamageTime: "",
        roofReportRequested: -1,
        roofGeneralCondition: "",
        roofStormDamage: "",
        guttersClean: "",
        ceilingInspection: -1,
        guttersSufficient: "",
        additionalRoofFixtures: -1,
        rainWaterBreach: "",
        maintenanceIssues: -1,
        maintenanceIssuesDescription: "",
        maintenanceIssuesInsuredAware: -1,
        maintenanceIssuesInsuredAwareTime: "",
        maintenanceIssuesAddressed: -1,
        damageConsistentWithEvent: -1,
        insuranceRelated: -1,
        recommendAcceptance: -1,
        additionalComments: "",
        numberOfStories: -1,
        roofPitch: "",
        structureArea: "",
        observations: "",
        resolution: ""
    },
    isLoading: false,
    error: ""
}

type Action =
    | { type: 'getDetails' }
    | { type: 'updateDetails', buildingAssessment: BuildingAssessment }
    | { type: 'reset' }
    | { type: 'error', error: "" };

const assessmentReducer = (assessment: Assessment = initialAssessment, action: Action): Assessment => {
    switch (action.type) {
        case 'getDetails':
            return { ...assessment, isLoading: true };
        case 'updateDetails':
            return { ...assessment, isLoading: false, buildingAssessment: action.buildingAssessment };
        case 'reset':
            return initialAssessment;
        case 'error':
            return { ...assessment, isLoading: false, error: action.error };
        default:
            return assessment;
    }
}

export default assessmentReducer; 

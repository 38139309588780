import * as React from 'react';
import ConfirmModal from './ConfirmModal';

interface JobPurchaseOrderAddDetailsListMobileProps {
    purchaseOrderDetails: PurchaseOrderDetail[];
    back(): void;
    edit(poDetailIndex: number): void;
    addNew(): void;
    next(): void;
    delete(poIndex: number): void;
    cancel(): void;
}

interface PurchaseOrderDetail {
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    quantityOrig: number;       //need to put back original values if they click back button
    quantityTypeOrig: string;
    unitOfMeasureOrig: number;
    unitCostOrig: number;
    unitCostOrigFormatted: string;
    totalOrig: number;
    totalOrigFormatted: string;
    exceedForecastReason: string;
}

const JobPurchaseOrderAddDetailsListMobileData = (props: JobPurchaseOrderAddDetailsListMobileProps) => {
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [poIndex, setPoIndex] = React.useState(-1);

    const cancel = (e: any) => {
        e.preventDefault();
        props.cancel();
    }

    const back = (e: any) => {
        e.preventDefault();
        props.back();
    }

    const addNew = (e: any) => {
        e.preventDefault();

        props.addNew();
    }

    const edit = (e: any, index: number) => {
        e.preventDefault();

        props.edit(index);
    }

    const next = (e: any) => {
        e.preventDefault();

        props.next();
    }

    //delete row
    const deleteDetail = (e: any, index: number) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setPoIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setPoIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        props.delete(poIndex);
        setShowConfirmModal(false);
        setPoIndex(-1);
    }
    //end delete row

    let confirmPopup = <ConfirmModal heading="Delete PO Row" text="Are you sure you want to delete this purchase order detail row?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />

    return (
        <div className="marginBottom50">
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div className="makeitflexspacebetween">
                <h4 className="mobile-margin">Edit Purchase Order</h4>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-red" href="#" onClick={(e) => cancel(e)}>
                        <span className="fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
            <div className="mobileFont">
                <div className="marginTop10">
                    <table className="table--main table__small tableColours mobileTableFont">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Cost Code</th>
                                <th className="textalignright">Quantity</th>
                                <th className="textalignright">Unit</th>
                                <th className="textalignright">Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.purchaseOrderDetails.map((detail, index) =>
                                <tr className={index === props.purchaseOrderDetails.length - 1 ? "hidden" : ""} key={index}>
                                    <td className="table__text--align">
                                        <div className="delete--tablecell">
                                            <a className="" href="#" onClick={(e) => edit(e, index)}>
                                                <span className="fas fa-edit edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="table__text--align">{detail.costCode}</td>
                                    <td className="table__text--align textalignright">{detail.quantityType}</td>
                                    <td className="table__text--align textalignright">{detail.unitCostFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.totalFormatted}</td>
                                    <td className="table__text--align">
                                        <div className="delete--tablecell">
                                            <a className="makeitred" href="#" onClick={(e) => deleteDetail(e, index)}>
                                                <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
            <div className="bottom-edit-nav makeitflexspacebetween">
                <div className="jobpo__backButton">
                    <a className="mobile-icon-grey" href="#" onClick={(e) => back(e)}>
                        <span className="fas fa-arrow-alt-circle-left mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => addNew(e)}>
                        <span className="fas fa-plus-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => next(e)}>
                        <span className="fas fa-arrow-alt-circle-right mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
        </div>
    );

}

export default JobPurchaseOrderAddDetailsListMobileData;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import SupplierInvoicePoSearchResults from './SupplierInvoicePoSearchResults';

interface SupplierInvoicePOSearchProps {
    suppliers: SupplierOption[];
    invoiceId: number;
    invoiceTotal: number;
    jobId: number;
    supplierId: number;
    showModal: boolean;
    hideModal(): void;
    saveModal(poResult: SupplierResult, duplicateInvoice: boolean): void;
}

interface SearchResult {
    selected: boolean;
    jobId: number;
    isMakeSafe: boolean;
    siteAddress: string;
    supplier: string;
    supplierId: number;
    supplierRegisteredForGst: boolean;
    xeroContactId: string;
    xeroContactName: string;
    purchaseOrders: SearchResultPo[];
}

interface SearchResultPo {
    selected: boolean;
    purchaseOrderId: number;
    dueDate: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    totalApplied: number;
    total: number;
    totalFormatted: string;
    remainingSubTotal: number;
    remainingSubTotalFormatted: string;
    remainingGst: number;
    remainingGstFormatted: string;
    remainingTotal: number;
    remainingTotalFormatted: string;
}

interface SupplierResult {
    invoiceId: number;
    jobId: number;
    siteAddress: string;
    supplier: string;
    supplierId: number;
    supplierRegisteredForGst: boolean;
    xeroContactId: string;
    xeroContactName: string;
    purchaseOrderNumber: number;
    purchaseOrderNumbers: string;
    purchaseOrders: SupplierResultPo[];
    poSubTotal: number;
    poSubTotalFormatted: string;
    poSubTotalRemaining: number;
    poSubTotalRemainingFormatted: string;
    poGst: number;
    poGstFormatted: string;
    poGstRemaining: number;
    poGstRemainingFormatted: string;
    poTotal: number;
    poTotalFormatted: string;
    poTotalRemaining: number;
    poTotalRemainingFormatted: string;
}

interface SupplierResultPo {
    purchaseOrderId: number;
    dueDate: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    total: number;
    totalFormatted: string;
}

interface SupplierOption {
    id: number;
    name: string;
    registeredForGst: boolean;
    validCertificate: boolean;
    certificateExpiryText: string;
    certificateExpiryDate: string;
}

const SupplierInvoicePOSearchPopup = (props: SupplierInvoicePOSearchProps) => {
    const [searchFields, setSearchFields] = React.useState({ poSearch: 0, jobIdSearch: 0, siteAddressSearch: "", supplierIdSearch: -1, noPoSearch: false });
    const [resetSupplier, setResetSupplier] = React.useState(false);
    const [showResults, setShowResults] = React.useState(false);
    const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);

    const handleKeyUp = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            search();
        }
    }

    const handleChange = (e: any) => {
        setSearchFields(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

        if (e.target.name === "supplierIdSearch") {
            setResetSupplier(true);
        }
    }

    const handleCheckboxChange = (e: any) => {
        setSearchFields(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        setSearchResults([]);
    }

    

    const search = () => {
        searchPo();
    }

    const searchPo = async () => {
        let jobIdSearch = props.jobId > 0 ? props.jobId : searchFields.jobIdSearch * 1;
        let supplierIdSearch = searchFields.supplierIdSearch > 0 || resetSupplier ? searchFields.supplierIdSearch * 1 : props.supplierId;

        if (!searchFields.poSearch && supplierIdSearch <= 0 && jobIdSearch === 0 && !searchFields.siteAddressSearch) {
            toast.error("Enter at least one search criteria before searching");
        } else {
            const token = await authService.getAccessToken();
            const user = await authService.getUser();
            axios.get('Suppliers/PurchaseOrderSearch?PurchaseOrderNumber=' + searchFields.poSearch + '&SupplierId=' + supplierIdSearch + '&JobId=' + jobIdSearch + '&SiteAddress=' + searchFields.siteAddressSearch + '&NoPo=' + searchFields.noPoSearch + '&subId=' + user.sub, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                setShowResults(true);
                setSearchResults(res.data);
            })
            .catch(error => {
                toast.error(error.message);
            });
        }
    }

    const save = () => {
        savePo();
    }

    const savePo = async () => {
        const user = await authService.getUser();
        //let searchResults = searchResults;
        var selectedJob = searchResults.find(s => s.selected);
        let selectedPos: SearchResultPo[] = [];
        if (!searchFields.noPoSearch) {
            //find the selected pos
            for (var x = 0; x < searchResults.length; x++) {
                selectedPos = searchResults[x].purchaseOrders.filter(po => po.selected && po.totalApplied > 0);
                if (selectedPos.length > 0) {
                    selectedJob = searchResults[x];
                    break;
                }
            }
        }

        if (selectedJob) {
            var result = {
                invoiceId: props.invoiceId,
                jobId: selectedJob.jobId,
                supplierId: selectedJob.supplierId,
                chargeGst: selectedJob.supplierRegisteredForGst,
                purchaseOrders: selectedPos,
                subId: user.sub
            };

            //save po details to invoice
            const token = await authService.getAccessToken();
            axios.post('Suppliers/InvoiceAssignPurchaseOrder', result, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });

                } else {
                    setSearchFields({ poSearch: 0, jobIdSearch: 0, siteAddressSearch: "", supplierIdSearch: -1, noPoSearch: false });
                    setShowResults(false);
                    setSearchResults([]);
                    
                    if (selectedJob) {
                        var poNumbers = "";
                        var poNumber = 0;
                        var subTotal = 0;
                        var gst = 0;
                        var total = 0;
                        var subTotalRemaining = 0;
                        var gstRemaining = 0;
                        var totalRemaining = 0;
                        for (var x = 0; x < selectedPos.length; x++) {
                            poNumber = poNumber > 0 ? poNumber : selectedPos[x].purchaseOrderId;    //set po to first po
                            poNumbers += poNumbers == "" ? selectedPos[x].purchaseOrderId : ", " + selectedPos[x].purchaseOrderId;
                            subTotal += selectedPos[x].subTotal;
                            gst += selectedPos[x].gst;
                            total += selectedPos[x].total;
                            subTotalRemaining += selectedPos[x].remainingSubTotal;
                            gstRemaining += selectedPos[x].remainingGst;
                            totalRemaining += selectedPos[x].remainingTotal;
                        }
                        //format the totals
                        var subTotalFormatted = subTotal.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });
                        var gstFormatted = gst.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });
                        var totalFormatted = total.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });
                        var subTotalRemainingFormatted = subTotalRemaining.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });
                        var gstRemainingFormatted = gstRemaining.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });
                        var totalRemainingFormatted = totalRemaining.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });

                        let poResult: SupplierResult = {
                            invoiceId: props.invoiceId,
                            jobId: selectedJob.jobId,
                            siteAddress: selectedJob.siteAddress,
                            supplierId: selectedJob.supplierId,
                            supplier: selectedJob.supplier,
                            supplierRegisteredForGst: selectedJob.supplierRegisteredForGst,
                            xeroContactId: selectedJob.xeroContactId,
                            xeroContactName: selectedJob.xeroContactName,
                            purchaseOrderNumber: poNumber,
                            purchaseOrderNumbers: poNumbers,
                            purchaseOrders: selectedPos,
                            poSubTotal: subTotal,
                            poSubTotalFormatted: subTotalFormatted,
                            poGst: gst,
                            poGstFormatted: gstFormatted,
                            poTotal: total,
                            poTotalFormatted: totalFormatted,
                            poSubTotalRemaining: subTotalRemaining,
                            poSubTotalRemainingFormatted: subTotalRemainingFormatted,
                            poGstRemaining: gstRemaining,
                            poGstRemainingFormatted: gstRemainingFormatted,
                            poTotalRemaining: totalRemaining,
                            poTotalRemainingFormatted: totalRemainingFormatted
                        };

                        props.saveModal(poResult, res.data.duplicateInvoice);
                        toast.success("Purchase order/s saved to Invoice");
                    }
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        } else {
            toast.error("Select the purchase order/s associated with this invoice");
        }
    }

    const hideModal = () => {
        setSearchFields({ poSearch: 0, jobIdSearch: 0, siteAddressSearch: "", supplierIdSearch: -1, noPoSearch: false });
        setShowResults(false);
        setSearchResults([]);
        props.hideModal();
    }

    const updateSearchResults = (results: SearchResult[]) => {
        setSearchResults(results);
    }

    let jobId = props.jobId > 0 ? props.jobId : searchFields.jobIdSearch;
    let supplierId = searchFields.supplierIdSearch > 0 || resetSupplier ? searchFields.supplierIdSearch : props.supplierId;

    let results = <SupplierInvoicePoSearchResults searchResults={searchResults} invoiceTotal={props.invoiceTotal} showNoPoSearch={searchFields.noPoSearch} updateResults={updateSearchResults} />

    return (
        <Modal size="lg" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Search for Purchase Order</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div className="makeitflexspacebetween">
                    <div>
                        <label className="input-group" htmlFor="poSearch">
                            <span className="label-small">Order #</span>
                            <input className="input" type="number" id="poSearch" name="poSearch" value={searchFields.poSearch} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleKeyUp(e)}></input>
                        </label>
                        <label className="input-group" htmlFor="jobIdSearch">
                            <span className="label-small">Job Id</span>
                            <input className="input" type="number" id="jobIdSearch" name="jobIdSearch" value={jobId} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleKeyUp(e)} disabled={props.jobId > 0}></input>
                        </label>
                    </div>
                    <div>
                        <label className="input-group" htmlFor="supplierIdSearch">
                            <span className="label-small">Supplier</span>
                            <select className="select supplier-import-posearch--width" id="supplierIdSearch" name="supplierIdSearch" value={supplierId} onChange={(e) => handleChange(e)} >
                                <option defaultValue="-1" value="-1"></option>
                                {props.suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                )};
                            </select>
                        </label>
                        <label className="input-group" htmlFor="siteAddressSearch">
                            <span className="label-small">Site Address</span>
                            <input className="input supplier-import-posearch--width" type="text" id="siteAddressSearch" name="siteAddressSearch" value={searchFields.siteAddressSearch} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleKeyUp(e)}></input>
                        </label>
                    </div>
                </div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="noPoSearch">
                        <span className="label">No PO - e.g. Roof Report</span>
                        <input className="checkbox" type="checkbox" id="noPoSearch" name="noPoSearch" checked={searchFields.noPoSearch} onChange={(e) => handleCheckboxChange(e)} onKeyUp={(e) => handleKeyUp(e)}></input>
                    </label>
                </div>
                <div className="input-group-parent">
                    
                </div>
                <button className="defaultbutton-prev" type='button' onClick={search}>Search</button>
                <hr></hr>
                <div className={showResults ? "" : "hidden"}>
                    {results}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={save}>Save</button>
            </Modal.Footer>
        </Modal>
    );
    
}

export default SupplierInvoicePOSearchPopup;
import * as React from 'react';

type JobDetails = {
    id: number;
    jobTypeId: number;
    jobSubTypeId: number;
    siteAddress: string;
    siteAddressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    receivedDate: string;
    receivedTime: string;
    siteVisitDate: string;
    siteVisitTime: string;
    insuredContactDate: string;
    insuredContactTime: string;
    jobAcknowledgementDateTime: string;
    contractPrice: string;
    completePercentage: string;
    completedDate: string;
    makeSafe: boolean;
    reportOnly: boolean;
    copyEstimate: boolean;
    templateJob: boolean;
    status: string;
    statusId: number;
}

type JobCustomerDetails = {
    companyName: string;
    customerRegisteredForGst: boolean;
    customerName: string;
    propertyManager: string;
    address: string;
    addressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    customerGooglePlaceId: string;
    customerAddressLatitude: number;
    customerAddressLongitude: number;
    phone: string;
    customerPhone: string;
    email: string;
}

type JobClientDetails = {
    isTbaClient: boolean;
    insurerId: number;
    insurerContactId: number;
    insurerPhoneNumber: string;
    insurerMobileNumber: string;
    claimNumber: string;
    claimStatusId: number;
    claimTypeId: number;
    excessAmount: number;
    adjusterId: number;
    adjusterContactId: number;
    adjusterPhoneNumber: string;
    adjusterMobileNumber: string;
    adjusterReferenceNumber: string;
    brokerId: number;
    brokerContactId: number;
    brokerReferenceNumber: string;
}

type JobInsurerClaimLine = {
    id: number;
    insurerId: number;
    claimNumber: string;
    splitPercentage: number;
    status: number;
}

type JobResources = {
    managerId: number;
    estimatorId: number;
    supervisorId: number;
}

type Job = {
    jobDetails: JobDetails;
    customerDetails: JobCustomerDetails;
    clientDetails: JobClientDetails;
    multipleInsurers: JobInsurerClaimLine[];
    resources: JobResources;
    isLoading: boolean;
    error?: string;
}

export const InitialJob: Job = {
    jobDetails: {
        id: 0,
        jobTypeId: -1,
        jobSubTypeId: -1,
        siteAddress: "",
        siteAddressUnitNumber: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        receivedDate: "",
        receivedTime: "",
        siteVisitDate: "",
        siteVisitTime: "",
        insuredContactDate: "",
        insuredContactTime: "",
        jobAcknowledgementDateTime: "",
        contractPrice: "",
        completedDate: "",
        completePercentage: "",
        makeSafe: false,
        reportOnly: false,
        copyEstimate: false,
        templateJob: false,
        status: "Registered",
        statusId: 1
    },
    customerDetails: {
        companyName: "",
        customerRegisteredForGst: false,
        customerName: "",
        propertyManager: "",
        address: "",
        addressUnitNumber: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        postCode: "",
        country: "",
        customerGooglePlaceId: "",
        customerAddressLatitude: 0,
        customerAddressLongitude: 0,
        phone: "",
        customerPhone: "",
        email: ""
    },
    clientDetails: {
        isTbaClient: false,
        insurerId: -1,
        insurerContactId: -1,
        insurerMobileNumber: "",
        insurerPhoneNumber: "",
        claimNumber: "",
        claimStatusId: 1,
        claimTypeId: -1,
        excessAmount: 0,
        adjusterId: -1,
        adjusterContactId: -1,
        adjusterMobileNumber: "",
        adjusterPhoneNumber: "",
        adjusterReferenceNumber: "",
        brokerId: -1,
        brokerContactId: -1,
        brokerReferenceNumber: ""
    },
    multipleInsurers: [{
        id: 0,
        insurerId: 0,
        claimNumber: "",
        splitPercentage: 0,
        status: 1
    }],
    resources: {
        managerId: -1,
        estimatorId: -1,
        supervisorId: -1
    },
    isLoading: false,
    error: ""
}

type Action =
    | { type: 'getDetails' }
    | { type: 'updateDetails', jobDetails: JobDetails }
    | { type: 'updateCustomer', customerDetails: JobCustomerDetails }
    | { type: 'updateClient', clientDetails: JobClientDetails }
    | { type: 'updateMultipleInsurers', multipleInsurers: JobInsurerClaimLine[] }
    | { type: 'updateResources', resources: JobResources }
    | { type: 'reset' }
    | { type: 'error', error: "" };

export const JobDetailsReducer = (job: Job = InitialJob, action: Action): Job => {
    switch (action.type) {
        case 'getDetails':
            return { ...job, isLoading: true };
        case 'updateDetails':
            return { ...job, isLoading: false, jobDetails: action.jobDetails };
        case 'updateCustomer':
            return { ...job, isLoading: false, customerDetails: action.customerDetails };
        case 'updateClient':
            return { ...job, isLoading: false, clientDetails: action.clientDetails };
        case 'updateMultipleInsurers':
            return { ...job, isLoading: false, multipleInsurers: action.multipleInsurers };
        case 'updateResources':
            return { ...job, isLoading: false, resources: action.resources };
        case 'reset':
            return InitialJob;
        case 'error':
            return { ...job, isLoading: false, error: action.error };
        default:
            return job;
    }
}



import JobPurchaseOrderListData from './JobPurchaseOrderList';
import JobPurchaseOrderDetailsData from './JobPurchaseOrderDetails';

interface JobPurchaseOrderProps {
    jobId: number;
    isMakeSafe: boolean;
    canEdit: boolean;
    canViewReport: boolean;
    canAddNewSuppliers: boolean;
    checkSupplierCertificate: boolean;
    showBackConfirm: boolean;
    id: number;
    page: string;
    changePage(id: number, page: string): void; 
    checkHow(): void;
    goBackConfirm(): void;
    updateShowBackConfirm(showBackConfirm: boolean): void;
}

const JobPurchaseOrdersData = (props: JobPurchaseOrderProps) => {

    const open = (id: number) => {
        props.changePage(id, "details");
    }

    const checkHow = () => {
        props.checkHow();
    }

    const goBackConfirm = () => {
        props.goBackConfirm();
    }

    var contents;
    if (props.page === "list") {
        contents = <JobPurchaseOrderListData jobId={props.jobId} canEdit={props.canEdit} isMakeSafe={props.isMakeSafe} canViewReport={props.canViewReport} open={open} checkHow={checkHow} />;
    }
    else if (props.page === "details" || props.page === "confirm") {
        contents = <JobPurchaseOrderDetailsData id={props.id} jobId={props.jobId} canEdit={props.canEdit} canViewReport={props.canViewReport} canAddNewSuppliers={props.canAddNewSuppliers} checkSupplierCertificate={props.checkSupplierCertificate} showBackConfirm={props.showBackConfirm} goBackConfirm={goBackConfirm} updateShowBackConfirm={props.updateShowBackConfirm} />
    }

    return (
        <div>
            {contents}
        </div>
    )
    
}

export default JobPurchaseOrdersData;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface GenericList {
    id: number;
    name: string;
}

const ReportJobTenderData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [insurers, setInsurers] = React.useState<GenericList[]>([]);
    const [estimators, setEstimators] = React.useState<GenericList[]>([]);
    const [filter, setFilter] = React.useState({ insurerId: -1, estimatorId: -1 });

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async() => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunJobTenderingReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Report/GetJobTenderingReportDropdowns?subId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setInsurers(res.data.insurers);
                    setEstimators(res.data.estimators);
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied")
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any) => {
        setFilter(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data
        var url = "/report/JobTender/" + filter.insurerId + "/" + filter.estimatorId + "/0";
        window.open(url, '_blank');
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        exportToExcel();
    }

    const exportToExcel = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios('Report/ExportJobTenderData?insurerId=' + filter.insurerId + "&estimatorId=" + filter.estimatorId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Tendering Jobs.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Job Tendering Report</h1>
            </div>
            <div className="marginTop20">
                <span>Select an Insurer and/or Estimator to filter report.</span>
            </div>
            <div>
                <label className="input-group" htmlFor="insurerId">
                    <span className="label">Insurer</span>
                    <select className="select" id="insurerId" name="insurerId" value={filter.insurerId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {insurers.map(insurer =>
                            <option key={insurer.id} value={insurer.id}>{insurer.name}</option>
                        )};
                    </select>
                </label>
                <label className="input-group" htmlFor="estimatorId">
                    <span className="label">Estimator</span>
                    <select className="select" id="estimatorId" name="estimatorId" value={filter.estimatorId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {estimators.map(estimator =>
                            <option key={estimator.id} value={estimator.id}>{estimator.name}</option>
                        )};
                    </select>
                </label>
                    
                <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportJobTenderData;
import * as React from 'react';
import { useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import SupplierDetail from './SupplierDetails';
import SupplierContact from './SupplierContacts';
import SupplierCertificates from './SupplierCertificates';
import authService from './api-authorization/AuthorizeService';
import supplierReducer from './SupplierReducer';

interface SupplierDetails {
    id: number;
    xeroContactId: string;
    xeroContactName: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    primaryTradeId: number;
    supplierTrades: GenericListItem[];
    phone: string;
    email: string;
    typeId: number;
    status: number;
    lockAccount: boolean;
    termsId: number;
}

interface SupplierContacts {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

interface SupplierCertificate {
    id: number;
    supplierId: number;
    typeId: number;
    type: string;
    certificateNumber: string;
    certificateName: string;
    expiryDate: string;
    sendExpiryNotification: boolean;
    insertedBy: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [trades, setTrades] = React.useState<GenericListItem[]>([]);
    const [otherTrades, setOtherTrades] = React.useState<GenericListItem[]>([]);
    const [certificateTypes, setCertificateTypes] = React.useState<GenericListItem[]>([]);
    const [termOptions, setTermOptions] = React.useState<GenericListItem[]>([]);
    const [supplierTypes, setSupplierTypes] = React.useState<GenericListItem[]>([]);
    const [statusOptions, setStatusOptions] = React.useState<GenericListItem[]>([]);
    const [tab, setTab] = React.useState("Details");

    const initialSupplierDetails: SupplierDetails = {
        id: 0,
        xeroContactId: "",
        xeroContactName: "",
        name: "",
        addressManual: false,
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        registeredForGst: false,
        abn: "",
        primaryTradeId: -1,
        supplierTrades: [],
        phone: "",
        email: "",
        typeId: -1,
        status: 1,
        lockAccount: false,
        termsId: 1
    }

    const initialSupplierContacts: SupplierContacts[] = [{
        id: 0,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    }];

    const [{
        supplierDetails,
        supplierContacts,
        supplierCertificates,
        isLoading,
    }, dispatch] = useReducer(supplierReducer, { supplierDetails: initialSupplierDetails, supplierContacts: initialSupplierContacts, supplierCertificates: [], isLoading: false });

    React.useEffect(() => {
        let Id = Number(id);

        if (Id > 0) {
            getData(Id);
        }
    }, []);

    const getData = async(Id: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        dispatch({ type: "getDetails" });   //set loading to true

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateEditSuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get user roles and status
                axios.get('Suppliers/GetDropdowns', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setTrades(res.data.trades);
                    setOtherTrades(res.data.trades);
                    setCertificateTypes(res.data.certificateTypes);
                    setStatusOptions(res.data.statusOptions);
                    setTermOptions(res.data.termOptions);
                    setSupplierTypes(res.data.supplierTypes);

                    //only get supplier details if we have searched for a supplier not creating a new one
                    axios.get('Suppliers/GetSupplierDetails?Id=' + Id, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                    .then(res => {
                        if (res.data.isError) {
                            var errors = res.data.messages as any[];
                            for (var i = 0; i < errors.length; i++) {
                                toast.error(errors[i].content);
                            }
                        } else {
                            var supplier = res.data;
                            var contacts = supplier.contacts;
                            contacts.push(getBlankContact());
                            var certificates = supplier.certificates;

                            dispatch({ type: "updateDetails", supplierDetails: supplier });
                            dispatch({ type: "updateContacts", supplierContacts: contacts });
                            dispatch({ type: "updateCertificates", supplierCertificates: certificates });
                        }
                    })
                    .catch(error => {
                        toast.error(error.message);
                    });
                    
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getBlankContact = () => {
        var contact: SupplierContacts = {
            id: 0,
            firstName: "",
            lastName: "",
            phone: "",
            mobile: "",
            email: ""
        };
        return contact;
    }

    const tabSelected = (tab: string) => {
        setTab(tab);
    }

    const tabChanged = (e: any) => {
        tabSelected(e.target.value);
    }

    const updateDetails = (supplierDetails: SupplierDetails) => {
        dispatch({ type: "updateDetails", supplierDetails: supplierDetails });
    }

    const updateContacts = (supplierContacts: SupplierContacts[]) => {
        dispatch({ type: "updateContacts", supplierContacts: supplierContacts });
    }

    const updateCertificates = (supplierCertificates: SupplierCertificate[]) => {
        dispatch({ type: "updateCertificates", supplierCertificates: supplierCertificates });
    }

    const sendToXero = () => {
        //save first
        save(true);
    }

    const sendSupplierToXero = async() => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            id: supplierDetails.id,
            page: "supplier",
            subId: user.sub
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //if authorised
                var contacts = supplierContacts;
                contacts.pop();

                var sendSupplier = {
                    supplierDetails: supplierDetails,
                    supplierContacts: contacts,
                    supplierCertificates: supplierCertificates,
                    subId: user.sub
                };

                axios.post('Xero/SendSupplierToXero', sendSupplier, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        toast.success("Supplier Saved and Sent To Xero");
                        navigate("/suppliersearch");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    const validateAll = () => {
        let formIsValid = true;
        var details = supplierDetails;
        if (!details.name || (details.registeredForGst === true && !details.abn) || !details.email || details.primaryTradeId < 0 || details.status < 0) {
            toast.error("Supplier Details must be completed!");
            formIsValid = false;
        }

        var contacts = supplierContacts;
        if (contacts.length < 2) {
            toast.error("Contacts - At least one contact is required");
            formIsValid = false;
        } else {
            for (var i = 0; i < (contacts.length - 1); i++) {
                let contactIsValid = true;
                if (!contacts[i].firstName || (!contacts[i].phone && !contacts[i].mobile) || !contacts[i].email) {
                    contactIsValid = false;
                }
                if (contactIsValid === false) {
                    formIsValid = false;
                    toast.error("Contacts - Name, Mobile and Email are required for every contact");
                }
            }
        }

        return formIsValid;
    }

    const save = (sendToXero: boolean) => {
        //validate all data before saving
        if (validateAll()) {
            saveSupplier(sendToXero);
        } else {
            toast.error("Please fix the validation issues before saving");
        }

    }

    const saveSupplier = async(sendToXero: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //only have one contact when adding
        var contacts = supplierContacts;
        contacts.pop();

        var saveSupplier = {
            supplierDetails: supplierDetails,
            supplierContacts: contacts,
            supplierCertificates: supplierCertificates,
            subId: user.sub
        };

        axios.post('Suppliers/Save', saveSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
            } else {
                if (sendToXero) {
                    sendSupplierToXero();
                } else {
                    toast.success("Supplier Saved");
                    navigate("/suppliersearch");
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var tabContents;
    if (tab === "Details") {
        tabContents = <SupplierDetail id={supplierDetails.id} supplierDetails={supplierDetails} statusOptions={statusOptions} termOptions={termOptions} trades={trades} otherTrades={otherTrades} supplierTypes={supplierTypes} update={updateDetails} save={save} sendToXero={sendToXero} />
    }
    else if (tab === "Contacts") {
        tabContents = <SupplierContact id={supplierDetails.id} supplierContacts={supplierContacts} showSave={true} update={updateContacts} save={save} />
    }
    else if (tab === "Certificates") {
        tabContents = <SupplierCertificates id={supplierDetails.id} certificateTypes={certificateTypes} supplierEmail={supplierDetails.email} supplierCertificates={supplierCertificates} update={updateCertificates} />
    }

    const renderDetails = (
        <div>
            <div className="tabsComponent" id="tab-container">
                <div className="tabsComponent__showDropdown">
                    <label className="input-group" htmlFor="userTab">
                        <span className="label">Section</span>
                        <select className="select" id="userTab" value={tab} onChange={tabChanged} >
                            <option value="Details">Details</option>
                            <option value="Contacts">Contacts</option>
                            <option value="Certificates">Certificates</option>
                        </select>
                    </label>
                </div>

                <div className="tabsComponent__tabs tabsComponent__hideTabs">
                    <input type="radio" name="tabs" id="tab1" className="tabsComponent__control" checked={tab === 'Details'} readOnly></input>
                    <label className="tabsComponent__label" htmlFor="tabItem1" onClick={() => tabSelected('Details')}>Details</label>
                    <input type="radio" name="tabs" id="tab2" className="tabsComponent__control" checked={tab === 'Contacts'} readOnly></input>
                    <label className="tabsComponent__label" htmlFor="tabItem2" onClick={() => tabSelected('Contacts')}>Contacts</label>
                    <input type="radio" name="tabs" id="tab3" className="tabsComponent__control" checked={tab === 'Certificates'} readOnly></input>
                    <label className="tabsComponent__label" htmlFor="tabItem3" onClick={() => tabSelected('Certificates')}>Certificates</label>
                </div>

                <div className="tabsComponent__content tabsComponent__content--selected">
                    <div>
                        {tabContents}
                    </div>
                </div>

            </div>
        </div>
    );
    
    let contents = isLoading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            <h1>Supplier</h1>
            {contents}
        </div>
    )
}

export default SupplierData;
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import JobTabsMobile from './JobTabsMobile';
import EstimateHeaderMobileData from './JobEstimateMobileHeader';
import EstimateDetailMobileData from './JobEstimateMobileDetails';

interface Estimate {
    id: number;
    estimateHeaders: EstimateHeaders[]
    subTotalFormatted: string;
    markUpPercentageFormatted: string;
    subTotalExGstFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
}

interface EstimateHeaders {
    id: number;
    area: string;
    areaOrder: number;
    amount: number;
    amountFormatted: string;
    amountExGstFormatted: string;
}

interface PageChangeData {
    page: string;
    headerId: number;
    headerText: string;
}

const EstimateMobileData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState({ canViewEstimate: false, canViewCostings: false, showAssessment: false });
    const [pageData, setPageData] = React.useState({ page: "header", headerId: 0, headerText: "" });
    const [estimate, setEstimate] = React.useState<Estimate>({
        id: 0,
        estimateHeaders: [],
        subTotalFormatted: "$0.00",
        markUpPercentageFormatted: "0%",
        subTotalExGstFormatted: "$0.00",
        gstFormatted: "$0.00",
        totalFormatted: "$0.00"
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
        let Id = Number(id);
        if (!Id) { Id = 0; }

        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=ViewJobEstimate', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get user roles and status
                axios.get('Estimate/GetEstimateForMobile?JobId=' + Id + "&SubId=" + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                        setEstimate(res.data.estimate);
                        setPermissions({ canViewEstimate: res.data.canViewEstimate, canViewCostings: res.data.canViewCostings, showAssessment: res.data.showAssessment });
                        setLoading(false);
                    }
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //move between headers and detail pages
    const pageChanged = (pageData: PageChangeData) => {
        setPageData({ page: pageData.page, headerId: pageData.headerId, headerText: pageData.headerText });
    }

    //job tab mobile - different page selected
    const tabChanged = (url: string) => {
        navigate(url);
    }

    var jobTabsMobile = <JobTabsMobile id={jobData.jobId} tab="estimate" canViewEstimate={permissions.canViewEstimate} canViewCostings={permissions.canViewCostings} showAssessment={permissions.showAssessment} tabChanged={tabChanged} />

    var tabContents;
    if (pageData.page === "header") {
        tabContents = <EstimateHeaderMobileData siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} estimate={estimate} changePage={pageChanged} />
    }
    else if (pageData.page === "details") {
        tabContents = <EstimateDetailMobileData jobId={jobData.jobId} headerId={pageData.headerId} headerText={pageData.headerText} changePage={pageChanged} />
    }

    const renderDetails = (
        <div>
            <div className="marginTop10 marginBottom70">
                {tabContents}
            </div>
            <div>
                {jobTabsMobile}
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default EstimateMobileData;
import * as React from 'react';
import logo from '../images/SkylineLogo.jpg';
import { LoginMenu } from './api-authorization/LoginMenu';

const TopMenu = (props: {}) => {
    return (
        <div className="top-nav">
            <div><a href="/"><img className="logo" src={logo}></img></a></div>
            <LoginMenu></LoginMenu>
        </div>
    )    
}

export default TopMenu;
import * as React from 'react';
import { Container } from 'reactstrap';
import TopMenu from './TopMenu';
import NavMenu from './NavMenu';
import { ToastContainer } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface LayoutProps {
    children: React.ReactNode;
}

interface LayoutData {
    isAuthenticated: boolean;
    loading: boolean;
}

export class Layout extends React.Component<LayoutProps, LayoutData> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            isAuthenticated: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const isAuthenticated = await authService.isAuthenticated();

        this.setState({ isAuthenticated: isAuthenticated, loading: false });
    }

    public render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderDetails();

        return <div>
            {contents}
        </div>;
    }

    renderDetails = () => {
        return (
            <div>
                <TopMenu />
                <div className={this.state.isAuthenticated ? "" : "hidden"}>
                    <NavMenu />
                </div>
                <Container className={this.state.isAuthenticated ? "" : "fullContainer"}>
                    {this.props.children}
                    <div>
                        <ToastContainer theme="colored" />
                    </div>
                </Container>
            </div>
        );
    }
}
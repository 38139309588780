import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface ExceedForecastProps {
    exceedForecast: ExceedForecast[];
    costCodeList: GenericListItem[],
    showModal: boolean;
    cancelModal(): void;
    update(exceedForecast: ExceedForecast[]): void;
    saveModal(): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface ExceedForecast {
    costCodeId: number;
    exceedForecastReason: string;
}

const ExceedForecastsPopup = (props: ExceedForecastProps) => {
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleCellChange = (index: number, e: any) => {
        var exceed = [...props.exceedForecast];
        var item = exceed[index];

        let exist: any = {};
        exist = item;
        exist[e.target.name] = e.target.value;

        setSaveDisabled(false);
        props.update(exceed);
    }

    const cancelModal = () => {
        props.cancelModal();
    }

    const validate = () => {
        var formIsValid = true;
        for (var i = 0; i < props.exceedForecast.length; i++) {
            if (!props.exceedForecast[i].exceedForecastReason) {
                formIsValid = false;
                setError(true);
            }
        }

        return formIsValid;
    } 

    const saveModal = () => {
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            props.saveModal();
        }
    }

    return (
        <Modal size="lg" show={props.showModal} onHide={cancelModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Exceed Forecast</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={cancelModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Enter reason for exceeding forecast</p>
                    {props.exceedForecast.map((detail, index) =>
                        <div key={detail.costCodeId} className="marginBottom10">
                            <select className="select" name="costCodeId" value={detail.costCodeId} disabled>
                                <option hidden defaultValue="-1"></option>
                                {props.costCodeList.map(item =>
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )};
                            </select>
                            <input type='text' className="input jobpo__exeedReasonSize" id="exceedForecastReason" name="exceedForecastReason" value={detail.exceedForecastReason} onChange={(e) => handleCellChange(index, e)} />
                        </div>
                    )}
                    <span className={error ? "label errors errors__leftmargin" : "hidden"}>Enter a reason for each cost code!</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={cancelModal}>Cancel</button>
                <button className="popup-button--yes" onClick={saveModal} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )
}

export default ExceedForecastsPopup;
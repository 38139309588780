import * as React from 'react';

interface SupplierDetails {
    id: number;
    xeroContactId: string;
    xeroContactName: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    primaryTradeId: number;
    supplierTrades: GenericListItem[];
    phone: string;
    email: string;
    typeId: number;
    status: number;
    lockAccount: boolean;
    termsId: number;
}

interface SupplierContacts {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

interface SupplierCertificate {
    id: number;
    supplierId: number;
    typeId: number;
    type: string;
    certificateNumber: string;
    certificateName: string;
    expiryDate: string;
    sendExpiryNotification: boolean;
    insertedBy: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

type Supplier = {
    supplierDetails: SupplierDetails;
    supplierContacts: SupplierContacts[];
    supplierCertificates: SupplierCertificate[];
    isLoading: boolean;
    error?: string;
}

const initialSupplier: Supplier = {
    supplierDetails: {
        id: 0,
        xeroContactId: "",
        xeroContactName: "",
        name: "",
        addressManual: false,
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        registeredForGst: false,
        abn: "",
        primaryTradeId: -1,
        supplierTrades: [],
        phone: "",
        email: "",
        typeId: -1,
        status: 1,
        lockAccount: false,
        termsId: 1
    },
    supplierContacts: [{
        id: 0,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    }],
    supplierCertificates: [],
    isLoading: false,
    error: ""
}

type Action =
    | { type: 'getDetails' }
    | { type: 'updateDetails', supplierDetails: SupplierDetails }
    | { type: 'updateContacts', supplierContacts: SupplierContacts[] }
    | { type: 'updateCertificates', supplierCertificates: SupplierCertificate[] }
    | { type: 'reset' }
    | { type: 'error', error: "" };

const supplierReducer = (supplier: Supplier = initialSupplier, action: Action): Supplier => {
    switch (action.type) {
        case 'getDetails':
            return { ...supplier, isLoading: true };
        case 'updateDetails':
            return { ...supplier, isLoading: false, supplierDetails: action.supplierDetails };
        case 'updateContacts':
            return { ...supplier, isLoading: false, supplierContacts: action.supplierContacts };
        case 'updateCertificates':
            return { ...supplier, isLoading: false, supplierCertificates: action.supplierCertificates };
        case 'reset':
            return initialSupplier;
        case 'error':
            return { ...supplier, isLoading: false, error: action.error };
        default:
            return supplier;
    }
}

export default supplierReducer; 
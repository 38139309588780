import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobTabs from './JobTabs';
import JobInvoiceListData from './JobInvoiceList';
import JobInvoiceDetailData from './JobInvoiceDetails';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

interface PageChangeData {
    id: number,
    page: string
}

const JobInvoiceData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [canEdit, setCanEdit] = React.useState(true);
    const [pageData, setPageData] = React.useState({ id: 0, page: "List" });
    
    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //save an audit trail note of who has accessed job details
        var auditTrail = {
            typeId: 7,
            linkId: Id,
            subId: user.sub
        };

        axios.post('Shared/SaveAuditTrail', auditTrail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //not doing anything if the audit trail doesn't work
        });

        //check permissions and get dropdowns
        axios.get('Users/CheckUserInvoicePermission?SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get invoice list
                //get user permissions and dropdowns
                axios.get('Jobs/GetInvoiceScreenData?SubId=' + user.sub + '&JobId=' + Id, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        setCanEdit(res.data.canEdit);
                        setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                        setPermissions(res.data.userJobPermissions);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const tabChanged = (url: string) => {
        //upper tab changed - details, estimate, costings etc
        navigate(url);
    }

    const pageChanged = (pageChangeData: PageChangeData) => {
        if (pageChangeData.page === "List") {
            setPageData({ id: pageChangeData.id, page: pageChangeData.page });
        } else {
            checkXeroAuth(pageChangeData);
        }
    }

    const checkXeroAuth = async (pageChangeData: PageChangeData) => {
        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "invoices",
            id: jobData.jobId
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //can send invoice
                setPageData({ id: pageChangeData.id, page: pageChangeData.page });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    var tabContents;
    if (pageData.page === "List") {
        tabContents = <JobInvoiceListData jobId={jobData.jobId} canEdit={canEdit} changePage={pageChanged} />
    } else {
        tabContents = <JobInvoiceDetailData jobId={jobData.jobId} id={pageData.id} showAddress={false} changePage={pageChanged} />
    }
    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="invoices" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />

    const renderDetails = (
        <div>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                {tabContents}
            </div>
        </div>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobInvoiceData;
import * as React from 'react';
import { useReducer } from 'react';
import GoogleAutoComplete from './GoogleAutoComplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import clientReducer from './ClientReducer';

interface ClientAddProps {
    types: GenericListItem[];
    terms: GenericListItem[];
    backToSearch(clientId: number): void;
}

interface XeroListItem {
    id: string;
    name: string;
    totalFormatted: string;
    selected: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

type ClientDetails = {
    id: number;
    xeroContactId: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    typeId: number;
    phone: string;
    termId: number;
    useOpus: boolean;
    maxMarginPercent: number;
    status: number;
    lockAccount: boolean;
}

type ClientContacts = {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

const ClientAddDetails = (props: ClientAddProps) => {
    const [addXeroContact, setAddXeroContact] = React.useState(false);
    const [xeroNameSearch, setXeroNameSearch] = React.useState("");
    const [xeroCustomers, setXeroCustomers] = React.useState<XeroListItem[]>([]);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const initialClientDetails: ClientDetails = {
        id: 0,
        xeroContactId: "",
        name: "",
        addressManual: false,
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        registeredForGst: false,
        abn: "",
        typeId: -1,
        phone: "",
        termId: -1,
        useOpus: false,
        maxMarginPercent: 0,
        status: 1,
        lockAccount: false
    };

    const initialClientContacts: ClientContacts[] = [{
        id: 0,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    }];

    const [{
        clientDetails,
        clientContacts,
        isLoading
    }, dispatch] = useReducer(clientReducer, { clientDetails: initialClientDetails, clientContacts: initialClientContacts, isLoading: false });

    const updateClientDetails = (updatedClientDetails: ClientDetails) => {
        dispatch({ type: "updateDetails", clientDetails: updatedClientDetails });
    }

    const updateClientContacts = (updatedClientContacts: ClientContacts[]) => {
        dispatch({ type: "updateContacts", clientContacts: updatedClientContacts });
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        let newClient: any = {};
        newClient = clientDetails;
        newClient[e.target.name] = e.target.value;

        updateClientDetails(newClient);
    }

    const handleContactChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        var contacts = clientContacts;
        let contact = contacts[0];
        let newContact: any = {};
        newContact = contact;
        newContact[e.target.name] = e.target.value;

        updateClientContacts(contacts);
    }

    const handleCellCheckboxChange = (index: number, e: any) => {
        //if selected checked then set all other customers to false
        if (e.target.checked) {
            for (var i = 0; i < xeroCustomers.length; i++) {
                xeroCustomers[i].selected = false;
            }
        } 

        var customers = [...xeroCustomers]
        var xeroCustomer = customers[index];
        let exist: any = {};
        exist = xeroCustomer;
        exist[e.target.name] = e.target.checked;

        setXeroCustomers(customers);
    }

    const handleCheckboxChange = (e: any) => {
        let newClient: any = {};
        newClient = clientDetails;
        newClient[e.target.name] = e.target.checked;
        updateClientDetails(newClient);
    }

    //update google address
    const setClientAddress = (addressDetails: AddressDetails) => {
        delete errors["address"];

        setErrors(errors);
        updateAddress(addressDetails);
    }

    const updateAddress = (addressDetails: AddressDetails) => {
        var client = clientDetails;
        client.address = addressDetails.fullAddress;
        client.streetNumber = addressDetails.street_number;
        client.streetName = addressDetails.route;
        client.suburb = addressDetails.locality;
        client.state = addressDetails.administrative_area_level_1;
        client.country = addressDetails.country;
        client.postCode = addressDetails.postal_code;
        client.googlePlaceId = addressDetails.placeId;
        client.siteAddressLatitude = addressDetails.latitude;
        client.siteAddressLongitude = addressDetails.longitude;
        updateClientDetails(client);
    }

    const searchXeroCustomers = (e: any) => {
        e.preventDefault();
        searchXeroClientCustomers();
    }

    const searchXeroClientCustomers = async () => {
        if (xeroNameSearch) {
            //check if we need to check Xero Auth
            //check authentication
            //enter the page that xero needs to return to, id blank as not used via invoicing
            var page = {
                page: "client",
                id: 0
            };
            //encode to base 64 so can retrieve it later
            var encoded = btoa(JSON.stringify(page));

            const user = await authService.getUser();
            const token = await authService.getAccessToken();

            axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var isAuth = res.data.isAuthorised;
                var xeroLoginUrl = res.data.xeroLoginUrl;

                if (isAuth) {
                    //if authorised
                    axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + xeroNameSearch, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                        .then(res => {
                            var xeroCustomerList = [...xeroCustomers];
                            var customers = res.data;
                            for (var i = 0; i < customers.length; i++) {
                                var customer = customers[i];
                                customer.selected = false;
                                xeroCustomerList.push(customer);
                            }

                            setXeroCustomers(xeroCustomerList);

                            if (customers.length === 0) {
                                toast.info("There were no Xero customers that matched your search.  Note: Xero search is case sensitive!");
                            }
                        })
                        .catch(error => {
                            toast.error(error.message);
                        });
                } else {
                    window.location.replace(xeroLoginUrl)
                }
            });
        } else {
            let errors: any = {};
            errors["xeroNameSearch"] = "Enter name to search";
            setErrors(errors);
        }
    }

    const validate = () => {
        let fields = clientDetails;
        let errors: any = {};
        let formIsValid = true;

        if (!fields.name) {
            formIsValid = false;
            errors["name"] = "Name is required";
        }

        if (fields.addressManual) {
            if (!fields.streetName) {
                formIsValid = false;
                errors["streetName"] = "Street Name is required";
            }

            if (!fields.suburb) {
                formIsValid = false;
                errors["suburb"] = "Suburb is required";
            }

            if (!fields.state) {
                formIsValid = false;
                errors["state"] = "State is required";
            }

            if (!fields.postCode) {
                formIsValid = false;
                errors["postCode"] = "Post Code is required";
            }
        } else {
            if (!fields.address) {
                formIsValid = false;
                errors["address"] = "Address is required";
            }
        }

        if (fields.registeredForGst === true && !fields.abn) {
            formIsValid = false;
            errors["abn"] = "ABN is required";
        }
        if (fields.typeId < 0) {
            formIsValid = false;
            errors["typeId"] = "Client Type is required";
        }
        if (fields.termId < 0) {
            formIsValid = false;
            errors["termId"] = "Terms are required";
        }
        if (fields.status < 0) {
            formIsValid = false;
            errors["status"] = "Status is required";
        }

        var contact = clientContacts[0];
        if (!contact.firstName) {
            formIsValid = false;
            errors["firstName"] = "First Name is required";
        }

        if (!contact.lastName) {
            formIsValid = false;
            errors["lastName"] = "Last Name is required";
        }

        if (!contact.phone && !contact.mobile) {
            formIsValid = false;
            errors["contactphone"] = "Phone or Mobile is required";
        }

        if (!contact.email) {
            formIsValid = false;
            errors["contactemail"] = "Email is required";
        }

        setErrors(errors);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {

            var xeroContacts = xeroCustomers.filter(x => x.selected);
            if (xeroContacts.length > 0) {
                //need to update the contact details with the xero contact id
                var client = clientDetails;
                client.xeroContactId = xeroContacts[0].id;
                updateClientDetails(client);
            }
            var sendToXero = addXeroContact;
            saveClient(sendToXero);
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveClient = async (sendToXero: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let client = clientDetails;

        var saveClient = {
            client: client,
            contacts: clientContacts,
            subId: user.sub
        };

        axios.post('Clients/Save', saveClient, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                let clientId = res.data.id;
                if (sendToXero) {
                    client.id = clientId;
                    updateClientDetails(client);
                    sendClientToXero(client.id);
                } else {
                    toast.success("Client Saved");
                    dispatch({ type: "reset" });
                    props.backToSearch(clientId);
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const sendClientToXero = async (clientId: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "client",
            id: clientId
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        var saveClient = {
            client: clientDetails,
            contacts: clientContacts,
            subId: user.sub
        };

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //if authorised
                axios.post('Clients/SendToXero', saveClient, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        toast.success("Client Saved and Sent To Xero");
                        dispatch({ type: "reset" });
                        props.backToSearch(clientId);
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    let contact = clientContacts[0];    //only 1 contact when adding
    var googleClientAddress = <GoogleAutoComplete addressId="autocompleteaddclient" addressLabel={"Address"} placeholder={clientDetails.address} className="input-group" disabled={false} save={setClientAddress} />

    return (
        <form name="client" onSubmit={save}>
            <div>
                <h4>Client Details</h4>
                <label className="input-group" htmlFor="name">
                    <span className="label">Name</span>
                    <input className="input" type="text" maxLength={200} id="name" name="name" value={clientDetails.name} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["name"] ? "label errors errors__leftmargin" : "hidden"}>{errors["name"]}</span>

                <label className="input-group" htmlFor="addXeroContact">
                    <span className="label">Add Client to Xero?</span>
                    <input className="checkbox" type="checkbox" id="addXeroContact" name="addXeroContact" checked={addXeroContact} onChange={(e) => setAddXeroContact(e.target.checked)}></input>
                </label>

                <label className="input-group" htmlFor="xeroNameSearch">
                    <span className="label">Xero Name Search</span>
                    <input className='input marginRight10' type='text' id="xeroNameSearch" name="xeroNameSearch" value={xeroNameSearch} onChange={(e) => setXeroNameSearch(e.target.value)}></input>
                    <button className="defaultbutton defaultbutton__xsmall marginBottom5" type="button" onClick={searchXeroCustomers}>Search</button>
                </label>
                <span className={errors["xeroNameSearch"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroNameSearch"]}</span>

                <div className={xeroCustomers.length === 0 ? "hidden" : ""}>
                    <div className='overflowAuto client__xeroTableSize'>
                        <table className="table--main table__small tableColours">
                            <thead>
                                <tr>
                                    <th className="hidden">Id</th>
                                    <th></th>
                                    <th>Name</th>
                                    <th className="textalignright">Outstanding</th>
                                </tr>
                            </thead>
                            <tbody>
                                {xeroCustomers.map((xeroCustomer, index) =>
                                    <tr key={index}>
                                        <td className="hidden">{xeroCustomer.id}</td>
                                        <td><input type="checkbox" className="input checkbox" name="selected" checked={xeroCustomer.selected} onChange={(e) => handleCellCheckboxChange(index, e)} /></td>
                                        <td>{xeroCustomer.name}</td>
                                        <td className="table__text--align textalignright">{xeroCustomer.totalFormatted}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <label className="input-group" htmlFor="addressManual">
                    <span className="label">Manual Address / PO Box</span>
                    <input className="checkbox" type="checkbox" id="addressManual" name="addressManual" checked={clientDetails.addressManual} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <div className={clientDetails.addressManual ? "hidden" : ""}>
                    {googleClientAddress}
                    <span className={errors["address"] ? "label errors errors__leftmargin" : "hidden"}>{errors["address"]}</span>
                </div>
                <div className={clientDetails.addressManual ? "" : "hidden"}>
                    <label className="input-group" htmlFor="streetName">
                        <span className="label">Street Name</span>
                        <input className="input" type="text" maxLength={100} id="streetName" name="streetName" value={clientDetails.streetName} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["streetName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["streetName"]}</span>

                    <label className="input-group" htmlFor="suburb">
                        <span className="label">Suburb</span>
                        <input className="input" type="text" maxLength={100} id="suburb" name="suburb" value={clientDetails.suburb} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["suburb"] ? "label errors errors__leftmargin" : "hidden"}>{errors["suburb"]}</span>

                    <label className="input-group" htmlFor="state">
                        <span className="label">State</span>
                        <select className="select" id="state" name="state" value={clientDetails.state} onChange={(e) => handleChange(e)} >
                            <option value=""></option>
                            <option value="VIC">VIC</option>
                            <option value="NSW">NSW</option>
                            <option value="QLD">QLD</option>
                            <option value="WA">WA</option>
                            <option value="SA">SA</option>
                            <option value="TAS">TAS</option>
                            <option value="ACT">ACT</option>
                            <option value="NT">NT</option>
                        </select>
                    </label>
                    <span className={errors["state"] ? "label errors errors__leftmargin" : "hidden"}>{errors["state"]}</span>

                    <label className="input-group" htmlFor="postCode">
                        <span className="label">Post Code</span>
                        <input className="input" type="text" maxLength={10} id="postCode" name="postCode" value={clientDetails.postCode} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["postCode"] ? "label errors errors__leftmargin" : "hidden"}>{errors["postCode"]}</span>
                </div>

                <label className="input-group" htmlFor="registeredForGst">
                    <span className="label">Registered For GST</span>
                    <input className="checkbox" type="checkbox" id="registeredForGst" name="registeredForGst" checked={clientDetails.registeredForGst} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="abn">
                    <span className="label">ABN</span>
                    <input className="input" type="text" maxLength={20} id="abn" name="abn" value={clientDetails.abn} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["abn"] ? "label errors errors__leftmargin" : "hidden"}>{errors["abn"]}</span>

                <label className="input-group" htmlFor="typeId">
                    <span className="label">Client Type</span>
                    <select className="select" id="typeId" name="typeId" value={clientDetails.typeId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {props.types.map(type =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["typeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["typeId"]}</span>

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className="input" type="text" maxLength={30} id="phone" name="phone" value={clientDetails.phone} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="termId">
                    <span className="label">Terms</span>
                    <select className="select" id="termId" name="termId" value={clientDetails.termId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {props.terms.map(term =>
                            <option key={term.id} value={term.id}>{term.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["termId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["termId"]}</span>

                <h4>Contact Details</h4>
                <label className="input-group" htmlFor="firstName">
                    <span className="label">First Name</span>
                    <input className='input' type='text' maxLength={100} id="firstName" name="firstName" value={contact.firstName} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["firstName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["firstName"]}</span>

                <label className="input-group" htmlFor="lastName">
                    <span className="label">Last Name</span>
                    <input className='input' type='text' maxLength={100} id="lastName" name="lastName" value={contact.lastName} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["lastName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["lastName"]}</span>

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className='input' type='text' maxLength={100} id="phone" name="phone" value={contact.phone} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <label className="input-group" htmlFor="mobile">
                    <span className="label">Mobile</span>
                    <input className='input' type='text' maxLength={100} id="mobile" name="mobile" value={contact.mobile} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["contactphone"] ? "label errors errors__leftmargin" : "hidden"}>{errors["contactphone"]}</span>

                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className='input' type='text' maxLength={200} id="email" name="email" value={contact.email} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["contactemail"] ? "label errors errors__leftmargin" : "hidden"}>{errors["contactemail"]}</span>


                <button className="defaultbutton-prev defaultbutton-label" type="submit">Save</button>
            </div>
        </form>
    );
}

export default ClientAddDetails;
import * as React from 'react';
import { useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserDetailData from './UserDetails';
import UserRoleData from './UserRoles';
import UserPermissionData from './UserPermissions';
import authService from './api-authorization/AuthorizeService';
import userReducer from './UserReducer';

interface UserDetails {
    id: number;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    password: string;
    confirmPassword: string;
    statusId: number;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface UserPermissions {
    id: number;
    name: string;
    group: string; 
    showGroup: boolean;
    selected: boolean;
}

const UserData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [roles, setRoles] = React.useState<GenericListItem[]>([]);
    const [managers, setManagers] = React.useState<GenericListItem[]>([]);
    const [statusOptions, setStatusOptions] = React.useState<GenericListItem[]>([]);
    const [tab, setTab] = React.useState("Details");

    const initialUserDetails: UserDetails = {
        id: 0,
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        password: "",
        confirmPassword: "",
        statusId: 1
    }

    const [{
        userDetails,
        userRoles,
        userManagers,
        userPermissions,
        defaultRoleId,
        isLoading,
    }, dispatch] = useReducer(userReducer, { userDetails: initialUserDetails, defaultRoleId: -1, userRoles: [], userManagers: [], userPermissions: [], isLoading: false });

    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        dispatch({ type: "getDetails" });

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateEditUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data == true) {
                //get user roles and status
                axios.get('Users/GetDropdowns?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setRoles(res.data.roles);
                    setManagers(res.data.managers);
                    setStatusOptions(res.data.statusOptions);
                    
                    //get user details
                    if (Id > 0) {
                        //only get user details if we have searched for a user not creating a new one
                        axios.get('Users/GetUserDetails?userId=' + Id, {
                            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                        })
                        .then(res => {
                            dispatch({ type: "updateDetails", userDetails: res.data.userDetails });
                            dispatch({ type: "updateDefaultRole", defaultRoleId: res.data.defaultRoleId });
                            dispatch({ type: "updateRoles", userRoles: res.data.userRoles });
                            dispatch({ type: "updateManagers", userManagers: res.data.userManagers });
                            dispatch({ type: "updatePermissions", userPermissions: res.data.userPermissions });
                        })
                        .catch(error => {
                            toast.error(error.message);
                        });
                    } else {
                        axios.get('Users/GetPermissions', {
                            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                        })
                        .then(res => {
                            dispatch({ type: "updatePermissions", userPermissions: res.data });
                        })
                        .catch(error => {
                            toast.error(error.message);
                        });
                    }
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const tabSelected = (tab: string) => {
        setTab(tab);
    }

    const tabChanged = (e: any) => {
        tabSelected(e.target.value);
    }

    const updateDetails = (userDetails: UserDetails) => {
        dispatch({ type: "updateDetails", userDetails: userDetails });
    }

    const updateDefaultRoleId = (defaultRoleId: number) => {
        dispatch({ type: "updateDefaultRole", defaultRoleId: defaultRoleId });
    }

    const updateRoles = (userRoles: GenericListItem[]) => {
        dispatch({ type: "updateRoles", userRoles: userRoles });
    }

    const updateMangers = (userManagers: GenericListItem[]) => {
        dispatch({ type: "updateManagers", userManagers: userManagers });
    }

    const updatePermissions = (userPermissions: UserPermissions[]) => {
        dispatch({ type: "updatePermissions", userPermissions: userPermissions });
    }

    const validateAll = () => {
        let formIsValid = true;
        var details = userDetails;
        if (!details.firstName || !details.lastName || !details.email || details.statusId < 0) {
            toast.error("User Details must be completed!");
            formIsValid = false;
        }
        if (userDetails.id == 0) {
            //check password - if existing user, separate form to change password
            if (!details.password || !details.confirmPassword) {
                toast.error("User Details - Password and Confirm Password are required");
                formIsValid = false;
            }
            if (details.password && details.confirmPassword) {
                //check password and confirm password match
                if (details.password != details.confirmPassword) {
                    toast.error("User Details - Password and Confirm Password must match");
                    formIsValid = false;
                }
            }
        }

        if (userRoles.length < 1) {
            toast.error("Roles - At least one role is required");
            formIsValid = false;
        }

        return formIsValid;
    }

    const save = () => {
        //validate all data before saving
        if (validateAll()) {
            var userToSave = {
                defaultRoleId: defaultRoleId,
                userDetails: userDetails,
                userRoles: userRoles,
                userManagers: userManagers,
                userPermissions: userPermissions,
            };

            if (userDetails.id == 0) {
                //create new user
                createUser(userToSave);
            } else {
                saveUser(userToSave);
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const createUser = async (userToSave: any) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        userToSave.subId = user.sub;

        axios.post('Account/Register', userToSave, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("User Saved");
                //then need to go to job search?
                navigate("/usersearch");
            }

        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const saveUser = async (userToSave: any) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        userToSave.subId = user.sub;

        axios.post('Users/Save', userToSave, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("User Saved");
                navigate("/usersearch");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var tabContents;
    if (tab == "Details") {
        tabContents = <UserDetailData id={userDetails.id} userDetails={userDetails} statusOptions={statusOptions} update={updateDetails} save={save} />
    }
    else if (tab == "Roles") {
        tabContents = <UserRoleData id={userDetails.id} defaultRoleId={defaultRoleId} userRoles={userRoles} roles={roles} userManagers={userManagers} managers={managers} updateUserRoles={updateRoles} updateUserManagers={updateMangers} updateDefaultRole={updateDefaultRoleId} save={save} />
    }
    else if (tab == "Permissions") {
        tabContents = <UserPermissionData id={userDetails.id} userPermissions={userPermissions} update={updatePermissions} save={save} />
    }

    const renderDetails = (
        <div>
            <div className="tabsComponent" id="tab-container">

                <div className="tabsComponent__showDropdown">
                    <label className="input-group" htmlFor="userTab">
                        <span className="label">Item Type</span>
                        <select className="select" id="userTab" value={tab} onChange={tabChanged} >
                            <option value="Details">Details</option>
                            <option value="Roles">Roles</option>
                            <option value="Permissions">Permissions</option>
                        </select>
                    </label>
                </div>

                <div className="tabsComponent__tabs tabsComponent__hideTabs">
                    <input type="radio" name="tabs" id="tab1" className="tabsComponent__control" checked={tab == 'Details'} readOnly></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('Details')}>Details</label>
                    <input type="radio" name="tabs" id="tab2" className="tabsComponent__control" checked={tab == 'Roles'} readOnly></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('Roles')}>Roles</label>
                    <input type="radio" name="tabs" id="tab3" className="tabsComponent__control" checked={tab == 'Permissions'} readOnly></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('Permissions')}>Permissions</label>
                </div>

                <div className="tabsComponent__content tabsComponent__content--selected">
                    <div>
                        {tabContents}
                    </div>
                </div>
            </div>
        </div>
    );

    let contents = isLoading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            <h1>User</h1>
            {contents}
        </div>
    );

}

export default UserData;
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import JobTabsMobile from './JobTabsMobile';

interface JobDetails {
    id: number;
    jobTypeId: number;
    jobType: string;
    siteAddress: string;
    isMakeSafe: boolean;
    isReportOnly: boolean;
    lossDate: string;
    siteVisitDateTime: string;
    contractPrice: string;
    statusId: number;
    customerCompanyName: string;
    customerName: string;
    customerPhone: string;
    propertyManager: string;
    insurerCompanyName: string;
    insurerPhoneNumber: string;
    insurerMobileNumber: string;
    claimNumber: string;
    excessAmount: number;
    adjusterCompanyName: string;
    adjusterPhoneNumber: string;
    adjusterMobileNumber: string;
    estimator: string;
    supervisor: string;
    manager: string;
}

const JobDetailsMobileData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [permissions, setPermissions] = React.useState({ canViewEstimate: false, canViewCostings: false, showAssessment: false });
    const [jobDetails, setJobDetails] = React.useState<JobDetails>({
        id: 0,
        jobTypeId: -1,
        jobType: "",
        siteAddress: "",
        isMakeSafe: false,
        isReportOnly: false,
        lossDate: "",
        siteVisitDateTime: "",
        contractPrice: "",
        statusId: -1,
        customerCompanyName: "",
        customerName: "",
        customerPhone: "",
        propertyManager: "",
        insurerCompanyName: "",
        insurerPhoneNumber: "",
        insurerMobileNumber: "",
        claimNumber: "",
        excessAmount: 0,
        adjusterCompanyName: "",
        adjusterPhoneNumber: "",
        adjusterMobileNumber: "",
        estimator: "",
        supervisor: "",
        manager: ""
    });
    const [siteAddress, setSiteAddress] = React.useState("");

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetJobDetailsForMobile?Id=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                setPermissions({ canViewEstimate: res.data.canViewEstimate, canViewCostings: res.data.canViewCostings, showAssessment: res.data.showAssessment });

                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setPermissions({ canViewEstimate: res.data.canViewEstimate, canViewCostings: res.data.canViewCostings, showAssessment: res.data.showAssessment });
                setJobDetails(res.data.jobDetails);
                setSiteAddress(res.data.siteAddress);
                setLoading(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    let showDetailsEst = jobDetails.statusId === 1 || jobDetails.statusId === 2 || jobDetails.statusId === 3;
    let jobTabsMobile = <JobTabsMobile id={jobDetails.id} tab="details" canViewEstimate={permissions.canViewEstimate} canViewCostings={permissions.canViewCostings} showAssessment={permissions.showAssessment} tabChanged={tabChanged} />
       
    const renderDetails = (
        <div>
            <div className="mobileFont marginTop10 marginBottom70">
                <h4 className="mobile-margin">Job Details</h4>
                <label className={"input-group input-group--mobile" + (jobDetails.isMakeSafe ? " makeSafeJob" : jobDetails.isReportOnly ? " reportOnlyJob" : "")} htmlFor="siteAddress">
                    {siteAddress}
                </label>
                <div className="mobileBackground">
                    <div className={showDetailsEst ? "" : "hidden"}>
                        <label className="input-group input-group--mobile" htmlFor="jobType">
                            <span className="mobileSplitScreen">Job Type</span>
                            {jobDetails.jobType}
                        </label>
                        <label className="input-group input-group--mobile" htmlFor="lossDate">
                            <span className="mobileSplitScreen">Date of Loss</span>
                            {jobDetails.lossDate}
                        </label>
                        <label className="input-group input-group--mobile" htmlFor="siteVisitDateTime">
                            <span className="mobileSplitScreen">Site Visit Date</span>
                            {jobDetails.siteVisitDateTime}
                        </label>
                    </div>
                    <label className="input-group input-group--mobile" htmlFor="contractPrice">
                        <span className="mobileSplitScreen">Contract Price</span>
                        {jobDetails.contractPrice}
                    </label>
                </div>
                <div className={jobDetails.jobTypeId === 1 ? "mobileAltBackground" : "hidden"} >
                    <label className="input-group input-group--mobile" htmlFor="claimNumber">
                        <span className="mobileSplitScreen">Claim #</span>
                        {jobDetails.claimNumber}
                    </label>
                    <label className="input-group input-group--mobile" htmlFor="insurerCompanyName">
                        {jobDetails.insurerCompanyName}
                    </label>
                    <div className={showDetailsEst ? "" : "hidden"}>
                        <label className="input-group input-group--mobile">
                            <a className={jobDetails.insurerPhoneNumber === "" ? "hidden" : "mobileSplitScreen"} href={"tel:" + jobDetails.insurerPhoneNumber}>{jobDetails.insurerPhoneNumber}</a>
                            <a className={jobDetails.insurerMobileNumber === "" ? "hidden" : ""} href={"tel:" + jobDetails.insurerMobileNumber}>{jobDetails.insurerMobileNumber}</a>
                        </label>
                    </div>
                    <label className={showDetailsEst ? "input-group input-group--mobile" : "hidden"} htmlFor="excessAmount">
                        <span className="mobileSplitScreen">Excess Amount</span>
                        {jobDetails.excessAmount}
                    </label>
                    <label className={jobDetails.adjusterCompanyName === "" ? "hidden" : "input-group"} htmlFor="adjusterCompanyName">
                        {jobDetails.adjusterCompanyName}
                    </label>
                    <div className={showDetailsEst ? "" : "hidden"}>
                        <label className="input-group input-group--mobile">
                            <a className={jobDetails.adjusterPhoneNumber === "" ? "hidden" : "mobileSplitScreen"} href={"tel:" + jobDetails.adjusterPhoneNumber}>{jobDetails.adjusterPhoneNumber}</a>
                            <a className={jobDetails.adjusterMobileNumber === "" ? "hidden" : ""} href={"tel:" + jobDetails.adjusterMobileNumber}>{jobDetails.adjusterMobileNumber}</a>
                        </label>
                    </div>
                </div>
                <div className="mobileBackground">
                    <label className={jobDetails.propertyManager == "" ? "hidden" : "input-group"} htmlFor="propertyManager">
                        <span className="mobileSplitScreen">Manager</span>
                        {jobDetails.propertyManager}
                    </label>
                    <label className={jobDetails.customerCompanyName == "" ? "hidden" : "input-group input-group--mobile"} htmlFor="customerCompanyName">
                        {jobDetails.customerCompanyName}
                    </label>
                    <label className="input-group input-group--mobile" htmlFor="customerName">
                        <span className="mobileSplitScreen">{jobDetails.customerName}</span>
                        <a href={"tel:" + jobDetails.customerPhone}>{jobDetails.customerPhone}</a>
                    </label>
                </div>
                <div className="mobileAltBackground">
                    <label className="input-group" htmlFor="estimator">
                        <span className="mobileSplitScreen">Estimator</span>
                        {jobDetails.estimator}
                    </label>
                    <label className="input-group" htmlFor="supervisor">
                        <span className="mobileSplitScreen">Supervisor</span>
                        {jobDetails.supervisor}
                    </label>
                    <label className="input-group" htmlFor="manager">
                        <span className="mobileSplitScreen">Manager</span>
                        {jobDetails.manager}
                    </label>
                </div>
            </div>
            <div>
                {jobTabsMobile}
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;
}

export default JobDetailsMobileData;
import * as React from 'react';

type User = {
    userDetails: UserDetails;
    userRoles: GenericListItem[];
    userManagers: GenericListItem[];
    userPermissions: UserPermissions[];
    defaultRoleId: number;
    isLoading: boolean;
    error?: string;
}

type UserDetails = {
    id: number;
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    password: string;
    confirmPassword: string;
    statusId: number;
}

type GenericListItem = {
    id: number;
    name: string;
}

type UserPermissions = {
    id: number;
    name: string;
    group: string;
    showGroup: boolean;
    selected: boolean;
}

const initialUser: User = {
    userDetails: {
        id: 0,
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        password: "",
        confirmPassword: "",
        statusId: 1
    },
    userRoles: [],
    userManagers: [],
    userPermissions: [],
    defaultRoleId: -1,
    isLoading: false,
    error: ""
}

type Action =
    | { type: 'getDetails' }
    | { type: 'updateDetails', userDetails: UserDetails }
    | { type: 'updateRoles', userRoles: GenericListItem[] }
    | { type: 'updateManagers', userManagers: GenericListItem[] }
    | { type: 'updatePermissions', userPermissions: UserPermissions[] }
    | { type: 'updateDefaultRole', defaultRoleId: number }
    | { type: 'reset' }
    | { type: 'error', error: "" };

const userReducer = (user: User = initialUser, action: Action): User => {
    switch (action.type) {
        case 'getDetails':
            return { ...user, isLoading: true };
        case 'updateDetails':
            return { ...user, isLoading: false, userDetails: action.userDetails };
        case 'updateRoles':
            return { ...user, isLoading: false, userRoles: action.userRoles };
        case 'updateManagers':
            return { ...user, isLoading: false, userManagers: action.userManagers };
        case 'updatePermissions':
            return { ...user, isLoading: false, userPermissions: action.userPermissions };
        case 'updateDefaultRole':
            return { ...user, isLoading: false, defaultRoleId: action.defaultRoleId };
        case 'reset':
            return initialUser;
        case 'error':
            return { ...user, isLoading: false, error: action.error };
        default:
            return user;
    }
}

export default userReducer; 
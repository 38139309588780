import * as React from 'react';
import { Modal } from 'react-bootstrap';
import GoogleAutoComplete from './GoogleAutoComplete';
import { toast } from 'react-toastify';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';

interface SupplierAddXeroContactPopupProps {
    supplierId: number;
    supplierInvoiceId: number;
    showModal: boolean;
    hideModal(): void;
    saveModal(xeroContactId: string, xeroContactName: string): void;
}

interface XeroContact {
    contactName: string;
    firstName: string;
    lastName: string;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    phone: string;
    email: string;
    abn: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const SupplierAddXeroContactPopup = (props: SupplierAddXeroContactPopupProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [xeroContact, setXeroContact] = React.useState<XeroContact>({
        contactName: "",
        firstName: "",
        lastName: "",
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        postCode: "",
        country: "",
        phone: "",
        email: "",
        abn: ""
    });

    React.useEffect(() => {
        if (props.supplierId > 0) {
            getSupplierDetails();
        } else {
            setLoading(false);
        }
    }, [props.showModal]);

    const getSupplierDetails = async () => {
        const token = await authService.getAccessToken();

        //only get supplier details if we have searched for a supplier not creating a new one
        axios.get('Suppliers/GetSupplierDetails?Id=' + props.supplierId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
            } else {
                var supplier = res.data;
                var supplierContacts = supplier.contacts;

                var firstName = "";
                var lastName = "";
                if (supplierContacts.length > 0) {
                    //get first contact name
                    firstName = supplierContacts[0].firstName;
                    lastName = supplierContacts[0].lastName;
                }

                var addXeroContact: XeroContact = {
                    contactName: supplier.name,
                    firstName: firstName,
                    lastName: lastName,
                    address: supplier.address,
                    streetNumber: supplier.streetNumber,
                    streetName: supplier.streetName,
                    suburb: supplier.suburb,
                    state: supplier.state,
                    postCode: supplier.postCode,
                    country: supplier.country,
                    phone: supplier.phone,
                    email: supplier.email,
                    abn: supplier.abn
                };

                setXeroContact(addXeroContact);
                setModalSaveDisabled(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideModal = () => {
        let contact = resetAddXeroContact();
        setXeroContact(contact);
        props.hideModal();
    }

    const resetAddXeroContact = () => {
        var addXeroContact: XeroContact = {
            contactName: "",
            firstName: "",
            lastName: "",
            address: "",
            streetNumber: "",
            streetName: "",
            suburb: "",
            state: "",
            postCode: "",
            country: "",
            phone: "",
            email: "",
            abn: ""
        };

        return addXeroContact;
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setXeroContact(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        setXeroContact(prevState => ({
            ...prevState,
            address: addressDetails.fullAddress,
            streetNumber: addressDetails.street_number,
            streetName: addressDetails.route,
            suburb: addressDetails.locality,
            state: addressDetails.administrative_area_level_1,
            country: addressDetails.country,
            postCode: addressDetails.postal_code
        }));
    }

    const validate = () => {
        let fields = xeroContact;
        let formIsValid = true;
        let error: any = {};

        if (!fields.contactName) {
            formIsValid = false;
            error["contactName"] = "Contact Name is required";
        }

        setErrors(error)
        return formIsValid;
    }

    const save = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        //validate all data before saving
        if (validate()) {
            saveXeroContact();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveXeroContact = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        var saveDetails = {
            supplierInvoiceId: props.supplierInvoiceId,
            xeroContact: xeroContact,
            subId: user.sub
        };

        axios.post('Xero/SendSupplierViaImportToXero', saveDetails, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var contact = resetAddXeroContact();
                setXeroContact(contact);
                props.saveModal(res.data.xeroContactId, res.data.xeroContactName);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Address"} placeholder={xeroContact.address} className="input-group" disabled={false} save={setAddress} />

    const renderDetails = (
        <Modal show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add New Xero Contact</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <label className="input-group" htmlFor="contactName">
                    <span className="label">Company Name</span>
                    <input className='input' type='text' maxLength={255} id="contactName" name="contactName" value={xeroContact.contactName} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["contactName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["contactName"]}</span>

                <label className="input-group" htmlFor="firstName">
                    <span className="label">First Name</span>
                    <input className='input' type='text' maxLength={255} id="firstName" name="firstName" value={xeroContact.firstName} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="lastName">
                    <span className="label">Last Name</span>
                    <input className='input' type='text' maxLength={255} id="lastName" name="lastName" value={xeroContact.lastName} onChange={(e) => handleChange(e)}></input>
                </label>

                {googleContents}

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className='input' type='text' maxLength={50} id="phone" name="phone" value={xeroContact.phone} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="abn">
                    <span className="label">ABN</span>
                    <input className='input' type='text' maxLength={255} id="abn" name="abn" value={xeroContact.abn} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className='input' type='text' maxLength={20} id="email" name="email" value={xeroContact.email} onChange={(e) => handleChange(e)}></input>
                </label>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={hideModal}>Cancel</button>
                <button className="popup-button--yes" onClick={save} disabled={modalSaveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default SupplierAddXeroContactPopup;
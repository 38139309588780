import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import NoteModal from './NotesModal';
import ReportJobPhotosPopup from './ReportJobPhotos';
import authService from './api-authorization/AuthorizeService';

interface JobCostingProps {
    jobId: number;
    canEdit: boolean;
    canViewReport: boolean;
    checkForecast(): void;
}

interface JobCostings {
    id: number;
    jobId: number;
    costCodeId: number;
    costCode: string;
    estimateAmountFormatted: string;
    variationAmountFormatted: string;
    totalEstimateAmount: number;
    totalEstimateAmountFormatted: string;
    forecastAmount: number;
    forecastAmountFormatted: string;
    actualAmount: number;
    actualAmountFormatted: string;
    forecastVariance: number;
    forecastVarianceFormatted: string;
    variance: number;
    varianceFormatted: string;
    variancePercentage: number;
    variancePercentageFormatted: string;
    exceedForecastReason: string;
    isDirty: boolean;
}

interface EditNotes {
    title: string;
    heading: string;
    rowIndex: number;
    id: number;
    notes: string;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const JobCostingsData = (props: JobCostingProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [jobCostings, setJobCostings] = React.useState<JobCostings[]>([]);
    const [estVarTotalExGst, setEstVarTotalExGst] = React.useState(0);
    const [showNotesModal, setShowNotesModal] = React.useState(false);
    const [showForecastNotesModal, setShowForecastNotesModal] = React.useState(false);
    const [editNotes, setEditNotes] = React.useState<EditNotes>({
        id: 0,
        rowIndex: 0,
        title: "",
        heading: "",
        notes: ""
    });
    const [forecast, setForecast] = React.useState({ forecastJobMarginPercentage: "", forecastJobMargin: "" });
    const [markUp, setMarkup] = React.useState({ estimateMarkUpPercentage: "", estimateMarkUp: "" });
    const [isDirty, setIsDirty] = React.useState(false);
    const [estimatedCompletionDate, setEstimatedCompletionDate] = React.useState("");
    const [origEstCompletionDate, setOrigEstCompletionDate] = React.useState("");
    const [showPhotoModal, setShowPhotosModal] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);
    const [loadingPhotos, setLoadingPhotos] = React.useState(false);
    const [reportComments, setReportComments] = React.useState("");
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //get user roles and status
        axios.get('Jobs/GetCostings?JobId=' + props.jobId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setEstimatedCompletionDate(res.data.estimatedCompletionDate);
                setOrigEstCompletionDate(res.data.estimatedCompletionDate);
                setJobCostings(res.data.jobCostings);
                setEstVarTotalExGst(res.data.estVarTotalExGst);
                setForecast({ forecastJobMarginPercentage: res.data.forecastJobMarginPercentageFormatted, forecastJobMargin: res.data.forecastJobMarginFormatted });
                setMarkup({ estimateMarkUpPercentage: res.data.estimateMarkUpPercentageFormatted, estimateMarkUp: res.data.estimateMarkUpFormatted });
                setReportComments(res.data.reportComments);
                setLoading(false);
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const handleEstCompletionDateChange = (e: any) => {
        //separate method for estimated completion date as need to popup reason
        //check if there is a value and an error, and if so remove from error list
        
        if (e.target.value) {
            var error = errors;
            delete errors[e.target.name];
            setErrors(error);
        }

        setEstimatedCompletionDate(e.target.value);
        setSaveDisabled(false);
        setIsDirty(true);

        if (origEstCompletionDate) {
            var editingNotes: EditNotes = {
                id: props.jobId,
                rowIndex: 0,
                title: "Estimated Completion Date Update",
                heading: "Enter reason for changing the estimated completion date",
                notes: ""
            };
            setShowNotesModal(true);
            setEditNotes(editingNotes);
        }
    }

    const handleForecastCellChange = (index: number, e: any) => {
        var forecastValue = e.target.value * 1;

        var jc = jobCostings;
        var costing = jc[index];
        costing.forecastAmount = forecastValue;
        costing.isDirty = true;

        //update formatted forecast
        costing.forecastAmountFormatted = forecastValue.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        //calculate forecast variance
        let forecastVariance = (forecastValue - costing.actualAmount);
        costing.forecastVarianceFormatted = forecastVariance.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        setJobCostings(jc);
        setSaveDisabled(false);

        //calculate total margin and margin %
        calculateForecastMargin(jobCostings);
    }

    const calculateForecastMargin = (jobCostings: JobCostings[]) => {
        //get total estimate and total forecast amount
        var forecastTotal = 0;
        for (var i = 0; i < jobCostings.length; i++) {
            forecastTotal += jobCostings[i].forecastAmount;
        }

        //calculate forecast job margin and forecast job margin %
        var forecastJobMargin = estVarTotalExGst - forecastTotal;
        let estVarTotal = estVarTotalExGst === 0 ? 1 : estVarTotalExGst;
        var forecastJobMarginPercent = (forecastJobMargin / estVarTotal) * 100;

        //format
        var forecastJobMarginFormatted = forecastJobMargin.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        var forecastJobMarginPercentFormatted = forecastJobMarginPercent.toFixed(2) + "%";
        setForecast({ forecastJobMargin: forecastJobMarginFormatted, forecastJobMarginPercentage: forecastJobMarginPercentFormatted });
    }

    //edit est completion date
    const hideChangeReason = () => {
        var notes = editNotes;
        if (notes.id > 0) {
            //reset job costing forecast to 0
            //as they haven't enter a reason why it is > budget
            var jc = jobCostings;
            var jobCosting = jc[notes.rowIndex];

            jobCosting.forecastAmount = 0;
            jobCosting.forecastAmountFormatted = "$0.00"
            setJobCostings(jc);
        }

        var resetNotes = resetChangeReason();
        setEstimatedCompletionDate(origEstCompletionDate);
        setEditNotes(resetNotes);
        setShowNotesModal(false);
        setShowForecastNotesModal(false);
    }

    const resetChangeReason = () => {
        var resetNotes: EditNotes = {
            title: "",
            heading: "",
            rowIndex: 0,
            id: 0,
            notes: ""
        }
        return resetNotes;
    }

    const updateChangeReason = (updatedNotes: EditNotes) => {
        var reason = editNotes;
        reason.notes = updatedNotes.notes;
        setEditNotes(prevState => ({ ...prevState, reason }));
    }

    const saveChangeReason = () => {
        if (editNotes.notes) {
            saveChangeReasonNotes();
        } else {
            toast.error("Enter a reason for changing the completion date!");
        }
    }

    const saveChangeReasonNotes = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var estCompletionDateChange = {
            id: props.jobId,
            estimatedCompletionDate: estimatedCompletionDate,
            reason: editNotes.notes,
            subId: user.sub
        };

        //save
        axios.post('Jobs/SaveEstCompletionDateUpdate', estCompletionDateChange, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data) {
                toast.success("Estimated Completion Date Update Reason Saved");
            } else {
                toast.success("Error while trying to update Estimated Completion date");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

        var resetNotes = resetChangeReason();
        setEditNotes(resetNotes);
        setShowNotesModal(false);
        setErrors({});
    }

    const checkForecast = (e: any, index: number) => {
        e.preventDefault();

        var costing = jobCostings[index];
        var forecastAmt = costing.forecastAmount * 1;
        var totalBudget = costing.totalEstimateAmount * 1;

        if (forecastAmt > totalBudget) {
            var editNotes: EditNotes = {
                id: costing.id,
                rowIndex: index,
                title: "Forecast > Budget",
                heading: "Enter reason why the forecast is > budget",
                notes: ""
            };
            setShowForecastNotesModal(true);
            setEditNotes(editNotes);
        }
    }

    const saveForecastReason = () => {
        if (editNotes.notes) {
            saveForecastReasonNotes();
        } else {
            toast.error("Enter a reason for the forecast being > the budget!");
        }
    }

    const saveForecastReasonNotes = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var jc = jobCostings;
        var costing = jc[editNotes.rowIndex];

        var estForecastChange = {
            jobId: props.jobId,
            costingId: costing.id,
            forecastAmount: costing.forecastAmount,
            costCode: costing.costCode,
            reason: editNotes.notes,
            subId: user.sub
        };

        //save
        axios.post('Jobs/SaveForecastUpdate', estForecastChange, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data) {
                toast.success("Forecast > Budget Reason Saved");
            } else {
                toast.success("Error while trying to save forecast reason");
                costing.forecastAmount = 0;
                setJobCostings(jc);
            }
            var resetNotes = resetChangeReason();
            setEditNotes(resetNotes);
            setShowForecastNotesModal(false);
            setErrors({});
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end edit est completion date modal

    //run job photos report
    const runJobPhotosReport = () => {
        runPhotosReport();
    }

    const runPhotosReport = async () => {
        const token = await authService.getAccessToken();

        //get photos
        axios.get('Jobs/GetJobPhotos?JobId=' + props.jobId + '&TypeId=3', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var photos = res.data.photos;
            if (!photos) {
                photos = [];
            }

            var uploadedPhotos: UploadedFile[] = [];
            for (var i = 0; i < photos.length; i++) {
                var uploadedPhoto: UploadedFile = {
                    file: {
                        id: photos[i].id,
                        fileName: photos[i].fileName,
                        fileType: photos[i].fileType,
                        content: photos[i].content,
                        photoOrder: photos[i].photoOrder
                    },
                    comment: photos[i].comment
                };
                uploadedPhotos.push(uploadedPhoto);
            }

            setShowPhotosModal(true);
            setUploadedFiles(uploadedPhotos);
        });
    }

    const hidePhotoImport = () => {
        setShowPhotosModal(false);
        setUploadedFiles([]);
    }

    const importPhotos = (files: File[]) => {
        setLoadingPhotos(true);
        importJobPhotos(files);
    }

    const importJobPhotos = async (files: File[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save photos
        const data = new FormData()
        data.append('jobId', props.jobId.toString());
        data.append('typeId', "3");

        for (var i = 0; i < files.length; i++) {
            data.append('photos', files[i]);
        }
        data.append('subId', user.sub);

        //save photos
        axios.post('Jobs/ImportJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //save the returned photos
                var files: UploadedFile[] = [];
                var photos = res.data.photos;
                for (var p = 0; p < photos.length; p++) {
                    var photo = photos[p];
                    var file: UploadedFile = {
                        file: {
                            id: photo.id,
                            fileName: photo.fileName,
                            fileType: photo.fileType,
                            content: photo.content,
                            photoOrder: photo.photoOrder
                        },
                        comment: photo.comment
                    };
                    files.push(file);
                }
                setUploadedFiles(files);
                setLoadingPhotos(false);
                toast.success("Photos have been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    const updatePhotoImport = (files: UploadedFile[]) => {
        setUploadedFiles(files);
    }

    const deletePhotoImport = (deleteIndex: number) => {
        var files = [...uploadedFiles];
        var photo = files[deleteIndex];

        if (!photo) {
            toast.error("Could not find photo to delete!");
            return;
        }

        if (photo.file.id > 0) {
            saveDeletePhoto(photo.file.id, deleteIndex);
        } else {
            //save the photos on upload so this shouldn't happen but just in case
            files.splice(deleteIndex, 1);
            //re-order the photos
            for (var i = 0; i < files.length; i++) {
                files[i].file.photoOrder = i + 1;
            }

            setUploadedFiles(files);
            toast.success("Photo has been deleted");
        }

    }

    const saveDeletePhoto = async (photoId: number, deleteIndex: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var files = [...uploadedFiles];

        var data = {
            id: photoId,
            subId: user.sub
        };

        //delete photo
        axios.post('Jobs/DeleteJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                files.splice(deleteIndex, 1);
                //re-order the photos
                for (var i = 0; i < files.length; i++) {
                    files[i].file.photoOrder = i + 1;
                }

                setUploadedFiles(files);
                toast.success("Photo has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const savePhotoImport = () => {
        saveJobPhotoImport();
    }

    const saveJobPhotoImport = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var photoComments = [];
        for (var i = 0; i < uploadedFiles.length; i++) {
            var photoOrder = uploadedFiles[i].file.photoOrder;
            if (!photoOrder) {
                //set the order for any null entries
                photoOrder = i + 1;
            }

            var item = {
                id: uploadedFiles[i].file.id,
                comments: uploadedFiles[i].comment,
                photoOrder: photoOrder
            };
            photoComments.push(item);
        }

        var data = {
            jobId: props.jobId,
            reportComments: reportComments,
            jobPhotoComments: photoComments,
            subId: user.sub
        };

        //save comments
        axios.post('Jobs/SaveJobPhotoReportComments', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Photos have been saved");
                setShowPhotosModal(false);
                setUploadedFiles([]);
                setModalSaveDisabled(false);
                
                printJobPhotosReport();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const updatePhotoComments = (rptComments: string) => {
        setReportComments(rptComments);
    }

    const updateSaveDisabled = () => {
        setModalSaveDisabled(true);
    }

    const printJobPhotosReport = () => {
        var url = "/job/" + props.jobId + "/report/2/jobPhotos";
        window.open(url, '_blank');
    }
    //end job photos report

    const save = (e: any) => {
        e.preventDefault();

        if (!estimatedCompletionDate) {
            let error: any = {};
            error["estimatedCompletionDate"] = "Estimated Completion Date is required";
            setErrors(error);
            toast.error("Enter Estimated Completion Date!");
        } else {
            //Only save items that have changed
            var changedCostings = jobCostings.filter(data => data.isDirty);
            if (changedCostings.length === 0 && !isDirty) {
                toast.info("No items have been changed!");
            } else {
                if (saveDisabled) {
                    return;
                }
                setSaveDisabled(true);
                
                saveCostings(changedCostings);
            }
        }
    }

    const saveCostings = async (changedCostings: JobCostings[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var costings = {
            jobId: props.jobId,
            estimatedCompletionDate: estimatedCompletionDate,
            jobCostings: changedCostings,
            subId: user.sub
        };

        axios.post('Jobs/SaveJobCosting', costings, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
            } else {
                toast.success("Costings Saved");
                props.checkForecast();
            }
        })
        .catch(error => {
            toast.error(error);
        });
    }

    let photoImport = <ReportJobPhotosPopup title="Select photos for photos report" showModal={showPhotoModal} loadingPhotos={loadingPhotos} uploadedFiles={uploadedFiles} reportComments={reportComments} hideModal={hidePhotoImport} importPhotos={importPhotos} update={updatePhotoImport} delete={deletePhotoImport} save={savePhotoImport} saveDisabled={modalSaveDisabled} updateSaveDisabled={updateSaveDisabled} updateReportComments={updatePhotoComments} />
    let estCompletionDateUpdateReason = <NoteModal showEditNotesModal={showNotesModal} editNotes={editNotes} hideNotes={hideChangeReason} updateNotes={updateChangeReason} saveNotes={saveChangeReason} maxLength={500} />
    let forecastoverbudget = <NoteModal showEditNotesModal={showForecastNotesModal} editNotes={editNotes} hideNotes={hideChangeReason} updateNotes={updateChangeReason} saveNotes={saveForecastReason} maxLength={1000} />
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {estCompletionDateUpdateReason}
                {photoImport}
                {forecastoverbudget}
            </div>
            <div>
                <label className="input-group estimate__alignTotals" htmlFor="estimatedCompletionDate">
                    <span className="label textalignright">Est. Completion Date</span>
                    <input className="input estimate__totalsWidth textalignright" type="date" id="estimatedCompletionDate" name="estimatedCompletionDate" value={estimatedCompletionDate} onChange={(e) => handleEstCompletionDateChange(e)} ></input>
                </label>
            </div>
            <div className="makeitflex estimate__alignTotals">
                <span className={errors["estimatedCompletionDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["estimatedCompletionDate"]}</span>
            </div>

            <div className="job__noteGridSize overflowAuto">
                <table className="table--main tableColours">
                    <thead>
                        <tr>
                            <th>Cost Code</th>
                            <th className="textalignright">Estimate</th>
                            <th className="textalignright">Variations</th>
                            <th className="textalignright">Total Estimate</th>
                            <th className="textalignright jobCostings__size">Forecast</th>
                            <th className="textalignright">Actual</th>
                            <th className="textalignright">Forecast Variance</th>
                            <th className="textalignright">Variance</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobCostings.map((costing, index) =>
                            <tr key={costing.id}>
                                <td className="table__text--align">{costing.costCode}</td>
                                <td className="table__text--align textalignright">{costing.estimateAmountFormatted}</td>
                                <td className="table__text--align textalignright">{costing.variationAmountFormatted}</td>
                                <td className="table__text--align textalignright">{costing.totalEstimateAmountFormatted}</td>
                                <td className="table__text--align textalignright">
                                    <input type='number' min="0" step="any" className={props.canEdit ? "form-control textalignright jobCostings__size" : "hidden"} name="forecastAmount" value={costing.forecastAmount} onChange={(e) => handleForecastCellChange(index, e)} onBlur={(e) => checkForecast(e, index)} />
                                    {props.canEdit ? "" : costing.forecastAmountFormatted}
                                </td>
                                <td className="table__text--align textalignright">{costing.actualAmountFormatted}</td>
                                <td className={costing.forecastVariance < 0 ? "table__text--align textalignright makeitred" : "table__text--align textalignright"}>{costing.forecastVarianceFormatted}</td>
                                <td className={costing.variance < 0 ? "table__text--align textalignright makeitred" : "table__text--align textalignright"}>{costing.varianceFormatted}</td>
                                <td className={costing.variancePercentage > 1 ? "table__text--align textalignright makeitred" : "table__text--align textalignright"}>{costing.totalEstimateAmount === 0 && costing.variance < 0 ? "" : costing.variancePercentageFormatted}</td>
                                <td className="table__text--align">
                                    <div className={costing.exceedForecastReason === "" || !costing.exceedForecastReason ? "hidden" : "tooltipitem tooltipitem__left"}><span className="fas fa-file-alt edit--icon alignIconCenter makeitred"></span>
                                        <span className="tooltipitemtext tooltipitemtext__left">{costing.exceedForecastReason}</span>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="input-group-parent">
                <div>
                    <button className="defaultbutton defaultbutton__medium" type="submit" disabled={!props.canEdit || saveDisabled}>Save</button>
                    <button className="defaultbutton defaultbutton__medium threebuttons__btn2--position" type="button" onClick={runJobPhotosReport}>Run Photos Report</button>
                    <button className={isSafari ? "defaultbutton defaultbutton__medium threebuttons__btn3--position" : "hidden"} type="button" onClick={printJobPhotosReport}>View Photos Report</button>
                </div>
            </div>
            <div className="input-group-parent">
                <label className="input-group estimate__alignTotals" htmlFor="forecastJobMarginPercentage">
                    <span className="label textalignright">Forecast Job Margin %</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="forecastJobMarginPercentage" name="forecastJobMarginPercentage" value={forecast.forecastJobMarginPercentage} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="forecastJobMargin">
                    <span className="label textalignright">Forecast Job Margin</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="forecastJobMargin" name="forecastJobMargin" value={forecast.forecastJobMargin} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="estimateMarkUpPercentage">
                    <span className="label textalignright">Estimate Mark Up %</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="estimateMarkUpPercentage" name="estimateMarkUpPercentage" value={markUp.estimateMarkUpPercentage} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="estimateMarkUp">
                    <span className="label textalignright">Estimate Mark Up</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="estimateMarkUp" name="estimateMarkUp" value={markUp.estimateMarkUp} disabled></input>
                </label>
            </div>  
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;
}

export default JobCostingsData;

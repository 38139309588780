import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface JobPurchaseOrderDetailsMobileProps {
    id: number;
    jobId: number;
    goBack(): void;
}

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplier: string;
    supplierContact: string;
    dateRequired: string;
    includeFullScope: boolean;
    instructions: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    purchaseOrderDetails: PurchaseOrderDetail[];
    statusId: number;
}

interface PurchaseOrderDetail {
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    isDirty: boolean;
}

const JobPurchaseOrderDetailsMobileData = (props: JobPurchaseOrderDetailsMobileProps) => {
    const [loading, setLoading] = React.useState(true);
    const [purchaseOrder, setPurchaseOrder] = React.useState<PurchaseOrder>(
        {
            id: 0,
            jobId: props.jobId,
            supplier: "",
            supplierContact: "",
            dateRequired: "",
            includeFullScope: false,
            instructions: "As per scope of works",
            subTotalFormatted: "",
            gstFormatted: "",
            totalFormatted: "",
            purchaseOrderDetails: [],
            statusId: 1
        });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        //get purchase order details
        axios.get('Jobs/GetPurchaseOrderMobile?Id=' + props.id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setPurchaseOrder(res.data);
            setLoading(false);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const goBack = (e: any) => {
        e.preventDefault();
        props.goBack();
    }

    const handleCheckboxChange = (e: any) => {
        saveFullScope(e.target.checked);
    }

    const saveFullScope = async(incFullScope: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let po = purchaseOrder;

        var savePoScope = {
            poId: props.id,
            includeFullScope: incFullScope,
            subId: user.sub
        };

        axios.post('Jobs/SavePurchaseOrderScope', savePoScope, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setPurchaseOrder(prevState => ({ ...prevState, includeFullScope: incFullScope }));
                
                toast.success("Purchase Order Scope has been Saved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const view = (e: any) => {
        //view purchase order
        e.preventDefault();
        var url = "/job/" + props.jobId + "/report/" + purchaseOrder.id + "/PurchaseOrder";
        window.open(url, '_blank');
    }

    let editPoUrl = "/job/" + props.jobId + "/purchaseorderdetailsm/" + purchaseOrder.id;

    const renderDetails =  (
        <div className="mobileFont marginTop10 marginBottom70">
            <div className={purchaseOrder.statusId === 1 ? "makeitflexspacebetween marginBottom10" : "marginBottom10"}>
                <a className={purchaseOrder.statusId === 1 ? "mobile-icon" : "hidden"} href={editPoUrl}>
                    <span className="fas fa-edit mobileMenuSize alignIconCenter"></span>
                </a>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-grey" href="#" onClick={(e) => goBack(e)}>
                        <span className="fas fa-arrow-alt-circle-left mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
            <div>
                <div className="mobileBackground">
                    <div>{purchaseOrder.supplier}</div>
                    <div>{purchaseOrder.supplierContact}</div>
                    <div>{purchaseOrder.dateRequired}</div>
                </div>
                <div className="mobileAltBackground">
                    <span className="mobileSplitScreen makeitbold">Instructions</span>
                    <div>{purchaseOrder.instructions}</div>

                    <span className="label">Include Full Scope</span>
                    <input className="checkbox" type="checkbox" id="includeFullScope" name="includeFullScope" checked={purchaseOrder.includeFullScope} onChange={(e) => handleCheckboxChange(e)}></input>
                </div>

                <div className="marginTop10">
                    <table className="table--main table__small tableColours mobileTableFont">
                        <thead>
                            <tr>
                                <th>Budget</th>
                                <th className="textalignright">Quantity</th>
                                <th className="textalignright">Unit</th>
                                <th className="textalignright">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseOrder.purchaseOrderDetails.map((detail, index) =>
                                <tr key={index}>
                                    <td className="table__text--align">{detail.costCode}<br></br>{detail.remainingFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.quantityType}</td>
                                    <td className="table__text--align textalignright">{detail.unitCostFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.totalFormatted}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mobileFont defaultbutton__container--right">
                <div>
                    <label className="input-group labeltextalignright makeitbold marginBottom15" htmlFor="subTotalExGstFormatted">
                        <span className="label-mobile">Sub Total ex GST</span>
                        <span className="estimate-mobile-totals">{purchaseOrder.subTotalFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright marginBottom15" htmlFor="gstFormatted">
                        <span className="label-mobile ">GST</span>
                        <span className="estimate-mobile-totals">{purchaseOrder.gstFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright makeitbold marginBottom15" htmlFor="totalFormatted">
                        <span className="label-mobile">Total</span>
                        <span className="estimate-mobile-totals">{purchaseOrder.totalFormatted}</span>
                    </label>
                </div>
            </div>
            <button className="mobile-button mobile-button_grey" type="button" onClick={view}>View Purchase Order</button>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobPurchaseOrderDetailsMobileData;

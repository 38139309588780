import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface AddMultipleInsurerInvoicePopupProps {
    jobId: number;
    show: boolean;
    hide(): void;
    save(): void;
}

interface InvoiceItem {
    linkId: number;     //to link to estimate, variation, progress claim deduction, excess deduction etc
    linkTypeId: number;
    description: string;
    total: number;
    available: number;  //amount remaining that hasn't been previously claimed
    availableFormatted: string;
}

const AddMultipleInsurerInvoicePopup = (props: AddMultipleInsurerInvoicePopupProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [invoiceTypeId, setInvoiceTypeId] = React.useState(-1);
    const [progressClaimNumber, setProgressClaimNumber] = React.useState(0);
    const [invoiceItems, setInvoiceItems] = React.useState<InvoiceItem[]>([]);
    const [worksCompleteText, setWorksCompleteText] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleTypeChange = (e: any) => {
        if (e.target.value) {
            clearError(e.target.name);
        }
        var value = e.target.value * 1;
        setInvoiceTypeId(value);
        setWorksCompleteText("");
        setSaveDisabled(false);

        if (value > 0) {
            getInvoiceLineItems(value);
        }
    }

    const getInvoiceLineItems = async (invoiceTypeId: number) => {
        const token = await authService.getAccessToken();

        setLoading(true);

        //GetCustomerDetails
        axios.get('Jobs/GetMultipleInsurerInvoiceDetails?JobId=' + props.jobId + '&InvoiceTypeId=' + invoiceTypeId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setProgressClaimNumber(res.data.progressClaimNumber);
            setInvoiceItems(res.data.claimLines);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleCellTextChange = (e: any, index: number) => {
        if (e.target.value) {
            clearError(e.target.name);
        }

        var invItems = [...invoiceItems];
        var invItem = invItems[index];
        invItem.description = e.target.value;

        setInvoiceItems(invItems);
        setSaveDisabled(false);
    }

    const handleCellAmountChange = (e: any, index: number) => {
        if (e.target.value) {
            clearError(e.target.name);
        }
        var value = e.target.value * 1;

        var invItems = [...invoiceItems];
        var invItem = invItems[index];
        invItem.total = value;

        setInvoiceItems(invItems);
        setSaveDisabled(false);
    }

    const clearError = (name: string) => {
        var error = errors;
        delete error[name];
        setErrors(error);
    }

    const validate = () => {
        let formIsValid = true;
        let error: any = {};

        if (invoiceTypeId < 1) {
            formIsValid = false;
            error["invoiceTypeId"] = "Invoice Type is required";
        } else if (invoiceTypeId === 2 && !worksCompleteText) {
            formIsValid = false;
            error["worksCompleteText"] = "Works Completed to date is required";
        }

        let invoiceTotal = 0;
        var itemError = "";
        for (var i = 0; i < invoiceItems.length; i++) {
            var invItem = invoiceItems[i];
            invoiceTotal += invItem.total;

            //check that they are not claiming more than what is available
            if (invItem.total > invItem.available) {
                formIsValid = false;
                itemError += invItem.description + " cannot claim more than " + invItem.availableFormatted + "\n";
            }
        }

        if (invoiceTotal === 0) {
            formIsValid = false;
            itemError += "Invoice Total must be > $0";
        }

        if (itemError != "") {
            error["invoiceTotal"] = itemError;
        }

        setErrors(error);
        return formIsValid;
    }

    const save = () => {
        //validate all data before saving
        if (validate()) {
            createMultipleInsurerInvoices();
        } else {
            toast.error("Please fix the validation issues before saving");
        }

    }

    const createMultipleInsurerInvoices = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var multiInvSave = {
            jobId: props.jobId,
            invoiceTypeId: invoiceTypeId,
            progressClaimNumber: progressClaimNumber,
            invoiceItems: invoiceItems,
            worksCompletedToDate: worksCompleteText,
            subId: user.sub
        };

        //save new contact
        axios.post('Jobs/SaveMultipleInsurerInvoices', multiInvSave, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Multiple Insurer Invoices Successfully");
                setInvoiceTypeId(-1);
                setInvoiceItems([]);
                props.save();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideModal = () => {
        setErrors({});
        
        props.hide();
    }

    return (
        <Modal show={props.show} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Create Multiple Insurer Invoices</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <label className="input-group" htmlFor="invoiceTypeId">
                    <span className="label">Invoice Type</span>
                    <select className="select" id="invoiceTypeId" name="invoiceTypeId" value={invoiceTypeId} onChange={(e) => handleTypeChange(e)} >
                        <option defaultValue="-1" value="-1"></option>
                        <option key={2} value={2}>Progress</option>
                        <option key={3} value={3}>Final</option>
                    </select>
                </label>
                <span className={errors["invoiceTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["invoiceTypeId"]}</span>

                <div className="marginTop10">Invoice Items</div>
                <div>NOTE: Enter $0.00 to exclude item from invoices</div>

                <div className='overflowAuto'>
                    <div className={loading ? "" : "hidden" }>Loading...</div>
                    <table className={loading ? "hidden" : 'table table__small tableColours'}>
                        <thead>
                            <tr>
                                <th className="hidden">Link Id</th>
                                <th className="hidden">Link Type Id</th>
                                <th>Description</th>
                                <th className="textalignright jobInvoice__multi-ins--colWidth">Total (incl GST)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceItems.map((detail, index) =>
                                <tr key={index}>
                                    <td className="hidden">{detail.linkId}</td>
                                    <td className="hidden">{detail.linkTypeId}</td>
                                    <td className="table__text--align">
                                        <input type='text' className="form-control table__tdmin-xlg" maxLength={3000} name="description" value={detail.description} onChange={(e) => handleCellTextChange(e, index)} />
                                    </td>
                                    <td className="table__text--align">
                                        <input type='number' className="form-control jobInvoice__multi-ins--amtWidth textalignright" name="total" value={detail.total} onChange={(e) => handleCellAmountChange(e, index)} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {errors["invoiceTotal"] ?
                        (errors["invoiceTotal"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>

                <div className={invoiceTypeId === 2 ? "" : "hidden"}>
                    <span>Note: 'Works Completed to date' will be added to invoice line before text entered in input box</span>
                    <label className="input-group" htmlFor="worksCompleteText">
                        <span className="label">Works Completed to date</span>
                        <textarea className="input job__noteSize" id="worksCompleteText" name="worksCompleteText" value={worksCompleteText} onChange={(e) => { clearError(e.target.name); setWorksCompleteText(e.target.value) } }></textarea>
                    </label>
                    <span className={errors["worksCompleteText"] ? "label errors errors__leftmargin" : "hidden"}>{errors["worksCompleteText"]}</span>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={hideModal}>Cancel</button>
                <button className="popup-button--yes" onClick={save} disabled={saveDisabled}>Create</button>
            </Modal.Footer>
        </Modal>

    )

}

export default AddMultipleInsurerInvoicePopup;
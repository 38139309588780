import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Pager from './Paging';
import authService from './api-authorization/AuthorizeService';

interface Result {
    pageSize: number;
    totalRecords: number;
    searchResults: SupplierList[];
}

interface SupplierList {
    id: number;
    name: string;
    primaryTrade: string;
    status: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierSearchData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [trades, setTrades] = React.useState<GenericListItem[]>([]);
    const [name, setName] = React.useState("");
    const [tradeId, setTradeId] = React.useState(-1);
    const [showInactive, setShowInactive] = React.useState(false);
    const [showResults, setShowResults] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [result, setResult] = React.useState<Result>({
        pageSize: 1,
        totalRecords: 0,
        searchResults: []
    });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateEditSuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Suppliers/GetTrades', {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setTrades(res.data);
                    setLoading(false);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addNew = () => {
        navigate("/supplier/add");
    }

    const handleTradeChange = (e: any) => {
        var newTradeId = e.target.value * 1;
        setTradeId(newTradeId);
    }

    const reset = () => {
        setName("");
        setTradeId(-1);
        setShowInactive(false);
        setPage(1);
        setResult({
            pageSize: 1,
            totalRecords: 0,
            searchResults: []
        });
        setShowResults(false);
    }

    const search = (e: any) => {
        e.preventDefault();

        //check to make sure at least one search field has been entered
        if (name || tradeId > 0) {
            getSuppliers(1, true);
        } else {
            setShowResults(false);
            toast.error("Please enter the supplier name or select a trade");
        }

    }

    const getSuppliers = async(page: number, getTotalRecords: boolean) => {
        const token = await authService.getAccessToken();
        var totalRecords = getTotalRecords ? 0 : result.totalRecords;

        axios.get('Suppliers/Search?Name=' + name + '&TradeId=' + tradeId + '&ShowInactive=' + showInactive + '&page=' + page + '&totalRecords=' + totalRecords + '&getTotalRecords=' + getTotalRecords, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setResult(res.data);
            setShowResults(true);
        })
    }

    const pageChanged = (pageNumber: number) => {
        setPage(pageNumber);
        getSuppliers(pageNumber, false);
    }

    const pagerContents = <Pager page={page} pageSize={result.pageSize} totalRecords={result.totalRecords} pageChanged={pageChanged} />

    const renderDetails = (
        <form onSubmit={search}>
            <div>
                <div className="input-group-parent3">
                    <label className="input-group" htmlFor="name">
                        <span className="label-small">Name</span>
                        <input className="input" type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)}></input>
                    </label>
                </div>
                <div className="input-group-parent3">
                    <label className="input-group" htmlFor="tradeId">
                        <span className="label-small">Trade</span>
                        <select className="select" id="tradeId" name="tradeId" value={tradeId} onChange={(e) => handleTradeChange(e)} >
                            <option defaultValue="-1"></option>
                            {trades.map(trade =>
                                <option key={trade.id} value={trade.id}>{trade.name}</option>
                            )}
                        </select>
                    </label>
                </div>
                <div className="input-group-parent3">
                    <label className="input-group" htmlFor="inactive">
                        <span className="label-small">Show Inactive</span>
                        <input className="input checkbox" type="checkbox" id="inactive" name="showInactive" checked={showInactive} onChange={(e) => setShowInactive(e.target.checked)}></input>
                    </label>
                </div>
                <div>
                    <button className="defaultbutton-prev defaultbutton-prev__split3" type='submit'>Search</button>
                    <button className="defaultbutton-next" type='button' onClick={reset}>Reset</button>
                </div>
                <hr></hr>

                <div className={showResults ? 'overflowAuto' : 'hidden'}>
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th>Name</th>
                                <th>Primary Trade</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result.searchResults.map(supplier =>
                                <tr key={supplier.id}>
                                    <td className="hidden">{supplier.id}</td>
                                    <td><NavLink to={'/supplier/' + supplier.id}>{supplier.name}</NavLink></td>
                                    <td>{supplier.primaryTrade}</td>
                                    <td>{supplier.status}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={(result.totalRecords / result.pageSize) > 1 ? "" : "hidden"}>
                    {pagerContents}
                </div>
            </div>

        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            <div className="input-group-parent marginBottom10">
                <h1>Supplier Search</h1>
            </div>
            <div className="input-group-parent marginBottom10">
                <div className="defaultbutton__container--right">
                    <button className="defaultbutton defaultbutton__small marginLeft10" type='button' onClick={addNew}>Add New</button>
                </div>
            </div>
            {contents}
        </div>
    )
    
}

export default SupplierSearchData;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import SupplierAddDetails from './SupplierAddDetails';
import authService from './api-authorization/AuthorizeService';

interface AddSupplierPopupProps {
    showModal: boolean;
    hideModal(): void;
    saveModal(supplierId: number): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierAddNewPopup = (props: AddSupplierPopupProps) => {
    const [trades, setTrades] = React.useState<GenericListItem[]>([]);
    const [otherTrades, setOtherTrades] = React.useState<GenericListItem[]>([]);
    const [statusOptions, setStatusOptions] = React.useState<GenericListItem[]>([]);
    const [termOptions, setTermOptions] = React.useState<GenericListItem[]>([]);
    const [supplierTypes, setSupplierTypes] = React.useState<GenericListItem[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        axios.get('Suppliers/GetDropdowns', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setTrades(res.data.trades);
            setOtherTrades(res.data.trades);
            setStatusOptions(res.data.statusOptions);
            setTermOptions(res.data.termOptions);
            setSupplierTypes(res.data.supplierTypes);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideModal = () => {
        props.hideModal();
    }

    const savedSupplier = (supplierId: number) => {
        props.saveModal(supplierId);
    }
    
    var contents = <SupplierAddDetails id={0} statusOptions={statusOptions} termOptions={termOptions} trades={trades} otherTrades={otherTrades} supplierTypes={supplierTypes} backToSearch={savedSupplier} />

    return (
        <Modal size="lg" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add New Supplier</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                {contents}
            </Modal.Body>
        </Modal>
    );
    
}

export default SupplierAddNewPopup;
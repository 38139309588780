export function getCurrentDate() {
    var date = new Date();
    var currentMonth = (date.getMonth() + 1);
    var month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    var currentDay = date.getDate();
    var day = currentDay < 10 ? "0" + currentDay : currentDay;

    return date.getFullYear() + "-" + month + "-" + day;
};


export function addDaysToCurrentDate(days: number) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    //format date
    var currentMonth = (date.getMonth() + 1);
    var month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    var currentDay = date.getDate();
    var day = currentDay < 10 ? "0" + currentDay : currentDay;

    return date.getFullYear() + "-" + month + "-" + day;
}

export function addDaysToDate(dateToModify: string, days: number) {
    var date = new Date(dateToModify);
    date.setDate(date.getDate() + days);
    //format date
    var currentMonth = (date.getMonth() + 1);
    var month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    var currentDay = date.getDate();
    var day = currentDay < 10 ? "0" + currentDay : currentDay;

    return date.getFullYear() + "-" + month + "-" + day;
}

export function addYeartoCurrentDate(noYears: number) {
    var date = new Date();
    var currentMonth = (date.getMonth() + 1);
    var month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    var currentDay = date.getDate();
    var day = currentDay < 10 ? "0" + currentDay : currentDay;

    return (date.getFullYear() + noYears) + "-" + month + "-" + day;
}

export function isValidDate(date: any) {
    var timestamp = Date.parse(date);

    if (isNaN(timestamp) == false) {
        return true;
    }
    return false;
}

export function isNumber(number: any) {
    return !isNaN(parseFloat(number)) && !isNaN(number - 0);
}

export function isDateInPast(date: any) {
    var currentdate = new Date();
    currentdate.setHours(0);
    currentdate.setMinutes(0);
    currentdate.setSeconds(0);
    return new Date(date) < currentdate; // true if currentdate is earlier
}

export function isDateInFuture(date: any) {
    var currentdate = new Date();
    currentdate.setHours(0);
    currentdate.setMinutes(0);
    currentdate.setSeconds(0);
    var dateToCheck = new Date(date);   //adds current time
    dateToCheck.setHours(0);
    dateToCheck.setMinutes(0);
    dateToCheck.setSeconds(0);
    return dateToCheck > currentdate; // true if currentdate is later
}

export function compareDate(date1: string, date2: string) {
    return new Date(date1) <= new Date(date2); // true if date2 is equal or later
}

export function compareDateGreater(date1: string, date2: string) {
    return new Date(date1) < new Date(date2); // true if date2 is later
}

export function formatDate(date: any) {
    var dateToFormat = new Date(date);
    var currentMonth = (dateToFormat.getMonth() + 1);
    var month = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    var currentDay = dateToFormat.getDate();
    var day = currentDay < 10 ? "0" + currentDay : currentDay;

    return (day + "/" + month + "/" + dateToFormat.getFullYear());
}

export function daysBetweenDates(dateStart: string, dateEnd: string) {
    var startDate = new Date(dateStart);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    var endDate = new Date(dateEnd);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    var daysdiff = ((endDate.valueOf() - startDate.valueOf()) / millisecondsPerDay);

    return Math.round(daysdiff);
}

export function businessDaysBetweenDates(dateStart: string, dateEnd: string) {
    var totalDays = 0;
    var startDate = new Date(dateStart);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    var endDate = new Date(dateEnd);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);

    while (startDate <= endDate) {

        var weekDay = startDate.getDay();
        if (weekDay != 0 && weekDay != 6)
            totalDays++;

        startDate.setDate(startDate.getDate() + 1);
    }

    return totalDays;
}


export function validTimeAmount(timeAmount: string) {
    if (timeAmount.length < 4 || timeAmount.length > 5) {
        return false;
    }
    if (!(timeAmount.indexOf(":") == 1 || timeAmount.indexOf(":") == 2)) {
        return false;
    }

    var sHours = timeAmount.split(':')[0];
    var sMinutes = timeAmount.split(':')[1];

    if (sHours == "" || parseInt(sHours) > 24) {
        return false;
    }
    if (sMinutes == "" || parseInt(sMinutes) < 0 || parseInt(sMinutes) > 59) {
        return false;
    }

    return true;
}

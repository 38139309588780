import * as React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';

interface JobPurchaseOrderAddDetailsMobileProps {
    jobId: number;
    purchaseOrderId: number;
    registeredForGst: boolean;
    canExceedBudget: boolean;
    gstValue: number;
    purchaseOrderDetail: PurchaseOrderDetail;
    costCodeBudgetCheck: CostCodeBudgetCheck[];
    costCodeList: GenericListItem[];
    uomList: GenericListItem[];
    back(): void;
    addNew(): void;
    next(): void;
    update(purchaseOrderDetail: PurchaseOrderDetail): void;
    cancel(): void;
}

interface PurchaseOrderDetail {
    itemIndex: number;
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    costCodeIdOrig: number;     //need to put back original values if they click back button
    costCodeOrig: string;
    quantityOrig: number;
    quantityTypeOrig: string;
    unitOfMeasureOrig: number;
    unitCostOrig: number;
    unitCostOrigFormatted: string;
    totalOrig: number;
    totalOrigFormatted: string;
    exceedForecastReason: string;
    newItem: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

//remaining => based on forcast, budgetRemaining => based on estimate
interface CostCodeBudgetCheck {
    costCodeId: number;
    remaining: number;              //after catering for any items added on this po
    origRemaining: number;          //from the database based on existing saved po
    budgetRemaining: number;        //after catering for any items added on this po
    origBudgetRemaining: number;    //from the database based on existing saved po
    currentItemTotal: number;       //used to cater for user changing cost codes when adding/editing an item
}

const JobPurchaseOrderAddDetailsMobileData = (props: JobPurchaseOrderAddDetailsMobileProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const cancel = (e: any) => {
        e.preventDefault();
        props.cancel();
    }

    const back = (e: any) => {
        e.preventDefault();

        //reset any totals that have changed
        var poDetails = props.purchaseOrderDetail;
        poDetails.costCodeId = poDetails.costCodeIdOrig;
        poDetails.costCode = poDetails.costCodeOrig;
        poDetails.quantity = poDetails.quantityOrig;
        poDetails.quantityType = poDetails.quantityTypeOrig;
        poDetails.unitOfMeasure = poDetails.unitOfMeasureOrig;
        poDetails.unitCost = poDetails.unitCostOrig;
        poDetails.unitCostFormatted = poDetails.unitCostOrigFormatted;
        poDetails.total = poDetails.totalOrig;
        poDetails.totalFormatted = poDetails.totalOrigFormatted;

        props.update(poDetails);

        props.back();
    }

    const next = (e: any) => {
        e.preventDefault();

        var poDetail = props.purchaseOrderDetail;
        if (validate(true)) {
            if (poDetail.costCodeId > 0 && poDetail.quantity > 0 && poDetail.unitCost > 0) {
                //only add if it isn't a blank item
                props.addNew();
            }
            
            props.next();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const addNew = (e: any) => {
        e.preventDefault();

        //need to validate and save existing line
        if (validate(false)) {
            props.addNew();
        }
        else {
            toast.error("Please fix validation issues before adding a new item!");
        }
    }

    //change events
    const handleChange = (e: any) => {
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        var detail = props.purchaseOrderDetail;
        let newDetail: any = {};
        newDetail = detail;
        newDetail[e.target.name] = e.target.value;
        newDetail["isDirty"] = true;

        if (e.target.name === "unitOfMeasure") {
            //update quantity type
            if (e.target.value <= 0) {
                newDetail["quantityType"] = "";
            } else {
                newDetail["quantityType"] = detail.quantity.toString() + ' ' + e.target.options[e.target.selectedIndex].text;
            }
        }

        props.update(newDetail);
    }

    const handleCostCodeChange = (e: any) => {
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        var detail = props.purchaseOrderDetail;
        let newDetail: any = {};
        newDetail = detail;
        let costCodeId = e.target.value * 1;
        newDetail[e.target.name] = costCodeId;
        newDetail["costCode"] = e.target.options[e.target.selectedIndex].text;

        //get cost code budget, forecast and remaining amounts
        if (costCodeId < 1) {
            newDetail.budget = 0;
            newDetail.budgetFormatted = "";
            newDetail.forecast = 0;
            newDetail.forecastFormatted = "";
            newDetail.remaining = 0;
            newDetail.remainingFormatted = "";

            props.update(newDetail);
        } else {
            getEstimateByCostCode(costCodeId, newDetail);
        }
    }

    const getEstimateByCostCode = async(costCodeId: number, newDetail: PurchaseOrderDetail) => {
        const token = await authService.getAccessToken();

        axios.get('Estimate/GetEstimateByCostCode?JobId=' + props.jobId + '&PurchaseOrderId=' + props.purchaseOrderId + '&CostCodeId=' + costCodeId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            newDetail.budget = res.data.budget;
            newDetail.budgetFormatted = res.data.budgetFormatted;
            newDetail.forecast = res.data.forecast;
            newDetail.forecastFormatted = res.data.forecastFormatted;
            newDetail.remaining = res.data.remaining;
            newDetail.remainingFormatted = res.data.remainingFormatted;
            newDetail.budgetRemaining = res.data.budgetRemaining;

            //CURRENT COST CODE
            //check if a previous line item has the same cost code and if so update the remaining and budget remaining
            var costCodeBudgetCheck = props.costCodeBudgetCheck;
            var costCodeItem = costCodeBudgetCheck.find(b => b.costCodeId === costCodeId);
            if (costCodeItem) {
                newDetail.remaining = (costCodeItem.remaining * 1);
                newDetail.remainingFormatted = newDetail.remaining.toLocaleString('en-AU', { style: 'currency', currency: 'AUD', });
                newDetail.budgetRemaining = (costCodeItem.budgetRemaining * 1);
            }

            props.update(newDetail);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const handleAmountChange = (e: any) => {
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        var detail = props.purchaseOrderDetail;
        let newDetail: any = {};
        newDetail = detail;
        newDetail[e.target.name] = e.target.value;
        newDetail["isDirty"] = true;

        //update totals
        //calculate unit cost / total
        var total = 0;
        if (e.target.name === "total" && newDetail.quantity > 0) {
            var unitCost = (e.target.value / newDetail.quantity);
            newDetail["unitCost"] = unitCost.toFixed(2);
            newDetail["unitCostFormatted"] = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
            total = e.target.value * 1;
            newDetail["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        } else if (e.target.name === "unitCost" && newDetail.quantity > 0) {
            var unitCost = e.target.value * 1;
            newDetail["unitCostFormatted"] = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
            total = ((e.target.value * 1) * newDetail.quantity);
            newDetail["total"] = total.toFixed(2);
            newDetail["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        } else {
            //quantity
            total = ((e.target.value * 1) * newDetail.unitCost);
            newDetail["total"] = total.toFixed(2);
            newDetail["totalFormatted"] = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });

            //update quantity type
            var uomItem = detail.unitOfMeasure * 1;
            if (uomItem > 0) {
                var uom = props.uomList.find(u => u.id === uomItem);
                if (uom) {
                    var qty = e.target.value;
                    newDetail["quantityType"] = qty.toString() + ' ' + uom.name;
                }
            }
        }
        
        props.update(newDetail);
    }
    //end change events

    const validate = (allowBlank: boolean) => {
        let fields = props.purchaseOrderDetail;
        let error: any = {};
        let formIsValid = true;
        let quantity = fields.quantity;
        let unitCost = fields.unitCost * 1;

        //when finishing, if all items are blank they can go to the confirm screen
        if (allowBlank && fields.costCodeId < 0 && quantity === 0 && unitCost === 0) {
            setErrors(error);
            return formIsValid;
        }

        if (fields.costCodeId < 0) {
            error["costCodeId"] = "Cost Code is required ";
            formIsValid = false;
        }

        if (quantity === 0) {
            error["quantity"] = "Quantity is required ";
            formIsValid = false;
        }

        if (fields.unitOfMeasure < 0) {
            error["unitOfMeasure"] = "UOM is required ";
            formIsValid = false;
        }

        if (unitCost === 0) {
            error["unitCost"] = "Unit Cost is required ";
            formIsValid = false;
        }

        let total = fields.total * 1;
        if (!props.canExceedBudget && total > fields.budgetRemaining) {
            error["total"] = "Cannot exceed Budget ";
            formIsValid = false;
        }

        let remaining = fields.remaining * 1;
        if (total > remaining && !fields.exceedForecastReason) {
            error["exceedForecastReason"] = "Exceed Forecast Reason is required ";
            formIsValid = false;
        }

        setErrors(error);
        return formIsValid;
    }

    let headingText = props.purchaseOrderId === 0 ? "Add Purchase Order" : "Edit Purchase Order";

    return (
        <div className="marginBottom50">
            <div className="makeitflexspacebetween">
                <h4 className="mobile-margin">{headingText}</h4>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-red" href="#" onClick={(e) => cancel(e)}>
                        <span className="fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
            <div className="mobileFont">
                <label className="input-group" htmlFor="costCodeId">
                    <span className="label">Cost Code</span>
                    <select className="select table__select--size" name="costCodeId" value={props.purchaseOrderDetail.costCodeId} onChange={(e) => handleCostCodeChange(e)} >
                        <option hidden defaultValue="-1"></option>
                        {props.costCodeList.map(item =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["costCodeId"] ? "label errors" : "hidden"}>{errors["costCodeId"]}</span>

                <label className="input-group" htmlFor="budgetFormatted">
                    <span className="label">Budget</span>
                    <input className="input" type="text" id="budgetFormatted" name="budgetFormatted" value={props.purchaseOrderDetail.budgetFormatted} disabled></input>
                </label>

                <label className="input-group" htmlFor="forecastFormatted">
                    <span className="label">Forecast</span>
                    <input className="input" type="text" id="forecastFormatted" name="forecastFormatted" value={props.purchaseOrderDetail.forecastFormatted} disabled></input>
                </label>

                <label className="input-group" htmlFor="remainingFormatted">
                    <span className="label">Remaining</span>
                    <input className="input" type="text" id="remainingFormatted" name="remainingFormatted" value={props.purchaseOrderDetail.remainingFormatted} disabled></input>
                </label>

                <label className="input-group" htmlFor="quantity">
                    <span className="label">Quantity</span>
                    <input type='number' min="0" step="any" className='input' name="quantity" value={props.purchaseOrderDetail.quantity} onChange={(e) => handleAmountChange(e)} />
                </label>
                <span className={errors["quantity"] ? "label errors" : "hidden"}>{errors["quantity"]}</span>

                <label className="input-group" htmlFor="unitOfMeasure">
                    <span className="label">UOM</span>
                    <select className="select table__select--size" name="unitOfMeasure" value={props.purchaseOrderDetail.unitOfMeasure} onChange={(e) => handleChange(e)} >
                        <option hidden defaultValue="-1"></option>
                        {props.uomList.map(item =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["unitOfMeasure"] ? "label errors" : "hidden"}>{errors["unitOfMeasure"]}</span>

                <label className="input-group" htmlFor="unitCost">
                    <span className="label">Unit Cost</span>
                    <input type='number' min="0" step="any" className='input' name="unitCost" value={props.purchaseOrderDetail.unitCost} onChange={(e) => handleAmountChange(e)} />
                </label>
                <span className={errors["unitCost"] ? "label errors" : "hidden"}>{errors["unitCost"]}</span>

                <label className="input-group" htmlFor="total">
                    <span className="label">Total</span>
                    <input type='number' min="0" step="any" className='input' name="total" value={props.purchaseOrderDetail.total} onChange={(e) => handleAmountChange(e)} />
                </label>
                <span className={errors["total"] ? "label errors" : "hidden"}>{errors["total"]}</span>

                <label className={props.purchaseOrderDetail.total > props.purchaseOrderDetail.remaining ? "input-group" : "hidden"} htmlFor="exceedForecastReason">
                    <span className="label">Exceed Forecast Reason</span>
                    <textarea className="input job__noteSize" id="exceedForecastReason" name="exceedForecastReason" value={props.purchaseOrderDetail.exceedForecastReason} onChange={(e) => handleChange(e)}></textarea>
                    <span className={errors["exceedForecastReason"] ? "label errors" : "hidden"}>{errors["exceedForecastReason"]}</span>
                </label>

            </div>
            <hr></hr>
            <div className="bottom-edit-nav makeitflexspacebetween">
                <div className="jobpo__backButton">
                    <a className="mobile-icon-grey" href="#" onClick={(e) => back(e)}>
                        <span className="fas fa-arrow-alt-circle-left mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => addNew(e)}>
                        <span className="fas fa-plus-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => next(e)}>
                        <span className="fas fa-arrow-alt-circle-right mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default JobPurchaseOrderAddDetailsMobileData;
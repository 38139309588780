import * as React from 'react';

interface NewJobNoteProps {
    currentStep: number;
    notes: string;
    prev(): void;
    finish(): void;
    update(data: any): void;
}

const NewJobNote = (props: NewJobNoteProps) => {
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const handleChange = (e: any) => {
        setSaveDisabled(false);
        
        props.update(e.target.value);
    }

    const finish = () => {
        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);

        props.finish();
    }

    if (props.currentStep !== 4) {
        return null;
    }
    return (
        <form>
            <p> Enter any notes from the conversation with the customer </p>
                
            <label className="input-group" htmlFor="notes">
                <textarea className="input notes__size" id="notes" name="notes" value={props.notes} onChange={(e) => handleChange(e)}></textarea>
            </label>
                
            <button className="defaultbutton" onClick={props.prev} type="button">Prev</button>
            <button className="defaultbutton-next" onClick={finish} type="button" disabled={ saveDisabled }>Finish</button>
        </form>
    );
}

export default NewJobNote;
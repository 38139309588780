import * as React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';

interface MatchingJobs {
    id: number;
    makeSafe: boolean;
    jobType: string;
    siteAddress: string;
    unitNumber: string;
    insurer: string;
    adjuster: string;
    broker: string;
    customer: string;
}

interface NewJobMatchProps {
    copyJobId: number;
    currentStep: number;
    siteAddress: string;
    goPrev: boolean;
    prev(): void;
    next(): void;
}

const NewJobMatchingJob = (props: NewJobMatchProps) => {
    const [loading, setLoading] = React.useState(true);
    const [jobs, setJobs] = React.useState<MatchingJobs[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var siteAddress = props.siteAddress;
        if (siteAddress) {
            axios.get('Jobs/GetMatchingJobs?siteAddress=' + encodeURIComponent(siteAddress) + "&copyJobId=" + props.copyJobId + "&subId=" + user.sub, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                setJobs(res.data);
                setLoading(false);

                if (res.data.length == 0) {
                    if (props.goPrev) {
                        props.prev();
                    } else {
                        //skip this step
                        props.next();
                    }
                }
            });
        }
    }

    if (props.currentStep !== 2) {
        return null;
    }

    const renderDetails = (
        <form>
            <div className="overflowAuto newJobMatchingJobs__bottomPadding">
                <table className="table--main newJobMatchingJobs__tableSize tableColours">
                    <thead>
                        <tr>
                            <th>Job Id</th>
                            <th>MakeSafe?</th>
                            <th>Job Type</th>
                            <th>Unit Number</th>
                            <th>Site Address</th>
                            <th>Insurer</th>
                            <th>Adjuster</th>
                            <th>Broker</th>
                            <th>Customer</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.map((job, index) =>
                            <tr key={job.id}>
                                <td><a href={"/job/" + job.id + "/details"} target="_blank">{job.id}</a></td>
                                <td className={job.makeSafe ? "makeSafeJob" : ""}>{job.makeSafe ? "MakeSafe" : ""}</td>
                                <td>{job.jobType}</td>
                                <td className={job.makeSafe ? "makeSafeJob" : ""}>{job.unitNumber}</td>
                                <td className={job.makeSafe ? "makeSafeJob" : ""}>{job.siteAddress}</td>
                                <td>{job.insurer}</td>
                                <td>{job.adjuster}</td>
                                <td>{job.broker}</td>
                                <td>{job.customer}</td>
                                <td className="table__text--align"><NavLink to={'/newjob/' + job.id} target="_blank">Copy Job</NavLink></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <button className="defaultbutton" onClick={props.prev} type="button">Prev</button>
            <button className="defaultbutton-next" onClick={props.next} type="button">Next</button>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default NewJobMatchingJob;
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';

import PermissionFailed from './components/PermissionFailed';

import AdminListData from './components/AdminLists';
import AdminCostCodeData from './components/AdminCostCodes';
import AdminPricelistReportData from './components/AdminPricelistReports';
import AdminProductData from './components/AdminProducts';
import AdminShortcutData from './components/AdminShortcuts';
import AdminSettingsData from './components/AdminSettings';

import JobData from './components/Jobs';
import NewJobData from './components/NewJob';
import JobDetailsData from './components/JobDetails';
import EstimateData from './components/JobEstimate';
import JobNotesData from './components/JobNotes';
import VariationData from './components/JobVariation';
import JobCostData from './components/JobCosts';
import JobFilesData from './components/JobFiles';
import JobOnGoingHireData from './components/JobOnGoingHire';
import JobScheduleData from './components/JobSchedule';
import InvoiceListData from './components/Invoices';
import InvoiceDetailsData from './components/InvoiceDetails';
import JobInvoiceData from './components/JobInvoice';

import XeroRedirectData from './components/XeroRedirect';

import ClientSearchData from './components/ClientSearch';
import ClientAdd from './components/ClientAdd';
import ClientData from './components/Client';

import SupplierSearchData from './components/SupplierSearch';
import SupplierAdd from './components/SupplierAdd';
import SupplierData from './components/Supplier';
import SupplierInvoicesData from './components/SupplierInvoices';

import ReportViewerData from './components/ReportViewer';
import JobReportViewerData from './components/JobReportViewer';

import ReportData from './components/Reports';
import ReportInsurerUpdateData from './components/ReportInsurerUpdate';
import ReportJobMarkupMarginData from './components/ReportJobMarkupMargin';
import ReportJobRepairLifeData from './components/ReportJobRepairLife';
import ReportJobQuoteLifeData from './components/ReportJobQuoteLife';
import ReportJobTenderData from './components/ReportJobTender';
import ReportJobMarkupMarginHistoryData from './components/ReportJobMarkupMarginHistory';
import ReportJobWipData from './components/ReportJobWip';
import ReportJobWorkInHandData from './components/ReportWorkInHand';
import ReportJobInvoicedData from './components/ReportJobInvoiced';
import ReportJobInvoicedProfitData from './components/ReportJobInvoicedProfit';
import ReportJobEstimatorData from './components/ReportJobEstimator';
import ReportJobsWonLostData from './components/ReportJobsWonLost';
import ReportJobsRequiringInvoiceData from './components/ReportJobRequiringInvoice';
import ReportJobSummaryData from './components/ReportJobSummary';
import ReportEstActualCompletionData from './components/ReportEstActualCompletionDate';
import ReportMakeSafeJobsData from './components/ReportMakeSafeJobs';
import ReportOpenPurchaseOrdersData from './components/ReportOpenPurchaseOrders';
import ReportMissingDataJobsData from './components/ReportJobMissingData';
import ReportJobValueByTypeData from './components/ReportJobValueByType.tsx';
import ReportJobValueByClaimTypeData from './components/ReportJobValueByClaimType';
import ReportJobClaimStatusData from './components/ReportJobClaimStatus';

import UserSearchData from './components/UserSearch';
import UserData from './components/User';

//Mobile Pages
import JobDetailsMobileData from './components/JobDetailsMobile';
import JobNotesMobileData from './components/JobNotesMobile';
import SignatureMobileData from './components/SignatureMobile';
import EstimateMobileData from './components/JobEstimateMobile';
import JobCostingsMobileData from './components/JobCostingsMobile';
import JobPurchaseOrdersMobileData from './components/JobPurchaseOrdersMobile';
import JobPurchaseOrdersAddMobileData from './components/JobPurchaseOrdersMobileAdd';
import JobAssessmentMobileData from './components/JobAssessmentMobile';
import NewJobMakeSafeMobileData from './components/NewJobMakeSafeMobile';
import ScheduleCalendarData from './components/ScheduleCalendar';


const AppRoutes = [
    {
        index: true, requireAuth: true, element: <JobData />
    },
    { path: '/job/:id/detailsm', requireAuth: true, element: <JobDetailsMobileData /> },
    { path: '/job/:id/estimatem', requireAuth: true, element: <EstimateMobileData /> },
    { path: '/job/:id/assessmentm', requireAuth: true, element: <JobAssessmentMobileData /> },
    { path: '/job/:id/costsm', requireAuth: true, element: <JobCostingsMobileData /> },
    { path: '/job/:id/purchaseordersm', requireAuth: true, element: <JobPurchaseOrdersMobileData /> },
    { path: '/job/:jobid/purchaseorderdetailsm/:id?', requireAuth: true, element: <JobPurchaseOrdersAddMobileData /> },
    { path: '/job/:id/notesm', requireAuth: true, element: <JobNotesMobileData /> },
    { path: '/job/:id/signaturem', requireAuth: true, element: <SignatureMobileData /> },
    { path: '/createmsjob', requireAuth: true, element: <NewJobMakeSafeMobileData /> },

    { path: '/newjob/:copyjobid?', requireAuth: true, element: <NewJobData /> },
    { path: '/job/:id/details', requireAuth: true, element: <JobDetailsData /> },
    { path: '/job/:id/estimate', requireAuth: true, element: <EstimateData /> },
    { path: '/job/:id/costings/:poid?', requireAuth: true, element: <JobCostData /> },
    { path: '/job/:id/schedule', requireAuth: true, element: <JobScheduleData /> },
    { path: '/job/:id/ongoinghire', requireAuth: true, element: <JobOnGoingHireData /> },
    { path: '/job/:id/variations', requireAuth: true, element: <VariationData /> },
    { path: '/job/:id/notes', requireAuth: true, element: <JobNotesData /> },
    { path: '/job/:id/files', requireAuth: true, element: <JobFilesData /> },
    { path: '/job/:id/report/:tableid/:name', requireAuth: true, element: <JobReportViewerData /> },
    { path: '/job/:id/invoices', requireAuth: true, element: <JobInvoiceData /> },

    { path: '/report/:name/:param1/:param2/:param3/:param4?/:param5?', requireAuth: true, element: <ReportViewerData /> },
    { path: '/reports/insurerupdate', requireAuth: true, element: <ReportInsurerUpdateData /> },
    { path: '/reports/jobmarkupmarginhistory', requireAuth: true, element: <ReportJobMarkupMarginHistoryData /> },
    { path: '/reports/jobmarkupmargin', requireAuth: true, element: <ReportJobMarkupMarginData /> },
    { path: '/reports/jobtender', requireAuth: true, element: <ReportJobTenderData /> },
    { path: '/reports/jobrepairlife', requireAuth: true, element: <ReportJobRepairLifeData /> },
    { path: '/reports/jobquotelife', requireAuth: true, element: <ReportJobQuoteLifeData /> },
    { path: '/reports/jobwip', requireAuth: true, element: <ReportJobWipData /> },
    { path: '/reports/jobworkinhand', requireAuth: true, element: <ReportJobWorkInHandData /> },
    { path: '/reports/jobsinvoiced', requireAuth: true, element: <ReportJobInvoicedData /> },
    { path: '/reports/jobsinvoicedprofit', requireAuth: true, element: <ReportJobInvoicedProfitData /> },
    { path: '/reports/jobmissingdata', requireAuth: true, element: <ReportMissingDataJobsData /> },
    { path: '/reports/jobestimator', requireAuth: true, element: <ReportJobEstimatorData /> },
    { path: '/reports/jobswonlost', requireAuth: true, element: <ReportJobsWonLostData /> },
    { path: '/reports/jobsrequiringinvoice', requireAuth: true, element: <ReportJobsRequiringInvoiceData /> },
    { path: '/reports/jobsummary', requireAuth: true, element: <ReportJobSummaryData /> },
    { path: '/reports/estactualcompletiondate', requireAuth: true, element: <ReportEstActualCompletionData /> },
    { path: '/reports/makesafejobs', requireAuth: true, element: <ReportMakeSafeJobsData /> },
    { path: '/reports/openpurchaseorders', requireAuth: true, element: <ReportOpenPurchaseOrdersData /> },
    { path: '/reports/jobvaluebytype', requireAuth: true, element: <ReportJobValueByTypeData /> },
    { path: '/reports/jobvaluebyclaimtype', requireAuth: true, element: <ReportJobValueByClaimTypeData /> },
    { path: '/reports/jobclaimstatus', requireAuth: true, element: <ReportJobClaimStatusData /> },
    { path: '/reports', requireAuth: true, element: <ReportData /> },
    { path: '/clientsearch', requireAuth: true, element: <ClientSearchData /> },
    { path: '/client/add', requireAuth: true, element: <ClientAdd /> },
    { path: '/client/sendinvoices', requireAuth: true, element: <InvoiceListData /> },
    { path: '/client/invoicedetails/:jobid/:id', requireAuth: true, element: <InvoiceDetailsData /> },
    { path: '/client/:id?', requireAuth: true, element: <ClientData /> },
    { path: '/suppliersearch', requireAuth: true, element: <SupplierSearchData /> },
    { path: '/supplier/add', requireAuth: true, element: <SupplierAdd /> },
    { path: '/supplier/invoices', requireAuth: true, element: <SupplierInvoicesData /> },
    { path: '/supplier/:id?', requireAuth: true, element: <SupplierData /> },
    { path: '/usersearch', requireAuth: true, element: <UserSearchData /> },
    { path: '/user/:id?', requireAuth: true, element: <UserData /> },
    { path: '/schedulecalendar', requireAuth: true, element: <ScheduleCalendarData /> },

    { path: '/adminlists', requireAuth: true, element: <AdminListData /> },
    { path: '/admincostcodes', requireAuth: true, element: <AdminCostCodeData /> },
    { path: '/adminshortcuts', requireAuth: true, element: <AdminShortcutData /> },
    { path: '/adminproducts', requireAuth: true, element: <AdminProductData /> },
    { path: '/adminpricelistreports', requireAuth: true, element: <AdminPricelistReportData /> },
    { path: '/adminsettings', requireAuth: true, element: <AdminSettingsData /> },
    { path: '/xeroredirect', requireAuth: true, element: <XeroRedirectData /> },
    { path: '/accessdenied', requireAuth: true, element: <PermissionFailed /> },

    ...ApiAuthorzationRoutes
];

export default AppRoutes;

import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';
import * as DateHelpers from './DateHelpers';
import SupplierInvoiceFilePopup from './SupplierInvoiceFile';

interface SupplierCertificateProps {
    id: number;
    supplierEmail: string;
    supplierCertificates: SupplierCertificate[];
    certificateTypes: GenericListItem[];
    update(supplierCertificates: SupplierCertificate[]): void;  //update list of certificates - used when deleting
}

interface SupplierCertificate {
    id: number;
    supplierId: number;
    typeId: number;
    type: string;
    certificateNumber: string;
    certificateName: string;
    expiryDate: string;
    sendExpiryNotification: boolean;
    insertedBy: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierCertificates = (props: SupplierCertificateProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [buttonDisabled, setButtonDisabled] = React.useState({ modalSaveDisabled: false, saveDisabled: false });
    const [confirmDelete, setConfirmDelete] = React.useState({ certificateIndex: -1, showConfirmModal: false });
    const [selectedFile, setSelectedFile] = React.useState(new File([""], "filename"));
    const [uploadedCertificate, setUploadedCertificate] = React.useState(new File([""], "filename"));
    const [showAddCertificate, setShowAddCertificate] = React.useState(false);
    const [addCertificate, setAddCertificate] = React.useState<SupplierCertificate>({
        id: 0,
        supplierId: props.id,
        typeId: -1,
        type: "",
        certificateNumber: "",
        certificateName: "",
        expiryDate: "",
        sendExpiryNotification: true,
        insertedBy: ""
    });
    const [supplierCertificateId, setSupplierCertificateId] = React.useState(0);
    const [showCertificate, setShowCertificate] = React.useState(false);

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
        }

        //update item that has changed
        let change: any = {};
        change = addCertificate;
        change[e.target.name] = e.target.value;

        if (e.target.name === "typeId") {
            let value = e.target.value * 1;
            if (value === 1 || value === 2) {
                //make sure send notification is true for Work Cover and Public Liability
                change["sendExpiryNotification"] = true;
                change["certificateName"] = "";
            }
        }

        setAddCertificate(change);
        setButtonDisabled({ modalSaveDisabled: false, saveDisabled: false });
    }

    const handleCheckboxChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
        }

        //update item that has changed
        let change: any = {};
        change = addCertificate;
        change[e.target.name] = e.target.checked;

        setAddCertificate(change);
        setButtonDisabled({ modalSaveDisabled: false, saveDisabled: false });
    }

    const handleImportFile = (e: any) => {
        setSelectedFile(e.target.files[0]);
    }

    //add certificate
    const cancelAddCertificate = (e: any) => {
        e.preventDefault();
        var certificate = resetCertificate();
        setAddCertificate(certificate);
        setShowAddCertificate(false);
        setErrors({});
    }

    const resetCertificate = () => {
        let certificate = {
            id: 0,
            supplierId: props.id,
            typeId: -1,
            type: "",
            certificateNumber: "",
            certificateName: "",
            expiryDate: "",
            sendExpiryNotification: true,
            insertedBy: ""
        };

        return certificate;
    }

    const validate = () => {
        let fields = addCertificate;
        let errors: any = {};
        let formIsValid = true;

        let typeId = fields.typeId * 1;
        if (typeId < 0) {
            formIsValid = false;
            errors["typeId"] = "Certificate Type is required";
        }

        if (!fields.certificateNumber) {
            formIsValid = false;
            errors["certificateNumber"] = "Certificate Number is required";
        }

        //public liability and work cover must have an expiry date
        if (typeId === 1 || typeId === 2) {
            if (!fields.expiryDate) {
                formIsValid = false;
                errors["expiryDate"] = "Expiry Date is required";
            }
        }

        if (typeId === 3) {
            if (!fields.certificateName) {
                formIsValid = false;
                errors["certificateName"] = "Certificate Name is required";
            }
        }

        //if expiry date entered, make sure it is a valid date and > today
        if (fields.expiryDate) {
            if (!DateHelpers.isValidDate(fields.expiryDate)) {
                formIsValid = false;
                errors["expiryDate"] = "Valid Expiry Date is required";
            } else if (DateHelpers.isDateInPast(fields.expiryDate)) {
                formIsValid = false;
                errors["expiryDate"] = "Expiry Date > today";
            }
        }

        //check to make sure a file has been uploaded
        if (selectedFile.size === 0) {
            formIsValid = false;
            errors["certificate"] = "Certificate is required";
        }

        setErrors(errors);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (buttonDisabled.saveDisabled) {
                return;
            }
            setButtonDisabled({ saveDisabled: true, modalSaveDisabled: false });
            saveCertificate();
        } else {
            toast.error("Please fix errors before saving!");
        }
    }

    const saveCertificate = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var supplierCertificates = props.supplierCertificates;
        var certificate = addCertificate;

        const data = new FormData();
        data.append('supplierId', certificate.supplierId.toString());
        data.append('typeId', certificate.typeId.toString());
        data.append('certificateNumber', certificate.certificateNumber);
        data.append('certificateName', certificate.certificateName);
        data.append('expiryDate', certificate.expiryDate);
        data.append('sendExpiryNotification', certificate.sendExpiryNotification.toString());
        data.append('certificate', selectedFile);
        data.append('subId', user.sub);

        axios.post('Suppliers/SaveCertificate', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Certificate has been deleted");
                var supCertificate = res.data;
                supplierCertificates.push(supCertificate);
                props.update(supplierCertificates);
                let newCertificate = resetCertificate();

                setAddCertificate(newCertificate);
                setShowAddCertificate(false);
                setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });
                setSelectedFile(new File([""], "filename"));
                setErrors({});
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end add certificate

    //view certificate
    const displayCertificate = (e: any, supplierCertificateId: number) => {
        e.preventDefault();
        showCertificateFile(supplierCertificateId);
    }

    const showCertificateFile = async (supplierCertificateId: number) => {
        const token = await authService.getAccessToken();

        axios.get('Suppliers/GetSupplierCertificate?SupplierCertificateId=' + supplierCertificateId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            setShowCertificate(true);
            setSupplierCertificateId(supplierCertificateId);
            setUploadedCertificate(res.data);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideShowCertificate = () => {
        setShowCertificate(false);
        setSupplierCertificateId(0);
        setUploadedCertificate(new File([""], "certificate"));
    }
    //end view certificate

    //delete certificate
    const deleteCertificate = (index: number, e: any) => {
        e.preventDefault();
        setConfirmDelete({ certificateIndex: index, showConfirmModal: true });
        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });
    }

    const hideConfirmModal = () => {
        setConfirmDelete({ certificateIndex: -1, showConfirmModal: false });
        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });
    }

    const saveConfirmModal = () => {
        if (buttonDisabled.modalSaveDisabled) {
            return;
        }
        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: true });
        deleteSupplierContact();
    }

    const deleteSupplierContact = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var certificates = props.supplierCertificates;
        var certificate = certificates[confirmDelete.certificateIndex];

        var deleteCertificate = {
            certificateid: certificate.id,
            subId: user.sub
        };

        axios.post('Suppliers/DeleteCertificate', deleteCertificate, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Certificate has been deleted");
                certificates.splice(confirmDelete.certificateIndex, 1);
                props.update(certificates);
                setConfirmDelete({ certificateIndex: -1, showConfirmModal: false });
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end delete certificate

    let lastRow = props.supplierCertificates.length;
    let confirmPopup = <ConfirmModal heading="Delete Certificate" text="Are you sure you want to delete this certificate?" hideConfirmModal={hideConfirmModal} showConfirmModal={confirmDelete.showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={buttonDisabled.modalSaveDisabled} />
    let certificateFile = <SupplierInvoiceFilePopup invoiceId={supplierCertificateId} heading="View Certificate" uploadedInvoice={uploadedCertificate} showModal={showCertificate} hideModal={hideShowCertificate} />
    let url = selectedFile.size > 0 ? URL.createObjectURL(selectedFile) : "";
    let validCert = props.supplierEmail.includes("@");

    return (
        <div>
            <div className="static-modal">
                {confirmPopup}
                {certificateFile}
            </div>
            <div>
                <div className={showAddCertificate ? "hidden" : ""}>
                    <div className={validCert ? "hidden" : "makeitred"}>You must enter a valid Supplier Email address before adding certificates!</div>
                    <button className="defaultbutton form-margin" onClick={(e) => setShowAddCertificate(true)} type="button" disabled={!validCert}>Add Certificate</button>
                </div>
                <div className={showAddCertificate ? "" : "hidden"}>
                    <div className="input-group-parent makeitverticalmiddle">
                        <label className="input-group" htmlFor="typeId">
                            <span className="label">Type </span>
                            <select className="select" id="typeId" name="typeId" value={addCertificate.typeId} onChange={(e) => handleChange(e)}>
                                <option hidden defaultValue="-1"></option>
                                {props.certificateTypes.map(type =>
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                )}
                            </select>
                        </label>
                        <span className={errors["typeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["typeId"]}</span>

                        <label className="input-group" htmlFor="certificateNumber">
                            <span className="label">Certificate Number</span>
                            <input className="input" type="text" maxLength={50} id="certificateNumber" name="certificateNumber" value={addCertificate.certificateNumber} onChange={(e) => handleChange(e)}></input>
                        </label>
                        <span className={errors["certificateNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["certificateNumber"]}</span>

                        <label className={(addCertificate.typeId * 1) === 3 ? "input-group" : "hidden"} htmlFor="certificateName">
                            <span className="label">Certificate Name</span>
                            <input className="input" type="text" maxLength={50} id="certificateName" name="certificateName" value={addCertificate.certificateName} onChange={(e) => handleChange(e)}></input>
                        </label>
                        <span className={errors["certificateName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["certificateName"]}</span>

                        <label className="input-group" htmlFor="expiryDate">
                            <span className="label">Expiry Date</span>
                            <input type='date' id="expiryDate" name="expiryDate" className='input' value={addCertificate.expiryDate} onChange={(e) => handleChange(e)} ></input>
                        </label>
                        <span className={errors["expiryDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["expiryDate"]}</span>

                        <label className={(addCertificate.typeId * 1) === 3 ? "input-group" : "hidden"} htmlFor="sendExpiryNotification">
                            <span className="label">Send Expiry Notification?</span>
                            <input className="checkbox" type="checkbox" id="sendExpiryNotification" name="sendExpiryNotification" checked={addCertificate.sendExpiryNotification} onChange={(e) => handleCheckboxChange(e)}></input>
                        </label>

                        <label className="input-group" htmlFor="certificate">
                            <span className="label">Certificate</span>
                            <input type="file" className="marginBottom10" name="file" accept="image/*, application/pdf" onChange={handleImportFile} />
                        </label>
                        <span className={errors["certificate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["certificate"]}</span>
                        <div>
                            <button className="defaultbutton-prev defaultbutton__small notes__button-position" onClick={save} type="button" disabled={buttonDisabled.saveDisabled}>Add</button>
                            <button className="defaultbutton-next defaultbutton__small" onClick={cancelAddCertificate} type="button">Cancel</button>
                        </div>
                    </div>
                    <div className="input-group-parent makeitverticalmiddle">
                        <div className={url === "" ? "hidden" : ""}>
                            <embed className="supplier-certificate-size" type="application/pdf" src={url}></embed>
                        </div>
                    </div>
                        
                </div>
                <div>
                    <hr></hr>
                </div>
                <div className="table__margin overflowAuto">
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th>Type</th>
                                <th>Certificate #</th>
                                <th>Expiry Date</th>
                                <th className="textaligncenter">Send Notification</th>
                                <th>Added By</th>
                                <th className="textaligncenter">Cert</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.supplierCertificates.map((certificate, index) =>
                                <tr key={index}>
                                    <td className="hidden">{certificate.id}</td>
                                    <td className="table__text--align">{certificate.type}</td>
                                    <td className="table__text--align">{certificate.certificateNumber}</td>
                                    <td className="table__text--align">{certificate.expiryDate}</td>
                                    <td className="table__text--align textaligncenter"><input type="checkbox" className="input checkbox" name="selected" checked={certificate.sendExpiryNotification} disabled /></td>
                                    <td className="table__text--align">{certificate.insertedBy}</td>
                                    <td className="table__text--align">
                                        <div className="delete--tablecell">
                                            <a href="#" onClick={(e) => displayCertificate(e, certificate.id)}>
                                                <span className="fas fa-file-image edit--icon alignIconCenter file__photoColour"></span>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="table__text--align">
                                        {lastRow != index ?
                                            <div className="delete--tablecell">
                                                <a className="makeitred" href="#" onClick={(e) => deleteCertificate(index, e)}>
                                                    <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                                </a>
                                            </div> : ""
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {errors["contacts"] ?
                        (errors["contacts"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>
            </div>
        </div>
    );

}

export default SupplierCertificates;
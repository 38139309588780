import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import { useNavigate } from 'react-router-dom';
import SupplierAddDetails from './SupplierAddDetails';

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierAdd = () => {
    let navigate = useNavigate();
    const [trades, setTrades] = React.useState<GenericListItem[]>([]);
    const [otherTrades, setOtherTrades] = React.useState<GenericListItem[]>([]);
    const [statusOptions, setStatusOptions] = React.useState<GenericListItem[]>([]);
    const [termOptions, setTermOptions] = React.useState<GenericListItem[]>([]);
    const [supplierTypes, setSupplierTypes] = React.useState<GenericListItem[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        axios.get('Suppliers/GetDropdowns', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setTrades(res.data.trades);
            setOtherTrades(res.data.trades);
            setStatusOptions(res.data.statusOptions);
            setTermOptions(res.data.termOptions);
            setSupplierTypes(res.data.supplierTypes);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const backToSearch = () => {
        navigate("/suppliersearch");
    }

    var supplier = <SupplierAddDetails id={0} statusOptions={statusOptions} termOptions={termOptions} trades={trades} otherTrades={otherTrades} supplierTypes={supplierTypes} backToSearch={backToSearch} />

    return (
        <div>
            {supplier}
        </div>
    )
}

export default SupplierAdd;
import * as React from 'react';
import { useReducer } from 'react';
import GoogleAutoComplete from './GoogleAutoComplete';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as DateHelpers from './DateHelpers';
import JobTabs from './JobTabs';
import ConfirmModalWithNoteData from './ConfirmModalWithNotes';
import AddClientContactPopups from './JobDetailsNewClientContact';
import JobDetailsAddInsurerPopup from './JobDetailsAddInsurerPopup';
import authService from './api-authorization/AuthorizeService';
import { JobDetailsReducer, InitialJob } from './JobDetailsReducer';

interface GenericListItem {
    id: number;
    name: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
    canEditStatus: boolean;
}

const JobDetailsData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false, canEditStatus: false });
    const [showMultipleInsurers, setShowMultipleInsurers] = React.useState(false);
    const [canEdit, setCanEdit] = React.useState(true);
    const [jobTypes, setJobTypes] = React.useState<GenericListItem[]>([]);
    const [jobSubTypes, setJobSubTypes] = React.useState<GenericListItem[]>([]);
    const [claimTypes, setClaimTypes] = React.useState<GenericListItem[]>([]);
    const [insurers, setInsurers] = React.useState<GenericListItem[]>([]);
    const [adjusters, setAdjusters] = React.useState<GenericListItem[]>([]);
    const [brokers, setBrokers] = React.useState<GenericListItem[]>([]);
    const [insurerContacts, setInsurerContacts] = React.useState<GenericListItem[]>([]);
    const [adjusterContacts, setAdjusterContacts] = React.useState<GenericListItem[]>([]);
    const [brokerContacts, setBrokerContacts] = React.useState<GenericListItem[]>([]);
    const [estimators, setEstimators] = React.useState<GenericListItem[]>([]);
    const [supervisors, setSupervisors] = React.useState<GenericListItem[]>([]);
    const [managers, setManagers] = React.useState<GenericListItem[]>([]);
    const [jobStatus, setJobStatus] = React.useState<GenericListItem[]>([]);
    const [editMakeSafeJob, setEditMakeSafeJob] = React.useState(false);
    const [editReportOnly, setEditReportOnly] = React.useState(false);
    const [editTemplateJob, setEditTemplateJob] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [cancelJobOptions, setCancelJobOptions] = React.useState({ showCancelConfirmModal: false, cancelNotes: "" });
    const [addContact, setAddContact] = React.useState({ showAddClientContactModal: false, clientId: 0, typeId: 0 });
    const [siteAddress, setSiteAddress] = React.useState("");
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const [{
        jobDetails,
        customerDetails,
        clientDetails,
        multipleInsurers,
        resources,
        isLoading,
    }, dispatch] = useReducer(JobDetailsReducer, { jobDetails: InitialJob.jobDetails, customerDetails: InitialJob.customerDetails, clientDetails: InitialJob.clientDetails, multipleInsurers: InitialJob.multipleInsurers, resources: InitialJob.resources, isLoading: false });

    React.useEffect(() => {
        window.scrollTo(0, 0);
        
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save an audit trail note of who has accessed job details
        //var auditTrail = {
        //    typeId: 2,
        //    linkId: Id,
        //    subId: user.sub
        //};

        //axios.post('Shared/SaveAuditTrail', auditTrail, {
        //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //})
        //.then(res => {
        //    //not doing anything if the audit trail doesn't work
        //});

        axios.get('Jobs/GetDropdowns?SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setJobTypes(res.data.jobTypes);
            setJobSubTypes(res.data.jobSubTypes);
            setClaimTypes(res.data.claimTypes);
            setInsurers(res.data.insurers);
            setAdjusters(res.data.adjusters);
            setBrokers(res.data.brokers);
            setEstimators(res.data.estimators);
            setSupervisors(res.data.supervisors);
            setManagers(res.data.managers);
            setJobStatus(res.data.jobStatus);
            setPermissions(res.data.userJobPermissions);

            //get job details
            axios.get('Jobs/GetJobDetails?Id=' + Id + "&subId=" + user.sub, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });
                } else {
                    var jobDetails = res.data.job;
                    if (jobDetails.clientDetails.claimTypeId === null) {
                        jobDetails.clientDetails.claimTypeId = -1;
                    }

                    setInsurerContacts(res.data.insurerContacts);
                    setAdjusterContacts(res.data.adjusterContacts);
                    setBrokerContacts(res.data.brokerContacts);

                    var jobDetails = res.data.job.jobDetails;
                    var customerDetails = res.data.job.customerDetails;
                    var clientDetails = res.data.job.clientDetails;
                    var resources = res.data.job.resources;

                    dispatch({ type: "updateDetails", jobDetails: jobDetails });
                    dispatch({ type: "updateCustomer", customerDetails: customerDetails });
                    dispatch({ type: "updateClient", clientDetails: clientDetails });
                    dispatch({ type: "updateResources", resources: resources });

                    setSiteAddress(res.data.siteAddress);
                    setLoading(false);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleCheckboxChange = (e: any) => {
        let change: any = jobDetails;
        change[e.target.name] = e.target.checked;
        dispatch({ type: "updateDetails", jobDetails: change });

        setSaveDisabled(false); 
    }

    const handleJobDetailsChange = (e: any, isNumber: boolean) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }
        
        let change: any = jobDetails;
        change[e.target.name] = isNumber ? e.target.value * 1 : e.target.value;
        dispatch({ type: "updateDetails", jobDetails: change });

        setSaveDisabled(false);
    }

    const handleCustomerCheckboxChange = (e: any) => {
        let change: any = customerDetails;
        change[e.target.name] = e.target.checked;
        dispatch({ type: "updateCustomer", customerDetails: change });

        setSaveDisabled(false);
    }

    const handleJobCustomerChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let change: any = customerDetails;
        change[e.target.name] = e.target.value;
        dispatch({ type: "updateCustomer", customerDetails: change });
        
        setSaveDisabled(false);
    }

    const handleJobClientChange = (e: any, isNumber: boolean) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let change: any = clientDetails;
        change[e.target.name] = isNumber ? e.target.value * 1 : e.target.value;
        dispatch({ type: "updateClient", clientDetails: change });

        setSaveDisabled(false);
    }

    const handleJobResourceChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let change: any = resources;
        change[e.target.name] = e.target.value * 1;
        dispatch({ type: "updateResources", resources: change });

        setSaveDisabled(false);
    }

    const enableMakeSafe = (e: any) => {
        e.preventDefault();
        setEditMakeSafeJob(true);
    }

    const enableReportOnly = (e: any) => {
        e.preventDefault();
        setEditReportOnly(true);
    }

    const enableTemplate = (e: any) => {
        e.preventDefault();
        setEditTemplateJob(true);
    }

    const getContacts = (clientId: number, typeId: number) => {
        if (clientId >= 0) {
            getJobClientContacts(clientId, typeId);
        } else {
            //reset the contacts
            var updatedClientDetails = clientDetails;
            if (typeId === 1) {
                updatedClientDetails.insurerContactId = -1;
                setInsurerContacts([]);
            } else if (typeId === 2) {
                updatedClientDetails.adjusterContactId = -1;
                setAdjusterContacts([]);
            } else {
                updatedClientDetails.brokerContactId = -1;
                setBrokerContacts([]);
            }
            dispatch({ type: "updateClient", clientDetails: updatedClientDetails });
        }
    }

    const getJobClientContacts = async (clientId: number, typeId: number) => {
        const token = await authService.getAccessToken();

        axios.get('Jobs/GetClientContactDropdown?clientId=' + clientId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var contacts = res.data;
            if (typeId === 1) {
                setInsurerContacts(contacts);
            } else if (typeId === 2) {
                setAdjusterContacts(contacts);
            } else {
                setBrokerContacts(contacts);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleClientSelectChange = (e: any, typeId: number) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let change: any = clientDetails;
        change[e.target.name] = e.target.value;
        dispatch({ type: "updateClient", clientDetails: change });

        setSaveDisabled(false);

        getContacts(e.target.value, typeId);
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["siteAddress"];
        setErrors(error);

        updateJobAddress(addressDetails);

        setSaveDisabled(false);
    }

    const updateJobAddress = (addressDetails: AddressDetails) => {
        
        let change: any = jobDetails;

        //check if there is a unit number
        var streetNo = "";
        if (addressDetails.subpremise) {
            streetNo += addressDetails.subpremise + "/";
        }
        streetNo += addressDetails.street_number;

        change.siteAddress = addressDetails.fullAddress;
        change.streetNumber = streetNo;
        change.streetName = addressDetails.route;
        change.suburb = addressDetails.locality;
        change.state = addressDetails.administrative_area_level_1;
        change.country = addressDetails.country;
        change.postCode = addressDetails.postal_code;
        change.googlePlaceId = addressDetails.placeId;
        change.siteAddressLatitude = addressDetails.latitude;
        change.siteAddressLongitude = addressDetails.longitude;
        dispatch({ type: "updateDetails", jobDetails: change });
    }

    //update google customer address
    const setCustomerAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["address"];
        setErrors(error);

        var custDetails = customerDetails;

        //check if there is a unit number
        var streetNo = "";
        if (addressDetails.subpremise) {
            streetNo += addressDetails.subpremise + "/";
        }
        streetNo += addressDetails.street_number;

        custDetails.address = addressDetails.fullAddress;
        custDetails.streetNumber = streetNo;
        custDetails.streetName = addressDetails.route;
        custDetails.suburb = addressDetails.locality;
        custDetails.state = addressDetails.administrative_area_level_1;
        custDetails.country = addressDetails.country;
        custDetails.postCode = addressDetails.postal_code;
        custDetails.customerGooglePlaceId = addressDetails.placeId;
        custDetails.customerAddressLatitude = addressDetails.latitude;
        custDetails.customerAddressLongitude = addressDetails.longitude;
        dispatch({ type: "updateCustomer", customerDetails: custDetails });

        setSaveDisabled(false);
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    //CANCEL JOB
    const cancelJob = () => {
        setCancelJobOptions({ showCancelConfirmModal: true, cancelNotes: "" });
        setModalSaveDisabled(false);
    }

    const updateNotes = (notes: string) => {
        setCancelJobOptions({ showCancelConfirmModal: true, cancelNotes: notes });
        setModalSaveDisabled(false);
    }

    const hideCancelConfirmModal = () => {
        setCancelJobOptions({ showCancelConfirmModal: false, cancelNotes: "" });
        setModalSaveDisabled(false);
    }

    const saveCancelJob = () => {
        cancelJobSave();
    }

    const cancelJobSave = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let updatedJobDetails = jobDetails;

        let cancelJob = {
            jobId: jobDetails.id,
            cancelReason: cancelJobOptions.cancelNotes,
            subId: user.sub
        }

        axios.post('Jobs/CancelJob', cancelJob, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            console.log(res.data);
            toast.success("Job Saved");

            setCanEdit(false);

            updatedJobDetails.statusId = res.data.statusId;
            updatedJobDetails.status = res.data.status;
            dispatch({ type: "updateDetails", jobDetails: updatedJobDetails });

            setCancelJobOptions({ showCancelConfirmModal: false, cancelNotes: "" });
            setModalSaveDisabled(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //END CANCEL JOB

    //ADD CLIENT CONTACT
    const showAddClientContactModal = (e: any, clientId: number, typeId: number) => {
        e.preventDefault();
        setAddContact({ showAddClientContactModal: true, clientId: clientId, typeId: typeId });
        setModalSaveDisabled(false);
    }

    const hideAddClientContactModal = () => {
        setAddContact({ showAddClientContactModal: false, clientId: 0, typeId: 0 });
        setModalSaveDisabled(false);
    }

    const saveAddClientContactModal = (contactId: number) => {
        let details = clientDetails;
        if (contactId > 0) {
            if (addContact.typeId === 1) {
                details.insurerContactId = contactId;
            } else if (addContact.typeId === 2) {
                details.adjusterContactId = contactId;
            } else {
                details.brokerContactId = contactId;
            }
        }
        dispatch({ type: "updateClient", clientDetails: details });
        setAddContact({ showAddClientContactModal: false, clientId: 0, typeId: 0 });
        setModalSaveDisabled(false);
        
        getContacts(addContact.clientId, addContact.typeId);
    }
    //END ADD CLIENT CONTACT

    //SHOW MULTIPLE INSURERS
    const showMultipleInsurersPopup = (e: any) => {
        e.preventDefault();
        setShowMultipleInsurers(true);
    }

    const hideMultipleInsurersPoup = () => {
        setShowMultipleInsurers(false);
    }

    //END SHOW MULTIPLE INSURERS

    const validate = () => {
        let jobfields = jobDetails;
        let custfields = customerDetails;
        let clientfields = clientDetails;
        let resourcefields = resources;
        let error: any = {};
        let formIsValid = true;

        if (jobfields.jobTypeId < 0) {
            formIsValid = false;
            error["jobTypeId"] = "Job Type is required";
        }

        if (jobfields.jobSubTypeId < 0) {
            formIsValid = false;
            error["jobSubTypeId"] = "Job Sub Type is required";
        }

        if (!jobfields.siteAddress) {
            formIsValid = false;
            error["siteAddress"] = "Site Address is required";
        }

        if (!jobfields.receivedDate) {
            formIsValid = false;
            error["receivedDate"] = "Received Date is required";
        } else if (!DateHelpers.isValidDate(jobfields.receivedDate)) {
            formIsValid = false;
            error["receivedDate"] = "Valid Received Date is required";
        }

        if (jobfields.siteVisitDate && !DateHelpers.isValidDate(jobfields.siteVisitDate)) {
            formIsValid = false;
            error["siteVisitDate"] = "Valid Site Visit Date is required";
        }

        if (jobfields.insuredContactDate && !DateHelpers.isValidDate(jobfields.insuredContactDate)) {
            formIsValid = false;
            error["insuredContactDate"] = "Valid Insured Contact Date is required";
        }

        if (!custfields.companyName) {
            //only mandatory if there is no company name
            if (!custfields.customerName) {
                formIsValid = false;
                error["customerName"] = "Customer Name is required";
            }
        }

        if (!custfields.address) {
            formIsValid = false;
            error["address"] = "Customer Address is required";
        }

        if (jobfields.jobTypeId === 1) {
            if (clientfields.insurerId < 0) {
                formIsValid = false;
                error["insurerId"] = "Insurer is required";
            }

            if (!clientfields.claimNumber && !jobfields.makeSafe) {
                formIsValid = false;
                error["claimNumber"] = "Claim # is required";
            }

            if (clientfields.claimStatusId < 0) {
                formIsValid = false;
                error["claimStatusId"] = "Claim Status is required";
            }

            if (clientfields.adjusterId > 0 && clientfields.adjusterContactId <= 0) {
                formIsValid = false;
                error["adjusterContactId"] = "Adjuster Contact is required";
            }
        }

        if (resourcefields.estimatorId < 0 && !jobfields.makeSafe) {
            formIsValid = false;
            error["estimatorId"] = "Estimator is required";
        }

        if ((jobfields.statusId === 5 || jobfields.makeSafe) && resourcefields.supervisorId < 0) {
            formIsValid = false;
            error["supervisorId"] = "Supervisor is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveJobDetails();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveJobDetails = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var saveJob = {
            subId: user.sub,
            job: {
                jobDetails: jobDetails,
                customerDetails: customerDetails,
                clientDetails: clientDetails,
                resources: resources,
                newJobNotes: "",
                copyJobId: 0
            }
        };

        //save job
        axios.post('Jobs/SaveJob', saveJob, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            console.log(res.data);
            toast.success("Job Saved");
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Site Address"} placeholder={jobDetails.siteAddress} className="input-group" disabled={!canEdit} save={setAddress} />
    //var googleCustomer = <GoogleAutoComplete addressId="autocompletecustomer" addressLabel={"Address"} placeholder={customerDetails.address} className="input-group" disabled={!canEdit} save={setCustomerAddress} />
    var jobTabs = <JobTabs id={jobDetails.id} siteAddress={siteAddress} isMakeSafe={jobDetails.makeSafe} isReportOnly={jobDetails.reportOnly} tab="details" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />
    let confirmPopupNotes = <ConfirmModalWithNoteData showConfirmModal={cancelJobOptions.showCancelConfirmModal} heading={"Cancel Job " + jobDetails.id + "?"} text="Are you sure you want to cancel this job?" notesText="Enter reason for cancelling" notes={cancelJobOptions.cancelNotes} updateNotes={updateNotes} hideConfirmModal={hideCancelConfirmModal} noConfirmModal={hideCancelConfirmModal} yesConfirmModal={saveCancelJob} saveDisabled={modalSaveDisabled} />
    let addClientContact = <AddClientContactPopups parentId={addContact.clientId} parentTypeId={1} showAddClientContactModal={addContact.showAddClientContactModal} hideAddClientContactModal={hideAddClientContactModal} saveAddClientContactModal={saveAddClientContactModal} />
    let addInsurerPopup = <JobDetailsAddInsurerPopup jobId={jobDetails.id} insurers={insurers} showModal={showMultipleInsurers} hideModal={hideMultipleInsurersPoup} />

    const renderDetails = (
        <form onSubmit={save}>
            <div>
                {jobTabs}
            </div>
            <div className="static-modal">
                {confirmPopupNotes}
                {addClientContact}
                {addInsurerPopup}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <div className="input-group-parent">
                    <div className="makeitflex">
                        <h4 className="jobdetails__heading">Job Details </h4>
                        <NavLink className="jobdetails__copy" to={'/newjob/' + jobDetails.id}>Copy Job</NavLink>
                    </div>
                    <label className="input-group" htmlFor="id">
                        <span className="label">Job Id</span>
                        <input className="input" type="text" id="id" name="id" value={jobDetails.id} disabled></input>
                    </label>

                    <label className="input-group" htmlFor="jobTypeId">
                        <span className="label">Job Type </span>
                        <select className="select" id="jobTypeId" name="jobTypeId" value={jobDetails.jobTypeId} onChange={(e) => handleJobDetailsChange(e, true)} disabled={!canEdit}>
                            <option hidden defaultValue="-1"></option>
                            {jobTypes.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["jobTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["jobTypeId"]}</span>

                    <label className="input-group" htmlFor="jobSubTypeId">
                        <span className="label">Job Sub Type </span>
                        <select className="select" id="jobSubTypeId" name="jobSubTypeId" value={jobDetails.jobSubTypeId} onChange={(e) => handleJobDetailsChange(e, true)} disabled={!canEdit}>
                            <option hidden defaultValue="-1"></option>
                            {jobSubTypes.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["jobSubTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["jobSubTypeId"]}</span>

                    <label className="input-group" htmlFor="makeSafe">
                        <span className="label">MakeSafe Required?</span>
                        <input className="checkbox job__editMargin" type="checkbox" id="makeSafe" name="makeSafe" checked={jobDetails.makeSafe} onChange={(e) => handleCheckboxChange(e)} disabled={!canEdit || !editMakeSafeJob}></input>
                        <a className={editMakeSafeJob ? "hidden" : ""} href="#" onClick={(e) => enableMakeSafe(e)}>
                            <div className="tooltipitem tooltipitem__left"><span className="fas fa-edit edit--icon alignIconCenter"></span>
                                <span className="tooltipitemtext tooltipitemtext__left">Click this to update the MakeSafe Required checkbox</span>
                            </div>
                        </a>
                    </label>

                    <label className="input-group" htmlFor="reportOnly">
                        <span className="label">Report Only Job</span>
                        <input className="checkbox job__editMargin" type="checkbox" id="reportOnly" name="reportOnly" checked={jobDetails.reportOnly} onChange={(e) => handleCheckboxChange(e)} disabled={!canEdit || !editReportOnly}></input>
                        <a className={editReportOnly ? "hidden" : ""} href="#" onClick={(e) => enableReportOnly(e)}>
                            <div className="tooltipitem tooltipitem__left"><span className="fas fa-edit edit--icon alignIconCenter"></span>
                                <span className="tooltipitemtext tooltipitemtext__left">Click this to update the Report Only checkbox</span>
                            </div>
                        </a>
                    </label>

                    {googleContents}
                    <span className={errors["siteAddress"] ? "label errors errors__leftmargin" : "hidden"}>{errors["siteAddress"]}</span>

                    <label className="input-group" htmlFor="siteAddressUnitNumber">
                        <span className="label">Unit Number</span>
                        <input className='input' type='text' maxLength={100} id="siteAddressUnitNumber" name="siteAddressUnitNumber" value={jobDetails.siteAddressUnitNumber} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="receivedDate">
                        <span className="label">Received Date</span>
                        <input type='date' id="receivedDate" name="receivedDate" className='input' value={jobDetails.receivedDate} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>
                    <span className={errors["receivedDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["receivedDate"]}</span>

                    <label className="input-group" htmlFor="receivedTime">
                        <span className="label">Received Time</span>
                        <input type='time' id="receivedTime" name="receivedTime" className='input' value={jobDetails.receivedTime} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="siteVisitDate">
                        <span className="label">Site Visit Date</span>
                        <input type='date' id="siteVisitDate" name="siteVisitDate" className='input' value={jobDetails.siteVisitDate} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>
                    <span className={errors["siteVisitDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["siteVisitDate"]}</span>

                    <label className="input-group" htmlFor="siteVisitTime">
                        <span className="label">Site Visit Time</span>
                        <input type='time' id="siteVisitTime" name="siteVisitTime" className='input' value={jobDetails.siteVisitTime} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="insuredContactDate">
                        <span className="label">Insured Contact Date</span>
                        <input type='date' id="insuredContactDate" name="insuredContactDate" className='input' value={jobDetails.insuredContactDate} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>
                    <span className={errors["insuredContactDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["insuredContactDate"]}</span>

                    <label className="input-group" htmlFor="insuredContactTime">
                        <span className="label">Insured Contact Time</span>
                        <input type='time' id="insuredContactTime" name="insuredContactTime" className='input' value={jobDetails.insuredContactTime} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="contractPrice">
                        <span className="label">Contract Price</span>
                        <input className="input" type="text" id="contractPrice" name="contractPrice" value={jobDetails.contractPrice} disabled></input>
                    </label>

                    <label className="input-group" htmlFor="jobAcknowledgementDateTime">
                        <span className="label">Job Acknowledgement</span>
                        <input className="input" type='datetime-local' id="jobAcknowledgementDateTime" name="jobAcknowledgementDateTime" value={jobDetails.jobAcknowledgementDateTime} onChange={(e) => handleJobDetailsChange(e, false)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="completePercentage">
                        <span className="label">Complete %</span>
                        <input className="input" type="text" id="completePercentage" name="completePercentage" value={jobDetails.completePercentage} disabled></input>
                    </label>

                    <label className="input-group" htmlFor="completedDate">
                        <span className="label">Completed Date</span>
                        <input className="input" type="text" id="completedDate" name="completedDate" value={jobDetails.completedDate} disabled></input>
                    </label>

                    <label className="input-group" htmlFor="templateJob">
                        <span className="label">Template Job?</span>
                        <input className="checkbox job__editMargin" type="checkbox" id="templateJob" name="templateJob" checked={jobDetails.templateJob} onChange={(e) => handleCheckboxChange(e)} disabled={!editTemplateJob}></input>
                        <a className={editTemplateJob ? "hidden" : ""} href="#" onClick={(e) => enableTemplate(e)}>
                            <div className="tooltipitem tooltipitem__left"><span className="fas fa-edit edit--icon alignIconCenter"></span>
                                <span className="tooltipitemtext tooltipitemtext__left">Click this to update the Template Job checkbox</span>
                            </div>
                        </a>
                    </label>

                    <label className="input-group" htmlFor="statusId">
                        <span className="label">Status</span>
                        <select className="select" id="statusId" name="statusId" value={jobDetails.statusId} onChange={(e) => handleJobDetailsChange(e, true)} disabled={!canEdit || !permissions.canEditStatus}>
                            {jobStatus.map(status =>
                                <option key={status.id} value={status.id}>{status.name}</option>
                            )};
                        </select>
                    </label>

                    <h4>Customer Details</h4>
                    <div className="makeitflexspacebetween">
                        <label className="input-group" htmlFor="companyName">
                            <span className="label">Company Name</span>
                            <input className='input jobdets__company--width marginRight10' type='text' maxLength={100} id="companyName" name="companyName" value={customerDetails.companyName} onChange={(e) => handleJobCustomerChange(e)} disabled={!canEdit}></input>
                            <span className="label-small">Reg GST?</span>
                            <input className="checkbox" type="checkbox" id="customerRegisteredForGst" name="customerRegisteredForGst" checked={customerDetails.customerRegisteredForGst} onChange={(e) => handleCustomerCheckboxChange(e)}></input>
                        </label>
                    </div>

                    <label className="input-group" htmlFor="propertyManager">
                        <span className="label">Property Manager</span>
                        <input className='input' type='text' maxLength={100} id="propertyManager" name="propertyManager" value={customerDetails.propertyManager} onChange={(e) => handleJobCustomerChange(e)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="customerFirstName">
                        <span className="label">Customer Name</span>
                        <input className='input' type='text' maxLength={100} id="customerName" name="customerName" value={customerDetails.customerName} onChange={(e) => handleJobCustomerChange(e)} disabled={!canEdit}></input>
                    </label>
                    <span className={errors["customerName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["customerName"]}</span>

                    {/*{googleCustomer}*/}
                    <span className={errors["address"] ? "label errors errors__leftmargin" : "hidden"}>{errors["address"]}</span>

                    <label className="input-group" htmlFor="addressUnitNumber">
                        <span className="label">Unit Number</span>
                        <input className='input' type='text' maxLength={100} id="addressUnitNumber" name="addressUnitNumber" value={customerDetails.addressUnitNumber} onChange={(e) => handleJobCustomerChange(e)} disabled={!canEdit}></input>
                    </label>

                    <label className="input-group" htmlFor="phone">
                        <span className="label">Phone</span>
                        <input className='input' type='text' maxLength={100} id="phone" name="phone" value={customerDetails.phone} onChange={(e) => handleJobCustomerChange(e)} disabled={!canEdit}></input>
                    </label>
                    <span className={errors["phone"] ? "label errors errors__leftmargin" : "hidden"}>{errors["phone"]}</span>
                    <div className="showMobile">
                        <label className={customerDetails.customerPhone === "" ? "hidden" : "input-group "} htmlFor="customerPhone">
                            <span className="label"></span>
                            <a className="showMobile" href={"tel:" + customerDetails.customerPhone}>Call Customer</a>
                        </label>
                    </div>

                    <label className="input-group" htmlFor="email">
                        <span className="label">Email</span>
                        <input className='input' type='text' maxLength={200} id="email" name="email" value={customerDetails.email} onChange={(e) => handleJobCustomerChange(e)} disabled={!canEdit}></input>
                    </label>
                    <span className={errors["email"] ? "label errors errors__leftmargin" : "hidden"}>{errors["email"]}</span>
                </div>
                <div className= "input-group-parent">
                    <div className={jobDetails.jobTypeId === 1 ? ""  : "hidden"} >
                        <h4>Client Details</h4>
                        <label className="input-group" htmlFor="insurerId">
                            <span className="label">Insurer
                                <a className="marginLeft10" href="#" onClick={(e) => showMultipleInsurersPopup(e)}>
                                    View
                                </a>
                            </span>
                            <select className="select" id="insurerId" name="insurerId" value={clientDetails.insurerId} onChange={(e) => handleClientSelectChange(e, 1)} disabled={!canEdit}>
                                <option defaultValue="-1" value="-1"></option>
                                {insurers.map(ins =>
                                    <option key={ins.id} value={ins.id}>{ins.name}</option>
                                )};
                            </select>
                        </label>
                        <span className={errors["insurerId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["insurerId"]}</span>

                        <label className="input-group" htmlFor="insurerContactId">
                            <span className="label">Contact
                                <a className={clientDetails.insurerId < 0 || !canEdit || clientDetails.isTbaClient ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddClientContactModal(e, clientDetails.insurerId, 1)}>
                                    Add New
                                </a>
                            </span>
                            <select className="select" id="insurerContactId" name="insurerContactId" value={clientDetails.insurerContactId} onChange={(e) => handleJobClientChange(e, true)} disabled={!canEdit}>
                                <option hidden defaultValue="-1"></option>
                                {insurerContacts.map(ins =>
                                    <option key={ins.id} value={ins.id}>{ins.name}</option>
                                )};
                            </select>
                        </label>
                        <span className={errors["insurerContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["insurerContactId"]}</span>

                        <label className={clientDetails.insurerPhoneNumber === "" ? "hidden" : "input-group"} htmlFor="insurerPhoneNumber">
                            <span className="label"></span>
                            <a className="showMobile" href={"tel:" + clientDetails.insurerPhoneNumber}>Call Insurer</a>
                            <span className="hideMobile">{clientDetails.insurerPhoneNumber}</span>
                        </label>
                        <label className={clientDetails.insurerMobileNumber === "" ? "hidden" : "input-group"} htmlFor="insurerPhoneNumber">
                            <span className="label"></span>
                            <a className="showMobile" href={"tel:" + clientDetails.insurerMobileNumber}>Call Insurer Mobile</a>
                            <span className="hideMobile">{clientDetails.insurerMobileNumber}</span>
                        </label>

                        <label className="input-group" htmlFor="claimNumber">
                            <span className="label">Claim #</span>
                            <input className='input' type='text' maxLength={20} id="claimNumber" name="claimNumber" value={clientDetails.claimNumber} onChange={(e) => handleJobClientChange(e, false)} disabled={!canEdit}></input>
                        </label>
                        <span className={errors["claimNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["claimNumber"]}</span>

                        <label className="input-group" htmlFor="claimStatusId">
                            <span className="label">Claim Status</span>
                            <select className="select" id="claimStatusId" name="claimStatusId" value={clientDetails.claimStatusId} onChange={(e) => handleJobClientChange(e, false)} disabled={!canEdit}>
                                <option hidden defaultValue="-1"></option>
                                <option key={1} value={1}>BAU</option>
                                <option key={2} value={2}>CAT</option>
                            </select>
                        </label>
                        <span className={errors["claimStatusId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["claimStatusId"]}</span>

                        <label className="input-group" htmlFor="claimTypeId">
                            <span className="label">Claim Type</span>
                            <select className="select" id="claimTypeId" name="claimTypeId" value={clientDetails.claimTypeId} onChange={(e) => handleJobClientChange(e, true)} disabled={!canEdit}>
                                <option hidden defaultValue="-1"></option>
                                {claimTypes.map(type =>
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                )};
                            </select>
                        </label>
                        <span className={errors["claimTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["claimTypeId"]}</span>

                        <label className="input-group" htmlFor="excessAmount">
                            <span className="label">Excess Amount (excl. GST)</span>
                            <input className='input' type='text' id="excessAmount" name="excessAmount" value={clientDetails.excessAmount} onChange={(e) => handleJobClientChange(e, false)} disabled={!canEdit}></input>
                        </label>
                        <span className={errors["excessAmount"] ? "label errors errors__leftmargin" : "hidden"}>{errors["excessAmount"]}</span>

                        <label className="input-group" htmlFor="adjusterId">
                            <span className="label">Adjuster</span>
                                <select className="select" id="adjusterId" name="adjusterId" value={clientDetails.adjusterId} onChange={(e) => handleClientSelectChange(e, 2)} disabled={!canEdit}>
                                    <option defaultValue="-1" value="-1"></option>
                                {adjusters.map(adj =>
                                    <option key={adj.id} value={adj.id}>{adj.name}</option>
                                )};
                            </select>
                        </label>

                        <label className="input-group" htmlFor="adjusterContactId">
                            <span className="label">Contact
                                <a className={clientDetails.adjusterId < 0 || !canEdit ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddClientContactModal(e, clientDetails.adjusterId, 2)}>
                                    Add New
                                </a>
                            </span>
                            <select className="select" id="adjusterContactId" name="adjusterContactId" value={clientDetails.adjusterContactId} onChange={(e) => handleJobClientChange(e, true)} disabled={!canEdit}>
                                <option hidden defaultValue="-1"></option>
                                {adjusterContacts.map(adj =>
                                    <option key={adj.id} value={adj.id}>{adj.name}</option>
                                )};
                            </select>
                        </label>
                        <span className={errors["adjusterContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["adjusterContactId"]}</span>
                        <label className={clientDetails.adjusterPhoneNumber === "" ? "hidden" : "input-group"} htmlFor="adjusterPhoneNumber">
                        <span className="label"></span>
                            <a className="showMobile" href={"tel:" + clientDetails.adjusterPhoneNumber}>Call Adjuster</a>
                            <span className="hideMobile">{clientDetails.adjusterPhoneNumber}</span>
                        </label>
                        <label className={clientDetails.adjusterMobileNumber === "" ? "hidden" : "input-group"} htmlFor="adjusterMobileNumber">
                        <span className="label"></span>
                            <a className="showMobile" href={"tel:" + clientDetails.adjusterMobileNumber}>Call Adjuster Mobile</a>
                            <span className="hideMobile">{clientDetails.adjusterMobileNumber}</span>
                        </label>

                        <label className="input-group" htmlFor="adjusterReferenceNumber">
                            <span className="label">Adjuster Ref #</span>
                            <input className='input' type='text' maxLength={20} id="adjusterReferenceNumber" name="adjusterReferenceNumber" value={clientDetails.adjusterReferenceNumber} onChange={(e) => handleJobClientChange(e, false)} disabled={!canEdit}></input>
                        </label>
                        <span className={errors["adjusterReferenceNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["adjusterReferenceNumber"]}</span>

                        <label className="input-group" htmlFor="brokerId">
                            <span className="label">Broker</span>
                                <select className="select" id="brokerId" name="brokerId" value={clientDetails.brokerId} onChange={(e) => handleClientSelectChange(e, 3)} disabled={!canEdit}>
                                    <option defaultValue="-1" value="-1"></option>
                                {brokers.map(bkr =>
                                    <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                                )};
                            </select>
                        </label>

                        <label className="input-group" htmlFor="brokerContactId">
                            <span className="label">Contact
                                <a className={clientDetails.brokerId < 0 || !canEdit ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddClientContactModal(e, clientDetails.brokerId, 3)}>
                                    Add New
                                </a>
                            </span>
                            <select className="select" id="brokerContactId" name="brokerContactId" value={clientDetails.brokerContactId} onChange={(e) => handleJobClientChange(e, true)} disabled={!canEdit}>
                                <option hidden defaultValue="-1"></option>
                                {brokerContacts.map(bkr =>
                                    <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                                )};
                            </select>
                        </label>
                        <span className={errors["brokerContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["brokerContactId"]}</span>

                        <label className="input-group" htmlFor="brokerReferenceNumber">
                            <span className="label">Broker Ref #</span>
                            <input className='input' type='text' maxLength={20} id="brokerReferenceNumber" name="brokerReferenceNumber" value={clientDetails.brokerReferenceNumber} onChange={(e) => handleJobClientChange(e, false)} disabled={!canEdit}></input>
                        </label>
                    </div>

                    <h4>Resources</h4>
                    <label className="input-group" htmlFor="managerId">
                        <span className="label">Manager</span>
                            <select className="select" id="managerId" name="managerId" value={resources.managerId} onChange={(e) => handleJobResourceChange(e)} disabled={!canEdit}>
                                <option defaultValue="-1" value="-1"></option>
                            {managers.map(mgr =>
                                <option key={mgr.id} value={mgr.id}>{mgr.name}</option>
                            )};
                        </select>
                    </label>

                    <label className="input-group" htmlFor="estimatorId">
                        <span className="label">Estimator</span>
                            <select className="select" id="estimatorId" name="estimatorId" value={resources.estimatorId} onChange={(e) => handleJobResourceChange(e)} disabled={!canEdit}>
                            <option defaultValue="-1" value="-1"></option>
                            {estimators.map(est =>
                                <option key={est.id} value={est.id}>{est.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["estimatorId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["estimatorId"]}</span>

                    <label className="input-group" htmlFor="supervisorId">
                        <span className="label">Supervisor</span>
                            <select className="select" id="supervisorId" name="supervisorId" value={resources.supervisorId} onChange={(e) => handleJobResourceChange(e)} disabled={!canEdit}>
                            <option defaultValue="-1" value="-1"></option>
                            {supervisors.map(sup =>
                                <option key={sup.id} value={sup.id}>{sup.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["supervisorId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supervisorId"]}</span>
                </div>
                <button className="defaultbutton-prev defaultbutton-prev__split defaultbutton__multiple" type="submit" disabled={!canEdit || saveDisabled}>Save</button>
                <button className="defaultbutton-next defaultbutton__multiple" type="button" onClick={cancelJob} disabled={!canEdit}>Cancel</button>
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default JobDetailsData;
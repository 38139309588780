import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface GenericList {
    id: number;
    name: string;
}

const ReportJobWipData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [supervisors, setSupervisors] = React.useState<GenericList[]>([]);
    const [supervisorId, setSupervisorId] = React.useState(-1);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunJobWipReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Users/GetSupervisors?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    var supervisors = res.data;
                    setSupervisors(supervisors);
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied")
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any) => {
        setSupervisorId(e.target.value);
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data
        var url = "/report/JobWip/-1/-1/" + supervisorId;
        window.open(url, '_blank');
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        exportToExcel();
    }

    const exportToExcel = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios('Report/ExportJobWipData?supervisorId=' + supervisorId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'WIP Jobs.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Job Wip Report</h1>
            </div>
            <div className="marginTop20">
                <span>Select a Supervisor to filter report.</span>
            </div>
            <div>
                <label className="input-group" htmlFor="supervisorId">
                    <span className="label">Supervisor</span>
                    <select className="select" id="supervisorId" name="supervisorId" value={supervisorId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {supervisors.map(supervisor =>
                            <option key={supervisor.id} value={supervisor.id}>{supervisor.name}</option>
                        )};
                    </select>
                </label>

                <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
        </div>
    );

    let contents = loading
    ? <p><em>Loading...</em></p>
    : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportJobWipData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import JobEstimateImportPopups from './JobEstimateImportPopup';
import ReportJobPhotosPopup from './ReportJobPhotos';
import authService from './api-authorization/AuthorizeService';

interface EstimateHeaderProps {
    canEdit: boolean;
    canStartJobWithoutExcess: boolean;
    canUnapprove: boolean;
    jobId: number;
    changePage(pageData: PageChangeData): void;
    runReport(data: RunReportData): void;
}

interface PageChangeData {
    estimateId: number;
    page: string;
    id: number;
    headerText: string;
    costCodeId: number;
    prevId: number; //for going back from split to details
    prevHeaderText: string;
}

interface Estimate {
    id: number;
    jobId: number;
    consultantScope: boolean;
    consultantScopeNotes: string;
    sent: boolean;
    approved: boolean;
    receivedSignedContract: boolean;
    startJobWithoutExcess: boolean;
    estimateHeaders: EstimateHeaders[]
    subTotal: number;
    subTotalFormatted: string;
    markUpPercentage: number;
    subTotalExGstFormatted: string;
    gstFormatted: string;
    gstPercentage: number;
    totalFormatted: string;
    isDirty: boolean;
}

interface EstimateHeaders {
    itemIndex: number;
    id: number;
    area: string;
    areaOrder: number;
    amount: number;
    amountFormatted: string;
    amountExGstFormatted: string;
    isDirty: boolean;
}

interface RunReportData {
    estimateId: number;
    reportName: string;
}

interface TemplateJobs {
    jobId: string;
    siteAddress: string;
    estimateSubTotalExGstFormatted: string;
    estimateGstFormatted: string;
    estimateTotalFormatted: string;
    selected: boolean;
    templateJob: boolean;
    showHeaders: boolean;
    headers: TemplateHeader[];
}

interface TemplateHeader {
    estimateId: number;
    headerId: number;
    area: string;
    areaOrder: number;
    subTotalExGstFormatted: string;
    selected: boolean;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

interface RequestFile {
    id: number;
    fileName: string;
}

const EstimateHeaderData = (props: EstimateHeaderProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [estimate, setEstimate] = React.useState<Estimate>({
        id: 0,
        jobId: props.jobId,
        consultantScope: false,
        consultantScopeNotes: "",
        sent: false,
        approved: false,
        receivedSignedContract: false,
        startJobWithoutExcess: false,
        estimateHeaders: [],
        subTotal: 0,
        subTotalFormatted: "$0.00",
        markUpPercentage: 20,
        subTotalExGstFormatted: "0.00",
        gstFormatted: "0.00",
        gstPercentage: 0.1,
        totalFormatted: "0.00",
        isDirty: false
    });
    const [requestFiles, setRequestFiles] = React.useState<RequestFile[]>([]);
    const [templateJobs, setTemplateJobs] = React.useState<TemplateJobs[]>([]);
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);
    const [showImportModal, setShowImportModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [estimateIndex, setEstimateIndex] = React.useState(-1);
    const [reportId, setReportId] = React.useState(-1);
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    const [loadingPhotos, setLoadingPhotos] = React.useState(false);
    const [reportComments, setReportComments] = React.useState("");

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var jobId = props.jobId;

        axios.get('Estimate/GetEstimate?JobId=' + jobId + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //add blank header row
            var est = res.data.estimate;

            //update item index
            for (var i = 0; i < est.estimateHeaders.length; i++) {
                est.estimateHeaders[i].itemIndex = i;
            }
            if (!est.approved && props.canEdit) {
                var blankRow = addBlankRow(est.estimateHeaders.length);
                est.estimateHeaders.push(blankRow);
            }

            setEstimate(est);
            setRequestFiles(res.data.requestFiles);

            setLoading(false);
            setReportComments(est.reportComments);

            //get template estimate jobs
            axios.get('Estimate/GetImportTemplateJobs', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                setTemplateJobs(res.data);
            })
            .catch(error => {
                toast.error(error.message);
            });
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var newRow: EstimateHeaders = {
            itemIndex: nextItemIndex,
            id: 0,
            area: "",
            areaOrder: 0,
            amount: 0,
            amountFormatted: "$0.00",
            amountExGstFormatted: "$0.00",
            isDirty: false
        };
        return newRow;
    }

    //IMPORT
    //import estimate from template or another job
    const importEst = () => {
        //this adds to the existing estimate
        setShowImportModal(true);
        setModalSaveDisabled(false);
    }

    const hideImport = () => {
        setShowImportModal(false);
        setModalSaveDisabled(false);
    }

    const importEstimate = (importJobId: string, headerIds: string) => {
        importJobEstimate(importJobId, headerIds);
    }

    const importJobEstimate = async (importJobId: string, headerIds: string) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var jobId = estimate.jobId;

        var jobIdToImport = importJobId == "" ? 0 : importJobId;

        axios.get('Estimate/ImportEstimate?ImportJobId=' + jobIdToImport + '&ImportHeaderIds=' + headerIds + '&jobId=' + jobId + '&subId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //refresh estimate
            var est = res.data;

            //update item index
            for (var i = 0; i < est.estimateHeaders.length; i++) {
                est.estimateHeaders[i].itemIndex = i;
            }
            if (!est.approved && props.canEdit) {
                var blankRow = addBlankRow(estimate.estimateHeaders.length);
                est.estimateHeaders.push(blankRow);
            }
            setEstimate(est);
            setLoading(false);
            setShowImportModal(false);
            setModalSaveDisabled(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const selectTemplate = (index: number, selected: boolean) => {
        var templates = [...templateJobs];
        var template = templates[index];
        template.selected = selected;

        //need to select/unselect all headers
        for (var i = 0; i < template.headers.length; i++) {
            var header = template.headers[i];
            header.selected = selected;
        }
        setTemplateJobs(templates);
    }

    const showTemplateHeaders = (index: number, showHeaders: boolean) => {
        var templates = [...templateJobs];
        var template = templates[index];
        template.showHeaders = showHeaders;

        setTemplateJobs(templates);
    }

    const selectTemplateHeader = (templateIndex: number, headerIndex: number, selected: boolean) => {
        var templates = [...templateJobs];
        var template = templates[templateIndex];
        var header = template.headers[headerIndex];
        header.selected = selected;

        if (!selected) {
            //means we have unticked one of the header items so untick the template selection as we now no longer want to import the whole estimate
            template.selected = false;
        }

        setTemplateJobs(templates);
    }
    //END IMPORT

    //delete row
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setEstimateIndex(index);
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setEstimateIndex(-1);
        setModalSaveDisabled(false);
    }

    const updateSaveDisabled = () => {
        setModalSaveDisabled(true);
    }

    const updateImportSaveDisabled = (saveDisabled: boolean) => {
        setModalSaveDisabled(saveDisabled);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        var est = estimate;
        var estimateHeaders = est.estimateHeaders;
        var estimateHeader = estimateHeaders[estimateIndex];

        if (estimateHeader.id === 0) {
            deleteHeaderUpdateOrder(estimate, estimateHeaders);
        } else {
            deleteHeader(estimate, estimateHeaders, estimateHeader.id);
        }
    }

    const deleteHeaderUpdateOrder = async (est: Estimate, estimateHeaders: EstimateHeaders[]) => {
        const token = await authService.getAccessToken();

        //remove from list
        var removedItem = estimateHeaders.splice(estimateIndex, 1);

        //loop through the headers and update the order
        for (var i = 0; i < estimateHeaders.length; i++) {
            estimateHeaders[i].itemIndex = (i);
            estimateHeaders[i].areaOrder = (i + 1);
        }

        //save headers as order changed
        //save new details order
        axios.post('Estimate/DeleteEstimateHeaderUpdateOrder', estimateHeaders, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                estimateHeaders.splice(estimateIndex, 0, removedItem[0]);
                setEstimate(est);
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(-1);
            } else {
                setEstimate(est);
                setShowConfirmModal(false)
                setModalSaveDisabled(false);
                setEstimateIndex(-1);
                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteHeader = async (est: Estimate, estimateHeaders: EstimateHeaders[], headerId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //remove from list
        var removedItem = estimateHeaders.splice(estimateIndex, 1);

        //loop through the headers and update the order
        for (var i = 0; i < estimateHeaders.length; i++) {
            estimateHeaders[i].itemIndex = (i);
            estimateHeaders[i].areaOrder = (i + 1);
        }

        var deleteDetail = {
            estimateId: estimate.id,
            id: headerId,
            jobId: props.jobId,
            estimateHeaders: estimateHeaders,
            subId: user.sub
        };

        axios.post('Estimate/DeleteEstimateHeader', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
                //add back in deleted item
                estimateHeaders.splice(estimateIndex, 0, removedItem[0]);
                setEstimate(est);
                setShowConfirmModal(false);
                setEstimateIndex(-1);
            } else {
                toast.success("Item has been deleted");

                //update estimate
                estimate.subTotal = res.data.subTotal;
                estimate.subTotalFormatted = res.data.subTotalFormatted;
                estimate.subTotalExGstFormatted = res.data.subTotalExGstFormatted;
                estimate.gstFormatted = res.data.gstFormatted;
                estimate.totalFormatted = res.data.totalFormatted;

                setEstimate(est);
                setShowConfirmModal(false);
                setEstimateIndex(-1);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end delete row

    const calculateTotals = (markup: number) => {
        let est = estimate;
        let subTotal = est.subTotal;
        var subTotalExGst = subTotal + (subTotal * (markup / 100));
        var gstAmount = subTotalExGst * est.gstPercentage;
        var total = subTotalExGst + gstAmount;

        let subTotalExGstFormatted = subTotalExGst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        let gstFormatted = gstAmount.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        let totalFormatted = total.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });

        setEstimate(prevState => ({ ...prevState, subTotalExGstFormatted: subTotalExGstFormatted, gstFormatted: gstFormatted, totalFormatted: totalFormatted }));
    }

    //download job file
    const downloadFile = (e: any, id: number) => {
        e.preventDefault();
        getFile(id);
    }

    const getFile = async (id: number) => {
        const token = await authService.getAccessToken();

        axios('Jobs/GetJobRequestFile?Id=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            //var fileName = res.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];
            var fileheader = res.headers["content-disposition"];
            var filename = "Requestfile.pdf";
            if (fileheader) {
                filename = fileheader.split('filename=')[1].split(';')[0];
                filename = filename.replaceAll('"', '');
            }
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleReportChange = (e: any) => {
        setReportId(e.target.value * 1);
    }

    const handleChange = (e: any) => {
        setEstimate(prevState => ({ ...prevState, [e.target.name]: e.target.value, isDirty: true }));

        if (e.target.name === "markUpPercentage") {
            //need to recalculate the totals
            calculateTotals(e.target.value);
        }
        setSaveDisabled(false);
    }

    const handleCheckboxChange = (e: any) => {
        setEstimate(prevState => ({ ...prevState, [e.target.name]: e.target.checked, isDirty: true }));
        setSaveDisabled(false);
    }

    //grid item changed
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var est = estimate;
        var headers = [...est.estimateHeaders];
        var header = headers[index];
        let exist: any = {};
        exist = header;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (index === (headers.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(headers.length);
            headers.push(newRow);
        }
        est.estimateHeaders = headers;

        setEstimate(est);
        setSaveDisabled(false);
        setErrors({});
    }

    //capture shortcut keys
    const handleKeyUp = (itemIndex: number, e: any) => {
        if (e.ctrlKey && e.keyCode === 50) {
            e.preventDefault();
            //go down
            openDetails(itemIndex, e);
        }
    }

    //copy header item
    const copy = (itemIndex: number, e: any) => {
        e.preventDefault();

        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        //if isDirty - ask if want to save changes
        var changed = estimate.estimateHeaders.filter(data => data.isDirty);
        if (!modalSaveDisabled && (changed.length > 0 || estimate.isDirty)) {
            saveEstimate(itemIndex, "copy");
        } else {
            copyHeader(itemIndex);
        }

    }

    const copyHeader = async (itemIndex: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var jobId = estimate.jobId;
        var headerId = estimate.estimateHeaders[itemIndex].id;

        axios.get('Estimate/CopyEstimateHeader?JobId=' + jobId + '&EstimateId=' + estimate.id + '&HeaderId=' + headerId + '&subId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //refresh estimate
            var est = res.data;

            //update item index
            for (var i = 0; i < est.estimateHeaders.length; i++) {
                est.estimateHeaders[i].itemIndex = i;
            }
            if (!est.approved && props.canEdit) {
                var blankRow = addBlankRow(est.estimateHeaders.length);
                est.estimateHeaders.push(blankRow);
            }
            setEstimate(est);
            setLoading(false);
            setShowImportModal(false);
            setModalSaveDisabled(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end copy header

    //open details page
    const openDetails = (itemIndex: number, e: any) => {
        e.preventDefault();

        //if isDirty - ask if want to save changes
        var changed = estimate.estimateHeaders.filter(data => data.isDirty);
        if (!modalSaveDisabled && (changed.length > 0 || estimate.isDirty)) {
            setModalSaveDisabled(true);
            saveEstimate(itemIndex, "");
        } else {
            var estDetail = estimate.estimateHeaders[itemIndex];
            openDetailsPage(estimate.id, estDetail.id, estDetail.area);
        }
    }

    const openDetailsPage = (estimateId: number, headerId: number, area: string) => {
        var pageChange: PageChangeData = {
            estimateId: estimateId,
            id: headerId,
            page: "details",
            headerText: area,
            costCodeId: -1,
            prevId: 0,
            prevHeaderText: ""
        };

        props.changePage(pageChange);
    }
    //end open details page

    //run job photos report
    const runJobPhotosReport = () => {
        runPhotosReport();
    }

    const runPhotosReport = async () => {
        const token = await authService.getAccessToken();

        //get photos
        axios.get('Jobs/GetJobPhotos?JobId=' + props.jobId + '&TypeId=2', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var photos = res.data.photos;
            if (!photos) {
                photos = [];
            }

            var uploadedPhotos: UploadedFile[] = [];
            for (var i = 0; i < photos.length; i++) {
                var uploadedPhoto: UploadedFile = {
                    file: {
                        id: photos[i].id,
                        fileName: photos[i].fileName,
                        fileType: photos[i].fileType,
                        content: photos[i].content,
                        photoOrder: photos[i].photoOrder
                    },
                    comment: photos[i].comment
                };
                uploadedPhotos.push(uploadedPhoto);
            }
            setShowPhotoModal(true);
            setUploadedFiles(uploadedPhotos);
        });
    }

    const hidePhotoImport = () => {
        setShowPhotoModal(false);
        setUploadedFiles([]);
    }

    const importPhotos = (files: File[]) => {
        setLoadingPhotos(true);
        importJobPhotos(files);
    }

    const importJobPhotos = async (files: File[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save photos
        const data = new FormData()
        data.append('jobId', props.jobId.toString());
        data.append('typeId', "2");
        data.append('subId', user.sub);

        for (var i = 0; i < files.length; i++) {
            data.append('photos', files[i]);
        }

        //save photos
        axios.post('Jobs/ImportJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //save the returned photos
                var files: UploadedFile[] = [];
                var photos = res.data.photos;
                for (var p = 0; p < photos.length; p++) {
                    var photo = photos[p];
                    var file: UploadedFile = {
                        file: {
                            id: photo.id,
                            fileName: photo.fileName,
                            fileType: photo.fileType,
                            content: photo.content,
                            photoOrder: photo.photoOrder
                        },
                        comment: photo.comment
                    };
                    files.push(file);
                }
                setUploadedFiles(files);
                setLoadingPhotos(false);
                
                toast.success("Photos have been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const updatePhotoImport = (files: UploadedFile[]) => {
        setUploadedFiles(files);
    }

    const deletePhotoImport = (deleteIndex: number) => {
        var files = [...uploadedFiles];
        var photo = files[deleteIndex];

        if (!photo) {
            toast.error("Could not find photo to delete!");
            return;
        }

        if (photo.file.id > 0) {
            deletePhoto(photo.file.id, deleteIndex);
        } else {
            //save the photos on upload so this shouldn't happen but just in case
            files.splice(deleteIndex, 1);
            //re-order the photos
            for (var i = 0; i < files.length; i++) {
                files[i].file.photoOrder = i + 1;
            }
            setUploadedFiles(files);
            toast.success("Photo has been deleted");
        }

    }

    const deletePhoto = async (photoId: number, deleteIndex: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var files = [...uploadedFiles];

        var data = {
            id: photoId,
            subId: user.sub
        };

        //delete photo
        axios.post('Jobs/DeleteJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                files.splice(deleteIndex, 1);
                //re-order the photos
                for (var i = 0; i < files.length; i++) {
                    files[i].file.photoOrder = i + 1;
                }
                setUploadedFiles(files);
                toast.success("Photo has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const savePhotoImport = () => {
        saveJobPhotoImport();
    }

    const saveJobPhotoImport = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var files = [...uploadedFiles];

        var photoComments = [];
        for (var i = 0; i < files.length; i++) {
            var photoOrder = files[i].file.photoOrder;
            if (!photoOrder) {
                //set the order for any null entries
                photoOrder = i + 1;
            }

            var item = {
                id: files[i].file.id,
                comments: files[i].comment,
                photoOrder: photoOrder
            };
            photoComments.push(item);
        }

        var data = {
            jobId: props.jobId,
            reportComments: reportComments,
            jobPhotoComments: photoComments,
            subId: user.sub
        };

        //save comments
        axios.post('Jobs/SaveJobPhotoReportComments', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Photos have been saved");
                setShowPhotoModal(false);
                setUploadedFiles([]);
                setModalSaveDisabled(false);
                printJobPhotosReport();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const updatePhotoComments = (rptComments: string) => {
        setReportComments(rptComments);
    }

    const printJobPhotosReport = () => {
        var url = "/job/" + props.jobId + "/report/1/jobPhotos";
        window.open(url, '_blank');
    }
    //end job photos report

    const runReport = () => {
        var reportName = "";
        var rptId = reportId * 1;

        if (rptId < 1) {
            toast.error("Select a report to run!");
        } else {
            switch (rptId) {
                case (1):
                    reportName = "scopeOfWorks";
                    break;
                case (2):
                    reportName = "quotationScope";
                    break;
                case (3):
                    reportName = "quotationItemScope";
                    break;
                case (4):
                    reportName = "quotationAreaScope";
                    break;
                case (5):
                    reportName = "quotationItemQtyScope";
                    break;
                case (6):
                    reportName = "quotationPriceScope";
                    break;
                case (7):
                    reportName = "quotationScopeMargin";
                    break;
                case (8):
                    reportName = "tradeBreakdown";
                    break;
                case (9):
                    reportName = "authority";
                    break;
                case (10):
                    reportName = "authorityScope";
                    break;
                default:
                    reportName = "";
                    break;
            }
            if (reportName !== "") {
                var data: RunReportData = { estimateId: estimate.id, reportName: reportName };
                props.runReport(data);
            }
        }
    }

    const email = () => {
        //validate and save before emailing
        //if isDirty - ask if want to save changes
        var changed = estimate.estimateHeaders.filter(data => data.isDirty);
        if (changed.length > 0 || estimate.isDirty) {
            saveEstimate(-1, "email");
        } else {
            emailQuote();
        }
    }

    const emailQuote = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var email = {
            jobId: estimate.jobId,
            estimateId: estimate.id,
            subId: user.sub
        }

        axios.post("Estimate/EmailQuote", email, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("Quote has been emailed Successfully");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const approve = () => {
        //validate and save before approving
        var changed = estimate.estimateHeaders.filter(data => data.isDirty);
        if (changed.length > 0 || estimate.isDirty) {
            saveEstimate(-1, "approve");
        } else {
            approveQuote();
        }
    }

    const approveQuote = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let est = estimate;
        var approve = {
            jobId: est.jobId,
            estimateId: est.id,
            subId: user.sub
        };

        axios.post("Estimate/ApproveQuote", approve, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                setEstimate(prevState => ({ ...prevState, approved: true }));
                toast.success("Quote has been successfully approved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const unapprove = () => {
        unapproveQuote();
    }

    const unapproveQuote = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let est = estimate;
        var unapprove = {
            jobId: est.jobId,
            estimateId: est.id,
            subId: user.sub
        }

        axios.post("Estimate/UnapproveQuote", unapprove, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                setEstimate(prevState => ({ ...prevState, approved: false, sent: false, receivedSignedContract: false }));
                toast.success("Quote has been successfully unapproved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = estimate.estimateHeaders;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        //if approved or can't edit we only have 1 row not 2
        let itemLengthCheck = (estimate.approved || !props.canEdit) ? 1 : 2;

        if (items.length < itemLengthCheck) {
            errorString = "Please enter at least one Area!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].area) {
                    newErrorString += " Area, ";
                    error = true;
                }

                if (newErrorString != "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["headers"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        setModalSaveDisabled(false);
        saveEstimate(-1, "");
    }

    const saveEstimate = async (openIndex: number, afterSave: string) => {
        if (validate()) {
            var est = estimate;
            //Only save items that have changed
            var changed = est.estimateHeaders.filter(data => data.isDirty);
            if (changed.length === 0 && est.isDirty === false) {
                toast.info("No items have been changed!");
            } else {
                const user = await authService.getUser();
                var saveEstimate = {
                    id: est.id,
                    consultantScope: est.consultantScope,
                    consultantScopeNotes: est.consultantScopeNotes,
                    jobId: est.jobId,
                    sent: est.sent,
                    approved: est.approved,
                    receivedSignedContract: est.receivedSignedContract,
                    startJobWithoutExcess: est.startJobWithoutExcess,
                    estimateHeaders: changed,
                    subTotalFormatted: est.subTotalFormatted,
                    markUpPercentage: est.markUpPercentage,
                    subTotalExGstFormatted: est.subTotalExGstFormatted,
                    gstFormatted: est.gstFormatted,
                    totalFormatted: est.totalFormatted,
                    isDirty: est.isDirty,
                    subId: user.sub
                };

                const token = await authService.getAccessToken();
                axios.post('Estimate/SaveEstimate', saveEstimate, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        for (var i = 0; i < errors.length; i++) {
                            toast.error(errors[i].content);
                        };
                    } else {
                        toast.success("Estimate Saved");
                        if (afterSave === "copy") {
                            copyHeader(openIndex);
                        } else if (openIndex >= 0) {
                            //open the details page
                            changed = res.data.estimateHeaders;
                            var findRow = changed.filter(data => data.itemIndex === openIndex);
                            var headerid = est.estimateHeaders[openIndex].id;
                            var area = est.estimateHeaders[openIndex].area;
                            if (findRow) {
                                headerid = findRow[0].id;
                                area = findRow[0].area;
                            }
                            openDetailsPage(res.data.id, headerid, area);
                        } else if (afterSave === "email") {
                            emailQuote();
                        } else if (afterSave === "approve") {
                            approveQuote();
                        } else {
                            changed = res.data.estimateHeaders;
                            //loop through the changed items and update the main list
                            for (var i = 0; i < changed.length; i++) {
                                est.estimateHeaders[changed[i].itemIndex] = changed[i];
                            }
                            est.isDirty = false;
                            setEstimate(est);
                            setErrors({});
                        }
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            }
        } else {
            toast.error("Please check and resolve the errors at the bottom of the table");
        }
    }

    //MOVE ROW
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var est = estimate;
        var estimateHeaders = [...est.estimateHeaders];
        var hasBlankRow = !est.approved && props.canEdit;
        var estHeaderLength = estimateHeaders.length - 1;

        if ((hasBlankRow && newIndex != estHeaderLength && oldIndex != estHeaderLength) || !hasBlankRow) {
            //only move if not blank row
            estimateHeaders.splice(newIndex, 0, estimateHeaders.splice(oldIndex, 1)[0]);

            //loop through the headers and update the order
            var length = hasBlankRow ? estimateHeaders.length - 1 : estimateHeaders.length;
            for (var i = 0; i < length; i++) {
                estimateHeaders[i].itemIndex = (i);
                estimateHeaders[i].areaOrder = (i + 1);
                estimateHeaders[i].isDirty = true;
            }

            setEstimate(est);
            saveEstimate(-1, "");
        }
    };
    //END MOVE ROW


    let confirmPopup = <ConfirmModal heading="Delete Estimate Area" text="Are you sure you want to delete this estimate area?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let importEstimatePopup = <JobEstimateImportPopups templateJobs={templateJobs} showModal={showImportModal} hideModal={hideImport} importModal={importEstimate} saveDisabled={modalSaveDisabled} updateSaveDisabled={updateImportSaveDisabled} selectTemplate={selectTemplate} showTemplateHeaders={showTemplateHeaders} selectTemplateHeader={selectTemplateHeader} />
    let photoImport = <ReportJobPhotosPopup title="Select photos for photos report" showModal={showPhotoModal} loadingPhotos={loadingPhotos} uploadedFiles={uploadedFiles} reportComments={reportComments} hideModal={hidePhotoImport} importPhotos={importPhotos} update={updatePhotoImport} delete={deletePhotoImport} save={savePhotoImport} saveDisabled={modalSaveDisabled} updateSaveDisabled={updateSaveDisabled} updateReportComments={updatePhotoComments} />
    let lastRow = (estimate.approved || !props.canEdit) ? estimate.estimateHeaders.length : estimate.estimateHeaders.length - 1;

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {importEstimatePopup}
                {photoImport}
            </div>
            <div className="makeitflexspacebetween">
                <div>
                    <label className="input-group estimate__consultScope" htmlFor="consultantScope">
                        <span className="label">Consultant Scope</span>
                        <input className="checkbox" type="checkbox" id="consultantScope" name="consultantScope" checked={estimate.consultantScope} onChange={(e) => handleCheckboxChange(e)} disabled={!props.canEdit} ></input>
                    </label>
                    <label className={estimate.consultantScope ? "input-group" : "hidden"} htmlFor="consultantScopeNotes">
                        <span className="label">Notes</span>
                        <textarea rows={4} className="input estimate-consultantnotes--size" id="consultantScopeNotes" maxLength={500} name="consultantScopeNotes" value={estimate.consultantScopeNotes} onChange={(e) => handleChange(e)} disabled={!props.canEdit}></textarea>
                    </label>
                </div>
                <div className={requestFiles.length > 0 ? "" : "hidden"}>
                    {requestFiles.map((file, index) => 
                        <div key={file.id}><a href="#" onClick={(e) => downloadFile(e, file.id)}>{file.fileName}</a></div>    
                    )}
                </div>
                <div>
                    <div className="estimate-import--btnPosition">
                        <button className={props.canEdit ? "defaultbutton defaultbutton__small marginBottom10" : "hidden"} type="button" onClick={importEst} disabled={estimate.approved}>Import</button>
                    </div>
                </div>
            </div>

            <div className='overflowAuto'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th></th>
                            <th>Area</th>
                            <th></th>
                            <th className="textalignright">Sub Total</th>
                            <th className="textalignright">Sub Total Ex Gst</th>
                            <th></th>
                            <th className={estimate.approved || !props.canEdit ? "hidden" : ""}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {estimate.estimateHeaders.map((header, itemIndex) =>
                            <tr key={itemIndex} draggable="true" onDragStart={(e) => handleDragStart(e, itemIndex)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, itemIndex)}>
                                <td className="hidden">{header.id}</td>
                                <td className="table__text--align">{header.areaOrder == 0 || header.areaOrder == 99 ? itemIndex + 1 : header.areaOrder}</td>
                                <td className="table__text--align">
                                    <input type="text" maxLength={100} className={estimate.approved || !props.canEdit ? "hidden" : "form-control estimate__scopeWidth"} name="area" value={header.area} onChange={(e) => handleCellChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} />
                                    {estimate.approved || !props.canEdit ? header.area : ""}
                                </td>
                                <td className="table__text--align">
                                    <a className={itemIndex === lastRow ? "hidden" : ""} href="#" onClick={(e) => openDetails(itemIndex, e)}>
                                        Open
                                    </a>
                                </td>
                                <td className="table__text--align textalignright">{header.amountFormatted}</td>
                                <td className="table__text--align textalignright">{header.amountExGstFormatted}</td>
                                <td className="table__text--align">
                                    <a className={estimate.approved || !props.canEdit || header.id === 0 ? "hidden" : ""} href="#" onClick={(e) => copy(itemIndex, e)}>
                                        Copy
                                    </a>
                                </td>
                                <td className={estimate.approved || !props.canEdit ? "hidden" : "table__text--align"}>
                                    <div className={itemIndex === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(itemIndex, e)} >
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["headers"] ?
                    (errors["headers"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div className="input-group-parent">
                <label className="input-group" htmlFor="sent">
                    <span className="label">Quote Sent</span>
                    <input className="checkbox" type="checkbox" id="sent" name="sent" checked={estimate.sent} onChange={(e) => handleCheckboxChange(e)} disabled={estimate.approved || !props.canEdit} ></input>
                </label>
                <label className="input-group" htmlFor="receivedSignedContract">
                    <span className="label">Received Signed Authority</span>
                    <input className="checkbox" type="checkbox" id="receivedSignedContract" name="receivedSignedContract" checked={estimate.receivedSignedContract} onChange={(e) => handleCheckboxChange(e)} disabled={estimate.approved === false || !props.canEdit} ></input>
                </label>
                <label className={props.canStartJobWithoutExcess ? "input-group" : "hidden"} htmlFor="startJobWithoutExcess">
                    <span className="label">Start Job Without Excess</span>
                    <input className="checkbox" type="checkbox" id="startJobWithoutExcess" name="startJobWithoutExcess" checked={estimate.startJobWithoutExcess} onChange={(e) => handleCheckboxChange(e)} disabled={estimate.approved === false || !props.canEdit} ></input>
                </label>
            </div>
            <div className="input-group-parent">
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="subTotalFormatted">
                    <span className="label labeltextalignright">Sub Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalFormatted" name="subTotalFormatted" value={estimate.subTotalFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="markUpPercentage">
                    <span className="label labeltextalignright">Global Mark-Up %</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="markUpPercentage" name="markUpPercentage" value={estimate.markUpPercentage} onChange={(e) => handleChange(e)} disabled={estimate.approved || !props.canEdit} ></input>
                </label>
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="subTotalExGstFormatted">
                    <span className="label labeltextalignright">Sub Total ex GST</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalExGstFormatted" name="subTotalExGstFormatted" value={estimate.subTotalExGstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="gstFormatted">
                    <span className="label labeltextalignright">GST</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={estimate.gstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label labeltextalignright">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={estimate.totalFormatted} disabled></input>
                </label>
            </div>
            <button className="defaultbutton" type="submit" disabled={!props.canEdit || saveDisabled}>Save</button>
            <button className={props.canEdit ? "defaultbutton defaultbutton__small marginLeft10" : "hidden"} type="button" onClick={approve} disabled={estimate.approved || !estimate.sent}>Approve</button>
            <button className={props.canEdit && estimate.approved && props.canUnapprove ? "defaultbutton defaultbutton__small marginLeft10" : "hidden"} type="button" onClick={unapprove}>Unapprove</button>
            <select className="select estimate__report--size" id="reportId" name="reportId" value={reportId} onChange={(e) => handleReportChange(e)} disabled={!props.canEdit}>
                <option hidden defaultValue="-1"></option>
                <option value="1">Scope of Works</option>
                <option value="2">Quotation Scope</option>
                <option value="3">Quotation Item Scope</option>
                <option value="4">Quotation Area Scope</option>
                <option value="5">Quotation Qty Scope</option>
                <option value="6">Quotation Price Scope</option>
                <option value="7">Quotation Scope MARGIN</option>
                <option value="8">Trade Breakdown</option>
                <option value="9">Authority</option>
                <option value="10">Authority Scope</option>
            </select>
            <button className="defaultbutton defaultbutton__small marginLeft10" type="button" onClick={runReport} disabled={!props.canEdit}>Run Report</button>
            <button className="defaultbutton defaultbutton__medium marginLeft10" type="button" onClick={runJobPhotosReport}>Run Photos Report</button>
            <button className={isSafari ? "defaultbutton defaultbutton__medium marginLeft10" : "hidden"} type="button" onClick={printJobPhotosReport}>View Photos Report</button>
        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

}

export default EstimateHeaderData;

import * as React from 'react';

interface JobDetailsAddInsurersProps {
    jobId: number;
    insurerOptions: GenericListItem[];
    insurers: JobInsurerClaimLine[];
    update(insurers: JobInsurerClaimLine[]): void;
}

interface JobInsurerClaimLine {
    id: number;
    insurerId: number;
    claimNumber: string;
    splitPercentage: number;
    status: number;
}

interface GenericListItem {
    id: number;
    name: string;
}

const NewJobDetailsAddInsurers = (props: JobDetailsAddInsurersProps) => {

    const handleCellChange = (e: any, itemIndex: number) => {
        e.preventDefault();

        let insurerDetails = [...props.insurers];
        let insurer = insurerDetails[itemIndex];
        let change: any = {};
        change = insurer;
        change[e.target.name] = e.target.value;

        //add a new row if we are updating the last row
        if (itemIndex === insurerDetails.length - 1) {
            var newRow = addBlankRow();
            insurerDetails.push(newRow);
        }

        props.update(insurerDetails);
    }

    const addBlankRow = () => {
        var blankRow: JobInsurerClaimLine = {
            id: 0,
            insurerId: -1,
            claimNumber: "",
            splitPercentage: 0,
            status: 1
        };

        return blankRow;
    }

    const deleteItem = (e: any, itemIndex: number) => {
        let insurerDetails = [...props.insurers];
        let insurer = insurerDetails[itemIndex];

        if (insurer.id > 0) {

        }
    }

    const deleteInsurer = async (insurerId: number) => {

    }

    const lastRow = props.insurers.length - 1;

    return (
        <div>
            <div>Add multiple insurers to the job, with a split % for invoicing.</div>
            <div>NOTE: Include main insurer in this list. Split % must add to 100.</div>
            <div className='overflowAuto'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th></th>
                            <th>Insurer</th>
                            <th>Claim Number</th>
                            <th>Split Percentage</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.insurers.map((item, index) =>
                            <tr key={index} >
                                <td className="hidden">{item.id}</td>
                                <td className="table__text--align">{index + 1}</td>
                                <td className="table__text--align">
                                    <select className="select" id="insurerId" name="insurerId" value={item.insurerId} onChange={(e) => handleCellChange(e, index)}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.insurerOptions.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <input type="text" maxLength={20} className="form-control" name="claimNumber" value={item.claimNumber} onChange={(e) => handleCellChange(e, index)} />

                                </td>
                                <td className="table__text--align">
                                    <input type="text" maxLength={100} className="form-control textalignright" name="splitPercentage" value={item.splitPercentage} onChange={(e) => handleCellChange(e, index)} />
                                </td>
                                <td className="table__text--align">
                                    <div className={index === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(e, index)} >
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default NewJobDetailsAddInsurers;
import * as React from 'react';
import { useReducer } from 'react';
import GoogleAutoComplete from './GoogleAutoComplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import supplierReducer from './SupplierReducer';

interface SupplierAddProps {
    id: number;
    supplierTypes: GenericListItem[];
    termOptions: GenericListItem[];
    trades: GenericListItem[];
    otherTrades: GenericListItem[];
    statusOptions: GenericListItem[];
    backToSearch(supplierId: number): void;
}

interface XeroListItem {
    id: string;
    name: string;
    totalFormatted: string;
    selected: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface SupplierDetails {
    id: number;
    xeroContactId: string;
    xeroContactName: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    termsId: number;
    primaryTradeId: number;
    supplierTrades: GenericListItem[];
    phone: string;
    email: string;
    typeId: number;
    status: number;
    lockAccount: boolean;
}

interface SupplierContacts {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const SupplierAddDetails = (props: SupplierAddProps) => {
    const [xeroNameSearch, setXeroNameSearch] = React.useState("");
    const [xeroCustomers, setXeroCustomers] = React.useState<XeroListItem[]>([]);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const initialSupplierDetails: SupplierDetails = {
        id: 0,
        xeroContactId: "",
        xeroContactName: "",
        name: "",
        addressManual: false,
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        registeredForGst: false,
        abn: "",
        primaryTradeId: -1,
        supplierTrades: [],
        phone: "",
        email: "",
        typeId: -1,
        status: 1,
        lockAccount: false,
        termsId: 1
    }

    const initialSupplierContacts: SupplierContacts[] = [{
        id: 0,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    }];

    const [{
        supplierDetails,
        supplierContacts,
        supplierCertificates,
        isLoading
    }, dispatch] = useReducer(supplierReducer, { supplierDetails: initialSupplierDetails, supplierContacts: initialSupplierContacts, supplierCertificates: [], isLoading: false });

    const updateDetails = (updatedSupplierDetails: SupplierDetails) => {
        dispatch({ type: "updateDetails", supplierDetails: updatedSupplierDetails });
    }

    const updateContacts = (updatedSupplierContacts: SupplierContacts[]) => {
        dispatch({ type: "updateContacts", supplierContacts: updatedSupplierContacts });
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        var supplierDetail = supplierDetails;
        let newSupplier: any = {};
        newSupplier = supplierDetail;
        newSupplier[e.target.name] = e.target.value;

        updateDetails(newSupplier);
    }

    const handleContactChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            if (e.target.name === "phone" || e.target.name === "mobile") {
                delete errors["contactphone"];
            } else if (e.target.name === "email") {
                delete errors["contactemail"];
            } else {
                delete errors[e.target.name];
            }
            setErrors(errors);
        }

        var supplierConts = [...supplierContacts];
        var contact = supplierConts[0];
        let newContact: any = {};
        newContact = contact;
        newContact[e.target.name] = e.target.value;

        updateContacts(supplierConts);
    }

    const handleCellCheckboxChange = (index: number, e: any) => {
        //if selected checked then set all other customers to false
        if (e.target.checked) {
            for (var i = 0; i < xeroCustomers.length; i++) {
                xeroCustomers[i].selected = false;
            }
        }

        var customers = [...xeroCustomers]
        var xeroCustomer = customers[index];
        let exist: any = {};
        exist = xeroCustomer;
        exist[e.target.name] = e.target.checked;

        setXeroCustomers(customers);
    }

    const handleCheckboxChange = (e: any) => {
        let newSupplier: any = {};
        newSupplier = supplierDetails;
        newSupplier[e.target.name] = e.target.checked;
        updateDetails(newSupplier);
    }

    const handleTradeChange = (e: any) => {
        var supplierDets = supplierDetails;
        var supplierTrades = supplierDets.supplierTrades;
        var newTrade: GenericListItem = {
            id: parseInt(e.target.value),
            name: e.target.selectedOptions[0].label
        };
        supplierTrades.push(newTrade);
        
        updateDetails(supplierDets);
    }

    //remove role from selected
    const removeItem = (e: any, id: number) => {
        e.preventDefault();

        var supplierDets = supplierDetails;
        var supplierTrades = supplierDets.supplierTrades;
        supplierTrades.splice(supplierTrades.findIndex(function (i) {
            return i.id === id;
        }), 1);

        
        updateDetails(supplierDets);
    }

    //update google address
    const setSupplierAddress = (addressDetails: AddressDetails) => {
        delete errors["address"];
        setErrors(errors);

        updateAddress(addressDetails);
    }

    const updateAddress = (addressDetails: AddressDetails) => {
        var supplier = supplierDetails;
        supplier.address = addressDetails.fullAddress;
        supplier.streetNumber = addressDetails.street_number;
        supplier.streetName = addressDetails.route;
        supplier.suburb = addressDetails.locality;
        supplier.state = addressDetails.administrative_area_level_1;
        supplier.country = addressDetails.country;
        supplier.postCode = addressDetails.postal_code;
        supplier.googlePlaceId = addressDetails.placeId;
        supplier.siteAddressLatitude = addressDetails.latitude;
        supplier.siteAddressLongitude = addressDetails.longitude;
        updateDetails(supplier);
    }

    const searchXeroContacts = (e: any) => {
        e.preventDefault();
        searchXeroSupplierCustomers();
    }

    const searchXeroSupplierCustomers = async() => {
        if (xeroNameSearch) {
            //check if we need to check Xero Auth
            //check authentication
            //enter the page that xero needs to return to, id blank as not used via invoicing
            var page = {
                page: "supplier",
                id: props.id
            };
            //encode to base 64 so can retrieve it later
            var encoded = btoa(JSON.stringify(page));

            const user = await authService.getUser();
            const token = await authService.getAccessToken();

            axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var isAuth = res.data.isAuthorised;
                var xeroLoginUrl = res.data.xeroLoginUrl;

                if (isAuth) {
                    //if authorised
                    axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + xeroNameSearch, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                        .then(res => {
                            var customers = res.data;
                            var xeroCusts = [...xeroCustomers];
                            for (var i = 0; i < customers.length; i++) {
                                var customer = customers[i];
                                customer.selected = false;
                                xeroCusts.push(customer);
                            }
                            setXeroCustomers(xeroCusts);

                            if (customers.length === 0) {
                                toast.info("There were no Xero customers that matched your search.  Note: Xero search is case sensitive!");
                            }
                        })
                        .catch(error => {
                            toast.error(error.message);
                        });
                } else {
                    window.location.replace(xeroLoginUrl)
                }
            });
        } else {
            let error: any = {};
            error["xeroNameSearch"] = "Enter name to search";
            setErrors(error);
        }
    }

    const validate = () => {
        let fields = supplierDetails;
        let error: any = {};
        let formIsValid = true;

        if (!fields.name) {
            formIsValid = false;
            error["name"] = "Name is required";
        }

        if (fields.typeId < 0) {
            formIsValid = false;
            error["typeId"] = "Supplier Type is required";
        }

        if (fields.addressManual) {
            if (!fields.streetName) {
                formIsValid = false;
                error["streetName"] = "Street Name is required";
            }

            if (!fields.suburb) {
                formIsValid = false;
                error["suburb"] = "Suburb is required";
            }

            if (!fields.state) {
                formIsValid = false;
                error["state"] = "State is required";
            }

            if (!fields.postCode) {
                formIsValid = false;
                error["postCode"] = "Post Code is required";
            }
        } else {
            if (!fields.address) {
                formIsValid = false;
                error["address"] = "Address is required";
            }
        }

        if (fields.registeredForGst === true && !fields.abn) {
            formIsValid = false;
            error["abn"] = "ABN is required";
        }
        if (fields.termsId < 0) {
            formIsValid = false;
            error["termsId"] = "Terms are required";
        }
        if (!fields.email) {
            formIsValid = false;
            error["email"] = "Email is required";
        }
        if (fields.primaryTradeId < 0) {
            formIsValid = false;
            error["primaryTradeId"] = "Primary Trade is required";
        }
        if (fields.status < 0) {
            formIsValid = false;
            error["status"] = "Status is required";
        }

        var contact = supplierContacts[0];
        if (!contact.firstName) {
            formIsValid = false;
            error["firstName"] = "First Name is required";
        }

        if (!contact.lastName) {
            formIsValid = false;
            error["lastName"] = "Last Name is required";
        }

        if (!contact.phone && !contact.mobile) {
            formIsValid = false;
            error["contactphone"] = "Phone or Mobile is required";
        }

        if (!contact.email) {
            formIsValid = false;
            error["contactemail"] = "Email is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {

            var xeroContacts = xeroCustomers.filter(x => x.selected);
            if (xeroContacts.length > 0) {
                //need to update the contact details with the xero contact id
                var supplierDets = supplierDetails;
                supplierDets.xeroContactId = xeroContacts[0].id;
                supplierDets.xeroContactName = xeroContacts[0].name;
                updateDetails(supplierDets);
            }
            saveSupplier(false);
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveSupplier = async (sendToXero: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let supplier = supplierDetails

        var saveSupplier = {
            supplierDetails: supplier,
            supplierContacts: supplierContacts,
            supplierCertificates: [],
            subId: user.sub
        };

        axios.post('Suppliers/Save', saveSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                let supplierId = res.data.id;
                if (sendToXero) {
                    supplier.id = supplierId;
                    updateDetails(supplier);
                    sendSupplierToXero(supplierId);
                } else {
                    toast.success("Supplier Saved");
                    dispatch({ type: "reset" });
                    props.backToSearch(supplierId);
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const sendSupplierToXero = async (supplierId: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "supplier",
            id: supplierId
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        var sendSupplier = {
            supplierDetails: supplierDetails,
            supplierContacts: supplierContacts,
            supplierCertificates: [],
            subId: user.sub
        };

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //if authorised
                axios.post('Xero/SendSupplierToXero', sendSupplier, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        toast.success("Supplier Saved and Sent To Xero");
                        dispatch({ type: "reset" });
                        props.backToSearch(supplierId);
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    //work out the available suppliers
    var availTrades = props.otherTrades.filter(function (data) {
        var found = supplierDetails.supplierTrades.find(r => r.id === data.id);
        if (!found) {
            return data;
        }
    });
    let contact = supplierContacts[0];    //only 1 contact when adding
    var googleSupplierAddress = <GoogleAutoComplete addressId="autocompleteaddsupplier" addressLabel={"Address"} placeholder={supplierDetails.address} className="input-group" disabled={false} save={setSupplierAddress} />

    return (
        <form name="supplier" onSubmit={save}>
            <div>
                <h4>Supplier Details</h4>
                <label className="input-group" htmlFor="name">
                    <span className="label">Name</span>
                    <input className="input" type="text" maxLength={200} id="name" name="name" value={supplierDetails.name} onChange={(e) => handleChange(e)} disabled={props.id === 0 ? false : true}></input>
                </label>
                <span className={errors["name"] ? "label errors errors__leftmargin" : "hidden"}>{errors["name"]}</span>

                <div className={supplierDetails.xeroContactId ? "hidden" : ""}>
                    <label className="input-group" htmlFor="xeroNameSearch">
                        <span className="label">Xero Name Search</span>
                        <input className='input marginRight10' type='text' id="xeroNameSearch" name="xeroNameSearch" value={xeroNameSearch} onChange={(e) => setXeroNameSearch(e.target.value)}></input>
                        <button className="defaultbutton defaultbutton__xsmall supplier-invoice__search--position" type="button" onClick={searchXeroContacts}>Search</button>
                    </label>
                    <span className={errors["xeroNameSearch"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroNameSearch"]}</span>

                    <div className={xeroCustomers.length === 0 ? "hidden" : ""}>
                        <div className='overflowAuto client__xeroTableSize'>
                            <table className="table--main table__small tableColours">
                                <thead>
                                    <tr>
                                        <th className="hidden">Id</th>
                                        <th></th>
                                        <th>Name</th>
                                        <th className="textalignright">Outstanding</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {xeroCustomers.map((xeroContact, index) =>
                                        <tr key={index}>
                                            <td className="hidden">{xeroContact.id}</td>
                                            <td><input type="checkbox" className="input checkbox" name="selected" checked={xeroContact.selected} onChange={(e) => handleCellCheckboxChange(index, e)} /></td>
                                            <td>{xeroContact.name}</td>
                                            <td className="table__text--align textalignright">{xeroContact.totalFormatted}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <label className="input-group" htmlFor="typeId">
                    <span className="label">Supplier Type</span>
                    <select className="select" id="typeId" name="typeId" value={supplierDetails.typeId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {props.supplierTypes.map(type =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["typeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["typeId"]}</span>

                <label className="input-group" htmlFor="addressManual">
                    <span className="label">Manual Address / PO Box</span>
                    <input className="checkbox" type="checkbox" id="addressManual" name="addressManual" checked={supplierDetails.addressManual} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <div className={supplierDetails.addressManual ? "hidden" : ""}>
                    {googleSupplierAddress}
                    <span className={errors["address"] ? "label errors errors__leftmargin" : "hidden"}>{errors["address"]}</span>
                </div>
                <div className={supplierDetails.addressManual ? "" : "hidden"}>
                    <label className="input-group" htmlFor="streetName">
                        <span className="label">Street Name</span>
                        <input className="input" type="text" maxLength={100} id="streetName" name="streetName" value={supplierDetails.streetName} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["streetName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["streetName"]}</span>

                    <label className="input-group" htmlFor="suburb">
                        <span className="label">Suburb</span>
                        <input className="input" type="text" maxLength={100} id="suburb" name="suburb" value={supplierDetails.suburb} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["suburb"] ? "label errors errors__leftmargin" : "hidden"}>{errors["suburb"]}</span>

                    <label className="input-group" htmlFor="state">
                        <span className="label">State</span>
                        <select className="select" id="state" name="state" value={supplierDetails.state} onChange={(e) => handleChange(e)} >
                            <option value="VIC">VIC</option>
                            <option value="NSW">NSW</option>
                            <option value="QLD">QLD</option>
                            <option value="WA">WA</option>
                            <option value="SA">SA</option>
                            <option value="TAS">TAS</option>
                            <option value="ACT">ACT</option>
                            <option value="NT">NT</option>
                        </select>
                    </label>
                    <span className={errors["state"] ? "label errors errors__leftmargin" : "hidden"}>{errors["state"]}</span>

                    <label className="input-group" htmlFor="postCode">
                        <span className="label">Post Code</span>
                        <input className="input" type="text" maxLength={10} id="postCode" name="postCode" value={supplierDetails.postCode} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["postCode"] ? "label errors errors__leftmargin" : "hidden"}>{errors["postCode"]}</span>
                </div>

                <label className="input-group" htmlFor="registeredForGst">
                    <span className="label">Registered For GST</span>
                    <input className="checkbox" type="checkbox" id="registeredForGst" name="registeredForGst" checked={supplierDetails.registeredForGst} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="abn">
                    <span className="label">ABN</span>
                    <input className="input" type="text" maxLength={20} id="abn" name="abn" value={supplierDetails.abn} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["abn"] ? "label errors errors__leftmargin" : "hidden"}>{errors["abn"]}</span>

                <label className="input-group" htmlFor="termsId">
                    <span className="label">Terms</span>
                    <select className="select" id="termsId" name="termsId" value={supplierDetails.termsId} onChange={(e) => handleChange(e)} disabled={supplierDetails.id === 0 ? true : false}>
                        {props.termOptions.map(term =>
                            <option key={term.id} value={term.id}>{term.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["termsId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["termsId"]}</span>

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className="input" type="text" maxLength={30} id="phone" name="phone" value={supplierDetails.phone} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className="input" type="text" maxLength={200} id="email" name="email" value={supplierDetails.email} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["email"] ? "label errors errors__leftmargin" : "hidden"}>{errors["email"]}</span>

                <label className="input-group" htmlFor="status">
                    <span className="label">Status</span>
                    <select className="select" id="status" name="status" value={supplierDetails.status} onChange={(e) => handleChange(e)} disabled={supplierDetails.id === 0 ? true : false}>
                        {props.statusOptions.map(status =>
                            <option key={status.id} value={status.id}>{status.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["status"] ? "label errors errors__leftmargin" : "hidden"}>{errors["status"]}</span>

                <label className="input-group" htmlFor="lockAccount">
                    <span className="label">Lock Account</span>
                    <input className="checkbox" type="checkbox" id="lockAccount" name="lockAccount" checked={supplierDetails.lockAccount} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="primaryTradeId">
                    <span className="label">Primary Trade</span>
                    <select className="select" id="primaryTradeId" name="primaryTradeId" value={supplierDetails.primaryTradeId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {props.trades.map(trade =>
                            <option key={trade.id} value={trade.id}>{trade.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["primaryTradeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["primaryTradeId"]}</span>

                <label className="input-group" htmlFor="supplierId">
                    <span className="label">Other Trades</span>
                    <select className="select" id="supplierId" name="supplierId" value={props.id} onChange={(e) => handleTradeChange(e)} >
                        <option defaultValue="-1"></option>
                        {availTrades.map(trade =>
                            <option key={trade.id} value={trade.id}>{trade.name}</option>
                        )}
                    </select>
                </label>
                <span className={errors["supplierId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierId"]}</span>

                <label className="input-group" htmlFor="trades">
                    <span className="label"></span>
                    <div className="">
                        {supplierDetails.supplierTrades.map(trade =>
                            <div className="list-close--nowrap" key={trade.id} >
                                <span className="input">{trade.name}</span>

                                <a className="makeitred" href="#" onClick={(e) => removeItem(e, trade.id)}>
                                    <span className="fas fa-times-circle alignDeleteIcon"></span>
                                </a>
                                <br />
                            </div>
                        )}
                    </div>
                </label>

                <h4>Contact Details</h4>
                <label className="input-group" htmlFor="firstName">
                    <span className="label">First Name</span>
                    <input className='input' type='text' maxLength={100} id="firstName" name="firstName" value={contact.firstName} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["firstName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["firstName"]}</span>

                <label className="input-group" htmlFor="lastName">
                    <span className="label">Last Name</span>
                    <input className='input' type='text' maxLength={100} id="lastName" name="lastName" value={contact.lastName} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["lastName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["lastName"]}</span>

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className='input' type='text' maxLength={100} id="phone" name="phone" value={contact.phone} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <label className="input-group" htmlFor="mobile">
                    <span className="label">Mobile</span>
                    <input className='input' type='text' maxLength={100} id="mobile" name="mobile" value={contact.mobile} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["contactphone"] ? "label errors errors__leftmargin" : "hidden"}>{errors["contactphone"]}</span>

                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className='input' type='text' maxLength={200} id="email" name="email" value={contact.email} onChange={(e) => handleContactChange(e)}></input>
                </label>
                <span className={errors["contactemail"] ? "label errors errors__leftmargin" : "hidden"}>{errors["contactemail"]}</span>

                <button className="defaultbutton-prev defaultbutton-label marginBottom10" type="submit">Save</button>
            </div>
        </form>
    );

}

export default SupplierAddDetails;
import * as React from 'react';
import GoogleAutoComplete from './GoogleAutoComplete';
import * as DateHelpers from './DateHelpers';
import { toast } from 'react-toastify';

interface NewJobDetailsProps {
    currentStep: number;
    jobDetails: NewJobDetails;
    customerDetails: NewJobCustomerDetails;
    jobTypes: GenericListItem[];
    jobSubTypes: GenericListItem[];
    copyJob: boolean;
    prev(): void;
    next(): void;
    importPdf(file: File): void;
    importAdditionalFiles(files: File[]): void;
    updateJob(jobDetails: NewJobDetails): void;
    updateCustomer(customerDetails: NewJobCustomerDetails): void;
}

interface NewJobDetails {
    jobTypeId: number;
    jobSubTypeId: number;
    siteAddress: string;
    siteAddressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    receivedDate: string;
    receivedTime: string;
    siteVisitDate: string;
    siteVisitTime: string;
    insuredContactDate: string;
    insuredContactTime: string;
    jobAcknowledgementDateTime: string;
    makeSafe: boolean;
    reportOnly: boolean;
    copyEstimate: boolean;
    templateJob: boolean;
    status: string;
}

interface NewJobCustomerDetails {
    companyName: string;
    customerRegisteredForGst: boolean;
    customerName: string;
    propertyManager: string;
    address: string;
    addressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    customerGooglePlaceId: string;
    customerAddressLatitude: number;
    customerAddressLongitude: number;
    phone: string;
    email: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const NewJobDetail = (props: NewJobDetailsProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [editTemplateJob, setEditTemplateJob] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const enableTemplate = (e: any) => {
        e.preventDefault();
        setEditTemplateJob(true);
    }

    const importpdf = (e: any) => {
        props.importPdf(e.target.files[0]);
    }

    const importAdditionalFiles = (e: any) => {
        props.importAdditionalFiles(e.target.files);
    }

    const handleJobChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let jobDetails: any = {};
        jobDetails = props.jobDetails;
        jobDetails[e.target.name] = e.target.value;

        setSaveDisabled(false);
        props.updateJob(jobDetails);
    }

    const handleCheckboxChange = (e: any) => {
        let jobDetails: any = {};
        jobDetails = props.jobDetails;
        jobDetails[e.target.name] = e.target.checked;

        props.updateJob(jobDetails);
    }

    const handleCustomerCheckboxChange = (e: any) => {
        let customerDetails: any = {};
        customerDetails = props.customerDetails;
        customerDetails[e.target.name] = e.target.checked;

        props.updateCustomer(customerDetails);
    }

    const handleCustomerChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let customerDetails: any = {};
        customerDetails = props.customerDetails;
        customerDetails[e.target.name] = e.target.value;

        setSaveDisabled(false);
        props.updateCustomer(customerDetails);
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["siteAddress"];
        setErrors(error);

        var jobDetails = props.jobDetails;

        //check if there is a unit number
        var streetNo = "";
        if (addressDetails.subpremise) {
            streetNo += addressDetails.subpremise + "/";
        }
        streetNo += addressDetails.street_number;

        jobDetails.siteAddress = addressDetails.fullAddress;
        jobDetails.streetNumber = streetNo;
        jobDetails.streetName = addressDetails.route;
        jobDetails.suburb = addressDetails.locality;
        jobDetails.state = addressDetails.administrative_area_level_1;
        jobDetails.country = addressDetails.country;
        jobDetails.postCode = addressDetails.postal_code;
        jobDetails.googlePlaceId = addressDetails.placeId;
        jobDetails.siteAddressLatitude = addressDetails.latitude;
        jobDetails.siteAddressLongitude = addressDetails.longitude;
       
        setSaveDisabled(false);
        props.updateJob(jobDetails);
    }

    //update google customer address
    const setCustomerAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["address"];
        setErrors(error);

        var custDetails = props.customerDetails;
        //check if there is a unit number
        var streetNo = "";
        if (addressDetails.subpremise) {
            streetNo += addressDetails.subpremise + "/";
        }
        streetNo += addressDetails.street_number;

        custDetails.address = addressDetails.fullAddress;
        custDetails.streetNumber = streetNo;
        custDetails.streetName = addressDetails.route;
        custDetails.suburb = addressDetails.locality;
        custDetails.state = addressDetails.administrative_area_level_1;
        custDetails.country = addressDetails.country;
        custDetails.postCode = addressDetails.postal_code;
        custDetails.customerGooglePlaceId = addressDetails.placeId;
        custDetails.customerAddressLatitude = addressDetails.latitude;
        custDetails.customerAddressLongitude = addressDetails.longitude;

        setSaveDisabled(false);
        props.updateCustomer(custDetails);
    }

    const validate = () => {
        let jobfields = props.jobDetails;
        let custfields = props.customerDetails;
        let error: any = {};
        let formIsValid = true;

        if (jobfields.jobTypeId < 0) {
            formIsValid = false;
            error["jobTypeId"] = "Job Type is required";
        }

        if (jobfields.jobSubTypeId < 0) {
            formIsValid = false;
            error["jobSubTypeId"] = "Job Sub Type is required";
        }

        if (!jobfields.siteAddress) {
            formIsValid = false;
            error["siteAddress"] = "Site Address is required";
        }

        if (!jobfields.receivedDate) {
            formIsValid = false;
            error["receivedDate"] = "Received Date is required";
        } else if (!DateHelpers.isValidDate(jobfields.receivedDate)) {
            formIsValid = false;
            error["receivedDate"] = "Valid Received Date is required";
        }

        if (jobfields.siteVisitDate && !DateHelpers.isValidDate(jobfields.siteVisitDate)) {
            formIsValid = false;
            error["siteVisitDate"] = "Valid Site Visit Date is required";
        }

        if (jobfields.insuredContactDate && !DateHelpers.isValidDate(jobfields.insuredContactDate)) {
            formIsValid = false;
            error["insuredContactDate"] = "Valid Insured Contact Date is required";
        }

        if (!custfields.companyName) {
            //only mandatory if there is no company name
            if (!custfields.customerName) {
                formIsValid = false;
                error["customerName"] = "Customer Name is required";
            }
        }

        if (!custfields.address) {
            formIsValid = false;
            error["address"] = "Customer Address is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const next = () => {
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            props.next();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    if (props.currentStep !== 1) {
        return null;
    }

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Site Address"} placeholder={props.jobDetails.siteAddress} className="input-group" disabled={false} save={setAddress} />
    var googleCustomer = <GoogleAutoComplete addressId="autocompletecustomer" addressLabel={"Address"} placeholder={props.customerDetails.address} className="input-group" disabled={false} save={setCustomerAddress} />

    return (
        <form>
            <h4 className="jobdetails__heading">Job Details </h4>

            <label className={props.copyJob ? "hidden" : "input-group"} htmlFor="file">
                <span className="label">Import from PDF</span>
                <input type="file" className="marginBottom10" id="file" name="file" accept="application/pdf" onChange={importpdf} />
            </label>
            <label className={props.copyJob ? "hidden" : "input-group"} htmlFor="additionalFiles">
                <span className="label">Import Additional Files</span>
                <input type="file" className="marginBottom10" id="additionalFiles" name="additionalFiles" accept="application/pdf" onChange={importAdditionalFiles} multiple />
            </label>

            <label className="input-group" htmlFor="jobTypeId">
                <span className="label">Job Type </span>
                <select className="select" id="jobTypeId" name="jobTypeId" value={props.jobDetails.jobTypeId} onChange={(e) => handleJobChange(e)}>
                    <option hidden defaultValue="-1"></option>
                    {props.jobTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["jobTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["jobTypeId"]}</span>

            <label className="input-group" htmlFor="jobSubTypeId">
                <span className="label">Job Sub Type </span>
                <select className="select" id="jobSubTypeId" name="jobSubTypeId" value={props.jobDetails.jobSubTypeId} onChange={(e) => handleJobChange(e)}>
                    <option hidden defaultValue="-1"></option>
                    {props.jobSubTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["jobSubTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["jobSubTypeId"]}</span>

            <label className="input-group" htmlFor="makeSafe">
                <span className="label">MakeSafe Required?</span>
                <input className="checkbox" type="checkbox" id="makeSafe" name="makeSafe" checked={props.jobDetails.makeSafe} onChange={(e) => handleCheckboxChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="reportOnly">
                <span className="label">Report Only Job</span>
                <input className="checkbox" type="checkbox" id="reportOnly" name="reportOnly" checked={props.jobDetails.reportOnly} onChange={(e) => handleCheckboxChange(e)}></input>
            </label>

            {googleContents}
            <span className={errors["siteAddress"] ? "label errors errors__leftmargin" : "hidden"}>{errors["siteAddress"]}</span>

            <label className="input-group" htmlFor="siteAddressUnitNumber">
                <span className="label">Unit Number</span>
                <input className='input' type='text' maxLength={100} id="siteAddressUnitNumber" name="siteAddressUnitNumber" value={props.jobDetails.siteAddressUnitNumber} onChange={(e) => handleJobChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="receivedDate">
                <span className="label">Received Date</span>
                <input type='date' id="receivedDate" name="receivedDate" className='input' value={props.jobDetails.receivedDate} onChange={(e) => handleJobChange(e)}></input>
            </label>
            <span className={errors["receivedDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["receivedDate"]}</span>

            <label className="input-group" htmlFor="receivedTime">
                <span className="label">Received Time</span>
                <input type='time' id="receivedTime" name="receivedTime" className='input' value={props.jobDetails.receivedTime} onChange={(e) => handleJobChange(e)} ></input>
            </label>

            <label className="input-group" htmlFor="siteVisitDate">
                <span className="label">Site Visit Date</span>
                <input type='date' id="siteVisitDate" name="siteVisitDate" className='input' value={props.jobDetails.siteVisitDate} onChange={(e) => handleJobChange(e)}></input>
            </label>
            <span className={errors["siteVisitDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["siteVisitDate"]}</span>

            <label className="input-group" htmlFor="siteVisitTime">
                <span className="label">Site Visit Time</span>
                <input type='time' id="siteVisitTime" name="siteVisitTime" className='input' value={props.jobDetails.siteVisitTime} onChange={(e) => handleJobChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="insuredContactDate">
                <span className="label">Insured Contact Date</span>
                <input type='date' id="insuredContactDate" name="insuredContactDate" className='input' value={props.jobDetails.insuredContactDate} onChange={(e) => handleJobChange(e)}></input>
            </label>
            <span className={errors["insuredContactDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["insuredContactDate"]}</span>

            <label className="input-group" htmlFor="insuredContactTime">
                <span className="label">Insured Contact Time</span>
                <input type='time' id="insuredContactTime" name="insuredContactTime" className='input' value={props.jobDetails.insuredContactTime} onChange={(e) => handleJobChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="jobAcknowledgementDateTime">
                <span className="label">Job Acknowledgement</span>
                <input className="input" type='datetime-local' id="jobAcknowledgementDateTime" name="jobAcknowledgementDateTime" value={props.jobDetails.jobAcknowledgementDateTime} onChange={(e) => handleJobChange(e)}></input>
            </label>

            <label className={props.copyJob ? "input-group" : "hidden"} htmlFor="copyEstimate">
                <span className="label">Copy Estimate?</span>
                <input className="checkbox" type="checkbox" id="copyEstimate" name="copyEstimate" checked={props.jobDetails.copyEstimate} onChange={(e) => handleCheckboxChange(e)}></input>
            </label>

            <h4>Template Details</h4>
            <label className="input-group" htmlFor="templateJob">
                <span className="label">Template Job?</span>
                <input className="checkbox job__editMargin" type="checkbox" id="templateJob" name="templateJob" checked={props.jobDetails.templateJob} onChange={(e) => handleCheckboxChange(e)} disabled={!editTemplateJob}></input>
                <a className={editTemplateJob ? "hidden" : ""} href="#" onClick={(e) => enableTemplate(e)}>
                    <div className="tooltipitem tooltipitem__left"><span className="fas fa-edit edit--icon alignIconCenter"></span>
                        <span className="tooltipitemtext tooltipitemtext__left">Click this to update the Template Job checkbox</span>
                    </div>
                </a>
            </label>

            <h4>Customer Details</h4>
            <label className="input-group" htmlFor="companyName">
                <span className="label">Company Name</span>
                <input className='input jobdets__company--width marginRight10' type='text' maxLength={100} id="companyName" name="companyName" value={props.customerDetails.companyName} onChange={(e) => handleCustomerChange(e)}></input>
                <span className="label-small">Reg GST?</span>
                <input className="checkbox" type="checkbox" id="customerRegisteredForGst" name="customerRegisteredForGst" checked={props.customerDetails.customerRegisteredForGst} onChange={(e) => handleCustomerCheckboxChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="propertyManager">
                <span className="label">Property Manager</span>
                <input className='input' type='text' maxLength={200} id="propertyManager" name="propertyManager" value={props.customerDetails.propertyManager} onChange={(e) => handleCustomerChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="customerName">
                <span className="label">Customer Name</span>
                <input className='input' type='text' maxLength={100} id="customerName" name="customerName" value={props.customerDetails.customerName} onChange={(e) => handleCustomerChange(e)}></input>
            </label>
            <span className={errors["customerName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["customerName"]}</span>
            
            {googleCustomer}
            <span className={errors["address"] ? "label errors errors__leftmargin" : "hidden"}>{errors["address"]}</span>

            <label className="input-group" htmlFor="addressUnitNumber">
                <span className="label">Unit Number</span>
                <input className='input' type='text' maxLength={100} id="addressUnitNumber" name="addressUnitNumber" value={props.customerDetails.addressUnitNumber} onChange={(e) => handleCustomerChange(e)}></input>
            </label>

            <label className="input-group" htmlFor="phone">
                <span className="label">Phone</span>
                <input className='input' type='text' maxLength={100} id="phone" name="phone" value={props.customerDetails.phone} onChange={(e) => handleCustomerChange(e)}></input>
            </label>
            <span className={errors["phone"] ? "label errors errors__leftmargin" : "hidden"}>{errors["phone"]}</span>

            <label className="input-group" htmlFor="email">
                <span className="label">Email</span>
                <input className='input' type='text' maxLength={200} id="email" name="email" value={props.customerDetails.email} onChange={(e) => handleCustomerChange(e)}></input>
            </label>
            <span className={errors["email"] ? "label errors errors__leftmargin" : "hidden"}>{errors["email"]}</span>

            <button className="defaultbutton-prev defaultbutton-label" onClick={next} type="button" disabled={ saveDisabled }>Next</button>
        </form>
    );
}

export default NewJobDetail;

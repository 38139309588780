import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import VariationCashSettlementPopup from './JobVariationCashSettlementPopup';
import authService from './api-authorization/AuthorizeService';
import PhotoImportSinglePopup from './PhotoImportSingle';

interface VariationDetailsProps {
    canEdit: boolean;
    jobId: number;
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    approved: boolean;
    costCodeList: GenericListItem[],
    uomList: GenericListItem[],
    pcpsList: GenericListItem[],
    changePage(pageData: PageChangeData): void;
}

interface VariationDetails {
    itemIndex: number;
    id: number;
    headerId: number;
    scope: string;
    scopeOrder: number;
    costCodeId: number;
    costCode: string;
    quantity: number;
    unitOfMeasureId: number;
    unitOfMeasure: string;
    pcPsId: number;
    pcPs: string;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    hasPhoto: boolean;
    isDirty: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface PageChangeData {
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    detailId: number;
    detailName: string;
    costCodeId: number;
    approved: boolean;
    page: string;
}

interface AppFile {
    id: number;
    fileType: string;
    content: any;
}

const VariationDetailsData = (props: VariationDetailsProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [variationDetails, setVariationDetails] = React.useState<VariationDetails[]>([]);
    const [totalFormatted, setTotalFormatted] = React.useState("$0.00");
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [openSaveDisabled, setOpenSaveDisabled] = React.useState(false);
    const [variationIndex, setVariationIndex] = React.useState(-1);
    const [showCashSettlement, setShowCashSettlement] = React.useState(false);
    const [showUploadPhotoModal, setShowUploadPhotoModal] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(new File([""], "filename"));
    const [uploadedPhoto, setUploadedPhoto] = React.useState<AppFile>({ id: 0, fileType: "", content: [] });
    const [variationDetailId, setVariationDetailId] = React.useState(0);

    React.useEffect(() => {
        getVariationDetails();
    }, [props.headerId]);

    const getVariationDetails = async () => {
        const token = await authService.getAccessToken();
        axios.get('Variation/GetVariationDetails?HeaderId=' + props.headerId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var varDetails = res.data.variationDetails;
            //update item index
            for (var i = 0; i < varDetails.length; i++) {
                varDetails[i].itemIndex = i;
            }

            //add blank row
            if (!props.approved && props.canEdit) {
                var blankRow = addBlankRow(varDetails.length);
                varDetails.push(blankRow);
            }
            setVariationDetails(varDetails);
            setTotalFormatted(res.data.totalFormatted);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var blankRow: VariationDetails = {
            itemIndex: nextItemIndex,
            id: 0,
            headerId: props.headerId,
            scope: "",
            scopeOrder: 0,
            costCodeId: -1,
            costCode: "",
            quantity: 0,
            unitOfMeasureId: -1,
            unitOfMeasure: "",
            pcPsId: -1,
            pcPs: "",
            unitCost: 0,
            unitCostFormatted: "",
            total: 0,
            totalFormatted: "",
            hasPhoto: false,
            isDirty: false
        }
        return blankRow;
    }

    //BACK Button
    const back = () => {
        //if isDirty - ask if want to save changes
        var changed = variationDetails.filter(data => data.isDirty);
        if (changed.length > 0) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);
            saveVariation(-1, true);
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            variationId: props.variationId,
            variationName: props.variationName,
            headerId: props.headerId,
            headerName: props.headerName,
            detailId: 0,
            detailName: "",
            costCodeId: -1,
            approved: props.approved,
            page: "header"
        };

        //validate and save before opening
        props.changePage(pageChange);
    }
    //End BACK Button

    //change events
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var varDetails = [...variationDetails];
        var detail = varDetails[index];
        let exist: any = {};
        exist = detail;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (e.target.name === "pcPsId" || e.target.name === "costCodeId" || e.target.name === "unitOfMeasureId") {
            var itemName = e.target.name.replace("Id", "");
            exist[itemName] = e.target.options[e.target.selectedIndex].text;
        }

        if (index === (variationDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(variationDetails.length);
            varDetails.push(newRow);
        }

        setVariationDetails(varDetails);
        setSaveDisabled(false);
        setOpenSaveDisabled(false);
        setErrors({});
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var varDetails = [...variationDetails];
        var detail = varDetails[index];

        let value = e.target.value * 1;

        detail.isDirty = true;

        //calculate unit cost / total
        if (e.target.name === "total") {
            let total = value;
            detail.total = total;
            detail.totalFormatted = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });

            //set quantity to 1 if its 0 and total changed
            let qty = detail.quantity <= 0 ? 1 : detail.quantity;
            detail.quantity = qty;

            let unitCost = value / qty;
            detail.unitCost = Number(unitCost.toFixed(2));
            detail.unitCostFormatted = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        } else if (e.target.name === "unitCost") {
            let unitCost = value;
            detail.unitCost = unitCost;
            detail.unitCostFormatted = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });

            //set quantity to 1 if its 0 and total changed
            let qty = detail.quantity <= 0 ? 1 : detail.quantity;
            detail.quantity = qty;

            let total = unitCost * qty;
            detail.total = Number(total.toFixed(2));
            detail.totalFormatted = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            });
        } else {
            //quantity
            detail.quantity = value;
            let total = value * detail.unitCost;
            detail.total = Number(total.toFixed(2));
            detail.totalFormatted = total.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD',
            }); 
        }

        if (index === (varDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(varDetails.length);
            varDetails.push(newRow);
        }

        var total = (calculateTotal(varDetails)).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        setVariationDetails(varDetails);
        setTotalFormatted(total);
        setSaveDisabled(false);
        setOpenSaveDisabled(false);
        setErrors({});
    }

    const calculateTotal = (variationDetails: VariationDetails[]) => {
        var total = 0;
        for (var i = 0; i < variationDetails.length - 1; i++) {
            total += parseFloat((variationDetails[i].quantity * Number(variationDetails[i].unitCost)).toFixed(2));
        }
        return total;
    }

    const handleScopeKeyUp = (index: number) => {
        getShortcut(index);
    }

    const getShortcut = async (index: number) => {
        //check if chars < 15, if so check if a shortcut key has been entered and update scope
        var varDetails = [...variationDetails];
        var detail = varDetails[index];

        if (detail.scope && detail.scope.length < 15) {
            //check if shortcut
            const token = await authService.getAccessToken();
            axios.get('Estimate/GetShortcutScope?Scope=' + detail.scope, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                detail.scope = res.data.scope;   //returns entered scope if not a shortcut
                detail.costCodeId = res.data.costCodeId >= 0 ? res.data.costCodeId : detail.costCodeId;
                detail.costCode = res.data.costCodeId >= 0 ? res.data.costCode : detail.costCode;
                setVariationDetails(varDetails);
            })
            .catch(error => {
                toast.error(error.message);
            });
        }
    }
    //end change events

    //add cash settlement items
    const openCashSettlement = (e: any) => {
        e.preventDefault();

        setShowCashSettlement(true);
    }

    const hideCashSettlement = () => {
        setShowCashSettlement(false);
    }

    const saveCashSettlement = () => {
        setShowCashSettlement(false);
        toast.success("Cash Settlement items added successfully");
        getVariationDetails();
    }

    //end cash settlement

    //open split page
    const openDetails = (itemIndex: number, e: any) => {
        e.preventDefault();

        if (openSaveDisabled) {
            return;
        }
        setOpenSaveDisabled(true);
        
        var changed = variationDetails.filter(data => data.isDirty);
        if (changed.length > 0) {
            saveVariation(itemIndex, false);
        } else {
            var varDetail = variationDetails[itemIndex];
            openDetailSplitPage(varDetail.id, varDetail.scope, varDetail.costCodeId);
        }
    }

    const openDetailSplitPage = (detailId: number, scope: string, costCodeId: number) => {
        var pageChange: PageChangeData = {
            variationId: props.variationId,
            variationName: props.variationName,
            headerId: props.headerId,
            headerName: props.headerName,
            detailId: detailId,
            detailName: scope,
            costCodeId: costCodeId,
            approved: props.approved,
            page: "split"
        };

        props.changePage(pageChange);
    }
    //end open split page

    //upload photo 
    const uploadPhoto = (detailId: number, e: any) => {
        uploadPhotoItem(detailId, e);
    }

    const uploadPhotoItem = async (detailId: number, e: any) => {
        e.preventDefault();
        const token = await authService.getAccessToken();

        //check if there is already a photo and show it
        axios.get('Variation/GetVariationDetailPhoto?DetailId=' + detailId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                setShowUploadPhotoModal(true);
                setModalSaveDisabled(false);
                setVariationDetailId(detailId);
                setSelectedFile(new File([""], "filename"));
                setUploadedPhoto(res.data);
            })
            .catch(error => {
                toast.error(error.message);
            });

    }

    const uploadPhotoHide = () => {
        setShowUploadPhotoModal(false);
        setModalSaveDisabled(false);
        setVariationDetailId(0);
        setSelectedFile(new File([""], "filename"));
    }

    const uploadPhotoUpdate = (file: File) => {
        setSelectedFile(file);
    }

    const uploadPhotoDelete = () => {
        deleteUploadedPhoto();
    }

    const deleteUploadedPhoto = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var photoDelete = {
            jobId: props.jobId,
            detailId: variationDetailId,
            subId: user.sub
        };

        axios.post('Variation/DeleteVariationDetailPhoto', photoDelete, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var detaillines = [...variationDetails];
                var detailline = detaillines.filter(x => x.id === variationDetailId);
                if (detailline.length > 0) {
                    detailline[0].hasPhoto = false;
                }
                setVariationDetails(detaillines);
                setShowUploadPhotoModal(false);
                setModalSaveDisabled(false);
                setVariationDetailId(0)
                setSelectedFile(new File([""], "filename"));

                toast.success("Photo has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const uploadPhotoSave = () => {
        setModalSaveDisabled(false);
        saveUploadedPhoto();
    }

    const saveUploadedPhoto = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        const data = new FormData();
        data.append('jobId', props.jobId.toString());
        data.append('detailId', variationDetailId.toString());
        data.append('photo', selectedFile);
        data.append('subId', user.sub);

        axios.post('Variation/SaveVariationDetailPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var detaillines = [...variationDetails];
                var detailline = detaillines.filter(x => x.id === variationDetailId);
                if (detailline.length > 0) {
                    detailline[0].hasPhoto = true;
                }
                setVariationDetails(detaillines);
                setShowUploadPhotoModal(false);
                setModalSaveDisabled(false);
                setVariationDetailId(0)
                setSelectedFile(new File([""], "filename"));

                toast.success("Photo has been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end upload photo
    
    //delete row
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();

        setVariationIndex(index);

        //save the rest of the estimate
        var changed = variationDetails.filter(data => data.isDirty);
        if (changed.length > 0) {
            //remove the row for deletion and then validate the rest
            //as shouldn't have to fix a row that you want to delete
            var varDetails = [...variationDetails];

            //remove from list
            varDetails.splice(index, 1);

            //just validate changed items for delete
            var changedDetails = varDetails.filter(data => data.isDirty);

            //need to save estimate details before delete
            if (changedDetails.length > 0) {
                if (validate(changedDetails, false)) {
                    saveVariationDetails(-1, false, true, changedDetails);
                } else {
                    toast.error("You need to fix errors on screen to save latest changes before deleting");
                }
            } else {
                setVariationDetails(varDetails);
            }
        } else {
            //no unsaved items so can just delete
            readyToDelete();
        }
    }

    const readyToDelete = () => {
        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setSaveDisabled(false);
    }


    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setVariationIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        var varDetails = [...variationDetails];
        var variationDetail = variationDetails[variationIndex];

        if (variationDetail.id === 0) {
            deleteVariationItemUpdateOrder(variationDetails);
        } else {
            deleteVariationItem(varDetails, variationDetail.id);
        }
    }
    //end delete row

    const deleteVariationItemUpdateOrder = async (varDetails: VariationDetails[]) => {
        const token = await authService.getAccessToken();

        //remove from list
        var removedItem = varDetails.splice(variationIndex, 1);

        //loop through the details and update the order
        var hasBlankRow = !props.approved && props.canEdit;
        var length = hasBlankRow ? varDetails.length - 1 : varDetails.length;
        for (var i = 0; i < length; i++) {
            varDetails[i].itemIndex = (i);
            varDetails[i].scopeOrder = (i + 1);
        }

        //recalculate totals
        var total = (calculateTotal(varDetails)).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        //save new details order
        axios.post('Variation/DeleteVariationDetailUpdateOrder', varDetails, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                varDetails.splice(variationIndex, 0, removedItem[0]);
                setVariationDetails(varDetails);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            } else {
                setVariationDetails(varDetails);
                setTotalFormatted(total);
                setShowConfirmModal(false);
                setVariationIndex(-1);

                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteVariationItem = async (varDetails: VariationDetails[], variationDetailId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //remove from list
        var removedItem = varDetails.splice(variationIndex, 1);

        //loop through the details and update the order
        var hasBlankRow = !props.approved && props.canEdit;
        var length = hasBlankRow ? varDetails.length - 1 : varDetails.length;
        for (var i = 0; i < length; i++) {
            varDetails[i].itemIndex = (i);
            varDetails[i].scopeOrder = (i + 1);
        }

        var deleteDetail = {
            variationId: props.variationId,
            id: variationDetailId,
            jobId: props.jobId,
            variationDetailsOrder: varDetails,
            subId: user.sub
        };

        axios.post('Variation/DeleteVariationDetail', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                varDetails.splice(variationIndex, 0, removedItem[0]);
                setVariationDetails(varDetails);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            } else {
                toast.success("Item has been deleted");

                var total = (calculateTotal(varDetails)).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                setVariationDetails(varDetails);
                setTotalFormatted(total);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const validate = (items: VariationDetails[], hasBlankRow: boolean) => {
        let errorList: any = {};
        let formIsValid = true;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length < 2) {
            errorString = "Please enter at least one Item!";
            error = true;
        } else {
            var itemsLength = hasBlankRow ? items.length - 1 : items.length;
            for (var i = 0; i < itemsLength; i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].scope) {
                    newErrorString += " Scope, ";
                    error = true;
                }

                if (items[i].costCodeId < 0) {
                    newErrorString += " Cost Code, ";
                    error = true;
                }

                var quantity = items[i].quantity * 1;
                if (quantity === 0) {
                    newErrorString += " Quantity, ";
                    error = true;
                }

                if (newErrorString !== "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["details"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        saveVariation(-1, false);
    }

    const saveVariation = (openIndex: number, goBack: boolean) => {
        if (validate(variationDetails, true)) {
            var details = [...variationDetails];

            //Only save items that have changed
            var changed = details.filter(data => data.isDirty);
            if (changed.length === 0) {
                toast.info("No items have been changed!");
            } else {
                saveVariationDetails(openIndex, goBack, false, changed);
            }
        } else {
            toast.error("Please check and resolve the errors at the bottom of the table");
        }
    }

    const saveVariationDetails = async (openIndex: number, goBackLevel: boolean, goDelete: boolean, changedDetails: VariationDetails[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var varDetails = [...variationDetails];

        var varDetailsSave = {
            variationId: props.variationId,    //used for updating the totals
            headerId: props.headerId,
            variationDetails: changedDetails,
            subId: user.sub
        };

        axios.post('Variation/SaveVariationDetails', varDetailsSave, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Variation Saved");
                if (openIndex >= 0) {
                    //open the split page
                    changedDetails = res.data.variationDetails;
                    var findRow = changedDetails.filter(data => data.itemIndex === openIndex);
                    var id = varDetails[openIndex].id;
                    var scope = varDetails[openIndex].scope;
                    var costCodeId = varDetails[openIndex].costCodeId;
                    if (findRow.length > 0) {
                        id = findRow[0].id;
                        scope = findRow[0].scope;
                        costCodeId = findRow[0].costCodeId;
                    }
                    openDetailSplitPage(id, scope, costCodeId);
                } else if (goBackLevel) {
                    goBack();
                } else if (goDelete) {
                    changedDetails = res.data.variationDetails;
                    //loop through the changed items and update the main list
                    for (var i = 0; i < changedDetails.length; i++) {
                        varDetails[changedDetails[i].itemIndex] = changedDetails[i];
                    }

                    setVariationDetails(varDetails);
                    setErrors({});

                    if (goDelete) {
                        readyToDelete();
                    }   
                } else {
                    getVariationDetails();
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }


    
    //    changedDetails = res.data.estimateDetails;
    //    loop through the changed items and update the main list
    //    for (var i = 0; i < changedDetails.length; i++) {
    //        details[changedDetails[i].itemIndex] = changedDetails[i];
    //    }

    //    setEstimateDetails(details);
    //    setErrors({});

    //    if (goDelete) {
    //        readyToDelete();
    //    }
    //}



    //MOVE ROW
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var varDetails = [...variationDetails];
        var hasBlankRow = !props.approved && props.canEdit;

        var varDetailsLength = varDetails.length - 1;

        if ((hasBlankRow && oldIndex != varDetailsLength && newIndex != varDetailsLength) || !hasBlankRow) {
            //only move if not blank row
            varDetails.splice(newIndex, 0, varDetails.splice(oldIndex, 1)[0]);

            //loop through the headers and update the order
            var length = hasBlankRow ? varDetails.length - 1 : varDetails.length;
            for (var i = 0; i < length; i++) {
                varDetails[i].itemIndex = (i);
                varDetails[i].scopeOrder = (i + 1);
                varDetails[i].isDirty = true;
            }

            setVariationDetails(varDetails);
            saveVariation(-1, false);
        }
    };
    //END MOVE ROW

    let confirmPopup = <ConfirmModal heading="Delete Variation Row" text="Are you sure you want to delete this variation detail row and all sub rows?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    let cashSettle = <VariationCashSettlementPopup jobId={props.jobId} variationId={props.variationId} variationHeaderId={props.headerId} showModal={showCashSettlement} hideModal={hideCashSettlement} saveModal={saveCashSettlement} />
    let photoUpload = <PhotoImportSinglePopup heading="Upload Photo" text="Search and select a photo to upload." fileType="Photo" selectedFile={selectedFile} uploadedPhoto={uploadedPhoto} showModal={showUploadPhotoModal} hideModal={uploadPhotoHide} update={uploadPhotoUpdate} delete={uploadPhotoDelete} save={uploadPhotoSave} saveDisabled={modalSaveDisabled} />
    let lastRow = props.approved || !props.canEdit ? variationDetails.length : variationDetails.length - 1;

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {confirmBack}
                {cashSettle}
                {photoUpload}
            </div>
            <div className="estimate__backButton">
                <h3>{props.headerName}</h3>
                <div>
                    <button type="button" className="defaultbutton marginRight10" onClick={openCashSettlement}>Cash Settlement</button>
                    <button type="button" className="defaultbutton" onClick={back}>Back</button>
                </div>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__large tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th className="table--smallHeaderSize"></th>
                            <th>Scope</th>
                            <th className="table--smallHeaderSize"></th>
                            <th className="table--smallHeaderSize">Cost Code</th>
                            <th className="table--smallHeaderSize">PC/PS</th>
                            <th className="table--smallHeaderSize">Qty</th>
                            <th className="table--smallHeaderSize">UOM</th>
                            <th className="textalignright table--smallHeaderSize">Unit Cost</th>
                            <th className="textalignright table--smallHeaderSize">Total</th>
                            <th className="table--smallHeaderSize">Photo</th>
                            <th className={props.approved || !props.canEdit ? "hidden" : "table--smallHeaderSize"}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {variationDetails.map((detail, itemIndex) =>
                            <tr key={itemIndex} draggable="true" onDragStart={(e) => handleDragStart(e, itemIndex)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, itemIndex)}>
                                <td className="hidden">{detail.id}</td>
                                <td className="table__text--align">{detail.scopeOrder == 0 ? itemIndex + 1 : detail.scopeOrder}</td>
                                <td className="table__text--align">
                                    <textarea rows={2} className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin-xlg"} name="scope" value={detail.scope} onChange={(e) => handleCellChange(itemIndex, e)} onBlur={(e) => handleScopeKeyUp(itemIndex)} />
                                    {props.approved || !props.canEdit ? detail.scope : ""}
                                </td>
                                <td className="table__text--align">
                                    <a className={itemIndex === lastRow ? "hidden" : ""} href="#" onClick={(e) => openDetails(detail.itemIndex, e)}>
                                        Open
                                    </a>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="costCodeId" value={detail.costCodeId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={props.approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.costCodeList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="pcPsId" value={detail.pcPsId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={props.approved || !props.canEdit ? true : false}>
                                        <option defaultValue="-1"></option>
                                        {props.pcpsList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <input type='number' min="0" step="any" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin--sm textalignright"} name="quantity" value={detail.quantity} onChange={(e) => handleCellAmountChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? detail.quantity : ""}
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="unitOfMeasureId" value={detail.unitOfMeasureId} onChange={(e) => handleCellChange(itemIndex, e)} disabled={props.approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.uomList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <input type='number' step="any" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin--md textalignright"} name="unitCost" value={detail.unitCost} onChange={(e) => handleCellAmountChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? detail.unitCostFormatted : ""}
                                </td>
                                <td className="table__text--align">
                                    <input type='number' step="any" className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin--md textalignright"} name="total" value={detail.total} onChange={(e) => handleCellAmountChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? detail.totalFormatted : ""}
                                </td>
                                <td className="table__text--align">
                                    <div className={itemIndex === lastRow || detail.id === 0 || props.approved || !props.canEdit ? "hidden" : "delete--tablecell"}>
                                        <a className={detail.hasPhoto ? "file__photoColour" : ""} href="#" onClick={(e) => uploadPhoto(detail.id, e)}>
                                            <span className="fas fa-file-image edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                                <td className={props.approved || !props.canEdit ? "hidden" : "table__text--align"}>
                                    <div className={itemIndex === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(itemIndex, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["details"] ?
                    (errors["details"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label textalignright">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={totalFormatted} disabled></input>
                </label>
            </div>

            <button className="defaultbutton hideMobile" type="submit" disabled={props.approved || !props.canEdit || saveDisabled}>Save</button>
        </form>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )

}

export default VariationDetailsData;
import * as React from 'react';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ReportOpenPurchaseOrdersData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunOpenPurchaseOrdersReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                setLoading(false);
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data 
        var url = "/report/OpenPurchaseOrders/-1/-1/-1";
        window.open(url, '_blank');
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Open Purchase Orders</h1>
            </div>
            <div className="marginTop20 marginBottom10">
                <span>Displays all Open Purchase Orders raised since 01/03/2022.</span>
            </div>
            <div>
                <button className="defaultbutton-prev" type='button' onClick={runReport}>Run Report</button>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportOpenPurchaseOrdersData;
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import JobsConfirmStatusChange from './JobsConfirmStatusChange';
import Pager from './Paging';
import * as DateHelpers from './DateHelpers';

interface GenericListItem {
    id: number;
    name: string;
}

interface JobResults {
    jobId: number;
    siteAddress: string;
    siteAddressGoogle: string;
    makeSafe: boolean;
    reportOnly: boolean;
    customer: string;
    insurer: string;
    insurerContact: string;
    claimNumber: string;
    adjuster: string;
    adjusterContact: string;
    adjusterReferenceNumber: string;
    broker: string;
    brokerContact: string;
    estimator: string;
    estimatorFullName: string;
    supervisor: string;
    supervisorFullName: string;
    status: string;
    statusId: number;
    quotedAmount: number;
    quotedAmountFormatted: string;
}

const JobData = () => {
    const [loading, setLoading] = React.useState(true);
    const [estimators, setEstimators] = React.useState<GenericListItem[]>([]);
    const [supervisors, setSupervisors] = React.useState<GenericListItem[]>([]);
    const [managers, setManagers] = React.useState<GenericListItem[]>([]);
    const [insurers, setInsurers] = React.useState<GenericListItem[]>([]);
    const [adjusters, setAdjusters] = React.useState<GenericListItem[]>([]);
    const [brokers, setBrokers] = React.useState<GenericListItem[]>([]);
    const [jobStatus, setJobStatus] = React.useState<GenericListItem[]>([]);
    const [showResults, setShowResults] = React.useState(false);
    const [resultsHasFilters, setResultsHasFilters] = React.useState(false);
    const [results, setResults] = React.useState<JobResults[]>([]);
    const [sortOrder, setSortOrder] = React.useState("asc");
    const [sortColumn, setSortColumn] = React.useState("jobId");
    const [canUpdateJobStatus, setCanUpdateJobStatus] = React.useState(false);
    const [canCreateMakeSafeJob, setCanCreateMakeSafeJob] = React.useState(false);
    const [showSearchFields, setShowSearchFields] = React.useState(false);
    const [searchFields, setSearchFields] = React.useState({ jobId: "", siteAddress: "", estimatorId: -1, supervisorId: -1, managerId: -1, quoteNumber: "", customerName: "", claimNumber: "", claimStatusId: -1, insurerId: -1, adjusterId: -1, brokerId: -1, jobStatusId: -1, purchaseOrderNumber: "" });
    const [showConfirmStatusChange, setShowConfirmStatusChange] = React.useState(false);
    const [statusChange, setStatusChange] = React.useState({ statusChangeIndex: -1, statusJobId: 0, statusJobStatusId: -1, statusTo: "" });
    const [jobResults, setJobResults] = React.useState({ totalRecords: 0, pageSize: 50 });
    const [page, setPage] = React.useState(1);
    const [useDefaultJobs, setUseDefaultJobs] = React.useState(true);   //gets default jobs when paging vs searched jobs
    const [envSetting, setEnvSetting] = React.useState("");

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetSearchDropdowns?Page=1&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setInsurers(res.data.insurers);
            setAdjusters(res.data.adjusters);
            setBrokers(res.data.brokers);
            setEstimators(res.data.estimators);
            setSupervisors(res.data.supervisors);
            setManagers(res.data.managers);
            setJobStatus(res.data.jobStatus);
            setJobResults({ totalRecords: res.data.defaultJobs.totalRecords, pageSize: res.data.defaultJobs.pageSize });
            setPage(1);
            setResults(res.data.defaultJobs.jobs);
            setUseDefaultJobs(true);
            setShowResults(res.data.defaultJobs.jobs.length > 0);
            setCanUpdateJobStatus(res.data.canUpdateJobStatus);
            setCanCreateMakeSafeJob(res.data.canCreateMakeSafeJob);
            setEnvSetting(res.data.envSetting);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any) => {
        setSearchFields(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    //show / hide search
    const showSearch = (e: any) => {
        e.preventDefault();
        var showFields = !showSearchFields;
        setShowSearchFields(showFields);
    }

    const search = (e: any) => {
        e.preventDefault();

        var isValid = true;

        if (searchFields.purchaseOrderNumber) {
            if (!DateHelpers.isNumber(searchFields.purchaseOrderNumber)) {
                toast.error("Purchase Order Nubmer must be a number!");
                isValid = false;
            }
        }

        //If Claim Status Id is set to BAU then we need another search criteria as most jobs are BAU
        let claimStatus = -1;
        if (searchFields.claimStatusId > 1) {
            claimStatus = searchFields.claimStatusId * 1;
        }

        if (!searchFields.jobId && !searchFields.siteAddress && searchFields.estimatorId < 0 && searchFields.supervisorId < 0 && searchFields.managerId < 0 && !searchFields.quoteNumber && !searchFields.customerName && !searchFields.claimNumber && claimStatus < 0 && searchFields.insurerId < 0 && searchFields.adjusterId < 0 && searchFields.brokerId < 0 && searchFields.jobStatusId < 0 && !searchFields.purchaseOrderNumber) {
            if (searchFields.claimStatusId * 1 === 1) {
                toast.error("Please enter another search criteria when searching for BAU jobs!");
            } else {
                toast.error("Please enter at least one search criteria!");
            }
            isValid = false;
        }

        if (isValid) {
            searchJobs(1);
        }
    }

    const searchJobs = async (pageNumber: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let statusId = searchFields.jobStatusId < 1 ? "" : searchFields.jobStatusId;
        axios.get('Jobs/Search?jobId=' + searchFields.jobId + "&page=" + pageNumber + "&totalRecords=" + jobResults.totalRecords + "&siteAddress=" + searchFields.siteAddress + "&subId=" + user.sub + "&estimatorId=" + searchFields.estimatorId + "&supervisorId=" + searchFields.supervisorId + "&managerId=" + searchFields.managerId + "&quoteNumber=" + searchFields.quoteNumber + "&customerName=" + searchFields.customerName + "&claimNumber=" + searchFields.claimNumber + "&claimStatusId=" + searchFields.claimStatusId + "&insurerId=" + searchFields.insurerId + "&adjusterId=" + searchFields.adjusterId + "&brokerId=" + searchFields.brokerId + "&jobStatusId=" + statusId + "&purchaseOrderNumber=" + searchFields.purchaseOrderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setJobResults({ totalRecords: res.data.totalRecords, pageSize: res.data.pageSize });
            setPage(pageNumber);
            setResults(res.data.jobs);
            setUseDefaultJobs(false);
            setShowResults(true);
            setShowSearchFields(false);
            setResultsHasFilters(true);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getDefaultJobs = async (pageNumber: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetDefaultJobs?Page=' + pageNumber + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobResults({ totalRecords: res.data.totalRecords, pageSize: res.data.pageSize });
                setPage(pageNumber);
                setResults(res.data.jobs);
                setUseDefaultJobs(true);
                setShowResults(res.data.jobs.length > 0);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const reset = (e: any) => {
        e.preventDefault();
        resetSearch();
    }

    const resetSearch = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetDefaultJobs?Page=1&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setSearchFields({
                    jobId: "",
                    siteAddress: "",
                    estimatorId: -1,
                    supervisorId: -1,
                    managerId: -1,
                    quoteNumber: "",
                    customerName: "",
                    claimNumber: "",
                    claimStatusId: -1,
                    insurerId: -1,
                    adjusterId: -1,
                    brokerId: -1,
                    jobStatusId: -1,
                    purchaseOrderNumber: ""
                });
                setJobResults({ totalRecords: res.data.totalRecords, pageSize: res.data.pageSize });
                setPage(1);
                setResults(res.data.jobs);
                setUseDefaultJobs(true);
                setShowResults(res.data.jobs.length > 0);
                setShowSearchFields(false);
                setResultsHasFilters(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const sortTable = (columnName: string) => {
        var newSortOrder = sortOrder;
        if (sortColumn === columnName) {
            //swap sort order
            newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        } else {
            //changed columns so need to put it back to asc
            newSortOrder = "asc";
        }

        results.sort(sortFunction)
        function sortFunction(a: any, b: any) {
            if (newSortOrder === "asc") {
                return (a[columnName] === b[columnName]) ? 0 : (a[columnName] < b[columnName]) ? -1 : 1
            } else {
                return (a[columnName] === b[columnName]) ? 0 : (a[columnName] < b[columnName]) ? 1 : -1
            }
        }
        setResults(results);
        setSortOrder(newSortOrder);
        setSortColumn(columnName);
    }

    //STATUS CHANGE
    const changeStatus = (index: number, e: any) => {
        e.preventDefault();
        var job = results[index];

        let statusTo = job.status === "In Progress" ? "On Hold" : "In Progress";

        setStatusChange({ statusChangeIndex: index, statusJobId: job.jobId, statusJobStatusId: job.statusId, statusTo: statusTo });
        setShowConfirmStatusChange(true);
    }

    const hideConfirmStatusModal = () => {
        setStatusChange({ statusChangeIndex: -1, statusJobId: 0, statusJobStatusId: -1, statusTo: "" });
        setShowConfirmStatusChange(false);
    }

    const saveConfirmStatusModal = () => {
        var jobs = results;
        var job = jobs[statusChange.statusChangeIndex];

        job.statusId = job.statusId === 5 ? 10 : 5;
        job.status = job.status === "In Progress" ? "On Hold" : "In Progress";
        setResults(jobs);

        setStatusChange({ statusChangeIndex: -1, statusJobId: 0, statusJobStatusId: -1, statusTo: "" });
        setShowConfirmStatusChange(false);    
    }
    //END STATUS CHANGE

    //JOB STATUS DROPDOWN CHANGE
    const handleCellChange = async(index: number, e: any) => {
        //update item that has changed
        var jobs = [...results];
        var job = jobs[index];

        var newStatusId = e.target.value * 1;

        let exist: any = {};
        exist = job;
        exist[e.target.name] = newStatusId;

        let todaysDate = DateHelpers.getCurrentDate();
        const user = await authService.getUser();

        var jobStatus = {
            jobId: job.jobId,
            statusId: newStatusId,
            statusDate: todaysDate,
            subId: user.sub
        };

        //save job
        const token = await authService.getAccessToken();
        axios.post('Jobs/UpdateJobStatus', jobStatus, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
            } else {
                toast.success("Job Status Saved");
                setResults(jobs);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const pageChanged = (pageNumber: number) => {
        window.scrollTo(0, 0);
        setPage(pageNumber);
        if (useDefaultJobs) {
            getDefaultJobs(pageNumber);
        } else {
            searchJobs(pageNumber);
        }
    }

    //END JOB STATUS DROPDOWN CHANGE
    const show = showSearchFields ? "show" : "";
    const confirmStatusChange = <JobsConfirmStatusChange showModal={showConfirmStatusChange} heading={"Change Job Status to " + statusChange.statusTo} jobId={statusChange.statusJobId} jobStatusId={statusChange.statusJobStatusId} hideModal={hideConfirmStatusModal} cancelModal={hideConfirmStatusModal} saveModal={saveConfirmStatusModal} />

    var pagerContents;
    if ((jobResults.totalRecords / jobResults.pageSize) > 1 && showResults) {
        //only render if we have records
        pagerContents = <Pager page={page} pageSize={jobResults.pageSize} totalRecords={jobResults.totalRecords} pageChanged={pageChanged} />
    }

    const renderDetails = (
        <form onSubmit={search}> 
            <div className="static-modal">
                {confirmStatusChange}
            </div>
            <div className="showMobile">
                <div className="defaultbutton__container--right makeitflexcenter marginTop10">
                    <a className={showSearchFields || !canCreateMakeSafeJob ? "hidden" : "mobile-icon-green"} href="/createmsjob">
                        <span className="fas fa-plus mobileMenuSize alignIconCenter"></span>
                        <span>MakeSafe Job</span>
                    </a>
                    <a className={showSearchFields ? "hidden" : "mobile-icon-green"} href="#" onClick={(e) => showSearch(e)}>
                        <span className="fas fa-search mobileMenuSize alignIconCenter"></span>
                        <span>Search</span>
                    </a>
                    <a className={resultsHasFilters ? "mobile-icon-grey" : "hidden"} href="#" onClick={(e) => reset(e)}>
                        <span className="fas fa-redo mobileMenuSize alignIconCenter"></span>
                        <span>Reset</span>
                    </a>
                    <a className={showSearchFields ? "mobile-icon-grey marginBottom10" : "hidden"} href="#" onClick={(e) => showSearch(e)}>
                        <span className="fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
            <div className={"showMobileToggle " + show}>
                <div className="input-group-parent3">
                    <label className="input-group" htmlFor="jobId">
                        <span className="label-small">Job Id</span>
                        <input type='text' id="jobId" name="jobId" className='input' value={searchFields.jobId} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <label className="input-group" htmlFor="siteAddress">
                        <span className="label-small">Site Address</span>
                        <input type='text' id="siteAddress" name="siteAddress" className='input' value={searchFields.siteAddress} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <label className="input-group" htmlFor="jobStatusId">
                        <span className="label-small">Job Status</span>
                        <select className="select" id="jobStatusId" name="jobStatusId" value={searchFields.jobStatusId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {jobStatus.map(status =>
                                <option key={status.id} value={status.id}>{status.name}</option>
                            )};
                            </select>
                    </label>
                    <label className="input-group" htmlFor="customerName">
                        <span className="label-small">Customer Name</span>
                        <input className='input' type='text' id="customerName" name="customerName" value={searchFields.customerName} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <label className="input-group" htmlFor="quoteNumber">
                        <span className="label-small">Quote #</span>
                        <input className='input' type='text' id="quoteNumber" name="quoteNumber" value={searchFields.quoteNumber} onChange={(e) => handleChange(e)}></input>
                    </label>
                </div>
                <div className="input-group-parent3">
                    <label className="input-group" htmlFor="claimNumber">
                        <span className="label-small">Claim #</span>
                        <input className='input' type='text' id="claimNumber" name="claimNumber" value={searchFields.claimNumber} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <label className="input-group" htmlFor="claimStatusId">
                        <span className="label-small">Claim Status</span>
                        <select className="select" id="claimStatusId" name="claimStatusId" value={searchFields.claimStatusId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            <option key={1} value={1}>BAU</option>
                            <option key={2} value={2}>CAT</option>
                        </select>
                    </label>
                    <label className="input-group" htmlFor="insurerId">
                        <span className="label-small">Insurer</span>
                        <select className="select" id="insurerId" name="insurerId" value={searchFields.insurerId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {insurers.map(ins =>
                                <option key={ins.id} value={ins.id}>{ins.name}</option>
                            )};
                            </select>
                    </label>
                    <label className="input-group" htmlFor="adjusterId">
                        <span className="label-small">Adjuster</span>
                        <select className="select" id="adjusterId" name="adjusterId" value={searchFields.adjusterId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {adjusters.map(adj =>
                                <option key={adj.id} value={adj.id}>{adj.name}</option>
                            )};
                            </select>
                    </label>
                    <label className="input-group" htmlFor="brokerId">
                        <span className="label-small">Broker</span>
                        <select className="select" id="brokerId" name="brokerId" value={searchFields.brokerId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {brokers.map(bkr =>
                                <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                            )};
                            </select>
                    </label>
                </div>
                <div className="input-group-parent3">
                    <label className="input-group" htmlFor="estimatorId">
                        <span className="label-small">Estimator</span>
                        <select className="select" id="estimatorId" name="estimatorId" value={searchFields.estimatorId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {estimators.map(est =>
                                <option key={est.id} value={est.id}>{est.name}</option>
                            )};
                        </select>
                    </label>
                    <label className="input-group" htmlFor="supervisorId">
                        <span className="label-small">Supervisor</span>
                        <select className="select" id="supervisorId" name="supervisorId" value={searchFields.supervisorId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {supervisors.map(sup =>
                                <option key={sup.id} value={sup.id}>{sup.name}</option>
                            )};
                        </select>
                    </label>
                    <label className="input-group" htmlFor="managerId">
                        <span className="label-small">Manager</span>
                        <select className="select" id="managerId" name="managerId" value={searchFields.managerId} onChange={(e) => handleChange(e)}>
                            <option defaultValue="-1" value="-1"></option>
                            {managers.map(mgr =>
                                <option key={mgr.id} value={mgr.id}>{mgr.name}</option>
                            )};
                        </select>
                    </label>
                    <label className="input-group" htmlFor="purchaseOrderNumber">
                        <span className="label-small">PO #</span>
                        <input className='input' type='text' id="purchaseOrderNumber" name="purchaseOrderNumber" value={searchFields.purchaseOrderNumber} onChange={(e) => handleChange(e)}></input>
                    </label>
                </div>
                <div className="hideMobile">
                    <button className="defaultbutton-prev defaultbutton-prev__split3" type='submit'>Search</button>
                    <button className="defaultbutton-next" type='button' onClick={reset}>Reset</button>
                </div>
                <div className="showMobile">
                    <button className="mobile-button mobile-button_green" type="button" onClick={search}>Search</button>
                    <button className="mobile-button mobile-button_grey" type="button" onClick={reset}>Reset</button>
                </div>
                <div className="marginTop10">
                    Note: When searching for Cancelled Jobs make sure you select "Cancelled" as the Job Status, otherwise they will not appear in the search results.
                </div>
            </div>
            <div className={showSearchFields ? "hidden" : ""}>
                <hr></hr>
                <div><span className="version">Version: 2.5.4 - Env: {envSetting}</span></div>
                <div className={showResults ? 'showMobile' : 'hidden'}>
                    {results.map((job, index) =>
                        <div key={job.jobId}>
                            <div className="makeitflexspacebetween makeitflexcenter">
                                <div>
                                    <NavLink className={job.makeSafe ? "makeSafeJob" : job.reportOnly ? "reportOnlyJob" : ""} to={'/job/' + job.jobId + "/detailsm"}>{job.siteAddress ? job.siteAddress.length > 50 ? job.siteAddress.substring(0, 40) + "... " : job.siteAddress : ""}</NavLink>
                                    <a className="marginLeft10" href={job.siteAddressGoogle} target="_blank" >
                                        <span className="fas fa-map-marker-alt edit--icon"></span>
                                    </a>
                                </div>
                                <div>
                                    {job.status}
                                </div>
                            </div>
                            <div className="makeitflexspacebetween makeitflexcenter">
                                <div>
                                    {job.customer}
                                </div>
                                <div>
                                    {job.quotedAmountFormatted}
                                </div>
                            </div>
                            <div className={job.insurer ? "makeitflexspacebetween makeitflexcenter" : "hidden"}>
                                <div>
                                    {job.insurer ? job.insurer.length > 50 ? job.insurer.substring(0, 40) + "... " : job.insurer : ""}
                                </div>
                                <div>
                                    {job.claimNumber}
                                </div>
                            </div>
                            <hr className="hr--no-margin"></hr>
                        </div>
                    )}
                </div>

                <div className={showResults ? 'overflowAuto hideMobile' : 'hidden'}>
                    <table className="table--main tableColours">
                        <thead>
                            <tr className="">
                                <th onClick={() => sortTable("jobId")}>
                                    <div className="sortParent">
                                        Job Id
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "jobId" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "jobId" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th>Site Address</th>
                                <th>MakeSafe</th>
                                <th onClick={() => sortTable("customer")}>
                                    <div className="sortParent">
                                        Customer
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "customer" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "customer" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("insurer")}>
                                    <div className="sortParent">
                                        Insurer
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "insurer" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "insurer" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("claimNumber")}>
                                    <div className="sortParent">
                                        Claim #
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "claimNumber" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "claimNumber" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("adjuster")}>
                                    <div className="sortParent">
                                        Adjuster
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "adjuster" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "adjuster" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("adjusterReferenceNumber")}>
                                    <div className="sortParent">
                                        Adj Ref #
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "adjusterReferenceNumber" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "adjusterReferenceNumber" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("broker")}>
                                    <div className="sortParent">
                                        Broker
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "broker" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "broker" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("estimatorFullName")}>
                                    <div className="sortParent">
                                        Estimator
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "estimatorFullName" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "estimatorFullName" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("supervisorFullName")}>
                                    <div className="sortParent">
                                        Supervisor
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "supervisorFullName" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "supervisorFullName" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th onClick={() => sortTable("status")}>
                                    <div className="sortParent">
                                        Status
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "status" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "status" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th></th>
                                <th onClick={() => sortTable("quotedAmount")}>
                                    <div className="sortParent">
                                        Quoted
                                        <span className={"fas fa-caret-up sortTop" + (sortColumn === "quotedAmount" && sortOrder === "asc" ? " makeitblack" : "")}></span>
                                        <span className={"fas fa-caret-down sortBottom" + (sortColumn === "quotedAmount" && sortOrder === "desc" ? " makeitblack" : "")}></span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((job, index) =>
                                <tr key={job.jobId}>
                                    <td className="table__text--align"><NavLink to={'/job/' + job.jobId + "/details"}>{job.jobId}</NavLink></td>
                                    <td className="table__text--align">
                                        <div className={"tooltipitem tooltipitem__right" + (job.makeSafe ? " makeSafeJob" : job.reportOnly ? " reportOnlyJob" : "")}>{job.siteAddress ? job.siteAddress.substring(0, 20) + "... " : ""}
                                            <span className="tooltipitemtext tooltipitemtext__right">{job.siteAddress}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align"><input type="checkbox" className="input checkbox" name="makeSafe" checked={job.makeSafe} disabled></input></td>
                                    <td className="table__text--align">{job.customer}</td>
                                    <td className="table__text--align">
                                        <div className="tooltipitem tooltipitem__right">{job.insurer ? job.insurer.substring(0, 15) + "... " : ""}
                                            <span className="tooltipitemtext tooltipitemtext__right">{job.insurer}<br></br>{job.insurerContact}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align">{job.claimNumber}</td>
                                    <td className="table__text--align">
                                        <div className="tooltipitem tooltipitem__right">{job.adjuster ? job.adjuster.substring(0, 15) + "... " : ""}
                                            <span className="tooltipitemtext tooltipitemtext__right">{job.adjuster}<br></br>{job.adjusterContact}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align">{job.adjusterReferenceNumber}</td>
                                    <td className="table__text--align">
                                        <div className="tooltipitem tooltipitem__right">{job.broker ? job.broker.substring(0, 15) + "... " : ""}
                                            <span className="tooltipitemtext tooltipitemtext__right">{job.broker}<br></br>{job.brokerContact}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align">
                                        <div className="tooltipitem tooltipitem__right">{job.estimator}
                                            <span className="tooltipitemtext tooltipitemtext__right">{job.estimatorFullName}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align">
                                        <div className="tooltipitem tooltipitem__right">{job.supervisor}
                                            <span className="tooltipitemtext tooltipitemtext__right">{job.supervisorFullName}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align">
                                        {canUpdateJobStatus ? "" : job.status}
                                        <select className={canUpdateJobStatus ? "select table__select--size" : "hidden"} name="statusId" value={job.statusId} onChange={(e) => handleCellChange(index, e)}>
                                            {jobStatus.map(item =>
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )};
                                        </select>
                                    </td>
                                    <td className="table__text--align">
                                        <a className={job.status === "In Progress" || job.status === "On Hold" ? "" : "hidden"} href="#" onClick={(e) => changeStatus(index, e)}>
                                            {job.status === "In Progress" ? "Hold Job" : job.status === "On Hold" ? "Start Job" : ""}
                                        </a>
                                    </td>
                                    <td className="table__text--align textalignright">{job.quotedAmountFormatted}</td>
                                    <td className="table__text--align"><NavLink to={'/newjob/' + job.jobId}>Copy Job</NavLink></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {pagerContents}
            </div>
        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default JobData;

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const ReportJobValueByClaimTypeData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [filter, setFilter] = React.useState({ startDate: "", endDate: "" });
    const [errors, setErrors] = React.useState("");

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunJobValueByClaimTypeReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                if (res.data === true) {
                    setLoading(false);
                } else if (res.data === false) {
                    //redirect to 403 permission denied
                    navigate("/accessdenied");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    const handleChange = (e: any) => {
        setFilter(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        setErrors("");
    }

    const validate = () => {
        let errors = "";
        let formIsValid = true;

        if (!filter.startDate) {
            formIsValid = false;
            errors = "Start Date is required";
        }

        if (!filter.endDate) {
            formIsValid = false;
            if (errors) {
                errors += ", End Date is required";
            } else {
                errors = "End Date is required";
            }
        }

        if (filter.startDate && filter.endDate && filter.endDate < filter.startDate) {
            formIsValid = false;
            if (errors) {
                errors += ", Start Date must be < End Date";
            } else {
                errors = "Start Date must be < End Date";
            }
        }

        setErrors(errors);
        return formIsValid;
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data

        if (validate()) {
            var url = "/report/JobValueByClaimType/" + filter.startDate + "/" + filter.endDate + "/-1";
            window.open(url, '_blank');
        } else {
            toast.error("Please fix the validation issues before running report");
        }
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        if (validate()) {
            exportToExcel();
        } else {
            toast.error("Please fix the validation issues before running report");
        }
    }

    const exportToExcel = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios('Report/ExportJobValueByClaimType?startDate=' + filter.startDate + "&endDate=" + filter.endDate + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Job Value by Claim Type.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Job Value By Claim Type Report</h1>
            </div>
            <div className="marginTop20">
                <span>Select a Start Date and End Date to run the report for value summary by claim type with jobs authorised or invoiced between the selected dates.</span>
            </div>
            <div>
                <label className="input-group" htmlFor="startDate">
                    <span className="label">Start Date</span>
                    <input type='date' id="startDate" name="startDate" className='input' value={filter.startDate} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="endDate">
                    <span className="label">End Date</span>
                    <input type='date' id="endDate" name="endDate" className='input' value={filter.endDate} onChange={(e) => handleChange(e)}></input>
                </label>

                <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
            <div>
                <span className="label errors report-error-align">{errors}</span>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportJobValueByClaimTypeData;
import * as React from 'react';

type ClientDetails = {
    id: number;
    xeroContactId: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    typeId: number;
    phone: string;
    termId: number;
    useOpus: boolean;
    maxMarginPercent: number;
    status: number;
    lockAccount: boolean;
}

type ClientContacts = {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

type Client = {
    clientDetails: ClientDetails;
    clientContacts: ClientContacts[];
    isLoading: boolean;
    error?: string;
}

const initialClient: Client = {
    clientDetails: {
        id: 0,
        xeroContactId: "",
        name: "",
        addressManual: false,
        address: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        registeredForGst: false,
        abn: "",
        typeId: -1,
        phone: "",
        termId: -1,
        useOpus: false,
        maxMarginPercent: 0,
        status: 1,
        lockAccount: false
    },
    clientContacts: [{
        id: 0,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    }],
    isLoading: false,
    error: ""
}

type Action =
    | { type: 'getDetails' }
    | { type: 'updateDetails', clientDetails: ClientDetails }
    | { type: 'updateContacts', clientContacts: ClientContacts[] }
    | { type: 'reset' }
    | { type: 'error', error: "" };

const clientReducer = (client: Client = initialClient, action: Action): Client => {
    switch (action.type) {
        case 'getDetails':
            return { ...client, isLoading: true };
        case 'updateDetails':
            return { ...client, isLoading: false, clientDetails: action.clientDetails };
        case 'updateContacts':
            return { ...client, isLoading: false, clientContacts: action.clientContacts };
        case 'reset':
            return initialClient;
        case 'error':
            return { ...client, isLoading: false, error: action.error };
        default:
            return client;
    }
}

export default clientReducer; 

import * as React from 'react';
import logo from '../images/powered_by_google_on_white.png';
import { useRef, useEffect } from "react";



interface GooglePlacesProps {
    addressId: string;
    addressLabel: string;
    placeholder: string;
    className: string;
    disabled: boolean;
    save(addressDetails: AddressDetails): void;
}

//interface for storing results of address from google
interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

interface componentForm {
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
};

const GoogleAutoComplete = (props: GooglePlacesProps) => {
    const [componentForm, setComponentForm] = React.useState<componentForm>({
        subpremise: 'short_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
    });
    const [addressDetails, setAddressDetails] = React.useState<AddressDetails>({
        fullAddress: "",
        subpremise: "",
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        latitude: 0,
        longitude: 0,
        placeId: ""
    });

    const options = {
        componentRestrictions: { country: "au" },
        fields: ['address_components', 'geometry', 'formatted_address', 'place_id', 'name'],
        types: ["geocode"]
    };
    

    let autoComplete: any;

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        /*const { Place } = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;*/
        if (window.google) {
            const input = document.getElementById(props.addressId) as HTMLInputElement;
            autoComplete = new window.google.maps.places.Autocomplete(input, options);
            autoComplete.addListener("place_changed", fillInAddress);
        }
    }

    const fillInAddress = () => {
        // Get the place details from the autocomplete object.
        var place = autoComplete.getPlace();
        let form: any = componentForm;
        let address: any = addressDetails;
        if (place && place.address_components) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                var placeAddrCom: any = place.address_components[i];

                if (form[addressType]) {
                    var val = placeAddrCom[form[addressType]];
                    address[addressType] = val;
                }
            }
            address["latitude"] = place.geometry?.location?.lat();
            address["longitude"] = place.geometry?.location?.lng();
            address["placeId"] = place.place_id;
            address["fullAddress"] = place.formatted_address;

            props.save(address);
        }
    }


    return (
        <div>
            <label className={props.className} htmlFor="address">
                <span className="label">{props.addressLabel}</span>
                <input className="input" id={props.addressId} placeholder={props.placeholder} type="text" disabled={props.disabled}></input>
            </label>
            <img className="marginLeftLabel" src={logo}></img>
        </div>
    );
};
export default GoogleAutoComplete;

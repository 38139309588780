import PhotoImportDetailPopup from './PhotoImportDetail';

interface JobAssessmentMobilePhotosProps {
    id: number;
    uploadedFiles: UploadedFile[];
    loadingPhotos: boolean;
    update(selectedFiles: UploadedFile[]): void;
    importPhotos(files: File[]): void;
    delete(deleteIndex: number): void;
    save(): void;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const JobAssessmentMobilePhotosData = (props: JobAssessmentMobilePhotosProps) => {
    window.scrollTo(0, 0);

    const save = (e: any) => {
        e.preventDefault();

        props.save();
    }

    var photoImportDetail = <PhotoImportDetailPopup title={"Upload Report Photos"} showLoading={props.loadingPhotos} uploadedFiles={props.uploadedFiles} importPhotos={props.importPhotos} update={props.update} delete={props.delete} />

    return (
        <div className="assessment__photo--width mobileFont marginBottom70">
            {photoImportDetail}

            <div className="jobpo__backButton">
                <a className="mobile-icon-green" href="#" onClick={(e) => save(e)}>
                    <span className="fas fa-check-circle mobileMenuSize alignIconCenter"></span>
                </a>
            </div>
        </div>
    )
}

export default JobAssessmentMobilePhotosData;
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobTabs from './JobTabs';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

const JobFilesData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [loading, setLoading] = React.useState(true);
    const [fileLocation, setFileLocation] = React.useState("");

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetFileData?JobId=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                setPermissions(res.data.userJobPermissions);
                setLoading(false);
                setFileLocation(res.data.fileLocation);
            }
        });
    }

    const handleChange = (e: any) => {
        setFileLocation(e.target.value);
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    const save = (e: any) => {
        e.preventDefault();

        saveFiles();
    }

    const saveFiles = async() => {
        let file = {
            jobId: jobData.jobId,
            fileLocation: fileLocation
        }

        const token = await authService.getAccessToken();
        axios.post('Jobs/SaveJobFile', file, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            toast.success("File Location Saved");
        })
        .catch(error => {
            var errors = error.response.data as any[];
            errors.map(function (error: any) {
                toast.error(error);
            });
        });
    }

    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="files" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />

    const renderDetails = (
        <form>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <a className="files__view-position fileSize" href={fileLocation} target="_blank">View Dropbox Files</a>
                <br></br>
                <br></br>
                <div>
                    <label className="input-group" htmlFor="fileLocation">
                        <span className="label">File Location</span>
                        <input className='input' type='text' maxLength={300} id="fileLocation" name="fileLocation" value={fileLocation} onChange={(e) => handleChange(e)} ></input>
                    </label>
                    <button className="defaultbutton-prev files__button-position" onClick={save} type="button">Save</button>
                </div>
                    
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobFilesData;
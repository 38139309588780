import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const ReportData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [permissions, setPermissions] = React.useState<string[]>([]);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunReports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Users/GetUserPermissions?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                }).then(res => {
                    setPermissions(res.data.permissions);
                    setLoading(false);
                })
                    .catch(error => {
                        toast.error(error.message);
                    });
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div className="adminlists__item">
                <NavLink className={permissions.indexOf("RunInsurerUpdateReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/insurerupdate">
                    Insurer Update Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobMarkupMarginHistoryReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobmarkupmarginhistory">
                    HISTORY Mark Up / Margin Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobMarkupMarginReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobmarkupmargin" >
                    Mark Up / Margin Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobTenderingReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobtender">
                    Tendering Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobRepairLifeReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobrepairlife">
                    Repair Life Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobQuoteLifeReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobquotelife" >
                    Quote Life Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobWipReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobwip" >
                    WIP Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobWipReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobworkinhand" >
                    Work In Hand Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunInvoicedJobReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobsinvoiced" >
                    Jobs Invoiced Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobsInvoicedProfitReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobsinvoicedprofit" >
                    Jobs Invoiced Profit Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunEstimatorReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobestimator" >
                    Estimator Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobsWonLostReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobswonlost">
                    Jobs Won/Lost Report
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobmissingdata" >
                    Jobs Missing Data Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobsRequiringInvoiceReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobsrequiringinvoice" >
                    Jobs Requiring an Invoice Report
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobsummary" >
                    Job Summary Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunEstimatedActualCompletionReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/estactualcompletiondate" >
                    Estimated vs Actual Completion Date Report
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/makesafejobs" >
                    Make Safe Jobs Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunOpenPurchaseOrdersReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/openpurchaseorders" >
                    Open Purchase Orders Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobValueByTypeReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobvaluebytype" >
                    Job Value By Type Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobValueByClaimTypeReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobvaluebyclaimtype" >
                    Job Value By Claim Type Report
                </NavLink>
                <NavLink className={permissions.indexOf("RunJobClaimStatusReport") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to="/reports/jobclaimstatus" >
                    Job Claim Status (BAU/CAT) Report
                </NavLink>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

};

export default ReportData;
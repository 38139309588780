import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';

interface ClientProps {
    clientContacts: ClientContacts[];
    update(clientContacts: ClientContacts[]): void;
    save(sendToXero: boolean): void;
}

interface ClientContacts {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

const ClientContact = (props: ClientProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [buttonDisabled, setButtonDisabled] = React.useState({ modalSaveDisabled: false, saveDisabled: false });
    const [confirmDelete, setconfirmDelete] = React.useState({ contactIndex: -1, showConfirmModal: false });

    //grid item changed
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var contacts = props.clientContacts;
        var contact = contacts[index];
        let existContact: any = {};
        existContact = contact;
        existContact[e.target.name] = e.target.value;

        if (index == (contacts.length - 1)) {
            //last row so need to add a new blank row
            var newContact: ClientContacts = {
                id: 0,
                firstName: "",
                lastName: "",
                phone: "",
                mobile: "",
                email: ""
            };
            contacts.push(newContact);
        }

        setButtonDisabled({ modalSaveDisabled: false, saveDisabled: false });
        props.update(contacts);
    }

    //DELETE CONTACT
    const deleteContact = (index: number, e: any) => {
        e.preventDefault();

        setButtonDisabled({ modalSaveDisabled: false, saveDisabled: false });
        setconfirmDelete({ showConfirmModal: true, contactIndex: index });
    }

    const hideConfirmModal = () => {
        setButtonDisabled({ modalSaveDisabled: false, saveDisabled: false });
        setconfirmDelete({ showConfirmModal: false, contactIndex: -1 });
    }

    const saveConfirmModal = () => {
        if (buttonDisabled.modalSaveDisabled) {
            return;
        }
        setButtonDisabled({ modalSaveDisabled: true, saveDisabled: false });

        var contacts = props.clientContacts;
        var contact = contacts[confirmDelete.contactIndex];

        if (contact.id == 0) {
            //just delete from the grid as doesn't exist in the database
            toast.success("Contact has been deleted");
            contacts.splice(confirmDelete.contactIndex, 1);
            props.update(contacts);
            setconfirmDelete({ showConfirmModal: false, contactIndex: -1 });
        } else {
            saveDeleteClientContact();
        }
    }

    const saveDeleteClientContact = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var contacts = props.clientContacts;
        var contact = contacts[confirmDelete.contactIndex];
        var deleteContact = {
            contactId: contact.id,
            subId: user.sub
        };

        axios.post('Clients/DeleteContact', deleteContact, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Contact has been deleted");
                contacts.splice(confirmDelete.contactIndex, 1);
                props.update(contacts);
                setconfirmDelete({ showConfirmModal: false, contactIndex: -1 });
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //END DELETE CONTACT

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = props.clientContacts;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        for (var i = 0; i < (items.length - 1); i++) {
            rowNumber += 1;
            var newErrorString = "";

            if (!items[i].firstName) {
                newErrorString += " First Name, ";
                error = true;
            }
            if (!items[i].lastName) {
                newErrorString += " Last Name, ";
                error = true;
            }
            if (!items[i].phone && !items[i].mobile) {
                newErrorString += " Phone or Mobile, ";
                error = true;
            }
            if (!items[i].email) {
                newErrorString += " Email, ";
                error = true;
            }

            if (newErrorString != "") {
                errorString += "\n"
                errorString += "Row " + rowNumber + ": " + newErrorString;
                errorString = errorString.substring(0, errorString.length - 2);
            }
        }

        if (error) {
            formIsValid = false;
            errorList["contacts"] = errorString;
        }

        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {

            if (buttonDisabled.saveDisabled) {
                return;
            }
            setButtonDisabled({ saveDisabled: true, modalSaveDisabled: false });

            props.save(false);
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    let lastRow = props.clientContacts.length - 1;
    let confirmPopup = <ConfirmModal heading="Delete Contact" text="Are you sure you want to delete this contact?" hideConfirmModal={hideConfirmModal} showConfirmModal={confirmDelete.showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={buttonDisabled.modalSaveDisabled} />

    return (
        <form name="client" onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div>
                <div className="table__margin overflowAuto">
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th></th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.clientContacts.map((contact, index) =>
                                <tr key={index}>
                                    <td className="hidden">{contact.id}</td>
                                    <td className="table__text--align">{index + 1}</td>
                                    <td>
                                        <input type="text" maxLength={100} className="form-control tablerow__textmedium" name="firstName" value={contact.firstName} onChange={(e) => handleCellChange(index, e)} />
                                    </td>
                                    <td>
                                        <input type="text" maxLength={100} className="form-control tablerow__textmedium" name="lastName" value={contact.lastName} onChange={(e) => handleCellChange(index, e)} />
                                    </td>
                                    <td>
                                        <input type="text" maxLength={30} className="form-control tablerow__textmedium" name="phone" value={contact.phone} onChange={(e) => handleCellChange(index, e)} />
                                    </td>
                                    <td>
                                        <input type="text" maxLength={30} className="form-control tablerow__textmedium" name="mobile" value={contact.mobile} onChange={(e) => handleCellChange(index, e)} />
                                    </td>
                                    <td>
                                        <input type="text" maxLength={200} className="form-control tablerow__textmedium" name="email" value={contact.email} onChange={(e) => handleCellChange(index, e)} />
                                    </td>
                                    <td className="table__text--align">
                                        {lastRow != index ?
                                            <div className="delete--tablecell">
                                                <a className="makeitred" href="#" onClick={(e) => deleteContact(index, e)}>
                                                    <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                                </a>
                                            </div> : ""
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {errors["contacts"] ?
                        (errors["contacts"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>

                <button className="defaultbutton" type="submit" disabled={ buttonDisabled.saveDisabled }>Save</button>
            </div>
        </form>
    );
}

export default ClientContact;
 import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface SupplierInvoiceXeroContactProps {
    invoiceId: number;
    showModal: boolean;
    hideModal(): void;
    saveModal(xeroContactId: string, xeroContactName: string): void;
    addNewContact(): void;
}

interface SearchResult {
    selected: boolean;
    id: string;
    name: string;
    totalOwedFormatted: string;
}

const SupplierInvoiceXeroContactPopup = (props: SupplierInvoiceXeroContactProps) => {
    const [supplierSearch, setSupplierSearch] = React.useState("");
    const [showResults, setShowResults] = React.useState(false);
    const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);

    const handleKeyUp = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            search();
        }
    }

    const handleCellCheckboxChange = (e: any, index: number) => {
        var results = [...searchResults];
        //set all results selected to false as can only select one at a time
        for (var i = 0; i < results.length; i++) {
            results[i].selected = false;
        }

        var result = results[index];

        let change: any = {};
        change = result;
        change[e.target.name] = e.target.checked;
        setSearchResults(results);
    }

    const search = () => {
        searchXeroSuppliers();
    }

    const searchXeroSuppliers = async () => {
        if (!supplierSearch) {
            toast.error("Enter supplier name to search");
        } else {
            const token = await authService.getAccessToken();
            const user = await authService.getUser();
            axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + supplierSearch, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var xeroContacts = res.data;
                for (var i = 0; i < xeroContacts.length; i++) {
                    xeroContacts[i].selected = false;
                }

                setShowResults(true);
                setSearchResults(xeroContacts);

                if (res.data.length === 0) {
                    toast.info("There were no Xero contacts that matched your search.  Note: Xero search is case sensitive!");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });          
        }
    }

    const addNew = () => {
        props.addNewContact();
    }

    const save = () => {
        saveSupplier();
    }

    const saveSupplier = async () => {
        var selectedContact = searchResults.filter(s => s.selected);
        if (selectedContact.length === 1) {
            var xeroContactId = selectedContact[0].id;
            var xeroContactName = selectedContact[0].name;

            const user = await authService.getUser();

            var xeroContact = {
                invoiceId: props.invoiceId,
                xeroContactId: xeroContactId,
                xeroContactName: xeroContactName,
                subId: user.sub
            };

            //save po details to invoice
            const token = await authService.getAccessToken();
            axios.post('Suppliers/InvoiceAssignXeroContact', xeroContact, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });

                } else {
                    setSupplierSearch("");
                    setShowResults(false);
                    setSearchResults([]);
                    props.saveModal(xeroContactId, xeroContactName);
                    toast.success("Xero Contact saved to Invoice");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        } else {
            toast.error("Select the Xero Contact associated with this invoice");
        }
    }

    const hideModal = () => {
        setSupplierSearch("");
        setShowResults(false);
        setSearchResults([]);
        props.hideModal();
    }

    return (
        <Modal size="lg" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Search for Xero Contact</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div className="makeitflex">
                    <label className="input-group" htmlFor="supplierSearch">
                        <span className="label-small">Supplier</span>
                        <input className="input" type="text" id="supplierSearch" name="supplierSearch" value={supplierSearch} onChange={(e) => setSupplierSearch(e.target.value)} onKeyUp={(e) => handleKeyUp(e)}></input>
                    </label>
                    <button className="defaultbutton marginLeft10 marginBottom10" type='button' onClick={search}>Search</button>
                    <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={addNew}>Add New</button>
                </div>
                    
                <hr></hr>
                <div className={showResults ? "" : "hidden"}>
                    <div className={showResults ? 'overflowAuto' : 'hidden'}>
                        <table className="table--main tableColours">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="hidden">Xero Contact Id</th>
                                    <th>Xero Contact Name</th>
                                    <th>Total Owed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map((result, index) =>
                                    <tr key={result.id}>
                                        <td>
                                            <input type="checkbox" className="input checkbox" name="selected" checked={result.selected} onChange={(e) => handleCellCheckboxChange(e, index)} />
                                        </td>
                                        <td>{result.name}</td>
                                        <td>{result.totalOwedFormatted}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={save}>Save</button>
            </Modal.Footer>
        </Modal>
    );
    
}

export default SupplierInvoiceXeroContactPopup;
import * as React from 'react';
import { toast } from 'react-toastify';

interface JobAssessmentBuildingProps {
    canEdit: boolean;
    saveDisabled: boolean;
    overviewPhoto: AppFile;
    geographicLocationPhoto: AppFile;
    buildingAssessment: BuildingAssessment;
    buildingTypes: GenericListItem[];
    roofTypes: GenericListItem[];
    constructionTypes: GenericListItem[];
    buildingFloors: GenericListItem[];
    yesNoUnsure: GenericListItem[];
    updateSaveDisabled(): void;
    update(buildingAssessment: BuildingAssessment): void;
    updateSave(): void; //save assessment on field exit
    importOverviewLocationPhoto(file: File, fileType: string): void;
    save(): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface BuildingAssessment {
    id: number;
    jobId: number;
    typeId: number;
    policyNumber: string;
    occupantName: string;
    occupantContactNumber: string;
    incidentConfirmed: number;
    clientInformedNextSteps: number;
    occupancyType: number;
    temporaryAccommodation: number;
    furnitureRemovalStorage: number;
    makeSafeRequired: number;
    emergencyServicesAttend: number;
    makeSafeDescription: string;
    workCompletedOthers: number;
    workCompletedOthersDescription: string;
    buildingType: number;
    outBuildings: string;
    buildingCondition: string;
    constructionType: number;
    roofType: number;
    buildingUse: number;
    buildingAge: string;
    propertyUninhabitable: number;
    uninhabitableDuringRepairs: number;
    uninhabitableDuringRepairsTime: string;
    damageDetails: string;
    insuredVersionOfEvents: string;
    causeOfDamage: string;
    causeOfDamageTime: string;
    roofReportRequested: number;
    roofGeneralCondition: string;
    roofStormDamage: string;
    guttersClean: string;
    ceilingInspection: number;
    guttersSufficient: string;
    additionalRoofFixtures: number;
    rainWaterBreach: string;
    maintenanceIssues: number;
    maintenanceIssuesDescription: string;
    maintenanceIssuesInsuredAware: number;
    maintenanceIssuesInsuredAwareTime: string;
    maintenanceIssuesAddressed: number;
    damageConsistentWithEvent: number;
    insuranceRelated: number;
    recommendAcceptance: number;
    additionalComments: string;
    numberOfStories: number;
    roofPitch: string;
    structureArea: string;
    observations: string;
    resolution: string;
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const JobAssessmentBuildingData = (props: JobAssessmentBuildingProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const handleImportOverviewFile = (e: any) => {
        props.importOverviewLocationPhoto(e.target.files[0], "4");
    }

    const handleImportLocationFile = (e: any) => {
        props.importOverviewLocationPhoto(e.target.files[0], "5");
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        var error = errors;
        if (e.target.value) {
            delete error[e.target.name];
            setErrors(error);
        }

        let ba = props.buildingAssessment;
        let change: any = ba;
        change[e.target.name] = e.target.value;

        props.update(ba);
    }

    const handleOnBlur = (e: any) => {
        props.updateSave();
    }

    const validate = () => {
        let fields = props.buildingAssessment;
        let error: any = {};
        let formIsValid = true;

        if (props.overviewPhoto.content.length == 0) {
            formIsValid = false;
            error["overviewPhoto"] = "Overview of Property Photo is required";
        }

        if (fields.buildingType < 0) {
            formIsValid = false;
            error["buildingType"] = "Building Type is required";
        }

        if (fields.roofType < 0) {
            formIsValid = false;
            error["roofType"] = "Roof Type is required";
        }

        if (fields.constructionType < 0) {
            formIsValid = false;
            error["constructionType"] = "Construction Type is required";
        }

        if (fields.numberOfStories < 0) {
            formIsValid = false;
            error["numberOfStories"] = "No. Stories / Floors is required";
        }

        if (!fields.roofPitch) {
            formIsValid = false;
            error["roofPitch"] = "Roof Pitch is required";
        }

        if (!fields.buildingAge) {
            formIsValid = false;
            error["buildingAge"] = "Building Age is required";
        }

        if (!fields.structureArea) {
            formIsValid = false;
            error["structureArea"] = "Structure Area is required";
        }

        if (!fields.maintenanceIssuesDescription) {
            formIsValid = false;
            error["maintenanceIssuesDescription"] = "Maintenance Issues is required";
        }

        if (fields.maintenanceIssuesInsuredAware < 0) {
            formIsValid = false;
            error["maintenanceIssuesInsuredAware"] = "Insured aware of Maintenance Issues is required";
        }

        if (!fields.causeOfDamage) {
            formIsValid = false;
            error["causeOfDamage"] = "Report/Circumstances is required";
        }

        if (!fields.observations) {
            formIsValid = false;
            error["observations"] = "Observations are required";
        }

        if (!fields.resolution) {
            formIsValid = false;
            error["resolution"] = "Resolution is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const next = () => {
        //saves assessment details and goes to upload photos
        if (validate()) {
            props.save();
        } else {
            toast.error("Check the assessment and fix all errors before going Next")
        }
    }

    return (
        <div>
            <label className="input-group" htmlFor="overviewPhoto">
                <span className="label-large">Overview of Property</span>
                <input type="file" className="marginBottom10" name="file" accept="image/*" onChange={handleImportOverviewFile} />
            </label>
            <span className={errors["overviewPhoto"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["overviewPhoto"]}</span>
            <div className={props.overviewPhoto.content.length == 0 ? "hidden" : ""}>
                <img className="assessment__photoSize" src={props.overviewPhoto.id > 0 ? "data:" + props.overviewPhoto.fileType + ";base64," + props.overviewPhoto.content : props.overviewPhoto.content}></img>
            </div>
            <span className={errors["overviewPhoto"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["overviewPhoto"]}</span>

            <label className="input-group" htmlFor="geographicLocationPhoto">
                <span className="label-large">Geographic Location</span>
                <input type="file" className="marginBottom10" name="file" accept="image/*" onChange={handleImportLocationFile} />
            </label>
            <span className={errors["geographicLocationPhoto"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["geographicLocationPhoto"]}</span>
            <div className={props.geographicLocationPhoto.content.length == 0 ? "hidden" : ""}>
                <img className="assessment__photoSize" src={props.geographicLocationPhoto.id > 0 ? "data:" + props.geographicLocationPhoto.fileType + ";base64," + props.geographicLocationPhoto.content : props.geographicLocationPhoto.content}></img>
            </div>

            <label className="input-group" htmlFor="buildingType">
                <span className="label-large">Building Type </span>
                <select className="select" id="buildingType" name="buildingType" value={props.buildingAssessment.buildingType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.buildingTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["buildingType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["buildingType"]}</span>

            <label className="input-group" htmlFor="roofType">
                <span className="label-large">Roof Type </span>
                <select className="select" id="roofType" name="roofType" value={props.buildingAssessment.roofType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.roofTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["roofType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["roofType"]}</span>

            <label className="input-group" htmlFor="constructionType">
                <span className="label-large">Construction Type</span>
                <select className="select" id="constructionType" name="constructionType" value={props.buildingAssessment.constructionType} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.constructionTypes.map(constructionType =>
                        <option key={constructionType.id} value={constructionType.id}>{constructionType.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["constructionType"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["constructionType"]}</span>

            <label className="input-group" htmlFor="numberOfStories">
                <span className="label-large">No. Stories / Floors </span>
                <select className="select" id="numberOfStories" name="numberOfStories" value={props.buildingAssessment.numberOfStories} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.buildingFloors.map(floor =>
                        <option key={floor.id} value={floor.id}>{floor.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["numberOfStories"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["numberOfStories"]}</span>

            <label className="input-group" htmlFor="roofPitch">
                <span className="label-large">Approx. Roof Pitch (Degrees)</span>
                <input className='input' type='text' maxLength={100} id="roofPitch" name="roofPitch" value={props.buildingAssessment.roofPitch} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>
            <span className={errors["roofPitch"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["roofPitch"]}</span>

            <label className="input-group" htmlFor="buildingAge">
                <span className="label-large">Approx. Building Age (Years)</span>
                <input className='input' type='text' id="buildingAge" maxLength={20} name="buildingAge" value={props.buildingAssessment.buildingAge} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>
            <span className={errors["buildingAge"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["buildingAge"]}</span>

            <label className="input-group" htmlFor="structureArea">
                <span className="label-large">Approx. Structure Area (m2)</span>
                <input className='input' type='text' maxLength={200} id="structureArea" name="structureArea" value={props.buildingAssessment.structureArea} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}></input>
            </label>
            <span className={errors["structureArea"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["structureArea"]}</span>

            <label className="input-group" htmlFor="maintenanceIssuesDescription">
                <span className="label-large">Any Obvious Maintenance Issues</span>
                <textarea className="input assessment__textSize" maxLength={500} id="maintenanceIssuesDescription" name="maintenanceIssuesDescription" value={props.buildingAssessment.maintenanceIssuesDescription} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["maintenanceIssuesDescription"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssuesDescription"]}</span>

            <label className="input-group" htmlFor="maintenanceIssuesInsuredAware">
                <span className="label-large">Insured aware of any maintenance issues? </span>
                <select className="select" id="maintenanceIssuesInsuredAware" name="maintenanceIssuesInsuredAware" value={props.buildingAssessment.maintenanceIssuesInsuredAware} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)} disabled={!props.canEdit}>
                    <option hidden defaultValue="-1"></option>
                    {props.yesNoUnsure.map(yesNoUnsureOption =>
                        <option key={yesNoUnsureOption.id} value={yesNoUnsureOption.id}>{yesNoUnsureOption.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["maintenanceIssuesInsuredAware"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["maintenanceIssuesInsuredAware"]}</span>

            <label className="input-group" htmlFor="causeOfDamage">
                <span className="label-large">Report/Circumstances</span>
                <textarea className="input assessment__textSize" id="causeOfDamage" name="causeOfDamage" value={props.buildingAssessment.causeOfDamage} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["causeOfDamage"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["causeOfDamage"]}</span>

            <label className="input-group" htmlFor="observations">
                <span className="label-large">Observations</span>
                <textarea className="input assessment__textSize" id="observations" name="observations" value={props.buildingAssessment.observations} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["observations"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["observations"]}</span>

            <label className="input-group" htmlFor="resolution">
                <span className="label-large">Resolution of Observation and Evaluation</span>
                <textarea className="input assessment__textSize" id="resolution" name="resolution" value={props.buildingAssessment.resolution} onChange={(e) => handleChange(e)} onBlur={(e) => handleOnBlur(e)}></textarea>
            </label>
            <span className={errors["resolution"] ? "label errors errors__leftmargin--large" : "hidden"}>{errors["resolution"]}</span>

            <button className="defaultbutton defaultbutton-label-large" type="button" onClick={next} disabled={!props.canEdit || props.saveDisabled}>Next</button>
        </div>
    )
}

export default JobAssessmentBuildingData;
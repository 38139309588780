import * as React from 'react';
import GoogleAutoComplete from './GoogleAutoComplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface SupplierProps {
    id: number;
    supplierDetails: SupplierDetails;
    statusOptions: GenericListItem[];
    termOptions: GenericListItem[];
    trades: GenericListItem[];
    otherTrades: GenericListItem[];
    supplierTypes: GenericListItem[];
    update(supplierDetails: SupplierDetails): void;
    save(sendToXero: boolean): void;
    sendToXero(): void;
}

interface XeroListItem {
    id: string;
    name: string;
    totalOwedFormatted: string;
    selected: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface SupplierDetails {
    id: number;
    xeroContactId: string;
    xeroContactName: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    termsId: number;
    primaryTradeId: number;
    supplierTrades: GenericListItem[];
    phone: string;
    email: string;
    typeId: number;
    status: number;
    lockAccount: boolean;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const SupplierDetail = (props: SupplierProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [buttonDisabled, setButtonDisabled] = React.useState({ modalSaveDisabled: false, saveDisabled: false });
    const [xeroNameSearch, setXeroNameSearch] = React.useState("");
    const [xeroContacts, setXeroContacts] = React.useState<XeroListItem[]>([]);
    const [sendDisabled, setSendDisabled] = React.useState(false);

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        var supplierDetails = props.supplierDetails;
        let newSupplier: any = {};
        newSupplier = supplierDetails;
        newSupplier[e.target.name] = e.target.value;

        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });
        props.update(newSupplier);
    }

    const handleCheckboxChange = (e: any) => {
        var supplierDetails = props.supplierDetails;
        let newSupplier: any = {};
        newSupplier = supplierDetails;
        newSupplier[e.target.name] = e.target.checked;

        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });
        props.update(newSupplier);
    }

    const handleTradeChange = (e: any) => {
        setErrors({});

        var supplierDetails = props.supplierDetails;
        var supplierTrades = supplierDetails.supplierTrades;
        var newTrade: GenericListItem = {
            id: parseInt(e.target.value),
            name: e.target.selectedOptions[0].label
        };
        supplierTrades.push(newTrade);
        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });

        props.update(supplierDetails);
    }

    //xero contacts
    const handleCellCheckboxChange = (index: number, e: any) => {
        //update item that has changed
        //if selected checked then set all other customers to false
        var xeroContactsUpdated = [...xeroContacts];
        if (e.target.checked) {
            for (var i = 0; i < xeroContactsUpdated.length; i++) {
                xeroContactsUpdated[i].selected = false;
            }
            setSendDisabled(true);
        } else {
            setSendDisabled(false);
        }

        var xeroCustomer = xeroContactsUpdated[index];
        let exist: any = {};
        exist = xeroCustomer;
        exist[e.target.name] = e.target.checked;

        setXeroContacts(xeroContactsUpdated);
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["address"];
        setErrors(error);

        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });
        updateAddress(addressDetails);
    }

    //update google address
    const updateAddress = (addressDetails: AddressDetails) => {
        var supplier = props.supplierDetails;

        supplier.address = addressDetails.fullAddress;
        supplier.streetNumber = addressDetails.street_number;
        supplier.streetName = addressDetails.route;
        supplier.suburb = addressDetails.locality;
        supplier.state = addressDetails.administrative_area_level_1;
        supplier.country = addressDetails.country;
        supplier.postCode = addressDetails.postal_code;
        supplier.googlePlaceId = addressDetails.placeId;
        supplier.siteAddressLatitude = addressDetails.latitude;
        supplier.siteAddressLongitude = addressDetails.longitude;

        props.update(supplier);
    }

    //remove trade from selected
    const removeItem = (e: any, id: number) => {
        e.preventDefault();
        setErrors({});

        var supplierDetails = props.supplierDetails;
        var supplierTrades = supplierDetails.supplierTrades;
        supplierTrades.splice(supplierTrades.findIndex(function (i) {
            return i.id === id;
        }), 1);
        setButtonDisabled({ saveDisabled: false, modalSaveDisabled: false });

        props.update(supplierDetails);
    }

    const searchXeroContacts = (e: any) => {
        e.preventDefault();
        if (xeroNameSearch) {
            getCustomersByName();
        } else {
            let error: any = {};
            error["xeroNameSearch"] = "Enter name to search";
            setErrors(error);
        }
    }

    const getCustomersByName = async() => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            id: props.id,
            page: "supplier",
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //if authorised
                axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + xeroNameSearch, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    var contacts = res.data;
                    var xeroContactsUpdated = [...xeroContacts];
                    for (var i = 0; i < contacts.length; i++) {
                        var customer = contacts[i];
                        customer.selected = false;
                        xeroContactsUpdated.push(customer);
                    }
                    setXeroContacts(xeroContactsUpdated);

                    if (contacts.length === 0) {
                        toast.info("There were no Xero contacts that matched your search.  Note: Xero search is case sensitive!");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    const clearXeroContact = (e: any) => {
        e.preventDefault();
        unlinkXeroContact();
    }

    const unlinkXeroContact = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var supplier = props.supplierDetails;

        var unlinkContact = {
            id: supplier.id,
            subId: user.sub
        };

        axios.post('Suppliers/UnlinkXeroContact', unlinkContact, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Xero Contact has been cleared");
                supplier.xeroContactId = "";
                supplier.xeroContactName = "";
                props.update(supplier);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const validate = () => {
        let fields = props.supplierDetails;
        let error: any = {};
        let formIsValid = true;

        if (!fields.name) {
            formIsValid = false;
            error["name"] = "Name is required";
        }

        if (fields.typeId < 0) {
            formIsValid = false;
            error["typeId"] = "Supplier Type is required";
        }

        if (fields.addressManual) {
            if (!fields.streetName) {
                formIsValid = false;
                error["streetName"] = "Street Name is required";
            }

            if (!fields.suburb) {
                formIsValid = false;
                error["suburb"] = "Suburb is required";
            }

            if (!fields.state) {
                formIsValid = false;
                error["state"] = "State is required";
            }

            if (!fields.postCode) {
                formIsValid = false;
                error["postCode"] = "Post Code is required";
            }
        } else {
            if (!fields.address) {
                formIsValid = false;
                error["address"] = "Address is required";
            }
        }

        if (fields.registeredForGst === true && !fields.abn) {
            formIsValid = false;
            error["abn"] = "ABN is required";
        }
        if (fields.termsId < 0) {
            formIsValid = false;
            error["termsId"] = "Terms are required";
        }
        if (!fields.email) {
            formIsValid = false;
            error["email"] = "Email is required";
        }
        if (fields.primaryTradeId < 0) {
            formIsValid = false;
            error["primaryTradeId"] = "Primary Trade is required";
        }
        if (fields.status < 0) {
            formIsValid = false;
            error["status"] = "Status is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (buttonDisabled.saveDisabled) {
                return;
            }
            setButtonDisabled({ saveDisabled: true, modalSaveDisabled: false });

            var xeroContactsSelected = xeroContacts.filter(x => x.selected);
            if (xeroContactsSelected.length > 0) {
                //need to update the contact details with the xero contact id
                var supplier = props.supplierDetails;
                supplier.xeroContactId = xeroContactsSelected[0].id;
                supplier.xeroContactName = xeroContactsSelected[0].name;
                props.update(supplier);
            }

            props.save(false);
        } else {
            toast.error("Please fix errors before saving!");
        }
    }

    const sendToXero = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (sendDisabled) {
                return;
            }
            setSendDisabled(true);

            var xeroContactsSelected = xeroContacts.filter(x => x.selected);
            if (xeroContactsSelected.length > 0) {
                //need to update the contact details with the xero contact id
                var supplier = props.supplierDetails;
                supplier.xeroContactId = xeroContactsSelected[0].id;
                props.update(supplier);
            }

            //save and send to Xero
            props.sendToXero();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    //work out the available suppliers
    var availTrades = props.otherTrades.filter(function (data) {
        var found = props.supplierDetails.supplierTrades.find(r => r.id === data.id);
        if (!found) {
            return data;
        }
    });

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Address"} placeholder={props.supplierDetails.address} className="input-group" disabled={false} save={setAddress} />

    return (
        <form name="supplier" onSubmit={save}>
            <div className="input-group-parent">
                <label className="input-group" htmlFor="xeroContactId">
                    <span className="label">Xero Contact Linked</span>
                    <input className="checkbox" type="checkbox" id="xeroContactId" name="xeroContactId" checked={props.supplierDetails.xeroContactId != ""} onChange={(e) => handleCheckboxChange(e)} disabled></input>
                </label>
                <div className={props.supplierDetails.xeroContactId ? "hidden" : ""}>
                    <label className="input-group" htmlFor="xeroNameSearch">
                        <span className="label">Xero Name Search</span>
                        <input className='input marginRight10' type='text' id="xeroNameSearch" name="xeroNameSearch" value={xeroNameSearch} onChange={(e) => setXeroNameSearch(e.target.value)}></input>
                        <button className="defaultbutton defaultbutton__xsmall supplier-invoice__search--position" type="button" onClick={searchXeroContacts}>Search</button>
                    </label>
                    <span className={errors["xeroNameSearch"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroNameSearch"]}</span>

                    <div className={xeroContacts.length === 0 ? "hidden" : ""}>
                        <div className='overflowAuto client__xeroTableSize'>
                            <table className="table--main table__small tableColours">
                                <thead>
                                    <tr>
                                        <th className="hidden">Id</th>
                                        <th></th>
                                        <th>Name</th>
                                        <th className="textalignright">Outstanding</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {xeroContacts.map((xeroContact, index) =>
                                        <tr key={index}>
                                            <td className="hidden">{xeroContact.id}</td>
                                            <td><input type="checkbox" className="input checkbox" name="selected" checked={xeroContact.selected} onChange={(e) => handleCellCheckboxChange(index, e)} /></td>
                                            <td>{xeroContact.name}</td>
                                            <td className="table__text--align textalignright">{xeroContact.totalOwedFormatted}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <label className="input-group" htmlFor="name">
                    <span className="label">Name</span>
                    <input className="input" type="text" maxLength={200} id="name" name="name" value={props.supplierDetails.name} onChange={(e) => handleChange(e)} disabled={props.id === 0 ? false : true}></input>
                </label>
                <span className={errors["name"] ? "label errors errors__leftmargin" : "hidden"}>{errors["name"]}</span>

                <label className={props.supplierDetails.xeroContactId ? "input-group" : "hidden"} htmlFor="xeroContactName">
                    <span className="label">Xero Contact</span>
                    <input className="input marginRight10" type="text" id="xeroContactName" name="xeroContactName" value={props.supplierDetails.xeroContactName} onChange={(e) => handleChange(e)} disabled={props.id === 0 ? false : true}></input>
                    <a href="#" onClick={clearXeroContact}>Clear Xero Contact</a>
                </label>

                <label className="input-group" htmlFor="typeId">
                    <span className="label">Supplier Type</span>
                    <select className="select" id="typeId" name="typeId" value={props.supplierDetails.typeId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {props.supplierTypes.map(type =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["typeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["typeId"]}</span>

                <label className="input-group" htmlFor="addressManual">
                    <span className="label">Manual Address / PO Box</span>
                    <input className="checkbox" type="checkbox" id="addressManual" name="addressManual" checked={props.supplierDetails.addressManual} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <div className={props.supplierDetails.addressManual ? "hidden" : ""}>
                    {googleContents}
                    <span className={errors["address"] ? "label errors errors__leftmargin" : "hidden"}>{errors["address"]}</span>
                </div>
                <div className={props.supplierDetails.addressManual ? "" : "hidden"}> 
                    <label className="input-group" htmlFor="streetName">
                        <span className="label">Street Name</span>
                        <input className="input" type="text" maxLength={100} id="streetName" name="streetName" value={props.supplierDetails.streetName} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["streetName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["streetName"]}</span>

                    <label className="input-group" htmlFor="suburb">
                        <span className="label">Suburb</span>
                        <input className="input" type="text" maxLength={100} id="suburb" name="suburb" value={props.supplierDetails.suburb} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["suburb"] ? "label errors errors__leftmargin" : "hidden"}>{errors["suburb"]}</span>

                    <label className="input-group" htmlFor="state">
                        <span className="label">State</span>
                        <select className="select" id="state" name="state" value={props.supplierDetails.state} onChange={(e) => handleChange(e)} >
                            <option value="VIC">VIC</option>
                            <option value="NSW">NSW</option>
                            <option value="QLD">QLD</option>
                            <option value="WA">WA</option>
                            <option value="SA">SA</option>
                            <option value="TAS">TAS</option>
                            <option value="ACT">ACT</option>
                            <option value="NT">NT</option>
                        </select>
                    </label>
                    <span className={errors["state"] ? "label errors errors__leftmargin" : "hidden"}>{errors["state"]}</span>

                    <label className="input-group" htmlFor="postCode">
                        <span className="label">Post Code</span>
                        <input className="input" type="text" maxLength={10} id="postCode" name="postCode" value={props.supplierDetails.postCode} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["postCode"] ? "label errors errors__leftmargin" : "hidden"}>{errors["postCode"]}</span>
                </div>

                <label className="input-group" htmlFor="registeredForGst">
                    <span className="label">Registered For GST</span>
                    <input className="checkbox" type="checkbox" id="registeredForGst" name="registeredForGst" checked={props.supplierDetails.registeredForGst} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="abn">
                    <span className="label">ABN</span>
                    <input className="input" type="text" maxLength={20} id="abn" name="abn" value={props.supplierDetails.abn} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["abn"] ? "label errors errors__leftmargin" : "hidden"}>{errors["abn"]}</span>

                <label className="input-group" htmlFor="termsId">
                    <span className="label">Terms</span>
                    <select className="select" id="termsId" name="termsId" value={props.supplierDetails.termsId} onChange={(e) => handleChange(e)} disabled={props.supplierDetails.id === 0 ? true : false}>
                        {props.termOptions.map(term =>
                            <option key={term.id} value={term.id}>{term.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["termsId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["termsId"]}</span>

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className="input" type="text" maxLength={30} id="phone" name="phone" value={props.supplierDetails.phone} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className="input" type="text" maxLength={200} id="email" name="email" value={props.supplierDetails.email} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["email"] ? "label errors errors__leftmargin" : "hidden"}>{errors["email"]}</span>

                <label className="input-group" htmlFor="status">
                    <span className="label">Status</span>
                    <select className="select" id="status" name="status" value={props.supplierDetails.status} onChange={(e) => handleChange(e)} disabled={props.supplierDetails.id === 0 ? true : false}>
                        {props.statusOptions.map(status =>
                            <option key={status.id} value={status.id}>{status.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["status"] ? "label errors errors__leftmargin" : "hidden"}>{errors["status"]}</span>

                <label className="input-group" htmlFor="lockAccount">
                    <span className="label">Lock Account</span>
                    <input className="checkbox" type="checkbox" id="lockAccount" name="lockAccount" checked={props.supplierDetails.lockAccount} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>
            </div>
            <div className="input-group-parent">
                <label className="input-group" htmlFor="primaryTradeId">
                    <span className="label">Primary Trade</span>
                    <select className="select" id="primaryTradeId" name="primaryTradeId" value={props.supplierDetails.primaryTradeId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {props.trades.map(trade =>
                            <option key={trade.id} value={trade.id}>{trade.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["primaryTradeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["primaryTradeId"]}</span>
                    
                <label className="input-group" htmlFor="supplierId">
                    <span className="label">Other Trades</span>
                    <select className="select" id="supplierId" name="supplierId" value={-1} onChange={(e) => handleTradeChange(e)} >
                        <option defaultValue="-1"></option>
                        {availTrades.map(trade =>
                            <option key={trade.id} value={trade.id}>{trade.name}</option>
                        )}
                    </select>
                </label>
                <span className={errors["supplierId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierId"]}</span>

                <label className="input-group" htmlFor="trades">
                    <span className="label"></span>
                    <div className="">
                        {props.supplierDetails.supplierTrades.map(trade =>
                            <div className="list-close--nowrap" key={trade.id} >
                                <span className="input">{trade.name}</span>
                                    
                                <a className="makeitred" href="#" onClick={(e) => removeItem(e, trade.id)}>
                                    <span className="fas fa-times-circle alignDeleteIcon"></span>
                                </a>
                                <br />
                            </div>
                        )}
                    </div>
                </label>
            </div>
            <button className="defaultbutton-prev defaultbutton-prev__split" type="submit" disabled={buttonDisabled.saveDisabled}>Save</button>
            <button className="defaultbutton-next" type="button" onClick={sendToXero} disabled={sendDisabled}>{props.supplierDetails.xeroContactId ? "Update Xero Contact" : "Send To Xero"}</button>
        </form>
    );

}

export default SupplierDetail;
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import * as DateHelpers from './DateHelpers';
import SupplierInvoiceFilePopup from './SupplierInvoiceFile';
import SupplierInvoicePOSearchPopup from './SupplierInvoicePOSearchPopup';
import SupplierInvoicePoReplacePopup from './SupplierInvoicePoReplacePopup';
import SupplierInvoiceXeroContactPopup from './SupplierInvoiceXeroContactPopup';
import SupplierInvoiceSupplierPopup from './SupplierInvoiceSupplierPopup';
import ConfirmModal from './ConfirmModal';
import ConfirmModalWithNoteData from './ConfirmModalWithNotes';
import AddXeroContact from './SupplierInvoiceAddXeroContactPopup';

interface Batch {
    batchId: number;
    batchDate: string;
}

interface ImportedInvoice {
    id: number;
    itemIndex: number;
    invoiceNumber: string;
    jobId: number;
    makeSafe: boolean;
    siteAddress: string;
    purchaseOrderNumber: number;
    purchaseOrderNumbers: string;   //can be multiple
    purchaseOrderNumberText: string;
    supplier: string;
    supplierId: number;
    supplierRegisteredForGst: boolean;
    supplierAttached: boolean;
    xeroInvoiceId: string;
    xeroContactId: string;
    xeroContactName: string;
    invoiceDate: string;
    dueDate: string;
    poSubTotal: number;
    poSubTotalFormatted: string;
    poSubTotalRemaining: number;
    poSubTotalRemainingFormatted: string;
    poGst: number;
    poGstFormatted: string;
    poGstRemaining: number;
    poGstRemainingFormatted: string;
    poTotal: number;
    poTotalFormatted: string;
    poTotalRemaining: number;
    poTotalRemainingFormatted: string;
    invoiceSubTotal: number;
    invoiceGst: number;
    invoiceTotal: number;
    approved: boolean;
    duplicateInvoice: boolean;
    statusId: number;
    status: string;
    selected: boolean;
    isDirty: boolean;
}

interface MakeSafeJob {
    supplierInvoiceId: number;
    supplierId: number;
    supplierName: string;
    siteAddress: string;
    subTypeId: number;
    insurerId: number;
    insurerContactId: number;
    claimNumber: string;
    estimatorId: number;
    supervisorId: number;
    customerName: string;
}

interface FailedInvoice {
    id: number;
    fileName: string;
    errorMessage: string;
}

interface SupplierResult {
    invoiceId: number;
    jobId: number;
    siteAddress: string;
    supplier: string;
    supplierId: number;
    supplierRegisteredForGst: boolean;
    xeroContactId: string;
    xeroContactName: string;
    purchaseOrderNumber: number;
    purchaseOrderNumbers: string;
    purchaseOrders: SupplierResultPo[];
    poSubTotal: number;
    poSubTotalFormatted: string;
    poSubTotalRemaining: number;
    poSubTotalRemainingFormatted: string;
    poGst: number;
    poGstFormatted: string;
    poGstRemaining: number;
    poGstRemainingFormatted: string;
    poTotal: number;
    poTotalFormatted: string;
    poTotalRemaining: number;
    poTotalRemainingFormatted: string;
}

interface SupplierResultPo {
    purchaseOrderId: number;
    dueDate: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    total: number;
    totalFormatted: string;
}

interface SupplierOption {
    id: number;
    name: string;
    registeredForGst: boolean;
    validCertificate: boolean;
    certificateExpiryText: string;
    certificateExpiryDate: string;
}

interface InsurerWithContactOption {
    id: number;
    name: string;
    contacts: GenericListItem[];
}

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierInvoicesData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [suppliers, setSuppliers] = React.useState<SupplierOption[]>([]);
    const [jobSubTypes, setJobSubTypes] = React.useState<GenericListItem[]>([]);
    const [insurers, setInsurers] = React.useState<InsurerWithContactOption[]>([]);
    const [estimators, setEstimators] = React.useState<GenericListItem[]>([]);
    const [supervisors, setSupervisors] = React.useState<GenericListItem[]>([]);
    const [gstPercentage, setGstPercentage] = React.useState(0.1);
    const [batches, setBatches] = React.useState<Batch[]>([]);
    const [batchData, setBatchData] = React.useState({ batchId: 0, batchDate: "" });
    const [canApproveSupplierInvoices, setCanApproveSupplierInvoices] = React.useState(false);
    const [showImport, setShowImport] = React.useState(false);
    const [showSearch, setShowSearch] = React.useState(false);
    const [searchFields, setSearchFields] = React.useState({
        invNumber: "",
        supplierId: -1,
        poNumber: "",
        jobId: "",
        siteAddress: "",
    });
    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
    const [uploadDisabled, setUploadDisabled] = React.useState(false);
    const [showSupplierInvoices, setShowSupplierInvoices] = React.useState(false);
    const [importedInvoices, setImportedInvoices] = React.useState<ImportedInvoice[]>([]);
    const [failedInvoices, setFailedInvoices] = React.useState<FailedInvoice[]>([]);
    const [totals, setTotals] = React.useState({ importedInvoicesTotalFormatted: "$0.00", importedInvoicesSentTotalFormatted: "$0.00" });
    const [selectAll, setSelectAll] = React.useState(false);
    const [selectAllMakeSafe, setSelectAllMakeSafe] = React.useState(false);
    const [makeSafeJobs, setMakeSafeJobs] = React.useState<MakeSafeJob[]>([]);
    const [createMakeSafeJobs, setCreateMakeSafeJobs] = React.useState(false);
    const [showFindPurchaseOrder, setShowFindPurchaseOrder] = React.useState(false);
    const [showReplacePurchaseOrder, setShowReplacePurchaseOrder] = React.useState(false);
    const [showFindSupplier, setShowFindSupplier] = React.useState(false);
    const [showFindXeroContact, setShowFindXeroContact] = React.useState(false);
    const [poSearchFields, setPoSearchFields] = React.useState({ poSearchJobId: 0, poSearchSupplierId: 0 });
    const [supplierId, setSupplierId] = React.useState(0);
    const [invoiceId, setInvoiceId] = React.useState(0);
    const [invoiceTotal, setInvoiceTotal] = React.useState(0);
    const [showAddXeroContact, setShowAddXeroContact] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [showApproveModal, setShowApproveModal] = React.useState(false);
    const [approveData, setApproveData] = React.useState({ approveIndex: 1, approveNotes: "" });
    const [showInvoice, setShowInvoice] = React.useState(false);
    const [uploadedInvoice, setUploadedInvoice] = React.useState<File>(new File([""], "filename"));
    const [invoiceIndexId, setInvoiceIndexId] = React.useState(-1);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateEditSuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Suppliers/GetSupplierInvoiceBatches?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setGstPercentage(res.data.gstPercentage);
                    setSuppliers(res.data.suppliers);
                    setJobSubTypes(res.data.jobSubTypes);
                    setInsurers(res.data.insurers);
                    setEstimators(res.data.estimators);
                    setSupervisors(res.data.supervisors);
                    setBatches(res.data.batches);
                    setCanApproveSupplierInvoices(res.data.canApproveSupplierInvoices);
                    setLoading(false);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const showImportSection = (e: any) => {
        e.preventDefault();
        setShowImport(!showImport);
    }

    const handleImportFile = (e: any) => {
        setSelectedFiles(e.target.files);
        setUploadDisabled(false);
    }

    const uploadInvoice = (e: any) => {
        e.preventDefault();
        uploadSupplierInvoice();
    }

    const uploadSupplierInvoice = async () => {
        if (uploadDisabled) {
            return;
        }
        setUploadDisabled(true);

        const data = new FormData();

        for (var i = 0; i < selectedFiles.length; i++ ) {
            data.append('invoices', selectedFiles[i]);
        };
        const user = await authService.getUser();
        data.append('subId', user.sub);

        const token = await authService.getAccessToken();
        axios.post('Suppliers/ImportInvoice', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var impInvoices = res.data.supplierInvoicesImported;
                for (var i = 0; i < impInvoices.length; i++) {
                    impInvoices[i].itemIndex = i;
                }

                var updatedbatches = [...batches];
                updatedbatches.unshift(res.data.batchDetails);
                setBatches(updatedbatches);
                setBatchData({ batchId: res.data.batchDetails.batchId, batchDate: res.data.batchDetails.batchDate });
                setShowSupplierInvoices(true);
                setImportedInvoices(impInvoices);
                setFailedInvoices(res.data.supplierInvoicesFailed);
                setTotals({ importedInvoicesTotalFormatted: res.data.importedInvoicesTotalFormatted, importedInvoicesSentTotalFormatted: res.data.importedInvoicesTotalSentFormatted });
                setSelectedFiles([]);

                toast.success("Invoice has been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    const handleSearchChange = (e: any) => {
        setSearchFields(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    //SEARCH
    const showSearchSection = (e: any) => {
        e.preventDefault();
        setShowSearch(!showSearch);
    }

    const validateSearch = () => {
        let fields = searchFields;
        var isValid = true;

        if (fields.poNumber) {
            if (!DateHelpers.isNumber(fields.poNumber)) {
                toast.error("PO # must be a number!");
                isValid = false;
            }
        }

        if (fields.jobId) {
            if (!DateHelpers.isNumber(fields.jobId)) {
                toast.error("Job Id must be a number!");
                isValid = false;
            }
        }

        if (!fields.invNumber && !fields.poNumber && fields.supplierId < 0 && !fields.jobId && !fields.siteAddress) {
            toast.error("Please enter at least one search criteria!");
            isValid = false;
        }

        return isValid;
    }

    const search = (e: any) => {
        e.preventDefault();

        if (validateSearch()) {
            searchInvoices();
        }
    }

    const searchInvoices = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Suppliers/SearchSupplierInvoices?invoiceNumber=' + searchFields.invNumber + "&purchaseOrderNumber=" + searchFields.poNumber + "&supplierId=" + searchFields.supplierId + "&jobId=" + searchFields.jobId + "&siteAddress=" + searchFields.siteAddress + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var impInvoices = res.data.importedInvoices;
            for (var i = 0; i < impInvoices.length; i++) {
                impInvoices[i].itemIndex = i;
            }
            setImportedInvoices(impInvoices);
            setFailedInvoices([]);
            setTotals({ importedInvoicesTotalFormatted: res.data.importedInvoicesTotalFormatted, importedInvoicesSentTotalFormatted: res.data.importedInvoicesTotalSentFormatted });
            setShowSupplierInvoices(true);
            setBatchData({ batchId: 0, batchDate: "" });
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const reset = (e: any) => {
        e.preventDefault();
        setShowSupplierInvoices(false);
        setBatchData({ batchId: 0, batchDate: "" });
        setImportedInvoices([]);
        setFailedInvoices([]);
        setSearchFields({ invNumber: "", poNumber: "", supplierId: -1, jobId: "", siteAddress: "" });
        setSelectAll(false);
    }

    //END SEARCH

    const handleBatchChange = (e: any) => {
        var batchId = e.target.value * 1;

        if (batchId > 0) {
            //get supplier invoices for batch selected
            getBatchInvoices(batchId);
        } else {
            setShowSupplierInvoices(false);
            setBatchData({ batchId: batchId, batchDate: "" });
            setImportedInvoices([]);
            setFailedInvoices([]);
            setSelectAll(false);
            setSearchFields({ invNumber: "", poNumber: "", supplierId: -1, jobId: "", siteAddress: "" });
        }
    }

    const getBatchInvoices = async (batchId: number) => {
        let batch = batches.find(b => b.batchId === batchId);
        let batchDate = "";
        if (batch) {
            batchDate = batch.batchDate;
        }

        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        axios.get('Suppliers/GetSupplierImportedInvoices?BatchId=' + batchId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var impInvoices = res.data.supplierInvoicesImported;
                for (var i = 0; i < impInvoices.length; i++) {
                    impInvoices[i].itemIndex = i;
                }

                setImportedInvoices(impInvoices);
                setFailedInvoices(res.data.supplierInvoicesFailed);
                setBatchData({ batchId: batchId, batchDate: batchDate });
                setTotals({ importedInvoicesTotalFormatted: res.data.importedInvoicesTotalFormatted, importedInvoicesSentTotalFormatted: res.data.importedInvoicesTotalSentFormatted });
                setSelectAll(false);
                setSearchFields({ invNumber: "", poNumber: "", supplierId: -1, jobId: "", siteAddress: "" });
                setShowSupplierInvoices(true);
            }
        });
    }

    const handleSelectAllChange = (e: any) => {
        var invoices = [...importedInvoices];
        var assignedInvoices = invoices.filter(i => i.statusId === 2);

        //loop through all the invoices and select any that are in assigned status
        for (var i = 0; i < assignedInvoices.length; i++) {
            assignedInvoices[i].selected = e.target.checked;
        }

        setImportedInvoices(invoices);
        setSelectAll(e.target.checked);
    }

    const handleSelectAllMakeSafeChange = (e: any) => {
        var invoices = [...importedInvoices];
        var newInvoices = invoices.filter(i => i.statusId === 1);

        //loop through all the invoices and select any that are in created status
        for (var i = 0; i < newInvoices.length; i++) {
            newInvoices[i].makeSafe = e.target.checked;
        }

        setImportedInvoices(invoices);
        setSelectAllMakeSafe(e.target.checked);
    }

    const handleCellChange = (e: any, index: number) => {
        //update item that has changed
        var invoices = [...importedInvoices];
        var invoice = invoices[index];
        
        let exist: any = {};
        exist = invoice;
        exist[e.target.name] = e.target.value;
        invoice.isDirty = true;

        //calculate totals
        if (e.target.name === "invoiceSubTotal") {
            //calculate gst and total
            var subTotal = e.target.value * 1;
            var gst = invoice.supplierRegisteredForGst ? parseFloat((subTotal * gstPercentage).toFixed(2)) : 0.00;
            var total = parseFloat((subTotal + gst).toFixed(2));
            invoice.invoiceGst = gst;
            invoice.invoiceTotal = total;
        } else if (e.target.name === "invoiceGst") {
            //calculate total
            var gst = e.target.value * 1;
            var total = parseFloat(((invoice.invoiceSubTotal * 1) + gst).toFixed(2));
            invoice.invoiceTotal = total;
        } else if (e.target.name === "invoiceTotal") {
            //calculate subtotal and gst
            var total = e.target.value * 1;
            var subTotal = invoice.supplierRegisteredForGst ? parseFloat((total / (gstPercentage + 1)).toFixed(2)) : parseFloat(total.toFixed(2));
            var gst = invoice.supplierRegisteredForGst ? parseFloat((total - subTotal).toFixed(2)) : 0.00;
            invoice.invoiceSubTotal = subTotal;
            invoice.invoiceGst = gst;
        }

        setImportedInvoices(invoices);
        setErrors({});
    }

    const handleCellCheckChange = (e: any, index: number) => {
        //update item that has changed
        var invoices = [...importedInvoices];
        var invoice = invoices[index];

        let exist: any = {};
        exist = invoice;
        exist[e.target.name] = e.target.checked;

        setImportedInvoices(invoices);
        setErrors({});
    }

    const handleMSCellChange = (e: any, index: number) => {
        var msJobs = [...makeSafeJobs];
        var msJob = msJobs[index];

        let exist: any = {};
        exist = msJob;
        exist[e.target.name] = e.target.value;

        //add supplier name
        if (e.target.name === "supplierId") {
            exist["supplierName"] = e.target.options[e.target.selectedIndex].text;
        }

        setMakeSafeJobs(msJobs);
        setErrors({});
    }

    //SHOW FIND PO NUMBER
    const findPurchaseOrder = (e: any, invId: number, invTotal: number, jobId: number, supId: number) => {
        e.preventDefault();

        setInvoiceId(invId);
        setInvoiceTotal(invTotal);
        setPoSearchFields({ poSearchJobId: jobId, poSearchSupplierId: supId });
        setShowFindPurchaseOrder(true);
    }

    const hideFindPurchaseOrder = () => {
        setInvoiceId(0);
        setInvoiceTotal(0);
        setPoSearchFields({ poSearchJobId: 0, poSearchSupplierId: 0 });
        setShowFindPurchaseOrder(false);
    }

    const saveFindPurchaseOrder = (result: SupplierResult, duplicateInvoice: boolean) => {
        var supInvoices = [...importedInvoices];
        var supInvoiceIndex = supInvoices.findIndex(ii => ii.id == result.invoiceId);
        if (supInvoiceIndex >= 0) {
            var supInvoice = supInvoices[supInvoiceIndex];
            supInvoice.jobId = result.jobId;
            supInvoice.siteAddress = result.siteAddress;
            supInvoice.supplier = result.supplier;
            supInvoice.supplierId = result.supplierId;
            supInvoice.supplierRegisteredForGst = result.supplierRegisteredForGst;
            supInvoice.purchaseOrderNumber = result.purchaseOrderNumber;
            supInvoice.purchaseOrderNumbers = result.purchaseOrderNumbers;
            supInvoice.poSubTotal = result.poSubTotal;
            supInvoice.poSubTotalFormatted = result.poSubTotalFormatted;
            supInvoice.poGst = result.poGst;
            supInvoice.poGstFormatted = result.poGstFormatted;
            supInvoice.poTotal = result.poTotal;
            supInvoice.poTotalFormatted = result.poTotalFormatted;
            supInvoice.poSubTotalRemaining = result.poSubTotalRemaining;
            supInvoice.poSubTotalRemainingFormatted = result.poSubTotalRemainingFormatted;
            supInvoice.poGstRemaining = result.poGstRemaining;
            supInvoice.poGstRemainingFormatted = result.poGstRemainingFormatted;
            supInvoice.poTotalRemaining = result.poTotalRemaining;
            supInvoice.poTotalRemainingFormatted = result.poTotalRemainingFormatted;
            supInvoice.status = "Assigned";
            supInvoice.statusId = 2;
            supInvoice.xeroContactId = result.xeroContactId;
            supInvoice.xeroContactName = result.xeroContactName;
            supInvoice.duplicateInvoice = duplicateInvoice;

            if (!result.supplierRegisteredForGst) {
                //set gst to 0 for any suppliers not registered for gst
                supInvoice.invoiceGst = 0.00;
                var total = supInvoice.invoiceTotal * 1;
                var subTotal = parseFloat(total.toFixed(2));
                supInvoice.invoiceSubTotal = subTotal;
            }
        }

        setInvoiceId(0);
        setInvoiceTotal(0);
        setPoSearchFields({ poSearchJobId: 0, poSearchSupplierId: 0 });
        setShowFindPurchaseOrder(false);
        setImportedInvoices(supInvoices);
    }

    const clearPurchaseOrder = (e: any, invId: number) => {
        e.preventDefault();

        clearPO(invId, false);
    }

    const clearJobPurchaseOrder = (e: any, invId: number) => {
        e.preventDefault();

        clearPO(invId, true);
    }

    const clearPO = async (invoiceId: number, clearJob: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var supplierInvoices = [...importedInvoices];
        var supplierInvoice = supplierInvoices.find(s => s.id == invoiceId);

        var saveDetails = {
            supplierInvoiceId: invoiceId,
            clearJob: clearJob,
            subId: user.sub
        };

        axios.post('Suppliers/ClearPurchaseOrder', saveDetails, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                if (supplierInvoice) {
                    if (clearJob) {
                        supplierInvoice.jobId = 0;
                    }
                    if (!supplierInvoice.supplierAttached) {
                        //clear as fields were added via PO not via supplier import
                        supplierInvoice.supplier = "";
                        supplierInvoice.supplierId = 0;
                        supplierInvoice.xeroContactId = "";
                        supplierInvoice.xeroContactName = "";
                        supplierInvoice.xeroInvoiceId = "";
                    }
                    supplierInvoice.siteAddress = "";
                    supplierInvoice.purchaseOrderNumber = 0;
                    supplierInvoice.purchaseOrderNumberText = "";
                    supplierInvoice.poGst = 0;
                    supplierInvoice.poGstFormatted = "$0.00";
                    supplierInvoice.poSubTotal = 0;
                    supplierInvoice.poSubTotalFormatted = "$0.00";
                    supplierInvoice.poTotal = 0;
                    supplierInvoice.poTotalFormatted = "$0.00";
                    supplierInvoice.status = "Created";
                    supplierInvoice.statusId = 1;

                    setImportedInvoices(supplierInvoices);

                    toast.success("Purchase Order has been cleared successfully");
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //END FIND PO NUMBER

    //REPLACE PO NUMBER
    const replacePoNumber = (e: any, invId: number, invTotal: number, jobId: number) => {
        e.preventDefault();

        setInvoiceId(invId);
        setInvoiceTotal(invTotal);
        setPoSearchFields({ poSearchJobId: jobId, poSearchSupplierId: 0 });
        setShowReplacePurchaseOrder(true);
    }

    const hideReplacePoNumber = () => {
        setInvoiceId(0);
        setPoSearchFields({ poSearchJobId: 0, poSearchSupplierId: 0 });
        setShowReplacePurchaseOrder(false);
    }

    const saveReplacePoNumber = () => {
        setInvoiceId(0);
        setPoSearchFields({ poSearchJobId: 0, poSearchSupplierId: 0 });
        setShowReplacePurchaseOrder(false);

        //reload the batch
        getBatchInvoices(batchData.batchId);
    }

    //END REPLACE PO NUMBER

    //FIND SUPPLIER
    const findSupplier = (e: any, invId: number, invIndex: number) => {
        e.preventDefault();
        setInvoiceId(invId);
        setInvoiceIndexId(invIndex);
        setShowFindSupplier(true);
    }

    const hideSupplier = () => {
        setInvoiceId(0);
        setShowFindSupplier(false);
    }

    const saveSupplier = (supplierId: number, supplierName: string, duplicateInvoice: boolean) => {
        let supplierInvoices = [...importedInvoices];
        let supplierInvoice = supplierInvoices[invoiceIndexId];

        supplierInvoice.supplier = supplierName;
        supplierInvoice.supplierId = supplierId;
        supplierInvoice.duplicateInvoice = duplicateInvoice;

        setImportedInvoices(supplierInvoices);
        setInvoiceId(0);
        setInvoiceIndexId(-1);
        setShowFindSupplier(false);

        toast.success("Supplier has been added to the invoice");
    }
    //END FIND SUPPLIER

    //FIND XERO CONTACT
    const findXeroContact = (e: any, invoiceId: number) => {
        e.preventDefault();
        findXeroSupplierContact(invoiceId);
    }

    const findXeroSupplierContact = async (invId: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if have authenticated with xero
        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "supplierinvoices",
            id: 0
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                setInvoiceId(invId);
                setShowFindXeroContact(true);
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    const hideFindXeroContact = () => {
        setInvoiceId(0);
        setShowFindXeroContact(false);
    }

    const saveFindXeroContact = (xeroContactId: string, xeroContactName: string) => {
        var supInvoices = [...importedInvoices];
        var supInvoiceIndex = supInvoices.findIndex(ii => ii.id == invoiceId);
        if (supInvoiceIndex >= 0) {
            var supInvoice = supInvoices[supInvoiceIndex];
            supInvoice.xeroContactId = xeroContactId;
            supInvoice.xeroContactName = xeroContactName;
            setImportedInvoices(supInvoices);
        }
        setInvoiceId(0);
        setShowFindXeroContact(false);
    }
    //END FIND XERO CONTACT

    //ADD NEW XERO CONTACT
    const addNewXeroContact = () => {
        var supInvoices = [...importedInvoices];
        var supInvoice = supInvoices.find(s => s.id == invoiceId);

        if (supInvoice) {
            setSupplierId(supInvoice.supplierId);
            setShowAddXeroContact(true);
            setShowFindXeroContact(false);
            setModalSaveDisabled(false);
        } else {
            toast.error("Cannot find supplier invoice!");
        }
    }

    const hideAddXeroContact = () => {
        setShowAddXeroContact(false);
        setInvoiceId(0);
    }

    const saveAddXeroContact = (contactId: string, contactName: string) => {
        var supInvoices = [...importedInvoices];
        var supInvoice = supInvoices.find(s => s.id == invoiceId);

        if (supInvoice) {
            supInvoice.xeroContactId = contactId;
            supInvoice.xeroContactName = contactName;
            setImportedInvoices(supInvoices);
        }

        setShowAddXeroContact(false);
        setShowFindXeroContact(false);
        setModalSaveDisabled(false);
    }
    //END ADD NEW XERO CONTACT

    //APPROVE INVOICE
    const approve = (itemIndex: number, e: any) => {
        e.preventDefault();

        setShowApproveModal(true);
        setApproveData({ approveIndex: itemIndex, approveNotes: "" });
        setModalSaveDisabled(false);
    }

    const hideApproveConfirmModal = () => {
        setShowApproveModal(false);
        setApproveData({ approveIndex: -1, approveNotes: "" });
        setModalSaveDisabled(false);
    }

    const updateApproveNotes = (appNotes: string) => {
        setApproveData({ approveIndex: approveData.approveIndex, approveNotes: appNotes });
        setModalSaveDisabled(false);
    }

    const saveApprove = () => {
        approveInvoice();
    }

    const approveInvoice = async () => {
        //approve supplier invoice as the invoice amount > purchase order amount
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        let invoices = [...importedInvoices];
        let invoice = invoices[approveData.approveIndex];

        var details = {
            subId: user.sub,
            invoiceId: invoice.id,
            notes: approveData.approveNotes
        };

        axios.post('Jobs/ApproveSupplierInvoice', details, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                invoice.approved = true;
                setImportedInvoices(invoices);
                setShowApproveModal(false);
                setApproveData({ approveIndex: -1, approveNotes: "" });
                setModalSaveDisabled(false);
                
                toast.success("Invoice has been approved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }
    //END APPROVE

    //SHOW INVOICE FILE
    const showInvoiceFile = (e: any, invId: number) => {
        e.preventDefault();
        showSupplierInvoice(invId);
    }

    const showSupplierInvoice = async (invId: number) => {
        const token = await authService.getAccessToken();

        axios.get('Suppliers/GetSupplierImportInvoice?InvoiceId=' + invId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            setShowInvoice(true);
            setInvoiceId(invId);
            setUploadedInvoice(res.data);
        })
        .catch(error => {
            toast.error(error.message);
        }); 
    }

    const hideShowInvoice = () => {
        var uplInvoice: File = new File([""], "filename");
        setInvoiceId(0);
        setShowInvoice(false);
        setUploadedInvoice(uplInvoice);
    }
    //END SHOW INVOICE FILE

    //CANCEL INVOICE
    const cancelInvoice = (e: any, index: number) => {
        e.preventDefault();
        setInvoiceIndexId(index);
        setShowCancelConfirmModal(true);
        setModalSaveDisabled(false);
    }

    const hideCancelConfirmModal = () => {
        setInvoiceIndexId(-1);
        setShowCancelConfirmModal(false);
        setModalSaveDisabled(false);
    }

    const saveCancelConfirmModal = () => {
        saveSupplierCancelConfirmModal();
    }

    const saveSupplierCancelConfirmModal = async () => {
        const user = await authService.getUser();
        var invoices = [...importedInvoices];
        var invoice = invoices[invoiceIndexId];

        var invoiceToCancel = {
            id: invoice.id,
            jobId: invoice.jobId,
            xeroInvoiceId: invoice.xeroInvoiceId,
            subId: user.sub
        };

        //cancel invoice
        const token = await authService.getAccessToken();
        axios.post('Xero/DeleteSupplierInvoice', invoiceToCancel, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                invoice.status = "Cancelled";
                invoice.statusId = 5;
                setImportedInvoices(invoices);
                setTotals({ importedInvoicesTotalFormatted: res.data.importedInvoicesTotalFormatted, importedInvoicesSentTotalFormatted: res.data.importedInvoicesTotalSentFormatted });
                setInvoiceIndexId(-1);
                setShowCancelConfirmModal(false);
                setModalSaveDisabled(false);
                toast.success("Invoice Cancelled");
                calculateTotals(invoices);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //END CANCEL INVOICE

    //Calculate Totals
    const calculateTotals = (invoices: ImportedInvoice[]) => {
        var total = 0;
        var totalSent = 0;

        for (var i = 0; i < invoices.length; i++) {
            var statusId = invoices[i].statusId;

            if (statusId != 5) {
                total += invoices[i].invoiceTotal;
                if (statusId === 3 || statusId === 4 || statusId === 6) {
                    totalSent += invoices[i].invoiceTotal;
                }
            }
        }

        //format and save totals
        var totalFormatted = total.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var totalSentFormatted = totalSent.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        }); 

        setTotals({ importedInvoicesTotalFormatted: totalFormatted, importedInvoicesSentTotalFormatted: totalSentFormatted });
    }

    const validate = (items: ImportedInvoice[], sendToXero: boolean) => {
        let errorsList: any = {};
        let formIsValid = true;
        
        var errorString = "Please enter the following values: ";
        var error = false;

        var warningString = "";
        var warning = false;

        for (var i = 0; i < (items.length); i++) {
            var newErrorString = "";

            if (!items[i].invoiceDate) {
                newErrorString += " Invoice Date, ";
                error = true;
            } else if (!DateHelpers.isValidDate(items[i].invoiceDate)) {
                newErrorString += " Valid Invoice Date, ";
                error = true;
            }

            if (!items[i].dueDate) {
                newErrorString += " Due Date, ";
                error = true;
            } else if (!DateHelpers.isValidDate(items[i].dueDate)) {
                newErrorString += " Valid Due Date, ";
                error = true;
            }

            if (items[i].invoiceSubTotal <= 0) {
                newErrorString += " Sub Total, ";
                error = true;
            }

            if (items[i].invoiceGst == 0 && items[i].supplierRegisteredForGst) {
                newErrorString += " GST > 0, ";
                error = true;
            } else if(items[i].invoiceGst > 0 && !items[i].supplierRegisteredForGst) {
                newErrorString += " GST must be 0, ";
                error = true;
            }

            if (items[i].invoiceTotal <= 0) {
                newErrorString += " Total, ";
                error = true;
            }

            //validate totals add up
            if (items[i].invoiceSubTotal > 0 && items[i].invoiceTotal >= 0) {
                var subTotalGst = (items[i].invoiceSubTotal * 1) + (items[i].invoiceGst * 1);
                subTotalGst = Math.round((subTotalGst + Number.EPSILON) * 100) / 100
                var total = items[i].invoiceTotal * 1;

                if (total - subTotalGst != 0) {
                    newErrorString += " Sub Total + Gst does not match Total, ";
                    error = true;
                }
            }

            //Check if all data is ready to send to Xero
            //This is not checked if we are just saving
            if (sendToXero) {
                if (items[i].jobId === 0) {
                    //Need to assign the purchase order
                    newErrorString += " You must assign a Job / Purchase Order before sending to Xero, ";
                    error = true;
                }
                if (!items[i].invoiceNumber) {
                    newErrorString += " You must enter a reference before sending to Xero, ";
                    error = true;
                }
                if (!items[i].xeroContactId) {
                    newErrorString += " You must assign a Xero Contact before sending to Xero, ";
                    error = true;
                }
                if ((items[i].invoiceTotal > items[i].poTotalRemaining) && !items[i].approved && items[i].purchaseOrderNumber > 0) {
                    newErrorString += " This invoice must be approved before sending to Xero, ";
                    error = true;
                }
            }

            if (newErrorString !== "") {
                errorString += "\n";
                errorString += "Row " + (items[i].itemIndex + 1) + ": " + newErrorString;
                errorString = errorString.substring(0, errorString.length - 2);
            }
        }

        if (error) {
            formIsValid = false;
            errorsList["invoices"] = errorString;
        }
        if (warning) {
            errorsList["invoicewarning"] = "Please check GST on the following rows: " + warningString ;
        }
        setErrors(errorsList);
        return formIsValid;
    }

    const saveInvoice = () => {
        saveInvoices(false);
    }

    const saveInvoices = (sendToXero: boolean) => {
        let items = importedInvoices.filter(i => i.isDirty); //validate just the ones that have been updated

        if (items.length === 0) {
            toast.info("No invoices have been changed!");
        } else {
            if (validate(items, false)) {
                saveImportedInvoices(sendToXero);
            } else {
                toast.error("Fix errors on screen before saving.")
            }
        }
    }

    const saveImportedInvoices = async (sendToXero: boolean) => {
        const user = await authService.getUser();
        var invoicesToSave = importedInvoices.filter(i => i.isDirty);

        var importedInvoicesSave = {
            invoices: invoicesToSave,
            subId: user.sub
        };

        //save po details to invoice
        const token = await authService.getAccessToken();
        axios.post('Suppliers/SaveImportedInvoices', importedInvoicesSave, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });

            } else {
                toast.success("Invoices saved");
                if (sendToXero) {
                    sendInvoices();
                } else {
                    getBatchInvoices(batchData.batchId);
                }
                
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //MAKE SAFE JOBS
    const createMakeSafeJob = (e: any) => {
        e.preventDefault();

        if (createMakeSafeJobs) {
            //cancel creation of makesafe jobs
            setCreateMakeSafeJobs(false);
            setMakeSafeJobs([]);
        } else {
            var makeSafeJobs = importedInvoices.filter(i => i.makeSafe && i.statusId === 1);
            if (makeSafeJobs.length > 0) {
                //create new makesafe jobs
                var msJobs: MakeSafeJob[] = [];
                for (var i = 0; i < makeSafeJobs.length; i++) {
                    let invoice = makeSafeJobs[i];
                    let msJob: MakeSafeJob = {
                        supplierInvoiceId: invoice.id,
                        supplierId: invoice.supplierId === 0 ? -1 : invoice.supplierId,
                        supplierName: invoice.supplier,
                        siteAddress: invoice.siteAddress,
                        subTypeId: -1,
                        insurerId: -1,
                        insurerContactId: -1,
                        claimNumber: "",
                        estimatorId: -1,
                        supervisorId: -1,
                        customerName: ""
                    }
                    msJobs.push(msJob);
                }

                setCreateMakeSafeJobs(true);
                setMakeSafeJobs(msJobs);
            } else {
                toast.error("Select 1 or more invoices to create MakeSafe jobs");
            }
        }
    }

    const removeInvoice = (e: any, index: number) => {
        e.preventDefault();

        //just removes the invoice from the makesafe job list
        var msJobs = [...makeSafeJobs];
        msJobs.splice(index, 1);

        setMakeSafeJobs(msJobs);
    }

    const validateMakeSafeJobs = () => {
        let isValid = true;
        let error: any = {};
        var errorString = "Please enter the following values: ";

        let items = makeSafeJobs;
        
        for (var i = 0; i < (items.length); i++) {
            var newErrorString = "";
            var item = items[i]

            if (item.supplierId <= 0) {
                newErrorString += " Supplier, ";
                isValid = false;
            }

            if (!item.siteAddress) {
                newErrorString += " Site Address, ";
                isValid = false;
            }

            if (item.estimatorId <= 0 && item.supervisorId <= 0) {
                newErrorString += " Estimator or Supervisor, ";
                isValid = false;
            }

            if (item.subTypeId <= 0) {
                newErrorString += " Sub Type, ";
                isValid = false;
            }

            if (newErrorString !== "") {
                errorString += "\n";
                errorString += "Row " + (i + 1) + ": " + newErrorString;
                errorString = errorString.substring(0, errorString.length - 2);
            }
        }

        if (isValid) {
            error["makesafejobs"] = "";
        } else {
            error["makesafejobs"] = errorString;
        }

        setErrors(error);
        return isValid;
    }

    const saveMakeSafeJobs = () => {
        //todo check that invoices are saved before creating makesafe jobs

        if (validateMakeSafeJobs()) {
            saveCreateMakeSafeJobs();
        }
    }

    const saveCreateMakeSafeJobs = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        var saveMakeSafe = {
            makeSafeJobs: makeSafeJobs,
            SubId: user.sub
        };

        //send invoice to Xero
        axios.post('Suppliers/CreateMakeSafeJobs', saveMakeSafe, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });

            } else {
                //reload the batch
                getBatchInvoices(batchData.batchId);

                toast.success("Make Safe jobs created successfully!");
                setMakeSafeJobs([]);
                setCreateMakeSafeJobs(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //END MAKE SAFE JOBS

    const sendInvoice = (e: any) => {
        e.preventDefault();

        let items = importedInvoices.filter(i => i.isDirty); //validate just the ones that have been updated
        if (items.length === 0) {
            //nothing has changed so don't need to save
            sendInvoices();
        } else {
            saveInvoices(true);
        }
        
    }

    const sendInvoices = () => {
        let items = importedInvoices.filter(i => i.selected); //validate just the ones that have been updated

        if (items.length === 0) {
            toast.error("You must tick at least one invoice to send to xero!");
        } else {
            if (validate(items, true)) {
                sendInvoicesToXero();
            } else {
                toast.error("Fix errors on screen before sending to Xero!")
            }
        }
        
    }

    const sendInvoicesToXero = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if have authenticated with xero
        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "supplierinvoices",
            id: 0
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                var selectedInvoices = importedInvoices.filter(i => i.selected);
                var invIds = selectedInvoices.map(i => i.id);

                var invoicesToSend = {
                    InvoiceIds: invIds,
                    SubId: user.sub
                };

                //send invoice to Xero
                axios.post('Xero/SendSupplierInvoicesToXero', invoicesToSend, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        //reload the batch as some may have been sent
                        getBatchInvoices(batchData.batchId);
                        toast.info("Check Invoices sent to Xero as some had an error!");

                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });

                    } else {
                        //reload the batch
                        getBatchInvoices(batchData.batchId);

                        toast.success("Invoices sent to Xero");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    let invoiceFile = <SupplierInvoiceFilePopup invoiceId={invoiceId} heading="View Invoice" uploadedInvoice={uploadedInvoice} showModal={showInvoice} hideModal={hideShowInvoice} />
    let poSearch = <SupplierInvoicePOSearchPopup invoiceId={invoiceId} invoiceTotal={invoiceTotal} jobId={poSearchFields.poSearchJobId} supplierId={poSearchFields.poSearchSupplierId} suppliers={suppliers} showModal={showFindPurchaseOrder} hideModal={hideFindPurchaseOrder} saveModal={saveFindPurchaseOrder} />
    let poReplace = <SupplierInvoicePoReplacePopup invoiceId={invoiceId} invoiceTotal={invoiceTotal} jobId={poSearchFields.poSearchJobId} showModal={showReplacePurchaseOrder} hideModal={hideReplacePoNumber} saveModal={saveReplacePoNumber} />
    let xeroContactSearch = <SupplierInvoiceXeroContactPopup invoiceId={invoiceId} showModal={showFindXeroContact} hideModal={hideFindXeroContact} saveModal={saveFindXeroContact} addNewContact={addNewXeroContact} />
    let supplierSearch = <SupplierInvoiceSupplierPopup showModal={showFindSupplier} supplierInvoiceId={invoiceId} suppliers={suppliers} hide={hideSupplier} save={saveSupplier} />
    let confirmPopup = <ConfirmModal heading="Cancel Supplier Invoice" text="Are you sure you want to cancel this invoice?" hideConfirmModal={hideCancelConfirmModal} showConfirmModal={showCancelConfirmModal} noConfirmModal={hideCancelConfirmModal} yesConfirmModal={saveCancelConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmPopupNotes = <ConfirmModalWithNoteData showConfirmModal={showApproveModal} heading={"Approve Invoice"} text="Are you sure you want to approve this invoice?" notesText="Enter reason why invoice total > purchase order total" notes={approveData.approveNotes} updateNotes={updateApproveNotes} hideConfirmModal={hideApproveConfirmModal} noConfirmModal={hideApproveConfirmModal} yesConfirmModal={saveApprove} saveDisabled={modalSaveDisabled} />
    let addXeroContact = <AddXeroContact supplierId={supplierId} supplierInvoiceId={invoiceId} showModal={showAddXeroContact} hideModal={hideAddXeroContact} saveModal={saveAddXeroContact} />
    let makeSafeButtonText = createMakeSafeJobs ? "Cancel MakeSafe" : "New MakeSafe Jobs";

    const renderDetails = (
        <div>
            <div className="static-modal">
                {invoiceFile}
                {poSearch}
                {poReplace}
                {xeroContactSearch}
                {confirmPopup}
                {confirmPopupNotes}
                {addXeroContact}
                {supplierSearch}
            </div>

            <form onSubmit={uploadInvoice}>
                <hr></hr>
                <div>
                    <a className={showImport ? "hidden" : "mobile-icon-grey makeitflexcenter"} href="#" onClick={(e) => showImportSection(e)}>
                        <span className="fas fa-plus-circle supplier-import__icon--size marginRight10"></span>Show Invoice Import
                    </a>
                    <a className={showImport ? "mobile-icon-grey makeitflexcenter" : "hidden"} href="#" onClick={(e) => showImportSection(e)}>
                        <span className="fas fa-times-circle supplier-import__icon--size marginRight10"></span>Hide Invoice Import
                    </a>
                </div>
                <div className={showImport ? "" : "hidden"}>
                    <div className="marginTop10">
                        <h3>Import Supplier Invoices</h3>
                        <span>1. Click the "Choose Files" button to select the supplier invoices to upload and send to Xero.</span><br></br>
                        <span>2. Click the Save button to upload the supplier invoices</span><br></br>
                        <span>3. Fix up the data for any invoices that were successfully imported</span><br></br>
                        <span>4. Invoices MUST have a Job Id and Xero Contact added before being sent to Xero (click the + button on the invoice)</span><br></br>
                        <span>5. Click the "Save" button to save your changes</span><br></br>
                        <span>6. Select the invoices to send to Xero by ticking the checkbox and then click the "Send to Xero" button</span><br></br>
                        <span>7. Any invoices that could not be uploaded will appear in a 2nd list, you will need to add these to Xero manually</span><br></br>
                    </div>
                    <div className="marginTop10">
                        <label htmlFor="files">Select supplier invoices:</label>
                        <input type="file" className="marginBottom10" id="files" name="files" accept="application/pdf" onChange={handleImportFile} multiple />
                    </div>
                    <button className="defaultbutton marginTop10" type="submit" disabled={uploadDisabled}>Import</button>
                </div>
                <hr></hr>
            </form>

            <div>
                <div className="marginTop10">
                    <a className={showSearch ? "hidden" : "mobile-icon-grey makeitflexcenter"} href="#" onClick={(e) => showSearchSection(e)}>
                        <span className="fas fa-plus-circle supplier-import__icon--size marginRight10"></span>Show Invoice Search
                    </a>
                    <a className={showSearch ? "mobile-icon-grey makeitflexcenter" : "hidden"} href="#" onClick={(e) => showSearchSection(e)}>
                        <span className="fas fa-times-circle supplier-import__icon--size marginRight10"></span>Hide Invoice Search
                    </a>
                </div>
                <div className={showSearch ? "marginTop10" : "hidden"}>
                    <h3>Search Supplier Invoices</h3>
                    <div className="input-group-parent3">
                        <label className="input-group" htmlFor="invNumber">
                            <span className="label-small">Ref # / Inv #</span>
                            <input className='input' type='text' id="invNumber" name="invNumber" value={searchFields.invNumber} onChange={(e) => handleSearchChange(e)}></input>
                        </label>
                        <label className="input-group" htmlFor="poNumber">
                            <span className="label-small">PO #</span>
                            <input className='input' type='text' id="poNumber" name="poNumber" value={searchFields.poNumber} onChange={(e) => handleSearchChange(e)}></input>
                        </label>
                    </div>
                    <div className="input-group-parent3">
                        <label className="input-group" htmlFor="supplierId">
                            <span className="label-small">Supplier</span>
                            <select className="select" id="supplierId" name="supplierId" value={searchFields.supplierId} onChange={(e) => handleSearchChange(e)}>
                                <option defaultValue="-1" value="-1"></option>
                                {suppliers.map(sup =>
                                    <option key={sup.id} value={sup.id}>{sup.name}</option>
                                )};
                            </select>
                        </label>
                    </div>
                    <div className="input-group-parent3">
                        <label className="input-group" htmlFor="jobId">
                            <span className="label-small">Job Id</span>
                            <input type='text' id="jobId" name="jobId" className='input' value={searchFields.jobId} onChange={(e) => handleSearchChange(e)}></input>
                        </label>
                        <label className="input-group" htmlFor="siteAddress">
                            <span className="label-small">Site Address</span>
                            <input type='text' id="siteAddress" name="siteAddress" className='input' value={searchFields.siteAddress} onChange={(e) => handleSearchChange(e)}></input>
                        </label>
                    </div>
                    <button className="defaultbutton-prev defaultbutton-prev__split3" type='button' onClick={search}>Search</button>
                    <button className="defaultbutton-next" type='button' onClick={reset}>Reset</button>
                </div>
                <hr></hr>
            </div>

            <div className="marginTop10">
                <label className="input-group" htmlFor="batchId">
                    <span className="label">Select Batch</span>
                    <select className="select supplier-importbatch--width marginRight10" id="batchId" name="batchId" value={batchData.batchId} onChange={(e) => handleBatchChange(e)}>
                        <option defaultValue="0" value="0"></option>
                        {batches.map(batch =>
                            <option key={batch.batchId} value={batch.batchId}>{batch.batchId}</option>
                        )};
                    </select>
                    <span>{batchData.batchDate}</span>
                </label>
            </div>

            <div className={showSupplierInvoices ? "marginTop10" : "hidden"}>
                <h3>Invoices that were successfully imported</h3>

                <div className="makeitflexspacebetween">
                    <div>
                        <div>1. Any jobs that are missing a Job Id and/or Xero Contact will need to be updated (click + button to search and add) before sending the invoice to Xero</div>
                        <div>2. Check the data to make sure it has imported correctly, and update if necessary before importing to Xero</div>
                        <div>3. Once the data is correct (and a Job and Xero contact added) tick the checkbox and click the Send To Xero button</div>
                        <div className="supplier-invoice--duplicate">4. Items with red background have a duplicate invoice number</div>
                    </div>
                    <div>
                        <button className="defaultbutton defaultbutton__medium" type="button" onClick={createMakeSafeJob}>{makeSafeButtonText}</button>
                    </div>
                </div>
                <div className={createMakeSafeJobs ? "hidden" : ""}>
                    <div className="table__margin overflowAuto">
                        <table className="table--main tableColours supplier-import__font--size">
                            <thead>
                                <tr>
                                    <th className="hidden">Id</th>
                                    <th></th>
                                    <th>
                                        <input name="selectall" type='checkbox' className="checkbox" checked={selectAll} onChange={(e) => handleSelectAllChange(e)} />
                                    </th>
                                    <th>Status</th>
                                    <th>Reference</th>
                                    <th>Job Id</th>
                                    <th>Site Address</th>
                                    <th>MS
                                        <input name="selectallMakeSafe" type='checkbox' className="checkbox" checked={selectAllMakeSafe} onChange={(e) => handleSelectAllMakeSafeChange(e)} />
                                    </th>
                                    <th>PO #</th>
                                    <th>Supplier</th>
                                    <th>Xero Contact</th>
                                    <th>Inv Date</th>
                                    <th>Due Date</th>
                                    <th>PO SubTotal</th>
                                    <th>Inv SubTotal</th>
                                    <th>PO Gst</th>
                                    <th>Inv Gst</th>
                                    <th>PO Total</th>
                                    <th>Inv Total</th>
                                    <th>Approve</th>
                                    <th></th>
                                    <th>Cancel</th>
                                </tr>
                            </thead>
                            <tbody>
                                {importedInvoices.map((invoice, index) =>
                                    <tr key={index} className={invoice.duplicateInvoice && invoice.statusId != 5 ? "supplier-invoice--duplicate" : ""}>
                                        <td className="hidden">{invoice.id}</td>
                                        <td className="table__text--align">{invoice.itemIndex + 1}</td>
                                        <td className="table__text--align">
                                            <input name="selected" type='checkbox' className="checkbox" checked={invoice.selected} onChange={(e) => handleCellCheckChange(e, index)} disabled={invoice.statusId != 2} />
                                        </td>
                                        <td className="table__text--align">{invoice.status}</td>
                                        <td className="table__text--align">
                                            <input type='text' className="form-control supplier-import__text--size" name="invoiceNumber" value={invoice.invoiceNumber} onChange={(e) => handleCellChange(e, index)} disabled={invoice.statusId > 2} />
                                        </td>
                                        <td className="table__text--align">
                                            <div className={invoice.jobId > 0 || invoice.statusId > 2 ? "hidden" : "delete--tablecell"}>
                                                <a href="#" onClick={(e) => findPurchaseOrder(e, invoice.id, invoice.invoiceTotal, invoice.jobId, invoice.supplierId)}>
                                                    <span className="fas fa-plus-square edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                            {invoice.jobId > 0 ? invoice.jobId : ""}
                                            <div className={invoice.jobId === 0 || invoice.statusId > 2 ? "hidden" : "delete--tablecell"}>
                                                <a href="#" title="Clear job & purchase order" onClick={(e) => clearJobPurchaseOrder(e, invoice.id)}>
                                                    <span className="fas fa-minus-square makeitred edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="table__text--align">{invoice.siteAddress}</td>
                                        <td className="table__text--align">
                                            <input name="makeSafe" type='checkbox' className="checkbox" checked={invoice.makeSafe} onChange={(e) => handleCellCheckChange(e, index)} disabled={invoice.statusId > 1} />
                                        </td>
                                        <td className="table__text--align">
                                            <div className={invoice.purchaseOrderNumber > 0 || invoice.statusId > 2 || invoice.makeSafe ? "hidden" : "delete--tablecell"}>
                                                <a href="#" onClick={(e) => findPurchaseOrder(e, invoice.id, invoice.invoiceTotal, invoice.jobId, invoice.supplierId)}>
                                                    <span className="fas fa-plus-square edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                            {invoice.purchaseOrderNumber == 0 ? invoice.purchaseOrderNumberText : ""}

                                            <a className={invoice.purchaseOrderNumber === 0 || invoice.jobId === 0 ? "hidden" : ""} href={"/job/" + invoice.jobId + "/costings/" + invoice.purchaseOrderNumber} target="_blank" title="View purchase order">{invoice.purchaseOrderNumbers}</a>

                                            <div className={invoice.purchaseOrderNumber === 0 || invoice.statusId > 2 || invoice.makeSafe ? "hidden" : "delete--tablecell"}>
                                                <a href="#" title="Clear purchase order" onClick={(e) => clearPurchaseOrder(e, invoice.id)}>
                                                    <span className="fas fa-minus-square makeitred edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                            <div className={invoice.purchaseOrderNumber > 0 && (invoice.statusId === 3 || invoice.statusId === 4) ? "delete--tablecell" : "hidden"}>
                                                <a href="#" title="Replace purchase order" onClick={(e) => replacePoNumber(e, invoice.id, invoice.invoiceTotal, invoice.jobId)}>
                                                    <i className="fa-solid fa-arrows-rotate"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="table__text--align">
                                            <div className={!invoice.supplier ? "delete--tablecell" : "hidden"}>
                                                <a href="#" onClick={(e) => findSupplier(e, invoice.id, index)}>
                                                    <span className="fas fa-plus-square edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                            {invoice.supplier}
                                        </td>
                                        <td className="table__text--align">
                                            <div className={invoice.xeroContactId || invoice.statusId > 2 ? "hidden" : "delete--tablecell"}>
                                                <a href="#" onClick={(e) => findXeroContact(e, invoice.id)}>
                                                    <span className="fas fa-plus-square edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                            {invoice.xeroContactId ? invoice.xeroContactName : ""}
                                        </td>
                                        <td className="table__text--align">
                                            <input name="invoiceDate" type='date' className="form-control supplier-import__date--size" value={invoice.invoiceDate} onChange={(e) => handleCellChange(e, index)} disabled={invoice.statusId > 2} />
                                        </td>
                                        <td className="table__text--align">
                                            <input name="dueDate" type='date' className="form-control supplier-import__date--size" value={invoice.dueDate} onChange={(e) => handleCellChange(e, index)} disabled={invoice.statusId > 2} />
                                        </td>
                                        <td className="table__text--align">
                                            {invoice.poSubTotalFormatted}<br></br>
                                            <span className="makeitgreen makeitbold">{invoice.poSubTotalRemainingFormatted}</span>
                                        </td>
                                        <td className="table__text--align">
                                            <input type='number' min="0" step="any" className={`form-control supplier-import__text--size textalignright ${((((invoice.invoiceSubTotal > invoice.poSubTotal) && invoice.poSubTotal > 0) || invoice.jobId === 0 || invoice.invoiceSubTotal === 0) && !invoice.approved && (invoice.statusId === 1 || invoice.statusId === 2)) ? 'makeitred' : ''}`} name="invoiceSubTotal" value={invoice.invoiceSubTotal} onChange={(e) => handleCellChange(e, index)} disabled={invoice.statusId > 2 || invoice.approved} />
                                        </td>
                                        <td className="table__text--align">
                                            {invoice.poGstFormatted}<br></br>
                                                <span className="makeitgreen makeitbold">{invoice.poGstRemainingFormatted}</span>
                                        </td>
                                        <td className="table__text--align">
                                            <input type='number' min="0" step="any" className={`form-control supplier-import__text--size textalignright ${((((invoice.invoiceGst > invoice.poGst) && invoice.poGst > 0) || invoice.jobId === 0 || (invoice.invoiceGst === 0 && invoice.supplierRegisteredForGst)) && !invoice.approved && (invoice.statusId === 1 || invoice.statusId === 2)) ? 'makeitred' : ''}`} name="invoiceGst" value={invoice.invoiceGst} onChange={(e) => handleCellChange(e, index)} disabled={invoice.statusId > 2 || invoice.approved} />
                                        </td>
                                        <td className="table__text--align">
                                            {invoice.poTotalFormatted}<br></br>
                                            <span className="makeitgreen makeitbold">{invoice.poTotalRemainingFormatted}</span>
                                        </td>
                                        <td className="table__text--align">
                                            <input type='number' min="0" step="any" className={`form-control supplier-import__text--size textalignright ${((((invoice.invoiceTotal > invoice.poTotal) && invoice.poTotal > 0) || invoice.jobId === 0 || invoice.invoiceTotal === 0) && !invoice.approved && (invoice.statusId === 1 || invoice.statusId === 2)) ? 'makeitred' : ''}`} name="invoiceTotal" value={invoice.invoiceTotal} onChange={(e) => handleCellChange(e, index)} disabled={invoice.statusId > 2 || invoice.approved} />
                                        </td>
                                        <td className="table__text--align"><button className={(invoice.invoiceTotal > invoice.poTotalRemaining) && !invoice.approved && invoice.purchaseOrderNumber > 0 && canApproveSupplierInvoices ? "jobInvoice__approve" : "hidden"} onClick={(e) => approve(index, e)}>Approve</button></td>
                                        <td className="table__text--align">
                                            <div className="delete--tablecell">
                                                <a href="#" onClick={(e) => showInvoiceFile(e, invoice.id)}>
                                                    <span className="fas fa-file-image edit--icon alignIconCenter file__photoColour"></span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="table__text--align">
                                            <div className={invoice.statusId > 3 ? "hidden" : "delete--tablecell"}>
                                                <a className="makeitred" href="#" onClick={(e) => cancelInvoice(e, index)}>
                                                    <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {errors["invoices"] ?
                            (errors["invoices"]).split("\n").map((item: any, key: any) => {
                                return <span className="label errors" key={key}>{item}<br /></span>
                            })
                            : ""}

                        {errors["invoicewarning"] ?
                            (errors["invoicewarning"]).split("\n").map((item: any, key: any) => {
                                return <span className="label warning" key={key}>{item}<br /></span>
                            })
                            : ""}
                    </div>
                    <div className="makeitflexspacebetween">
                        <div>
                            <button className="defaultbutton defaultbutton__medium" type="button" onClick={saveInvoice}>Save</button>
                            <button className="defaultbutton defaultbutton__medium marginLeft10" type="button" onClick={sendInvoice}>Send To Xero</button>
                        </div>
                        <div>
                            <label className="input-group estimate__alignTotals" htmlFor="importedInvoicesTotal">
                                <span className="label textalignright">Total</span>
                                <input className="input estimate__totalsWidth textalignright" type="text" id="importedInvoicesTotal" name="importedInvoicesTotal" value={totals.importedInvoicesTotalFormatted} disabled></input>
                            </label>
                            <label className="input-group estimate__alignTotals" htmlFor="importedInvoicesSentTotal">
                                <span className="label textalignright">Total Sent</span>
                                <input className="input estimate__totalsWidth textalignright" type="text" id="importedInvoicesSentTotal" name="importedInvoicesSentTotal" value={totals.importedInvoicesSentTotalFormatted} disabled></input>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={createMakeSafeJobs ? "" : "hidden"}>
                    <div className="table__margin overflowAuto">
                        <table className="table--main tableColours supplier-import__font--size">
                            <thead>
                                <tr>
                                    <th className="hidden">Id</th>
                                    <th></th>
                                    <th></th>
                                    <th>Supplier</th>
                                    <th>Site Address</th>
                                    <th>Customer</th>
                                    <th>Sub Type</th>
                                    <th>Insurer</th>
                                    <th>Insurer Contact</th>
                                    <th>Claim #</th>
                                    <th>Estimator</th>
                                    <th>Supervisor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {makeSafeJobs.map((invoice, index) =>
                                    <tr key={index}>
                                        <td className="hidden">{invoice.supplierInvoiceId}</td>
                                        <td className="table__text--align">{index + 1}</td>
                                        <td className="table__text--align">
                                            <div className="delete--tablecell">
                                                <a href="#" onClick={(e) => showInvoiceFile(e, invoice.supplierInvoiceId)}>
                                                    <span className="fas fa-file-image edit--icon alignIconCenter file__photoColour"></span>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="table__text--align">
                                            <select className="select" id="supplierId" name="supplierId" value={invoice.supplierId} onChange={(e) => handleMSCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {suppliers.map(supplier =>
                                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <input className='input' type='text' maxLength={500} id="siteAddress" name="siteAddress" value={invoice.siteAddress} onChange={(e) => handleMSCellChange(e, index)}></input>
                                        </td>
                                        <td className="table__text--align">
                                            <input className='input' type='text' maxLength={100} id="customerName" name="customerName" value={invoice.customerName} onChange={(e) => handleMSCellChange(e, index)}></input>
                                        </td>
                                        <td className="table__text--align">
                                            <select className="select" id="subTypeId" name="subTypeId" value={invoice.subTypeId} onChange={(e) => handleMSCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {jobSubTypes.map(subType =>
                                                    <option key={subType.id} value={subType.id}>{subType.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <select className="select" id="insurerId" name="insurerId" value={invoice.insurerId} onChange={(e) => handleMSCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {insurers.map(insurer =>
                                                    <option key={insurer.id} value={insurer.id}>{insurer.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <select className="select" id="insurerContactId" name="insurerContactId" value={invoice.insurerContactId} onChange={(e) => handleMSCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {insurers.find(i => i.id === (invoice.insurerId * 1))?.contacts.map(insurerContact =>
                                                    <option key={insurerContact.id} value={insurerContact.id}>{insurerContact.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <input type="text" maxLength={20} className="form-control tablerow__textmedium" name="claimNumber" value={invoice.claimNumber} onChange={(e) => handleMSCellChange(e, index)} />
                                        </td>
                                        <td className="table__text--align">
                                            <select className="select" id="estimatorId" name="estimatorId" value={invoice.estimatorId} onChange={(e) => handleMSCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {estimators.map(estimator =>
                                                    <option key={estimator.id} value={estimator.id}>{estimator.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <select className="select" id="supervisorId" name="supervisorId" value={invoice.supervisorId} onChange={(e) => handleMSCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {supervisors.map(supervisor =>
                                                    <option key={supervisor.id} value={supervisor.id}>{supervisor.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <div className="delete--tablecell">
                                                <a className="makeitred" href="#" onClick={(e) => removeInvoice(e, index)}>
                                                    <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {errors["makesafejobs"] ?
                            (errors["makesafejobs"]).split("\n").map((item: any, key: any) => {
                                return <span className="label errors" key={key}>{item}<br /></span>
                            })
                            : ""}
                    </div>
                    <div>
                        <button className="defaultbutton defaultbutton__medium" type="button" onClick={saveMakeSafeJobs}>Create Jobs</button>
                    </div>
                </div>
            </div>

            <div className={failedInvoices.length === 0 ? "hidden" : "marginTop20"}>
                <h3>Invoices that could not be imported</h3>
                <div>You will need to add these invoices into Xero manually</div>
                <div className="table__margin overflowAuto">
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th>Filename</th>
                                <th>Error Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {failedInvoices.map((invoice, index) =>
                                <tr key={index}>
                                    <td className="hidden">{invoice.id}</td>
                                    <td className="table__text--align">{invoice.fileName}</td>
                                    <td className="table__text--align">{invoice.errorMessage}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )
}

export default SupplierInvoicesData;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface SupplierInvoiceSupplierProps {
    supplierInvoiceId: number;
    suppliers: GenericListItem[];
    showModal: boolean;
    hide(): void;
    save(supplierId: number, supplierName: string, duplicateInvoice: boolean): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

const SupplierInvoiceSupplierPopup = (props: SupplierInvoiceSupplierProps) => {
    const [supplierId, setSupplierId] = React.useState(-1);

    const handleSupplierIdChange = (e: any) => {
        setSupplierId(e.target.value * 1);
    }

    const save = () => {
        if (supplierId > 0) {
            saveInvoiceSupplier();
        } else {
            toast.error("Please select a supplier to save to invoice");
        }
    }

    const saveInvoiceSupplier = async () => {
        const user = await authService.getUser();

        var assignSupplier = {
            invoiceId: props.supplierInvoiceId,
            supplierId: supplierId,
            subId: user.sub
        };

        //cancel invoice
        const token = await authService.getAccessToken();
        axios.post('Suppliers/InvoiceAssignSupplier', assignSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                props.save(supplierId, res.data.supplierName, res.data.duplicateInvoice);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideModal = () => {
        setSupplierId(-1);
        props.hide();
    }

    return (
        <Modal size="sm" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add Supplier</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <label className="input-group" htmlFor="supplierId">
                        <span className="label">Supplier</span>
                        <select className="select" id="supplierId" name="supplierId" value={supplierId} onChange={(e) => handleSupplierIdChange(e)} >
                            <option defaultValue="-1"></option>
                            {props.suppliers.map(supplier =>
                                <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                            )}
                        </select>
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={save}>Save</button>
            </Modal.Footer>
        </Modal>
    );
    
}

export default SupplierInvoiceSupplierPopup;
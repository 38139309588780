import * as React from 'react';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ReportMakeSafeJobsData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunReports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                setLoading(false);
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data 
        var url = "/report/MakeSafeJobs/-1/-1/-1";
        window.open(url, '_blank');
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        exportToExcel();
    }

    const exportToExcel = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios('Report/ExportMakeSafeJobsData?subId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'MakeSafe Jobs.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Make Safe Jobs</h1>
            </div>
            <div className="marginTop20 marginBottom10">
                <span>Displays all In Progress Make Safe jobs.</span>
            </div>
            <div>
                <button className="defaultbutton-prev" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
        </div>
    );
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportMakeSafeJobsData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import PhotoImportSinglePopup from './PhotoImportSingle';

interface EstimateDetailMobileProps {
    jobId: number;
    headerId: number;
    headerText: string;
    changePage(pageData: PageChangeData): void;
}

interface EstimateDetails {
    id: number;
    headerId: number;
    scope: string;
    scopeOrder: number;
    costCode: string;
    quantityUom: string;
    pcPs: string;
    unitCostFormatted: string;
    subTotalFormatted: string;
    markUpPercentageFormatted: string;
    totalFormatted: string;
    hasPhoto: boolean;
}

interface AppFile {
    id: number;
    fileType: string;
    content: any;
}

interface PageChangeData {
    page: string;
    headerId: number;
    headerText: string;
}

const EstimateDetailMobileData = (props: EstimateDetailMobileProps) => {
    const [loading, setLoading] = React.useState(true);
    const [estimateDetails, setEstimateDetails] = React.useState<EstimateDetails[]>([]);
    const [estimateTotals, setEstimateTotals] = React.useState({ subTotalFormatted: "$0.00", subTotalExGstFormatted: "$0.00" });
    const [photoUpload, setPhotoUpload] = React.useState({ showUploadPhotoModal: false, estimateDetailId: 0, uploadPhotoSaveDisabled: false });
    const [selectedFile, setSelectedFile] = React.useState<File>(new File([""], "filename"));
    const [uploadedPhoto, setUploadedPhoto] = React.useState<AppFile>({ id: 0, fileType: "", content: [] });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async() => {
        //get estimate details
        const token = await authService.getAccessToken();

        //get user roles and status
        axios.get('Estimate/GetEstimateDetailsForMobile?HeaderId=' + props.headerId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setEstimateDetails(res.data.estimateDetails);
            setEstimateTotals({ subTotalFormatted: res.data.subTotalFormatted, subTotalExGstFormatted: res.data.subTotalExGstFormatted });
            setLoading(false);
        });
    }

    const back = (e: any) => {
        e.preventDefault();

        var pageChange: PageChangeData = {
            page: "header",
            headerId: 0,
            headerText: "",
        };

        props.changePage(pageChange);
    }

    //upload photo 
    const uploadPhoto = (detailId: number, e: any) => {
        uploadPhotoItem(detailId, e);
    }

    const uploadPhotoItem = async(detailId: number, e: any) => {
        e.preventDefault();
        const token = await authService.getAccessToken();

        //check if there is already a photo and show it
        axios.get('Estimate/GetEstimateDetailPhoto?DetailId=' + detailId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setPhotoUpload({ showUploadPhotoModal: true, estimateDetailId: detailId, uploadPhotoSaveDisabled: false });
            setSelectedFile(new File([""], "filename"));
            setUploadedPhoto(res.data);
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    const uploadPhotoHide = () => {
        setSelectedFile(new File([""], "filename"));
        setPhotoUpload({ showUploadPhotoModal: false, estimateDetailId: 0, uploadPhotoSaveDisabled: false });
    }

    const uploadPhotoUpdate = (selectedFile: File) => {
        setSelectedFile(selectedFile);
    }

    const uploadPhotoDelete = () => {
        deleteUploadedPhoto();
    }

    const deleteUploadedPhoto = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var photoDelete = {
            jobId: props.jobId,
            detailId: photoUpload.estimateDetailId,
            subId: user.sub
        };

        axios.post('Estimate/DeleteEstimateDetailPhoto', photoDelete, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });
                } else {
                    var detaillines = [...estimateDetails];
                    var detailline = detaillines.filter(x => x.id === photoUpload.estimateDetailId);
                    if (detailline.length > 0) {
                        detailline[0].hasPhoto = false;
                    }

                    setEstimateDetails(detaillines);
                    setPhotoUpload({ showUploadPhotoModal: false, estimateDetailId: 0, uploadPhotoSaveDisabled: false });
                    setSelectedFile(new File([""], "filename"));

                    toast.success("Photo has been deleted");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    const uploadPhotosDisableSave = () => {
        var showUpload = photoUpload.showUploadPhotoModal;
        var estDetailId = photoUpload.estimateDetailId;
        setPhotoUpload({ showUploadPhotoModal: showUpload, estimateDetailId: estDetailId, uploadPhotoSaveDisabled: true });
    }

    const uploadPhotoSave = () => {
        uploadPhotosDisableSave();
        saveUploadedPhoto();
    }

    const saveUploadedPhoto = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        const data = new FormData();
        data.append('jobId', props.jobId.toString());
        data.append('detailId', photoUpload.estimateDetailId.toString());
        data.append('photo', selectedFile);
        data.append('subId', user.sub);

        axios.post('Estimate/SaveEstimateDetailPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var detaillines = [...estimateDetails];
                var detailline = detaillines.filter(x => x.id === photoUpload.estimateDetailId);
                if (detailline.length > 0) {
                    detailline[0].hasPhoto = true;
                }
                setEstimateDetails(detaillines);
                setPhotoUpload({ showUploadPhotoModal: false, estimateDetailId: 0, uploadPhotoSaveDisabled: false });

                toast.success("Photo has been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end upload photo

    let photoImport = <PhotoImportSinglePopup heading="Upload Photo" text="Search and select a photo to upload." fileType="Photo" selectedFile={selectedFile} uploadedPhoto={uploadedPhoto} showModal={photoUpload.showUploadPhotoModal} hideModal={uploadPhotoHide} update={uploadPhotoUpdate} delete={uploadPhotoDelete} save={uploadPhotoSave} saveDisabled={photoUpload.uploadPhotoSaveDisabled} />

    const renderDetails = (
        <div className="marginTop10 marginBottom70">
            <div className="static-modal">
                {photoImport}
            </div>
            <div className="estimate__backButton">
                <h3>{props.headerText}</h3>
                <a className="mobile-icon-grey" href="#" onClick={(e) => back(e)}>
                    <span className="fas fa-arrow-alt-circle-left mobileMenuSize alignIconCenter"></span>
                </a>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th></th>
                            <th>Scope</th>
                            <th>Code / PC/PS / Qty</th>
                            <th className="textalignright">Unit / Sub</th>
                            <th className="textalignright">MU % / Total</th>
                            <th>Photo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {estimateDetails.map((detail, index) =>
                            <tr key={index}>
                                <td className="hidden">{detail.id}</td>
                                <td className="table__text--align">{detail.scopeOrder == 0 ? index + 1 : detail.scopeOrder}</td>
                                <td className="table__text--align">
                                    {detail.scope}
                                </td>
                                <td className="table__text--align table--cell--nowrap">
                                    {detail.costCode}<br></br>
                                    <span>{detail.quantityUom}</span><br></br>
                                    {detail.pcPs}
                                </td>
                                <td className="table__text--align textalignright">
                                    {detail.unitCostFormatted}<br></br>
                                    {detail.subTotalFormatted}
                                </td>
                                <td className="table__text--align textalignright">
                                    {detail.markUpPercentageFormatted}<br></br>
                                    {detail.totalFormatted}
                                </td>
                                <td className="table__text--align">
                                    <div className="delete--tablecell">
                                        <a className={detail.hasPhoto ? "file__photoColour" : ""} href="#" onClick={(e) => uploadPhoto(detail.id, e)}>
                                            <span className="fas fa-file-image edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mobileFont defaultbutton__container--right">
                <div>
                    <label className="input-group labeltextalignright" htmlFor="subTotalFormatted">
                        <span className="label label--padding-small label-mobile">Sub Total</span>
                        <span className="estimate-mobile-totals">{estimateTotals.subTotalFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright makeitbold" htmlFor="subTotalExGstFormatted">
                        <span className="label label--padding-small label-mobile">Sub Total ex GST</span>
                        <span className="estimate-mobile-totals">{estimateTotals.subTotalExGstFormatted}</span>
                    </label>
                </div>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default EstimateDetailMobileData;
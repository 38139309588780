import * as React from 'react';
import GoogleAutoComplete from './GoogleAutoComplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface ClientProps {
    clientDetails: ClientDetails;
    statusOptions: GenericListItem[];
    types: GenericListItem[];
    terms: GenericListItem[];
    update(clientDetails: ClientDetails): void;
    save(sendToXero: boolean): void;
    sendToXero(): void;
}

interface XeroListItem {
    id: string;
    name: string;
    totalFormatted: string;
    selected: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface ClientDetails {
    id: number;
    xeroContactId: string;
    name: string;
    addressManual: boolean;
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    registeredForGst: boolean;
    abn: string;
    typeId: number;
    phone: string;
    termId: number;
    useOpus: boolean;
    maxMarginPercent: number;
    status: number;
    lockAccount: boolean;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const ClientDetail = (props: ClientProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [buttonDisabled, setButtonDisabled] = React.useState({ sendDisabled: false, saveDisabled: false });
    const [xeroNameSearch, setXeroNameSearch] = React.useState("");
    const [xeroCustomers, setXeroCustomers] = React.useState<XeroListItem[]>([]);
    
    //XERO CUSTOMER SEARCH
    const handleStateChange = (e: any) => {
        setXeroNameSearch(e.target.value);
        setButtonDisabled({ saveDisabled: false, sendDisabled: false });
    }

    const handleCellCheckboxChange = (index: number, e: any) => {
        //update item that has changed
        var xeroCustomerList = [...xeroCustomers];

        //if selected checked then set all other customers to false
        if (e.target.checked) {
            for (var i = 0; i < xeroCustomerList.length; i++) {
                xeroCustomerList[i].selected = false;
            }
            setButtonDisabled({ saveDisabled: false, sendDisabled: true });
        } else {
            setButtonDisabled({ saveDisabled: false, sendDisabled: false });
        }

        var xeroCustomer = xeroCustomerList[index];
        xeroCustomer.selected = e.target.checked;
        setXeroCustomers(xeroCustomerList);
    }
    //END XERO CUSTOMER SEARCH

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        var error = errors;
        if (e.target.value) {
            delete error[e.target.name];
            setErrors(error);
        }

        setButtonDisabled({ saveDisabled: false, sendDisabled: false });

        var client = props.clientDetails;
        let newClient: any = {};
        newClient = client;
        newClient[e.target.name] = e.target.value;

        props.update(newClient);
    }

    const handleCheckboxChange = (e: any) => {
        var client = props.clientDetails;
        let newClient: any = {};
        newClient = client;
        newClient[e.target.name] = e.target.checked;

        setButtonDisabled({ saveDisabled: false, sendDisabled: false });

        props.update(newClient);
    }

    //update google address
    const setClientAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["address"];
        setErrors(error);

        setButtonDisabled({ saveDisabled: false, sendDisabled: false });
        updateAddress(addressDetails);
    }

    //update google address
    const updateAddress = (addressDetails: AddressDetails) => {
        var client = props.clientDetails;

        client.address = addressDetails.fullAddress;
        client.streetNumber = addressDetails.street_number;
        client.streetName = addressDetails.route;
        client.suburb = addressDetails.locality;
        client.state = addressDetails.administrative_area_level_1;
        client.country = addressDetails.country;
        client.postCode = addressDetails.postal_code;
        client.googlePlaceId = addressDetails.placeId;
        client.siteAddressLatitude = addressDetails.latitude;
        client.siteAddressLongitude = addressDetails.longitude;

        props.update(client);
    }

    const searchXeroCustomers = (e: any) => {
        e.preventDefault();
        searchXeroClientCustomers();
    }

    const searchXeroClientCustomers = async() => {
        if (xeroNameSearch) {
            //check if we need to check Xero Auth
            //check authentication
            //enter the page that xero needs to return to, id blank as not used via invoicing
            var page = {
                page: "client",
                id: props.clientDetails.id
            };
            //encode to base 64 so can retrieve it later
            var encoded = btoa(JSON.stringify(page));

            const user = await authService.getUser();
            const token = await authService.getAccessToken();

            axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var isAuth = res.data.isAuthorised;
                var xeroLoginUrl = res.data.xeroLoginUrl;

                if (isAuth) {
                    //if authorised
                    axios.get('Xero/GetCustomersByName?SubId=' + user.sub + '&CustomerName=' + xeroNameSearch, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                    .then(res => {
                        var customers = res.data;
                        var xeroCustomersList = [...xeroCustomers];
                        for (var i = 0; i < customers.length; i++) {
                            var customer = customers[i];
                            customer.selected = false;
                            xeroCustomersList.push(customer);
                        }

                        setXeroCustomers(xeroCustomersList);

                        if (customers.length === 0) {
                            toast.info("There were no Xero customers that matched your search.  Note: Xero search is case sensitive!");
                        }
                    })
                    .catch(error => {
                        toast.error(error.message);
                    });
                } else {
                    window.location.replace(xeroLoginUrl)
                }
            }); 
        } else {
            let error: any = {};
            error["xeroNameSearch"] = "Enter name to search";
            setErrors(error);
        }
    }

    const validate = () => {
        let fields = props.clientDetails;
        let error: any = {};
        let formIsValid = true;

        if (!fields.name) {
            formIsValid = false;
            error["name"] = "Name is required";
        }

        if (fields.addressManual) {
            if (!fields.streetName) {
                formIsValid = false;
                error["streetName"] = "Street Name is required";
            }

            if (!fields.suburb) {
                formIsValid = false;
                error["suburb"] = "Suburb is required";
            }

            if (!fields.state) {
                formIsValid = false;
                error["state"] = "State is required";
            }

            if (!fields.postCode) {
                formIsValid = false;
                error["postCode"] = "Post Code is required";
            }
        } else {
            if (!fields.address) {
                formIsValid = false;
                error["address"] = "Address is required";
            }
        }

        if (fields.registeredForGst === true && !fields.abn) {
            formIsValid = false;
            error["abn"] = "ABN is required";
        }
        if (fields.typeId < 0) {
            formIsValid = false;
            error["typeId"] = "Client Type is required";
        }
        if (fields.termId < 0) {
            formIsValid = false;
            error["termId"] = "Terms are required";
        }
        if (fields.status < 0) {
            formIsValid = false;
            error["status"] = "Status is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (buttonDisabled.saveDisabled) {
                return;
            }
            setButtonDisabled({ saveDisabled: true, sendDisabled: false });

            var xeroContacts = xeroCustomers.filter(x => x.selected);
            if (xeroContacts.length > 0) {
                //need to update the contact details with the xero contact id
                var client = props.clientDetails;
                client.xeroContactId = xeroContacts[0].id;
                props.update(client);
            }

            props.save(false);
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const sendToXero = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (buttonDisabled.sendDisabled ) {
                return;
            }
            setButtonDisabled({ saveDisabled: true, sendDisabled: true });
            
            var xeroContacts = xeroCustomers.filter(x => x.selected);
            if (xeroContacts.length > 0) {
                //need to update the contact details with the xero contact id
                var client = props.clientDetails;
                client.xeroContactId = xeroContacts[0].id;
                props.update(client);
            }

            //save and send to Xero
            props.sendToXero();
        } else {
            toast.error("Please fix the validation issues before sending");
        }
    }

    var googleClientAddress = <GoogleAutoComplete addressId="autocompleteclient" addressLabel={"Address"} placeholder={props.clientDetails.address} className="input-group" disabled={false} save={setClientAddress} />

    return (
        <form name="client" onSubmit={save}>
            <div>
                <label className="input-group" htmlFor="xeroContactId">
                    <span className="label">Xero Contact Linked</span>
                    <input className="checkbox" type="checkbox" id="xeroContactId" name="xeroContactId" checked={props.clientDetails.xeroContactId != ""} disabled></input>
                </label>
                <div className={props.clientDetails.xeroContactId ? "hidden" : ""}>
                    <label className="input-group" htmlFor="xeroNameSearch">
                        <span className="label">Xero Name Search</span>
                        <input className='input marginRight10' type='text' id="xeroNameSearch" name="xeroNameSearch" value={xeroNameSearch} onChange={(e) => handleStateChange(e)}></input>
                        <button className="defaultbutton defaultbutton__xsmall marginBottom5" type="button" onClick={searchXeroCustomers}>Search</button>
                    </label>
                    <span className={errors["xeroNameSearch"] ? "label errors errors__leftmargin" : "hidden"}>{errors["xeroNameSearch"]}</span>

                    <div className={xeroCustomers.length === 0 ? "hidden" : ""}>
                        <div className='overflowAuto client__xeroTableSize'>
                            <table className="table--main table__small tableColours">
                                <thead>
                                    <tr>
                                        <th className="hidden">Id</th>
                                        <th></th>
                                        <th>Name</th>
                                        <th className="textalignright">Outstanding</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {xeroCustomers.map((xeroCustomer, index) =>
                                        <tr key={index}>
                                            <td className="hidden">{xeroCustomer.id}</td>
                                            <td><input type="checkbox" className="input checkbox" name="selected" checked={xeroCustomer.selected} onChange={(e) => handleCellCheckboxChange(index, e)} /></td>
                                            <td>{xeroCustomer.name}</td>
                                            <td className="table__text--align textalignright">{xeroCustomer.totalFormatted}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <label className="input-group" htmlFor="name">
                    <span className="label">Name</span>
                    <input className="input" type="text" maxLength={200} id="name" name="name" value={props.clientDetails.name} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["name"] ? "label errors errors__leftmargin" : "hidden"}>{errors["name"]}</span>

                <label className="input-group" htmlFor="addressManual">
                    <span className="label">Manual Address / PO Box</span>
                    <input className="checkbox" type="checkbox" id="addressManual" name="addressManual" checked={props.clientDetails.addressManual} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <div className={props.clientDetails.addressManual ? "hidden" : ""}>
                    {googleClientAddress}
                    <span className={errors["address"] ? "label errors errors__leftmargin" : "hidden"}>{errors["address"]}</span>
                </div>
                <div className={props.clientDetails.addressManual ? "" : "hidden"}>
                    <label className="input-group" htmlFor="streetName">
                        <span className="label">Street Name</span>
                        <input className="input" type="text" maxLength={100} id="streetName" name="streetName" value={props.clientDetails.streetName} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["streetName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["streetName"]}</span>

                    <label className="input-group" htmlFor="suburb">
                        <span className="label">Suburb</span>
                        <input className="input" type="text" maxLength={100} id="suburb" name="suburb" value={props.clientDetails.suburb} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["suburb"] ? "label errors errors__leftmargin" : "hidden"}>{errors["suburb"]}</span>

                    <label className="input-group" htmlFor="state">
                        <span className="label">State</span>
                        <select className="select" id="state" name="state" value={props.clientDetails.state} onChange={(e) => handleChange(e)} >
                            <option value="VIC">VIC</option>
                            <option value="NSW">NSW</option>
                            <option value="QLD">QLD</option>
                            <option value="WA">WA</option>
                            <option value="SA">SA</option>
                            <option value="TAS">TAS</option>
                            <option value="ACT">ACT</option>
                            <option value="NT">NT</option>
                        </select>
                    </label>
                    <span className={errors["state"] ? "label errors errors__leftmargin" : "hidden"}>{errors["state"]}</span>

                    <label className="input-group" htmlFor="postCode">
                        <span className="label">Post Code</span>
                        <input className="input" type="text" maxLength={10} id="postCode" name="postCode" value={props.clientDetails.postCode} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["postCode"] ? "label errors errors__leftmargin" : "hidden"}>{errors["postCode"]}</span>
                </div>

                <label className="input-group" htmlFor="registeredForGst">
                    <span className="label">Registered For GST</span>
                    <input className="checkbox" type="checkbox" id="registeredForGst" name="registeredForGst" checked={props.clientDetails.registeredForGst} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="abn">
                    <span className="label">ABN</span>
                    <input className="input" type="text" maxLength={20} id="abn" name="abn" value={props.clientDetails.abn} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["abn"] ? "label errors errors__leftmargin" : "hidden"}>{errors["abn"]}</span>

                <label className="input-group" htmlFor="typeId">
                    <span className="label">Client Type</span>
                    <select className="select" id="typeId" name="typeId" value={props.clientDetails.typeId} disabled>
                        <option hidden defaultValue="-1"></option>
                        {props.types.map(type =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["typeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["typeId"]}</span>

                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className="input" type="text" maxLength={30} id="phone" name="phone" value={props.clientDetails.phone} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="termId">
                    <span className="label">Terms</span>
                    <select className="select" id="termId" name="termId" value={props.clientDetails.termId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {props.terms.map(term =>
                            <option key={term.id} value={term.id}>{term.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["termId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["termId"]}</span>

                <label className="input-group" htmlFor="useOpus">
                    <span className="label">Use OPUS</span>
                    <input className="checkbox" type="checkbox" id="useOpus" name="useOpus" checked={props.clientDetails.useOpus} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="maxMarginPercent">
                    <span className="label">Max Margin %</span>
                    <input className="input" type="number" min={0} step={1} id="maxMarginPercent" name="maxMarginPercent" value={props.clientDetails.maxMarginPercent} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="status">
                    <span className="label">Status</span>
                    <select className="select" id="status" name="status" value={props.clientDetails.status} onChange={(e) => handleChange(e)} disabled={props.clientDetails.id === 0 ? true : false}>
                        {props.statusOptions.map(status =>
                            <option key={status.id} value={status.id}>{status.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["status"] ? "label errors errors__leftmargin" : "hidden"}>{errors["status"]}</span>

                <label className="input-group" htmlFor="lockAccount">
                    <span className="label">Lock Account</span>
                    <input className="checkbox" type="checkbox" id="lockAccount" name="lockAccount" checked={props.clientDetails.lockAccount} onChange={(e) => handleCheckboxChange(e)}></input>
                </label>

                <button className="defaultbutton-prev defaultbutton-label" type="submit" disabled={buttonDisabled.saveDisabled}>Save</button>
                <button className="defaultbutton-next" type="button" onClick={sendToXero} disabled={buttonDisabled.sendDisabled}>{props.clientDetails.xeroContactId ? "Update Xero Contact" : "Send To Xero"}</button>
            </div>
        </form>
    );
    

}

export default ClientDetail;
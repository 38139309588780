import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';
import JobPurchaseOrderInvoiceReceivedPopup from './JobPurchaseOrderInvoiceReceived';

interface JobPurchaseOrderListProps {
    jobId: number;
    isMakeSafe: boolean;
    canEdit: boolean;
    canViewReport: boolean;
    open(id: number): void;
    checkHow(): void;
}

interface JobPurchaseOrders {
    id: number;
    jobId: number;
    supplier: string;
    insertedDateFormatted: string;
    totalFormatted: string;
    invoicedFormatted: string;
    remainingFormatted: string;
    remaining: number;
    status: string;
    statusId: number;
    isDirty: boolean;
}

interface JobSupplier {
    jobSupplierId: number;
    supplierName: string;
    invoiceTotal: number;
    invoiceTotalFormatted: string;
}

interface GenericListItem {
    id: number;
    name: string;
}

const JobPurchaseOrderListData = (props: JobPurchaseOrderListProps) => {
    const [loading, setLoading] = React.useState(true);
    const [howRequired, setHowRequired] = React.useState(false);
    const [howCompleted, setHowCompleted] = React.useState(false);
    const [jobPurchaseOrders, setJobPurchaseOrders] = React.useState<JobPurchaseOrders[]>([]);
    const [jobSupplierList, setJobSupplierList] = React.useState<JobSupplier[]>([]);
    const [supplierOptions, setSupplierOptions] = React.useState<GenericListItem[]>([]);
    const [supplierId, setSupplierId] = React.useState(-1);
    const [canSetSupplierInvoiceReceived, setCanSetSupplierInvoiceReceived] = React.useState(false);
    const [cancelOptions, setCancelOptions] = React.useState({ showConfirmModal: false, poIndex: -1, confirmTypeId: 0 });
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [invReceivedOptions, setInvReceivedOptions] = React.useState({ showInvReceived: false, poId: 0, poRemaining: 0, poRemainingFormatted: "" });
    const [poTotals, setPoTotals] = React.useState({ purchaseOrderTotalFormatted: "$0.00", invoicedTotalFormatted: "$0.00" });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //get user roles and status
        axios.get('Jobs/GetPurchaseOrders?JobId=' + props.jobId + '&IsMakeSafe=' + props.isMakeSafe + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setHowRequired(res.data.howRequired);
            setHowCompleted(res.data.howCompleted);
            setJobPurchaseOrders(res.data.purchaseOrders);
            setPoTotals({ purchaseOrderTotalFormatted: res.data.purchaseOrderTotalFormatted, invoicedTotalFormatted: res.data.invoicedTotalFormatted });
            setJobSupplierList(res.data.jobSupplierList);
            setSupplierOptions(res.data.suppliers);
            setCanSetSupplierInvoiceReceived(res.data.canSetSupplierInvoiceReceived);
            setLoading(false);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const open = (id: number, e: any) => {
        e.preventDefault();
        props.open(id);
    }

    const saveHowChecked = (e: any) => {
        saveHow(e.target.checked);
    }

    const saveHow = async (howChecked: boolean) => {
        const user = await authService.getUser();

        setHowCompleted(howChecked);

        //save the change as only one on the screen so no point having a save button
        var saveHow = {
            jobId: props.jobId,
            how: howChecked,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/SaveHow', saveHow, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("HOW Saved");
                props.checkHow();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleSupplierChange = (e: any) => {
        var supId = e.target.value * 1;

        if (supId > 0) {
            saveSupplier(supId);
        }
    }

    const saveSupplier = async (supId: number) => {
        const user = await authService.getUser();

        //save the change as only one on the screen so no point having a save button
        var saveSupplier = {
            jobId: props.jobId,
            supplierId: supId,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/SaveJobSupplier', saveSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Supplier Saved");
                setJobSupplierList(res.data);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //CANCEL PO
    const cancel = (e: any, index: number) => {
        e.preventDefault();

        setCancelOptions({ showConfirmModal: true, poIndex: index, confirmTypeId: 1 });
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setCancelOptions({ showConfirmModal: false, poIndex: -1, confirmTypeId: 0 });
        setModalSaveDisabled(false);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        if (cancelOptions.confirmTypeId === 1) {
            saveCancelPo();
        } else {
            deleteJobSupplier();
        }
        
    }

    const saveCancelPo = async () => {
        var pos = jobPurchaseOrders;
        var po = pos[cancelOptions.poIndex];
        const user = await authService.getUser();

        var cancelPO = {
            id: po.id,
            jobId: props.jobId,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/CancelPurchaseOrder', cancelPO, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Purchase Order has been cancelled");

                //remove from list
                po.status = "Cancelled";
                setJobPurchaseOrders(pos);
                setCancelOptions({ showConfirmModal: false, poIndex: -1, confirmTypeId: 0 });
                setModalSaveDisabled(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //END CANCEL PO

    //DELETE JOB SUPPLIER
    const deleteSupplier = (e: any, index: number) => {
        e.preventDefault();

        setCancelOptions({ showConfirmModal: true, poIndex: index, confirmTypeId: 2 });
        setModalSaveDisabled(false);
    }

    const deleteJobSupplier = async () => {
        const user = await authService.getUser();
        var suppliers = jobSupplierList;
        var supplier = suppliers[cancelOptions.poIndex];

        var deleteSupplier = {
            jobSupplierId: supplier.jobSupplierId,
            supplierName: supplier.supplierName,
            jobId: props.jobId,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/DeleteJobSupplier', deleteSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Supplier has been removed from job");

                //remove from list
                suppliers.splice(cancelOptions.poIndex, 1);
                setJobSupplierList(suppliers);
                setCancelOptions({ showConfirmModal: false, poIndex: -1, confirmTypeId: 0 });
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //END DELETE JOB SUPPLIER

    const copy = (e: any, id: number) => {
        e.preventDefault();
        copyPo(id);
    }

    const copyPo = async (id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        axios.get('Jobs/CopyPurchaseOrder?Id=' + id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //open po to edit
            var id = res.data;
            props.open(id);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const view = (e: any, id: number) => {
        e.preventDefault();
        var url = "/job/" + props.jobId + "/report/" + id + "/PurchaseOrder";
        window.open(url, '_blank');
    }

    //Invoice Received
    const invoiceReceived = (e: any, index: number) => {
        e.preventDefault();
        var pos = jobPurchaseOrders;
        var po = pos[index];

        setInvReceivedOptions({ showInvReceived: true, poId: po.id, poRemaining: po.remaining, poRemainingFormatted: po.remainingFormatted });
    }

    const hideInvoiceReceived = () => {
        setInvReceivedOptions({ showInvReceived: false, poId: 0, poRemaining: 0, poRemainingFormatted: "" });
        getData();
    }

    let confirmHeader = cancelOptions.confirmTypeId === 1 ? "Cancel PO" : "Remove Supplier";
    let confirmText = cancelOptions.confirmTypeId === 1 ? "Are you sure you want to cancel this purchase order?" : "Are you sure you want to remove this supplier from the job?";

    let confirmPopup = <ConfirmModal heading={confirmHeader} text={confirmText} hideConfirmModal={hideConfirmModal} showConfirmModal={cancelOptions.showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let invReceived = <JobPurchaseOrderInvoiceReceivedPopup show={invReceivedOptions.showInvReceived} jobId={props.jobId} poId={invReceivedOptions.poId} poRemaining={invReceivedOptions.poRemaining} poRemainingFormatted={invReceivedOptions.poRemainingFormatted} hide={hideInvoiceReceived} />

    const renderDetails = (
        <form>
            <div className="static-modal">
                {confirmPopup}
                {invReceived}
            </div>
            <div>
                <span>Note: Job must be In Progress (Excess received and Received Signed Authority), HOW Taken Out (if applicable) and Forecast Complete to create Purchase Orders</span>
            </div>
            <div className={howRequired ? "" : "hidden"}>
                <label className="input-group" htmlFor="howCompleted">
                    <span className="label labelLarge">HOW Taken Out?</span>
                    <input type="checkbox" className="input checkbox" id="howCompleted" name="howCompleted" checked={howCompleted} onChange={(e) => saveHowChecked(e)} />
                </label>
            </div>
            <div className="job__noteGridSize overflowAuto">
                <table className="table--main tableColours">
                    <thead>
                        <tr>
                            <th>Order #</th>
                            <th>Supplier</th>
                            <th className="textalignright">Created</th>
                            <th className="textalignright">Total</th>
                            <th className="textalignright">Invoiced</th>
                            <th className="textalignright">Remaining</th>
                            <th>Status</th>
                            <th className="textaligncenter">View</th>
                            <th></th>
                            <th className="textaligncenter">
                                <div className="tooltipitem tooltipitem__right">Inv Rec
                                    <span className="tooltipitemtext tooltipitemtext__right">Invoice received and uploaded to Xero externally e.g. via Dext</span>
                                </div>
                            </th>
                            <th className="textaligncenter">Cancel</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobPurchaseOrders.map((po, index) =>
                            <tr key={po.id}>
                                <td className="table__text--align">
                                    <a href="#" onClick={(e) => open(po.id, e)}>
                                        {po.id}
                                    </a>
                                </td>
                                <td className="table__text--align">{po.supplier}</td>
                                <td className="table__text--align textalignright">{po.insertedDateFormatted}</td>
                                <td className="table__text--align textalignright">{po.totalFormatted}</td>
                                <td className="table__text--align textalignright">{po.invoicedFormatted}</td>
                                <td className="table__text--align textalignright">{po.remainingFormatted}</td>
                                <td className="table__text--align">{po.status}</td>
                                <td className="table__text--align">
                                    <div className={!props.canViewReport ? "hidden" : "delete--tablecell"}>
                                        <a href="#" onClick={(e) => view(e, po.id)}>
                                            <span className="fas fa-file-export edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                                <td className="table__text--align">
                                    <a href="#" onClick={(e) => copy(e, po.id)}>
                                        Copy
                                    </a>
                                </td>
                                <td className="table__text--align">
                                    <div className={(po.statusId === 1 || po.statusId === 2) && po.remaining > 0 && canSetSupplierInvoiceReceived ? "" : "hidden"}>
                                        <a href="#" onClick={(e) => invoiceReceived(e, index)}>
                                            <span className="fas fa-file-invoice edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                                <td className="table__text--align">
                                    <div className={(po.status === "Created" || po.status === "Sent") && props.canEdit ? "delete--tablecell" : "hidden"}>
                                        <a className="makeitred" href="#" onClick={(e) => cancel(e, index)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="marginTop10">
                <label className="input-group estimate__alignTotals" htmlFor="purchaseOrderTotalFormatted">
                    <span className="label textalignright">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" value={poTotals.purchaseOrderTotalFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="invoicedTotalFormatted">
                    <span className="label textalignright">Invoiced Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" value={poTotals.invoicedTotalFormatted} disabled></input>
                </label>
            </div>
            <div className={props.isMakeSafe ? "" : "hidden"}>
                <h2>Suppliers</h2>
                <label className="input-group" htmlFor="supplierId">
                    <span className="label">Supplier/s</span>
                    <select className="select" id="supplierId" name="supplierId" value={supplierId} onChange={(e) => handleSupplierChange(e)} >
                        <option defaultValue="-1"></option>
                        {supplierOptions.map(supplier =>
                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                        )}
                    </select>
                </label>
                <table className="table--main table__auto tableColours">
                    <thead>
                        <tr>
                            <th>Supplier</th>
                            <th>Invoice Received</th>
                            <th className="textaligncenter">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobSupplierList.map((sup, index) =>
                            <tr key={sup.jobSupplierId}>
                                <td className="table__text--align">
                                    {sup.supplierName}
                                </td>
                                <td className="table__text--align textalignright">
                                    {sup.invoiceTotalFormatted}
                                </td>
                                <td className="table__text--align">
                                    <div className={sup.invoiceTotal > 0 ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteSupplier(e, index)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobPurchaseOrderListData;

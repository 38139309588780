import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
        userName: null,
        firstName: "",
        system: ""
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user && user.name
    });

      if (isAuthenticated) {
          this.getSystem();
      }
    }

    async getSystem() {
        const token = await authService.getAccessToken();
        //check if cookie exists
        var name = cookies.get('sky');

        if (!name || name === "") {
            const user = await authService.getUser();

            if (user) {
                axios.get('Menu/GetSystemFirstName?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    let d = new Date();
                    d.setDate(d.getDate() + 14);
                    cookies.set('sky', res.data.firstName, { path: '/', expires: d });
                    this.setState({ system: res.data.system, firstName: res.data.firstName });
                });
            }
        } else {
            //already have name so just get system
            axios.get('Menu/GetSystem', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                this.setState({ system: res.data, firstName: name });
            });
        }


    }

  render() {
      const { isAuthenticated, userName, firstName, system } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, firstName, system, profilePath, logoutPath, logoutState);
    }
  }

    authenticatedView(userName, firstName, system, profilePath, logoutPath, logoutState) {
        let name = !firstName || firstName === "" ? userName : firstName;
        return (<Fragment>
            <div className="top-nav--fontsize">Welcome {name}</div>
            <div className={system ? "top-nav--fontsize error" : "hidden"}>{system}</div>
            <div className="makeitflexcenter">
                <div>
                    <NavLink className="text-dark marginRight10" to={profilePath}>Profile</NavLink>
                </div>
                <div>
                    <NavLink replace tag={Link} className="logout-button" to={logoutPath} state={logoutState}>Logout</NavLink>
                </div>
            </div>
        </Fragment>);
      }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <div>
                <NavLink tag={Link} className="logout-button" to={loginPath}>Login</NavLink>
            </div>
        </Fragment>);
    }
}

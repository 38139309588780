import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import ConfirmModalWithNoteData from './ConfirmModalWithNotes';

interface JobSupplierInvoiceListProps {
    jobId: number;
    canEdit: boolean;
    canApproveSupplierInvoices: boolean;
    changePage(pageData: PageChangeData): void;
}

interface SupplierInvoice {
    id: number;
    xeroInvoiceId: string;
    poNumbers: string;
    supplier: string;
    invoiceDate: string;
    dueDate: string;
    status: string;
    statusId: number;
    paidDate: string;
    total: number;
    totalFormatted: string;
    poTotal: number;
    poTotalFormatted: string;
    poRemaining: number;
    poRemainingFormatted: string;
    awaitingApproval: boolean;
    selected: boolean;
}

interface PageChangeData {
    id: number,
    page: string
}

const JobSupplierInvoiceListData = (props: JobSupplierInvoiceListProps) => {
    const [loading, setLoading] = React.useState(true);
    const [invoices, setInvoices] = React.useState<SupplierInvoice[]>([]);
    const [totals, setTotals] = React.useState({ totalFormatted: "$0.00", totalSentFormatted: "$0.00" });
    const [approveData, setApproveData] = React.useState({ showApproveModal: false, approveIndex: -1 });
    const [approveNotes, setApproveNotes] = React.useState("");
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [selectAll, setSelectAll] = React.useState(false);

    React.useEffect(() => {
        getSupplierInvoices();
    }, []);

    const getSupplierInvoices = async () => {
        const token = await authService.getAccessToken();
        var jobId = props.jobId;

        axios.get('Jobs/GetSupplierInvoices?JobId=' + jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setInvoices(res.data.supplierInvoices);
            setTotals({ totalFormatted: res.data.totalFormatted, totalSentFormatted: res.data.totalSentFormatted });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //DOWNLOAD INVOICES
    const handleSelectAllChange = (e: any) => {
        var allInvoices = [...invoices];

        //loop through all the invoices and select any that are in created status
        for (var i = 0; i < allInvoices.length; i++) {
            allInvoices[i].selected = e.target.checked;
        }

        setInvoices(allInvoices);
        setSelectAll(e.target.checked);
    }

    const handleCellCheckChange = (e: any, index: number) => {
        //update item that has changed
        var allInvoices = [...invoices];
        var invoice = allInvoices[index];

        invoice.selected = e.target.checked;
        setInvoices(allInvoices);
    }

    const downloadInvoices = (e: any) => {
        e.preventDefault();

        var selectedInvoices = invoices.filter(i => i.selected);
        if (selectedInvoices.length === 0) {
            toast.error("Select one or more invoices to download!");
        } else {
            downloadSupplierInvoices(selectedInvoices);
        }
    }

    const downloadSupplierInvoices = async (selectedInvoices: SupplierInvoice[]) => {
        const token = await authService.getAccessToken();

        var ids: number[] = [];
        for (var i = 0; i < selectedInvoices.length; i++) {
            ids.push(selectedInvoices[i].id);
        }

        var supInvIds = {
            jobId: props.jobId,
            supplierInvoiceIds: ids
        };

        axios.post('Jobs/DownloadSupplierInvoiceFiles', supInvIds, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            responseType: 'blob'
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            //var fileName = res.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];
            var fileheader = res.headers["content-disposition"];
            var filename = "SupplierInvoices.zip";
            if (fileheader) {
                filename = fileheader.split('filename=')[1].split(';')[0];
                filename = filename.replaceAll('"', '');
            }
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    //END DOWNLOAD INVOICES

    //APPROVE INVOICE
    const approve = (itemIndex: number, e: any) => {
        e.preventDefault();

        setApproveData({ showApproveModal: true, approveIndex: itemIndex });
        setApproveNotes("");
        setModalSaveDisabled(false);
    }

    const hideApproveConfirmModal = () => {
        setApproveData({ showApproveModal: false, approveIndex: -1 });
        setApproveNotes("");
        setModalSaveDisabled(false);
    }

    const updateApproveNotes = (appNotes: string) => {
        setApproveNotes(appNotes);
        setModalSaveDisabled(false);
    }

    const saveApprove = () => {
        approveInvoice();
    }

    const approveInvoice = async () => {
        //approve supplier invoice as the invoice amount > purchase order amount
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        var invoiceList = [...invoices];
        let invoice = invoiceList[approveData.approveIndex];

        var details = {
            subId: user.sub,
            invoiceId: invoice.id,
            notes: approveNotes
        };

        axios.post('Jobs/ApproveSupplierInvoice', details, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                invoice.awaitingApproval = false;
                setInvoices(invoiceList);
                setApproveData({ showApproveModal: false, approveIndex: -1 });
                setApproveNotes("");
                setModalSaveDisabled(false);
                toast.success("Invoice has been approved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
           
    }
    //END APPROVE

    const checkPaidStatus = () => {
        checkInvPaidStatus();
    }

    const checkInvPaidStatus = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "costings",
            id: props.jobId
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                var details = {
                    jobId: props.jobId * 1,
                    jobInvoices: invoices,
                    subId: user.sub
                };

                //can check paid status of invoices
                axios.post('Jobs/UpdateSupplierInvoicePaidStatus', details, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        getSupplierInvoices();
                        toast.success("Supplier Invoices Paid Status updated");
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    const openDetails = (id: number, e: any) => {
        e.preventDefault();
        var pageDetails: PageChangeData = {
            id: id,
            page: "Details"
        };
        props.changePage(pageDetails);
    }

    let confirmPopupNotes = <ConfirmModalWithNoteData showConfirmModal={approveData.showApproveModal} heading={"Approve Invoice"} text="Are you sure you want to approve this invoice?" notesText="Enter reason why invoice total > purchase order total" notes={approveNotes} updateNotes={updateApproveNotes} hideConfirmModal={hideApproveConfirmModal} noConfirmModal={hideApproveConfirmModal} yesConfirmModal={saveApprove} saveDisabled={modalSaveDisabled} />

    const renderDetails = (
        <div>
            <div className="static-modal">
                {confirmPopupNotes}
            </div>
            <div className="makeitflexspacebetween">
                <button className="defaultbutton" type="button" onClick={downloadInvoices}>Download Supplier Invoices</button>
                <button className={props.canEdit ? "defaultbutton defaultbutton__medium marginBottom10" : "hidden"} type="button" onClick={checkPaidStatus}>Check Paid Status</button>
            </div>
            <div className='overflowAuto showMobile'>
                <table className="table--main table__small tableColours mobileTableFont">
                    <thead>
                        <tr>
                            <th>Inv# PO#</th>
                            <th>Supplier</th>
                            <th>Inv Date / Due Date</th>
                            <th className="textalignright">Total / PO / Remain</th>
                            <th>Status / Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice, index) =>
                            <tr key={index}>
                                <td className="table__text--align">
                                    <a href="#" onClick={(e) => openDetails(invoice.id, e)}>
                                        {invoice.id}
                                    </a><br></br>
                                    {invoice.poNumbers}
                                </td>
                                <td className="table__text--align">{invoice.supplier}</td>
                                <td className="table__text--align">
                                    {invoice.invoiceDate}<br></br>
                                    {invoice.dueDate}
                                </td>
                                <td className="table__text--align textalignright">
                                    {invoice.totalFormatted}<br></br>
                                    {invoice.poTotalFormatted}<br></br>
                                    {invoice.poRemainingFormatted}
                                </td>
                                <td className="table__text--align">
                                    {invoice.status}<br></br>
                                    {invoice.paidDate}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='overflowAuto hideMobile'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th>
                                <input name="selectall" type='checkbox' className="checkbox" checked={selectAll} onChange={(e) => handleSelectAllChange(e)} />
                            </th>
                            <th>Inv Number</th>
                            <th>PO Number</th>
                            <th>Supplier</th>
                            <th>Invoice Date</th>
                            <th>Due Date</th>
                            <th className="textalignright">Total</th>
                            <th className="textalignright">PO Total</th>
                            <th className="textalignright">Remaining PO</th>
                            <th>Status</th>
                            <th>Paid Date</th>
                            <th>Approve</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice, index) =>
                            <tr key={index}>
                                <td className="table__text--align">
                                    <input name="selected" type='checkbox' className="checkbox" checked={invoice.selected} onChange={(e) => handleCellCheckChange(e, index)} />
                                </td>
                                <td className="table__text--align">
                                    <a href="#" onClick={(e) => openDetails(invoice.id, e)}>
                                        {invoice.id}
                                    </a>
                                </td>
                                <td className="table__text--align">{invoice.poNumbers}</td>
                                <td className="table__text--align">{invoice.supplier}</td>
                                <td className="table__text--align">{invoice.invoiceDate}</td>
                                <td className="table__text--align">{invoice.dueDate}</td>
                                <td className="table__text--align textalignright">{invoice.totalFormatted}</td>
                                <td className="table__text--align textalignright">{invoice.poTotalFormatted}</td>
                                <td className="table__text--align textalignright">{invoice.poRemainingFormatted}</td>
                                <td className="table__text--align">{invoice.status}</td>
                                <td className="table__text--align">{invoice.paidDate}</td>
                                <td className="table__text--align"><button className={invoice.awaitingApproval && props.canApproveSupplierInvoices ? "jobInvoice__approve" : "hidden"} onClick={(e) => approve(index, e)}>Approve</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div>
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="totalFormatted">
                    <span className="label">Total Invoices</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={totals.totalFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="totalSentFormatted">
                    <span className="label">Total Sent</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalSentFormatted" name="totalSentFormatted" value={totals.totalSentFormatted} disabled></input>
                </label>
            </div>
        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;
}

export default JobSupplierInvoiceListData;
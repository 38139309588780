import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobInvoiceDetailData from './JobInvoiceDetails';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const InvoiceDetailsData = () => {
    let navigate = useNavigate();
    const { id, jobid } = useParams();
    const [invData, setInvData] = React.useState({ jobId: 0, id: 0 });
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let Id = Number(id);
        let JobId = Number(jobid);
        getData(JobId, Id);
    }, []);

    const getData = async (JobId: number, Id: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check permissions and get dropdowns
        axios.get('Users/CheckUserInvoicePermission?SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                var tokenx = token == null ? "" : token;
                checkXeroAuth(JobId, Id, tokenx, user.sub);
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const checkXeroAuth = async (jobId: number, id: number, token: string, subId: string) => {
        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "invoices",
            id: jobId
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + subId + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //can send invoice
                setInvData({ jobId: jobId, id: id });
                setLoading(false);
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    const goInvoiceList = () => {
        navigate("/client/sendinvoices");
    }

    var dataContents = <JobInvoiceDetailData jobId={invData.jobId} id={invData.id} showAddress={true} changePage={goInvoiceList} />
        
    const renderDetails = (
        <div>
            <div>
                {dataContents}
            </div>
        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

}

export default InvoiceDetailsData;
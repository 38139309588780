
interface JobTabsProps {
    id: number;
    siteAddress: string;
    isMakeSafe: boolean;
    isReportOnly: boolean;
    tab: string;
    canViewEstimate: boolean;
    canViewCostings: boolean;
    canViewVariations: boolean;
    canViewInvoicing: boolean;
    tabChanged(url: string): void;
}

const JobTabsData = (props: JobTabsProps) => {

    const tabSelected = (tab: string) => {
        //redirect to new tab location
        var url = "/job/" + props.id + "/" + tab;
        props.tabChanged(url);
    }

    const tabChanged = (e: any) => {
        tabSelected(e.target.value);
    }

    const noChange = (e: any) => {
        //no changes as user doesn't actually click on the radio button
    }

    return (
        <div>
            <div className="marginTop10 marginBottom10">
                <h2 className={props.isMakeSafe ? "makeSafeJob" : props.isReportOnly ? "reportOnlyJob" : ""}>{props.id} - {props.siteAddress}</h2>
            </div>
            <div className="tabsComponent" id="tab-container">
                <div className="tabsComponent__showDropdown">
                    <label className="input-group" htmlFor="jobTab">
                        <span className="label">Job Item</span>
                        <select className="select" id="jobTab" value={props.tab} onChange={tabChanged} >
                            <option value="details">Details</option>
                            <option className={props.canViewEstimate ? "" : "hidden"} value="estimate">Estimate</option>
                            <option className={props.canViewCostings ? "" : "hidden"} value="costings">Costings</option>
                            <option value="schedule">Schedule</option>
                            <option value="ongoinghire">On-Going Hire</option>
                            <option className={props.canViewVariations ? "" : "hidden"} value="variations">Variations</option>
                            <option value="notes">Notes</option>
                            <option value="files">Files</option>
                            <option className={props.canViewInvoicing ? "" : "hidden"} value="invoices">Invoices</option>
                        </select>
                    </label>
                </div>

                <div className="tabsComponent__tabs tabsComponent__hideTabs">
                    <input type="radio" name="tabs" id="tab1" className="tabsComponent__control" checked={props.tab == 'details'} onChange={noChange}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('details')}>Details</label>

                    <input type="radio" name="tabs" id="tab2" className={props.canViewEstimate ? "tabsComponent__control" : "hidden"} checked={props.tab == 'estimate'} onChange={noChange}></input>
                    <label className={props.canViewEstimate ? "tabsComponent__label" : "hidden"} htmlFor="tabItem" onClick={() => tabSelected('estimate')}>Estimate</label>

                    <input type="radio" name="tabs" id="tab3" className={props.canViewCostings ? "tabsComponent__control" : "hidden"} checked={props.tab == 'costings'} onChange={noChange}></input>
                    <label className={props.canViewCostings ? "tabsComponent__label" : "hidden"} htmlFor="tabItem" onClick={() => tabSelected('costings')}>Costings</label>

                    <input type="radio" name="tabs" id="tab4" className="tabsComponent__control" checked={props.tab == 'schedule'} onChange={noChange}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('schedule')}>Schedule</label>

                    <input type="radio" name="tabs" id="tab5" className="tabsComponent__control" checked={props.tab == 'ongoinghire'} onChange={noChange}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('ongoinghire')}>On-Going Hire</label>

                    <input type="radio" name="tabs" id="tab6" className={props.canViewVariations ? "tabsComponent__control" : "hidden"} checked={props.tab == 'variations'} onChange={noChange}></input>
                    <label className={props.canViewVariations ? "tabsComponent__label" : "hidden"} htmlFor="tabItem" onClick={() => tabSelected('variations')}>Variations</label>

                    <input type="radio" name="tabs" id="tab7" className="tabsComponent__control" checked={props.tab == 'notes'} onChange={noChange}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('notes')}>Notes</label>

                    <input type="radio" name="tabs" id="tab8" className="tabsComponent__control" checked={props.tab == 'files'} onChange={noChange}></input>
                    <label className="tabsComponent__label" htmlFor="tabItem" onClick={() => tabSelected('files')}>Files</label>

                    <input type="radio" name="tabs" id="tab9" className={props.canViewInvoicing ? "tabsComponent__control" : "hidden"} checked={props.tab == 'invoices'} onChange={noChange}></input>
                    <label className={props.canViewInvoicing ? "tabsComponent__label" : "hidden"} htmlFor="tabItem" onClick={() => tabSelected('invoices')}>Invoices</label>
                </div>

            </div>
        </div>
    )
}

export default JobTabsData;


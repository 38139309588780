import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddClientContactPopups from './JobDetailsNewClientContact';
import ClientAddNewPopup from './ClientAddNewPopup';
import NewJobDetailsAddInsurers from './NewJobDetailsAddInsurers';
import authService from './api-authorization/AuthorizeService';

interface NewJobClientDetailsProps {
    currentStep: number;
    clientDetails: JobClientDetails;
    multipleInsurers: JobInsurerClaimLine[];
    resources: JobResources;
    jobTypeId: number;
    makeSafe: boolean;
    claimTypes: GenericListItem[];
    insurers: GenericListItem[];
    adjusters: GenericListItem[];
    brokers: GenericListItem[];
    estimators: GenericListItem[];
    supervisors: GenericListItem[];
    managers: GenericListItem[];
    canAddNewClients: boolean;
    prev(): void;
    next(): void;
    update(clientDetails: JobClientDetails): void;
    updateInsurers(multipleInsurers: JobInsurerClaimLine[]): void;
    updateResources(resources: JobResources): void;
    updateClients(clients: GenericListItem[], typeId: number, clientid: number): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface JobClientDetails {
    insurerId: number;
    insurerContactId: number;
    claimNumber: string;
    claimStatusId: number;
    claimTypeId: number;
    excessAmount: number;
    adjusterId: number;
    adjusterContactId: number;
    adjusterReferenceNumber: string;
    brokerId: number;
    brokerContactId: number;
    brokerReferenceNumber: string;
}

interface JobResources {
    managerId: number;
    estimatorId: number;
    supervisorId: number;
}

interface JobInsurerClaimLine {
    id: number;
    insurerId: number;
    claimNumber: string;
    splitPercentage: number;
    status: number;
}

const NewJobClientDetail = (props: NewJobClientDetailsProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [insurerContacts, setInsurerContacts] = React.useState<GenericListItem[]>([]);
    const [adjusterContacts, setAdjusterContacts] = React.useState<GenericListItem[]>([]);
    const [brokerContacts, setBrokerContacts] = React.useState<GenericListItem[]>([]);
    const [showAddClientModal, setShowAddClientModal] = React.useState(false);
    const [showAddClientContactModal, setShowAddClientContactModal] = React.useState(false);
    const [clientId, setClientId] = React.useState(0);
    const [typeId, setTypeId] = React.useState(0);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [multipleInsurers, setMultipleInsurers] = React.useState(false);

    React.useEffect(() => {
        if (props.multipleInsurers.length > 1) {
            setMultipleInsurers(true);
        }
        getExistingContacts();
    }, []);

    
    const getExistingContacts = () => {
        //cater for user pressing previous and next buttons so screen needs to reload with saved details
        //load insurance contacts
        if (insurerContacts.length === 0 && props.clientDetails.insurerId > 0) {
            //need to load insurance contacts
            getContacts(props.clientDetails.insurerId, 1);
        }
        //load adjuster contacts
        if (adjusterContacts.length === 0 && props.clientDetails.adjusterId > 0) {
            getContacts(props.clientDetails.adjusterId, 2);
        }
        //load broker contacts
        if (brokerContacts.length === 0 && props.clientDetails.brokerId > 0) {
            getContacts(props.clientDetails.brokerId, 3);
        }
    }

    const getClients = async (clientId: number, typeId: number) => {
        const token = await authService.getAccessToken();
        axios.get('Clients/GetClientDropdown?typeId=' + typeId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var clients = res.data;
            props.updateClients(clients, typeId, clientId);
            getContacts(clientId, typeId);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getContacts = async (clientId: number, typeId: number) => {
        if (clientId <= 0) {
            if (typeId == 1) {
                setInsurerContacts([]);
            } else if (typeId == 2) {
                setAdjusterContacts([]);
            } else {
                setBrokerContacts([]);
            }
        } else {
            const token = await authService.getAccessToken();
            axios.get('Jobs/GetClientContactDropdown?clientId=' + clientId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var contacts = res.data;
                if (typeId == 1) {
                    setInsurerContacts(contacts);
                } else if (typeId == 2) {
                    setAdjusterContacts(contacts);
                } else {
                    setBrokerContacts(contacts);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        }
    }

    const handleClientSelectChange = (e: any, updatedTypeId: number) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let updatedClientDetails: any = {};
        updatedClientDetails = props.clientDetails;
        updatedClientDetails[e.target.name] = e.target.value;

        getContacts(e.target.value, updatedTypeId);

        setSaveDisabled(false);
        props.update(updatedClientDetails);
    }

    const handleClientChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let updatedClientDetails: any = {};
        updatedClientDetails = props.clientDetails;
        updatedClientDetails[e.target.name] = e.target.value;

        setSaveDisabled(false);
        props.update(updatedClientDetails);
    }

    const handleMultipleInsurersChange = (e: any) => {
        var error = errors;
        delete error["splitPercentage"];
        setErrors(error);

        if (e.target.checked) {
            setMultipleInsurers(true);
        } else {
            setMultipleInsurers(false);

            //need to remove any entered insurer details
            let multiIns:JobInsurerClaimLine[] = [];
            let blankIns = addBlankMultiInsRow();
            multiIns.push(blankIns);
            props.updateInsurers(multiIns);
        }
    }

    const addBlankMultiInsRow = () => {
        var blankRow: JobInsurerClaimLine = {
            id: 0,
            insurerId: -1,
            claimNumber: "",
            splitPercentage: 0,
            status: 1
        };

        return blankRow;
    }

    const handleResourcesChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let updatedResources: any = {};
        updatedResources = props.resources;
        updatedResources[e.target.name] = e.target.value;

        setSaveDisabled(false);
        props.updateResources(updatedResources);
    }

    //ADD CLIENT
    const showAddNewClientModal = (e: any, updatedTypeId: number) => {
        e.preventDefault();

        setShowAddClientModal(true);
        setTypeId(updatedTypeId);
    }

    const hideAddClientModal = () => {
        setShowAddClientModal(false);
        setTypeId(0);
    }

    const saveAddClientModal = (updatedClientId: number) => {
        setShowAddClientModal(false);
        setTypeId(0);
        getClients(updatedClientId, typeId);
    }

    //ADD CLIENT CONTACT
    const showAddNewClientContactModal = (e: any, updatedClientId: number, updatedTypeId: number) => {
        e.preventDefault();

        setShowAddClientContactModal(true);
        setClientId(updatedClientId);
        setTypeId(updatedTypeId);
    }

    const hideAddClientContactModal = () => {
        setShowAddClientContactModal(false);
        setClientId(0);
        setTypeId(0);
    }

    const saveAddClientContactModal = (contactId: number) => {
        let details = props.clientDetails;
        if (contactId > 0) {
            if (typeId === 1) {
                details.insurerContactId = contactId;
            } else if (typeId === 2) {
                details.adjusterContactId = contactId;
            } else {
                details.brokerContactId = contactId;
            }
        }

        setShowAddClientContactModal(false);
        setClientId(0);
        setTypeId(0);
        getContacts(clientId, typeId);

        props.update(details);
    }
    //END ADD CLIENT CONTACT

    //MULTIPLE INSURERS
    const updateInsurers = (insurers: JobInsurerClaimLine[]) => {
        props.updateInsurers(insurers);
    }
    //END MULTIPLE INSURERS

    const prev = () => {
        props.prev();
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        //Insurance Jobs Validation
        if (props.jobTypeId == 1) {
            if (props.clientDetails.insurerId < 0) {
                formIsValid = false;
                error["insurerId"] = "Insurer is required";
            }

            if (!props.clientDetails.claimNumber && !props.makeSafe) {
                formIsValid = false;
                error["claimNumber"] = "Claim # is required";
            }

            if (props.clientDetails.claimStatusId < 0) {
                formIsValid = false;
                error["claimStatusId"] = "Claim Status is required";
            }

            if (props.clientDetails.excessAmount < 0) {
                formIsValid = false;
                error["excessAmount"] = "Excess Amount >= 0";
            }

            if (props.clientDetails.adjusterId > 0 && props.clientDetails.adjusterContactId <= 0) {
                formIsValid = false;
                error["adjusterContactId"] = "Adjuster Contact is required";
            }

        }

        let splitPercent = 0;
        if (props.multipleInsurers.length > 1) {
            var splitError = "";
            //make sure the total % split is 100%
            for (var i = 0; i < props.multipleInsurers.length - 1; i++) {
                let insurer = props.multipleInsurers[i];
                if (insurer.status === 1) {
                    if (insurer.insurerId <= 0) {
                        splitError += "Row " + (i + 1) + " must have an Insurer selected! \n";
                    }

                    let itemSplitPercent = insurer.splitPercentage * 1;
                    splitPercent += itemSplitPercent;
                    if (itemSplitPercent <= 0) {
                        splitError += "Row " + (i + 1) + " must have a split % > 0! \n";
                    }
                }
            }

            if (splitPercent != 100) {
                splitError += "Split % MUST add up to 100!";
            }

            if (splitError != "") {
                formIsValid = false;
                error["splitPercentage"] = splitError;
            }
        }

        if (props.resources.estimatorId < 0 && !props.makeSafe) {
            formIsValid = false;
            error["estimatorId"] = "Estimator is required";
        }

        if (props.makeSafe && props.resources.supervisorId < 0) {
            formIsValid = false;
            error["supervisorId"] = "Supervisor is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const next = () => {
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            props.next();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    if (props.currentStep !== 3) {
        return null;
    }

    let addClientContact = <AddClientContactPopups parentId={clientId} parentTypeId={1} showAddClientContactModal={showAddClientContactModal} hideAddClientContactModal={hideAddClientContactModal} saveAddClientContactModal={saveAddClientContactModal} />
    let addClient = <ClientAddNewPopup newTypeId={typeId} showModal={showAddClientModal} hideModal={hideAddClientModal} saveModal={saveAddClientModal} /> 
    let multiInsurers = <NewJobDetailsAddInsurers jobId={0} insurerOptions={props.insurers} insurers={props.multipleInsurers} update={updateInsurers} />

    return (
        <form>
            <div className="static-modal">
                {addClientContact}
                {addClient}
            </div>
            <div className={props.jobTypeId == 1 ? "" : "hidden"} >
                <div className="input-group-parent">
                    <h4>Client Details</h4>
                    <label className="input-group" htmlFor="insurerId">
                        <span className="label">Insurer
                            <a className={props.canAddNewClients ? "marginLeft10" : "hidden"} href="#" onClick={(e) => showAddNewClientModal(e, 1)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="insurerId" name="insurerId" value={props.clientDetails.insurerId} onChange={(e) => handleClientSelectChange(e, 1)}>
                            <option defaultValue="-1"></option>
                            {props.insurers.map(ins =>
                                <option key={ins.id} value={ins.id}>{ins.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["insurerId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["insurerId"]}</span>

                    <label className="input-group" htmlFor="multipleInsurers">
                        <span className="label">Multiple Insurers?</span>
                        <input className="checkbox" type="checkbox" id="multipleInsurers" name="multipleInsurers" checked={multipleInsurers} onChange={(e) => handleMultipleInsurersChange(e)}></input>
                    </label>

                    <label className="input-group" htmlFor="insurerContactId">
                        <span className="label">Contact
                            <a className={props.clientDetails.insurerId < 0 ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddNewClientContactModal(e, props.clientDetails.insurerId, 1)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="insurerContactId" name="insurerContactId" value={props.clientDetails.insurerContactId} onChange={(e) => handleClientChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            {insurerContacts.map(ins =>
                                <option key={ins.id} value={ins.id}>{ins.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["insurerContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["insurerContactId"]}</span>

                    <label className="input-group" htmlFor="claimNumber">
                        <span className="label">Claim #</span>
                        <input className='input' type='text' maxLength={20} id="claimNumber" name="claimNumber" value={props.clientDetails.claimNumber} onChange={(e) => handleClientChange(e)}></input>
                    </label>
                    <span className={errors["claimNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["claimNumber"]}</span>

                    <label className="input-group" htmlFor="claimStatusId">
                        <span className="label">Claim Status</span>
                        <select className="select" id="claimStatusId" name="claimStatusId" value={props.clientDetails.claimStatusId} onChange={(e) => handleClientChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            <option key={1} value={1}>BAU</option>
                            <option key={2} value={2}>CAT</option>
                        </select>
                    </label>
                    <span className={errors["claimStatusId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["claimStatusId"]}</span>

                    <label className="input-group" htmlFor="claimTypeId">
                        <span className="label">Claim Type</span>
                        <select className="select" id="claimTypeId" name="claimTypeId" value={props.clientDetails.claimTypeId} onChange={(e) => handleClientChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            {props.claimTypes.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["claimTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["claimTypeId"]}</span>

                    <label className="input-group" htmlFor="excessAmount">
                        <span className="label">Excess Amount (excl. GST)</span>
                        <input className='input' type='number' min={0} id="excessAmount" name="excessAmount" value={props.clientDetails.excessAmount} onChange={(e) => handleClientChange(e)}></input>
                    </label>
                    <span className={errors["excessAmount"] ? "label errors errors__leftmargin" : "hidden"}>{errors["excessAmount"]}</span>

                    <label className="input-group" htmlFor="adjusterId">
                        <span className="label">Adjuster
                            <a className={props.canAddNewClients ? "marginLeft10" : "hidden"} href="#" onClick={(e) => showAddNewClientModal(e, 2)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="adjusterId" name="adjusterId" value={props.clientDetails.adjusterId} onChange={(e) => handleClientSelectChange(e, 2)}>
                            <option defaultValue="-1"></option>
                            {props.adjusters.map(adj =>
                                <option key={adj.id} value={adj.id}>{adj.name}</option>
                            )};
                        </select>
                    </label>

                    <label className="input-group" htmlFor="adjusterContactId">
                        <span className="label">Contact
                            <a className={props.clientDetails.adjusterId < 0 ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddNewClientContactModal(e, props.clientDetails.adjusterId, 2)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="adjusterContactId" name="adjusterContactId" value={props.clientDetails.adjusterContactId} onChange={(e) => handleClientChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            {adjusterContacts.map(adj =>
                                <option key={adj.id} value={adj.id}>{adj.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["adjusterContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["adjusterContactId"]}</span>

                    <label className="input-group" htmlFor="adjusterReferenceNumber">
                        <span className="label">Adjuster Ref #</span>
                        <input className='input' type='text' maxLength={20} id="adjusterReferenceNumber" name="adjusterReferenceNumber" value={props.clientDetails.adjusterReferenceNumber} onChange={(e) => handleClientChange(e)}></input>
                    </label>
                    <span className={errors["adjusterReferenceNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["adjusterReferenceNumber"]}</span>

                    <label className="input-group" htmlFor="brokerId">
                        <span className="label">Broker
                            <a className={props.canAddNewClients ? "marginLeft10" : "hidden"} href="#" onClick={(e) => showAddNewClientModal(e, 3)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="brokerId" name="brokerId" value={props.clientDetails.brokerId} onChange={(e) => handleClientSelectChange(e, 3)}>
                            <option defaultValue="-1"></option>
                            {props.brokers.map(bkr =>
                                <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                            )};
                        </select>
                    </label>

                    <label className="input-group" htmlFor="brokerContactId">
                        <span className="label">Contact
                            <a className={props.clientDetails.brokerId < 0 ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddNewClientContactModal(e, props.clientDetails.brokerId, 3)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="brokerContactId" name="brokerContactId" value={props.clientDetails.brokerContactId} onChange={(e) => handleClientChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            {brokerContacts.map(bkr =>
                                <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["brokerContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["brokerContactId"]}</span>

                    <label className="input-group" htmlFor="brokerReferenceNumber">
                        <span className="label">Broker Ref #</span>
                        <input className='input' type='text' maxLength={20} id="brokerReferenceNumber" name="brokerReferenceNumber" value={props.clientDetails.brokerReferenceNumber} onChange={(e) => handleClientChange(e)}></input>
                    </label>
                </div>
                <div className={multipleInsurers ? "input-group-parent" : "hidden" }>
                    {multiInsurers}

                    {errors["splitPercentage"] ?
                        (errors["splitPercentage"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>
            </div>
            <h4>Resources</h4>
            <label className="input-group" htmlFor="managerId">
                <span className="label">Manager</span>
                <select className="select" id="managerId" name="managerId" value={props.resources.managerId} onChange={(e) => handleResourcesChange(e)}>
                    <option defaultValue="-1" value="-1"></option>
                    {props.managers.map(mgr =>
                        <option key={mgr.id} value={mgr.id}>{mgr.name}</option>
                    )};
                </select>
            </label>

            <label className="input-group" htmlFor="estimatorId">
                <span className="label">Estimator</span>
                <select className="select" id="estimatorId" name="estimatorId" value={props.resources.estimatorId} onChange={(e) => handleResourcesChange(e)}>
                    <option defaultValue="-1" value="-1"></option>
                    {props.estimators.map(est =>
                        <option key={est.id} value={est.id}>{est.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["estimatorId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["estimatorId"]}</span>

            <label className="input-group" htmlFor="supervisorId">
                <span className="label">Supervisor</span>
                <select className="select" id="supervisorId" name="supervisorId" value={props.resources.supervisorId} onChange={(e) => handleResourcesChange(e)}>
                    <option defaultValue="-1" value="-1"></option>
                    {props.supervisors.map(sup =>
                        <option key={sup.id} value={sup.id}>{sup.name}</option>
                    )};
                </select>
            </label>
            <span className={errors["supervisorId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supervisorId"]}</span>

            <button className="defaultbutton-prev defaultbutton-label" onClick={prev} type="button">Prev</button>
            <button className="defaultbutton-next" onClick={next} type="button" disabled={ saveDisabled }>Next</button>
        </form>
    );
}


export default NewJobClientDetail;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface AddClientContactPopupProps {
    parentId: number;
    parentTypeId: number;       //1 = Client, 2 = Supplier
    showAddClientContactModal: boolean;
    hideAddClientContactModal(): void;
    saveAddClientContactModal(contactId: number): void;
}

interface ClientContact {
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    email: string;
}

const AddClientContactPopups = (props: AddClientContactPopupProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [clientContact, setClientContact] = React.useState<ClientContact>({
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: ""
    });

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setSaveDisabled(false);
        setClientContact(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const hideAddClientContactModal = () => {
        setErrors({});
        props.hideAddClientContactModal();
    }

    const validate = () => {
        let fields = clientContact;
        let error: any = {};
        let formIsValid = true;

        if (!fields.firstName) {
            formIsValid = false;
            error["firstName"] = "First Name is required";
        }

        if (!fields.phone && !fields.mobile) {
            formIsValid = false;
            error["phone"] = "Phone or Mobile is required";
        }

        if (!fields.email) {
            formIsValid = false;
            error["email"] = "Email is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const saveAddClientContactModal = () => {
        if (validate()) {

            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveAddClientContact();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveAddClientContact = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var updatedClientContact = {
            id: props.parentId,
            firstName: clientContact.firstName,
            lastName: clientContact.lastName,
            phone: clientContact.phone,
            mobile: clientContact.mobile,
            email: clientContact.email,
            subId: user.sub
        };

        //save new contact
        var api = 'Clients/SaveClientContact';
        if (props.parentTypeId === 2) {
            api = 'Suppliers/SaveContact';
        }

        axios.post(api, updatedClientContact, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            console.log(res.data);
            toast.success("New Contact Saved");

            var contact: ClientContact = {
                firstName: "",
                lastName: "",
                phone: "",
                mobile: "",
                email: ""
            }

            setClientContact(contact);
            setSaveDisabled(false);
            props.saveAddClientContactModal(res.data.contactId);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <Modal size="sm" show={props.showAddClientContactModal} onHide={hideAddClientContactModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add {props.parentTypeId === 1 ? "Client" : "Supplier"} Contact</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideAddClientContactModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <label className="input-group" htmlFor="firstName">
                    <span className="label">First Name</span>
                    <input className='input' type='text' maxLength={100} id="firstName" name="firstName" value={clientContact.firstName} onChange={(e) => handleChange(e)} ></input>
                </label>
                <span className={errors["firstName"] ? "label errors" : "hidden"}>{errors["firstName"]}</span>
                <label className="input-group" htmlFor="lastName">
                    <span className="label">Last Name</span>
                    <input className='input' type='text' maxLength={100} id="lastName" name="lastName" value={clientContact.lastName} onChange={(e) => handleChange(e)} ></input>
                </label>
                <label className="input-group" htmlFor="phone">
                    <span className="label">Phone</span>
                    <input className='input' type='text' maxLength={30} id="phone" name="phone" value={clientContact.phone} onChange={(e) => handleChange(e)} ></input>
                </label>
                <label className="input-group" htmlFor="mobile">
                    <span className="label">Mobile</span>
                    <input className='input' type='text' maxLength={30} id="mobile" name="mobile" value={clientContact.mobile} onChange={(e) => handleChange(e)} ></input>
                </label>
                <span className={errors["phone"] ? "label errors" : "hidden"}>{errors["phone"]}</span>
                <label className="input-group" htmlFor="email">
                    <span className="label">Email</span>
                    <input className='input' type='text' maxLength={200} id="email" name="email" value={clientContact.email} onChange={(e) => handleChange(e)} ></input>
                </label>
                <span className={errors["email"] ? "label errors" : "hidden"}>{errors["email"]}</span>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={saveAddClientContactModal} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )
}

export default AddClientContactPopups;
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface GenericList {
    id: number;
    name: string;
}

const ReportEstActualCompletionData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [supervisors, setSupervisors] = React.useState<GenericList[]>([]);
    const [filter, setFilter] = React.useState({ startDate: "", endDate: "", supervisorId: -1 });
    const [errors, setErrors] = React.useState("");

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async() => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunEstimatedActualCompletionReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Users/GetSupervisors?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    var supervisors = res.data;
                    setSupervisors(supervisors);
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any) => {
        setFilter(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        setErrors("");
    }

    const validate = () => {
        let errors = "";
        let formIsValid = true;

        if (!filter.startDate) {
            formIsValid = false;
            errors = "Start Date is required";
        }

        if (!filter.endDate) {
            formIsValid = false;
            if (errors) {
                errors += ", End Date is required";
            } else {
                errors = "End Date is required";
            }
        }

        if (filter.startDate && filter.endDate && filter.endDate < filter.startDate) {
            formIsValid = false;
            if (errors) {
                errors += ", Start Date must be < End Date";
            } else {
                errors = "Start Date must be < End Date";
            }
        }

        setErrors(errors);
        return formIsValid;
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data

        if (validate()) {
            var url = "/report/EstActualCompletion/" + filter.startDate + "/" + filter.endDate + "/" + filter.supervisorId;;
            window.open(url, '_blank');
        } else {
            toast.error("Please fix the validation issues before running report");
        }
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        if (validate()) {
            exportToExcel();
        } else {
            toast.error("Please fix the validation issues before running report");
        }
    }

    const exportToExcel = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios('Report/ExportEstActualCompletionData?startDate=' + filter.startDate + "&endDate=" + filter.endDate + "&supervisorId=" + filter.supervisorId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Completion Date.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
}

    const renderDetails = (
        <div>
            <div>
                <h1>Estimated vs Actual Completion Date Report</h1>
            </div>
            <div className="marginTop20 marginBottom10">
                <div>Select a Start Date and End Date to run the report for jobs with jobs completed (or invoiced if no completion date) between the selected dates.</div>
                <div>Select a Supervisor to filter the report</div>
                <div>Note: ONLY jobs with an Estimated Completion Date will be displayed</div>
            </div>
            <div>
                <label className="input-group" htmlFor="startDate">
                    <span className="label">Start Date</span>
                    <input type='date' id="startDate" name="startDate" className='input' value={filter.startDate} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="endDate">
                    <span className="label">End Date</span>
                    <input type='date' id="endDate" name="endDate" className='input' value={filter.endDate} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="supervisorId">
                    <span className="label">Supervisor</span>
                    <select className="select" id="supervisorId" name="supervisorId" value={filter.supervisorId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {supervisors.map(supervisor =>
                            <option key={supervisor.id} value={supervisor.id}>{supervisor.name}</option>
                        )};
                    </select>
                </label>

                <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
            <div>
                <span className="label errors report-error-align">{errors}</span>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportEstActualCompletionData;
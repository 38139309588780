import * as React from 'react';

interface UserPermissionProps {
    id: number;
    userPermissions: UserPermissions[];
    update(userPermissions: UserPermissions[]): void;
    save(): void;
}

interface UserPermissions {
    id: number;
    name: string;
    group: string;
    showGroup: boolean;
    selected: boolean;
}

const UserPermissionData = (props: UserPermissionProps) => {
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const onCheckboxChange = (id: number, value: boolean) => {
        setSaveDisabled(false);

        var userPermissions = props.userPermissions;
        var userPermission = userPermissions.find(x => x.id === id);
        if (userPermission) {
            userPermission.selected = value;
            props.update(userPermissions);
        }
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);

        props.save();
    }

    let userPermissions = !props.userPermissions ? [] : props.userPermissions;

    return (
        <form name="user" onSubmit={save}>
            <div>
                {userPermissions.map((permission, index) =>
                    <div key={permission.id}>
                        <div className={permission.showGroup ? "user__permission-group" : "hidden"}>{permission.group}</div>
                        <label key={permission.id} className="input-group" htmlFor="permission">
                            <span className="label"></span>
                            <input type='checkbox' className="input-control checkboxlist--margin" checked={permission.selected} onChange={(e) => onCheckboxChange(permission.id, e.target.checked)} />
                            <span>{permission.name}</span>
                        </label>
                    </div>
                )}

                <button className="defaultbutton-prev defaultbutton-label marginTop10" type="submit" disabled={ saveDisabled }>Save</button>
            </div>
        </form>
    );
}

export default UserPermissionData;
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface GenericList {
    id: number;
    name: string;
}

const ReportJobInvoicedProfitData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [insurers, setInsurers] = React.useState<GenericList[]>([]);
    const [claimTypes, setClaimTypes] = React.useState<GenericList[]>([]);
    const [claimStatus, setClaimStatus] = React.useState<GenericList[]>([]);
    const [filter, setFilter] = React.useState({ startDate: "", endDate: "", insurerId: -1, claimTypeId: -1, claimStatusId: -1 });
    const [errors, setErrors] = React.useState("");

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunJobsInvoicedProfitReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                if (res.data === true) {
                    axios.get('Report/GetInvoicedProfitDropdowns', {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                        .then(res => {
                            setInsurers(res.data.insurers);
                            setClaimTypes(res.data.claimTypes);
                            setClaimStatus(res.data.claimStatus);
                            setLoading(false);
                        })
                        .catch(error => {
                            toast.error(error.message);
                        });
                } else if (res.data === false) {
                    //redirect to 403 permission denied
                    navigate("/accessdenied");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    const handleChange = (e: any) => {
        setFilter(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        setErrors("");
    }

    const validate = () => {
        let errors = "";
        let formIsValid = true;

        if (!filter.startDate) {
            formIsValid = false;
            errors = "Start Date is required";
        }

        if (!filter.endDate) {
            formIsValid = false;
            if (errors) {
                errors += ", End Date is required";
            } else {
                errors = "End Date is required";
            }
        }

        if (filter.startDate && filter.endDate && filter.endDate < filter.startDate) {
            formIsValid = false;
            if (errors) {
                errors += ", Start Date must be < End Date";
            } else {
                errors = "Start Date must be < End Date";
            }
        }

        setErrors(errors);
        return formIsValid;
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data

        if (validate()) {
            var url = "/report/JobsInvoicedProfit/" + filter.startDate + "/" + filter.endDate + "/" + filter.insurerId + "/" + filter.claimTypeId + "/" + filter.claimStatusId;
            window.open(url, '_blank');
        } else {
            toast.error("Please fix the validation issues before running report");
        }
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        if (validate()) {
            exportToExcel();
        } else {
            toast.error("Please fix the validation issues before running report");
        }
    }

    const exportToExcel = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios('Report/ExportJobsInvoicedProfit?startDate=' + filter.startDate + "&endDate=" + filter.endDate + "&insurerId=" + filter.insurerId + "&claimTypeId=" + filter.claimTypeId + "&claimStatusId=" + filter.claimStatusId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Jobs Invoiced Profit.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Jobs Invoiced Profit Report</h1>
            </div>
            <div className="marginTop20">
                <span>Select a Start Date and End Date to run the report for jobs with Invoiced date between the selected dates. Select an Insurer, Claim Type, Claim Status to filter report.</span>
            </div>
            <div>
                <label className="input-group" htmlFor="startDate">
                    <span className="label">Start Date</span>
                    <input type='date' id="startDate" name="startDate" className='input' value={filter.startDate} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="endDate">
                    <span className="label">End Date</span>
                    <input type='date' id="endDate" name="endDate" className='input' value={filter.endDate} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="insurerId">
                    <span className="label">Insurer</span>
                    <select className="select" id="insurerId" name="insurerId" value={filter.insurerId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {insurers.map(insurer =>
                            <option key={insurer.id} value={insurer.id}>{insurer.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="claimTypeId">
                    <span className="label">Claim Type</span>
                    <select className="select" id="claimTypeId" name="claimTypeId" value={filter.claimTypeId} onChange={(e) => handleChange(e)} >
                        <option hidden defaultValue="-1"></option>
                        {claimTypes.map(type =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="claimStatusId">
                    <span className="label">Claim Status</span>
                    <select className="select" id="claimStatusId" name="claimStatusId" value={filter.claimStatusId} onChange={(e) => handleChange(e)} >
                        <option hidden defaultValue="-1"></option>
                        <option key={1} value={1}>BAU</option>
                        <option key={2} value={2}>CAT</option>
                    </select>
                </label>

                <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
            <div>
                <span className="label errors report-error-align">{errors}</span>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportJobInvoicedProfitData;
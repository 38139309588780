import * as React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface Invoice {
    id: number;
    jobId: number;
    siteAddress: string;
    customerName: string;
    dueDate: string;
    type: string;
    total: number;
    totalFormatted: string;
}

const InvoiceListData = () => {
    const [loading, setLoading] = React.useState(true);
    const [invoices, setInvoices] = React.useState<Invoice[]>([]);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Jobs/GetUnsentInvoices?SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setInvoices(res.data.invoices);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div className='overflowAuto marginTop10'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th>Inv Number</th>
                            <th>Site Address</th>
                            <th>Job Id</th>
                            <th>Type</th>
                            <th>Due Date</th>
                            <th className="textalignright">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice, index) =>
                            <tr key={index}>
                                <td className="table__text--align">
                                    <a href={"/client/invoicedetails/" + invoice.jobId + "/" + invoice.id}>
                                        {invoice.id}
                                    </a>
                                </td>
                                <td>{invoice.siteAddress}</td>
                                <td><NavLink target={"_blank"} to={'/job/' + invoice.jobId + "/invoices"}>{invoice.jobId}</NavLink></td>
                                <td>{invoice.type}</td>
                                <td>{invoice.dueDate}</td>
                                <td className="table__text--align textalignright">{invoice.totalFormatted}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>

    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        <h1>Send Client Invoices</h1>
        {contents}
    </div>)
}

export default InvoiceListData;
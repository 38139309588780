import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const XeroRedirectData = () => {
    let navigate = useNavigate();

    React.useEffect(() => {
        //get the query parameters
        const params = new URLSearchParams(window.location.search);
        var code = "";
        if (params.has('code')) {
            code = params.get('code') ?? "";
        };
        var state = "";
        if (params.has('state')) {
            state = params.get('state') ?? "";
        }

        //decode state parameter
        var actual = JSON.parse(atob(state));

        updateData(code, actual);
    }, []);

    
    const updateData = async(code: string, actual: any) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save the quick books code and realmid
        var authDetails = {
            code: code,
            subId: user.sub
        };

        if (code) {
            //save the quick books authentication details
            axios.post('Xero/SaveAuthentication', authDetails, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                //redirect to the appropriate page
                var redirect = "/job/" + actual.id + "/" + actual.page;

                if (actual.page === "client") {
                    //redirect to client page rather than a job page
                    redirect = "/" + actual.page + "/" + actual.id;
                } else if (actual.page === "supplier") {
                    //redirect to supplier page rather than a job page
                    redirect = "/" + actual.page + "/" + actual.id;
                } else if (actual.page === "supplierinvoices") {
                    redirect = "/supplier/invoices";
                }

                navigate(redirect);
            })
            .catch(error => {
                toast.error(error.message);
            });
        }
    }

    return <div>
        Redirecting...
    </div>;
}

export default XeroRedirectData;
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const win: any = window;
const Stimulsoft = win.Stimulsoft;

const ReportViewerData = () => {
    let navigate = useNavigate();
    const { name, param1, param2, param3, param4, param5 } = useParams();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let reportName = name ?? "";
        let p1 = param1 ?? "";
        let p2 = param2 ?? "";
        let p3 = param3 ?? "";
        let p4 = param4 ? Number(param4) : -1;
        let p5 = param4 ? Number(param5) : -1;

        if (reportName != "") {
            getData(reportName, p1, p2, p3, p4, p5);
        }
    }, []);

    const getData = async(reportName: string, param1: string, param2: string, param3: string, param4: number, param5: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //get the data
        axios.get('Report/Get' + reportName + 'Data?param1=' + param1 + "&param2=" + param2 + "&param3=" + param3 + "&param4=" + param4 + "&param5=" + param5 + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setLoading(false);

                var viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

                //console.log('Creating a new report instance');
                var report = new Stimulsoft.Report.StiReport();
                var reportFile = Stimulsoft.System.IO.File.getFile("reportfiles/" + res.data.reportFileName);
                report.load(reportFile);

                var reportData = JSON.stringify(res.data.reportData);
                //Dataset created
                var dataSet = new Stimulsoft.System.Data.DataSet("data");
                dataSet.readJson(reportData)
                report.regData(dataSet.dataSetName, "", dataSet);
                report.dictionary.connect(false);
                report.dictionary.synchronize();

                //add relationships between data
                //for (var i = 0; i < res.data.reportRelationships.length; i++) {
                //    var dataRelation = new Stimulsoft.Report.Dictionary.StiDataRelation(res.data.reportRelationships[i].sourceName, res.data.reportRelationships[i].name, res.data.reportRelationships[i].alias, report.dictionary.dataSources.getByName(res.data.reportRelationships[i].parentTableName), report.dictionary.dataSources.getByName(res.data.reportRelationships[i].childTableName), [res.data.reportRelationships[i].parentFieldName], [res.data.reportRelationships[i].childFieldName]);
                //    report.dictionary.relations.add(dataRelation);
                //    report.dictionary.synchronize();
                //}

                viewer.report = report;
                viewer.renderHtml('viewer');
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div id="viewer"></div>
        </div>
    )
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

}

export default ReportViewerData;

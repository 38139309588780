import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';

interface EstimateDetailsSplitProps {
    canEdit: boolean;
    pageData: PageChangeData,
    jobId: number;
    costCodeList: GenericListItem[],
    uomList: GenericListItem[],
    labMatList: GenericListItem[],
    changePage(pageData: PageChangeData): void;
}

interface EstimateDetailsSplit {
    itemIndex: number;
    id: number;
    detailId: number;
    scopeOrder: number;
    scope: string;
    costCodeId: number;
    costCode: string;
    labMatId: number;
    labMat: string;
    quantity: number;
    unitOfMeasureId: number;
    unitOfMeasure: string;
    unitCost: number;
    unitCostFormatted: string;
    subTotal: number;
    subTotalFormatted: string;
    isDirty: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface PageChangeData {
    estimateId: number;
    page: string;
    id: number;
    headerText: string;
    costCodeId: number;
    prevId: number; //for going back from split to details
    prevHeaderText: string;
}

const EstimateDetailsSplitData = (props: EstimateDetailsSplitProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [estimateDetailsSplit, setEstimateDetailsSplit] = React.useState<EstimateDetailsSplit[]>([]);
    const [estimateSplitTotal, setEstimateSplitTotal] = React.useState("$0.00");
    const [estimateSubTotalExGstOrig, setEstimateSubTotalExGstOrig] = React.useState(0);
    const [estimateTotals, setEstimateTotals] = React.useState({ estimateSubTotalExGst: "$0.00", estimateGst: "$0.00", estimateTotal: "$0.00" });     //estimateSubTotalExGstOrig - orig totals exclude current detail totals so I can modify the total when the details change
    const [percentages, setPercentages] = React.useState({ gstPercentage: 0.1, detailMarkupPercentage: 0, globalMarkupPercentage: 0 });
    const [approved, setApproved] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [estimateIndex, setEstimateIndex] = React.useState(-1);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        var detailId = props.pageData.id;

        axios.get('Estimate/GetEstimateDetailSplit?JobId=' + props.jobId + '&DetailId=' + detailId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //add blank header row
            var estDetailSplit = res.data.estimateDetailSplit;
            //update item index
            for (var i = 0; i < estDetailSplit.length; i++) {
                estDetailSplit[i].itemIndex = i;
                estDetailSplit[i].unitCost = (estDetailSplit[i].unitCost).toFixed(2);
                estDetailSplit[i].subTotal = (estDetailSplit[i].subTotal).toFixed(2);
            }
            if (!res.data.approved && props.canEdit) {
                var blankRow = addBlankRow(estDetailSplit.length);
                estDetailSplit.push(blankRow);
            }
            setApproved(res.data.approved);
            setEstimateDetailsSplit(estDetailSplit);
            setEstimateSplitTotal(res.data.estimateSplitTotal);
            setEstimateSubTotalExGstOrig(res.data.estimateSubTotalExGstOrig);
            setEstimateTotals({ estimateSubTotalExGst: res.data.estimateSubTotalExGst, estimateGst: res.data.estimateGst, estimateTotal: res.data.estimateTotal });
            setPercentages({ gstPercentage: res.data.gstPercentage, detailMarkupPercentage: res.data.detailMarkupPercentage, globalMarkupPercentage: res.data.globalMarkupPercentage });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var blankRow: EstimateDetailsSplit = {
            itemIndex: nextItemIndex,
            id: 0,
            detailId: props.pageData.id,
            scopeOrder: 0,
            scope: "",
            costCodeId: !props.pageData.costCodeId ? -1 : props.pageData.costCodeId,
            costCode: "",
            labMatId: -1,
            labMat: "",
            quantity: 0,
            unitOfMeasureId: -1,
            unitOfMeasure: "",
            unitCost: 0,
            unitCostFormatted: "",
            subTotal: 0,
            subTotalFormatted: "",
            isDirty: false
        }
        return blankRow;
    }

    //BACK Button
    const back = () => {
        //if isDirty - ask if want to save changes
        var changed = estimateDetailsSplit.filter(data => data.isDirty);
        if (changed.length > 0) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);
            saveEstimate(true);
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            estimateId: props.pageData.estimateId,
            id: props.pageData.prevId,
            page: "details",
            headerText: props.pageData.prevHeaderText,
            costCodeId: -1,
            prevId: 0,
            prevHeaderText: ""
        };

        //validate and save before opening
        props.changePage(pageChange);
    }
    //End BACK Button

    //Change Events
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var estimateSplits = [...estimateDetailsSplit];
        var split = estimateSplits[index];
        let exist: any = {};
        exist = split;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (e.target.name === "costCodeId" || e.target.name === "labMatId" || e.target.name === "unitOfMeasureId") {
            //set the description fields
            var itemName = e.target.name.replace("Id", "");
            exist[itemName] = e.target.options[e.target.selectedIndex].text;
        }

        if (index === (estimateSplits.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(estimateSplits.length);
            estimateSplits.push(newRow);
        }

        setEstimateDetailsSplit(estimateSplits);
        setSaveDisabled(false);
        setErrors({});
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var estimateSplits = [...estimateDetailsSplit];
        var split = estimateSplits[index];
        let exist: any = {};
        exist = split;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        //calculate unit cost / total
        if (e.target.name === "subTotal" && split.quantity > 0) {
            var unitCost = ((e.target.value * 1) / split.quantity);
            exist["unitCost"] = unitCost.toFixed(2);
            exist["unitCostFormatted"] = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            var subTotal = e.target.value * 1;
            exist["subTotalFormatted"] = subTotal.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        } else if (e.target.name === "unitCost" && split.quantity > 0) {
            var subTotal = (e.target.value * 1 * split.quantity);
            exist["subTotal"] = subTotal.toFixed(2);
            exist["subTotalFormatted"] = subTotal.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
            var unitCost = e.target.value * 1;
            exist["unitCostFormatted"] = unitCost.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        } else {
            //quantity
            var subTotal = (e.target.value * 1 * split.unitCost);
            exist["subTotal"] = subTotal.toFixed(2);
            exist["subTotalFormatted"] = subTotal.toLocaleString('en-AU', {
                style: 'currency',
                currency: 'AUD'
            });
        }

        if (index === (estimateSplits.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(estimateSplits.length);
            estimateSplits.push(newRow);
        }

        var estimateTotals = calculateTotal(estimateSplits);
        var total = (estimateTotals.total).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateSubTotalExGst = (estimateTotals.estimateSubTotalExGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateGst = (estimateTotals.estimateGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateTotal = (estimateTotals.estimateTotal).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        setEstimateDetailsSplit(estimateSplits);
        setEstimateSplitTotal(total);
        setEstimateTotals({ estimateSubTotalExGst: estimateSubTotalExGst, estimateGst: estimateGst, estimateTotal: estimateTotal });
        setSaveDisabled(false);
        setErrors({});
    }

    //capture shortcut keys
    const handleKeyUp = (itemIndex: number, e: any) => {
        if (e.ctrlKey && e.keyCode === 51) {
            e.preventDefault();
            //go up
            back();
        }
    }

    const calculateTotal = (estimateDetailSplit: EstimateDetailsSplit[]) => {
        var total = 0;
        for (var i = 0; i < estimateDetailSplit.length - 1; i++) {
            total += parseFloat((estimateDetailSplit[i].quantity * estimateDetailSplit[i].unitCost).toFixed(2));
        }
        
        var totalIncItemMarkup = total + (total * percentages.detailMarkupPercentage);
        var totalIncGlobalMarkup = totalIncItemMarkup + (totalIncItemMarkup * percentages.globalMarkupPercentage);

        var estimateSubTotalExGst = estimateSubTotalExGstOrig + totalIncGlobalMarkup;
        var estimateGst = estimateSubTotalExGst * percentages.gstPercentage;
        var estimateTotal = estimateSubTotalExGst + estimateGst;

        var totals = {
            total: total,
            estimateSubTotalExGst: estimateSubTotalExGst,
            estimateGst: estimateGst,
            estimateTotal: estimateTotal
        };

        return totals;
    }
    //End Change Events

    //delete row
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setEstimateIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setEstimateIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        var estDetailsSplit = [...estimateDetailsSplit];
        var estDetailSplit = estimateDetailsSplit[estimateIndex];

        //remove from list
        var removedItem = estDetailsSplit.splice(estimateIndex, 1);

        //loop through the headers and update the order
        var hasBlankRow = !approved && props.canEdit;
        var length = hasBlankRow ? estDetailsSplit.length - 1 : estDetailsSplit.length;
        for (var i = 0; i < estDetailsSplit.length; i++) {
            estDetailsSplit[i].itemIndex = (i);
            if (i < length) {
                //don't want to update the scope order for the blank row
                estDetailsSplit[i].scopeOrder = (i + 1);
            }
        }

        if (estDetailSplit.id === 0) {
            deleteItemUpdateOrder(estDetailsSplit, removedItem);
        } else {
            deleteEstimateItem(estDetailsSplit, removedItem);
        }
    }

    const deleteItemUpdateOrder = async (estDetailsSplit: EstimateDetailsSplit[], removedItem: EstimateDetailsSplit[]) => {
        const token = await authService.getAccessToken();

        var estimateTotals = calculateTotal(estDetailsSplit);
        var total = (estimateTotals.total).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateSubTotalExGst = (estimateTotals.estimateSubTotalExGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateGst = (estimateTotals.estimateGst).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        var estimateTotal = (estimateTotals.estimateTotal).toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });

        //save new details order
        axios.post('Estimate/DeleteEstimateDetailSplitUpdateOrder', estDetailsSplit, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                estDetailsSplit.splice(estimateIndex, 0, removedItem[0]);

                setEstimateDetailsSplit(estDetailsSplit);
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(-1);
            } else {
                setEstimateDetailsSplit(estDetailsSplit);
                setEstimateSplitTotal(total);
                setEstimateTotals({ estimateSubTotalExGst: estimateSubTotalExGst, estimateGst: estimateGst, estimateTotal: estimateTotal });
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(-1);

                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteEstimateItem = async (estDetailsSplit: EstimateDetailsSplit[], removedItem: EstimateDetailsSplit[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var deleteDetail = {
            estimateId: props.pageData.estimateId,
            id: removedItem[0].id,
            jobId: props.jobId,
            estimateDetailsOrder: estDetailsSplit,
            subId: user.sub
        };

        axios.post('Estimate/DeleteEstimateDetailSplit', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                estDetailsSplit.splice(estimateIndex, 0, removedItem[0]);
                setEstimateDetailsSplit(estDetailsSplit);
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(-1);
            } else {
                toast.success("Item has been deleted");

                var estimateTotals = calculateTotal(estDetailsSplit);
                var total = (estimateTotals.total).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateSubTotalExGst = (estimateTotals.estimateSubTotalExGst).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateGst = (estimateTotals.estimateGst).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });
                var estimateTotal = (estimateTotals.estimateTotal).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                });

                setEstimateDetailsSplit(estDetailsSplit);
                setEstimateSplitTotal(total);
                setEstimateTotals({ estimateSubTotalExGst: estimateSubTotalExGst, estimateGst: estimateGst, estimateTotal: estimateTotal });
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setEstimateIndex(-1);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end delete row

    //save
    const validate = () => {
        let errorsList: any = {};
        let formIsValid = true;
        let items = estimateDetailsSplit;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length < 2) {
            errorString = "Please enter at least one Item!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (items[i].costCodeId < 0) {
                    newErrorString += " Cost Code, ";
                    error = true;
                }

                if (items[i].labMatId < 0) {
                    newErrorString += " Lab/Mat Split, ";
                    error = true;
                }

                if (items[i].quantity === 0) {
                    newErrorString += " Quantity, ";
                    error = true;
                }

                if (items[i].unitCost === 0) {
                    newErrorString += " Unit Cost, ";
                    error = true;
                }

                if (newErrorString !== "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorsList["split"] = errorString;
        }
        setErrors(errorsList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        
        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        saveEstimate(false);
    }

    const saveEstimate = async (goBackPage: boolean) => {
        if (validate()) {
            var splits = [...estimateDetailsSplit];

            //Only save items that have changed
            var changed = splits.filter(data => data.isDirty);
            if (changed.length === 0) {
                toast.info("No items have been changed!");
            } else {
                const token = await authService.getAccessToken();
                const user = await authService.getUser();

                var estDetailSplit = {
                    estimateId: props.pageData.estimateId,
                    jobId: props.jobId,
                    detailId: props.pageData.id,
                    estimateDetailSplit: changed,
                    subId: user.sub
                };

                axios.post('Estimate/SaveEstimateDetailsSplit', estDetailSplit, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        toast.success("Estimate Saved");
                        if (goBackPage) {
                            goBack();
                        } else {
                            changed = res.data.estimateDetailSplit;
                            //loop through the changed items and update the main list
                            for (var i = 0; i < changed.length; i++) {
                                splits[changed[i].itemIndex] = changed[i];
                            }
                            setEstimateDetailsSplit(splits);
                            setErrors({});
                        }
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }
    //end save

    //MOVE ROW
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var estDetailsSplit = [...estimateDetailsSplit];
        var hasBlankRow = !approved && props.canEdit;

        var estDetailsLength = estDetailsSplit.length - 1;

        if ((hasBlankRow && oldIndex != estDetailsLength && newIndex != estDetailsLength) || !hasBlankRow) {
            //only move if not blank row
            estDetailsSplit.splice(newIndex, 0, estDetailsSplit.splice(oldIndex, 1)[0]);

            //loop through the headers and update the order
            var length = hasBlankRow ? estDetailsSplit.length - 1 : estDetailsSplit.length;
            for (var i = 0; i < length; i++) {
                estDetailsSplit[i].itemIndex = (i);
                estDetailsSplit[i].scopeOrder = (i + 1);
                estDetailsSplit[i].isDirty = true;
            }

            setEstimateDetailsSplit(estDetailsSplit);
            saveEstimate(false);
        }
    };
    //END MOVE ROW

    let confirmPopup = <ConfirmModal heading="Delete Estimate Row" text="Are you sure you want to delete this estimate detail row?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    let lastRow = estimateDetailsSplit.length - 1;

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {confirmBack}
            </div>
            <div className="estimate__backButton">
                <h3>{props.pageData.headerText}</h3>
                <button type="button" className="defaultbutton" onClick={back}>Back</button>
            </div>

            <div className='overflowAuto'>
                <table className="table--main table__extralarge tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th></th>
                            <th>Notes</th>
                            <th>Cost Code</th>
                            <th>Lab/Mat</th>
                            <th>Qty</th>
                            <th>UOM</th>
                            <th className="textalignright">Unit Cost</th>
                            <th className="textalignright">Sub Total</th>
                            <th className={approved || !props.canEdit ? "hidden" : ""}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {estimateDetailsSplit.map((split, itemIndex) =>
                            <tr key={itemIndex} draggable="true" onDragStart={(e) => handleDragStart(e, itemIndex)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, itemIndex)}>
                                <td className="hidden">{split.id}</td>
                                <td className="table__text--align">{split.scopeOrder == 0 ? itemIndex + 1 : split.scopeOrder}</td>
                                <td className="table__text--align">
                                    <textarea rows={2} className={approved || !props.canEdit ? "hidden" : "form-control estimate__scopeWidth"} name="scope" value={split.scope} onChange={(e) => handleCellChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} />
                                    {approved || !props.canEdit ? split.scope : ""}
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="costCodeId" value={split.costCodeId} onChange={(e) => handleCellChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.costCodeList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="labMatId" value={split.labMatId} onChange={(e) => handleCellChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={approved || !props.canEdit ? true : false}>
                                        <option defaultValue="-1"></option>
                                        {props.labMatList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align estimate__tdWidth">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="quantity" value={split.quantity} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} />
                                    {approved || !props.canEdit ? split.quantity : ""}
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="unitOfMeasureId" value={split.unitOfMeasureId} onChange={(e) => handleCellChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} disabled={approved || !props.canEdit ? true : false}>
                                        <option hidden defaultValue="-1"></option>
                                        {props.uomList.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align estimate__tdWidth textalignright">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="unitCost" value={split.unitCost} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} />
                                    {approved || !props.canEdit ? split.unitCostFormatted : ""}
                                </td>
                                <td className="table__text--align estimate__tdWidth textalignright">
                                    <input type='number' min="0" step="any" className={approved || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="subTotal" value={split.subTotal} onChange={(e) => handleCellAmountChange(itemIndex, e)} onKeyUp={(e) => handleKeyUp(itemIndex, e)} />
                                    {approved || !props.canEdit ? split.subTotalFormatted : ""}
                                </td>
                                <td className={approved || !props.canEdit ? "hidden" : "table__text--align"}>
                                    <div className={itemIndex === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(itemIndex, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["split"] ?
                    (errors["split"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div>
                <div className="input-group-parent">
                    <label className="input-group estimate__width" htmlFor="subTotalExGstFormatted">
                        <span className="label">Estimate Sub Total ex GST</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalExGstFormatted" name="subTotalExGstFormatted" value={estimateTotals.estimateSubTotalExGst} disabled></input>
                    </label>
                    <label className="input-group" htmlFor="gstFormatted">
                        <span className="label">Estimate GST</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={estimateTotals.estimateGst} disabled></input>
                    </label>
                    <label className="input-group" htmlFor="totalFormatted">
                        <span className="label">Estimate Total</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={estimateTotals.estimateTotal} disabled></input>
                    </label>
                </div>
                <div className="input-group-parent">
                    <label className="input-group estimate__alignTotals" htmlFor="estimateTotal">
                        <span className="label textalignright">Total</span>
                        <input className="input estimate__totalsWidth textalignright" type="text" id="estimateTotal" name="estimateTotal" value={estimateSplitTotal} disabled></input>
                    </label>
                </div>
            </div>

            <button className="defaultbutton hideMobile" type="submit" disabled={approved || !props.canEdit || saveDisabled}>Save</button>
        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

}

export default EstimateDetailsSplitData;
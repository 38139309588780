import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';

interface GenericList {
    id: number;
    name: string;
}

const ReportJobEstimatorData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [estimators, setEstimators] = React.useState<GenericList[]>([]);
    const [estimatorId, setEstimatorId] = React.useState(-1);
    const [errors, setErrors] = React.useState("");

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=RunEstimatorReport', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Users/GetEstimators?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    var estimators = res.data;
                    setEstimators(estimators);
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any) => {
        setEstimatorId(e.target.value);
        setErrors("");
    }

    const runReport = (e: any) => {
        e.preventDefault();
        //run the report and export the data
        var url = "/report/JobEstimator/-1/-1/" + estimatorId;
        window.open(url, '_blank');
    }

    const exportReportToExcel = (e: any) => {
        e.preventDefault();

        exportToExcel();
    }

    const exportToExcel = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios('Report/ExportJobEstimatorData?estimatorId=' + estimatorId + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(res => {
            //DOWNLOAD FILE TO BROWSER
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Estimator Jobs.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div>
                <h1>Estimator Report</h1>
            </div>
            <div className="marginTop20">
                <span>Select an Estimator to filter report.</span>
            </div>
            <div>
                <label className="input-group" htmlFor="estimatorId">
                    <span className="label">Estimator</span>
                    <select className="select" id="estimatorId" name="estimatorId" value={estimatorId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {estimators.map(estimator =>
                            <option key={estimator.id} value={estimator.id}>{estimator.name}</option>
                        )};
                    </select>
                </label>

                <button className="defaultbutton-prev defaultbutton-prev__split" type='button' onClick={runReport}>Run Report</button>
                <button className="defaultbutton-next" type='button' onClick={exportReportToExcel}>Export to Excel</button>
            </div>
            <div>
                <span className="label errors report-error-align">{errors}</span>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
};

export default ReportJobEstimatorData;

interface JobTabsMobileProps {
    id: number;
    tab: string;
    canViewEstimate: boolean;
    canViewCostings: boolean;
    showAssessment: boolean;
    tabChanged(url: string): void;
}

const JobTabsMobileData = (props: JobTabsMobileProps) => {
    const jobUrl = "/job/" + props.id;

    return (
        <div>
            <div className="bottom-nav">
                <a className="mobile-icon-white" href={jobUrl + "/detailsm"}>
                    <span className="fas fa-address-card mobileMenuSize alignIconCenter"></span>
                    <span>Details</span>
                </a>
                <a className={props.canViewEstimate ? "mobile-icon-white" : "hidden"} href={jobUrl + "/estimatem"}>
                    <span className="fas fa-calculator mobileMenuSize alignIconCenter"></span>
                    <span>Estimate</span>
                </a>
                <a className={props.canViewEstimate && props.showAssessment ? "mobile-icon-white" : "hidden"} href={jobUrl + "/assessmentm"}>
                    <span className="fas fa-calculator mobileMenuSize alignIconCenter"></span>
                    <span>Assessment</span>
                </a>
                <a className={props.canViewCostings && !props.showAssessment ? "mobile-icon-white" : "hidden"} href={jobUrl + "/costsm"}>
                    <span className="fas fa-file-invoice-dollar mobileMenuSize alignIconCenter"></span>
                    <span>Costs</span>
                </a>
                <a className={props.canViewCostings && !props.showAssessment ? "mobile-icon-white" : "hidden"} href={jobUrl + "/purchaseordersm"}>
                    <span className="fas fa-cart-arrow-down mobileMenuSize alignIconCenter"></span>
                    <span>P/O</span>
                </a>
                <a className="mobile-icon-white" href={jobUrl + "/notesm"}>
                    <span className="fas fa-edit mobileMenuSize alignIconCenter"></span>
                    <span>Notes</span>
                </a>
                <a className="mobile-icon-white" href={jobUrl + "/signaturem"}>
                    <span className="fas fa-file-signature mobileMenuSize alignIconCenter"></span>
                    <span>Sign</span>
                </a>
            </div>
        </div>
    )
}

export default JobTabsMobileData;


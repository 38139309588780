import * as React from 'react';
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface JobDetailsAddInsurerPopupProps {
    jobId: number;
    insurers: GenericListItem[];
    showModal: boolean;
    hideModal(): void;
}

interface JobInsurerClaimLine {
    itemIndex: number;
    id: number;
    insurerId: number;
    claimNumber: string;
    splitPercentage: number;
    status: number;
}

const initInsurers: JobInsurerClaimLine = {
    itemIndex: 0,
    id: 0,
    insurerId: -1,
    claimNumber: "",
    splitPercentage: 0,
    status: 1
};

interface GenericListItem {
    id: number;
    name: string;
}

const JobDetailsAddInsurerPopup = (props: JobDetailsAddInsurerPopupProps) => {
    const [insurerDetails, setInsurerDetails] = React.useState<JobInsurerClaimLine[]>([initInsurers]);
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const token = await authService.getAccessToken();
        
        axios.get('Jobs/GetMultipleInsurers?JobId=' + props.jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var multiInsur = res.data;
            //add item index
            var newIndex = 1;
            for (var i = 0; i < multiInsur.length; i++) {
                var ins = multiInsur[i];
                ins.itemIndex = newIndex;
                newIndex += 1;
            }

            var newLine = addBlankMultiInsRow(newIndex);
            multiInsur.push(newLine);

            setInsurerDetails(multiInsur);       
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
                
    }

    const addBlankMultiInsRow = (newItemIndex: number) => {
        var blankRow: JobInsurerClaimLine = {
            itemIndex: newItemIndex,
            id: 0,
            insurerId: -1,
            claimNumber: "",
            splitPercentage: 0,
            status: 1
        };

        return blankRow;
    }

    const handleCellChange = (e: any, itemIndex: number) => {
        let insurers = [...insurerDetails];
        let insurer = insurers[itemIndex];
        let change: any = { };
        change = insurer;
        change[e.target.name] = e.target.value; 
        
        //add new blank row
        let lastRow = insurers.length - 1;
        if (itemIndex === lastRow) {
            var newRow = addBlankMultiInsRow(insurer.itemIndex + 1);
            insurers.push(newRow);
        }

        setInsurerDetails(insurers);
    }

    const hideModal = () => {
        props.hideModal();
    }

    const deleteItem = (e: any, itemIndex: number) => {
        e.preventDefault();

        let insurers = [...insurerDetails];
        let insurer = insurers[itemIndex];

        if (insurer.id === 0) {
            //can just delte as not saved
            insurers.splice(itemIndex, 1);
        } else {
            //set status so can delete later
            insurer.status = 0;
        }

        //update item indexes
        let newItemIndex = 1;
        for (var i = 0; i < insurers.length; i++) {
            if (insurers[i].status === 1) {
                insurers[i].itemIndex = newItemIndex;
                newItemIndex += 1;
            }
        }

        setInsurerDetails(insurers);
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        let splitPercent = 0;
        var splitError = "";
        var do100Check = false;
        //make sure the total % split is 100%
        for (var i = 0; i < insurerDetails.length - 1; i++) {
            let insurer = insurerDetails[i];
            if (insurer.status === 1) {
                if (insurer.insurerId <= 0) {
                    splitError += "Row " + insurer.itemIndex + " must have an Insurer selected! \n";
                }

                let itemSplitPercent = insurer.splitPercentage * 1;
                splitPercent += itemSplitPercent;
                if (itemSplitPercent <= 0) {
                    splitError += "Row " + insurer.itemIndex + " must have a split % > 0! \n";
                }
                do100Check = true;  //only want to do this if we haven't deleted the all the multiple insurers
            }
        }

        if (splitPercent != 100 && do100Check) {
            splitError += "Split % MUST add up to 100!";
        }

        if (splitError != "") {
            formIsValid = false;
            error["insurerDetails"] = splitError;
            setErrors(error);
        }

        return formIsValid;
    }

    const saveInsurers = () => {
        if (validate()) {
            saveMultipleInsurers();
        }
    }

    const saveMultipleInsurers = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var multiInsurers = [...insurerDetails];
        multiInsurers.pop();
        
        var saveInsurers = {
            jobId: props.jobId,
            jobMultipleInsurers: multiInsurers,
            subId: user.sub
        };

        axios.post('Jobs/SaveMultipleInsurers', saveInsurers, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
            } else {
                //add blank row back if they re-open
                let newRow = addBlankMultiInsRow(multiInsurers.length + 1);
                multiInsurers.push(newRow);
                setInsurerDetails(multiInsurers);

                props.hideModal();
                toast.success("Insurers have been saved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const lastRow = insurerDetails.length - 1;

    return (
        <Modal size="lg" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add Multiple Insurers</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Add multiple insurers to the job, with a split % for invoicing. NOTE: Split % must add to 100</p>

                    <div className={loading ? '' : 'hidden'}>
                        <p><em>Loading...</em></p>
                    </div>
                    <div className={loading ? 'hidden' : 'overflowAuto'}>
                        <table className="table--main table__small tableColours">
                            <thead>
                                <tr>
                                    <th className="hidden">Id</th>
                                    <th></th>
                                    <th>Insurer</th>
                                    <th>Claim Number</th>
                                    <th>Split %</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {insurerDetails.map((item, index) =>
                                    <tr key={index} className={item.status === 1 ? "" : "hidden" } >
                                        <td className="hidden">{item.id}</td>
                                        <td className="table__text--align">{item.itemIndex}</td>
                                        <td className="table__text--align">
                                            <select className="select" id="insurerId" name="insurerId" value={item.insurerId} onChange={(e) => handleCellChange(e, index)} >
                                                <option defaultValue="-1" value="-1"></option>
                                                {props.insurers.map(ins =>
                                                    <option key={ins.id} value={ins.id}>{ins.name}</option>
                                                )};
                                            </select>
                                        </td>
                                        <td className="table__text--align">
                                            <input type="text" maxLength={20} className="form-control" name="claimNumber" value={item.claimNumber} onChange={(e) => handleCellChange(e, index)} />
                                            
                                        </td>
                                        <td className="table__text--align">
                                            <input type="text" maxLength={100} className="form-control textalignright" name="splitPercentage" value={item.splitPercentage} onChange={(e) => handleCellChange(e, index)} />
                                        </td>
                                        <td className="table__text--align">
                                            <div className={index === lastRow ? "hidden" : "delete--tablecell"}>
                                                <a className="makeitred" href="#" onClick={(e) => deleteItem(e, index)} >
                                                    <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {errors["insurerDetails"] ?
                            (errors["insurerDetails"]).split("\n").map((item: any, key: any) => {
                                return <span className="label errors" key={key}>{item}<br /></span>
                            })
                            : ""}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={saveInsurers}>Save</button>
            </Modal.Footer>
        </Modal>

    )

}

export default JobDetailsAddInsurerPopup;
import * as React from 'react';

interface PagerProps {
    page: number,
    pageSize: number,
    totalRecords: number,
    pageChanged(page: number): void;
}

const Pager = (props: PagerProps) => {
    const [page, setPage] = React.useState(1);

    const pageChanged = (pageNumber: number) => {
        props.pageChanged(pageNumber);
    }

    const firstPage = () => {
        setPage(1);
        pageChanged(1);
    }

    const prevPage = () => {
        var currentPage = props.page;
        var newPage;
        if (currentPage == 1) {
            newPage = 1;
            setPage(1);
            pageChanged(1);
        } else {
            newPage = currentPage - 1;
            setPage(newPage);
            pageChanged(newPage);
        }
    }

    const nextPage = () => {
        setPage(props.page + 1);
        pageChanged(props.page + 1);
    }

    const lastPage = () => {
        var totalPages = (props.totalRecords / props.pageSize) | 0;
        setPage(totalPages + 1);
        pageChanged(totalPages + 1);
    }

    var buttonFirst = "<<";
    var buttonPrev = "<";
    var buttonNext = ">";
    var buttonLast = ">>";

    var totalPages = Math.ceil(props.totalRecords / props.pageSize);

    return (

        <div className="pagingrow">
            <button className={props.page == 1 ? "hidden" : "pagingbutton marginRight10"} onClick={firstPage} type="button">{buttonFirst}</button>
            <button className={props.page == 1 ? "hidden" : "pagingbutton"} onClick={prevPage} type="button">{buttonPrev}</button>
            <span className="pagingbutton--note">Page {props.page} of {totalPages}</span>
            <button className={props.page == totalPages ? "hidden" : "pagingbutton"} onClick={nextPage} type="button">{buttonNext}</button>
            <button className={props.page == totalPages ? "hidden" : "pagingbutton marginLeft10"} onClick={lastPage} type="button">{buttonLast}</button>
        </div>
    )
}

export default Pager;
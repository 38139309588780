import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import JobTabsMobile from './JobTabsMobile';
import Pager from './Paging';
import authService from './api-authorization/AuthorizeService';

interface NoteDetails {
    pageSize: number;
    totalRecords: number;
    jobNotes: NoteList[];
}

interface NoteList {
    id: number;
    noteType: string;
    clientUpdate: boolean;
    insertedDateFormatted: string;
    note: string;
    addedBy: string;
    systemNote: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

const JobNotesMobileData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState({ canViewEstimate: false, canViewCostings: false, showAssessment: false });
    const [noteTypes, setNoteTypes] = React.useState<GenericListItem[]>([]);
    const [noteDetails, setNoteDetails] = React.useState<NoteDetails>({ pageSize: 1, totalRecords: 0, jobNotes: []});
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [showAddNote, setShowAddNote] = React.useState(false);
    const [addNote, setAddNote] = React.useState({ noteTypeId: -1, newNotes: "", newNoteInsurer: false });
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [showSystemNotes, setShowSystemNotes] = React.useState(false);
    const [noteTypeFilter, setNoteTypeFilter] = React.useState("");

    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetNoteDropdowns?JobId=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                var permissions = res.data.userJobPermissions;
                setPermissions({ canViewEstimate: permissions.viewJobEstimate, canViewCostings: permissions.viewJobCostings, showAssessment: res.data.showAssessment });
                setNoteTypes(res.data.noteTypes);

                getNotes(Id, 1, true, "", false);
            }
        });
    }

    const getNotes = async (jobId: number, page: number, getTotalRecords: boolean, filter: string, systemNotes: boolean) => {
        const token = await authService.getAccessToken();
        //get list of notes from database
        var totalRecords = getTotalRecords ? 0 : noteDetails.totalRecords;
        var noteType = filter; // !== "" ? filter : null;

        axios.get('Jobs/GetNotes?jobId=' + jobId + '&page=' + page + '&totalRecords=' + totalRecords + '&getTotalRecords=' + getTotalRecords + '&showSystemNotes=' + systemNotes + '&showClientUpdates=false' + '&noteType=' + noteType, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setLoading(false);
            setPage(page);
            setNoteDetails(res.data);
        })
    }

    const handleAddTypeIdChange = (e: any) => {
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        setSaveDisabled(false);
        let notes = addNote.newNotes;
        let insurer = addNote.newNoteInsurer;
        let typeId = e.target.value * 1;

        setAddNote({ noteTypeId: typeId, newNoteInsurer: insurer, newNotes: notes });
    }

    const handleAddNotesChange = (e: any) => {
        if (e.target.value) {
            delete errors[e.target.name];
            setErrors(errors);
        }

        setSaveDisabled(false);
        let noteType = addNote.noteTypeId;
        let insurer = addNote.newNoteInsurer;

        setAddNote({ noteTypeId: noteType, newNoteInsurer: insurer, newNotes: e.target.value });
    }

    const handleAddInsurerChange = (e: any) => {
        setSaveDisabled(false);
        let noteType = addNote.noteTypeId;
        let notes = addNote.newNotes;
        setAddNote({ noteTypeId: noteType, newNoteInsurer: e.target.checked, newNotes: notes });
    }

    const handleSystemNotesChange = (e: any) => {
        setSaveDisabled(false);
        setShowSystemNotes(e.target.checked);

        getNotes(jobData.jobId, 1, true, noteTypeFilter, e.target.checked);
    }

    const handleTypeChange = (e: any) => {
        setSaveDisabled(false);
        setNoteTypeFilter(e.target.value);

        getNotes(jobData.jobId, 1, true, e.target.value, showSystemNotes);
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    const pageChanged = (pageNumber: number) => {
        setPage(pageNumber);
        getNotes(jobData.jobId, pageNumber, false, noteTypeFilter, showSystemNotes);
    }

    const showAddNewNote = (e: any) => {
        e.preventDefault();

        var showNote = !showAddNote;
        setShowAddNote(showNote);
        setAddNote({ noteTypeId: -1, newNotes: "", newNoteInsurer: false });
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (addNote.noteTypeId <= 0) {
            formIsValid = false;
            error["noteTypeId"] = "Note Type is required";
        }

        if (!addNote.newNotes || addNote.newNotes.length < 5) {
            formIsValid = false;
            error["newNotes"] = "Notes > 5 characters are required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveJobNote();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveJobNote = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let jobNotes = {
            jobId: jobData.jobId,
            noteTypeId: addNote.noteTypeId,
            note: addNote.newNotes,
            insurerUpdate: addNote.newNoteInsurer,
            subId: user.sub
        }

        axios.post('Jobs/SaveJobNote', jobNotes, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            toast.success("Note Saved");
            //then need to clear notes so can resave and add note to list
            var newNote = {
                id: res.data.id,
                noteType: res.data.noteType,
                clientUpdate: res.data.clientUpdate,
                insertedDateFormatted: res.data.insertedDateFormatted,
                note: res.data.note,
                addedBy: res.data.addedBy,
                systemNote: res.data.systemNote
            };
            if (page === 1) {
                //add note to top of list
                noteDetails.jobNotes.unshift(newNote);
                if (noteDetails.jobNotes.length > (noteDetails.pageSize)) {
                    //remove the oldest note as goes onto 2nd page
                    noteDetails.jobNotes.pop();
                }
            }
            setAddNote({ noteTypeId: -1, newNotes: "", newNoteInsurer: false });
            setNoteDetails(noteDetails);
            setShowAddNote(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var jobTabsMobile = <JobTabsMobile id={jobData.jobId} tab="details" canViewEstimate={permissions.canViewEstimate} canViewCostings={permissions.canViewCostings} showAssessment={permissions.showAssessment} tabChanged={tabChanged} />

    let filteredNoteTypes = noteTypes.filter(type =>
        type.id > 0
    )

    var pagerContents;
    if ((noteDetails.totalRecords / noteDetails.pageSize) > 1) {
        //only render if we have records
        pagerContents = <Pager page={page} pageSize={noteDetails.pageSize} totalRecords={noteDetails.totalRecords} pageChanged={pageChanged} />
    }

    const renderDetails = (
        <form>
            <div className="mobileFont marginTop10 marginBottom70">
                <div className="makeitflexspacebetween">
                    <h4 className="mobile-margin">Job Notes</h4>
                    <div className={showAddNote ? "hidden" : "defaultbutton__container--right makeitflexcenter"}>
                        <a className="mobile-icon-green" href="#" onClick={(e) => showAddNewNote(e)}>
                            <span className="fas fa-plus-circle mobileMenuSize alignIconCenter"></span>
                        </a>
                    </div>
                    <div className={showAddNote ? "defaultbutton__container--right makeitflexcenter" : "hidden"}>
                        <a className="mobile-icon-grey" href="#" onClick={(e) => showAddNewNote(e)}>
                            <span className="fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                        </a>
                    </div>
                </div>
                <span className={jobData.isMakeSafe ? "makeSafeJob" : jobData.isReportOnly ? "reportOnlyJob" : ""}>{jobData.siteAddress}</span>
                <div className={showAddNote ? "marginTop10" : "hidden"}>
                    <label className="input-group" htmlFor="noteType">
                        <span className="label-small label-mobile">Type </span>
                        <select className="select" id="noteType" name="noteTypeId" value={addNote.noteTypeId} onChange={(e) => handleAddTypeIdChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            {filteredNoteTypes.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )};
                        </select>
                    </label>
                    <span className="label errors">{errors["noteTypeId"]}</span>

                    <label className="input-group" htmlFor="newNotes">
                        <span className="label-small label-mobile">Notes</span>
                        <textarea className="input job__noteSize" id="newNotes" name="newNotes" value={addNote.newNotes} onChange={(e) => handleAddNotesChange(e)}></textarea>
                    </label>
                    <span className={errors["newNotes"] ? "label errors errors__leftmargin" : "hidden"}>{errors["newNotes"]}</span>

                    <label className="input-group" htmlFor="newNoteInsurer">
                        <span className="label-medium label-mobile">Insurer Update?</span>
                        <input className="checkbox" type="checkbox" id="newNoteInsurer" name="newNoteInsurer" checked={addNote.newNoteInsurer} onChange={(e) => handleAddInsurerChange(e)}></input>
                    </label>

                    <div>
                        <button className="mobile-button mobile-button_green" type="button" onClick={save} disabled={saveDisabled}>Add Note</button>
                    </div>
                </div>
                <div className={showAddNote ? "hidden" : "marginTop10"} >
                    <div className="input-group-parent">
                        <label className="input-group" htmlFor="noteTypeFilter">
                            <span className="label-small label-mobile">Type </span>
                            <select className="select" id="noteTypeFilter" name="noteTypeFilter" value={noteTypeFilter} onChange={(e) => handleTypeChange(e)}>
                                <option defaultValue="-1"></option>
                                {filteredNoteTypes.map(type =>
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                )};
                            </select>
                        </label>
                    </div>
                    <div className="input-group-parent">
                        <label className="input-group job__checkboxPosition" htmlFor="systemFilter">
                            <span className="notes__label--system-size label-mobile">Show System Notes </span>
                            <div>
                                <input type="checkbox" name="showSystemNotes" id="systemFilter" checked={showSystemNotes} onChange={(e) => handleSystemNotesChange(e)}></input>
                            </div>
                        </label>
                    </div>
                    <div className="job__noteGridSize overflowAuto">
                        <table className="table--main table__small tableColours mobileTableFont">
                            <thead>
                                <tr>
                                    <th>Date/Time</th>
                                    <th>Type</th>
                                    <th></th>
                                    <th>Notes</th>
                                    <th>Added</th>
                                </tr>
                            </thead>
                            <tbody>
                                {noteDetails.jobNotes.map((note, index) =>
                                    <tr key={note.id}>
                                        <td>{note.insertedDateFormatted}</td>
                                        <td>{note.noteType}</td>
                                        <td className="textaligncenter"><input className="checkbox" type="checkbox" checked={note.clientUpdate} disabled></input></td>
                                        <td>{note.note}</td>
                                        <td>{note.addedBy}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {pagerContents}
                </div>
            </div>
            <div>
                {jobTabsMobile}
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;

}

export default JobNotesMobileData;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import SupplierInvoicePoSearchResults from './SupplierInvoicePoSearchResults';

interface SupplierInvoicePoReplaceProps {
    invoiceId: number;
    invoiceTotal: number;
    jobId: number;
    showModal: boolean;
    hideModal(): void;
    saveModal(): void;
}

interface SearchResult {
    selected: boolean;
    jobId: number;
    isMakeSafe: boolean;
    siteAddress: string;
    supplier: string;
    supplierId: number;
    supplierRegisteredForGst: boolean;
    xeroContactId: string;
    xeroContactName: string;
    purchaseOrders: SearchResultPo[];
}

interface SearchResultPo {
    selected: boolean;
    purchaseOrderId: number;
    dueDate: string;
    subTotal: number;
    subTotalFormatted: string;
    gst: number;
    gstFormatted: string;
    totalApplied: number;
    total: number;
    totalFormatted: string;
    remainingSubTotal: number;
    remainingSubTotalFormatted: string;
    remainingGst: number;
    remainingGstFormatted: string;
    remainingTotal: number;
    remainingTotalFormatted: string;
}

const SupplierInvoicePoReplacePopup = (props: SupplierInvoicePoReplaceProps) => {
    const [searchFields, setSearchFields] = React.useState({ poSearch: 0, jobIdSearch: props.jobId });
    const [showResults, setShowResults] = React.useState(false);
    const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);

    React.useEffect(() => {
        setSearchFields({ poSearch: 0, jobIdSearch: props.jobId });
        if (props.jobId > 0) {
            searchPo(props.jobId);
        }
    }, [props.jobId]);

    const handleKeyUp = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            search();
        }
    }

    const handleChange = (e: any) => {
        setSearchFields(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const search = () => {
        if (!searchFields.poSearch && searchFields.jobIdSearch === 0) {
            toast.error("Enter at least one search criteria before searching");
        } else {
            searchPo(searchFields.jobIdSearch);
        }
    }

    const searchPo = async (jobIdSearch: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        axios.get('Suppliers/PurchaseOrderReplaceSearch?SupplierInvoiceId=' + props.invoiceId + '&PurchaseOrderNumber=' + searchFields.poSearch + '&JobId=' + jobIdSearch + '&subId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setShowResults(true);
            setSearchResults(res.data);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const replace = () => {
        replacePo();
    }

    const replacePo = async () => {
        const user = await authService.getUser();

        var selectedJob = searchResults.find(s => s.selected);
        let selectedPos: SearchResultPo[] = [];

        //find the selected pos
        for (var x = 0; x < searchResults.length; x++) {
            selectedPos = searchResults[x].purchaseOrders.filter(po => po.selected && po.totalApplied > 0);
            if (selectedPos.length > 0) {
                selectedJob = searchResults[x];
                break;
            }
        }
       

        if (selectedJob) {
            var result = {
                invoiceId: props.invoiceId,
                jobId: selectedJob.jobId,
                supplierId: selectedJob.supplierId,
                chargeGst: selectedJob.supplierRegisteredForGst,
                purchaseOrders: selectedPos,
                subId: user.sub
            };

            //save po details to invoice
            const token = await authService.getAccessToken();
            axios.post('Suppliers/InvoiceReplacePurchaseOrder', result, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });

                } else {
                    setSearchFields({ poSearch: 0, jobIdSearch: props.jobId });
                    setShowResults(false);
                    setSearchResults([]);
                    toast.success("Purchase order/s saved to Invoice");
                    props.saveModal();
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        } else {
            toast.error("Select the purchase order/s associated with this invoice");
        }
    }

    const hideModal = () => {
        setSearchFields({ poSearch: 0, jobIdSearch: props.jobId });
        setShowResults(false);
        setSearchResults([]);
        props.hideModal();
    }

    const updateSearchResults = (results: SearchResult[]) => {
        setSearchResults(results);
    }

    let results = <SupplierInvoicePoSearchResults searchResults={searchResults} invoiceTotal={props.invoiceTotal} showNoPoSearch={false} updateResults={updateSearchResults} />

    return (
        <Modal size="lg" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Replace Purchase Order</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div className="makeitflexspacebetween">
                    <label className="input-group marginRight10" htmlFor="poSearch">
                        <span className="label-small">Order #</span>
                        <input className="input" type="number" id="poSearch" name="poSearch" value={searchFields.poSearch} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleKeyUp(e)}></input>
                    </label>
                    <label className="input-group marginRight10" htmlFor="jobIdSearch">
                        <span className="label-small">Job Id</span>
                        <input className="input" type="number" id="jobIdSearch" name="jobIdSearch" value={searchFields.jobIdSearch} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleKeyUp(e)} ></input>
                    </label>
                    <button className="defaultbutton-prev" type='button' onClick={search}>Search</button>
                </div>
                <hr></hr>
                <div className={showResults ? "" : "hidden"}>
                    {results}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={replace}>Replace</button>
            </Modal.Footer>
        </Modal>
    );

}

export default SupplierInvoicePoReplacePopup;
import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

const AdminListData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=EditAdminLists', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                setLoading(false);
            } else if (res.data === false) {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const renderDetails = (
        <div>
            <div className="adminlists__item">
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/admincostcodes'} >
                    Cost Codes
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/adminshortcuts'} >
                    Estimate Shortcuts
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/adminproducts'} >
                    On-Going Hire Products
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/adminpricelistreports'} >
                    Pricelist for Reports
                </NavLink>
                <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/adminsettings'} >
                    Settings
                </NavLink>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)

};

export default AdminListData;
import * as React from 'react';
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface JobEstimateImportPopupProps {
    saveDisabled: boolean;
    templateJobs: TemplateJobs[],
    showModal: boolean;
    hideModal(): void;
    importModal(jobId: string, headerIds: string): void;
    updateSaveDisabled(saveDisabled: boolean): void;
    selectTemplate(index: number, selected: boolean): void;
    showTemplateHeaders(index: number, showHeaders: boolean): void;
    selectTemplateHeader(templateIndex: number, headerIndex: number, selected: boolean): void;
}

interface TemplateJobs {
    jobId: string;
    siteAddress: string;
    estimateSubTotalExGstFormatted: string;
    estimateGstFormatted: string;
    estimateTotalFormatted: string;
    selected: boolean;
    templateJob: boolean;
    showHeaders: boolean;
    headers: TemplateHeader[];
}

interface TemplateHeader {
    estimateId: number;
    headerId: number;
    area: string;
    areaOrder: number;
    subTotalExGstFormatted: string;
    selected: boolean;
}

const JobEstimateImportPopups = (props: JobEstimateImportPopupProps) => {
    const [searchFields, setSearchFields] = React.useState({ jobId: "", address: "" });
    const [results, setResults] = React.useState<TemplateJobs[]>([]);

    const handleChange = (e: any) => {
        setSearchFields(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleCellSelectedChange = (index: number, e: any) => {
        props.updateSaveDisabled(false);

        //update item that has changed and uncheck others
        var resultsAll = props.templateJobs.concat(results);
        for (var i = 0; i < resultsAll.length; i++) {
            resultsAll[i].selected = false;

            for (var x = 0; x < resultsAll[i].headers.length; x++) {
                var header = resultsAll[i].headers[x];
                header.selected = false;
            }
        }
        //update selected item
        var result = resultsAll[index];

        if (result.templateJob) {
            props.selectTemplate(index, e.target.checked);
        } else {
            var localResults = [...results];
            var localResult = localResults[index - props.templateJobs.length]

            let exist: any = {};
            exist = localResult;
            exist[e.target.name] = e.target.checked;

            //need to select/unselect all headers
            for (var i = 0; i < localResult.headers.length; i++) {
                var header = localResult.headers[i];
                header.selected = e.target.checked;
            }

            setResults(localResults);
        }
        
    }

    //update sub table check boxes
    const handleSubCellSelectedChange = (templateIndex: number, headerIndex: number, e: any) => {
        var resultsAll = props.templateJobs.concat(results);
        var template = resultsAll[templateIndex];

        props.updateSaveDisabled(false);

        if (template.templateJob) {
            props.selectTemplateHeader(templateIndex, headerIndex, e.target.checked);
        } else {
            var localResults = [...results];
            var localResult = localResults[templateIndex - props.templateJobs.length]
            var header = localResult.headers[headerIndex];
            let exist: any = {};
            exist = header;
            exist[e.target.name] = e.target.checked;

            if (!e.target.checked) {
                //means we have unticked one of the header items so untick the template selection as we now no longer want to import the whole estimate
                localResult.selected = false;
            }

            setResults(localResults);
        }
    }

    const showHeaders = (showHeader: boolean, index: number, e: any) => {
        e.preventDefault();

        var resultsAll = props.templateJobs.concat(results);
        var result = resultsAll[index];
        if (result.templateJob) {
            props.showTemplateHeaders(index, showHeader);
        } else {
            var localResults = [...results];
            var localResult = localResults[index - props.templateJobs.length]
            localResult.showHeaders = showHeader;

            setResults(localResults);
        }
    }

    const search = (e: any) => {
        e.preventDefault();

        if (!searchFields.jobId && !searchFields.address) {
            toast.error("Please enter Job Id or Address to search!");
        } else {
            estimateSearch();
        }
    }

    const estimateSearch = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Estimate/ImportJobSearch?jobId=' + searchFields.jobId + "&siteAddress=" + searchFields.address + "&subId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var searchResults = res.data;
            
            props.updateSaveDisabled(false);
            setResults(searchResults);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideConfirmModal = () => {
        setSearchFields({ jobId: "", address: "" });
        setResults([]);
        props.hideModal();
    }

    const importConfirmModal = (e: any) => {
        e.preventDefault();

        var allResults = props.templateJobs.concat(results);
        var jobId = "";
        var headerIds= "";
        //work out what estimate / estimate headers to import
        for (var x = 0; x < allResults.length; x++) {
            var estimate = allResults[x];
            if (estimate.selected) {
                jobId = estimate.jobId;
            } else {
                //check headers
                for (var y = 0; y < estimate.headers.length; y++) {
                    var header = estimate.headers[y];
                    if (header.selected) {
                        if (headerIds.length > 0) {
                            headerIds += ",";
                        }
                        headerIds += header.headerId;
                    }
                }
            }
        }

        if (jobId === "" && headerIds.length === 0) {
            toast.error("Select an estimate to import");
        }  else {
            if (props.saveDisabled) {
                return;
            }
            props.updateSaveDisabled(true);

            setSearchFields({ jobId: "", address: "" });
            setResults([]);
            props.importModal(jobId, headerIds);
        }
    }

    var allResults = props.templateJobs.concat(results);

    return (
        <Modal size="lg" show={props.showModal} onHide={hideConfirmModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Import Estimate</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideConfirmModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Search for a Job Estimate or select an Estimate Template to import</p>
                </div>
                <div className="input-group--inline">
                    <label className="input-group" htmlFor="jobId">
                        <span className="label-small">Job Id</span>
                        <input className="input estimate-import--jobId" type="text" id="jobId" name="jobId" value={searchFields.jobId} onChange={(e) => handleChange(e)}></input>
                    </label>

                    <label className="input-group marginLeft10Mobile" htmlFor="address">
                        <span className="label-small">Address</span>
                        <input className="input" type="text" id="address" name="address" value={searchFields.address} onChange={(e) => handleChange(e)}></input>
                    </label>

                    <button className="defaultbutton marginLeft10LabelMobile-small" type="button" onClick={search}>Search</button>
                </div>
                <div>
                    <p>Select an entire estimate to import or one or more areas to import</p>
                </div>

                <div className='overflowAuto marginTop10'>
                    <table className="table--main">
                        <thead className="subtableHeaderColour">
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Job Id</th>
                                <th>Site Address</th>
                                <th>Sub Total ex GST</th>
                                <th>GST</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        {allResults.map((result, index) =>
                            <tbody key={result.jobId}>
                                <tr className="subtableRowColour">
                                    <td className="table__text--align">
                                        <input type="checkbox" className="input checkbox" name="selected" checked={result.selected} onChange={(e) => handleCellSelectedChange(index, e)} />
                                    </td>
                                    <td className="table__text--align">
                                        <a className={result.showHeaders ? "hidden" : "mobile-icon-green"} href="#" onClick={(e) => showHeaders(true, index, e)}>
                                            <span className="fas fa-plus-circle alignIconCenter"></span>
                                        </a>
                                        <a className={result.showHeaders ? "mobile-icon-grey" : "hidden"} href="#" onClick={(e) => showHeaders(false, index, e)}>
                                            <span className="fas fa-times-circle alignIconCenter"></span>
                                        </a>
                                    </td>
                                    <td className="table__text--align">{result.jobId}</td>
                                    <td className="table__text--align">
                                        <div className="tooltipitem tooltipitem__right">{result.siteAddress ? result.siteAddress.substring(0, 20) + "... " : ""}
                                            <span className="tooltipitemtext tooltipitemtext__right">{result.siteAddress}</span>
                                        </div>
                                    </td>
                                    <td className="table__text--align">{result.estimateSubTotalExGstFormatted}</td>
                                    <td className="table__text--align">{result.estimateGstFormatted}</td>
                                    <td className="table__text--align">{result.estimateTotalFormatted}</td>
                                </tr>
                                <tr className={result.showHeaders ? "subtableSubRowColour" : "hidden"}>
                                    <td colSpan={7}>
                                        <table className="table--main subtable tableColours">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Area</th>
                                                    <th>Sub Total ex GST</th>
                                                </tr>
                                            </thead>
                                            {result.headers.map((header, headerindex) => 
                                                <tbody key={header.headerId}>
                                                    <tr>
                                                        <td className="table__text--align">
                                                            <input type="checkbox" className="input checkbox" name="selected" checked={header.selected} onChange={(e) => handleSubCellSelectedChange(index, headerindex, e)} />
                                                        </td>
                                                        <td className="table__text--align">{header.area}</td>
                                                        <td className="table__text--align">{header.subTotalExGstFormatted}</td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={importConfirmModal} disabled={props.saveDisabled}>Import</button>
            </Modal.Footer>
        </Modal>

    )

}

export default JobEstimateImportPopups;
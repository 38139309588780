import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import ConfirmModal from './ConfirmModal';

interface CostCodeList {
    id: number;
    description: string;
    listOrder: number;
    status: string;
    discountPercentage: number;
    excludeFromReport: boolean;
    excludeFromMargin: boolean;
    isDirty: boolean;
}

const AdminCostCodeData = () => {
    let navigate = useNavigate();
    const [costcodeList, setCostCodes] = React.useState<CostCodeList[]>([]);
    const [showConfirmModal, setShowConfirm] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [costcodeIndex, setCostcodeIndex] = React.useState(-1);
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
        getCostCodesData();
    }, [])

    const getCostCodesData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=EditAdminLists', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get list of cost codes
                getCostCodeList();
                setLoading(false);
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getCostCodeList = async () => {
        const token = await authService.getAccessToken();

        axios.get('Admin/GetCostCodeList', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //add blank row
            var costcodes = res.data;
            var item = getBlankCostCode();
            costcodes.push(item);

            setCostCodes(costcodes);
            setLoading(false);
        });
    }

    //grid item changed
    const handleCellChange = (index: number) => (e: any) => {
        //update item that has changed
        var costcodes = [...costcodeList];
        var costcode = costcodes[index];
        let existCode: any = {};
        existCode = costcode;
        existCode[e.target.name] = e.target.value;
        existCode["isDirty"] = true;

        if (index == (costcodes.length - 1)) {
            //last row so need to add a new blank row
            var newCode = getBlankCostCode();
            costcodes.push(newCode);
        }

        setSaveDisabled(false);
        setCostCodes(costcodes);
    }

    const handleCellChangeNumber = (index: number) => (e: any) => {
        //update item that has changed
        var costcodes = [...costcodeList];
        var costcode = costcodes[index];
        let existCode: any = {};
        existCode = costcode;
        existCode[e.target.name] = e.target.value * 1;
        existCode["isDirty"] = true;

        if (index == (costcodes.length - 1)) {
            //last row so need to add a new blank row
            var newCode = getBlankCostCode();
            costcodes.push(newCode);
        }

        setSaveDisabled(false);
        setCostCodes(costcodes);
    }

    const handleCellCheckChange = (index: number) => (e: any) => {
        //update item that has changed
        var costcodes = [...costcodeList];
        var costcode = costcodes[index];
        let existCode: any = {};
        existCode = costcode;
        existCode[e.target.name] = e.target.checked;
        existCode["isDirty"] = true;

        if (index == (costcodes.length - 1)) {
            //last row so need to add a new blank row
            var newCode = getBlankCostCode();
            costcodes.push(newCode);
        }

        setSaveDisabled(false);
        setCostCodes(costcodes);
    }

    const getBlankCostCode = () => {
        var newCode: CostCodeList = {
            id: 0,
            description: "",
            discountPercentage: 0,
            listOrder: 0,
            excludeFromReport: false,
            excludeFromMargin: false,
            status: "Active",
            isDirty: false
        };
        return newCode;
    }

    const validate = () => {
        let formIsValid = true;
        let errors: any = {};
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        var saveCostCodes = costcodeList;
        for (var i = 0; i < saveCostCodes.length - 1; i++) {
            rowNumber += 1;
            var newErrorString = "";

            if (saveCostCodes[i].description === "") {
                newErrorString += " Description, ";
                error = true;
            }

            if (saveCostCodes[i].discountPercentage < 0) {
                newErrorString += " Discount % >= 0, ";
                error = true;
            }

            if (saveCostCodes[i].listOrder < 0) {
                newErrorString += " List Order >= 0, ";
                error = true;
            }

            if (newErrorString != "") {
                errorString += "\n"
                errorString += "Row " + rowNumber + ": " + newErrorString;
                errorString = errorString.substring(0, errorString.length - 2);
            }
        }

        if (error) {
            formIsValid = false;
            errors["costcodes"] = errorString;
        }

        setErrors(errors);

        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            var costCodes = costcodeList.filter(c => c.isDirty);

            if (costCodes.length == 0) {
                toast.info("No cost codes have been changed!");
            } else {
                if (saveDisabled) {
                    return;
                }
                setSaveDisabled(true);

                saveCostCodes(costCodes);
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveCostCodes = async (costCodes: CostCodeList[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var adminCostCodes = {
            costCodes: costCodes,
            subId: user.sub
        };

        axios.post('Admin/SaveCostCodes', adminCostCodes, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Cost Code has been saved");
                getCostCodeList();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteCostCodes = (e: any, index: number) => {
        e.preventDefault();

        setShowConfirm(true);
        setModalSaveDisabled(false);
        setCostcodeIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirm(false);
        setModalSaveDisabled(false);
        setCostcodeIndex(0);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        saveDeleteCostCodes();
    }

    const saveDeleteCostCodes = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var costcodes = costcodeList;
        var costcode = costcodes[costcodeIndex];
        var deleteCostCode = {
            id: costcode.id,
            subId: user.sub
        };

        axios.post('Admin/DeleteCostCode', deleteCostCode, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Cost Code has been deleted");
                getCostCodeList();
                setShowConfirm(false);
                setCostcodeIndex(-1);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const confirmPopup = <ConfirmModal heading="Delete Cost Code" text="Are you sure you want to delete this cost code?" saveDisabled={modalSaveDisabled} hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} />
    let lastRow = costcodeList.length;

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div className="overflowAuto">
                <table className="table--main tableColours">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="hidden">Id</th>
                            <th>Description</th>
                            <th>
                                <div className="tooltipitem tooltipitem__left"><span>Discount %</span>
                                    <span className="tooltipitemtext tooltipitemtext__left">This will discount the estimate value when costings and sending purchase orders</span>
                                </div>
                            </th>
                            <th>
                                <div className="tooltipitem tooltipitem__left"><span>Excl. from report</span>
                                    <span className="tooltipitemtext tooltipitemtext__left">This will exclude this cost code from the estimate report scope of works</span>
                                </div>
                            </th>
                            <th>
                                <div className="tooltipitem tooltipitem__left"><span>Excl. from margin</span>
                                    <span className="tooltipitemtext tooltipitemtext__left">This will exclude this cost code when reducing the margin % on the estimate margin report</span>
                                </div>
                            </th>
                            <th>List Order</th>
                            <th>Status</th>
                            <th className="table__center-text--align">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {costcodeList.map((costcode, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="hidden">{costcode.id}</td>
                                <td>
                                    <input className={costcode.id == 0 ? "input" : "hidden"} maxLength={100} type="text" name="description" value={costcode.description} onChange={handleCellChange(index)}></input>
                                    {costcode.id == 0 ? "" : costcode.description}
                                </td>
                                <td>
                                    <input className={costcode.status === "Active" ? "input" : "hidden"} type="number" name="discountPercentage" value={costcode.discountPercentage} onChange={handleCellChangeNumber(index)}></input>
                                    {costcode.status === "Active" ? "" : costcode.discountPercentage}
                                </td>
                                <td>
                                    <input type="checkbox" className="input checkbox" name="excludeFromReport" checked={costcode.excludeFromReport} onChange={handleCellCheckChange(index)} disabled={costcode.status !== "Active"}></input>
                                </td>
                                <td>
                                    <input type="checkbox" className="input checkbox" name="excludeFromMargin" checked={costcode.excludeFromMargin} onChange={handleCellCheckChange(index)} disabled={costcode.status !== "Active"}></input>
                                </td>
                                <td>
                                    <input className={costcode.listOrder != 99 && costcode.status === "Active" ? "input" : "hidden"} type="number" name="listOrder" value={costcode.listOrder} onChange={handleCellChangeNumber(index)}></input>
                                    {costcode.listOrder != 99 && costcode.status !== "Active" ? costcode.listOrder : ""}
                                </td>
                                <td>
                                    {costcode.status}
                                </td>
                                <td className="table__text--align">
                                    <div className={costcode.status === "Active" && (lastRow != (index + 1)) ? "delete--tablecell" : "hidden"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteCostCodes(e, index)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["costcodes"] ?
                    (errors["costcodes"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <button className="defaultbutton marginBottom10" type='submit' disabled={saveDisabled}>Save</button>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        <h1>Add/Edit Cost Codes</h1>
        {contents}
    </div>)
}

export default AdminCostCodeData;

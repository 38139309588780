import * as React from 'react';
import { useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { JobDetailsReducer, InitialJob } from './JobDetailsReducer';
import NewJobDetail from './NewJobDetails';
import NewJobMatchingJob from './NewJobMatchingJobs';
import NewJobClientDetail from './NewJobClientDetails';
import NewJobNote from './NewJobNotes';
import authService from './api-authorization/AuthorizeService';
declare var google: any;

interface JobDetails {
    id: number;
    jobTypeId: number;
    jobSubTypeId: number;
    siteAddress: string;
    siteAddressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    receivedDate: string;
    receivedTime: string;
    siteVisitDate: string;
    siteVisitTime: string;
    insuredContactDate: string;
    insuredContactTime: string;
    jobAcknowledgementDateTime: string;
    contractPrice: string;
    completePercentage: string;
    completedDate: string;
    makeSafe: boolean;
    reportOnly: boolean;
    copyEstimate: boolean;
    templateJob: boolean;
    status: string;
    statusId: number;
}

interface JobCustomerDetails {
    companyName: string;
    customerRegisteredForGst: boolean;
    customerName: string;
    propertyManager: string;
    address: string;
    addressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    customerGooglePlaceId: string;
    customerAddressLatitude: number;
    customerAddressLongitude: number;
    phone: string;
    customerPhone: string;
    email: string;
}

interface JobClientDetails {
    isTbaClient: boolean;
    insurerId: number;
    insurerContactId: number;
    insurerPhoneNumber: string;
    insurerMobileNumber: string;
    claimNumber: string;
    claimStatusId: number;
    claimTypeId: number;
    excessAmount: number;
    adjusterId: number;
    adjusterContactId: number;
    adjusterPhoneNumber: string;
    adjusterMobileNumber: string;
    adjusterReferenceNumber: string;
    brokerId: number;
    brokerContactId: number;
    brokerReferenceNumber: string;
}

interface JobInsurerClaimLine {
    id: number;
    insurerId: number;
    claimNumber: string;
    splitPercentage: number;
    status: number;
}

interface JobResources {
    managerId: number;
    estimatorId: number;
    supervisorId: number;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface GoogleAddress {
    address: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
}

const NewJobData = () => {
    let navigate = useNavigate();
    const { copyjobid } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [currentStep, setCurrentStep] = React.useState(1);
    const [jobTypes, setJobTypes] = React.useState<GenericListItem[]>([]);
    const [jobSubTypes, setJobSubTypes] = React.useState<GenericListItem[]>([]);
    const [claimTypes, setClaimTypes] = React.useState<GenericListItem[]>([]);
    const [insurers, setInsurers] = React.useState<GenericListItem[]>([]);
    const [adjusters, setAdjusters] = React.useState<GenericListItem[]>([]);
    const [brokers, setBrokers] = React.useState<GenericListItem[]>([]);
    const [estimators, setEstimators] = React.useState<GenericListItem[]>([]);
    const [supervisors, setSupervisors] = React.useState<GenericListItem[]>([]);
    const [managers, setManagers] = React.useState<GenericListItem[]>([]);
    const [requestFile, setRequestFile] = React.useState<File>(new File([""], "filename"));
    const [requestAdditionalFiles, setRequestAdditionalFiles] = React.useState<File[]>([]);
    const [canAddNewClients, setCanAddNewClients] = React.useState(false);
    const [notes, setNotes] = React.useState("");
    const [copyJobId, setCopyJobId] = React.useState(0);
    const [goPrev, setGoPrev] = React.useState(false);

    const [{
        jobDetails,
        customerDetails,
        clientDetails,
        multipleInsurers,
        resources,
        isLoading,
    }, dispatch] = useReducer(JobDetailsReducer, { jobDetails: InitialJob.jobDetails, customerDetails: InitialJob.customerDetails, clientDetails: InitialJob.clientDetails, multipleInsurers: InitialJob.multipleInsurers, resources: InitialJob.resources, isLoading: false });

    React.useEffect(() => {
        let copyJobId = copyjobid ? Number(copyjobid) : 0;

        getData(copyJobId);
    }, []);

    const getData = async (copyJobId: number) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateNewJob', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                axios.get('Jobs/GetDropdowns?SubId=' + user.sub + '&NewJob=true&CopyJobId=' + copyJobId, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    var jobDetails = res.data.copyJobDetails.jobDetails;
                    var customerDetails = res.data.copyJobDetails.customerDetails;
                    var clientDetails = res.data.copyJobDetails.clientDetails;
                    var multipleInsurers = res.data.copyJobDetails.multipleInsurers;
                    var resources = res.data.copyJobDetails.resources;

                    dispatch({ type: "updateDetails", jobDetails: jobDetails });
                    dispatch({ type: "updateCustomer", customerDetails: customerDetails });
                    dispatch({ type: "updateClient", clientDetails: clientDetails });
                    dispatch({ type: "updateMultipleInsurers", multipleInsurers: multipleInsurers });
                    dispatch({ type: "updateResources", resources: resources });

                    setJobTypes(res.data.jobTypes);
                    setJobSubTypes(res.data.jobSubTypes);
                    setClaimTypes(res.data.claimTypes);
                    setInsurers(res.data.insurers);
                    setAdjusters(res.data.adjusters);
                    setBrokers(res.data.brokers);
                    setEstimators(res.data.estimators);
                    setSupervisors(res.data.supervisors);
                    setManagers(res.data.managers);
                    setCanAddNewClients(res.data.canAddNewClients);
                    setCopyJobId(copyJobId);
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const importAdditionalFiles = (files: File[]) => {
        if (files) {
            setRequestAdditionalFiles(files);
        }
    }

    const importPdf = (file: File) => {
        importJobFromPdf(file);
    }

    const importJobFromPdf = async(file: File) => {
        const data = new FormData()
        data.append('file', file);
        
        const user = await authService.getUser();
        data.append('subId', user.sub);

        const token = await authService.getAccessToken();
        axios.post('Jobs/ImportJobFromPdf', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //check if there is an address - need to search google maps
                var jobDetails: JobDetails = res.data.jobDetails;
                var customerDetails: JobCustomerDetails = res.data.customerDetails;

                var address = jobDetails.siteAddress;
                if (address) {
                    findGoogleAddress(address).then((resolve) => {
                        var googleAddress: any = resolve;

                        if (googleAddress) {
                            if (googleAddress.address) {
                                jobDetails.siteAddress = googleAddress.address;
                                jobDetails.streetNumber = googleAddress.streetNumber;
                                jobDetails.streetName = googleAddress.streetName;
                                jobDetails.suburb = googleAddress.suburb;
                                jobDetails.state = googleAddress.state;
                                jobDetails.country = googleAddress.country;
                                jobDetails.postCode = googleAddress.postCode;
                                jobDetails.googlePlaceId = googleAddress.googlePlaceId;
                                jobDetails.siteAddressLatitude = googleAddress.siteAddressLatitude;
                                jobDetails.siteAddressLongitude = googleAddress.siteAddressLongitude;

                                customerDetails.address = googleAddress.address;
                                customerDetails.streetNumber = googleAddress.streetNumber;
                                customerDetails.streetName = googleAddress.streetName;
                                customerDetails.suburb = googleAddress.suburb;
                                customerDetails.state = googleAddress.state;
                                customerDetails.country = googleAddress.country;
                                customerDetails.postCode = googleAddress.postCode;
                                customerDetails.customerGooglePlaceId = googleAddress.googlePlaceId;
                                customerDetails.customerAddressLatitude = googleAddress.siteAddressLatitude;
                                customerDetails.customerAddressLongitude = googleAddress.siteAddressLongitude;
                            }
                        }

                        setRequestFile(file);
                        dispatch({ type: "updateDetails", jobDetails: jobDetails });
                        dispatch({ type: "updateCustomer", customerDetails: customerDetails });
                        dispatch({ type: "updateClient", clientDetails: res.data.clientDetails });
                        setNotes(res.data.newJobNotes);
                    });
                } else {
                    setRequestFile(file);
                    dispatch({ type: "updateDetails", jobDetails: jobDetails });
                    dispatch({ type: "updateCustomer", customerDetails: customerDetails });
                    dispatch({ type: "updateClient", clientDetails: res.data.clientDetails });
                    setNotes(res.data.newJobNotes);
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const findGoogleAddress = async(siteAddress: string) => {
        const map = new google.maps.Map(document.getElementById('map'));
        const placesService = new google.maps.places.PlacesService(map);

        var request = {
            query: siteAddress,
            fields: ['formatted_address', 'place_id', 'name', 'geometry']
        };

        var googleAddress: GoogleAddress = {
            address: "",
            streetNumber: "",
            streetName: "",
            suburb: "",
            state: "VIC",
            country: "Australia",
            postCode: "",
            googlePlaceId: "",
            siteAddressLatitude: 0,
            siteAddressLongitude: 0
        };

        return new Promise((resolve, reject) => {
            placesService.findPlaceFromQuery(request, (results: any, status: any) => {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    if (results.length > 0) {
                        //grab first result
                        var addressDetails = results[0];
                        var address = addressDetails.formatted_address;

                        //get street parts 
                        var addressNameParts = addressDetails.name.split(" ");
                        var streetNo = "";
                        var streetName = "";
                        for (var i = 1; i < addressNameParts.length; i++) {
                            streetNo = addressNameParts[0];
                            streetName += streetName === "" ? addressNameParts[i] : " " + addressNameParts[i];
                        }

                        var addr = address.replace(", Australia", "");
                        //suburb
                        var suburb = "";
                        var commaIndex = addr.indexOf(",");
                        commaIndex = commaIndex >= 0 ? commaIndex + 2 : commaIndex;
                        var stateIndex = addr.indexOf("VIC");
                        if (commaIndex >= 0 && stateIndex > commaIndex) {
                            suburb = addr.substring(commaIndex, stateIndex).trim();
                        }

                        //postcode
                        var postcode = addr.slice(addr.length - 4);

                        //return address
                        googleAddress.address = address;
                        googleAddress.streetNumber = streetNo;
                        googleAddress.streetName = streetName;
                        googleAddress.suburb = suburb;
                        googleAddress.postCode = postcode;
                        googleAddress.googlePlaceId = addressDetails.place_id;
                        googleAddress.siteAddressLatitude = addressDetails.geometry.location.lat();
                        googleAddress.siteAddressLongitude = addressDetails.geometry.location.lng();

                        resolve(googleAddress);
                    }

                } else {
                    resolve(googleAddress);
                }
            });
        });
        
    }

    const updateJobDetails = (updatedJobDetails: JobDetails) => {
        dispatch({ type: "updateDetails", jobDetails: updatedJobDetails });

        var jobType = jobDetails.jobTypeId * 1;
        if (jobType === 2 && multipleInsurers.length > 1) {
            //clear multiple insurers if they select insurance job and then change it to private
            dispatch({ type: "updateMultipleInsurers", multipleInsurers: InitialJob.multipleInsurers });
        }

    }
    
    const updateCustomerDetails = (updatedCustomerDetails: JobCustomerDetails) => {
        dispatch({ type: "updateCustomer", customerDetails: updatedCustomerDetails });
    }

    const updateClientDetails = (updatedClientDetails: JobClientDetails) => {
        dispatch({ type: "updateClient", clientDetails: updatedClientDetails });
    }

    const updateMultipleInsurers = (updatedInsurers: JobInsurerClaimLine[]) => {
        dispatch({ type: "updateMultipleInsurers", multipleInsurers: updatedInsurers });
    }

    const updateResources = (updatedResources: JobResources) => {
        dispatch({ type: "updateResources", resources: updatedResources });
    }

    const updateClients = (clients: GenericListItem[], typeId: number, clientId: number) => {
        if (typeId == 1) {
            clientDetails.insurerId = clientId;
            dispatch({ type: "updateClient", clientDetails: clientDetails });
            setInsurers(clients);
        } else if (typeId == 2) {
            clientDetails.adjusterId = clientId;
            dispatch({ type: "updateClient", clientDetails: clientDetails });
            setAdjusters(clients);
        } else {
            clientDetails.brokerId = clientId;
            dispatch({ type: "updateClient", clientDetails: clientDetails });
            setBrokers(clients);
        }
    }

    const updateNotes = (newNotes: string) => {
        setNotes(newNotes);
    }

    const next = () => {
        // Make sure currentStep is set to something reasonable
        if (currentStep >= 3) {
            setCurrentStep(4);
        } else {
            let newCurrentStep = currentStep + 1;
            setCurrentStep(newCurrentStep);
        }

        setGoPrev(false);
    }

    const prev = () => {
        if (currentStep <= 1) {
            setCurrentStep(1);
        } else {
            let newCurrentStep = currentStep - 1;
            setCurrentStep(newCurrentStep);
        }

        setGoPrev(true);
    }

    const finish = () => {
        saveJob();
    }

    const saveJob = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        multipleInsurers.pop();

        var newJob = {
            job: {
                jobDetails: jobDetails,
                customerDetails: customerDetails,
                clientDetails: clientDetails,
                multipleInsurers: multipleInsurers,
                resources: resources,
                newJobNotes: notes,
                copyJobId: copyJobId
            },
            subId: user.sub
        };

        //save any uploaded files
        const data = new FormData();
        if (requestFile.size > 0) {
            data.append('requestFile', requestFile);
            for (var i = 0; i < requestAdditionalFiles.length; i++) {
                data.append('requestAdditionalFile', requestAdditionalFiles[i]);
            };
            data.append('subId', user.sub);
        }
        
        axios.post('Jobs/SaveJob', newJob, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var jobId = res.data.id;
                if (requestFile.size > 0) {
                    data.append('jobId', jobId);
                    axios.post('Jobs/SaveJobRequest', data, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                    .then(res => {
                        toast.success("Job Saved");
                        //then need to go to job
                        navigate("/job/" + jobId + "/details");
                    });
                } else {
                    //then need to go to job
                    navigate("/job/" + jobId + "/details");
                }
                
            } 
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var tabContents;
    if (currentStep === 1) {
        tabContents = <NewJobDetail currentStep={currentStep} jobDetails={jobDetails} customerDetails={customerDetails} jobTypes={jobTypes} jobSubTypes={jobSubTypes} copyJob={copyJobId > 0 ? true : false} prev={prev} next={next} updateJob={updateJobDetails} updateCustomer={updateCustomerDetails} importPdf={importPdf} importAdditionalFiles={importAdditionalFiles } />
    }
    else if (currentStep === 2) {
        tabContents = <NewJobMatchingJob copyJobId={copyJobId} currentStep={currentStep} siteAddress={jobDetails.siteAddress} prev={prev} next={next} goPrev={goPrev} />
    }
    else if (currentStep === 3) {
        tabContents = <NewJobClientDetail currentStep={currentStep} clientDetails={clientDetails} multipleInsurers={multipleInsurers} resources={resources} jobTypeId={jobDetails.jobTypeId} makeSafe={jobDetails.makeSafe} claimTypes={claimTypes} insurers={insurers} adjusters={adjusters} brokers={brokers} managers={managers} estimators={estimators} supervisors={supervisors} canAddNewClients={canAddNewClients} prev={prev} next={next} update={updateClientDetails} updateInsurers={updateMultipleInsurers} updateResources={updateResources} updateClients={updateClients} />
    }
    else if (currentStep === 4) {
        tabContents = <NewJobNote currentStep={currentStep} notes={notes} prev={prev} finish={finish} update={updateNotes}  />
    } 

    const renderDetails = (
        <div>
            {tabContents}
        </div>
    );
        
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            <h1>New Job</h1>
            <hr></hr>
            {contents}
            <div className="hidden" id="map"></div>
        </div>
    )
}

export default NewJobData;
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import JobTabsMobile from './JobTabsMobile';
import NoteModal from './NotesModal';
import ReportJobPhotosPopup from './ReportJobPhotos';

interface JobCostings {
    id: number;
    jobId: number;
    costCode: string;
    totalEstimateAmountFormatted: string;
    forecastAmountFormatted: string;
    actualAmountFormatted: string;
    variancePercentage: number;
    variancePercentageFormatted: string;
}

interface EditNotes {
    title: string;
    heading: string;
    rowIndex: number;
    id: number;
    notes: string;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

interface EstCompletionSave {
    id: number;
    estimatedCompletionDate: string;
    reason: string;
    subId: string;
};

const JobCostingsMobileData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState({ canViewEstimate: false, canViewCostings: false, showAssessment: false });
    const [costingData, setCostingData] = React.useState({ origEstCompletionDate: "", forecastJobMarginPercentageFormatted: "", forecastJobMarginFormatted: "", estimateMarkUpPercentageFormatted: "", estimateMarkUpFormatted: "" });
    const [jobCostings, setJobCostings] = React.useState <JobCostings[]>([]);
    const [estimatedCompletionDate, setEstimatedCompletionDate] = React.useState("");
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [showNotesModal, setShowNotesModal] = React.useState(false);
    const [editNotes, setEditNotes] = React.useState<EditNotes>({
        id: 0,
        rowIndex: 0,
        title: "",
        heading: "",
        notes: ""
    });
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    const [loadingPhotos, setLoadingPhotos] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);
    const [reportComments, setReportComments] = React.useState("");

    React.useEffect(() => {
        window.scrollTo(0, 0);

        let Id = Number(id);

        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetCostingsMobile?JobId=' + Id + "&SubId=" + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                setPermissions({ canViewEstimate: res.data.canViewEstimate, canViewCostings: res.data.canViewCostings, showAssessment: res.data.showAssessment });
                setEstimatedCompletionDate(res.data.estimatedCompletionDate);
                setJobCostings(res.data.jobCostings);
                setCostingData({ origEstCompletionDate: res.data.estimatedCompletionDate, forecastJobMarginPercentageFormatted: res.data.forecastJobMarginPercentageFormatted, forecastJobMarginFormatted: res.data.forecastJobMarginFormatted, estimateMarkUpPercentageFormatted: res.data.estimateMarkUpPercentageFormatted, estimateMarkUpFormatted: res.data.estimateMarkUpFormatted });
                setReportComments(res.data.reportComments);
                setLoading(false);
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    const handleEstCompletionDateChange = (e: any) => {
        setEstimatedCompletionDate(e.target.value);

        if (costingData.origEstCompletionDate) {
            var editNotes: EditNotes = {
                id: jobData.jobId,
                rowIndex: 0,
                title: "Estimated Completion Date Update",
                heading: "Enter reason for changing the estimated completion date",
                notes: ""
            };
            setShowNotesModal(true);
            setEditNotes(editNotes);
        } else {
            //save estimated completion date
            var estCompletionSave: EstCompletionSave = {
                id: jobData.jobId,
                estimatedCompletionDate: e.target.value,
                reason: "",
                subId: ""
            };
            
            saveChangeReasonNotes(estCompletionSave);
        }
    }

    const hideChangeReason = () => {
        var editNotes = resetChangeReason();
        setEditNotes(editNotes);
        setEstimatedCompletionDate(costingData.origEstCompletionDate);
        setShowNotesModal(false);
    }

    const resetChangeReason = () => {
        var resetNotes: EditNotes = {
            title: "",
            heading: "",
            rowIndex: 0,
            id: 0,
            notes: ""
        }
        return resetNotes;
    }

    const updateChangeReason = (updatedNotes: EditNotes) => {
        var reason = editNotes;
        reason.notes = updatedNotes.notes;
        setEditNotes(prevState => ({ ...prevState, reason }));
    }

    const saveChangeReason = () => {
        if (editNotes.notes) {
            var estCompletionSave = {
                id: jobData.jobId,
                estimatedCompletionDate: estimatedCompletionDate,
                reason: editNotes.notes,
                subId: ""
            };
            saveChangeReasonNotes(estCompletionSave);
        } else {
            toast.error("Enter a reason for changing the completion date!");
        }
    }

    const saveChangeReasonNotes = async (estCompletionSave: EstCompletionSave) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        estCompletionSave.subId = user.sub; //set user

        //save
        axios.post('Jobs/SaveEstCompletionDateUpdate', estCompletionSave, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data) {
                var estMarkUp = costingData.estimateMarkUpFormatted;
                var estMarkUpPercent = costingData.estimateMarkUpPercentageFormatted;
                var jobMargin = costingData.forecastJobMarginFormatted;
                var jobMarginPercent = costingData.forecastJobMarginPercentageFormatted;
                setCostingData({ origEstCompletionDate: estimatedCompletionDate, estimateMarkUpFormatted: estMarkUp, estimateMarkUpPercentageFormatted: estMarkUpPercent, forecastJobMarginFormatted: jobMargin, forecastJobMarginPercentageFormatted: jobMarginPercent });
                toast.success("Estimated Completion Date Saved");
            } else {
                toast.success("Error while trying to update Estimated Completion date");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

        var editNotesReset = resetChangeReason();

        setEditNotes(editNotesReset);
        setShowNotesModal(false);
    }
    //end edit est completion date modal

    //run job photos report
    const runJobPhotosReport = (e: any) => {
        e.preventDefault();
        runPhotosReport();
    }

    const runPhotosReport = async() => {
        const token = await authService.getAccessToken();

        //get photos
        axios.get('Jobs/GetJobPhotos?JobId=' + jobData.jobId + '&TypeId=3', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var photos = res.data.photos;
            if (!photos) {
                photos = [];
            }

            var uploadedPhotos: UploadedFile[] = [];
            for (var i = 0; i < photos.length; i++) {
                var uploadedPhoto: UploadedFile = {
                    file: {
                        id: photos[i].id,
                        fileName: photos[i].fileName,
                        fileType: photos[i].fileType,
                        content: photos[i].content,
                        photoOrder: photos[i].photoOrder
                    },
                    comment: photos[i].comment
                };
                uploadedPhotos.push(uploadedPhoto);
            }

            setShowPhotoModal(true);
            setUploadedFiles(uploadedPhotos);
        });
    }

    const hidePhotoImport = () => {
        setShowPhotoModal(false);
        setUploadedFiles([]);
    }

    const importPhotos = (files: File[]) => {
        setLoadingPhotos(true);
        importJobPhotos(files);
    }

    const importJobPhotos = async(files: File[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save photos
        const data = new FormData()
        data.append('jobId', jobData.jobId.toString());
        data.append('typeId', "3");

        for (var i = 0; i < files.length; i++) {
            data.append('photos', files[i]);
        }
        data.append('subId', user.sub);

        //save photos
        axios.post('Jobs/ImportJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //save the returned photos
                var uploadedFiles: UploadedFile[] = [];
                var photos = res.data.photos;
                for (var p = 0; p < photos.length; p++) {
                    var photo = photos[p];
                    var file: UploadedFile = {
                        file: {
                            id: photo.id,
                            fileName: photo.fileName,
                            fileType: photo.fileType,
                            content: photo.content,
                            photoOrder: photo.photoOrder
                        },
                        comment: photo.comment
                    };
                    uploadedFiles.push(file);
                }
                setUploadedFiles(uploadedFiles);
                setLoadingPhotos(false);
                
                toast.success("Photos have been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    const updatePhotoImport = (files: UploadedFile[]) => {
        setUploadedFiles(files);
    }

    const deletePhotoImport = (deleteIndex: number) => {
        var files = [...uploadedFiles];
        var photo = files[deleteIndex];

        if (!photo) {
            toast.error("Could not find photo to delete!");
            return;
        }

        if (photo.file.id > 0) {
            saveDeletePhoto(photo.file.id, deleteIndex);
        } else {
            //save the photos on upload so this shouldn't happen but just in case
            files.splice(deleteIndex, 1);
            //re-order the photos
            for (var i = 0; i < files.length; i++) {
                files[i].file.photoOrder = i + 1;
            }

            setUploadedFiles(files);
            toast.success("Photo has been deleted");
        }

    }

    const saveDeletePhoto = async(photoId: number, deleteIndex: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var files = [...uploadedFiles];

        var data = {
            id: photoId,
            subId: user.sub
        };

        //delete photo
        axios.post('Jobs/DeleteJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                files.splice(deleteIndex, 1);
                //re-order the photos
                for (var i = 0; i < files.length; i++) {
                    files[i].file.photoOrder = i + 1;
                }

                setUploadedFiles(files);
                toast.success("Photo has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const savePhotoImport = () => {
        saveJobPhotoImport();
    }

    const saveJobPhotoImport = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var photoComments = [];
        for (var i = 0; i < uploadedFiles.length; i++) {
            var photoOrder = uploadedFiles[i].file.photoOrder;
            if (!photoOrder) {
                //set the order for any null entries
                photoOrder = i + 1;
            }

            var item = {
                id: uploadedFiles[i].file.id,
                comments: uploadedFiles[i].comment,
                photoOrder: photoOrder
            };
            photoComments.push(item);
        }

        var data = {
            jobId: jobData.jobId,
            reportComments: reportComments,
            jobPhotoComments: photoComments,
            subId: user.sub
        };

        //save comments
        axios.post('Jobs/SaveJobPhotoReportComments', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Photos have been saved");
                setShowPhotoModal(false);
                setUploadedFiles([]);
                setModalSaveDisabled(false);
                    
                printPhotoReport();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const updatePhotoComments = (reportComments: string) => {
        setReportComments(reportComments);
    }

    const updateSaveDisabled = () => {
        setModalSaveDisabled(true);
    }

    const printJobPhotosReport = (e: any) => {
        e.preventDefault();

        printPhotoReport();
    }

    const printPhotoReport = () => {
        var url = "/job/" + jobData.jobId + "/report/2/jobPhotos";
        window.open(url, '_blank');
    }
    //end job photos report

    var jobTabsMobile = <JobTabsMobile id={jobData.jobId} tab="details" canViewEstimate={permissions.canViewEstimate} canViewCostings={permissions.canViewCostings} showAssessment={permissions.showAssessment} tabChanged={tabChanged} />
    let estCompletionDateUpdateReason = <NoteModal showEditNotesModal={showNotesModal} editNotes={editNotes} hideNotes={hideChangeReason} updateNotes={updateChangeReason} saveNotes={saveChangeReason} maxLength={500} />
    let photoImport = <ReportJobPhotosPopup title="Select photos for photos report" showModal={showPhotoModal} loadingPhotos={loadingPhotos} uploadedFiles={uploadedFiles} reportComments={reportComments} hideModal={hidePhotoImport} importPhotos={importPhotos} update={updatePhotoImport} delete={deletePhotoImport} save={savePhotoImport} saveDisabled={modalSaveDisabled} updateSaveDisabled={updateSaveDisabled} updateReportComments={updatePhotoComments} />
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const renderDetails = (
        <div>
            <div className="static-modal">
                {estCompletionDateUpdateReason}
                {photoImport}
            </div>
            <div className="marginTop10 marginBottom70">
                <div className="makeitflexspacebetween">
                    <h4 className="mobile-margin">Job Costs</h4>
                    <div>
                        <div className="defaultbutton__container--right makeitflexcenter">
                            <a className="mobile-icon-green" href="#" onClick={(e) => runJobPhotosReport(e)}>
                                <span className="fas fa-camera mobileMenuSize alignIconCenter"></span>
                                <span>Report</span>
                            </a>
                            <a className={isSafari ? "mobile-icon-green" : "hidden"} href="#" onClick={(e) => printJobPhotosReport(e)}>
                                <span className="fas fa-file-alt mobileMenuSize alignIconCenter"></span>
                                <span>Print</span>
                            </a>
                        </div>
                    </div>
                </div>
                <span className={"mobileFont" + (jobData.isMakeSafe ? " makeSafeJob" : jobData.isReportOnly ? " reportOnlyJob" : "")}>{jobData.siteAddress}</span>
                <div className="mobileFont marginTop10">
                    <label className="input-group estimate__alignTotals makeitflexspacebetween" htmlFor="estimatedCompletionDate">
                        <span className="jobCostings__label--date-size label-mobile">Est. Completion Date</span>
                        <input className="input estimate__totalsWidth textalignright" type="date" id="estimatedCompletionDate" name="estimatedCompletionDate" value={estimatedCompletionDate} onChange={(e) => handleEstCompletionDateChange(e)} ></input>
                    </label>
                </div>
                <div className="job__noteGridSize overflowAuto">
                    <table className="table--main table__small tableColours">
                        <thead>
                            <tr>
                                <th>Cost Code</th>
                                <th className="textalignright">Estimate</th>
                                <th className="textalignright">Forecast</th>
                                <th className="textalignright">Actual</th>
                                <th className="textalignright">Variance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobCostings.map(costing =>
                                <tr key={costing.id}>
                                    <td className="table__text--align">{costing.costCode}</td>
                                    <td className="table__text--align textalignright">{costing.totalEstimateAmountFormatted}</td>
                                    <td className="table__text--align textalignright">{costing.forecastAmountFormatted}</td>
                                    <td className="table__text--align textalignright">{costing.actualAmountFormatted}</td>
                                    <td className={costing.variancePercentage > 1 ? "table__text--align textalignright makeitred" : "table__text--align textalignright"}>
                                        {costing.variancePercentageFormatted}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="mobileFont defaultbutton__container--right">
                    <div>
                        <label className="input-group labeltextalignright marginTop10 marginBottom15">
                            <span className="label-mobile">Forecast job Margin %</span>
                            <span className="estimate-mobile-totals">{costingData.forecastJobMarginPercentageFormatted}</span>
                        </label>
                        <label className="input-group labeltextalignright marginBottom15">
                            <span className="label-mobile ">Forecast Job Margin</span>
                            <span className="estimate-mobile-totals">{costingData.forecastJobMarginFormatted}</span>
                        </label>
                        <label className="input-group labeltextalignright makeitbold marginBottom15">
                            <span className="label-mobile">Estimate Mark Up %</span>
                            <span className="estimate-mobile-totals">{costingData.estimateMarkUpPercentageFormatted}</span>
                        </label>
                        <label className="input-group labeltextalignright marginBottom15">
                            <span className="label-mobile ">Estimate Mark Up</span>
                            <span className="estimate-mobile-totals">{costingData.estimateMarkUpFormatted}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div>
                {jobTabsMobile}
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
        {contents}
        </div>
    );
}

export default JobCostingsMobileData;
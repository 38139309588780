import * as React from 'react';
import Modal from 'react-bootstrap/Modal'
import GoogleAutoComplete from './GoogleAutoComplete';
import * as DateHelpers from './DateHelpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface ScheduleCalendarAddAppointmentProps {
    showModal: boolean;
    hideModal(): void;
    saveModal(): void;
}

interface Appointment {
    id: number;
    startDateTime: string;
    endDateTime: string;
    title: string;
    jobId: number;
    location: string;
    googlePlaceId: string;
    locationLat: number;
    locationLng: number;
    notes: string;
    attendees: AppointmentAttendee[];
}

interface AppointmentAttendee {
    id: number;
    userId: number;
    name: string;
    email: string;
    phone: string;
}

const initAppointment: Appointment = {
    id: 0,
    startDateTime: "",
    endDateTime: "",
    title: "",
    jobId: 0,
    location: "",
    googlePlaceId: "",
    locationLat: 0,
    locationLng: 0,
    notes: "",
    attendees: []
};

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const ScheduleCalendarAddAppointmentData = (props: ScheduleCalendarAddAppointmentProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [appointment, setAppointment] = React.useState<Appointment>(initAppointment);
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const hideModal = () => {
        setAppointment(initAppointment);
        props.hideModal();
    }

    const handleChange = (e: any) => {
        setAppointment(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        setSaveDisabled(false);
    }

    const handleAttendeeChange = (index: number, e: any) => {
        var appt = appointment;
        var attendees = [...appt.attendees];
        var attendee = attendees[index];

        let exist: any = {};
        exist = attendee;
        exist[e.target.name] = e.target.value;

        setAppointment(appt);
        setSaveDisabled(false);
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["location"];
        setErrors(error);

        updateAddress(addressDetails);

        setSaveDisabled(false);
    }

    const updateAddress = (addressDetails: AddressDetails) => {
        setAppointment(prevState => ({
            ...prevState,
            googlePlaceId: addressDetails.placeId,
            locationLat: addressDetails.latitude,
            locationLng: addressDetails.longitude,
            location: addressDetails.fullAddress
        }));
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (!appointment.startDateTime) {
            formIsValid = false;
            error["startDateTime"] = "Start Time is required";
        } else if (!DateHelpers.isValidDate(appointment.startDateTime)) {
            formIsValid = false;
            error["startDateTime"] = "Valid Start Time is required";
        }

        if (!appointment.endDateTime) {
            formIsValid = false;
            error["endDateTime"] = "End Time is required";
        } else if (!DateHelpers.isValidDate(appointment.endDateTime)) {
            formIsValid = false;
            error["endDateTime"] = "Valid End Time is required";
        }

        if (!appointment.title) {
            formIsValid = false;
            error["title"] = "Title is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = () => {
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);
            saveAppointment();
        }
    }

    const saveAppointment = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var saveJob = {
            appointment: appointment,
            subId: user.sub
        };

        //save job
        axios.post('Calendar/SaveAppointment', saveJob, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            toast.success("Appointment Saved");
            setAppointment(initAppointment);
            props.saveModal();
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Location"} placeholder={appointment.location} className="input-group" disabled={false} save={setAddress} />
    //TODO add Job search

    return (
        <Modal show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add Appointment</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Add a new appointment to the calendar</p>
                </div>
                <label className="input-group" htmlFor="startDateTime">
                    <span className="label">Start Time</span>
                    <input className="input" type='datetime-local' id="startDateTime" name="startDateTime" value={appointment.startDateTime} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["startDateTime"] ? "label errors errors__leftmargin" : "hidden"}>{errors["startDateTime"]}</span>
                <label className="input-group" htmlFor="endDateTime">
                    <span className="label">End Time</span>
                    <input className="input" type='datetime-local' id="endDateTime" name="endDateTime" value={appointment.endDateTime} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["endDateTime"] ? "label errors errors__leftmargin" : "hidden"}>{errors["endDateTime"]}</span>
                <label className="input-group" htmlFor="title">
                    <span className="label">Title</span>
                    <input className='input' type='text' maxLength={50} id="title" name="title" value={appointment.title} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["title"] ? "label errors errors__leftmargin" : "hidden"}>{errors["title"]}</span>
                {googleContents}
                <label className="input-group" htmlFor="notes">
                    <span className="label">Notes</span>
                    <textarea rows={4} className="input estimate-consultantnotes--size" id="notes" maxLength={500} name="notes" value={appointment.notes} onChange={(e) => handleChange(e)} ></textarea>
                </label>

            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={save} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )

}

export default ScheduleCalendarAddAppointmentData;

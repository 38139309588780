import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobTabs from './JobTabs';
import EstimateHeaderData from './JobEstimateHeader';
import EstimateDetailsData from './JobEstimateDetails';
import EstimateDetailsSplitData from './JobEstimateDetailsSplit';
import JobAssessmentData from './JobAssessments';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface GenericListItem {
    id: number;
    name: string;
}

interface PageChangeData {
    estimateId: number;
    page: string;
    id: number;
    headerText: string;
    costCodeId: number;
    prevId: number; //for going back from split to details
    prevHeaderText: string;
}

interface RunReportData {
    estimateId: number;
    reportName: string;
}

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

const EstimateData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [pagePermissions, setPagePermissions] = React.useState({ canEdit: false, canStartJobWithoutExcess: false, canUnapprove: false });
    const [costCodeList, setCostCodeList] = React.useState<GenericListItem[]>([]);
    const [uomList, setUomList] = React.useState<GenericListItem[]>([]);
    const [pcpsList, setPcpsList] = React.useState<GenericListItem[]>([]);
    const [labMatList, setLabMatList] = React.useState<GenericListItem[]>([]);
    const [supplierList, setSupplierList] = React.useState<GenericListItem[]>([]);
    const [subTab, setSubTab] = React.useState("estimate");
    const [pageData, setPageData] = React.useState<PageChangeData>({
        estimateId: 0,
        page: "header",
        id: 0,
        headerText: "",
        costCodeId: -1,
        prevId: 0,
        prevHeaderText: ""
    });

    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save an audit trail note of who has accessed job details
        var auditTrail = {
            typeId: 3,
            linkId: Id,
            subId: user.sub
        };

        axios.post('Shared/SaveAuditTrail', auditTrail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //not doing anything if the audit trail doesn't work
        });

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=ViewJobEstimate', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get user roles and status
                axios.get('Estimate/GetDropdowns?JobId=' + Id + "&SubId=" + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                        setPermissions(res.data.userJobPermissions);
                        setPagePermissions({
                            canEdit: res.data.canEdit,
                            canStartJobWithoutExcess: res.data.canStartJobWithoutExcess,
                            canUnapprove: res.data.canUnapprove
                        });
                        setCostCodeList(res.data.costCodeList);
                        setUomList(res.data.uomList);
                        setPcpsList(res.data.pcPsList);
                        setLabMatList(res.data.labMatList);
                        setSupplierList(res.data.suppliers);
                        setLoading(false);
                    }
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const tabChanged = (url: string) => {
        //upper tab changed - details, estimate, costings etc
        navigate(url);
    }

    //sub tabs
    const subTabSelected = (subTab: string) => {
        var page = "header";
        if (subTab == "assessment") {
            page = "assessment";
        }
        setSubTab(subTab);
        setPageData(prevState => ({ ...prevState, page: page }))
    }

    const subTabChanged = (e: any) => {
        subTabSelected(e.target.value);
    }

    const pageChanged = (pageData: PageChangeData) => {
        setPageData(pageData);
    }

    const runReport = (data: RunReportData) => {
        var url = "/job/" + jobData.jobId + "/report/" + data.estimateId + "/" + data.reportName;
        window.open(url, '_blank');
    }

    var tabContents;
    if (pageData.page === "header") {
        tabContents = <EstimateHeaderData canEdit={pagePermissions.canEdit} canStartJobWithoutExcess={pagePermissions.canStartJobWithoutExcess} canUnapprove={pagePermissions.canUnapprove} jobId={jobData.jobId} changePage={pageChanged} runReport={runReport} />
    }
    else if (pageData.page === "details") {
        tabContents = <EstimateDetailsData canEdit={pagePermissions.canEdit} jobId={jobData.jobId} pageData={pageData} costCodeList={costCodeList} uomList={uomList} pcpsList={pcpsList} suppliersList={supplierList} changePage={pageChanged} />
    }
    else if (pageData.page === "split") {
        tabContents = <EstimateDetailsSplitData canEdit={pagePermissions.canEdit} jobId={jobData.jobId} pageData={pageData} costCodeList={costCodeList} uomList={uomList} labMatList={labMatList} changePage={pageChanged} />
    } else if (pageData.page === "assessment") {
        tabContents = <JobAssessmentData jobId={jobData.jobId} />
    }
    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="estimate" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />

    const renderDetails = (
        <div>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <div>
                    <div className="tabsComponent__showDropdown">
                        <label className="input-group" htmlFor="jobEstimateTab">
                            <span className="label">Estimate Items</span>
                            <select className="select" id="jobEstimateTab" value={subTab} onChange={subTabChanged} >
                                <option value="estimate">Estimate</option>
                                <option value="assessment">Assessment</option>
                            </select>
                        </label>
                    </div>
                    <div className="tabsComponent__tabs tabsComponent__hideTabs">
                        <input type="radio" name="jobEstimatesTab" id="tabEstimate" className="tabsComponent__control" checked={subTab === 'estimate'} readOnly></input>
                        <label className="tabsComponent__label jobCostings__tabSize" htmlFor="tabItem" onClick={() => subTabSelected('estimate')}>Estimate</label>
                        <input type="radio" name="jobEstimatesTab" id="tabAssessment" className="tabsComponent__control" checked={subTab === 'assessment'} readOnly></input>
                        <label className="tabsComponent__label jobCostings__tabSize" htmlFor="tabItem" onClick={() => subTabSelected('assessment')}>Assessment</label>
                    </div>
                </div>
                <div className="tabsComponent__content tabsComponent__content--selected tabsComponent__noPadding">
                    <div>
                        {tabContents}
                    </div>
                </div>
            </div>
        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
}

export default EstimateData;
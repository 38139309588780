import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import JobTabs from './JobTabs';
import * as DateHelpers from './DateHelpers';
import ConfirmModal from './ConfirmModal';
import PhotoImportPopup from './PhotoImport';
import SignaturePopup from './Signature';
import PhotoImportSinglePopup from './PhotoImportSingle';
import authService from './api-authorization/AuthorizeService';

interface GenericListItem {
    id: number;
    name: string;
}

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

interface Schedule {
    id: number;
    jobId: number;
    startDateFormatted: string;
    endDateFormatted: string;
    jobCompletionReceived: boolean;
    jobCompletionUploaded: boolean;
    jobCompletionId: number;    //file id of certificate if uploaded else 0
    subTotalDays: number;
    publicHolidayDays: number;
    inclementWeatherDays: number;
    totalDays: number;
    scheduleDetails: ScheduleDetail[];
}

interface ScheduleDetail {
    id: number;
    scheduleId: number;
    description: string;
    costCodeId: number;
    costCode: string;
    startDate: string;
    startDateFormatted: string;
    endDate: string;
    endDateFormatted: string;
    days: number;
    percentageComplete: number;
    percentageCompleteFormatted: string;
    isDirty: boolean;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const JobScheduleData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false, claimNumber: "" });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [costcodes, setCostcodes] = React.useState<GenericListItem[]>([]);
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    const [loadingPhotos, setLoadingPhotos] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<File>(new File([""], "filename"));
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);
    const [uploadedFile, setUploadedFile] = React.useState<AppFile>({
        id: 0,
        fileName: "",
        fileType: "",
        content: [],
        photoOrder: 1
    });
    const [canEdit, setCanEdit] = React.useState(false);
    const [schedule, setSchedule] = React.useState<Schedule>({
        id: 0,
        jobId: 0,
        startDateFormatted: "",
        endDateFormatted: "",
        jobCompletionReceived: false,
        jobCompletionUploaded: false,
        jobCompletionId: 0,
        subTotalDays: 0,
        publicHolidayDays: 0,
        inclementWeatherDays: 0,
        totalDays: 0,
        scheduleDetails: []
    });
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [deleteOptions, setDeleteOptions] = React.useState({ showConfirmModal: false, scheduleIndex: 0 });
    const [showSignatureModal, setShowSignatureModal] = React.useState(false);
    const [showCompletionCertModal, setShowCompletionCertModal] = React.useState(false);

    React.useEffect(() => {
        let Id = Number(id);

        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        //save an audit trail note of who has accessed job details
        var auditTrail = {
            typeId: 5,
            linkId: Id,
            subId: user.sub
        };

        axios.post('Shared/SaveAuditTrail', auditTrail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //not doing anything if the audit trail doesn't work
        });

        getSchedule(Id);
    }

    const getSchedule = async (JobId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        axios.get('Jobs/GetScheduleData?JobId=' + JobId + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var scheduleList = res.data.schedule;
                if (!scheduleList.jobCompletionReceived && res.data.canEdit) {
                    var newRow = addBlankRow();
                    scheduleList.scheduleDetails.push(newRow);
                }

                setJobData({ jobId: JobId, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly, claimNumber: res.data.claimNumber });
                setSchedule(scheduleList);
                setCanEdit(res.data.canEdit);
                setPermissions(res.data.userJobPermissions);
                setCostcodes(res.data.costCodes);
                setLoading(false);
            }
        });
    }

    const handleIncWeatherChange = (e: any) => {
        let newSchedule = schedule;
        schedule.inclementWeatherDays = e.target.value;
        let updatedSchedule = calculateDays(newSchedule);

        setSchedule(prevState => ({ ...prevState, updatedSchedule }));
        
        setSaveDisabled(false);
    }

    const handleCheckboxChange = (e: any) => {
        //update item that has changed
        var scheduleList = schedule;
        scheduleList.jobCompletionReceived = e.target.checked;
        setSchedule(scheduleList);
        setSaveDisabled(false);
        setErrors({});
    }

    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var scheduleList = schedule;
        var scheduleDetails = scheduleList.scheduleDetails;
        var detail = scheduleDetails[index];
        let exist: any = {};
        exist = detail;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (e.target.name === "startDate" && detail.endDate && DateHelpers.isValidDate(e.target.value)) {
            //calculate days
            detail.days = (DateHelpers.businessDaysBetweenDates(e.target.value, detail.endDate));
        } else if (e.target.name === "endDate" && detail.startDate && DateHelpers.isValidDate(e.target.value)) {
            detail.days = (DateHelpers.businessDaysBetweenDates(detail.startDate, e.target.value));
        }

        if (index === (scheduleDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow();
            scheduleDetails.push(newRow);
        }

        scheduleList = calculateDays(scheduleList);
        setSchedule(scheduleList);
        setSaveDisabled(false);
        setErrors({});
    }

    const calculateDays = (scheduleList: Schedule) => {
        var totalDays = 0;
        for (var i = 0; i < (scheduleList.scheduleDetails.length - 1); i++) {
            totalDays += (scheduleList.scheduleDetails[i].days * 1);
        }
        totalDays += (scheduleList.publicHolidayDays * 1);
        totalDays += (scheduleList.inclementWeatherDays * 1);
        scheduleList.totalDays = totalDays;

        return scheduleList;
    }

    const addBlankRow = () => {
        var blankRow: ScheduleDetail = {
            id: 0,
            scheduleId: schedule.id,
            description: "",
            costCodeId: -1,
            costCode: "",
            startDate: "",
            startDateFormatted: "",
            endDate: "",
            endDateFormatted: "",
            days: 0,
            percentageComplete: 0,
            percentageCompleteFormatted: "0 %",
            isDirty: false
        }
        return blankRow;
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    //delete row
    const deleteItem = (e: any, index: number) => {
        e.preventDefault();
        setDeleteOptions({ showConfirmModal: true, scheduleIndex: index });
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setDeleteOptions({ showConfirmModal: false, scheduleIndex: -1 });
        setModalSaveDisabled(false);
    }

    const updateSaveDisabled = () => {
        setModalSaveDisabled(true);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setSaveDisabled(false);
        setModalSaveDisabled(true);

        var scheduleList = schedule;
        var scheduleDetails = scheduleList.scheduleDetails;
        var scheduleDetail = scheduleDetails[deleteOptions.scheduleIndex];

        if (scheduleDetail.id === 0) {
            //not saved yet so just remove from list
            scheduleDetails.splice(deleteOptions.scheduleIndex, 1);
            setSchedule(scheduleList);
            setDeleteOptions({ showConfirmModal: false, scheduleIndex: -1 });

            toast.success("Schedule Item has been deleted");
        } else {
            deleteScheduleItem();
        }
    }

    const deleteScheduleItem = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var scheduleList = schedule;
        var scheduleDetails = scheduleList.scheduleDetails;
        var scheduleDetail = scheduleDetails[deleteOptions.scheduleIndex];

        var deleteDetail = {
            id: scheduleDetail.id,
            jobId: jobData.jobId,
            subId: user.sub
        };

        axios.post('Jobs/DeleteScheduleItem', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("Schedule Item has been deleted");

                //remove from list
                scheduleDetails.splice(deleteOptions.scheduleIndex, 1);
                scheduleList = calculateDays(scheduleList);
                setSchedule(scheduleList);
                setDeleteOptions({ showConfirmModal: false, scheduleIndex: -1 });
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end delete row

    //completion
    const viewCompletion = () => {
        viewCompletionReport();
    }

    const viewCompletionReport = async () => {
        const token = await authService.getAccessToken();
        //get photos
        axios.get('Jobs/GetJobPhotos?JobId=' + jobData.jobId + '&TypeId=1', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var photos = res.data.photos;
            if (!photos) {
                photos = [];
            }

            var uploadedPhotos: UploadedFile[] = [];
            for (var i = 0; i < photos.length; i++) {
                var uploadedPhoto: UploadedFile = {
                    file: {
                        id: photos[i].id,
                        fileName: photos[i].fileName,
                        fileType: photos[i].fileType,
                        content: photos[i].content,
                        photoOrder: photos[i].photoOrder
                    },
                    comment: photos[i].comment
                };
                uploadedPhotos.push(uploadedPhoto);
            }

            setShowPhotoModal(true);
            setUploadedFiles(uploadedPhotos);
        });
    }

    const hidePhotoImport = () => {
        setShowPhotoModal(false);
        setUploadedFiles([]);
    }

    const importPhotos = (files: File[]) => {
        setLoadingPhotos(true);
        importJobPhotos(files);
    }

    const importJobPhotos = async (files: File[]) => {
        //save photos
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        const data = new FormData()
        data.append('jobId', jobData.jobId.toString());
        data.append('typeId', "1");
        data.append('subId', user.sub);

        for(var i = 0; i < files.length; i++) {
            data.append('photos', files[i]);
        }

        //save photos
        axios.post('Jobs/ImportJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //save the returned photos
                var uploadedFileList: UploadedFile[] = [];
                var photos = res.data.photos;
                for (var p = 0; p < photos.length; p++) {
                    var photo = photos[p];
                    var file: UploadedFile = {
                        file: {
                            id: photo.id,
                            fileName: photo.fileName,
                            fileType: photo.fileType,
                            content: photo.content,
                            photoOrder: photo.photoOrder
                        },
                        comment: photo.comment
                    };
                    uploadedFileList.push(file);
                }
                setUploadedFiles(uploadedFileList);
                setLoadingPhotos(false);
                toast.success("Photos have been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
        
    }

    const updatePhotoImport = (files: UploadedFile[]) => {
        setUploadedFiles(files);
    }

    const deletePhotoImport = (deleteIndex: number) => {
        deleteJobPhotoImport(deleteIndex);
    }

    const deleteJobPhotoImport = async (deleteIndex: number) => {
        var files = [...uploadedFiles];
        var photo = files[deleteIndex];

        if (!photo) {
            toast.error("Could not find photo to delete!");
            return;
        }

        if (photo.file.id > 0) {
            const token = await authService.getAccessToken();
            const user = await authService.getUser();

            var data = {
                id: photo.file.id,
                subId: user.sub
            };

            //delete photo
            axios.post('Jobs/DeleteJobPhoto', data, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    errors.map(function (error: any) {
                        toast.error(error.content);
                    });
                } else {
                    files.splice(deleteIndex, 1);
                    //re-order the photos
                    for (var i = 0; i < files.length; i++) {
                        files[i].file.photoOrder = i + 1;
                    }
                    setUploadedFiles(files);
                    toast.success("Photo has been deleted");
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        } else {
            //save the photos on upload so this shouldn't happen but just in case
            files.splice(deleteIndex, 1);
            //re-order the photos
            for (var i = 0; i < files.length; i++) {
                files[i].file.photoOrder = i + 1;
            }
            setUploadedFiles(files);
            toast.success("Photo has been deleted");
        }
        
    }

    const savePhotoImport = () => {
        saveJobPhotoImport();
    }

    const saveJobPhotoImport = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var photoComments = [];
        for (var i = 0; i < uploadedFiles.length; i++) {
            var photoOrder = uploadedFiles[i].file.photoOrder;
            if (!photoOrder) {
                //set the order for any null entries
                photoOrder = i + 1;
            }

            var item = {
                id: uploadedFiles[i].file.id,
                comments: uploadedFiles[i].comment,
                photoOrder: photoOrder
            };
            photoComments.push(item);
        }

        var data = {
            jobPhotoComments: photoComments,
            subId: user.sub
        };

        //save comments
        axios.post('Jobs/SaveJobPhotoComments', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Photos have been saved");
                setShowPhotoModal(false);
                setUploadedFiles([]);
                //run report
                printCompletion();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const printCompletion = () => {
        var url = "/job/" + jobData.jobId + "/report/" + schedule.id + "/completion";
        window.open(url, '_blank');
    }

    const showSignature = () => {
        setShowSignatureModal(true);
    }

    const hideSignature = () => {
        setShowSignatureModal(false);
    }

    const saveSignature = (fileUrl: string, signatureName: string) => {
        saveCompleteSignature(fileUrl, signatureName);
    }

    const saveCompleteSignature = async (fileUrl: string, signatureName: string) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let scheduleList = schedule;
        //save photos
        const data = new FormData()
        data.append('jobId', jobData.jobId.toString());
        data.append('typeId', "8");
        data.append('signature', fileUrl);
        data.append('signatureName', signatureName);
        data.append('subId', user.sub);

        //save photos
        axios.post('Jobs/SaveJobSignature', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //tick signed completion received tickbox
                scheduleList.jobCompletionReceived = true;
                setSchedule(scheduleList);
                toast.success("Signature has been saved");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

        setShowSignatureModal(false);
    }
    //end completion

    //upload printed & signed completion report
    const uploadCompletion = (e: any) => {
        e.preventDefault();

        //if there is already a certificate get it
        if (schedule.jobCompletionUploaded) {
            getJobPhotos();
        } else {
            var uploadedFileItem = resetUploadedFile();
            setShowCompletionCertModal(true);
            setModalSaveDisabled(false);
            setSelectedFile(new File([""], "filename"));
            setUploadedFile(uploadedFileItem);
        }
        
    }

    const getJobPhotos = async () => {
        const token = await authService.getAccessToken();

        axios.get('Jobs/GetJobPhotos?JobId=' + jobData.jobId + '&TypeId=9', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var photos = res.data.photos;
            if (!photos) {
                photos = [];
            }
            var uploadedCert: AppFile = {
                id: 0,
                fileName: "",
                fileType: "",
                content: [],
                photoOrder: 1
            };
            if (photos.length > 0) {
                uploadedCert = {
                    id: photos[0].id,
                    fileName: photos[0].fileName,
                    fileType: photos[0].fileType,
                    content: photos[0].content,
                    photoOrder: photos[0].photoOrder
                };
            }

            setShowCompletionCertModal(true);
            setModalSaveDisabled(false);
            setSelectedFile(new File([""], "filename"));
            setUploadedFile(uploadedCert);
        });
    }

    const resetUploadedFile = () => {
        var uploadedFile: AppFile = {
            id: 0,
            fileName: "",
            fileType: "",
            content: [],
            photoOrder: 1
        };
        return uploadedFile;
    }

    const hideCompletionUpload = () => {
        var uploadedFileItem = resetUploadedFile();
        setShowCompletionCertModal(false);
        setModalSaveDisabled(false);
        setSelectedFile(new File([""], "filename"));
        setUploadedFile(uploadedFileItem);
    }

    const updateCompletionUpload = (selFile: File) => {
        setSelectedFile(selFile);
    }

    const deleteCompletionCertficate = () => {
        var scheduleItem = schedule;

        if (scheduleItem.jobCompletionId > 0) {
            deleteJobPhoto();
        } else {
            //remove the certificate
            scheduleItem.jobCompletionId = 0;
            scheduleItem.jobCompletionUploaded = false;
            setSchedule(scheduleItem);

            var uploadedFileItem = resetUploadedFile();
            setUploadedFile(uploadedFileItem);

            toast.success("Certificate has been deleted");
        }
    }

    const deleteJobPhoto = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var scheduleItem = schedule;

        var data = {
            id: scheduleItem.jobCompletionId,
            subId: user.sub
        };

        //delete photo
        axios.post('Jobs/DeleteJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var uploadedFileItem = resetUploadedFile();
                setUploadedFile(uploadedFileItem);

                scheduleItem.jobCompletionId = 0;
                scheduleItem.jobCompletionUploaded = false;
                setSchedule(scheduleItem);
                
                toast.success("Certificate has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const saveCompletionCertficate = () => {
        saveCompletionCert();
    }

    const saveCompletionCert = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        var scheduleItem = schedule;

        //save photos
        const data = new FormData()
        data.append('jobId', jobData.jobId.toString());
        data.append('typeId', "9");
        data.append('certificate', selectedFile);
        data.append('subId', user.sub);

        //save photos
        axios.post('Jobs/ImportJobCertificate', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var certId = res.data;
                scheduleItem.jobCompletionReceived = true;
                scheduleItem.jobCompletionId = certId;
                scheduleItem.jobCompletionUploaded = true;
                setSchedule(scheduleItem);

                var uploadedFileItem = resetUploadedFile();
                setUploadedFile(uploadedFileItem);

                setShowCompletionCertModal(false);
                setModalSaveDisabled(false);
                setSelectedFile(new File([""], "filename"));
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end upload printed & signed completion report

    const printSchedule = () => {
        var url = "/job/" + jobData.jobId + "/report/" + schedule.id + "/jobSchedule";
        window.open(url, '_blank');
    }

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = schedule.scheduleDetails;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length > 1) {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";
                var validStartDate = true;
                var validEndDate = true;

                if (!items[i].description) {
                    newErrorString += " Description, ";
                    error = true;
                }

                if (!items[i].startDate || !DateHelpers.isValidDate(items[i].startDate)) {
                    newErrorString += " Valid Start Date, ";
                    error = true;
                    validStartDate = false;
                }

                if (!items[i].endDate || !DateHelpers.isValidDate(items[i].endDate)) {
                    newErrorString += " Valid End Date, ";
                    error = true;
                    validEndDate = false;
                }

                if (validStartDate && validEndDate) {
                    if (!DateHelpers.compareDate(items[i].startDate, items[i].endDate)) {
                        newErrorString += " Start Date <= End Date, ";
                        error = true;
                    }
                }

                if (items[i].percentageComplete < 0 || items[i].percentageComplete > 100) {
                    newErrorString += " % Complete must be between 0 and 100, ";
                    error = true;
                }

                if (items[i].days < 1) {
                    newErrorString += " Days > 0, ";
                    error = true;
                }

                if (newErrorString != "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["schedule"] = errorString;
            setErrors(errorList);
        }
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveSchedule();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveSchedule = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var saveScheduleItem = schedule;
        saveScheduleItem.scheduleDetails.pop();

        var saveScheduleDetails = {
            schedule: saveScheduleItem,
            subId: user.sub
        };

        axios.post('Jobs/SaveSchedule', saveScheduleDetails, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
            } else {
                toast.success("Schedule Saved");
                var scheduleItem = res.data;
                if (!scheduleItem.jobCompletionReceived && canEdit) {
                    var newRow = addBlankRow();
                    scheduleItem.scheduleDetails.push(newRow);
                }
                setSchedule(scheduleItem);
                setErrors({});
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    
    let signatureText = "I confirm all the associated work with the claim: " + jobData.claimNumber + " for " + jobData.siteAddress + " that was completed by Skyline Building Group has been done so in a quality and professional manner.";
    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="schedule" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />
    let confirmPopup = <ConfirmModal heading="Delete Schedule Item" text="Are you sure you want to delete this schedule item?" hideConfirmModal={hideConfirmModal} showConfirmModal={deleteOptions.showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let photoImport = <PhotoImportPopup title="Select photos for Completion Certificate" showModal={showPhotoModal} loadingPhotos={loadingPhotos} uploadedFiles={uploadedFiles} hideModal={hidePhotoImport} importPhotos={importPhotos} update={updatePhotoImport} delete={deletePhotoImport} save={savePhotoImport} saveDisabled={modalSaveDisabled} />
    let getSignature = <SignaturePopup heading="Sign Completion Certificate" description={signatureText} showModal={showSignatureModal} hideModal={hideSignature} save={saveSignature} saveDisabled={modalSaveDisabled} updateSaveDisabled={updateSaveDisabled} />
    //upload printed and signed completion certificate
    var certtext = "Select the completion certficate (Signed Page ONLY) to upload.";
    let certUpload = <PhotoImportSinglePopup heading="Upload Certificate" text={certtext} fileType="Certificate" selectedFile={selectedFile} uploadedPhoto={uploadedFile} showModal={showCompletionCertModal} hideModal={hideCompletionUpload} update={updateCompletionUpload} delete={deleteCompletionCertficate} save={saveCompletionCertficate} saveDisabled={modalSaveDisabled} />

    let lastRow = schedule.scheduleDetails.length - 1;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
    const renderDetails = (
        <form onSubmit={save}>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <div className="static-modal">
                    {confirmPopup}
                    {photoImport}
                    {getSignature}
                    {certUpload}
                </div>
                <div className="input-group hideMobile">
                    <span className="jobSchedule__dateLabelSize">Start Date: {schedule.startDateFormatted}</span>
                    <span>End Date: {schedule.endDateFormatted}</span>
                </div>
                <div className="showMobile">
                    <div className="jobSchedule__add">
                        <span>Start Date: {schedule.startDateFormatted}</span><br></br>
                        <span>End Date: {schedule.endDateFormatted}</span>
                    </div>
                </div>
                <div className="hidden">
                    <a className="textalignright" href="https://www.google.com" target="_blank">Update OPUS</a>
                </div>

                <div className='overflowAuto'>
                    <table className="table--main table__small tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th></th>
                                <th>Description</th>
                                <th>Trade</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th className="textalignright">Bus. Days</th>
                                <th className="textalignright">% Complete</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedule.scheduleDetails.map((item, index) =>
                                <tr key={index}>
                                    <td className="hidden">{item.id}</td>
                                    <td>{ index + 1 }</td>
                                    <td className="table__text--align">
                                        <input type="text" maxLength={500} className={schedule.jobCompletionReceived || !canEdit ? "hidden" : "form-control table__tdmin-xlg"} name="description" value={item.description} onChange={(e) => handleCellChange(index, e)} />
                                        {schedule.jobCompletionReceived || !canEdit ? item.description : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <select className="select table__select--size" name="costCodeId" value={item.costCodeId} onChange={(e) => handleCellChange(index, e)} disabled={schedule.jobCompletionReceived || !canEdit ? true : false}>
                                            <option hidden defaultValue="-1"></option>
                                            {costcodes.map(costcode =>
                                                <option key={costcode.id} value={costcode.id}>{costcode.name}</option>
                                            )};
                                        </select>
                                    </td>
                                    <td className="table__text--align">
                                        <input type={schedule.jobCompletionReceived || !canEdit ? 'hidden' : "date"} name="startDate" className="form-control table__tdmin--md" value={item.startDate} onChange={(e) => handleCellChange(index, e)} />
                                        {schedule.jobCompletionReceived || !canEdit ? item.startDateFormatted : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <input type={schedule.jobCompletionReceived || !canEdit ? 'hidden' : "date"} name="endDate" className="form-control table__tdmin--md" value={item.endDate} onChange={(e) => handleCellChange(index, e)} />
                                        {schedule.jobCompletionReceived || !canEdit ? item.endDateFormatted : ""}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        <input type='number' min="0" step="any" className={schedule.jobCompletionReceived || !canEdit ? "hidden" : "form-control table__tdmin--sm textalignright"} name="days" value={item.days} onChange={(e) => handleCellChange(index, e)} />
                                        {schedule.jobCompletionReceived || !canEdit ? item.days : ""}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        <input type='number' min="0" max="100" step="any" className={schedule.jobCompletionReceived || !canEdit ? "hidden" : "form-control table__tdmin--sm textalignright"} name="percentageComplete" value={item.percentageComplete} onChange={(e) => handleCellChange(index, e)} />
                                        {schedule.jobCompletionReceived || !canEdit ? item.percentageComplete : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <div className={index === lastRow || schedule.jobCompletionReceived || !canEdit ? "hidden" : "delete--tablecell"}>
                                            <a className="makeitred" href="#" onClick={(e) => deleteItem(e, index)}>
                                                <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {errors["schedule"] ?
                        (errors["schedule"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="subTotalFormatted">
                        <span className="label labelLarge">Job Completion Certificate Received</span>
                        <input type="checkbox" className="input checkbox " id="jobCompletionReceived" name="jobCompletionReceived" checked={schedule.jobCompletionReceived} onChange={(e) => handleCheckboxChange(e)} disabled={!canEdit} />
                        <a title="Upload signed certificate" className={schedule.jobCompletionUploaded ? "file__photoColour" : ""} href="#" onClick={(e) => uploadCompletion(e)}>
                            <span className="fas fa-file-image edit--icon alignIconCenter"></span>
                        </a>
                    </label>
                    <div>
                        <button className="defaultbutton defaultbutton__medium" type="submit" disabled={!canEdit || saveDisabled}>Save</button>
                        <button className="defaultbutton defaultbutton__medium fourbuttons__btn2--position" type="button" onClick={showSignature} disabled={!canEdit}>Sign Completion</button>

                        <button className="defaultbutton defaultbutton__medium fourbuttons__btn3--position" type="button" onClick={viewCompletion}>Run Completion</button>
                        <button className={canEdit && isSafari ? "defaultbutton defaultbutton__medium fourbuttons__btn4--position" : "hidden"} type="button" onClick={printCompletion}>View Completion</button>
                            
                        <button className="defaultbutton defaultbutton__medium fourbuttons__btn4--position" type="button" onClick={printSchedule} disabled={!canEdit || schedule.scheduleDetails.length <= 1}>Print Schedule</button>
                    </div>
                </div>
                <div className="input-group-parent">
                    <label className="input-group estimate__alignTotals" htmlFor="inclementWeatherDays">
                        <span className="label">Inclement Weather etc.</span>
                        <input className="input estimate__totalsWidth textalignright" type="number" min={0} id="inclementWeatherDays" name="inclementWeatherDays" value={schedule.inclementWeatherDays} onChange={(e) => handleIncWeatherChange(e)} disabled={schedule.jobCompletionReceived || !canEdit}></input>
                    </label>
                    <label className="input-group estimate__alignTotals" htmlFor="totalDays">
                        <span className="label">Total Days</span>
                        <input className="input estimate__totalsWidth textalignright" type="number" id="totalDays" name="totalDays" value={schedule.totalDays} disabled></input>
                    </label>
                </div>
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;
}

export default JobScheduleData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import ExceedForecastsPopup from './JobPurchaseOrderExceedPopup';
import SupplierAddNewPopup from './SupplierAddNewPopup';
import AddClientContactPopups from './JobDetailsNewClientContact';
import authService from './api-authorization/AuthorizeService';

interface JobPurchaseOrderDetailsProps {
    id: number;
    jobId: number;
    canEdit: boolean;
    canViewReport: boolean;
    canAddNewSuppliers: boolean;
    checkSupplierCertificate: boolean;
    showBackConfirm: boolean;
    updateShowBackConfirm(showBackConfirm: boolean): void;
    goBackConfirm(): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface SupplierOption {
    id: number;
    name: string;
    registeredForGst: boolean;
    validCertificate: boolean;
    certificateExpiryText: string;
    certificateExpiryDate: string;
}

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplierId: number;
    supplierContactId: number;
    certificateExpiryDate: string;
    validCertificate: boolean;
    certificateExpiryText: string;
    dateRequired: string;
    includeFullScope: boolean;
    instructions: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    purchaseOrderDetails: PurchaseOrderDetail[];
    statusId: number;
    isDirty: boolean;
}

interface PurchaseOrderDetail {
    id: number;
    purchaseOrderId: number;
    costCodeId: number;
    costCode: string;
    budget: number;
    budgetFormatted: string;
    forecast: number;
    forecastFormatted: string;
    remaining: number;
    remainingFormatted: string;
    budgetRemaining: number;
    quantity: number;
    quantityType: string;
    unitOfMeasure: number;
    unitCost: number;
    unitCostFormatted: string;
    total: number;
    totalFormatted: string;
    isDirty: boolean;
}

interface ExceedForecast {
    costCodeId: number;
    exceedForecastReason: string;
}

interface ExceedForecastCheck {
    costCodeId: number;
    remaining: number;
    totalUsed: number;
}

interface CostCodeBudgetCheck {
    costCodeId: number;
    budgetRemaining: number;
    total: number;
}

const JobPurchaseOrderDetailsData = (props: JobPurchaseOrderDetailsProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [suppliers, setSuppliers] = React.useState<SupplierOption[]>([]);
    const [supplierContacts, setSupplierContacts] = React.useState<GenericListItem[]>([]);
    const [statusList, setStatusList] = React.useState<GenericListItem[]>([]);
    const [purchaseOrder, setPurchaseOrder] = React.useState<PurchaseOrder>({
        id: 0,
        jobId: props.jobId,
        supplierId: -1,
        supplierContactId: -1,
        certificateExpiryDate: "",
        validCertificate: true,
        certificateExpiryText: "",
        dateRequired: "",
        includeFullScope: false,
        instructions: "As per scope of works",
        subTotalFormatted: "",
        gstFormatted: "",
        totalFormatted: "",
        purchaseOrderDetails: [],
        statusId: 1,
        isDirty: false
    });
    const [costCodeList, setCostCodeList] = React.useState<GenericListItem[]>([]);
    const [uomList, setUomList] = React.useState<GenericListItem[]>([]);
    const [canExceedBudget, setCanExceedBudget] = React.useState(false);
    const [gstValue, setGstValue] = React.useState(0.1);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [showAddSupplier, setShowAddSupplier] = React.useState(false);
    const [showAddSupplierContact, setShowAddSupplierContact] = React.useState(false);
    const [deleteOptions, setDeleteOptions] = React.useState({ showConfirmModal: false, poIndex: 0 });
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [showExceedModal, setShowExceedModal] = React.useState(false);
    const [exceedForecast, setExceedForecast] = React.useState<ExceedForecast[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //get purchase order details
        if (props.id > 0) {
            axios.get('Jobs/GetPurchaseOrder?SubId=' + user.sub + '&JobId=' + props.jobId + '&Id=' + props.id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                let po = res.data.purchaseOrder;
                if (props.canEdit && res.data.purchaseOrder.statusId == 1) {
                    //add blank row to details
                    var blankRow = addBlankRow(res.data.purchaseOrder.id);
                    po.purchaseOrderDetails.push(blankRow);
                }
                setPurchaseOrder(po);
                setSuppliers(res.data.suppliers);
                setSupplierContacts(res.data.supplierContacts);
                setStatusList(res.data.statusList);
                setCostCodeList(res.data.costCodeList);
                setUomList(res.data.uomList);
                setCanExceedBudget(res.data.canExceedBudget);
                setGstValue(res.data.gstValue);
                setLoading(false);
            }).catch(error => {
                toast.error(error.message);
            });
        } else {
            //just get the dropdowns
            axios.get('Jobs/GetPurchaseOrderDropdowns?SubId=' + user.sub + '&JobId=' + props.jobId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                setSuppliers(res.data.suppliers);
                setStatusList(res.data.statusList);
                setCostCodeList(res.data.costCodeList);
                setUomList(res.data.uomList);
                setCanExceedBudget(res.data.canExceedBudget);
                setGstValue(res.data.gstValue);
                setLoading(false);
            }).catch(error => {
                toast.error(error.message);
            });

            var po = purchaseOrder;
            if (props.canEdit && po.statusId == 1) {
                //add blank row to details
                var blankRow = addBlankRow(po.id);
                po.purchaseOrderDetails.push(blankRow);
                setPurchaseOrder(po);
            }
        }
    }

    const goBackConfirm = () => {
        props.goBackConfirm();
    }

    const saveBackConfirm = () => {
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);
            savePO(true);
        } else {
            toast.error("Please fix the validation issues before saving");
            props.updateShowBackConfirm(false);
        }
    }

    const getSuppliers = async (supplierId: number) => {
        const token = await authService.getAccessToken();

        axios.get('Suppliers/GetSuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            let po = purchaseOrder;
            po.supplierId = supplierId;
            po.supplierContactId = -1;

            setSuppliers(res.data);
            setPurchaseOrder(po);
            getSupplierContacts(supplierId);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getSupplierContacts = async (supplierId: number) => {
        const token = await authService.getAccessToken();

        if (supplierId < 1) {
            setSuppliers([]);
        } else {
            axios.get('Suppliers/GetSupplierContacts?SupplierId=' + supplierId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setSupplierContacts(res.data);
        }).catch(error => {
            toast.error(error.message);
        });
        }
    }

    const addBlankRow = (poId: number) => {
        let blankRow: PurchaseOrderDetail = {
            id: 0,
            purchaseOrderId: poId,
            costCodeId: -1,
            costCode: "",
            budget: 0,
            budgetFormatted: "",
            forecast: 0,
            forecastFormatted: "",
            remaining: 0,
            remainingFormatted: "",
            budgetRemaining: 0,
            quantity: 0,
            quantityType: "",
            unitOfMeasure: -1,
            unitCost: 0,
            unitCostFormatted: "$0.00",
            total: 0,
            totalFormatted: "$0.00",
            isDirty: false
        };
        return blankRow;
    }

    //ADD NEW SUPPLIER
    const showAddSupplierModal = (e: any) => {
        e.preventDefault();

        setShowAddSupplier(true);
    }

    const hideAddSupplierModal = () => {
        setShowAddSupplier(false);
    }

    const saveAddSupplierModal = (supplierId: number) => {        
        setShowAddSupplier(false);
        getSuppliers(supplierId);
    }
    //END ADD NEW SUPPLIER

    //ADD NEW SUPPLIER CONTACT
    const showAddSupplierContactModal = (e: any) => {
        e.preventDefault();
        setShowAddSupplierContact(true);
    }

    const hideAddSupplierContactModal = () => {
        setShowAddSupplierContact(false);
    }

    const saveAddSupplierContactModal = (contactId: number) => {
        setPurchaseOrder(prevState => ({ ...prevState, supplierContactId: contactId }));
        setShowAddSupplierContact(false);

        getSupplierContacts(purchaseOrder.supplierId);
    }
    //END ADD NEW SUPPLIER CONTACT

    //change events
    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        var poDetails = [...purchaseOrder.purchaseOrderDetails];
        //if we change the status to/from Created we need to remove/add the blank details row
        if (e.target.name === "statusId" && purchaseOrder.statusId === 1 && e.target.value > 1) {
            //status changed from created so remove the blank row
            poDetails.pop();
        } else if (e.target.name === "statusId" && purchaseOrder.statusId > 1 && e.target.value === 1) {
            //status changed back to Created so add back the blank row
            var blankRow = addBlankRow(purchaseOrder.id);
            poDetails.push(blankRow);
        }

        setPurchaseOrder(prevState => ({ ...prevState, [e.target.name]: e.target.value, isDirty: true, purchaseOrderDetails: poDetails }));
        setSaveDisabled(false);
        setModalSaveDisabled(false);
    }

    const handleSupplierChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        let supplierId = e.target.value * 1;
        if (supplierId) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setPurchaseOrder(prevState => ({ ...prevState, supplierId: supplierId, isDirty: true }));

        //find certificate expiry text
        var supplierOption = suppliers.find(s => s.id === supplierId);
        if (supplierOption) {
            var certExpiry = supplierOption.certificateExpiryText;
            var validCert = supplierOption.validCertificate;
            var expiryDate = supplierOption.certificateExpiryDate;

            setPurchaseOrder(prevState => ({
                ...prevState,
                certificateExpiryText: certExpiry,
                validCertificate: validCert,
                certificateExpiryDate: expiryDate,
                isDirty: true
            }));
        }
        setSaveDisabled(false);
        setModalSaveDisabled(false);

        //get list of contacts
        getSupplierContacts(e.target.value);
    }

    const handleCheckboxChange = (e: any) => {
        setPurchaseOrder(prevState => ({ ...prevState, [e.target.name]: e.target.checked, isDirty: true }));
        setSaveDisabled(false);
        setModalSaveDisabled(false);
    }

    const handleCellChange = (index: number, e: any) => {
        var poDetails = [...purchaseOrder.purchaseOrderDetails];
        var poDetail = poDetails[index];
        let exist: any = {};
        exist = poDetail;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (index === (poDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(purchaseOrder.id);
            poDetails.push(newRow);
        }

        setPurchaseOrder(prevState => ({ ...prevState, purchaseOrderDetails: poDetails, isDirty: true }));
        setSaveDisabled(false);
        setModalSaveDisabled(false);
        setErrors({});
    }

    //separating so I can get the budget
    const handleCostCodeChange = (index: number, e: any) => {
        let poDetails = [...purchaseOrder.purchaseOrderDetails];
        let poDetail = poDetails[index];
        let costCodeId = e.target.value;
        poDetail.costCodeId = costCodeId;
        poDetail.isDirty = true;

        if (index === (poDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(purchaseOrder.id);
            poDetails.push(newRow);
        }

        //get the budget for the cost code
        if (e.target.value < 1) {
            poDetail.budget = 0;
            poDetail.budgetFormatted = "";
            poDetail.forecast = 0;
            poDetail.forecastFormatted = "";
            poDetail.remaining = 0;
            poDetail.remainingFormatted = "";
            setPurchaseOrder(prevState => ({ ...prevState, purchaseOrderDetails: poDetails, isDirty: true }));
            setSaveDisabled(false);
            setModalSaveDisabled(false);
            setErrors({});
        } else {
            getEstimateByCostCode(poDetails, poDetail, costCodeId);
        }
        
    }

    const getEstimateByCostCode = async (poDetails: PurchaseOrderDetail[], poDetail: PurchaseOrderDetail, costCodeId: number) => {
        const token = await authService.getAccessToken();

        axios.get('Estimate/GetEstimateByCostCode?JobId=' + props.jobId + '&PurchaseOrderId=' + props.id + '&CostCodeId=' + costCodeId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            poDetail.budget = res.data.budget;
            poDetail.budgetFormatted = res.data.budgetFormatted;
            poDetail.forecast = res.data.forecast;
            poDetail.forecastFormatted = res.data.forecastFormatted;
            poDetail.remaining = res.data.remaining;
            poDetail.remainingFormatted = res.data.remainingFormatted;
            poDetail.budgetRemaining = res.data.budgetRemaining;

            setPurchaseOrder(prevState => ({ ...prevState, purchaseOrderDetails: poDetails, isDirty: true }));
            setSaveDisabled(false);
            setModalSaveDisabled(false);
            setErrors({});
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const handleCellAmountChange = (index: number, e: any) => {
        var poDetails = [...purchaseOrder.purchaseOrderDetails];
        var poDetail = poDetails[index];
        let exist: any = {};
        exist = poDetail;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        //calculate unit cost / total
        if (e.target.name === "total" && poDetail.quantity > 0) {
            exist["unitCost"] = (e.target.value / poDetail.quantity).toFixed(2);
        } else if (e.target.name === "unitCost" && poDetail.quantity > 0) {
            exist["total"] = (e.target.value * poDetail.quantity).toFixed(2);
        } else {
            //quantity
            exist["total"] = (e.target.value * poDetail.unitCost).toFixed(2);
        }

        if (index === (poDetails.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(purchaseOrder.id);
            poDetails.push(newRow);
        }

        calculateTotals(poDetails);
        setPurchaseOrder(prevState => ({ ...prevState, purchaseOrderDetails: poDetails, isDirty: true }));
        setSaveDisabled(false);
        setModalSaveDisabled(false);
        setErrors({});
    }

    const calculateTotals = (poDetails: PurchaseOrderDetail[]) => {
        //check if supplier is registered for gst
        let registeredForGst = true;
        let supplier = suppliers.find(s => s.id == purchaseOrder.supplierId);
        if (supplier) {
            registeredForGst = supplier.registeredForGst;
        }

        var subTotalExGst = 0;
        var gst = 0;
        var total = 0;

        for (var i = 0; i < (poDetails.length - 1); i++) {
            subTotalExGst += (poDetails[i].total * 1);
        }
        gst = registeredForGst ? (subTotalExGst * gstValue) : 0;
        total = subTotalExGst + gst;

        var subTotalExGstFormatted = subTotalExGst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var gstFormatted = gst.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        var totalFormatted = total.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });

        setPurchaseOrder(prevState => ({ ...prevState, subTotalFormatted: subTotalExGstFormatted, gstFormatted: gstFormatted, totalFormatted: totalFormatted }));
    }
    //end change events

    //delete row
    const deleteItem = (e: any, index: number) => {
        e.preventDefault();
        setDeleteOptions({ showConfirmModal: true, poIndex: index });
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setDeleteOptions({ showConfirmModal: false, poIndex: -1 });
        setModalSaveDisabled(false);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        var poDetails = [...purchaseOrder.purchaseOrderDetails];
        var poDetail = poDetails[deleteOptions.poIndex];

        if (poDetail.id == 0) {
            //not saved yet so just remove from list
            poDetails.splice(deleteOptions.poIndex, 1);
            //recalculate totals
            calculateTotals(poDetails);

            setPurchaseOrder(prevState => ({ ...prevState, purchaseOrderDetails: poDetails }));
            setDeleteOptions({ showConfirmModal: false, poIndex: -1 });
            setSaveDisabled(false);
            setModalSaveDisabled(false);
            toast.success("Item has been deleted");

        } else {
            deletePODetail();
        }
    }

    const deletePODetail = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var poDetails = [...purchaseOrder.purchaseOrderDetails];
        var poDetail = poDetails[deleteOptions.poIndex];

        var deleteDetail = {
            PurchaseOrderId: purchaseOrder.id,
            id: poDetail.id,
            jobId: props.jobId,
            subId: user.sub
        };

        axios.post('Jobs/DeletePurchaseOrderDetail', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Item has been deleted");

                //remove from list
                poDetails.splice(deleteOptions.poIndex, 1);
                //recalculate totals
                calculateTotals(poDetails);

                setPurchaseOrder(prevState => ({ ...prevState, purchaseOrderDetails: poDetails }));
                setDeleteOptions({ showConfirmModal: false, poIndex: -1 });
                setSaveDisabled(false);
                setModalSaveDisabled(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end delete row

    //exceed forecast notes
    const updateExceedForecast = (updatedExceedForecast: ExceedForecast[]) => {
        setExceedForecast(updatedExceedForecast);
        setSaveDisabled(false);
    }

    const cancelExceedForecast = () => {
        setShowExceedModal(false);
    }

    const saveExceedForecast = () => {
        savePO(false);
        setShowExceedModal(false);
    }
    //end exceed forecast notes

    const view = (e: any) => {
        //view purchase order
        e.preventDefault();
        var url = "/job/" + props.jobId + "/report/" + purchaseOrder.id + "/PurchaseOrder";
        window.open(url, '_blank');
    }

    const validate = () => {
        let fields = purchaseOrder;
        let errorList: any = {};
        let formIsValid = true;

        if (fields.supplierId < 0) {
            formIsValid = false;
            errorList["supplierId"] = "Supplier is required";
        }

        if (fields.supplierContactId < 0) {
            formIsValid = false;
            errorList["supplierContactId"] = "Attention is required";
        }

        if (fields.statusId < 0) {
            formIsValid = false;
            errorList["statusId"] = "Status is required";
        }

        if (props.checkSupplierCertificate && !fields.validCertificate) {
            formIsValid = false;
            errorList["supplierId"] = "A valid certificate is required";
        }

        var budgetError = false;
        var budgetCheck: CostCodeBudgetCheck[] = [];
        var rowNumber = 0;
        let items = fields.purchaseOrderDetails;
        var errorString = "Please enter the following values: ";
        var error = false;
        var lengthCheck = fields.statusId < 2 ? 2 : 1;  //blank row for Created status to cater for
        if (items.length < lengthCheck) {
            errorString = "Please enter at least one Line Item!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                let costCodeId = items[i].costCodeId * 1;
                if (costCodeId < 0) {
                    newErrorString += " Cost Code, ";
                    error = true;
                }

                let quantity = items[i].quantity;
                if (quantity === 0) {
                    newErrorString += " Quantity, ";
                    error = true;
                }

                if (items[i].unitOfMeasure < 0) {
                    newErrorString += " UOM, ";
                    error = true;
                }

                let unitCost = items[i].unitCost * 1;
                if (unitCost === 0) {
                    newErrorString += " Unit Cost, ";
                    error = true;
                }

                let total = items[i].total * 1;
                if (!canExceedBudget && total > items[i].budgetRemaining) {
                    newErrorString += " Cannot exceed Budget, ";
                    error = true;
                    budgetError = true;
                }

                var checkBudget = budgetCheck.find(b => b.costCodeId === costCodeId);
                if (checkBudget) {
                    checkBudget.total += total;
                } else {
                    let newCheckBudget: CostCodeBudgetCheck = {
                        costCodeId: costCodeId,
                        budgetRemaining: items[i].budgetRemaining,
                        total: total
                    };
                    budgetCheck.push(newCheckBudget);
                }

                if (newErrorString != "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (!budgetError && !canExceedBudget) {
            //check if we have exceeded a costcode budget over entire po.  Caters for costcode being split across multiple lines
            for (var i = 0; i < budgetCheck.length; i++) {
                if (budgetCheck[i].total > budgetCheck[i].budgetRemaining) {
                    formIsValid = false;
                    errorList["budgetCheck"] = "Cost Codes cannot exceed Budget!";
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["details"] = errorString;
        }

        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            //if any row items have changed check exceed forecast otherwise just save
            var rowChanged = purchaseOrder.purchaseOrderDetails.filter(po => po.isDirty);
            if (rowChanged.length === 0) {
                savePO(false);
            }
            else {
                //check if any items exceed forecast
                var exceedForecastCheck: ExceedForecastCheck[] = [];
                var poDetails = purchaseOrder.purchaseOrderDetails;
                for (var i = 0; i < (poDetails.length - 1); i++) {
                    var exists = exceedForecastCheck.find(f => f.costCodeId === (poDetails[i].costCodeId * 1));
                    if (exists) {
                        //update total
                        exists.totalUsed += (poDetails[i].total * 1);
                    } else {
                        //add new row
                        var newItem: ExceedForecastCheck = {
                            costCodeId: poDetails[i].costCodeId,
                            remaining: poDetails[i].remaining,
                            totalUsed: poDetails[i].total
                        };
                        exceedForecastCheck.push(newItem);
                    }
                }

                //check if any items exceed forecast
                var newExceedForecast: ExceedForecast[] = [];
                for (var y = 0; y < exceedForecastCheck.length; y++) {
                    if (exceedForecastCheck[y].totalUsed > exceedForecastCheck[y].remaining) {
                        var newExceed: ExceedForecast = {
                            costCodeId: exceedForecastCheck[y].costCodeId,
                            exceedForecastReason: ""
                        };
                        newExceedForecast.push(newExceed);
                    }
                }
                setExceedForecast(newExceedForecast);

                if (newExceedForecast.length === 0) {
                    savePO(false);
                } else {
                    setShowExceedModal(true);
                }
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const savePO = async (goBack: boolean) => {
        if (purchaseOrder.statusId === 1) {
            var poDetails = purchaseOrder.purchaseOrderDetails;
            poDetails.pop();
        }

        const user = await authService.getUser();
        var saveData = {
            purchaseOrder: purchaseOrder,
            exceedForecast: exceedForecast,
            subId: user.sub
        };

        const token = await authService.getAccessToken();
        axios.post('Jobs/SavePurchaseOrder', saveData, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Purchase Order Saved");
                if (goBack) {
                    goBackConfirm();
                } else {
                    var po = res.data;
                    if (po.statusId === 1) {
                        var blankRow = addBlankRow(po.id);
                        po.purchaseOrderDetails.push(blankRow);
                    }
                    setPurchaseOrder(po);
                    setErrors({});
                }
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var rowChanged = purchaseOrder.purchaseOrderDetails.filter(po => po.isDirty);
    if (props.showBackConfirm && !purchaseOrder.isDirty && rowChanged.length === 0) {
        props.goBackConfirm();
    }

    let postatus = purchaseOrder.statusId == 1 ? statusList : statusList.filter(s => s.id > 1);
    let lastRow = (purchaseOrder.statusId == 1 && props.canEdit) ? purchaseOrder.purchaseOrderDetails.length - 1 : purchaseOrder.purchaseOrderDetails.length;
    let viewDisabled = purchaseOrder.id === 0 || purchaseOrder.isDirty || rowChanged.length > 0;

    let confirmPopup = <ConfirmModal heading="Delete PO Row" text="Are you sure you want to delete this purchase order detail row?" hideConfirmModal={hideConfirmModal} showConfirmModal={deleteOptions.showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmBackPopup = <ConfirmModal heading="Save before going Back?" text="Do you want to save your changes before going back?" hideConfirmModal={hideConfirmModal} showConfirmModal={props.showBackConfirm} noConfirmModal={goBackConfirm} yesConfirmModal={saveBackConfirm} saveDisabled={modalSaveDisabled} />
    let exceedForecastPopup = <ExceedForecastsPopup costCodeList={costCodeList} exceedForecast={exceedForecast} showModal={showExceedModal} cancelModal={cancelExceedForecast} update={updateExceedForecast} saveModal={saveExceedForecast} />
    let addSupplier = <SupplierAddNewPopup showModal={showAddSupplier} hideModal={hideAddSupplierModal} saveModal={saveAddSupplierModal} />
    let addSupplierContact = <AddClientContactPopups parentId={purchaseOrder.supplierId} parentTypeId={2} showAddClientContactModal={showAddSupplierContact} hideAddClientContactModal={hideAddSupplierContactModal} saveAddClientContactModal={saveAddSupplierContactModal} />

    const renderDetails = (
        <div>
            <div className="static-modal">
                {confirmPopup}
                {confirmBackPopup}
                {exceedForecastPopup}
                {addSupplier}
                {addSupplierContact}
            </div>
            <div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="supplierId">
                        <span className="label">Supplier
                            <a className={(props.canAddNewSuppliers && purchaseOrder.statusId < 2 && props.canEdit ) ? "marginLeft10" : "hidden"} href="#" onClick={(e) => showAddSupplierModal(e)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="supplierId" name="supplierId" value={purchaseOrder.supplierId} onChange={(e) => handleSupplierChange(e)} disabled={purchaseOrder.statusId > 1 || !props.canEdit ? true : false}>
                            <option defaultValue="-1" value="-1"></option>
                            {suppliers.map(supplier =>
                                <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                            )};
                        </select>
                    </label>
                    <div className={purchaseOrder.validCertificate ? "marginLeftLabel" : "marginLeftLabel makeitred"}>{purchaseOrder.certificateExpiryText}</div>
                    <span className={errors["supplierId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierId"]}</span>

                    <label className="input-group" htmlFor="supplierContactId">
                        <span className="label">Attention
                            <a className={purchaseOrder.supplierId < 0 || purchaseOrder.statusId > 1 || !props.canEdit || !props.canAddNewSuppliers ? "hidden" : "marginLeft10"} href="#" onClick={(e) => showAddSupplierContactModal(e)}>
                                Add New
                            </a>
                        </span>
                        <select className="select" id="supplierContactId" name="supplierContactId" value={purchaseOrder.supplierContactId} onChange={(e) => handleChange(e)} disabled={purchaseOrder.statusId > 1 || !props.canEdit ? true : false}>
                            <option defaultValue="-1" value="-1"></option>
                            {supplierContacts.map(contact =>
                                <option key={contact.id} value={contact.id}>{contact.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["supplierContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierContactId"]}</span>

                    <label className="input-group" htmlFor="dateRequired">
                        <span className="label">Date Required</span>
                        <input type='date' id="dateRequired" name="dateRequired" className='input' value={purchaseOrder.dateRequired} onChange={(e) => handleChange(e)} disabled={purchaseOrder.statusId > 1 || !props.canEdit ? true : false}></input>
                    </label>
                    <span className={errors["dateRequired"] ? "label errors errors__leftmargin" : "hidden"}>{errors["dateRequired"]}</span>

                    <label className="input-group" htmlFor="statusId">
                        <span className="label">Status</span>
                        <select className="select" id="statusId" name="statusId" value={purchaseOrder.statusId} onChange={(e) => handleChange(e)} disabled={!props.canEdit || purchaseOrder.statusId == 4}>
                            {postatus.map(status =>
                                <option key={status.id} value={status.id}>{status.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["statusId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["statusId"]}</span>
                </div>
                <div className="input-group-parent">
                    <label className="input-group jobpo__textarea" htmlFor="instructions">
                        <span className="label">Instructions</span>
                        <textarea className="input jobpo__textHeight" id="instructions" name="instructions" value={purchaseOrder.instructions} onChange={(e) => handleChange(e)} disabled={!props.canEdit}></textarea>
                    </label>
                    <label className="input-group" htmlFor="includeFullScope">
                        <span className="label">Include Full Scope</span>
                        <input className="checkbox" type="checkbox" id="includeFullScope" name="includeFullScope" checked={purchaseOrder.includeFullScope} onChange={(e) => handleCheckboxChange(e)} disabled={!props.canEdit}></input>
                    </label>
                </div>
                <div className="job__noteGridSize overflowAuto">
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th>Cost Code</th>
                                <th className="textalignright">Budget</th>
                                <th className="textalignright">Forecast</th>
                                <th className="textalignright">Remaining</th>
                                <th className="textalignright">Quantity</th>
                                <th>UOM</th>
                                <th className="textalignright">Unit Cost</th>
                                <th className="textalignright">Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseOrder.purchaseOrderDetails.map((detail, index) =>
                                <tr key={index}>
                                    <td className="table__text--align">
                                        <select className="select table__select--size" name="costCodeId" value={detail.costCodeId} onChange={(e) => handleCostCodeChange(index, e)} disabled={purchaseOrder.statusId > 1 || !props.canEdit ? true : false}>
                                            <option hidden defaultValue="-1"></option>
                                            {costCodeList.map(item =>
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )};
                                        </select>
                                    </td>
                                    <td className="table__text--align textalignright">{detail.budgetFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.forecastFormatted}</td>
                                    <td className="table__text--align textalignright">{detail.remainingFormatted}</td>
                                    <td className="table__text--align estimate__tdWidth textalignright">
                                        <input type='number' min="0" step="any" className={purchaseOrder.statusId > 1 || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="quantity" value={detail.quantity} onChange={(e) => handleCellAmountChange(index, e)} />
                                        {purchaseOrder.statusId > 1 || !props.canEdit ? detail.quantity : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <select className="select table__select--size" name="unitOfMeasure" value={detail.unitOfMeasure} onChange={(e) => handleCellChange(index, e)} disabled={purchaseOrder.statusId > 1 || !props.canEdit ? true : false}>
                                            <option hidden defaultValue="-1"></option>
                                            {uomList.map(item =>
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )};
                                        </select>
                                    </td>
                                    <td className="table__text--align estimate__tdWidth textalignright">
                                        <input type='number' min="0" step="any" className={purchaseOrder.statusId > 1 || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="unitCost" value={detail.unitCost} onChange={(e) => handleCellAmountChange(index, e)} />
                                        {purchaseOrder.statusId > 1 || !props.canEdit ? detail.unitCostFormatted : ""}
                                    </td>
                                    <td className="table__text--align estimate__tdWidth textalignright">
                                        <input type='number' min="0" step="any" className={purchaseOrder.statusId > 1 || !props.canEdit ? "hidden" : "form-control estimate__qtyWidth textalignright"} name="total" value={detail.total} onChange={(e) => handleCellAmountChange(index, e)} />
                                        {purchaseOrder.statusId > 1 || !props.canEdit ? detail.totalFormatted : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <div className={index === lastRow || purchaseOrder.statusId > 1 || !props.canEdit ? "hidden" : "delete--tablecell"}>
                                            <a className="makeitred" href="#" onClick={(e) => deleteItem(e, index)}>
                                                <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {errors["details"] ?
                        (errors["details"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>
                <span className={errors["budgetCheck"] ? "label errors" : "hidden"}>{errors["budgetCheck"]}</span>
            </div>
            <div className="input-group-parent--row">
                <button className="defaultbutton" type="button" onClick={save} disabled={!props.canEdit || saveDisabled}>Save</button>
                <button className={props.canViewReport ? "defaultbutton defaultbutton__small marginLeft10" : "hidden"} type="button" onClick={view} disabled={viewDisabled}>View</button>
            </div>
            <div className="input-group-parent">
                <label className="input-group estimate__alignTotals estimate__width" htmlFor="subTotalExGstFormatted">
                    <span className="label">Sub Total ex GST</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="subTotalExGstFormatted" name="subTotalExGstFormatted" value={purchaseOrder.subTotalFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="gstFormatted">
                    <span className="label">GST</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="gstFormatted" name="gstFormatted" value={purchaseOrder.gstFormatted} disabled></input>
                </label>
                <label className="input-group estimate__alignTotals" htmlFor="totalFormatted">
                    <span className="label">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={purchaseOrder.totalFormatted} disabled></input>
                </label>
            </div>
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );

}

export default JobPurchaseOrderDetailsData;

import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface VariationCashSettlementProps {
    jobId: number;
    variationId: number;
    variationHeaderId: number;
    showModal: boolean;
    hideModal(): void;
    saveModal(): void;
}

interface CashSettlement {
    selected: boolean;
    costCodeId: number;
    costCode: string;
    remaining: number;
    remainingFormatted: string;
}

const VariationCashSettlementPopup = (props: VariationCashSettlementProps) => {
    const [cashSettlementItems, setCashSettlementItems] = React.useState<CashSettlement[]>([]);
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        axios.get('Variation/GetCashSettlementItems?JobId=' + props.jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setCashSettlementItems(res.data.cashSettlementItems);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any, itemIndex: number) => {
        var items = [...cashSettlementItems]
        var item = items[itemIndex];

        item.selected = e.target.checked;

        setCashSettlementItems(items);
        setSaveDisabled(false);
    }

    const hideModal = () => {
        setSaveDisabled(false);
        props.hideModal();
    }

    const save = () => {
        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);

        var selectedItems = cashSettlementItems.filter(c => c.selected);

        if (selectedItems.length < 1) {
            toast.error("Please select at least one Cost Code to cash settle!");
        } else {
            saveCashSettlement(selectedItems);
        }
    }

    const saveCashSettlement = async (selectedItems: CashSettlement[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var saveCashSettlement = {
            jobId: props.jobId,
            variationId: props.variationId,
            variationHeaderId: props.variationHeaderId,
            cashSettlementItems: selectedItems,
            subId: user.sub
        };

        axios.post('Variation/SaveCashSettlementItems', saveCashSettlement, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setSaveDisabled(false);
                props.saveModal();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <Modal show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add Cash Settlement Items</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <span>Select Cost Codes that you want to cash settle</span>
                <div><span className="makeitred makeitbold">CALC NOTE: </span>Estimate Value + Approved Variations - Purchase Orders</div>
                <div>Cancel any Purchase Orders not going ahead before cash settling</div>
                <div className='overflowAuto'>
                    <table className="table--main table__small tableColours">
                        <tbody>
                            {cashSettlementItems.map((item, index) =>
                                <tr key={item.costCodeId} >
                                    <td className="hidden">{item.costCodeId}</td>
                                    <td className="table__text--align">
                                        <input type="checkbox" className="input checkbox" name="selected" checked={item.selected} onChange={(e) => handleChange(e, index)} />
                                    </td>
                                    <td className="table__text--align">
                                        {item.costCode}
                                    </td>
                                    <td className="table__text--align">
                                        {item.remainingFormatted}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={save} disabled={saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default VariationCashSettlementPopup;
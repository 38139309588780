import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleAutoComplete from './GoogleAutoComplete';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

interface JobDetails {
    subTypeId: number;
    siteAddress: string;
    siteAddressUnitNumber: string;
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    googlePlaceId: string;
    siteAddressLatitude: number;
    siteAddressLongitude: number;
    customerName: string;
    customerPhone: string;
    insurerId: number;
    insurerContactId: number;
    adjusterId: number;
    adjusterContactId: number;
    brokerId: number;
    brokerContactId: number;
    claimNumber: string;
    claimStatusId: number;
    estimatorId: number;
    supervisorId: number;
    suppliers: GenericListItem[];
}

interface GenericListItem {
    id: number;
    name: string;
}

interface AddressDetails {
    fullAddress: string;
    subpremise: string;
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_1: string;
    country: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    placeId: string;
};

const NewJobMakeSafeMobileData = () => {
    let navigate = useNavigate();
    const [jobSubTypes, setJobSubTypes] = React.useState<GenericListItem[]>([]);
    const [insurers, setInsurers] = React.useState<GenericListItem[]>([]);
    const [adjusters, setAdjusters] = React.useState<GenericListItem[]>([]);
    const [brokers, setBrokers] = React.useState<GenericListItem[]>([]);
    const [insurerContacts, setInsurerContacts] = React.useState<GenericListItem[]>([]);
    const [adjusterContacts, setAdjusterContacts] = React.useState<GenericListItem[]>([]);
    const [brokerContacts, setBrokerContacts] = React.useState<GenericListItem[]>([]);
    const [estimators, setEstimators] = React.useState<GenericListItem[]>([]);
    const [supervisors, setSupervisors] = React.useState<GenericListItem[]>([]);
    const [supplierOptions, setSupplierOptions] = React.useState<GenericListItem[]>([]);
    const [supplierId, setSupplierId] = React.useState(-1);
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [jobDetails, setJobDetails] = React.useState<JobDetails>({
        subTypeId: -1,
        siteAddress: "",
        siteAddressUnitNumber: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "",
        postCode: "",
        googlePlaceId: "",
        siteAddressLatitude: 0,
        siteAddressLongitude: 0,
        customerName: "",
        customerPhone: "",
        insurerId: -1,
        insurerContactId: -1,
        adjusterId: -1,
        adjusterContactId: -1,
        brokerId: -1,
        brokerContactId: -1,
        claimNumber: "",
        claimStatusId: 1,
        estimatorId: -1,
        supervisorId: -1,
        suppliers: []
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const getData = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetDropdownsMobile&subId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setJobSubTypes(res.data.jobSubTypes);
            setInsurers(res.data.insurers);
            setAdjusters(res.data.adjusters);
            setBrokers(res.data.brokers);
            setEstimators(res.data.estimators);
            setSupervisors(res.data.supervisors);
            setSupplierOptions(res.data.suppliers);
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        var error = errors;
        if (e.target.value) {
            delete error[e.target.name];
            setErrors(error);
        }

        setJobDetails(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleClientChange = (e: any, typeId: number) => {
        var error = errors;
        if (e.target.value) {
            delete error[e.target.name];
            delete error["clientId"];
            setErrors(error);
        }

        let clientId = e.target.value * 1;
        setJobDetails(prevState => ({ ...prevState, [e.target.name]: clientId }));

        if (clientId > 0) {
            getContacts(e.target.value, typeId);
        }
    }

    const handleSupplierChange = (e: any) => {
        setErrors({});

        var job = jobDetails;
        var suppliers = job.suppliers;
        var newSupplier: GenericListItem = {
            id: parseInt(e.target.value),
            name: e.target.selectedOptions[0].label
        };
        suppliers.push(newSupplier);
        setJobDetails(job);
    }

    const removeSupplier = (e: any, id: number) => {
        e.preventDefault();
        setErrors({});

        var job = jobDetails;
        var suppliers = job.suppliers;
        suppliers.splice(suppliers.findIndex(function (i) {
            return i.id === id;
        }), 1);
        setJobDetails(job);
    }

    const getContacts = async (clientId: number, typeId: number) => {
        if (clientId <= 0) {
            if (typeId === 1) {
                setInsurerContacts([]);
            } else if (typeId === 2) {
                setAdjusterContacts([]);
            } else {
                setBrokerContacts([]);
            }
        } else {
            const token = await authService.getAccessToken();
            axios.get('Jobs/GetClientContactDropdown?clientId=' + clientId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                var contacts = res.data;
                if (typeId === 1) {
                    setInsurerContacts(contacts);
                } else if (typeId === 2) {
                    setAdjusterContacts(contacts);
                } else {
                    setBrokerContacts(contacts);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        }
    }

    //update google address
    const setAddress = (addressDetails: AddressDetails) => {
        var error = errors;
        delete error["siteAddress"];
        setErrors(error);

        setJobDetails(prevState => ({
            ...prevState,
            siteAddress: addressDetails.fullAddress,
            streetNumber: addressDetails.street_number,
            streetName: addressDetails.route,
            suburb: addressDetails.locality,
            state: addressDetails.administrative_area_level_1,
            country: addressDetails.country,
            postCode: addressDetails.postal_code,
            googlePlaceId: addressDetails.placeId,
            siteAddressLatitude: addressDetails.latitude,
            siteAddressLongitude: addressDetails.longitude
        }));
    }

    const validate = () => {
        let fields = jobDetails;
        let error: any = {};
        let formIsValid = true;

        if (fields.subTypeId < 0) {
            formIsValid = false;
            error["subTypeId"] = "Sub Type is required";
        }

        if (!fields.siteAddress) {
            formIsValid = false;
            error["siteAddress"] = "Site Address is required";
        }

        if (!fields.customerName) {
            formIsValid = false;
            error["customerName"] = "Site Contact Name is required";
        }

        if (!fields.customerPhone) {
            formIsValid = false;
            error["customerPhone"] = "Site Contact Phone is required";
        }

        if (fields.insurerId < 0 && fields.adjusterId < 0 && fields.brokerId < 0) {
            formIsValid = false;
            error["clientId"] = "Insurer OR Adjuster OR Broker is required";
        }

        if (fields.estimatorId < 0 && fields.supervisorId < 0) {
            formIsValid = false;
            error["supervisorId"] = "Estimator OR Supervisor is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            saveMSJob();
        } else {
            toast.error("Please fix validation errors on screen before saving!");
        }
    }

    const saveMSJob = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var saveMsJob = {
            subId: user.sub,
            makeSafeJob: jobDetails
        };

        //save job
        axios.post('Jobs/SaveMSJob', saveMsJob, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Make Safe job successfully created");
                navigate("/");
            } 
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var googleContents = <GoogleAutoComplete addressId="autocomplete" addressLabel={"Site Address"} placeholder={jobDetails.siteAddress} className="input-group" disabled={false} save={setAddress} />

    const renderDetails = (
        <div>
            <div className="mobileFont marginTop10 marginBottom10">
                <h4 className="mobile-margin">Create MakeSafe Job</h4>

                <label className="input-group" htmlFor="subTypeId">
                    <span className="label">Job Sub Type</span>
                    <select className="select" id="subTypeId" name="subTypeId" value={jobDetails.subTypeId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {jobSubTypes.map(type =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["subTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["subTypeId"]}</span>

                {googleContents}
                <span className={errors["siteAddress"] ? "label errors errors__leftmargin" : "hidden"}>{errors["siteAddress"]}</span>

                <label className="input-group" htmlFor="siteAddressUnitNumber">
                    <span className="label">Unit Number</span>
                    <input className='input' type='text' maxLength={100} id="siteAddressUnitNumber" name="siteAddressUnitNumber" value={jobDetails.siteAddressUnitNumber} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="customerName">
                    <span className="label">Site Contact Name</span>
                    <input className='input' type='text' maxLength={100} id="customerName" name="customerName" value={jobDetails.customerName} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["customerName"] ? "label errors errors__leftmargin" : "hidden"}>{errors["customerName"]}</span>

                <label className="input-group" htmlFor="customerPhone">
                    <span className="label">Site Contact Phone</span>
                    <input className='input' type='text' maxLength={100} id="customerPhone" name="customerPhone" value={jobDetails.customerPhone} onChange={(e) => handleChange(e)}></input>
                </label>
                <span className={errors["customerPhone"] ? "label errors errors__leftmargin" : "hidden"}>{errors["customerPhone"]}</span>

                <label className="input-group" htmlFor="insurerId">
                    <span className="label">Insurer</span>
                    <select className="select" id="insurerId" name="insurerId" value={jobDetails.insurerId} onChange={(e) => handleClientChange(e, 1)}>
                        <option defaultValue="-1"></option>
                        {insurers.map(ins =>
                            <option key={ins.id} value={ins.id}>{ins.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["clientId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["clientId"]}</span>

                <label className={jobDetails.insurerId <= 0 ? "hidden" : "input-group"} htmlFor="insurerContactId">
                    <span className="label">Contact</span>
                    <select className="select" id="insurerContactId" name="insurerContactId" value={jobDetails.insurerContactId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {insurerContacts.map(ins =>
                            <option key={ins.id} value={ins.id}>{ins.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="claimNumber">
                    <span className="label">Claim #</span>
                    <input className='input' type='text' maxLength={20} id="claimNumber" name="claimNumber" value={jobDetails.claimNumber} onChange={(e) => handleChange(e)}></input>
                </label>

                <label className="input-group" htmlFor="adjusterId">
                    <span className="label">Adjuster</span>
                    <select className="select" id="adjusterId" name="adjusterId" value={jobDetails.adjusterId} onChange={(e) => handleClientChange(e, 2)}>
                        <option defaultValue="-1"></option>
                        {adjusters.map(adj =>
                            <option key={adj.id} value={adj.id}>{adj.name}</option>
                        )};
                    </select>
                </label>

                <label className={jobDetails.adjusterId <= 0 ? "hidden" : "input-group"} htmlFor="adjusterContactId">
                    <span className="label">Contact</span>
                    <select className="select" id="adjusterContactId" name="adjusterContactId" value={jobDetails.adjusterContactId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {adjusterContacts.map(adj =>
                            <option key={adj.id} value={adj.id}>{adj.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="brokerId">
                    <span className="label">Broker</span>
                    <select className="select" id="brokerId" name="brokerId" value={jobDetails.brokerId} onChange={(e) => handleClientChange(e, 3)}>
                        <option defaultValue="-1"></option>
                        {brokers.map(bkr =>
                            <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                        )};
                    </select>
                </label>

                <label className={jobDetails.brokerId <= 0 ? "hidden" : "input-group"} htmlFor="brokerContactId">
                    <span className="label">Contact</span>
                    <select className="select" id="brokerContactId" name="brokerContactId" value={jobDetails.brokerContactId} onChange={(e) => handleChange(e)}>
                        <option hidden defaultValue="-1"></option>
                        {brokerContacts.map(bkr =>
                            <option key={bkr.id} value={bkr.id}>{bkr.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="estimatorId">
                    <span className="label">Estimator</span>
                    <select className="select" id="estimatorId" name="estimatorId" value={jobDetails.estimatorId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {estimators.map(est =>
                            <option key={est.id} value={est.id}>{est.name}</option>
                        )};
                    </select>
                </label>

                <label className="input-group" htmlFor="supervisorId">
                    <span className="label">Supervisor</span>
                    <select className="select" id="supervisorId" name="supervisorId" value={jobDetails.supervisorId} onChange={(e) => handleChange(e)}>
                        <option defaultValue="-1" value="-1"></option>
                        {supervisors.map(sup =>
                            <option key={sup.id} value={sup.id}>{sup.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["supervisorId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supervisorId"]}</span>

                <label className="input-group" htmlFor="supplierId">
                    <span className="label">Supplier/s</span>
                    <select className="select" id="supplierId" name="supplierId" value={supplierId} onChange={(e) => handleSupplierChange(e)} >
                        <option defaultValue="-1"></option>
                        {supplierOptions.map(supplier =>
                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                        )}
                    </select>
                </label>
                <span className={errors["supplierId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierId"]}</span>

                <label className="input-group" htmlFor="suppliers">
                    <span className="label"></span>
                    {jobDetails.suppliers.map(supplier =>
                        <div className="list-close--nowrap" key={supplier.id} >
                            <span className="input">{supplier.name}</span>

                            <a className="makeitred" href="#" onClick={(e) => removeSupplier(e, supplier.id)}>
                                <span className="fas fa-times-circle alignDeleteIcon"></span>
                            </a>
                            <br />
                        </div>
                    )}
                </label>

                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => save(e)}>
                        <span className="fas fa-check-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
                
        </div>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default NewJobMakeSafeMobileData;
import * as React from 'react';
import JobSupplierInvoiceListData from './JobSupplierInvoiceList';
import JobSupplierInvoiceDetailData from './JobSupplierInvoiceDetails';
import axios from 'axios';
import authService from './api-authorization/AuthorizeService';

interface JobSupplierInvoiceProps {
    jobId: number;
    canEdit: boolean;
    canApproveSupplierInvoices: boolean;
}

interface PageChangeData {
    id: number,
    page: string
}

const JobInvoiceData = (props: JobSupplierInvoiceProps) => {
    const [pageData, setPageData] = React.useState<PageChangeData>({ id: 0, page: "List" });

    const pageChanged = (pageChangeData: PageChangeData) => {
        if (pageChangeData.page === "List") {
            setPageData({ id: pageChangeData.id, page: pageChangeData.page });
        } else {
            CheckXeroAuth(pageChangeData);
        }
    }

    const CheckXeroAuth = async (pageChangeData: PageChangeData) => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var pageDetails = {
            page: "costings",
            id: props.jobId,
            subId: user.sub
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(pageDetails));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                //can send invoice
                setPageData({ id: pageChangeData.id, page: pageChangeData.page });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    var contents;
    if (pageData.page === "List") {
        contents = <JobSupplierInvoiceListData jobId={props.jobId} canEdit={props.canEdit} canApproveSupplierInvoices={props.canApproveSupplierInvoices} changePage={pageChanged} />
    } else {
        contents = <JobSupplierInvoiceDetailData JobId={props.jobId} id={pageData.id} changePage={pageChanged} />
    }

    return (
        <div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                {contents}
            </div>
        </div>
    )
}

export default JobInvoiceData;
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/SkylineLogo.jpg';
import authService from './api-authorization/AuthorizeService';

const NavMenu = () => {
    const [loading, setLoading] = React.useState(true);
    const [systemCompanyId, setSystemCompanyId] = React.useState(1);
    const [permissions, setPermissions] = React.useState<string[]>([]);
    const [menu, setMenu] = React.useState(window.innerWidth > 760);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            const token = await authService.getAccessToken();
            const user = await authService.getUser();

            if (user) {
                setLoading(false);
                axios.get('Users/GetUserPermissions?SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    setSystemCompanyId(res.data.systemCompanyId);
                    setPermissions(res.data.permissions);
                    setLoading(false);
                })
                .catch(error => {

                });
            }
        }
        
    }

    const toggleNavbar = () => {
        if (window.innerWidth <= 760) {
            var currentMenu = menu;
            setMenu(!currentMenu);
        }
    }

    const show = (menu) ? "show" : "";

    const renderDetails = (
        <div>
            <div className="top-nav--mobile">
                <nav className="navbar navbar-expand-md navbar-light bg-light">
                    <div className="navbar-brand"><img className="logo logo__small" src={logo}></img></div>
                    <button className="navbar-toggler marginRight10" type="button" onClick={toggleNavbar} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                
                </nav>
            </div>
            <nav className="navbar navbar-expand-md navbar-light bg-light navbar-mobile-margin">
                <div className={"collapse navbar-collapse " + show} id="navbarSupportedContent">
                    <ul className="navbar-nav mt-2 mt-lg-0" onClick={toggleNavbar}>
                        <li className="nav-item">
                            <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/'}>
                                Jobs
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("CreateNewJob") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/newjob'}>
                                New Job
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("RunReports") < 0 ? "nav-link" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/reports'}>
                                Reports
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("CreateEditSuppliers") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/suppliersearch'}>
                                Suppliers
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("ImportSupplierInvoices") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/supplier/invoices'}>
                                Supplier Invoice Import
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("CreateEditClients") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/clientsearch'}>
                                Clients
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("SendInvoicesToXero") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/client/sendinvoices'}>
                                Send Client Invoices
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={permissions.indexOf("CreateEditUsers") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/usersearch'}>
                                Users
                            </NavLink>
                        </li>
                        <li className={systemCompanyId === 2 ? "hidden" : "nav-item"}>
                            <NavLink className={permissions.indexOf("EditAdminLists") < 0 ? "hidden" : (props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/adminlists'}>
                                Admin Lists
                            </NavLink>
                        </li>
                        <li className={systemCompanyId === 2 ? "nav-item" : "hidden"}>
                            <NavLink className={(props) => (props.isActive ? "nav-link active" : "nav-link")} to={'/schedulecalendar'}>
                                Schedule Calendar
                            </NavLink>
                        </li>
                    </ul>
                    
                </div>
            </nav>
        </div>

    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        {contents}
    </div>)
    
}

export default NavMenu;
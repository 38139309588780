import * as React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import ClientAddDetails from './ClientAddDetails';
import authService from './api-authorization/AuthorizeService';

interface AddClientPopupProps {
    newTypeId: number;
    showModal: boolean;
    hideModal(): void;
    saveModal(clientId: number): void;
}

interface GenericListItem {
    id: number;
    name: string;
}

const ClientAddNewPopup = (props: AddClientPopupProps) => { 
    const [types, setTypes] = React.useState<GenericListItem[]>([]);
    const [terms, setTerms] = React.useState<GenericListItem[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        axios.get('Clients/GetDropdowns', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setTypes(res.data.types);
            setTerms(res.data.terms);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const hideModal = () => {
        props.hideModal();
    }

    const savedClient = (clientId: number) => {
        props.saveModal(clientId);
    }
    
    var contents = <ClientAddDetails types={types} terms={terms} backToSearch={savedClient} />

    return (
        <Modal size="lg" show={props.showModal} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Add New Client</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                {contents}
            </Modal.Body>
        </Modal>
    )
}

export default ClientAddNewPopup;
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface ConfirmModalWithNotesProps {
    heading: string;
    text: string;
    notesText: string;
    notes: string;
    saveDisabled: boolean;
    updateNotes(notesText: string): void;
    showConfirmModal: boolean;
    hideConfirmModal(): void;
    noConfirmModal(): void;
    yesConfirmModal(): void;
}

const ConfirmModalWithNoteData = (props: ConfirmModalWithNotesProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    
    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(errors);
        }

        let notes = props.notes;
        notes = e.target.value;
        props.updateNotes(notes);
    }

    const hideConfirmModal = () => {
        setErrors({});
        props.hideConfirmModal();
    }

    const noConfirmModal = () => {
        setErrors({});
        props.noConfirmModal();
    }

    const validate = () => {
        let errors: any = {};
        let formIsValid = true;

        if (!props.notes) {
            formIsValid = false;
            errors["notes"] = "Notes are required";
        }

        setErrors(errors);
        return formIsValid;
    }

    const yesConfirmModal = () => {
        if (validate()) {
            if (props.saveDisabled) {
                return;
            }
            setErrors({});
            props.yesConfirmModal();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    return (
        <Modal size="sm" show={props.showConfirmModal} onHide={hideConfirmModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>{props.heading}</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideConfirmModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>{props.text}</p>
                </div>
                <label className="input-group" htmlFor="notes">
                    <span className="label-large">{props.notesText}</span>
                    <textarea className="input job__noteSize" id="notes" name="notes" value={props.notes} onChange={(e) => handleChange(e)}></textarea>
                </label>
                <span className={errors["notes"] ? "label errors" : "hidden"}>{errors["notes"]}</span>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={noConfirmModal}>No</button>
                <button className="popup-button--yes" onClick={yesConfirmModal} disabled={props.saveDisabled}>Yes</button>
            </Modal.Footer>
        </Modal>

    )
    
}

export default ConfirmModalWithNoteData;
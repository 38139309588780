import * as React from 'react';
import { Modal } from 'react-bootstrap';
import ConfirmModal from './ConfirmModal';

interface PhotoImportSingleProps {
    heading: string;
    text: string;
    fileType: string;
    saveDisabled: boolean;
    selectedFile: File;
    uploadedPhoto: AppFile;
    showModal: boolean;
    hideModal(): void;
    update(selectedFile: File): void;
    delete(): void;
    save(): void;
}

interface AppFile {
    id: number;
    fileType: string;
    content: any;
}

const PhotoImportSinglePopup = (props: PhotoImportSingleProps) => {
    const [showConfirmModal, setShowConfirm] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);

    const handleImportFile = (e: any) => {
        props.update(e.target.files[0]);
    }

    const hideModal = () => {
        props.hideModal();
    }

    const deletePhoto = (e: any) => {
        e.preventDefault();

        setShowConfirm(true);
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setShowConfirm(false);
        setModalSaveDisabled(false);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setShowConfirm(false);
        
        props.delete();
    }

    const save = (e: any) => {
        e.preventDefault();

        props.save();
    }
    
    const showPhoto = props.fileType === "Photo";
    const url = props.uploadedPhoto.id > 0 ? "data:" + props.uploadedPhoto.fileType + ";base64," + props.uploadedPhoto.content : "";
    const selUrl = props.selectedFile.size > 0 ? URL.createObjectURL(props.selectedFile) : "";
    const confirmPopup = <ConfirmModal heading={"Delete " + props.fileType} text={"Are you sure you want to delete this " + props.fileType + "?"} hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />

    return (
        <div>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <Modal show={props.showModal} onHide={hideModal}>
                <Modal.Header className="modal__header--colour">
                    <Modal.Title>{props.heading}</Modal.Title>
                    <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{props.text}</p>
                        <p className={showPhoto ? "makeitred" : "hidden"}>MAX FILE SIZE: 2MB</p>
                    </div>
                    <div className="marginTop10">
                        <input type="file" className="marginBottom10" name="file" accept="image/*, application/pdf" onChange={handleImportFile} />
                    </div>
                    <div className={selUrl === "" ? "hidden" : ""}>
                        <hr></hr>
                        <p>Uploaded {props.fileType}</p>
                        <div>
                            <img className={showPhoto ? "photoImport__single-photo--width" : "hidden"} src={selUrl}></img>
                            <embed className={showPhoto ? "hidden" : "certificate__fileSize"} type="application/pdf" src={selUrl}></embed>
                        </div>
                    </div>
                    
                    <div className={url === "" ? "hidden" : ""}>
                        <hr></hr>
                        <p>Existing {props.fileType}</p>
                        <div className="photoImport__deleteParent--position">
                            <a className="makeitred photoImport__single-delete--position" href="#" onClick={(e) => deletePhoto(e)}>
                                <span className="fas fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                            </a>
                            <img className={showPhoto ? "photoImport__single-photo--width" : "hidden"} src={url}></img>
                            <embed className={showPhoto ? "hidden" : "certificate__fileSize"} type="application/pdf" src={url}></embed>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="popup-button--yes" onClick={save} disabled={props.saveDisabled}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PhotoImportSinglePopup;
import { Modal } from 'react-bootstrap';

interface ConfirmPopupProps {
    heading: string;
    text: string;
    saveDisabled: boolean;
    showConfirmModal: boolean;
    hideConfirmModal(): void;
    noConfirmModal(): void;
    yesConfirmModal(): void;
}

const ConfirmModalData = (props: ConfirmPopupProps) => {

    const hideConfirmModal = () => {
        props.hideConfirmModal();
    }

    const noConfirmModal = () => {
        props.noConfirmModal();
    }

    const yesConfirmModal = () => {
        props.yesConfirmModal();
    }

    return (
        <Modal size="sm" show={props.showConfirmModal} onHide={hideConfirmModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>{props.heading}</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideConfirmModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>{props.text}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={noConfirmModal}>No</button>
                <button className="popup-button--yes" onClick={yesConfirmModal} disabled={props.saveDisabled}>Yes</button>
            </Modal.Footer>
        </Modal>
    );

}

export default ConfirmModalData;
import * as React from 'react';
import ConfirmModal from './ConfirmModal';

interface PhotoImportDetailProps {
    title: string;
    uploadedFiles: UploadedFile[];
    showLoading: boolean;
    update(selectedFiles: UploadedFile[]): void;
    importPhotos(files: File[]): void;
    delete(deleteIndex: number): void;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const PhotoImportDetailPopup = (props: PhotoImportDetailProps) => {
    const [deleteIndex, setDeleteIndex] = React.useState(-1);
    const [showConfirmModal, setShowConfirm] = React.useState(false);
    const [modalSaveDisabled, setSaveDisabled] = React.useState(false);

    const uploadedFiles = props.uploadedFiles;
    

    const handleCellChange = (e: any, index: number) => {
        
        var files = [...props.uploadedFiles];
        var file = files[index];
        file.comment = e.target.value;

        props.update(files);
    }

    const handleImportFile = (e: any) => {
        props.importPhotos(e.target.files);
    }

    //DELETE
    const deletePhoto = (e: any, itemIndex: number) => {
        e.preventDefault();
        setDeleteIndex(itemIndex);
        setShowConfirm(true);
        setSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setDeleteIndex(-1);
        setShowConfirm(false);
        setSaveDisabled(false);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }

        props.delete(deleteIndex);
        setDeleteIndex(-1);
        setShowConfirm(false);
        setSaveDisabled(true);
    }
    //END DELETE

    //MOVE PHOTOS
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var photos = [...props.uploadedFiles];

        photos.splice(newIndex, 0, photos.splice(oldIndex, 1)[0]);

        //loop through the photos and update the order  
        for (var i = 0; i < photos.length; i++) {
            photos[i].file.photoOrder = (i + 1);
        }

        props.update(photos);
    };
    //END MOVE PHOTOS

    const confirmPopup = <ConfirmModal heading="Delete Photo" text="Are you sure you want to delete this photo?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />

    return (
        <div>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div>
                <p>{props.title}</p>
            </div>
            <div className={props.showLoading ? "hidden" : "marginTop10"}>
                <input type="file" className="marginBottom10" name="file" accept="image/*" onChange={handleImportFile} multiple />
            </div>

            <div className={props.uploadedFiles.length === 0 || props.showLoading ? "hidden" : ""}>
                <hr></hr>

                {uploadedFiles.map((uploadedFile, index) =>
                    <div key={index} className="input-group-parent" draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, index)}>
                        <div className={index % 2 == 0 ? "photos-multiple--odd file__photo--border" : "photos-multiple--even file__photo--border"}>
                            <div className="photoImport__deleteParent--position">
                                <a className="makeitred photoImport__delete--position" href="#" onClick={(e) => deletePhoto(e, index)}>
                                    <span className="fas fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                                </a>
                                <img className="photoImport--size" src={uploadedFile.file.id > 0 ? "data:" + uploadedFile.file.fileType + ";base64," + uploadedFile.file.content : uploadedFile.file.content}></img>
                            </div>
                            <div>
                                <input className='input photoImport--comment-size' type='text' maxLength={200} id={"comment" + index} name="comment" value={uploadedFile.comment} onChange={(e) => handleCellChange(e, index)} ></input>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className={props.showLoading ? "marginTop10" : "hidden"}>
                <span>Loading Photos...</span>
            </div>
        </div>
    )
}

export default PhotoImportDetailPopup;
import { Modal } from 'react-bootstrap';
import PhotoImportDetailPopup from './PhotoImportDetail';

interface ReportJobPhotosProps {
    reportComments: string;
    title: string;
    saveDisabled: boolean;
    loadingPhotos: boolean;
    uploadedFiles: UploadedFile[];
    showModal: boolean;
    hideModal(): void;
    update(selectedFiles: UploadedFile[]): void;
    importPhotos(files: File[]): void;
    delete(deleteIndex: number): void;
    save(): void;
    updateSaveDisabled(): void;
    updateReportComments(reportComments: string): void;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const ReportJobPhotosPopup = (props: ReportJobPhotosProps) => {
    const handleChange = (e: any) => {
        props.updateReportComments(e.target.value)
    }

    const hideModal = () => {
        props.hideModal();
    }

    const save = (e: any) => {
        e.preventDefault();

        props.save();
    }

    const photoImportDetail = <PhotoImportDetailPopup title={props.title} showLoading={props.loadingPhotos} uploadedFiles={props.uploadedFiles} importPhotos={props.importPhotos} update={props.update} delete={props.delete} />
    const contents = props.loadingPhotos ? "Loading Photos..." : photoImportDetail;

    return (
        <div>
            <Modal size="lg" show={props.showModal} onHide={hideModal}>
                <Modal.Header className="modal__header--colour">
                    <Modal.Title>Upload Photo</Modal.Title>
                    <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label className="input-group" htmlFor="reportComments">
                                <span className="label">Report Comments</span>
                                <textarea className="photoReport-comment--size" id="reportComments" name="reportComments" value={props.reportComments} onChange={(e) => handleChange(e)}></textarea>
                            </label>
                        </div>
                        {contents}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="popup-button--yes" onClick={save} disabled={props.saveDisabled || props.loadingPhotos}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default ReportJobPhotosPopup;
import * as React from 'react';
import { toast } from 'react-toastify';
import * as DateHelpers from './DateHelpers';

interface JobPurchaseOrderAddMobileProps {
    suppliers: SupplierOption[];
    supplierContacts: GenericListItem[];
    purchaseOrder: PurchaseOrder;
    checkSupplierCertificate: boolean;
    getSupplierContacts(supplierId: number): void;
    next(): void;
    update(purchaseOrder: PurchaseOrder): void;
    cancel(): void;
}

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplierId: number;
    supplier: string;
    supplierContactId: number;
    supplierContact: string;
    certificateExpiryDate: string;
    validCertificate: boolean;
    certificateExpiryText: string;
    registeredForGst: boolean;
    dateRequired: string;
    dateRequiredFormatted: string;
    includeFullScope: boolean;
    instructions: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    statusId: number;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface SupplierOption {
    id: number;
    name: string;
    registeredForGst: boolean;
    validCertificate: boolean;
    certificateExpiryText: string;
    certificateExpiryDate: string;
}

const JobPurchaseOrderAddMobileData = (props: JobPurchaseOrderAddMobileProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const cancel = (e: any) => {
        e.preventDefault();
        props.cancel();
    }

    const next = (e: any) => {
        e.preventDefault();
        if (validate()) {
            props.next();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const getSupplierContacts = (supplierId: number) => {
        props.getSupplierContacts(supplierId);
    }

    //change events
    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        let newPurchaseOrder: any = {};
        newPurchaseOrder = props.purchaseOrder;
        newPurchaseOrder[e.target.name] = e.target.value;
        if (e.target.name === "supplierContactId") {
            let supplierContactId = e.target.value * 1;
            let supplierContact = props.supplierContacts.find(sc => sc.id == supplierContactId);
            if (supplierContact) {
                newPurchaseOrder.supplierContact = supplierContact.name;
            }
        } else if (e.target.name === "dateRequired") {
            if (DateHelpers.isValidDate(e.target.value)) {
                newPurchaseOrder.dateRequiredFormatted = DateHelpers.formatDate(e.target.value);
            }
        }

        
        props.update(newPurchaseOrder);
    }

    const handleSupplierChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }
        let po = props.purchaseOrder;
        let supplierId = e.target.value * 1;
        po.supplierId = supplierId;

        let registeredForGst = true;
        po.certificateExpiryText = "";
        let supplier = props.suppliers.find(s => s.id == supplierId);
        if (supplier) {
            po.supplier = supplier.name;
            registeredForGst = supplier.registeredForGst;
            po.certificateExpiryText = supplier.certificateExpiryText;
            po.validCertificate = supplier.validCertificate;
            po.certificateExpiryDate = supplier.certificateExpiryDate;
        }
        po.registeredForGst = registeredForGst;

        props.update(po);

        //get list of contacts
        getSupplierContacts(e.target.value);
    }

    const handleCheckboxChange = (e: any) => {
        let po = props.purchaseOrder;

        let change: any = {};
        change = po;
        change[e.target.name] = e.target.checked;
        props.update(po);
    }
    //end change events

    const validate = () => {
        let fields = props.purchaseOrder;
        let error: any = {};
        let formIsValid = true;

        if (fields.supplierId < 0) {
            formIsValid = false;
            error["supplierId"] = "Supplier is required";
        }

        if (fields.supplierContactId < 0) {
            formIsValid = false;
            error["supplierContactId"] = "Attention is required";
        }

        if (props.checkSupplierCertificate && !fields.validCertificate) {
            formIsValid = false;
            error["supplierId"] = "A valid certificate is required";
        }

        if (fields.dateRequired && !DateHelpers.isValidDate(fields.dateRequired)) {
            formIsValid = false;
            error["dateRequired"] = "Valid Required Date is required";
        }

        setErrors(error);
        return formIsValid;
    }

    let headingText = props.purchaseOrder.id === 0 ? "Add Purchase Order" : "Edit Purchase Order";

    return (
        <div className="marginBottom50">
            <div className="makeitflexspacebetween">
                <h4 className="mobile-margin">{headingText}</h4>
                <div className="jobpo__backButton">
                    <a className="mobile-icon-red" href="#" onClick={(e) => cancel(e)}>
                        <span className="fas fa-times-circle mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
            <div>
                <label className="input-group" htmlFor="supplierId">
                    <span className="label label-mobile">Supplier</span>
                    <select className="select" id="supplierId" name="supplierId" value={props.purchaseOrder.supplierId} onChange={(e) => handleSupplierChange(e)} >
                        <option defaultValue="-1" value="-1"></option>
                        {props.suppliers.map(supplier =>
                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                        )};
                    </select>
                </label>
                <div className={props.purchaseOrder.validCertificate ? "" : "makeitred"}>{props.purchaseOrder.certificateExpiryText}</div>
                <span className={errors["supplierId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierId"]}</span>

                <label className="input-group" htmlFor="supplierContactId">
                    <span className="label label-mobile">Attention</span>
                    <select className="select" id="supplierContactId" name="supplierContactId" value={props.purchaseOrder.supplierContactId} onChange={(e) => handleChange(e)} >
                        <option defaultValue="-1" value="-1"></option>
                        {props.supplierContacts.map(contact =>
                            <option key={contact.id} value={contact.id}>{contact.name}</option>
                        )};
                    </select>
                </label>
                <span className={errors["supplierContactId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["supplierContactId"]}</span>

                <label className="input-group" htmlFor="dateRequired">
                    <span className="label label-mobile">Date Required</span>
                    <input type='date' id="dateRequired" name="dateRequired" className='input' value={props.purchaseOrder.dateRequired} onChange={(e) => handleChange(e)} ></input>
                </label>
                <span className={errors["dateRequired"] ? "label errors errors__leftmargin" : "hidden"}>{errors["dateRequired"]}</span>

                <label className="input-group jobpo__textarea" htmlFor="instructions">
                    <span className="label label-mobile">Instructions</span>
                    <textarea className="input jobpo__textHeight" id="instructions" name="instructions" value={props.purchaseOrder.instructions} onChange={(e) => handleChange(e)}></textarea>
                </label>
                <label className="input-group" htmlFor="includeFullScope">
                    <span className="label label-mobile--inline label-mobile">Include Full Scope</span>
                    <input className="checkbox" type="checkbox" id="includeFullScope" name="includeFullScope" checked={props.purchaseOrder.includeFullScope} onChange={(e) => handleCheckboxChange(e)} ></input>
                </label>

            </div>
            <div className="bottom-edit-nav">
                <div className="jobpo__backButton">
                    <a className="mobile-icon-green" href="#" onClick={(e) => next(e)}>
                        <span className="fas fa-arrow-alt-circle-right mobileMenuSize alignIconCenter"></span>
                    </a>
                </div>
            </div>
        </div>
    );

}
export default JobPurchaseOrderAddMobileData;
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import JobTabs from './JobTabs';
import Pager from './Paging';
import authService from './api-authorization/AuthorizeService';

interface NoteDetails {
    pageSize: number;
    totalRecords: number;
    jobNotes: NoteList[];
}

interface NoteList {
    id: number,
    noteType: string,
    insertedDateFormatted: string,
    note: string,
    addedBy: string,
    systemNote: boolean,
    clientUpdate: boolean
}

interface GenericListItem {
    id: number;
    name: string;
}

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

const JobNotesData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [noteTypes, setNoteTypes] = React.useState<GenericListItem[]>([]);
    const [noteDetails, setNoteDetails] = React.useState<NoteDetails>({ pageSize: 1, totalRecords: 0, jobNotes: [] });
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [showAddNote, setShowAddNote] = React.useState(false);
    const [addNote, setAddNote] = React.useState({ noteTypeId: -1, newNotes: "", newNoteInsurer: false });
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [showSystemNotes, setShowSystemNotes] = React.useState(false);
    const [showClientUpdates, setShowClientUpdates] = React.useState(false);
    const [noteTypeFilter, setNoteTypeFilter] = React.useState("");

    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async(Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Jobs/GetNoteDropdowns?JobId=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                setPermissions(res.data.userJobPermissions);
                setNoteTypes(res.data.noteTypes);
                getNotes(Id, 1, true, noteTypeFilter, showSystemNotes, showClientUpdates);
            }
        });
    }

    const getNotes = async (jobId: number, page: number, getTotalRecords: boolean, filter: string, systemNotes: boolean, clientUpdates: boolean) => {
        const token = await authService.getAccessToken();
        //get list of notes from database
        var totalRecords = getTotalRecords ? 0 : noteDetails.totalRecords;
        var noteType = filter;
        axios.get('Jobs/GetNotes?jobId=' + jobId + '&page=' + page + '&totalRecords=' + totalRecords + '&getTotalRecords=' + getTotalRecords + '&showSystemNotes=' + systemNotes + '&showClientUpdates=' + clientUpdates + '&noteType=' + noteType, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setPage(page);
            setNoteDetails(res.data);
            setLoading(false);
        })
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        setSaveDisabled(false);
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setAddNote(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleInsurerCheckboxChange = (e: any) => {
        setSaveDisabled(false);
        setAddNote(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
    }

    const handleCheckboxFilterChange = (e: any) => {
        setSaveDisabled(false);

        var showClientUpd = showClientUpdates;
        var showSysNotes = showSystemNotes;
        if (e.target.name === "showClientUpdates") {
            showClientUpd = e.target.checked;
            setShowClientUpdates(e.target.checked);
        } else if (e.target.name == "showSystemNotes") {
            showSysNotes = e.target.checked;
            setShowSystemNotes(e.target.checked);
        }

        getNotes(jobData.jobId, 1, true, noteTypeFilter, showSysNotes, showClientUpd);
    }

    const handleTypeChange = (e: any) => {
        setNoteTypeFilter(e.target.value);
        setSaveDisabled(false);

        getNotes(jobData.jobId, 1, true, e.target.value, showSystemNotes, showClientUpdates);
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    const pageChanged = (pageNumber: number) => {
        setPage(pageNumber);
        getNotes(jobData.jobId, pageNumber, false, noteTypeFilter, showSystemNotes, showClientUpdates);
    }

    const updateShowAddNote = () => {
        var showNote = !showAddNote;
        setShowAddNote(showNote);
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (addNote.noteTypeId <= 0) {
            formIsValid = false;
            error["noteTypeId"] = "Note Type is required";
        }

        if (!addNote.newNotes || addNote.newNotes.length < 5) {
            formIsValid = false;
            error["newNotes"] = "Notes > 5 characters are required";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();
        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveJobNote();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveJobNote = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let jobNotes = {
            jobId: jobData.jobId,
            noteTypeId: addNote.noteTypeId,
            note: addNote.newNotes,
            insurerUpdate: addNote.newNoteInsurer,
            subId: user.sub
        }

        axios.post('Jobs/SaveJobNote', jobNotes, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            toast.success("Note Saved");
            //then need to clear notes so can resave and add note to list
            var newNote = {
                id: res.data.id,
                noteType: res.data.noteType,
                insertedDateFormatted: res.data.insertedDateFormatted,
                note: res.data.note,
                addedBy: res.data.addedBy,
                systemNote: res.data.systemNote,
                clientUpdate: res.data.clientUpdate
            };
            if (page === 1) {
                //add note to top of list
                noteDetails.jobNotes.unshift(newNote);
                if (noteDetails.jobNotes.length > (noteDetails.pageSize)) {
                    //remove the oldest note as goes onto 2nd page
                    noteDetails.jobNotes.pop();
                }
                setNoteDetails(noteDetails);
            }
            setAddNote({ noteTypeId: -1, newNotes: "", newNoteInsurer: false });
            setShowAddNote(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="notes" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />
    let filteredNoteTypes = noteTypes.filter(type =>
        type.id > 0
    )

    var pagerContents;
    if ((noteDetails.totalRecords / noteDetails.pageSize) > 1) {
        //only render if we have records
        pagerContents = <Pager page={page} pageSize={noteDetails.pageSize} totalRecords={noteDetails.totalRecords} pageChanged={pageChanged} />
    }

    const renderDetails = (
        <form>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <div className={showAddNote ? "hidden" : ""}>
                    <button className="defaultbutton defaultbutton__small" onClick={updateShowAddNote} type="button">Add Note</button>
                </div>
                <div className={showAddNote ? "" : "hidden"}>
                    <label className="input-group" htmlFor="noteType">
                        <span className="label">Type </span>
                        <select className="select" id="noteType" name="noteTypeId" value={addNote.noteTypeId} onChange={(e) => handleChange(e)}>
                            <option hidden defaultValue="-1"></option>
                            {filteredNoteTypes.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )};
                        </select>
                    </label>
                    <span className={errors["noteTypeId"] ? "label errors errors__leftmargin" : "hidden"}>{errors["noteTypeId"]}</span>

                    <label className="input-group" htmlFor="newNotes">
                        <span className="label">Notes</span>
                        <textarea className="input job__noteSize" id="newNotes" name="newNotes" value={addNote.newNotes} onChange={(e) => handleChange(e)}></textarea>
                    </label>
                    <span className={errors["newNotes"] ? "label errors errors__leftmargin" : "hidden"}>{errors["newNotes"]}</span>

                    <label className="input-group" htmlFor="newNoteInsurer">
                        <span className="label">Insurer Update?</span>
                        <input className="checkbox" type="checkbox" id="newNoteInsurer" name="newNoteInsurer" checked={addNote.newNoteInsurer} onChange={(e) => handleInsurerCheckboxChange(e)}></input>
                    </label>

                    <div>
                        <button className="defaultbutton-prev defaultbutton__small notes__button-position" onClick={save} type="button" disabled={saveDisabled}>Add</button>
                        <button className="defaultbutton-next defaultbutton__small" onClick={updateShowAddNote} type="button">Cancel</button>
                    </div>
                </div>
                <div>
                    <hr></hr>
                </div>
                <div className="makeitflexspacebetween">
                    <label className="input-group marginRight10" htmlFor="noteTypeFilter">
                        <span className="label">Type </span>
                        <select className="select" id="noteTypeFilter" name="noteTypeFilter" value={noteTypeFilter} onChange={(e) => handleTypeChange(e)}>
                            <option defaultValue="-1"></option>
                            {filteredNoteTypes.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )};
                        </select>
                    </label>
                    <label className="input-group job__checkboxPosition" htmlFor="showSystemNotes">
                        <span className="label">Show System Notes </span>
                        <div>
                            <input type="checkbox" name="showSystemNotes" id="showSystemNotes" checked={showSystemNotes} onChange={(e) => handleCheckboxFilterChange(e)}></input>
                        </div>
                    </label>
                    <label className="input-group job__checkboxPosition" htmlFor="showClientUpdates">
                        <span className="label">Show Client Updates </span>
                        <div>
                            <input type="checkbox" name="showClientUpdates" id="showClientUpdates" checked={showClientUpdates} onChange={(e) => handleCheckboxFilterChange(e)}></input>
                        </div>
                    </label>
                </div>
                <div className="job__noteGridSize overflowAuto">
                    <table className="table--main table__small tableColours mobileTableFont">
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Type</th>
                                <th className="notes__check--width">Client Update</th>
                                <th>Notes</th>
                                <th>Added By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {noteDetails.jobNotes.map((note, index) =>
                                <tr key={note.id}>
                                    <td>{note.insertedDateFormatted}</td>
                                    <td>{note.noteType}</td>
                                    <td className="textaligncenter"><input className="checkbox" type="checkbox" checked={note.clientUpdate} disabled></input></td>
                                    <td>{note.note}</td>
                                    <td>{note.addedBy}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {pagerContents}
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;
}

export default JobNotesData;
import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';
import { useNavigate } from 'react-router-dom';

interface PricelistItem {
    id: number;
    invoiceTypeId: number;
    clientId: number;
    subTotal: string;
    gst: string;
    total: string;
    marginPercent: string;
    isDirty: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

const AdminPricelistReportData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [clients, setClients] = React.useState<GenericListItem[]>([]);
    const [reportOptions, setReportOptions] = React.useState<GenericListItem[]>([]);
    const [pricelistReports, setPricelistReports] = React.useState<PricelistItem[]>([]);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [pricelistIndex, setPricelistIndex] = React.useState(-1);
    const [gstPercentage, setGstPercentage] = React.useState(0.1);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=EditAdminLists', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                getPricelist(true);
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const getPricelist = async (getDropdowns: boolean) => {
        const token = await authService.getAccessToken();

        //get list of shortcuts and dropdowns
        axios.get('Admin/GetPricelistReportsList?GetDropdowns=' + getDropdowns, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var pricelistReports = res.data.pricelistReports;
            var blankRow = addBlankRow();
            pricelistReports.push(blankRow);

            if (getDropdowns) {
                var clients = res.data.clients;
                var reportOptions = res.data.reportOptions;
                var gstPercentage = res.data.gstPercentage;

                setClients(clients);
                setReportOptions(reportOptions);
                setGstPercentage(gstPercentage);
            }

            setPricelistReports(pricelistReports);
            setLoading(false);
        });
    }

    const addBlankRow = () => {
        var newItem: PricelistItem = {
            id: 0,
            invoiceTypeId: -1,
            clientId: -1,
            subTotal: "0",
            gst: "0",
            total: "0",
            marginPercent: "0",
            isDirty: false
        };

        return newItem;
    }

    //grid item changed
    const handleCellChange = (index: number) => (e: any) => {
        //update item that has changed
        var items = [...pricelistReports];
        var item = items[index];
        let exist: any = {};
        exist = item;
        exist[e.target.name] = e.target.value;
        exist["isDirty"] = true;

        let lastRow = items.length - 1;
        if (index == lastRow) {
            //add new item
            let blankRow = addBlankRow();
            items.push(blankRow);
        }

        setPricelistReports(items);
    }

    const handleCellAmountChange = (index: number) => (e: any) => {
        //update item that has changed
        var items = [...pricelistReports];
        var item = items[index];

        var amountChanged = e.target.value === "" ? e.target.value : e.target.value * 1;    //if decimal point entered first * 1 causes it to lose the .

        let exist: any = {};
        exist = item;
        exist[e.target.name] = amountChanged;
        exist.isDirty = true;

        let existSubTotal = parseFloat(item.subTotal);
        let existTotal = parseFloat(item.total);

        //calculate unit cost / total
        if (e.target.name === "subTotal") {
            //subtotal
            let gst = amountChanged * gstPercentage;
            exist["gst"] = gst.toFixed(2);
            let total = amountChanged + gst;
            exist["total"] = total.toFixed(2);
        } else if (e.target.name === "gst" && existSubTotal > 0) {
            //gst - calc total
            let total = existSubTotal + amountChanged;
            exist["total"] = total.toFixed(2);
        } else if (e.target.name === "gst" && existSubTotal === 0 && existTotal > 0) {
            //gst - calc subtotal
            let subTotal = (existTotal * 1) - amountChanged;
            exist["subTotal"] = subTotal.toFixed(2);
        } else if (e.target.name === "total") {
            //total - get gst and subtotal
            let gstPercent = gstPercentage * 100;
            let gst = amountChanged * gstPercent / (100 + gstPercent)
            exist["gst"] = gst.toFixed(2);
            let subTotal = amountChanged - gst;
            exist["subTotal"] = subTotal.toFixed(2);
        }

        let lastRow = items.length - 1;
        if (index == lastRow) {
            //add new item
            let blankRow = addBlankRow();
            items.push(blankRow);
        }

        setPricelistReports(items);
    }

    const cellAmountLeave = (index: number) => (e: any) => {
        var items = [...pricelistReports];
        var item = items[index];

        item.subTotal = parseFloat(item.subTotal).toFixed(2);
        item.gst = parseFloat(item.gst).toFixed(2);
        item.total = parseFloat(item.total).toFixed(2);
        item.marginPercent = parseFloat(item.marginPercent).toFixed(2);

        setPricelistReports(items);
    }

    //DELETE
    const savePricelistItems = async (pricelistItems: PricelistItem[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let savePricelist = {
            pricelistItems: pricelistItems,
            subId: user.sub
        };

        axios.post('Admin/SavePricelitsReports', savePricelist, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Pricelist has been saved");
                getPricelist(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deletePricelistItem = (index: number) => (e: any) => {
        e.preventDefault();

        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setPricelistIndex(index);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setPricelistIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        saveDeletePricelistItem();
    }

    const saveDeletePricelistItem = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var pricelistItems = pricelistReports;
        var pricelistItem = pricelistItems[pricelistIndex];
        var deletePricelistItem = {
            id: pricelistItem.id,
            subId: user.sub
        };

        axios.post('Admin/DeletePricelistReportItem', deletePricelistItem, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Pricelist Item has been deleted");
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setPricelistIndex(-1);

                getPricelist(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //SAVE
    const validate = () => {
        let formIsValid = true;
        let errors: any = {};
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        var pricelistItems = pricelistReports;
        for (var i = 0; i < pricelistItems.length - 1; i++) {
            rowNumber += 1;
            var newErrorString = "";

            if (pricelistItems[i].invoiceTypeId < 0) {
                newErrorString += " Report Type, ";
                error = true;
            }

            if (parseFloat(pricelistItems[i].subTotal) <= 0) {
                newErrorString += " Sub Total, ";
                error = true;
            }

            if (parseFloat(pricelistItems[i].gst) <= 0) {
                newErrorString += " Gst, ";
                error = true;
            }

            if (parseFloat(pricelistItems[i].total) <= 0) {
                newErrorString += " Total, ";
                error = true;
            }

            if (newErrorString != "") {
                errorString += "\n"
                errorString += "Row " + rowNumber + ": " + newErrorString;
                errorString = errorString.substring(0, errorString.length - 2);
            }
        }

        if (error) {
            formIsValid = false;
            errors["pricelists"] = errorString;
        }

        setErrors(errors);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            var pricelists = pricelistReports.filter(c => c.isDirty);

            if (pricelists.length == 0) {
                toast.info("No pricelist items have been changed!");
            } else {
                savePricelistItems(pricelists);
            }
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const confirmPopup = <ConfirmModal heading={"Delete Pricelist Report Item"} text="Are you sure you want to delete this pricelist item?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    const lastRow = pricelistReports.length - 1;

    const renderDetails = (
        <form>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div className="overflowAuto">
                <table className="table--main tableColours">
                    <thead>
                        <tr>
                            <th className="table--smallHeaderSize"></th>
                            <th className="hidden">Id</th>
                            <th className="table--smallHeaderSize">Report</th>
                            <th>Client</th>
                            <th>Sub-Total</th>
                            <th>GST</th>
                            <th>Total</th>
                            <th>Margin</th>
                            <th className="table--smallHeaderSize table__center-text--align">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pricelistReports.map((pricelist, index) =>
                            <tr key={index}>
                                <td className="table__text--align">{index + 1}</td>
                                <td className="hidden">{pricelist.id}</td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="invoiceTypeId" value={pricelist.invoiceTypeId} onChange={handleCellChange(index)} disabled={pricelist.id > 0 && pricelist.clientId < 0}>
                                        <option defaultValue="-1"></option>
                                        {reportOptions.map(item =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <select className="select table__select--size" name="clientId" value={pricelist.clientId} onChange={handleCellChange(index)} disabled={pricelist.id > 0 && pricelist.clientId < 0}>
                                        <option defaultValue="-1"></option>
                                        {clients.map(client =>
                                            <option key={client.id} value={client.id}>{client.name}</option>
                                        )};
                                    </select>
                                </td>
                                <td className="table__text--align">
                                    <input className="input textalignright" type="number" name="subTotal" value={pricelist.subTotal} onChange={handleCellAmountChange(index)} onBlur={cellAmountLeave(index)}></input>
                                </td>
                                <td className="table__text--align">
                                    <input className="input textalignright" type="number" name="gst" value={pricelist.gst} onChange={handleCellAmountChange(index)}></input>
                                </td>
                                <td className="table__text--align">
                                    <input className="input textalignright" type="number" name="total" value={pricelist.total} onChange={handleCellAmountChange(index)}></input>
                                </td>
                                <td className="table__text--align">
                                    <input className="input textalignright" type="number" name="marginPercent" value={pricelist.marginPercent} onChange={handleCellAmountChange(index)}></input>
                                </td>
                                <td className="table__text--align">
                                    <div className={(lastRow == index) || (pricelist.id > 0 && pricelist.clientId < 0) ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={deletePricelistItem(index)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["pricelists"] ?
                    (errors["pricelists"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <button className="defaultbutton marginBottom10" type="button" onClick={save}>Save</button>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        <h1>Add/Edit Pricelists for Reports</h1>
        {contents}
    </div>)

}

export default AdminPricelistReportData;

import * as React from 'react';
import { Modal } from 'react-bootstrap';
import authService from './api-authorization/AuthorizeService';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as DateHelpers from './DateHelpers';

interface JobPurchaseOrderInvoiceReceivedProps {
    jobId: number;
    poId: number;
    poRemaining: number;
    poRemainingFormatted: string;
    show: boolean;
    hide(): void;
}

const JobPurchaseOrderInvoiceReceivedPopup = (props: JobPurchaseOrderInvoiceReceivedProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    let currentDate = DateHelpers.getCurrentDate();
    const [invRecData, setInvRecData] = React.useState({ invoiceDate: currentDate, invoiceNumber: "", invoiceAmount: 0 });
    const [selectedFile, setSelectedFile] = React.useState<File>(new File([""], "filename"));

    const hideModal = () => {
        setErrors({});
        props.hide();
    }

    const handleChange = (e: any) => {
        //check if there is a value and an error, and if so remove from error list
        if (e.target.value) {
            var error = errors;
            delete error[e.target.name];
            setErrors(error);
        }

        setInvRecData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleImportFile = (e: any) => {
        setSelectedFile(e.target.files[0]);
    }

    const validate = () => {
        let error: any = {};
        let formIsValid = true;

        if (!invRecData.invoiceDate) {
            formIsValid = false;
            error["invoiceDate"] = "Invoice Date is required";
        }

        if (!invRecData.invoiceNumber) {
            formIsValid = false;
            error["invoiceNumber"] = "Invoice Number is required";
        }

        if (invRecData.invoiceAmount <= 0) {
            formIsValid = false;
            error["invoiceAmount"] = "Invoice Amount is required";
        } else if (invRecData.invoiceAmount > props.poRemaining) {
            formIsValid = false;
            error["invoiceAmount"] = "Invoice Amount must be <= Purchase Order Remaining Amount";
        }

        setErrors(error);
        return formIsValid;
    }

    const save = () => {
        if (validate()) {
            saveInvoice();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveInvoice = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        const data = new FormData()
        data.append('invoice', selectedFile);
        data.append('jobId', props.jobId.toString());
        data.append('purchaseOrderId', props.poId.toString());
        data.append('invoiceNumber', invRecData.invoiceNumber);
        data.append('invoiceDate', invRecData.invoiceDate);
        data.append('invoiceAmount', invRecData.invoiceAmount.toString());
        data.append('subId', user.sub);

        //send invoice to Xero
        axios.post('Suppliers/SupplierInvoiceUploadedExternally', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });

            } else {
                toast.success("Invoice Created");
                hideModal();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <Modal show={props.show} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Received Supplier Invoice</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span>Supplier Invoice has been received and uploaded to Xero via an External System (Dext)</span>
                    <label className="input-group" htmlFor="invoiceDate">
                        <span className="label">Invoice Date</span>
                        <input type='date' id="invoiceDate" name="invoiceDate" className='input' value={invRecData.invoiceDate} onChange={(e) => handleChange(e)} ></input>
                    </label>
                    <span className={errors["invoiceDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["invoiceDate"]}</span>

                    <label className="input-group" htmlFor="invoiceNumber">
                        <span className="label">Invoice Number / Reference</span>
                        <input className='input' type='text' maxLength={100} id="invoiceNumber" name="invoiceNumber" value={invRecData.invoiceNumber} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["invoiceNumber"] ? "label errors errors__leftmargin" : "hidden"}>{errors["invoiceNumber"]}</span>

                    <label className="input-group" htmlFor="invoiceAmount">
                        <span className="label">Invoice Amount</span>
                        <input className='input textalignright' type='number' id="invoiceAmount" name="invoiceAmount" value={invRecData.invoiceAmount} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["invoiceAmount"] ? "label errors errors__leftmargin" : "hidden"}>{errors["invoiceAmount"]}</span>

                    <label className="input-group" htmlFor="poRemainingFormatted">
                        <span className="label">PO Remaining</span>
                        <input className='input textalignright' type='text' id="poRemainingFormatted" name="poRemainingFormatted" value={props.poRemainingFormatted} disabled></input>
                    </label>

                    <div className="marginTop10">
                        <label className="input-group" htmlFor="files">
                            <span className="label">Select supplier invoice</span>
                            <input type="file" className="marginBottom10" id="files" name="files" accept="application/pdf" onChange={handleImportFile} />
                        </label>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--no" onClick={hideModal}>Cancel</button>
                <button className="popup-button--yes" onClick={save}>Save</button>
            </Modal.Footer>
        </Modal>

    )

}

export default JobPurchaseOrderInvoiceReceivedPopup;
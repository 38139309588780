import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import JobTabs from './JobTabs';
import authService from './api-authorization/AuthorizeService';
import * as DateHelpers from './DateHelpers';
import ConfirmModal from './ConfirmModal';
import JobOnGoingHireExtendPopupData from './JobOnGoingHireExtend';

interface OnGoingHireProduct {
    id: number;
    productId: number;
    product: string;
    subTotal: string;
    gst: string;
    total: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    startDate: string;
    startDateFormatted: string;
    endDate: string;
    endDateFormatted: string;
    days: number;
    complete: boolean;
    isDirty: boolean;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

const JobOnGoingHireData = () => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [productList, setProductList] = React.useState<GenericListItem[]>([]);
    const [onGoingHireProducts, setOnGoingHireProducts] = React.useState<OnGoingHireProduct[]>([]);
    const [gstPercentage, setGstPercentage] = React.useState(0.1);
    const [canEdit, setCanEdit] = React.useState(false);
    const [deleteOptions, setDeleteOptions] = React.useState({ productIndex: -1, showConfirmModal: false });
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [extendOptions, setExtendOptions] = React.useState({ extendProductIndex: -1, showExtendModal: false });
    const [extendProduct, setExtendProduct] = React.useState<OnGoingHireProduct>({
        id: 0,
        productId: 0,
        product: "",
        subTotal: "0.00",
        gst: "0.00",
        total: "0.00",
        subTotalFormatted: "$0.00",
        gstFormatted: "$0.00",
        totalFormatted: "$0.00",
        startDate: "",
        startDateFormatted: "",
        endDate: "",
        endDateFormatted: "",
        days: 0,
        complete: false,
        isDirty: false
    });

    React.useEffect(() => {
        let Id = Number(id);
        getData(Id);
    }, []);

    const getData = async (Id: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        
        axios.get('Jobs/GetOnGoingHireData?JobId=' + Id + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                var onGoingProducts = res.data.onGoingHireProducts;
                if (res.data.canEdit) {
                    var newRow = addBlankRow();
                    onGoingProducts.push(newRow);
                }

                setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly });
                setGstPercentage(res.data.gstPercentage);
                setProductList(res.data.productList);
                setOnGoingHireProducts(onGoingProducts);
                setCanEdit(res.data.canEdit);
                setPermissions(res.data.userJobPermissions);
                setLoading(false);
            }
        });
    }

    const getOnGoingHireProducts = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        setLoading(true);

        axios.get('Jobs/GetOnGoingHireProducts?JobId=' + jobData.jobId + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var onGoingProducts = res.data;
            var newRow = addBlankRow();
            onGoingProducts.push(newRow);
            setOnGoingHireProducts(onGoingProducts);
            setLoading(false);
        });
    }

    const handleCellChange = (index: number, e: any) => {
        var onGoingProducts = [...onGoingHireProducts];
        var onGoingProduct = onGoingProducts[index];
        var value = e.target.value;
        if (e.target.name === "productId") {
            //need productid to be a number
            value = value * 1;
        }

        let exist: any = {};
        exist = onGoingProduct;
        exist[e.target.name] = value;
        exist.isDirty = true;

        //calculate days
        if (e.target.name === "startDate" && onGoingProduct.endDate && DateHelpers.isValidDate(e.target.value)) {  
            onGoingProduct.days = (DateHelpers.daysBetweenDates(e.target.value, onGoingProduct.endDate));
        } else if (e.target.name === "endDate" && onGoingProduct.startDate && DateHelpers.isValidDate(e.target.value)) {
            onGoingProduct.days = (DateHelpers.daysBetweenDates(onGoingProduct.startDate, e.target.value));
        }

        if (index === (onGoingProducts.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow();
            onGoingProducts.push(newRow);
        }
        setOnGoingHireProducts(onGoingProducts);
        setSaveDisabled(false);
        setErrors({});
    }

    const handleCellAmountChange = (index: number, e: any) => {
        //update item that has changed
        var onGoingProducts = [...onGoingHireProducts];
        var onGoingProduct = onGoingProducts[index];

        var amountChanged = e.target.value === "" ? e.target.value : e.target.value * 1;    //if decimal point entered first * 1 causes it to lose the .

        let exist: any = {};
        exist = onGoingProduct;
        exist[e.target.name] = amountChanged;
        exist.isDirty = true;

        let existSubTotal = parseFloat(onGoingProduct.subTotal);
        let existTotal = parseFloat(onGoingProduct.total);

        //calculate unit cost / total
        if (e.target.name === "subTotal") {
            //subtotal
            let gst = amountChanged * gstPercentage;
            exist["gst"] = gst.toFixed(2);
            let total = amountChanged + gst;
            exist["total"] = total.toFixed(2);
        } else if (e.target.name === "gst" && existSubTotal > 0) {
            //gst - calc total
            let total = existSubTotal + amountChanged;
            exist["total"] = total.toFixed(2);
        } else if (e.target.name === "gst" && existSubTotal === 0 && existTotal > 0) {
            //gst - calc subtotal
            let subTotal = (existTotal * 1) - amountChanged;
            exist["subTotal"] = subTotal.toFixed(2);
        } else if (e.target.name === "total") {
            //total - get gst and subtotal
            let gstPercent = gstPercentage * 100;
            let gst = amountChanged * gstPercent / (100 + gstPercent)
            exist["gst"] = gst.toFixed(2);
            let subTotal = amountChanged - gst;
            exist["subTotal"] = subTotal.toFixed(2);
        }

        let lastRow = onGoingProducts.length - 1;
        if (index == lastRow) {
            //add new item
            let blankRow = addBlankRow();
            onGoingProducts.push(blankRow);
        }
        setOnGoingHireProducts(onGoingProducts);
        setSaveDisabled(false);
        setErrors({});
    }

    const handleCheckboxChange = (index: number, e: any) => {
        var onGoingProducts = [...onGoingHireProducts];
        var onGoingProduct = onGoingProducts[index];

        let exist: any = {};
        exist = onGoingProduct;
        exist[e.target.name] = e.target.checked;
        exist.isDirty = true;

        setOnGoingHireProducts(onGoingProducts);
        setSaveDisabled(false);
        setErrors({});
    }

    const addBlankRow = () => {
        var blankRow: OnGoingHireProduct = {
            id: 0,
            productId: -1,
            product: "",
            subTotal: "0.00",
            subTotalFormatted: "$0.00",
            gst: "0.00",
            gstFormatted: "$0.00",
            total: "0.00",
            totalFormatted: "$0.00",
            startDate: "",
            startDateFormatted: "",
            endDate: "",
            endDateFormatted: "",
            days: 0,
            complete: false,
            isDirty: false
        }
        return blankRow;
    }

    const tabChanged = (url: string) => {
        navigate(url);
    }

    //extend item
    const extend = (e: any, index: number) => {
        e.preventDefault();

        var products = [...onGoingHireProducts];
        var product = products[index];
        
        //auto extend the dates
        var existEndDate = product.endDate;
        //new start date = exist end date + 1 day
        var newStartDate = DateHelpers.addDaysToDate(existEndDate, 1);
        //new end date = new start date + no days
        var newEndDate = DateHelpers.addDaysToDate(newStartDate, product.days);
        var extendHireProduct: OnGoingHireProduct = {
            id: product.id,
            productId: product.productId,
            product: product.product,
            startDate: newStartDate,
            endDate: newEndDate,
            startDateFormatted: "",
            endDateFormatted: "",
            subTotal: product.subTotal,
            gst: product.gst,
            total: product.total,
            subTotalFormatted: "",
            gstFormatted: "",
            totalFormatted: "",
            days: product.days,
            complete: false,
            isDirty: false
        };

        setExtendProduct(extendHireProduct);
        setExtendOptions({ showExtendModal: true, extendProductIndex: index });
    }

    const resetExtendProduct = () => {
        var extendProduct: OnGoingHireProduct = {
            id: 0,
            productId: 0,
            product: "",
            subTotal: "0.00",
            gst: "0.00",
            total: "0.00",
            subTotalFormatted: "$0.00",
            gstFormatted: "$0.00",
            totalFormatted: "$0.00",
            startDate: "",
            startDateFormatted: "",
            endDate: "",
            endDateFormatted: "",
            days: 0,
            complete: false,
            isDirty: false
        };

        return extendProduct;
    }

    const hideExtend = () => {
        var extendHireProduct = resetExtendProduct();
        setExtendProduct(extendHireProduct);
        setExtendOptions({ showExtendModal: false, extendProductIndex: -1 });
    }

    const updateExtend = (extendHireProduct: OnGoingHireProduct) => {
        setExtendProduct(prevState => ({ ...prevState, extendHireProduct }));
    }

    const saveExtend = () => {
        saveExtendProduct();
    }

    const saveExtendProduct = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var saveExtendProduct = {
            jobId: jobData.jobId,
            extendProduct: extendProduct,
            subId: user.sub
        };

        axios.post('Jobs/ExtendOnGoingHireProduct', saveExtendProduct, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("On-Going Hire product has been extended");
                var extendHireProduct = resetExtendProduct();
                setExtendProduct(extendHireProduct);
                setExtendOptions({ showExtendModal: false, extendProductIndex: -1 });
                
                getOnGoingHireProducts();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end extend item

    //delete row
    const deleteItem = (e: any, index: number) => {
        e.preventDefault();

        setDeleteOptions({ showConfirmModal: true, productIndex: index });
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setDeleteOptions({ showConfirmModal: false, productIndex: -1 });
        setModalSaveDisabled(false);
    }

    const updateSaveDisabled = () => {
        setModalSaveDisabled(true);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);

        var onGoingProducts = [...onGoingHireProducts];
        var onGoingProduct = onGoingProducts[deleteOptions.productIndex];

        if (onGoingProduct.id === 0) {
            //not saved yet so just remove from list
            onGoingProducts.splice(deleteOptions.productIndex, 1);
            setOnGoingHireProducts(onGoingProducts);
            setDeleteOptions({ showConfirmModal: false, productIndex: -1 });

            toast.success("Product has been deleted");
        } else {
            deleteProduct();
        }
    }

    const deleteProduct = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var onGoingProducts = [...onGoingHireProducts];
        var onGoingProduct = onGoingProducts[deleteOptions.productIndex];

        var deleteProduct = {
            id: onGoingProduct.id,
            jobId: jobData.jobId,
            subId: user.sub
        };

        axios.post('Jobs/DeleteOnGoingHireProduct', deleteProduct, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                toast.success("On-Going Hire product has been deleted");

                //remove from list
                onGoingProducts.splice(deleteOptions.productIndex, 1);
                setOnGoingHireProducts(onGoingProducts);
                setDeleteOptions({ showConfirmModal: false, productIndex: -1 });
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end delete row

    const validate = () => {
        let formIsValid = true;
        var items = onGoingHireProducts;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        if (items.length > 1) {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";
                var validStartDate = true;
                var validEndDate = true;

                if (items[i].productId < 0) {
                    newErrorString += " Product, ";
                    error = true;
                } else if (items[i].productId === 1 && !items[i].product) {
                    newErrorString += " Other Product, ";
                    error = true;
                }

                if (!items[i].startDate || !DateHelpers.isValidDate(items[i].startDate)) {
                    newErrorString += " Valid Start Date, ";
                    error = true;
                    validStartDate = false;
                }

                if (!items[i].endDate || !DateHelpers.isValidDate(items[i].endDate)) {
                    newErrorString += " Valid End Date, ";
                    error = true;
                    validEndDate = false;
                }

                if (validStartDate && validEndDate) {
                    if (!DateHelpers.compareDate(items[i].startDate, items[i].endDate)) {
                        newErrorString += " Start Date <= End Date, ";
                        error = true;
                    }
                }

                var subtotal = !items[i].subTotal ? 0 : parseFloat(items[i].subTotal);
                if (subtotal === 0) {
                    newErrorString += " Sub-Total, ";
                    error = true;
                }

                var total = !items[i].total ? 0 : parseFloat(items[i].total);
                if (total === 0) {
                    newErrorString += " Total, ";
                    error = true;
                }

                if (newErrorString != "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            let errorList: any = {};
            errorList["products"] = errorString;
            setErrors(errorList);
        }
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveOnGoingHireProducts();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveOnGoingHireProducts = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        
        var saveProducts = onGoingHireProducts.filter(o => o.isDirty);

        if (saveProducts.length === 0) {
            toast.info("No Products have changed!")
        } else {
            var saveOnGoingHireProducts = {
                jobId: jobData.jobId,
                onGoingHireProducts: saveProducts,
                subId: user.sub
            };

            axios.post('Jobs/SaveOnGoingHireProducts', saveOnGoingHireProducts, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                if (res.data.isError) {
                    var errors = res.data.messages as any[];
                    for (var i = 0; i < errors.length; i++) {
                        toast.error(errors[i].content);
                    }
                } else {
                    toast.success("On-Going Hire Products Saved");
                    setErrors({});

                    //reload products
                    getOnGoingHireProducts();
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
        }

    }


    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="ongoinghire" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />
    let confirmPopup = <ConfirmModal heading="Delete On-Going Hire Product" text="Are you sure you want to delete this on-going hire product?" hideConfirmModal={hideConfirmModal} showConfirmModal={deleteOptions.showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let extendProductItem = <JobOnGoingHireExtendPopupData show={extendOptions.showExtendModal} saveDisabled={modalSaveDisabled} gstPercentage={gstPercentage} product={extendProduct} hide={hideExtend} update={updateExtend} save={saveExtend} />
    let lastRow = canEdit ? onGoingHireProducts.length - 1 : onGoingHireProducts.length;

    const renderDetails = (
        <form onSubmit={save}>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <div className="static-modal">
                    {confirmPopup}
                    {extendProductItem}
                </div>

                <div className='overflowAuto'>
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th></th>
                                <th>Product</th>
                                <th>SubTotal</th>
                                <th>GST</th>
                                <th>Total</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Days</th>
                                <th>Extend</th>
                                <th className="textaligncenter">Complete</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {onGoingHireProducts.map((item, index) =>
                                <tr key={index}>
                                    <td className="hidden">{item.id}</td>
                                    <td>{index + 1}</td>
                                    <td className="table__text--align">
                                        <select className={!canEdit || item.complete ? "hidden" : "select table__select--size"} name="productId" value={item.productId} onChange={(e) => handleCellChange(index, e)}>
                                            <option hidden defaultValue="-1"></option>
                                            {productList.map(product =>
                                                <option key={product.id} value={product.id}>{product.name}</option>
                                            )};
                                        </select>
                                        <br className={item.productId === 1 && canEdit && !item.complete ? '' : "hidden"}></br>
                                        <input className={item.productId === 1 && canEdit && !item.complete ? 'input ongoinghire-other--size' : "hidden"} type='text' maxLength={50} id="product" name="product" value={item.product} onChange={(e) => handleCellChange(index, e)}></input>
                                        {!canEdit || item.complete ? item.product : ""}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        <input type='number' min="0" step="any" className={!canEdit || item.complete ? "hidden" : "form-control table__tdmin--sm textalignright"} name="subTotal" value={item.subTotal} onChange={(e) => handleCellAmountChange(index, e)} />
                                        {!canEdit || item.complete ? item.subTotalFormatted : ""}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        <input type='number' min="0" step="any" className={!canEdit || item.complete ? "hidden" : "form-control table__tdmin--sm textalignright"} name="gst" value={item.gst} onChange={(e) => handleCellAmountChange(index, e)} />
                                        {!canEdit || item.complete || item.complete ? item.gstFormatted : ""}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        <input type='number' min="0" step="any" className={!canEdit || item.complete ? "hidden" : "form-control table__tdmin--sm textalignright"} name="total" value={item.total} onChange={(e) => handleCellAmountChange(index, e)} />
                                        {!canEdit || item.complete ? item.totalFormatted : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <input type="date" className={!canEdit || item.complete ? "hidden" : "form-control table__tdmin--md"} name="startDate" value={item.startDate} onChange={(e) => handleCellChange(index, e)} />
                                        {!canEdit || item.complete ? item.startDateFormatted : ""}
                                    </td>
                                    <td className="table__text--align">
                                        <input type="date" className={!canEdit || item.complete ? "hidden" : "form-control table__tdmin--md"} name="endDate" value={item.endDate} onChange={(e) => handleCellChange(index, e)} />
                                        {!canEdit || item.complete ? item.endDateFormatted : ""}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        {item.days}
                                    </td>
                                    <td className="table__text--align">
                                        <div className={index === lastRow || item.id === 0 || !canEdit || item.complete ? "hidden" : "delete--tablecell"}>
                                            <a className="makeitskyblue" href="#" onClick={(e) => extend(e, index)}>
                                                <span className="fas fa-arrow-right edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="table__text--align textaligncenter">
                                        <input className={index === lastRow ? "hidden" : "checkbox"} type="checkbox" id="complete" name="complete" checked={item.complete} onChange={(e) => handleCheckboxChange(index, e)} disabled={!canEdit || (item.complete && !item.isDirty)}></input>
                                    </td>
                                    <td className="table__text--align">
                                        <div className={index === lastRow || !canEdit || item.complete ? "hidden" : "delete--tablecell"}>
                                            <a className="makeitred" href="#" onClick={(e) => deleteItem(e, index)}>
                                                <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {errors["products"] ?
                        (errors["products"]).split("\n").map((item: any, key: any) => {
                            return <span className="label errors" key={key}>{item}<br /></span>
                        })
                        : ""}
                </div>
                <button className="defaultbutton defaultbutton__medium" type="submit" disabled={!canEdit || saveDisabled}>Save</button>
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return <div>
        {contents}
    </div>;
}

export default JobOnGoingHireData;
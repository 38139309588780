import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import AddMultipleInsurerInvoicePopup from './JobInvoiceMultipleInsurerPopup';

interface JobInvoiceListProps {
    jobId: number;
    canEdit: boolean;
    changePage(pageData: PageChangeData): void;
}

interface Invoice {
    id: number;
    xeroInvoiceId: string;
    xeroInvoiceNumber: string;
    customerName: string;
    invoiceDate: string;
    dueDate: string;
    type: string;
    status: string;
    statusId: number;
    paidDate: string;
    total: number;
    totalFormatted: string;
}

interface PageChangeData {
    id: number,
    page: string
}

const JobInvoiceListData = (props: JobInvoiceListProps) => {
    const [loading, setLoading] = React.useState(true);
    const [invoices, setInvoices] = React.useState<Invoice[]>([]);
    const [multipleInsurers, setMultipleInsurers] = React.useState(false);
    const [showMultipleInvoice, setShowMultipleInvoice] = React.useState(false);
    const [totals, setTotals] = React.useState({ totalFormatted: "$0.00", totalSentFormatted: "$0.00" });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Jobs/GetInvoices?SubId=' + user.sub + '&JobId=' + props.jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setInvoices(res.data.invoices); 
            setMultipleInsurers(res.data.jobHasMultipleInsurers);
            setTotals({ totalFormatted: res.data.totalFormatted, totalSentFormatted: res.data.totalSentFormatted });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const checkPaidStatus = () => {
        checkInvPaidStatus();
    }

    const checkInvPaidStatus = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        //check if we need to check Xero Auth
        //check authentication
        //enter the page that xero needs to return to, id blank as not used via invoicing
        var page = {
            page: "invoices",
            id: props.jobId
        };
        //encode to base 64 so can retrieve it later
        var encoded = btoa(JSON.stringify(page));

        axios.get('Xero/GetAuthentication?SubId=' + user.sub + '&ReturnUrl=' + encoded, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var isAuth = res.data.isAuthorised;
            var xeroLoginUrl = res.data.xeroLoginUrl;

            if (isAuth) {
                var details = {
                    jobId: props.jobId * 1,
                    jobInvoices: invoices,
                    subId: user.sub
                };

                //can check paid status of invoices
                axios.post('Jobs/UpdateJobInvoicePaidStatus', details, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        setInvoices(res.data.invoices);
                        setTotals({ totalFormatted: res.data.totalFormatted, totalSentFormatted: res.data.totalSentFormatted });
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            } else {
                window.location.replace(xeroLoginUrl)
            }
        });
    }

    const addNew = () => {
        var pageDetails: PageChangeData = {
            id: 0,
            page: "Details"
        };
        props.changePage(pageDetails);
    }

    //MULTIPLE INSURER INVOICES
    const addMultipleInvoice = () => {
        setShowMultipleInvoice(true);
    }

    const hideMultipleInvoice = () => {
        setShowMultipleInvoice(false);
    }

    const saveMultipleInvoice = () => {
        setShowMultipleInvoice(false);
        getData();
    }

    //END MULTIPLE INSURER INVOICES

    const openDetails = (id: number, e: any) => {
        e.preventDefault();
        var pageDetails: PageChangeData = {
            id: id,
            page: "Details"
        };
        props.changePage(pageDetails);
    }

    let multipleInvoice = <AddMultipleInsurerInvoicePopup jobId={props.jobId} show={showMultipleInvoice} hide={hideMultipleInvoice} save={saveMultipleInvoice} />

    const renderDetails = (
        <div>
            <div className="static-modal">
                {multipleInvoice}
            </div>
            <div className="makeitflexspacebetween">
                <h2 className={multipleInsurers ? "makeitred" : ""}>{multipleInsurers ? "This job has MULTIPLE Insurers!" : ""}</h2>
                <div className="defaultbutton__container--right">
                    <button className={props.canEdit ? "defaultbutton" : "hidden"} type="button" onClick={checkPaidStatus}>Check Paid Status</button>
                    <button className={props.canEdit && multipleInsurers ? "defaultbutton invoice__back--position" : "hidden"} type="button" title="Creates an invoice per insurer added to the job." onClick={addMultipleInvoice}>Multiple Insurer Invoice</button>
                    <button className={props.canEdit ? "defaultbutton defaultbutton__small invoice__back--position" : "hidden"} type="button" onClick={addNew}>Add New</button>
                </div>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th>Inv Number</th>
                            <th>Xero Inv Number</th>
                            <th>Customer</th>
                            <th>Invoice Date</th>
                            <th>Due Date</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Paid Date</th>
                            <th className="textalignright">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice, index) =>
                            <tr key={index}>
                                <td className="table__text--align">
                                    <a href="#" onClick={(e) => openDetails(invoice.id, e)}>
                                        {invoice.id}
                                    </a>
                                </td>
                                <td>{invoice.xeroInvoiceNumber}</td>
                                <td>{invoice.customerName}</td>
                                <td>{invoice.invoiceDate}</td>
                                <td>{invoice.dueDate}</td>
                                <td>{invoice.type}</td>
                                <td>{invoice.status}</td>
                                <td>{invoice.paidDate}</td>
                                <td className="table__text--align textalignright">{invoice.totalFormatted}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <label className="input-group estimate__alignTotals estimate__width" htmlFor="totalFormatted">
                <span className="label">Total Invoices</span>
                <input className="input estimate__totalsWidth textalignright" type="text" id="totalFormatted" name="totalFormatted" value={totals.totalFormatted} disabled></input>
            </label>
            <label className="input-group estimate__alignTotals" htmlFor="totalSentFormatted">
                <span className="label">Total Sent</span>
                <input className="input estimate__totalsWidth textalignright" type="text" id="totalSentFormatted" name="totalSentFormatted" value={totals.totalSentFormatted} disabled></input>
            </label>
        </div>

    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobInvoiceListData;
import * as React from 'react';
import { useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import JobAssessmentBuildingData from './JobAssessmentsBuilding';
import JobAssessmentSuuData from './JobAssessmentsSuu';
import JobAssessmentPhotosData from './JobAssessmentsPhotos';
import authService from './api-authorization/AuthorizeService';
import assessmentReducer from './JobAssessmentReducer';

interface JobAssessmentProps {
   jobId: number;
}

interface GenericListItem {
    id: number;
    name: string;
}

interface BuildingAssessment {
    id: number;
    jobId: number;
    typeId: number;
    policyNumber: string;
    occupantName: string;
    occupantContactNumber: string;
    incidentConfirmed: number;
    clientInformedNextSteps: number;
    occupancyType: number;
    temporaryAccommodation: number;
    furnitureRemovalStorage: number;
    makeSafeRequired: number;
    emergencyServicesAttend: number;
    makeSafeDescription: string;
    workCompletedOthers: number;
    workCompletedOthersDescription: string;
    buildingType: number;
    outBuildings: string;
    buildingCondition: string;
    constructionType: number;
    roofType: number;
    buildingUse: number;
    buildingAge: string;
    propertyUninhabitable: number;
    uninhabitableDuringRepairs: number;
    uninhabitableDuringRepairsTime: string;
    damageDetails: string;
    insuredVersionOfEvents: string;
    causeOfDamage: string;
    causeOfDamageTime: string;
    roofReportRequested: number;
    roofGeneralCondition: string;
    roofStormDamage: string;
    guttersClean: string;
    ceilingInspection: number;
    guttersSufficient: string;
    additionalRoofFixtures: number;
    rainWaterBreach: string;
    maintenanceIssues: number;
    maintenanceIssuesDescription: string;
    maintenanceIssuesInsuredAware: number;
    maintenanceIssuesInsuredAwareTime: string;
    maintenanceIssuesAddressed: number;
    damageConsistentWithEvent: number;
    insuranceRelated: number;
    recommendAcceptance: number;
    additionalComments: string;
    numberOfStories: number;
    roofPitch: string;
    structureArea: string;
    observations: string;
    resolution: string;
}

interface UploadedFile {
    file: AppFile,
    comment: string
}

interface AppFile {
    id: number;
    fileName: string;
    fileType: string;
    content: any;
    photoOrder: number;
}

const JobAssessmentData = (props: JobAssessmentProps) => {
    const [loading, setLoading] = React.useState(true);
    const [canEdit, setCanEdit] = React.useState(false);
    const [buildingTypes, setBuildingTypes] = React.useState<GenericListItem[]>([]);
    const [roofTypes, setRoofTypes] = React.useState<GenericListItem[]>([]);
    const [buildingFloors, setBuildingFloors] = React.useState<GenericListItem[]>([]);
    const [occupancyTypes, setOccupancyTypes] = React.useState<GenericListItem[]>([]);
    const [constructionTypes, setConstructionTypes] = React.useState<GenericListItem[]>([]);
    const [buildingUse, setBuildingUse] = React.useState<GenericListItem[]>([]);
    const [yesNo, setYesNo] = React.useState<GenericListItem[]>([]);
    const [yesNoUnsure, setYesNoUnsure] = React.useState<GenericListItem[]>([]);
    const [overviewPhoto, setOverviewPhoto] = React.useState<AppFile>(
        {
            id: 0,
            fileType: "",
            fileName: "",
            content: [],
            photoOrder: 1
        });
    const [geographicLocationPhoto, setGeographicLocationPhoto] = React.useState<AppFile>(
        {
            id: 0,
            fileType: "",
            fileName: "",
            content: [],
            photoOrder: 1
        });

    const initialBuildingAssessment: BuildingAssessment = {
        id: 0,
        jobId: 0,
        typeId: 1,
        policyNumber: "",
        occupantName: "",
        occupantContactNumber: "",
        incidentConfirmed: -1,
        clientInformedNextSteps: -1,
        occupancyType: -1,
        temporaryAccommodation: -1,
        furnitureRemovalStorage: -1,
        makeSafeRequired: -1,
        emergencyServicesAttend: -1,
        makeSafeDescription: "",
        workCompletedOthers: -1,
        workCompletedOthersDescription: "",
        buildingType: -1,
        outBuildings: "",
        buildingCondition: "",
        constructionType: -1,
        roofType: -1,
        buildingUse: -1,
        buildingAge: "",
        propertyUninhabitable: -1,
        uninhabitableDuringRepairs: -1,
        uninhabitableDuringRepairsTime: "",
        damageDetails: "",
        insuredVersionOfEvents: "",
        causeOfDamage: "",
        causeOfDamageTime: "",
        roofReportRequested: -1,
        roofGeneralCondition: "",
        roofStormDamage: "",
        guttersClean: "",
        ceilingInspection: -1,
        guttersSufficient: "",
        additionalRoofFixtures: -1,
        rainWaterBreach: "",
        maintenanceIssues: -1,
        maintenanceIssuesDescription: "",
        maintenanceIssuesInsuredAware: -1,
        maintenanceIssuesInsuredAwareTime: "",
        maintenanceIssuesAddressed: -1,
        damageConsistentWithEvent: -1,
        insuranceRelated: -1,
        recommendAcceptance: -1,
        additionalComments: "",
        numberOfStories: -1,
        roofPitch: "",
        structureArea: "",
        observations: "",
        resolution: ""
    };

    const [{
        buildingAssessment,
        isLoading,
    }, dispatch] = useReducer(assessmentReducer, { buildingAssessment: initialBuildingAssessment, isLoading: false });

    const [buildingAssessmentPhotos, setBuildingAssessmentPhotos] = React.useState<UploadedFile[]>([]);
    const [buildingAssessmentSuuPhotos, setBuildingAssessmentSuuPhotos] = React.useState<UploadedFile[]>([]);
    const [loadingPhotos, setLoadingPhotos] = React.useState(false);
    const [showPhotos, setShowPhotos] = React.useState(false);
    const [reportUrl, setReportUrl] = React.useState("");
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const getData = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        axios.get('Estimate/GetAssessmentDropdowns?JobId=' + props.jobId + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var reportName = res.data.buildingAssessmentReport.typeId == 1 ? "buildingAssessment" : "buildingAssessmentSuu";
            var url = "/job/" + props.jobId + "/report/" + res.data.buildingAssessmentReport.id + "/" + reportName;

            setCanEdit(res.data.canEdit);
            setReportUrl(url);
            setBuildingTypes(res.data.buildingTypes);
            setRoofTypes(res.data.roofTypes);
            setBuildingFloors(res.data.buildingFloors);
            setOccupancyTypes(res.data.occupancyTypes);
            setConstructionTypes(res.data.constructionTypes);
            setBuildingUse(res.data.buildingUse);
            setYesNo(res.data.yesNo);
            setYesNoUnsure(res.data.yesNoUnsure);
            setOverviewPhoto(res.data.overviewPhoto);
            setGeographicLocationPhoto(res.data.geographicLocationPhoto);
            dispatch({ type: "updateDetails", buildingAssessment: res.data.buildingAssessmentReport });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const onTypeChanged = (e: any) => {
        var ba = buildingAssessment;
        ba.typeId = e.target.value * 1;

        var reportName = ba.typeId == 1 ? "buildingAssessment" : "buildingAssessmentSuu";
        var url = "/job/" + props.jobId + "/report/" + ba.id + "/" + reportName;

        dispatch({ type: "updateDetails", buildingAssessment: ba });
        setReportUrl(url);
    }

    const importOverviewLocationPhoto = (file: File, fileType: string) => {
        importOverviewLocPhoto(file, fileType);
    }

    const importOverviewLocPhoto = async (file: File, fileType: string) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save photos
        const data = new FormData()
        data.append('jobId', props.jobId.toString());
        data.append('typeId', fileType);
        data.append('photos', file);
        data.append('subId', user.sub);

        //save photos
        axios.post('Jobs/ImportJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //save the returned photos
                var photos = res.data.photos;
                for (var p = 0; p < photos.length; p++) {
                    var photo = photos[p];
                    var file: AppFile = {
                        id: photo.id,
                        fileName: photo.fileName,
                        fileType: photo.fileType,
                        content: photo.content,
                        photoOrder: photo.photoOrder
                    };
                    if (fileType === "4") {
                        setOverviewPhoto(file);
                    } else {
                        setGeographicLocationPhoto(file);
                    }
                        
                }
                toast.success("Photos have been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    };

    const updateSaveDisabled = () => {
        setSaveDisabled(true);
    }

    const updateAssessment = (ba: BuildingAssessment) => {
        dispatch({ type: "updateDetails", buildingAssessment: ba });
    }

    const updateSaveAssessment = () => {
        //save the estimate after each change
        saveJobAssessment(true);
    }

    const saveAssessment = () => {
        saveJobAssessment(false);
    }

    const saveJobAssessment = async(update: boolean) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var ba = buildingAssessment;

        var saveBuildingAssessment = {
            buildingAssessment: ba,
            subId: user.sub
        }

        axios.post('Estimate/SaveAssessment', saveBuildingAssessment, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                ba.id = res.data.id;

                var url = "";
                var showPics = false;
                if (!update) {
                    //only show the view report button after going next
                    var reportName = buildingAssessment.typeId == 1 ? "buildingAssessment" : "buildingAssessmentSuu";
                    url = "/job/" + props.jobId + "/report/" + buildingAssessment.id + "/" + reportName;
                    toast.success("Assessment Saved");
                    showPics = true;

                    setReportUrl(url);
                    setShowPhotos(showPics);
                    setSaveDisabled(false);
                    setLoadingPhotos(true);
                    dispatch({ type: "updateDetails", buildingAssessment: ba });

                    axios.get('Estimate/GetAssessmentPhotos?JobId=' + props.jobId + '&TypeId=' + ba.typeId, {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                    .then(res => {
                        //get photos
                        var photos = res.data;

                        //Get Building Assessment Photos
                        if (!photos) {
                            photos = [];
                        }

                        var uploadedAssessmentPhotos: UploadedFile[] = [];
                        for (var i = 0; i < photos.length; i++) {
                            var uploadedPhoto: UploadedFile = {
                                file: {
                                    id: photos[i].id,
                                    fileName: photos[i].fileName,
                                    fileType: photos[i].fileType,
                                    content: photos[i].content,
                                    photoOrder: photos[i].photoOrder
                                },
                                comment: photos[i].comment
                            };
                            uploadedAssessmentPhotos.push(uploadedPhoto);
                        }

                        var baPhotos: UploadedFile[] = [];
                        var suuPhotos: UploadedFile[] = [];

                        if (buildingAssessment.typeId == 1) {
                            baPhotos = uploadedAssessmentPhotos;
                        } else {
                            suuPhotos = uploadedAssessmentPhotos;
                        }

                        setBuildingAssessmentPhotos(baPhotos);
                        setBuildingAssessmentSuuPhotos(suuPhotos);
                        setLoadingPhotos(false);
                    })
                    .catch(error => {
                        toast.error(error.message);
                    });
                } else {
                    dispatch({ type: "updateDetails", buildingAssessment: ba });
                    setReportUrl(url);
                    setShowPhotos(showPics);
                    setSaveDisabled(false);
                } 
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //PHOTOS
    const importPhotos = (files: File[]) => {
        setLoadingPhotos(true);
        importJobPhotos(files);
    }

    const importJobPhotos = async(files: File[]) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save photos
        const data = new FormData()
        data.append('jobId', props.jobId.toString());
        data.append('typeId', buildingAssessment.typeId == 1 ? "6" : "7");
        data.append('subId', user.sub);

        for (var i = 0; i < files.length; i++) {
            data.append('photos', files[i]);
        }

        //save photos
        axios.post('Jobs/ImportJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                //save the returned photos
                var uploadedFiles: UploadedFile[] = [];
                var photos = res.data.photos;
                for (var p = 0; p < photos.length; p++) {
                    var photo = photos[p];
                    var file: UploadedFile = {
                        file: {
                            id: photo.id,
                            fileName: photo.fileName,
                            fileType: photo.fileType,
                            content: photo.content,
                            photoOrder: photo.photoOrder
                        },
                        comment: photo.comment
                    };
                    uploadedFiles.push(file);
                }
                if (buildingAssessment.typeId == 1) {
                    setBuildingAssessmentPhotos(uploadedFiles);
                    setLoadingPhotos(false);
                } else {
                    setBuildingAssessmentSuuPhotos(uploadedFiles);
                    setLoadingPhotos(false);
                }
                    
                toast.success("Photos have been uploaded");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });

    }

    const updatePhotoImport = (files: UploadedFile[]) => {
        if (buildingAssessment.typeId == 1) {
            setBuildingAssessmentPhotos(files);
        } else {
            setBuildingAssessmentSuuPhotos(files);
        }
    }

    const deletePhotoImport = (deleteIndex: number) => {
        var files = buildingAssessment.typeId == 1 ? buildingAssessmentPhotos : buildingAssessmentSuuPhotos;
        var photo = files[deleteIndex];

        if (!photo) {
            toast.error("Could not find photo to delete!");
            return;
        }

        if (photo.file.id > 0) {
            deleteJobPhoto(photo.file.id, deleteIndex);
        } else {
            //save the photos on upload so this shouldn't happen but just in case
            files.splice(deleteIndex, 1);
            //re-order the photos
            for (var i = 0; i < files.length; i++) {
                files[i].file.photoOrder = i + 1;
            }

            if (buildingAssessment.typeId == 1) {
                setBuildingAssessmentPhotos(files);
            } else {
                setBuildingAssessmentSuuPhotos(files);
            }
            toast.success("Photo has been deleted");
        }

    }

    const deleteJobPhoto = async(photoId: number, deleteIndex: number) => {
        var files = buildingAssessment.typeId == 1 ? buildingAssessmentPhotos : buildingAssessmentSuuPhotos;
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var data = {
            id: photoId,
            subId: user.sub
        };

        //delete photo
        axios.post('Jobs/DeleteJobPhoto', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                files.splice(deleteIndex, 1);

                //re-order the photos
                for (var i = 0; i < files.length; i++) {
                    files[i].file.photoOrder = i + 1;
                }

                if (buildingAssessment.typeId == 1) {
                    setBuildingAssessmentPhotos(files);
                } else {
                    setBuildingAssessmentSuuPhotos(files);
                }
                toast.success("Photo has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const savePhotoImport = () => {
        saveJobPhotoImport();
    }

    const saveJobPhotoImport = async() => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        var files = buildingAssessment.typeId == 1 ? buildingAssessmentPhotos : buildingAssessmentSuuPhotos;

        var photoComments = [];
        for (var i = 0; i < files.length; i++) {
            var photoOrder = files[i].file.photoOrder;
            if (!photoOrder) {
                //set the order for any null entries
                photoOrder = i + 1;
            }
            var item = {
                id: files[i].file.id,
                comments: files[i].comment,
                photoOrder: photoOrder
            };
            photoComments.push(item);
        }

        var data = {
            jobPhotoComments: photoComments,
            subId: user.sub
        };

        //save comments
        axios.post('Jobs/SaveJobPhotoComments', data, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Photos have been saved");
                setShowPhotos(false);
                printAssessmentReport();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const printAssessmentReport = () => {
        var reportName = buildingAssessment.typeId == 1 ? "buildingAssessment" : "buildingAssessmentSuu";
        var url = "/job/" + props.jobId + "/report/" + buildingAssessment.id + "/" + reportName;
        window.open(url, '_blank');
    }
    //END PHOTOS

    var tabContents;
    if (showPhotos) {
        var existingPhotos = buildingAssessment.typeId == 1 ? buildingAssessmentPhotos : buildingAssessmentSuuPhotos;
        //show photo upload page
        tabContents = <JobAssessmentPhotosData id={buildingAssessment.id} loadingPhotos={loadingPhotos} uploadedFiles={existingPhotos} importPhotos={importPhotos} update={updatePhotoImport} delete={deletePhotoImport} save={savePhotoImport} />;
    }
    else if (buildingAssessment.typeId === 1) {
        tabContents = <JobAssessmentBuildingData canEdit={canEdit} saveDisabled={saveDisabled} overviewPhoto={overviewPhoto} geographicLocationPhoto={geographicLocationPhoto} buildingAssessment={buildingAssessment} buildingFloors={buildingFloors} buildingTypes={buildingTypes} roofTypes={roofTypes} constructionTypes={constructionTypes} yesNoUnsure={yesNoUnsure} importOverviewLocationPhoto={importOverviewLocationPhoto} updateSaveDisabled={updateSaveDisabled} update={updateAssessment} updateSave={updateSaveAssessment} save={saveAssessment} />
    }
    else if (buildingAssessment.typeId === 2) {
        tabContents = <JobAssessmentSuuData canEdit={canEdit} saveDisabled={saveDisabled} buildingAssessment={buildingAssessment} buildingUse={buildingUse} buildingTypes={buildingTypes} constructionTypes={constructionTypes} occupancyTypes={occupancyTypes} roofTypes={roofTypes} yesNo={yesNo} yesNoUnsure={yesNoUnsure} updateSaveDisabled={updateSaveDisabled} update={updateAssessment} updateSave={updateSaveAssessment} save={saveAssessment} />
    }

    const renderDetails = (
        <div>
            <div className={showPhotos ? "hidden" : ""}>
                <div className={buildingAssessment.id === 0 || reportUrl === "" ? "hidden" : "input-group"}>
                    <button type="button" className='defaultbutton defaultbutton__medium defaultbutton-label-large' onClick={(e) => { e.preventDefault(); window.open(reportUrl, "_blank"); }}>View Report</button>
                </div>
                <div className="input-group">
                    <label htmlFor="assessmentType" className="label-large">Assessment Type</label>
                    <input type="radio" className="marginRight10" id="ba" name="assessmentType" value="1" checked={buildingAssessment.typeId === 1} onChange={(e) => onTypeChanged(e)}></input>
                    <label className="label" htmlFor="ba">Building Assessment</label>
                    <input type="radio" className="marginRight10" id="suu" name="assessmentType" value="2" checked={buildingAssessment.typeId === 2} onChange={(e) => onTypeChanged(e)}></input>
                    <label className="label" htmlFor="suu">SUU Building Assessment</label>
                </div>
            </div>
            <div>
                {tabContents}
            </div>

        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );

}

export default JobAssessmentData;
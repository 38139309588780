import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import JobTabs from './JobTabs';
import JobCostingsData from './JobCostings';
import JobPurchaseOrdersData from './JobPurchaseOrders';
import JobSupplierInvoicesData from './JobSupplierInvoices';
import authService from './api-authorization/AuthorizeService';

interface UserJobPermissions {
    viewJobEstimate: boolean;
    viewJobCostings: boolean;
    viewJobVariations: boolean;
    viewJobInvoicing: boolean;
}

const JobCostData = () => {
    let navigate = useNavigate();
    const { id, poid } = useParams();
    const[loading, setLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({ jobId: 0, siteAddress: "", isMakeSafe: false, isReportOnly: false });
    const [permissions, setPermissions] = React.useState<UserJobPermissions>({ viewJobEstimate: false, viewJobCostings: false, viewJobVariations: false, viewJobInvoicing: false });
    const [page, setPage] = React.useState("list");
    const [subTab, setSubTab] = React.useState("costs");
    const [pagePermissions, setPagePermissions] = React.useState({
        canEdit: false,
        canViewReport: false,
        canViewSupplierInvoices: false,
        canEditSupplierInvoices: false,
        canApproveSupplierInvoices: false,
        canAddNewSuppliers: false,
        checkSupplierCertificate: false,
        allowAddPo: false
    });
    const [showBackConfirm, setShowBackConfirm] = React.useState(false);
    const [itemId, setItemId] = React.useState(0);
    const [awaitingHow, setAwaitingHow] = React.useState(false);
    const [forecastComplete, setForecastComplete] = React.useState(false);

    React.useEffect(() => {
        let Id = Number(id);
        let PoId = Number(poid);

        getData(Id, PoId);
    }, []);

    const getData = async(Id: number, PoId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //save an audit trail note of who has accessed job details
        var auditTrail = {
            typeId: 4,
            linkId: Id,
            subId: user.sub
        };

        axios.post('Shared/SaveAuditTrail', auditTrail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            //not doing anything if the audit trail doesn't work
        });

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=ViewJobCostings', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                //get site address
                axios.get('Jobs/GetCostingData?JobId=' + Id + '&SubId=' + user.sub, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        errors.map(function (error: any) {
                            toast.error(error.content);
                        });
                    } else {
                        var subTabs = PoId === 0 ? "costs" : "po";
                        setSubTab(subTabs);
                        setJobData({ jobId: Id, siteAddress: res.data.siteAddress, isMakeSafe: res.data.isMakeSafe, isReportOnly: res.data.isReportOnly })
                        setPagePermissions({ canEdit: res.data.canEdit, canViewReport: res.data.canViewReport, canViewSupplierInvoices: res.data.canViewSupplierInvoices, canEditSupplierInvoices: res.data.canEditSupplierInvoices, canApproveSupplierInvoices: res.data.canApproveSupplierInvoices, canAddNewSuppliers: res.data.canAddNewSuppliers, checkSupplierCertificate: res.data.checkSupplierCertificate, allowAddPo: res.data.allowAddPo });
                        setAwaitingHow(res.data.awaitingHow);
                        setForecastComplete(res.data.forecastComplete);
                        setPermissions(res.data.userJobPermissions);
                        setLoading(false);
                    }
                }).catch(error => {
                    toast.error(error.message);
                });
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied")
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //main tab
    const tabChanged = (url: string) => {
        navigate(url);
    }

    //sub tabs
    const subTabSelected = (updatedSubTab: string) => {
        setSubTab(updatedSubTab);
        setPage("list");
        setItemId(0);
    }

    const subTabChanged = (e: any) => {
        subTabSelected(e.target.value);
    }

    //purchase order pages
    const pageChanged = (id: number, page: string) => {
        setItemId(id);
        setPage(page);
    }

    const addNew = () => {
        setItemId(0);
        setPage("details");
    }

    const back = () => {
        setItemId(0);
        setPage("details");
        setShowBackConfirm(true);
    }
    const backConfirm = () => {
        setItemId(0);
        setPage("list");
        setShowBackConfirm(false);
    }

    const updateShowBackConfirm = (updatedShowBackConfirm: boolean) => {
        setShowBackConfirm(updatedShowBackConfirm);
    }

    const checkHow = () => {
        checkHowComplete();
    }

    const checkHowComplete = async () => {
        const token = await authService.getAccessToken();

        axios.get('Jobs/CheckHow?JobId=' + jobData.jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setAwaitingHow(res.data);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const checkForecast = () => {
        checkForecastComplete();
    }

    const checkForecastComplete = async() => {
        const token = await authService.getAccessToken();

        axios.get('Jobs/CheckForecast?JobId=' + jobData.jobId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setForecastComplete(res.data);
        }).catch(error => {
            toast.error(error.message);
        });
    }

    var tabContents;
    if (subTab === "costs") {
        tabContents = <JobCostingsData canEdit={pagePermissions.canEdit} canViewReport={pagePermissions.canViewReport} jobId={jobData.jobId} checkForecast={checkForecast} />
    }
    else if (subTab === "po") {
        tabContents = <JobPurchaseOrdersData canEdit={pagePermissions.allowAddPo} isMakeSafe={jobData.isMakeSafe} canViewReport={pagePermissions.canViewReport} canAddNewSuppliers={pagePermissions.canAddNewSuppliers} checkSupplierCertificate={pagePermissions.checkSupplierCertificate} jobId={jobData.jobId} showBackConfirm={showBackConfirm} id={itemId} page={page} changePage={pageChanged} checkHow={checkHow} goBackConfirm={backConfirm} updateShowBackConfirm={updateShowBackConfirm} />
    } else if (subTab === "invoices") {
        tabContents = <JobSupplierInvoicesData canEdit={pagePermissions.canEditSupplierInvoices} canApproveSupplierInvoices={pagePermissions.canApproveSupplierInvoices} jobId={jobData.jobId} />
    }
        
    var jobTabs = <JobTabs id={jobData.jobId} siteAddress={jobData.siteAddress} isMakeSafe={jobData.isMakeSafe} isReportOnly={jobData.isReportOnly} tab="costings" canViewEstimate={permissions.viewJobEstimate} canViewCostings={permissions.viewJobCostings} canViewVariations={permissions.viewJobVariations} canViewInvoicing={permissions.viewJobInvoicing} tabChanged={tabChanged} />

    const renderDetails = (
        <div>
            <div>
                {jobTabs}
            </div>
            <div className="tabsComponent__content tabsComponent__content--selected">
                <div className="jobpo__addNew">
                    <div className="tabsComponent__showDropdown">
                        <label className="input-group" htmlFor="jobCostsTab">
                            <span className="label">Cost Item</span>
                            <select className="select" id="jobCostsTab" value={subTab} onChange={subTabChanged} >
                                <option value="costs">Costs</option>
                                <option value="po">Purchase Orders</option>
                                <option className={pagePermissions.canViewSupplierInvoices ? "" : "hidden"} value="invoices">Invoices</option>
                            </select>
                        </label>
                    </div>
                    <div className="tabsComponent__tabs tabsComponent__hideTabs">
                        <input type="radio" name="jobCostsTab" id="tabCosts" className="tabsComponent__control" checked={subTab === 'costs'} readOnly></input>
                        <label className="tabsComponent__label jobCostings__tabSize" htmlFor="tabItem" onClick={() => subTabSelected('costs')}>Costs</label>
                        <input type="radio" name="jobCostsTab" id="tabPO" className="tabsComponent__control" checked={subTab === 'po'} readOnly></input>
                        <label className="tabsComponent__label jobCostings__tabSize" htmlFor="tabItem" onClick={() => subTabSelected('po')}>Purchase Orders</label>
                        <input type="radio" name="jobCostsTab" id="tabCosts" className={pagePermissions.canViewSupplierInvoices ? "tabsComponent__control" : "hidden"} checked={subTab === 'invoices'} readOnly></input>
                        <label className={pagePermissions.canViewSupplierInvoices ? "tabsComponent__label jobCostings__tabSize" : "hidden"} htmlFor="tabItem" onClick={() => subTabSelected('invoices')}>Invoices</label>
                    </div>
                    <div>
                        <button className={subTab === "po" && page === "list" ? "defaultbutton defaultbutton__small" : "hidden"} type='button' onClick={addNew} disabled={awaitingHow || !forecastComplete || !pagePermissions.allowAddPo}>Add New</button>
                        <button className={subTab === "po" && page === "details" ? "defaultbutton defaultbutton__small" : "hidden"} type='button' onClick={back}>Back</button>
                    </div>
                </div>
                <div className="tabsComponent__content tabsComponent__content--selected tabsComponent__noPadding">
                    <div>
                        {tabContents}
                    </div>
                </div>
            </div>
        </div>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    );
}

export default JobCostData;
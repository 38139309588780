import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import { useNavigate } from 'react-router-dom';
import ClientAddDetails from './ClientAddDetails';

interface GenericListItem {
    id: number;
    name: string;
}

const ClientAdd = () => {
    let navigate = useNavigate();
    const [types, setTypes] = React.useState<GenericListItem[]>([]);
    const [terms, setTerms] = React.useState<GenericListItem[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        axios.get('Clients/GetDropdowns', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setTypes(res.data.types);
            setTerms(res.data.terms);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const backToSearch = () => {
        navigate("/clientsearch");
    }

    var client = <ClientAddDetails types={types} terms={terms} backToSearch={backToSearch} />
    
    return (
        <div>
            {client}
        </div>
    )
}

export default ClientAdd;
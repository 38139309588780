import JobData from "./Jobs";

interface JobPurchaseOrderProps {
    jobId: number;
    siteAddress: string;
    isMakeSafe: boolean;
    isReportOnly: boolean;
    howRequired: boolean;
    howCompleted: boolean;
    purchaseOrders: PurchaseOrder[];
    open(poId: number): void;
    addNew(): void;
}

interface PurchaseOrder {
    id: number;
    jobId: number;
    supplier: string;
    insertedDateFormatted: string;
    totalFormatted: string;
    invoicedFormatted: string;
    remainingFormatted: string;
    status: string;
}

const JobPurchaseOrderListMobileData = (props: JobPurchaseOrderProps) => {
    const open = (poId: number, e: any) => {
        e.preventDefault();
        props.open(poId);
    }

    const addNew = (e: any) => {
        e.preventDefault();
        props.addNew();
    }

    return (
        <div>
            <div className="marginTop10 marginBottom70">
                <div className="makeitflexspacebetween">
                    <h4 className="mobile-margin">Purchase Orders</h4>
                    <div className="defaultbutton__container--right makeitflexcenter">
                        <a className="mobile-icon-green" href="#" onClick={(e) => addNew(e)}>
                            <span className="fas fa-plus-circle mobileMenuSize alignIconCenter"></span>
                        </a>
                    </div>
                </div>
                <span className={"mobileFont" + (props.isMakeSafe ? " makeSafeJob" : props.isReportOnly ? " reportOnlyJob" : "")}>{props.siteAddress}</span>
                <div className="overflowAuto marginTop10">
                    <table className="table--main table__small tableColours">
                        <thead>
                            <tr>
                                <th>Order #</th>
                                <th>Supplier</th>
                                <th className="textalignright">Total</th>
                                <th className="textalignright">Inv / Remain</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.purchaseOrders.map((po, index) =>
                                <tr key={po.id}>
                                    <td className="table__text--align">
                                        <a href="#" onClick={(e) => open(po.id, e)}>
                                            {po.id}
                                        </a>
                                    </td>
                                    <td className="table__text--align">{po.supplier}</td>
                                    <td className="table__text--align textalignright">
                                        {po.totalFormatted}
                                    </td>
                                    <td className="table__text--align textalignright">
                                        {po.invoicedFormatted}<br></br>
                                        {po.remainingFormatted}
                                    </td>
                                    <td className="table__text--align">
                                        {po.status}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

}

export default JobPurchaseOrderListMobileData;
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const win = window;
const Stimulsoft = win.Stimulsoft;

Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkvS6ve+duFuELD9hJf74FV4flzfUPrVgcgf5Gr7iKHCjTU6w" +
    "r72rzFXbO49RuoXQHr/iRBJSLuTWYz911rzwAFNK2PnLG7tZD1cLoQjRpfEWPSyTiKm1n2FkV9x8O+zhHozLRXp0hF" +
    "4Pae5GWelWXkstZzkqog7/kRvyWfaOReNQXxD1/L6qdWQo0P53e+MutIbLU+ZdxPAqCYCj+IrnS2kOOjKsAuxMvU2W" +
    "9vU6ZEY2L72NqksPkek//BUimEcBR2Faq7TMJGSA6MjQb9ovj4mi4iUuuQkD6d63hfNlRkzEMqhqlJfR35Km4eBK/O" +
    "YNRvOk+QrfidKoRdg0IuGhP4ZboqknDsgg5gikSpdtkGge1DLONi0zanQbrSY227uI1EGGv0DvCR3IH3H2cJXot80c" +
    "+0D35zyANyxF+xGVWVPZjHy+sRNwWeKTJ2L1ar+83La5t8n6m6eyvmu8nu2mz7dcePXZ3g4vFEYPguFez5+KSyvdK8" +
    "8B1XDy88eSzx//bse0KS9c/wBSNvugApCqEe";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

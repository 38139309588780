import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';

interface VariationHeaderProps {
    jobId: number;
    variationId: number;
    variationName: string;
    canEdit: boolean;
    approved: boolean;
    changePage(pageData: PageChangeData): void;
}

interface PageChangeData {
    variationId: number;
    variationName: string;
    headerId: number;
    headerName: string;
    detailId: number;
    detailName: string;
    approved: boolean;
    page: string;
}

interface VariationHeaderItem {
    itemIndex: number;
    id: number;
    area: string;
    areaOrder: number;
    amountExGst: number;
    amountExGstFormatted: string;
    isDirty: boolean;
}

const VariationHeaderData = (props: VariationHeaderProps) => {
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
    const [variationHeaders, setVariationHeaders] = React.useState<VariationHeaderItem[]>([]);
    const [totals, setTotals] = React.useState({ totalExGst: 0, totalExGstFormatted: "$0.00" });
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [openSaveDisabled, setOpenSaveDisabled] = React.useState(false);
    const [copySaveDisabled, setCopySaveDisabled] = React.useState(false);
    const [variationIndex, setVariationIndex] = React.useState(-1);

    React.useEffect(() => {
        getVariationHeaders();
    }, [props.variationId]);

    const getVariationHeaders = async () => {
        const token = await authService.getAccessToken();

        axios.get('Variation/GetVariationHeaders?VariationId=' + props.variationId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            var headers = res.data.variationHeaders;

            //update item index
            for (var i = 0; i < headers.length; i++) {
                headers[i].itemIndex = i;
            }
            if (!props.approved && props.canEdit) {
                var blankRow = addBlankRow(headers.length);
                headers.push(blankRow);
            }
            setVariationHeaders(headers);
            setTotals({ totalExGst: res.data.totalExGst, totalExGstFormatted: res.data.totalExGstFormatted });
            setLoading(false);
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addBlankRow = (nextItemIndex: number) => {
        var newRow: VariationHeaderItem = {
            itemIndex: nextItemIndex,
            id: 0,
            area: "",
            areaOrder: 0,
            amountExGst: 0,
            amountExGstFormatted: "$0.00",
            isDirty: false
        };
        return newRow;
    }

    //BACK Button
    const back = () => {
        //if isDirty - ask if want to save changes
        var changed = variationHeaders.filter(data => data.isDirty);
        if (changed.length > 0) {
            //check if they want to save
            setShowBackConfirmModal(true);
            setModalSaveDisabled(false);
        } else {
            goBack();
        }
    }

    const cancelBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
    }

    //no save
    const noBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        setModalSaveDisabled(false);
        goBack();
    }

    const saveBackConfirmModal = () => {
        setShowBackConfirmModal(false);
        if (modalSaveDisabled) {
            return;
        } else {
            setModalSaveDisabled(true);
            saveHeaders(-1, "back");
        }
    }

    const goBack = () => {
        var pageChange: PageChangeData = {
            variationId: props.variationId,
            variationName: props.variationName,
            headerId: 0,
            headerName: "",
            detailId: 0,
            detailName: "",
            approved: props.approved,
            page: "list"
        };

        //validate and save before opening
        props.changePage(pageChange);
    }
    //End BACK Button

    const calculateTotals = () => {
        var varLength = !props.approved && props.canEdit ? variationHeaders.length - 1 : variationHeaders.length;

        var total = 0;
        for (var i = 0; i < varLength; i++) {
            total += variationHeaders[i].amountExGst;
        }
        var totalExGstFormatted = total.toLocaleString("en-AU", { style: "currency", currency: "AUD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
        setTotals({ totalExGst: total, totalExGstFormatted: totalExGstFormatted });
    }

    //delete row
    const deleteItem = (index: number, e: any) => {
        e.preventDefault();

        setVariationIndex(index);
        setShowConfirmModal(true);
        setModalSaveDisabled(false);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setVariationIndex(-1);
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(false);
        setSaveDisabled(false);

        var headers = [...variationHeaders];
        var header = headers[variationIndex];

        if (header.id === 0) {
            deleteVariationHeaderUpdateOrder(headers);
        } else {
            deleteVariationHeader(headers, header.id);
            
        }
    }

    const deleteVariationHeaderUpdateOrder = async (headers: VariationHeaderItem[]) => {
        const token = await authService.getAccessToken();

        //remove from list
        var removedItem = headers.splice(variationIndex, 1);

        //loop through the headers and update the order
        var hasBlankRow = !props.approved && props.canEdit;
        var length = hasBlankRow ? headers.length - 1 : headers.length;
        for (var i = 0; i < length; i++) {
            headers[i].itemIndex = (i);
            headers[i].areaOrder = (i + 1);
        }

        //save headers as order changed
        //save new details order
        axios.post('Variation/DeleteVariationHeaderUpdateOrder', headers, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
                //add back in deleted item
                headers.splice(variationIndex, 0, removedItem[0]);
                setVariationHeaders(headers);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            } else {
                setVariationHeaders(headers);
                setShowConfirmModal(false);
                setVariationIndex(-1);
                
                calculateTotals();
                toast.success("Item has been deleted");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const deleteVariationHeader = async (headers: VariationHeaderItem[], headerId: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        //remove from list
        var removedItem = headers.splice(variationIndex, 1);

        //loop through the headers and update the order
        for (var i = 0; i < headers.length; i++) {
            headers[i].itemIndex = (i);
            headers[i].areaOrder = (i + 1);
        }

        var deleteDetail = {
            variationId: props.variationId,
            id: headerId,
            jobId: props.jobId,
            variationHeaderOrder: headers,
            subId: user.sub
        };

        axios.post('Variation/DeleteVariationHeader', deleteDetail, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                }
                //add back in deleted item
                headers.splice(variationIndex, 0, removedItem[0]);
                setVariationHeaders(headers);
                setShowConfirmModal(false);
                setVariationIndex(-1);
            } else {
                toast.success("Item has been deleted");

                setVariationHeaders(headers);
                setShowConfirmModal(false);
                setVariationIndex(-1);

                calculateTotals();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    //end delete row

    //grid item changed
    const handleCellChange = (index: number, e: any) => {
        //update item that has changed
        var headers = [...variationHeaders];
        var header = headers[index];
        let exist: any = {};
        exist = header;
        exist[e.target.name] = e.target.value;
        exist.isDirty = true;

        if (index === (headers.length - 1)) {
            //last row so need to add a new blank row
            var newRow = addBlankRow(headers.length);
            headers.push(newRow);
        }

        setVariationHeaders(headers);
        setSaveDisabled(false);
        setOpenSaveDisabled(false);
        setErrors({});
    }

    //copy header item
    const copy = (itemIndex: number, e: any) => {
        e.preventDefault();

        if (copySaveDisabled) {
            return;
        }
        setCopySaveDisabled(true);

        //if isDirty - ask if want to save changes
        var changed = variationHeaders.filter(data => data.isDirty);
        if (!copySaveDisabled && changed.length > 0) {
            saveHeaders(itemIndex, "copy");
        } else {
            copyHeader(itemIndex);
        }

    }

    const copyHeader = async (itemIndex: number) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        var headers = [...variationHeaders];
        var headerId = headers[itemIndex].id;
        axios.get('Variation/CopyVariationHeader?JobId=' + props.jobId + '&VariationId=' + props.variationId + '&HeaderId=' + headerId + '&SubId=' + user.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                for (var i = 0; i < errors.length; i++) {
                    toast.error(errors[i].content);
                };
            } else {
                //add newly created header
                var newHeader = res.data;
                //add new header before last blank row
                headers.splice(headers.length - 1, 0, newHeader);

                //update item index for all headers
                for (var i = 0; i < headers.length; i++) {
                    headers[i].itemIndex = i;
                }
                setVariationHeaders(headers);
                setModalSaveDisabled(false);
                setCopySaveDisabled(false);
                
                calculateTotals();
                toast.success("Variation Header " + newHeader.area + " has been copied.");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }
    //end copy header

    //open details page
    const openDetails = (itemIndex: number, e: any) => {
        e.preventDefault();

        //if isDirty - ask if want to save changes
        var changed = variationHeaders.filter(data => data.isDirty);
        if (!openSaveDisabled && changed.length > 0) {
            setOpenSaveDisabled(true);
            saveHeaders(itemIndex, "");
        } else {
            var header = variationHeaders[itemIndex];
            openDetailsPage(header.id, header.area);
        }
    }

    const openDetailsPage = (headerId: number, area: string) => {
        var pageChange: PageChangeData = {
            variationId: props.variationId,
            variationName: props.variationName,
            headerId: headerId,
            headerName: area,
            detailId: 0,
            detailName: "",
            approved: props.approved,
            page: "details",
        };

        props.changePage(pageChange);
    }
    //end open details page

    const validate = () => {
        let errorList: any = {};
        let formIsValid = true;
        let items = variationHeaders;
        var rowNumber = 0;

        var errorString = "Please enter the following values: ";
        var error = false;

        //if approved or can't edit we only have 1 row not 2
        let itemLengthCheck = (props.approved || !props.canEdit) ? 1 : 2;

        if (items.length < itemLengthCheck) {
            errorString = "Please enter at least one Area!";
            error = true;
        } else {
            for (var i = 0; i < (items.length - 1); i++) {
                rowNumber += 1;
                var newErrorString = "";

                if (!items[i].area) {
                    newErrorString += " Area, ";
                    error = true;
                }

                if (newErrorString != "") {
                    errorString += "\n"
                    errorString += "Row " + rowNumber + ": " + newErrorString;
                    errorString = errorString.substring(0, errorString.length - 2);
                }
            }
        }

        if (error) {
            formIsValid = false;
            errorList["headers"] = errorString;
        }
        setErrors(errorList);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (saveDisabled) {
            return;
        }
        setSaveDisabled(true);
        setOpenSaveDisabled(false);
        setCopySaveDisabled(false);
        
        saveHeaders(-1, "");
    }

    const saveHeaders = async (openIndex: number, afterSave: string) => {
        if (validate()) {
            var headers = [...variationHeaders];
            //Only save items that have changed
            var changed = headers.filter(data => data.isDirty);
            if (changed.length === 0) {
                toast.info("No items have been changed!");
            } else {
                const token = await authService.getAccessToken();
                const user = await authService.getUser();

                var headerDetails = {
                    variationId: props.variationId,
                    variationHeaders: changed,
                    subId: user.sub
                };

                axios.post('Variation/SaveVariationHeaders', headerDetails, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                .then(res => {
                    if (res.data.isError) {
                        var errors = res.data.messages as any[];
                        for (var i = 0; i < errors.length; i++) {
                            toast.error(errors[i].content);
                        };
                    } else {
                        toast.success("Headers Saved");
                        if (afterSave === "back") {
                            goBack();
                        } else if (afterSave === "copy") {
                            copyHeader(openIndex);
                        } else if (openIndex >= 0) {
                            //open the details page
                            changed = res.data.variationHeaders;
                            var findRow = changed.filter(data => data.itemIndex === openIndex);
                            var headerid = headers[openIndex].id;
                            var area = headers[openIndex].area;
                            if (findRow.length > 0) {
                                headerid = findRow[0].id;
                                area = findRow[0].area;
                            }
                            openDetailsPage(headerid, area);
                        } else {
                            changed = res.data.variationHeaders;
                            //loop through the changed items and update the main list
                            for (var i = 0; i < changed.length; i++) {
                                headers[changed[i].itemIndex] = changed[i];
                            }

                            setVariationHeaders(headers);
                            setErrors({});
                        }
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                });
            }
        } else {
            toast.error("Please check and resolve the errors at the bottom of the table");
        }
    }

    //MOVE ROW
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, oldIndex: number) => {
        event.dataTransfer.setData('text', oldIndex.toString());
    }

    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
        const oldIndexText = event.dataTransfer.getData('text');
        var oldIndex = parseInt(oldIndexText);
        onSortEnd(oldIndex, newIndex);
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        var headers = [...variationHeaders];
        var hasBlankRow = !props.approved && props.canEdit;

        var headersLength = headers.length - 1;

        if ((hasBlankRow && oldIndex != headersLength && newIndex != headersLength) || !hasBlankRow) {
            //only move if not blank row
            headers.splice(newIndex, 0, headers.splice(oldIndex, 1)[0]);

            //loop through the headers and update the order
            var length = hasBlankRow ? headers.length - 1 : headers.length;
            for (var i = 0; i < length; i++) {
                headers[i].itemIndex = (i);
                headers[i].areaOrder = (i + 1);
                headers[i].isDirty = true;
            }

            setVariationHeaders(headers);
            saveHeaders(-1, "");
        }
    };
    //END MOVE ROW

    let confirmPopup = <ConfirmModal heading="Delete Variation Area" text="Are you sure you want to delete this variation area?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />
    let confirmBack = <ConfirmModal heading="Save changed data" text="Would you like to Save before going back?" hideConfirmModal={cancelBackConfirmModal} showConfirmModal={showBackConfirmModal} noConfirmModal={noBackConfirmModal} yesConfirmModal={saveBackConfirmModal} saveDisabled={modalSaveDisabled} />
    let lastRow = (props.approved || !props.canEdit) ? variationHeaders.length : variationHeaders.length - 1;

    const renderDetails = (
        <form onSubmit={save}>
            <div className="static-modal">
                {confirmPopup}
                {confirmBack}
            </div>
            <div className="estimate__backButton">
                <h3>{props.variationName}</h3>
                <button type="button" className="defaultbutton" onClick={back}>Back</button>
            </div>
            <div className='overflowAuto'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th></th>
                            <th>Area</th>
                            <th></th>
                            <th className="textalignright">Total</th>
                            <th></th>
                            <th className={props.approved || !props.canEdit ? "hidden" : ""}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {variationHeaders.map((header, itemIndex) =>
                            <tr key={itemIndex} draggable="true" onDragStart={(e) => handleDragStart(e, itemIndex)} onDragOver={enableDropping} onDrop={(e) => handleDrop(e, itemIndex)}>
                                <td className="hidden">{header.id}</td>
                                <td className="table__text--align">{header.areaOrder == 0 || header.areaOrder == 99 ? itemIndex + 1 : header.areaOrder}</td>
                                <td className="table__text--align">
                                    <input type="text" maxLength={100} className={props.approved || !props.canEdit ? "hidden" : "form-control table__tdmin-xlg"} name="area" value={header.area} onChange={(e) => handleCellChange(itemIndex, e)} />
                                    {props.approved || !props.canEdit ? header.area : ""}
                                </td>
                                <td className="table__text--align">
                                    <a className={itemIndex === lastRow ? "hidden" : ""} href="#" onClick={(e) => openDetails(itemIndex, e)}>
                                        Open
                                    </a>
                                </td>
                                <td className="table__text--align textalignright">{header.amountExGstFormatted}</td>
                                <td className="table__text--align">
                                    <a className={props.approved || !props.canEdit || header.id === 0 ? "hidden" : ""} href="#" onClick={(e) => copy(itemIndex, e)}>
                                        Copy
                                    </a>
                                </td>
                                <td className={props.approved || !props.canEdit ? "hidden" : "table__text--align"}>
                                    <div className={itemIndex === lastRow ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteItem(itemIndex, e)} >
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {errors["headers"] ?
                    (errors["headers"]).split("\n").map((item: any, key: any) => {
                        return <span className="label errors" key={key}>{item}<br /></span>
                    })
                    : ""}
            </div>
            <div>
                <label className="input-group estimate__alignTotals" htmlFor="totalExGstFormatted">
                    <span className="label textalignright">Total</span>
                    <input className="input estimate__totalsWidth textalignright" type="text" id="totalExGstFormatted" name="totalExGstFormatted" value={totals.totalExGstFormatted} disabled></input>
                </label>
            </div>

            <button className="defaultbutton hideMobile" type="submit" disabled={!props.canEdit || saveDisabled}>Save</button>
        </form>
    )

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            {contents}
        </div>
    )

}

export default VariationHeaderData;
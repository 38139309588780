import * as React from 'react';
import { Modal } from 'react-bootstrap';
import * as DateHelpers from './DateHelpers';
import { toast } from 'react-toastify';

interface JobOnGoingHireExtendPopupProps {
    saveDisabled: boolean;
    product: OnGoingHireProduct;
    gstPercentage: number;
    show: boolean;
    hide(): void;
    update(product: OnGoingHireProduct): void;
    save(): void;
}

interface OnGoingHireProduct {
    id: number;
    productId: number;
    product: string;
    subTotal: string;
    gst: string;
    total: string;
    subTotalFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
    startDate: string;
    startDateFormatted: string;
    endDate: string;
    endDateFormatted: string;
    days: number;
    complete: boolean;
    isDirty: boolean;
}

const JobOnGoingHireExtendPopupData = (props: JobOnGoingHireExtendPopupProps) => {
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const handleChange = (e: any) => {
        var updatedProduct = props.product;

        let change: any = {};
        change = updatedProduct;
        change[e.target.name] = e.target.value;

        props.update(change);
    }

    const handleAmountChange = (e: any) => {
        var updatedProduct = props.product;
        let change: any = {};
        change = updatedProduct;
        let amountChanged = e.target.value * 1;
        change[e.target.name] = amountChanged;
        
        let existSubTotal = parseFloat(updatedProduct.subTotal);
        let existTotal = parseFloat(updatedProduct.total);

        //calculate unit cost / total
        if (e.target.name === "subTotal") {
            //subtotal
            let gst = amountChanged * props.gstPercentage;
            change["gst"] = gst.toFixed(2);
            let total = amountChanged + gst;
            change["total"] = total.toFixed(2);
        } else if (e.target.name === "gst" && existSubTotal > 0) {
            //gst - calc total
            let total = existSubTotal + amountChanged;
            change["total"] = total.toFixed(2);
        } else if (e.target.name === "gst" && existSubTotal === 0 && existTotal > 0) {
            //gst - calc subtotal
            let subTotal = (existTotal * 1) - amountChanged;
            change["subTotal"] = subTotal.toFixed(2);
        } else if (e.target.name === "total") {
            //total - get gst and subtotal
            let gstPercent = props.gstPercentage * 100;
            let gst = amountChanged * gstPercent / (100 + gstPercent)
            change["gst"] = gst.toFixed(2);
            let subTotal = amountChanged - gst;
            change["subTotal"] = subTotal.toFixed(2);
        }

        props.update(change);
    }

    const hideModal = () => {
        props.hide();
    }

    const validate = () => {
        let fields = props.product;
        let error: any = {};
        let formIsValid = true;

        var subTotal = parseFloat(fields.subTotal);
        if (subTotal <= 0) {
            formIsValid = false;
            error["subTotal"] = "Sub Total is required";
        }

        var total = parseFloat(fields.total);
        if (total <= 0) {
            formIsValid = false;
            error["total"] = "Total is required";
        }

        if (!fields.startDate) {
            formIsValid = false;
            error["startDate"] = "Start Date is required";
        } else if (!DateHelpers.isValidDate(fields.startDate)) {
            formIsValid = false;
            error["startDate"] = "Valid Start Date is required";
        }

        if (!fields.endDate) {
            formIsValid = false;
            error["endDate"] = "End Date is required";
        } else if (!DateHelpers.isValidDate(fields.endDate)) {
            formIsValid = false;
            error["endDate"] = "Valid End Date is required";
        }

        setErrors(error);
        return formIsValid;
    }

    const saveModal = () => {
        if (validate()) {
            props.save();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    return (
        <Modal show={props.show} onHide={hideModal}>
            <Modal.Header className="modal__header--colour">
                <Modal.Title>Extend Product</Modal.Title>
                <button type="button" className="close modal__close--colour" onClick={hideModal}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div>
                        <p>Extend On-Going Hire Product</p>
                    </div>
                    <label className="input-group" htmlFor="subTotal">
                        <span className="label">Sub Total</span>
                        <input type='number' min="0" step="any" className="input textalignright" name="subTotal" value={props.product.subTotal} onChange={(e) => handleAmountChange(e)} />
                    </label>
                    <span className={errors["subTotal"] ? "label errors errors__leftmargin" : "hidden"}>{errors["subTotal"]}</span>
                    <label className="input-group" htmlFor="gst">
                        <span className="label">GST</span>
                        <input type='number' min="0" step="any" className="input textalignright" name="gst" value={props.product.gst} onChange={(e) => handleAmountChange(e)} />
                    </label>
                    <label className="input-group" htmlFor="total">
                        <span className="label">Total</span>
                        <input type='number' min="0" step="any" className="input textalignright" name="total" value={props.product.total} onChange={(e) => handleAmountChange(e)} />
                    </label>
                    <span className={errors["total"] ? "label errors errors__leftmargin" : "hidden"}>{errors["total"]}</span>

                    <label className="input-group" htmlFor="startDate">
                        <span className="label">Start Date</span>
                        <input type='date' id="startDate" name="startDate" className='input' value={props.product.startDate} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["startDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["startDate"]}</span>
                    <label className="input-group" htmlFor="endDate">
                        <span className="label">End Date</span>
                        <input type='date' id="endDate" name="endDate" className='input' value={props.product.endDate} onChange={(e) => handleChange(e)}></input>
                    </label>
                    <span className={errors["endDate"] ? "label errors errors__leftmargin" : "hidden"}>{errors["endDate"]}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="popup-button--yes" onClick={saveModal} disabled={props.saveDisabled}>Save</button>
            </Modal.Footer>
        </Modal>

    )

}

export default JobOnGoingHireExtendPopupData;
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmModal from './ConfirmModal';
import authService from './api-authorization/AuthorizeService';

interface ProductList {
    id: number;
    description: string;
}

const AdminProductData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [productList, setProducts] = React.useState<ProductList[]>([]);
    const [product, setProduct] = React.useState("");
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [modalSaveDisabled, setModalSaveDisabled] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [productIndex, setproductIndex] = React.useState(-1);
    const [productDelete, setproductDelete] = React.useState("");
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();

        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=EditAdminLists', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                getProducts();
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }


    const getProducts = async () => {
        const token = await authService.getAccessToken();

        //get list of shortcuts and dropdowns
        axios.get('Admin/GetProducts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setLoading(false);
            setProducts(res.data);
        });
    }

    const handleChange = () => (e: any) => {
        setSaveDisabled(false);
        setProduct(e.target.value);
    }

    const deleteProduct = (index: number, e: any) => {
        e.preventDefault();

        var product = productList[index];
        setShowConfirmModal(true);
        setModalSaveDisabled(false);
        setproductIndex(index);
        setproductDelete(product.description);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
        setModalSaveDisabled(false);
        setproductIndex(-1);
        setproductDelete("");
    }

    const saveConfirmModal = () => {
        if (modalSaveDisabled) {
            return;
        }
        setModalSaveDisabled(true);
        setSaveDisabled(false);

        saveDeleteProduct();
    }

    const saveDeleteProduct = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        
        var product = productList[productIndex];
        var deleteProduct = {
            id: product.id,
            subId: user.sub
        };

        axios.post('Admin/DeleteProduct', deleteProduct, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Product " + product.description + " has been deleted");
                setShowConfirmModal(false);
                setModalSaveDisabled(false);
                setproductIndex(-1);
                setproductDelete("");

                getProducts();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const validate = () => {
        let formIsValid = true;
        let errors: any = {};

        if (!product) {
            formIsValid = false;
            errors["product"] = "Product is required";
        }

        setErrors(errors);
        return formIsValid;
    }

    const save = (e: any) => {
        e.preventDefault();

        if (validate()) {
            if (saveDisabled) {
                return;
            }
            setSaveDisabled(true);

            saveProduct();
        } else {
            toast.error("Please fix the validation issues before saving");
        }
    }

    const saveProduct = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        let saveProduct = {
            productDescription: product,
            subId: user.sub
        };

        axios.post('Admin/SaveProduct', saveProduct, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data.isError) {
                var errors = res.data.messages as any[];
                errors.map(function (error: any) {
                    toast.error(error.content);
                });
            } else {
                toast.success("Product has been saved");
                setProduct("");
                getProducts();
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const confirmPopup = <ConfirmModal heading={"Delete Product " + productDelete} text="Are you sure you want to delete this product?" hideConfirmModal={hideConfirmModal} showConfirmModal={showConfirmModal} noConfirmModal={hideConfirmModal} yesConfirmModal={saveConfirmModal} saveDisabled={modalSaveDisabled} />

    const renderDetails = (
        <form>
            <div className="static-modal">
                {confirmPopup}
            </div>
            <div>
                <label className="input-group" htmlFor="product">
                    <span className="label">Product</span>
                    <input className='input' type='text' maxLength={50} id="product" name="product" value={product} onChange={handleChange()}></input>
                </label>
                <span className={errors["product"] ? "label errors errors__leftmargin" : "hidden"}>{errors["product"]}</span>

                <button className="defaultbutton-prev defaultbutton__small defaultbutton-label marginBottom10" onClick={save} type="button" disabled={saveDisabled}>Add</button>

            </div>
            <div className="overflowAuto">
                <table className="table--main tableColours adminProducts-size">
                    <thead>
                        <tr>
                            <th className="table--smallHeaderSize"></th>
                            <th className="hidden">Id</th>
                            <th className="table--smallHeaderSize">Product</th>
                            <th className="table--smallHeaderSize table__center-text--align">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productList.map((product, index) =>
                            <tr key={index}>
                                <td className="table__text--align">{index + 1}</td>
                                <td className="hidden">{product.id}</td>
                                <td className="table__text--align">{product.description}</td>
                                <td className="table__text--align">
                                    <div className={product.id === 1 ? "hidden" : "delete--tablecell"}>
                                        <a className="makeitred" href="#" onClick={(e) => deleteProduct(index, e)}>
                                            <span className="fas fa-times-circle edit--icon alignIconCenter"></span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </form>
    );

    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (<div>
        <h1>Add/Edit On-Going Hire Products</h1>
        {contents}
    </div>)
}

export default AdminProductData;

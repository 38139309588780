
interface EstimateHeaderMobileProps {
    siteAddress: string;
    isMakeSafe: boolean;
    isReportOnly: boolean;
    estimate: Estimate;
    changePage(pageData: PageChangeData): void;
}

interface Estimate {
    id: number;
    estimateHeaders: EstimateHeaders[]
    subTotalFormatted: string;
    markUpPercentageFormatted: string;
    subTotalExGstFormatted: string;
    gstFormatted: string;
    totalFormatted: string;
}

interface EstimateHeaders {
    id: number;
    area: string;
    areaOrder: number;
    amount: number;
    amountFormatted: string;
    amountExGstFormatted: string;
}

interface PageChangeData {
    page: string;
    headerId: number;
    headerText: string;
}

const EstimateHeaderMobileData = (props: EstimateHeaderMobileProps) => {
    const openDetails = (itemIndex: number, e: any) => {
        e.preventDefault();
        var header = props.estimate.estimateHeaders[itemIndex];

        var pageChange: PageChangeData = {
            page: "details",
            headerId: header.id,
            headerText: header.area,
        };

        props.changePage(pageChange);
    }

    return (
        <div className="marginTop10 marginBottom70">
            <h4 className="mobile-margin">Estimate</h4>
            <span className={"mobileFont" + (props.isMakeSafe ? " makeSafeJob" : props.isReportOnly ? " reportOnlyJob" : "")}>{props.siteAddress}</span>
            <div className='overflowAuto marginTop10'>
                <table className="table--main table__small tableColours">
                    <thead>
                        <tr>
                            <th className="hidden">Id</th>
                            <th></th>
                            <th>Area</th>
                            <th className="textalignright">Sub Total</th>
                            <th className="textalignright">Sub Total Ex Gst</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.estimate.estimateHeaders.map((header, index) =>
                            <tr key={index}>
                                <td className="hidden">{header.id}</td>
                                <td className="table__text--align">{header.areaOrder == 0 || header.areaOrder == 99 ? index + 1 : header.areaOrder}</td>
                                <td className="table__text--align">
                                    <a href="#" onClick={(e) => openDetails(index, e)}>
                                        {header.area.length > 30 ? header.area.substring(0, 30) + '...' : header.area}
                                    </a>
                                </td>
                                <td className="table__text--align textalignright">
                                    {header.amountFormatted}<br></br>
                                </td>
                                <td className="table__text--align textalignright">
                                    {header.amountExGstFormatted}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mobileFont defaultbutton__container--right">
                <div>
                    <label className="input-group labeltextalignright marginTop10 marginBottom15" htmlFor="subTotalFormatted">
                        <span className="label-mobile">Sub Total</span>
                        <span className="estimate-mobile-totals">{props.estimate.subTotalFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright marginBottom15" htmlFor="markUpPercentage">
                        <span className="label-mobile ">Global Mark-Up %</span>
                        <span className="estimate-mobile-totals">{props.estimate.markUpPercentageFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright makeitbold marginBottom15" htmlFor="subTotalExGstFormatted">
                        <span className="label-mobile">Sub Total ex GST</span>
                        <span className="estimate-mobile-totals">{props.estimate.subTotalExGstFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright marginBottom15" htmlFor="gstFormatted">
                        <span className="label-mobile ">GST</span>
                        <span className="estimate-mobile-totals">{props.estimate.gstFormatted}</span>
                    </label>
                    <label className="input-group labeltextalignright makeitbold marginBottom15" htmlFor="totalFormatted">
                        <span className="label-mobile">Total</span>
                        <span className="estimate-mobile-totals">{props.estimate.totalFormatted}</span>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default EstimateHeaderMobileData;
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Pager from './Paging';
import authService from './api-authorization/AuthorizeService';

interface Result {
    pageSize: number;
    totalRecords: number;
    searchResults: ClientList[];
}

interface ClientList {
    id: number;
    name: string;
    type: string;
    status: string;
}

const ClientSearchData = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState("");
    const [showInactive, setShowInactive] = React.useState(false);
    const [showResults, setShowResults] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [result, setResult] = React.useState<Result>({
        pageSize: 1,
        totalRecords: 0,
        searchResults: []
    });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async() => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        axios.get('Users/CheckUserPermission?SubId=' + user.sub + '&Permission=CreateEditClients', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            if (res.data === true) {
                setLoading(false);
            } else {
                //redirect to 403 permission denied
                navigate("/accessdenied");
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    const addNew = () => {
        navigate("/client/add");
    }

    const handleChange = (e: any) => {
        setName(e.target.value);
    }

    const handleCheckboxChange = (e: any) => {
        setShowInactive(e.target.checked);
    }

    const reset = () => {
        setName("");
        setShowInactive(false);
        setShowResults(false);

        var result: Result = {
            pageSize: 1,
            totalRecords: 0,
            searchResults: []
        };
        setResult(result);
    }

    const search = (e: any) => {
        e.preventDefault();

        //check to make sure at least one search field has been entered
        if (name) {
            getClients(1, true);
        } else {
            setShowResults(false);
            toast.error("Please enter the client name");
        }

    }

    const getClients = async(page: number, getTotalRecords: boolean) => {
        var totalRecords = getTotalRecords ? 0 : result.totalRecords;
        const token = await authService.getAccessToken();

        axios.get('Clients/Search?Name=' + name + '&ShowInactive=' + showInactive + '&page=' + page + '&totalRecords=' + totalRecords + '&getTotalRecords=' + getTotalRecords, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        .then(res => {
            setShowResults(true);
            setResult(res.data);
        })
    }

    const pageChanged = (pageNumber: number) => {
        setPage(pageNumber);
        getClients(pageNumber, false);
    }

    const pagerContents = <Pager page={page} pageSize={result.pageSize} totalRecords={result.totalRecords} pageChanged={pageChanged} />

    const renderDetails = (
        <form onSubmit={search}>
            <div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="name">
                        <span className="label">Name</span>
                        <input className="input" type="text" id="name" name="name" value={name} onChange={(e) => handleChange(e)}></input>
                    </label>
                </div>
                <div className="input-group-parent">
                    <label className="input-group" htmlFor="inactive">
                        <span className="label">Show Inactive</span>
                        <input className="input checkbox" type="checkbox" id="inactive" name="showInactive" checked={showInactive} onChange={(e) => handleCheckboxChange(e)}></input>
                    </label>
                </div>
                <div>
                    <button className="defaultbutton-prev defaultbutton-prev__split" type='submit'>Search</button>
                    <button className="defaultbutton-next" type='button' onClick={reset}>Reset</button>
                </div>
                <hr></hr>

                <div className={showResults ? 'overflowAuto' : 'hidden'}>
                    <table className="table--main tableColours">
                        <thead>
                            <tr>
                                <th className="hidden">Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result.searchResults.map(client =>
                                <tr key={client.id}>
                                    <td className="hidden">{client.id}</td>
                                    <td><NavLink to={'/client/' + client.id}>{client.name}</NavLink></td>
                                    <td>{client.type}</td>
                                    <td>{client.status}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={(result.totalRecords / result.pageSize) > 1 ? "" : "hidden"}>
                    {pagerContents}
                </div>
            </div>

        </form>
    );
    
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderDetails;

    return (
        <div>
            <div className="input-group-parent marginBottom10">
                <h1>Client Search</h1>
            </div>
            <div className="input-group-parent marginBottom10">
                <div className="defaultbutton__container--right">
                    <button className="defaultbutton defaultbutton__small marginLeft10" type='button' onClick={addNew}>Add New</button>
                </div>
            </div>
            {contents}
        </div>
    )

}

export default ClientSearchData;

